import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';

export const ContactInfoWrapper = styled('div')({
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'column',
});

export const UnsubscribeWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& .MuiCheckbox-root': {
        padding: 0,
    },
});

export const ActivityTitle = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.38)',
    letterSpacing: '1.5px !important',
    fontWeight: '500 !important',
    fontSize: '10px !important',
    lineHeight: '12px !important',
    display: 'block',
    textTransform: 'uppercase',
    marginBottom: '16px',
});

export const ActivityWrapper = styled('div')({
    display: 'grid',
    gridGap: '0 10px',
    gridTemplateColumns: '14px 1fr 60px',
    '&:not(:last-child)': {
        marginBottom: 24,
    },
    '& svg': {
        transform: 'translateY(2px)',
    },
});

export const IconWrapper = styled('span')({
    '& svg': {
        transform: 'translateY(0)',
    },
    '& svg path': {
        fill: '#1F4C5C',
        fillOpacity: 1,
    },
});
