import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { RadioButtonsGroup } from '@components/RadioButtonsGroup';
import { surveysPreview as previewTranslation } from '@constants/surveys-preview';
import { LOCALE, LOCALE_KEY } from '@constants/locale';
import { useTranslation } from '@hooks/useTranslation';
import Main from './Main';
import Positive from './Positive';
import Negative from './Negative';

export const PreviewTabs = styled(RadioButtonsGroup)({
    width: 252,
    display: 'block',
    margin: '24px auto 0',
    '& .MuiFormControlLabel-root': {
        height: 32,
        padding: '0 4px',
    },
});

const ViewMap = {
    Main: 0,
    Positive: 1,
    Negative: 2,
    SurveyDisabled: 3,
};

const LOCALES = {
    [LOCALE.UNITED_KINGDOM]: LOCALE_KEY.GB,
    [LOCALE.UKRAINE]: LOCALE_KEY.UA,
    [LOCALE.INTERNATIONAL]: LOCALE_KEY.GB,
    [LOCALE.SPAIN]: LOCALE_KEY.ES,
    [LOCALE.PORTUGAL]: LOCALE_KEY.PT,
    [LOCALE.BRAZIL]: LOCALE_KEY.BR,
    [LOCALE.POLAND]: LOCALE_KEY.PL,
    [LOCALE.GERMANY]: LOCALE_KEY.DE,
};

const Preview = ({ company, preview, locale }) => {
    const { t } = useTranslation();
    const [view, setView] = useState(ViewMap.Main);

    const language = LOCALES[locale];

    useEffect(() => {
        setView(preview.satisfactionSurveyEnabled ? ViewMap.Main : ViewMap.SurveyDisabled);
    }, [preview.satisfactionSurveyEnabled]);

    return (
        <div style={{ padding: 16 }}>
            <div style={{ width: 280 }}>
                {view === ViewMap.Main && (
                    <Main
                        systemFields={preview.systemFields}
                        customFields={preview.customFields}
                        language={language}
                        company={company}
                        t={previewTranslation[language]}
                    />
                )}
                {(view === ViewMap.Positive || view === ViewMap.SurveyDisabled) && (
                    <Positive
                        t={previewTranslation[language]}
                        language={language}
                        satisfactionSurveyEnabled={preview.satisfactionSurveyEnabled}
                        company={company}
                        reviewPlatforms={preview.reviewPlatforms}
                    />
                )}
                {view === ViewMap.Negative && (
                    <Negative
                        systemFields={preview.systemFields}
                        customFields={preview.customFields}
                        language={language}
                        t={previewTranslation[language]}
                    />
                )}
            </div>
            {view !== ViewMap.SurveyDisabled && (
                <PreviewTabs
                    value={view}
                    onChange={setView}
                    options={[
                        { value: ViewMap.Main, label: t('FeedbackSurveys.main') },
                        { value: ViewMap.Positive, label: t('FeedbackSurveys.positive') },
                        { value: ViewMap.Negative, label: t('FeedbackSurveys.negative') },
                    ]}
                />
            )}
        </div>
    );
};

export default Preview;
