export const analyticsConstants = {
    ANALYTICS_REQUEST: 'ANALYTICS_REQUEST',
    ANALYTICS_SUCCESS: 'ANALYTICS_SUCCESS',
    ANALYTICS_FAILURE: 'ANALYTICS_FAILURE',
    DASHBOARD_ANALYTICS_REQUEST: 'DASHBOARD_ANALYTICS_REQUEST',
    DASHBOARD_ANALYTICS_SUCCESS: 'DASHBOARD_ANALYTICS_SUCCESS',
    DASHBOARD_ANALYTICS_FAILURE: 'DASHBOARD_ANALYTICS_FAILURE',
    // new
    ANALYTICS_CONVERSION_REQUEST: 'ANALYTICS_CONVERSION_REQUEST',
    ANALYTICS_CONVERSION_SUCCESS: 'ANALYTICS_CONVERSION_SUCCESS',
    ANALYTICS_CONVERSION_FAILURE: 'ANALYTICS_CONVERSION_FAILURE',

    ANALYTICS_REVIEWS_REQUEST: 'ANALYTICS_REVIEWS_REQUEST',
    ANALYTICS_REVIEWS_SUCCESS: 'ANALYTICS_REVIEWS_SUCCESS',
    ANALYTICS_REVIEWS_FAILURE: 'ANALYTICS_REVIEWS_FAILURE',

    ANALYTICS_FEEDBACK_REQUEST: 'ANALYTICS_FEEDBACK_REQUEST',
    ANALYTICS_FEEDBACK_SUCCESS: 'ANALYTICS_FEEDBACK_SUCCESS',
    ANALYTICS_FEEDBACK_FAILURE: 'ANALYTICS_FEEDBACK_FAILURE',

    ANALYTICS_CONVERSATIONS_SUCCESS: 'ANALYTICS_CONVERSATIONS_SUCCESS',
    ANALYTICS_CONVERSATIONS_FAILURE: 'ANALYTICS_CONVERSATIONS_FAILURE',

    ANALYTICS_SOCIAL_POSTS_REQUEST: 'ANALYTICS_SOCIAL_POSTS_REQUEST',
    ANALYTICS_SOCIAL_POSTS_SUCCESS: 'ANALYTICS_SOCIAL_POSTS_SUCCESS',
    ANALYTICS_SOCIAL_POSTS_FAILURE: 'ANALYTICS_SOCIAL_POSTS_FAILURE',

    ANALYTICS_PRESENCE_REQUEST: 'ANALYTICS_PRESENCE_REQUEST',
    ANALYTICS_PRESENCE_SUCCESS: 'ANALYTICS_PRESENCE_SUCCESS',
    ANALYTICS_PRESENCE_FAILURE: 'ANALYTICS_PRESENCE_FAILURE',
};

export const TREND_TYPE = {
    CONVERSATIONS: 0,
    IMPRESSIONS: 1,
    REACH: 2,
    ENGAGEMENTS: 3,
    PRESENCE_VIEWS: 4,
    PRESENCE_ENGAGEMENTS: 5,
};

export const GroupByFilter = {
    Days: 0,
    Weeks: 1,
    Months: 2,
    Years: 3,
};
