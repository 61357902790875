import { LOCALE, LOCALE_KEY } from './locale';
import { SURVEY_LANGUAGE } from './language';

export const micrositesConstants = {
    MICROSITES_REQUEST: 'MICROSITES_REQUEST',
    MICROSITES_SUCCESS: 'MICROSITES_SUCCESS',
    MICROSITES_ERROR: 'MICROSITES_ERROR',
};

export const VIEW_TYPE = {
    EDIT: 'EDIT',
    VIEW: 'VIEW',
};

// survey preview language
export const PREVIEW_LOCALES = {
    [LOCALE.UNITED_KINGDOM]: LOCALE_KEY.GB,
    [LOCALE.UKRAINE]: LOCALE_KEY.UA,
    [LOCALE.INTERNATIONAL]: LOCALE_KEY.GB,
    [LOCALE.SPAIN]: LOCALE_KEY.ES,
    [LOCALE.PORTUGAL]: LOCALE_KEY.PT,
    [LOCALE.BRAZIL]: LOCALE_KEY.PT,
    [LOCALE.POLAND]: LOCALE_KEY.PL,
    [LOCALE.GERMANY]: LOCALE_KEY.DE,
};

export const LANGUAGES_MAP = {
    [LOCALE.UNITED_KINGDOM]: [
        SURVEY_LANGUAGE.GB,
        SURVEY_LANGUAGE.DE,
        SURVEY_LANGUAGE.ES,
        SURVEY_LANGUAGE.FR,
        SURVEY_LANGUAGE.AR,
        SURVEY_LANGUAGE.PL,
    ],
    [LOCALE.UKRAINE]: [SURVEY_LANGUAGE.UA, SURVEY_LANGUAGE.GB],
    [LOCALE.INTERNATIONAL]: [
        SURVEY_LANGUAGE.GB,
        SURVEY_LANGUAGE.DE,
        SURVEY_LANGUAGE.ES,
        SURVEY_LANGUAGE.FR,
        SURVEY_LANGUAGE.AR,
        SURVEY_LANGUAGE.PL,
    ],
    [LOCALE.SPAIN]: [
        SURVEY_LANGUAGE.ES,
        SURVEY_LANGUAGE.DE,
        SURVEY_LANGUAGE.GB,
        SURVEY_LANGUAGE.FR,
        SURVEY_LANGUAGE.AR,
        SURVEY_LANGUAGE.PL,
    ],
    [LOCALE.PORTUGAL]: [
        SURVEY_LANGUAGE.PT,
        SURVEY_LANGUAGE.ES,
        SURVEY_LANGUAGE.DE,
        SURVEY_LANGUAGE.GB,
        SURVEY_LANGUAGE.FR,
        SURVEY_LANGUAGE.AR,
        SURVEY_LANGUAGE.PL,
    ],
    [LOCALE.BRAZIL]: [
        SURVEY_LANGUAGE.PT,
        SURVEY_LANGUAGE.ES,
        SURVEY_LANGUAGE.DE,
        SURVEY_LANGUAGE.GB,
        SURVEY_LANGUAGE.FR,
        SURVEY_LANGUAGE.AR,
        SURVEY_LANGUAGE.PL,
    ],
    [LOCALE.POLAND]: [
        SURVEY_LANGUAGE.PL,
        SURVEY_LANGUAGE.DE,
        SURVEY_LANGUAGE.ES,
        SURVEY_LANGUAGE.FR,
        SURVEY_LANGUAGE.GB,
        SURVEY_LANGUAGE.PT,
        SURVEY_LANGUAGE.UA,
        SURVEY_LANGUAGE.AR,
    ],
    [LOCALE.GERMANY]: [
        SURVEY_LANGUAGE.DE,
        SURVEY_LANGUAGE.ES,
        SURVEY_LANGUAGE.FR,
        SURVEY_LANGUAGE.GB,
        SURVEY_LANGUAGE.PT,
        SURVEY_LANGUAGE.UA,
        SURVEY_LANGUAGE.AR,
        SURVEY_LANGUAGE.PL,
    ],
};

// localise modal
const UA_OPTIONS = [
    { label: 'Українська', value: SURVEY_LANGUAGE.UA },
    { label: 'English', value: SURVEY_LANGUAGE.GB },
];
const GB_OPTIONS = [
    { label: 'English', value: SURVEY_LANGUAGE.GB },
    { label: 'Español', value: SURVEY_LANGUAGE.ES },
    { label: 'Français', value: SURVEY_LANGUAGE.FR },
    { label: 'Deutsch', value: SURVEY_LANGUAGE.DE },
    { label: 'Polski', value: SURVEY_LANGUAGE.PL },
];
const INTERNATIONAL_OPTIONS = [
    { label: 'English', value: SURVEY_LANGUAGE.GB },
    { label: 'Español', value: SURVEY_LANGUAGE.ES },
    { label: 'Français', value: SURVEY_LANGUAGE.FR },
    { label: 'Deutsch', value: SURVEY_LANGUAGE.DE },
    { label: 'Português', value: SURVEY_LANGUAGE.PT },
    { label: 'العربية', value: SURVEY_LANGUAGE.AR },
    { label: 'Русский', value: SURVEY_LANGUAGE.RU },
    { label: 'Українська', value: SURVEY_LANGUAGE.UA },
    { label: 'Polski', value: SURVEY_LANGUAGE.PL },
];
const ES_OPTIONS = [
    { label: 'Español', value: SURVEY_LANGUAGE.ES },
    { label: 'English', value: SURVEY_LANGUAGE.GB },
    { label: 'Français', value: SURVEY_LANGUAGE.FR },
    { label: 'Deutsch', value: SURVEY_LANGUAGE.DE },
    { label: 'Português', value: SURVEY_LANGUAGE.PT },
    { label: 'Русский', value: SURVEY_LANGUAGE.RU },
    { label: 'Українська', value: SURVEY_LANGUAGE.UA },
    { label: 'Polski', value: SURVEY_LANGUAGE.PL },
];
const PT_OPTIONS = [
    { label: 'Português', value: SURVEY_LANGUAGE.PT },
    { label: 'Español', value: SURVEY_LANGUAGE.ES },
    { label: 'English', value: SURVEY_LANGUAGE.GB },
    { label: 'Français', value: SURVEY_LANGUAGE.FR },
    { label: 'Deutsch', value: SURVEY_LANGUAGE.DE },
    { label: 'Русский', value: SURVEY_LANGUAGE.RU },
    { label: 'Українська', value: SURVEY_LANGUAGE.UA },
    { label: 'Polski', value: SURVEY_LANGUAGE.PL },
];
const PL_OPTIONS = [
    { label: 'Polski', value: SURVEY_LANGUAGE.PL },
    { label: 'Español', value: SURVEY_LANGUAGE.ES },
    { label: 'English', value: SURVEY_LANGUAGE.GB },
    { label: 'Français', value: SURVEY_LANGUAGE.FR },
    { label: 'Deutsch', value: SURVEY_LANGUAGE.DE },
    { label: 'Português', value: SURVEY_LANGUAGE.PT },
    { label: 'Русский', value: SURVEY_LANGUAGE.RU },
    { label: 'Українська', value: SURVEY_LANGUAGE.UA },
];
const DE_OPTIONS = [
    { label: 'Deutsch', value: SURVEY_LANGUAGE.DE },
    { label: 'Español', value: SURVEY_LANGUAGE.ES },
    { label: 'English', value: SURVEY_LANGUAGE.GB },
    { label: 'Français', value: SURVEY_LANGUAGE.FR },
    { label: 'Português', value: SURVEY_LANGUAGE.PT },
    { label: 'Русский', value: SURVEY_LANGUAGE.RU },
    { label: 'Українська', value: SURVEY_LANGUAGE.UA },
    { label: 'Polski', value: SURVEY_LANGUAGE.PL },
];

export const LOCALISE_MODAL_LANGUAGES = {
    [LOCALE.UNITED_KINGDOM]: GB_OPTIONS,
    [LOCALE.UKRAINE]: UA_OPTIONS,
    [LOCALE.INTERNATIONAL]: INTERNATIONAL_OPTIONS,
    [LOCALE.SPAIN]: ES_OPTIONS,
    [LOCALE.PORTUGAL]: PT_OPTIONS,
    [LOCALE.BRAZIL]: PT_OPTIONS,
    [LOCALE.POLAND]: PL_OPTIONS,
    [LOCALE.GERMANY]: DE_OPTIONS,
};
