import { styled } from '@mui/material/styles';
import { ReviewsEmptyIcon } from 'assets/images/icons';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';

const Container = styled('div')({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const Wrapper = styled('div')({
    '& svg': {
        display: 'block',
        margin: '0 auto 24px',
    },
});

const ReviewsNotSelected = ({ type }) => {
    const { t } = useTranslation();
    return (
        <Container>
            <Wrapper>
                <ReviewsEmptyIcon />
                <Typography variant="h4" sx={{ color: '#1F4C5C', marginBottom: '8px', textAlign: 'center' }}>
                    {t('ReviewsEmptyFeed.reviewsNotSelectedTitle')}
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'center' }}>
                    {t('ReviewsEmptyFeed.reviewsNotSelectedDescription', {
                        feedback:
                            type === 'feedback'
                                ? t('ReviewsEmptyFeed.surveyDescription')
                                : t('ReviewsEmptyFeed.integrationDescription'),
                    })}
                </Typography>
            </Wrapper>
        </Container>
    );
};

export default ReviewsNotSelected;
