import { styled } from '@mui/material/styles';
import { useTranslation } from '@hooks/useTranslation';
import MetricItem from '../Common/MetricItem';

export const MetricGrid = styled('div')(() => ({
    display: 'grid',
    gridGap: 16,
    gridTemplateAreas: '"metric1 metric2 metric3 metric4"',
    gridAutoColumns: 'minmax(255px, auto) minmax(255px, auto) minmax(255px, auto) minmax(255px, auto)',
    '@media (max-width: 1439px)': {
        gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
        gridTemplateAreas: '"metric1 metric2" "metric3 metric4"',
    },
    '@media (max-width: 600px)': {
        gridTemplateColumns: '100%',
        gridTemplateAreas: '"metric1" "metric2" "metric3" "metric4"',
    },
    marginBottom: 16,
}));

const PresenceRates = ({ data }) => {
    const { t } = useTranslation();

    return (
        <MetricGrid>
            <MetricItem
                title={t('AnalyticsPresence.searchAppearances')}
                data={{ metric: data.searchAppearance, delta: data.searchAppearanceChange }}
                styles={{ gridArea: 'metric1' }}
                withPercentageDelta
            />
            <MetricItem
                title={t('AnalyticsPresence.views')}
                data={{ metric: data.views, delta: data.viewsChange }}
                styles={{ gridArea: 'metric2' }}
                withPercentageDelta
            />
            <MetricItem
                title={t('AnalyticsSocialPosts.engagements')}
                data={{ metric: data.engagements, delta: data.engagementsChange }}
                styles={{ gridArea: 'metric3' }}
                withPercentageDelta
            />
            <MetricItem
                title={t('AnalyticsConversion.conversion')}
                data={{ metric: data.conversion, delta: data.conversionChange }}
                styles={{ gridArea: 'metric4' }}
                withPercentage
                withPercentageDelta
            />
        </MetricGrid>
    );
};

export default PresenceRates;
