import { styled } from '@mui/material/styles';
import ReactPhoneInput from 'react-phone-input-2';
import cls from 'classnames';
import 'react-phone-input-2/lib/material.css';
import { baseStyles } from './styles';

const Container = styled('div')(
    () => `
    position: relative;
    padding-bottom: 24px;
`,
);
const Asterisc = styled('span')(
    () => `
    color: #B00020;
    font-weight: 500;
`,
);
const ErrorMessage = styled('p')(
    () => `
    color: #B00020;
    font-family: Rubik, sans-serif;
    font-size: 12px;
    letter-spacing: 0.4px;
    position: absolute;
    bottom: -6px;
    left: 12px;
`,
);

const PhoneWrapper = ({ className, ...props }) => (
    <div className={className}>
        <ReactPhoneInput {...props} ref={props.innerRef} />
    </div>
);

const StyledPhoneInput = styled(PhoneWrapper)(() => baseStyles);

const PhoneInput = ({ onChange, onBlur, value, inputProps, label, required, error, ...rest }) => {
    return (
        <Container>
            <StyledPhoneInput
                containerClass={cls({
                    phoneNumber: true,
                    phoneError: !!error,
                })}
                specialLabel={
                    <p>
                        {label} {required && <Asterisc>*</Asterisc>}
                    </p>
                }
                inputProps={{ ...inputProps }}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                autoFormat={false}
                {...rest}
            />
            <ErrorMessage>{error}</ErrorMessage>
        </Container>
    );
};

export default PhoneInput;
