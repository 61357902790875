import { profilesMediaConstants } from '@constants/profiles-media';

const initialState = {
    data: [],
    hasMore: false,
    loading: true,
    error: '',
};

export function profilesMedia(state = initialState, action) {
    switch (action.type) {
        case profilesMediaConstants.PROFILES_MEDIA_FETCH:
            return { ...state, error: '' };
        case profilesMediaConstants.PROFILES_MEDIA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.items,
                hasMore: action.data.hasMore,
                error: '',
            };
        case profilesMediaConstants.PROFILES_MEDIA_LOAD_MORE_SUCCESS:
            return {
                ...state,
                data: [...state.data, ...action.data.items],
                hasMore: action.data.hasMore,
            };
        case profilesMediaConstants.PROFILES_MEDIA_ERROR:
            return { ...state, error: action.message, loading: false };
        case profilesMediaConstants.PROFILES_MEDIA_SET:
            return { ...state, data: action.data };
        default:
            return state;
    }
}
