import { userConstants } from '@constants/user';
import { accountConstants } from '@constants/account';
import { userService } from '@services';
import GTMHelper from '@helpers/gtm';
import { store } from 'store';

export const userActions = {
    setUserFail,
    logout,
    getUser,
    resetStore,
    setUser,
    setUserLoading,
};

function getUser(id) {
    return dispatch => {
        return userService.getUser(id).then(
            user => {
                const userFromLS = JSON.parse(localStorage.getItem('user'));
                localStorage.setItem(
                    'user',
                    JSON.stringify({ ...userFromLS, ...user, token: user?.token || userFromLS?.token }),
                );
                dispatch(success(user));
            },
            error => dispatch(failure(error)),
        );
    };

    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }

    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

function logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('company');
    GTMHelper.push('user-data-updated');
    store.dispatch(resetStore());
    accountConstants.BILLING_EXPIRED = 0;
    return { type: userConstants.LOGOUT };
}

function resetStore() {
    return { type: userConstants.CLEAR_STORE };
}

function setUser(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
}

function setUserFail() {
    return { type: userConstants.LOGIN_FAILURE };
}

function setUserLoading(isLoading) {
    return { type: userConstants.SET_USER_LOADING, payload: isLoading };
}
