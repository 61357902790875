import sortBy from 'lodash.sortby';
import { CHANNEL } from '@constants/channels';
import contactsHelper from './contacts';

export const getSurveysOptionsById = (companyId, companies) => {
    return (
        sortBy(
            companies
                ?.find(company => company.companyId === companyId)
                ?.surveys?.map(survey => ({ value: survey.id, label: survey.name || 'MAIN' })),
            ['label'],
        ) || []
    );
};

export const getSurveysOptions = (companyIds, companies) => {
    return (
        sortBy(
            companies
                ?.filter(company => companyIds.includes(company.companyId))
                ?.map(company => company.surveys)
                ?.flat()
                ?.map(survey => ({ value: survey.id, label: survey.name || 'MAIN' })),
            ['label'],
        ) || []
    );
};

export const getMessageSurveyContent = (id, companyId, companies) => {
    return (
        companies?.find(company => company.companyId === companyId)?.surveys?.find(survey => survey.id === id)
            ?.message || ''
    );
};

export const findCompany = (companies, companyId) => {
    return companies?.find(company => company.companyId === companyId) || {};
};

const checkUniqueBulkContacts = (list, typeField) => {
    const uniqueMap = {};
    const uniqueList = [];
    for (const contact of list) {
        if (!uniqueMap[contact[typeField]]) {
            if (contact[typeField]) uniqueMap[contact[typeField]] = true;
            uniqueList.push(contact);
            continue;
        }
    }
    return uniqueList;
};

export const validateBulkData = (data, locale, channelType) => {
    if (!data || data.length === 0) return null;
    const list = [];
    const isEmail = channelType === CHANNEL.EMAIL;

    data.forEach(element => {
        if (isEmail) {
            contactsHelper.checkEmails(element, 'Email');
        } else {
            contactsHelper.checkPhoneNumbers(element, locale, 'Mobile_Number');
        }
        list.push(element);
    });
    const filteredEmptyValues = isEmail ? list.filter(item => item.Email) : list.filter(item => item.Mobile_Number);
    return checkUniqueBulkContacts(filteredEmptyValues, isEmail ? 'Email' : 'Mobile_Number');
};
