import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const ifoodIntegrationService = {
    getAuthCode,
    connectIntegration,
    disconnectIntegration,
    getMerchants,
    editProfile,
};

function getMerchants() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/integrations/ifood/merchants`, requestOptions).then(handleResponse);
}

function getAuthCode() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/integrations/ifood/user-code`, requestOptions).then(handleResponse);
}

function connectIntegration(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/integrations/ifood/authenticate`, requestOptions).then(handleResponse);
}

function disconnectIntegration() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/integrations/ifood/revoke-access`, requestOptions).then(handleResponse);
}

function editProfile(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/profiles/ifood`, requestOptions).then(handleResponse);
}
