import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import AppSkeleton from '@features/AppSkeleton';
import SendInvites from '@features/SendInvites';
import { accountActions } from '@actions';
import ResendEmail from '@features/ResendEmail';
import ServiceWorkerUpdatesSnackbar from '@features/ServiceWorkerUpdatesSnackbar';
import Header from '@features/Header';
import Sidebar from '@features/Sidebar';
import HubspotCalendar from '@features/HubspotCalendar';
import BillingDrawer from '@features/BillingDrawer';

const Container = styled('div')({
    display: 'grid',
    gridTemplateColumns: '280px calc(100% - 280px)',
    gridTemplateAreas: `"sidebar main" "sidebar main"`,
    '@media (max-width: 1024px)': {
        gridTemplateColumns: '100%',
        gridTemplateAreas: `"sidebar" "header" "main"`,
    },
});

const Main = styled('div')({
    gridArea: 'main',
});

const AppContent = styled('div', {
    shouldForwardProp: prop => prop !== 'pathname',
})(({ pathname }) => {
    const backgroundColor = pathname === '/' ? 'rgb(242, 242, 242)' : 'transparent';
    return {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor,
        padding: pathname === '/messaging/inbox' ? '13px 24px 0 24px' : 24,
        position: 'relative',
        overflow: 'hidden',
        minHeight: 'calc(var(--100vh) - 72px)',
        marginTop: 72,
        '@media (max-width: 1024px)': {
            padding: 0,
            height: `calc(var(--100vh) - 108px)`,
            minHeight: `calc(var(--100vh) - 108px)`,
            marginTop: 0,
            top: 108,
        },
        '@media print': {
            height: 'auto',
            top: 0,
            position: 'relative',
        },
    };
});

const AppLayout = ({ children }) => {
    const isMobile = useMediaQuery('@media (max-width: 1024px)', { noSsr: true });
    const isPrint = useMediaQuery('@media print');
    const isMobileView = isMobile && !isPrint; // to avoid changing the layout when click print button
    const { pathname } = useLocation();

    const account = useSelector(state => state.account);
    const user = useSelector(state => state.authentication.user);
    const authLoading = useSelector(state => state.authentication.loading);
    const { isOpen } = useSelector(state => state.sendInvitesUi);
    const { isOpen: isPricingDrawerOpen } = useSelector(state => state.pricingDrawerUi);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!account.account && !account.loading && user) {
            dispatch(accountActions.get());
        }
    }, [account, user, dispatch]);

    if ((!account.account && account.loading) || authLoading) {
        return <AppSkeleton />;
    }

    return (
        <Container>
            {user && account.account ? <Sidebar isMobile={isMobileView} /> : null}
            <Main>
                {user && account.account ? <Header /> : null}
                <AppContent pathname={pathname}>
                    <ResendEmail user={user} />
                    {user && account.account ? <ServiceWorkerUpdatesSnackbar /> : null}
                    <HubspotCalendar />
                    {isPricingDrawerOpen && <BillingDrawer />}
                    {children}
                </AppContent>
            </Main>
            {isOpen ? <SendInvites isMobile={isMobileView} /> : null}
        </Container>
    );
};

export default AppLayout;
