import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch from '@mui/material/Switch';

const PrimarySwitch = styled(MuiSwitch)(() => ({
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: '#1F4C5C',
            transform: 'translateX(22px)',
            '& + .MuiSwitch-track': {
                opacity: 0.15,
                backgroundColor: '#1F4C5C',
            },
        },
        '&.Mui-checked.Mui-disabled': {
            color: '#1F4C5C',
        },
    },
}));

const LabelFormControlLabelStyled = styled(FormControlLabel)({
    '& .MuiTypography-root': {
        fontFamily: 'Rubik, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.875rem', //14px
        lineHeight: '16px',
        letterSpacing: '0.25px',
        color: 'rgba(0, 0, 0, 0.87)',
    },
});

export const Switch = ({ checked, handleChange, label = '', className, disabled = false, style, sx }) => {
    return (
        <LabelFormControlLabelStyled
            control={
                <PrimarySwitch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={disabled}
                />
            }
            label={label}
            className={className}
            style={style}
            sx={sx}
        />
    );
};
