import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { Input } from '@components/Input';
import { alertActions, accountActions } from '@actions';
import { ifoodIntegrationService } from '@services';
import { useTranslation } from '@hooks/useTranslation';
import { Modal, Content } from './styles';

const IFoodModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const IFoodSchema = yup.object().shape({
        userCode: yup.string().required('validation.required'),
    });

    const resolver = useYupValidationResolver(IFoodSchema);

    const {
        getValues,
        control,
        formState: { isValid, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: { userCode: '' },
    });

    const onGetAuthCode = async () => {
        try {
            const response = await ifoodIntegrationService.getAuthCode();
            localStorage.setItem('iFoodAuthCodeVerifier', response.authorizationCodeVerifier);
            window.open(response.verificationUrlComplete, 'iFood', 'noopener=yes');
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
    };

    const onConnectIFood = async () => {
        try {
            const { userCode } = getValues();
            const authorizationCodeVerifier = localStorage.getItem('iFoodAuthCodeVerifier');
            await ifoodIntegrationService.connectIntegration({
                userCode,
                authorizationCodeVerifier,
            });
            dispatch(alertActions.success(t('alertMessages.connectIntegrationSuccess')));
            dispatch(accountActions.get());
            onClose();
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.connectIntegrationFail')));
            }
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            handleClose={onClose}
            title={t('SettingsIntegrations.iFoodTitle')}
            subtitle={
                <Typography variant="caption" color="textSecondary">
                    {t('SettingsIntegrations.iFoodSubtitle')}
                </Typography>
            }
            onPrimaryAction={onConnectIFood}
            primaryActionText={t('buttons.connect')}
            primaryActionDisabled={isSubmitting || !isValid}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content>
                <Typography variant="caption" color="textSecondary" sx={{ marginBottom: '8px' }}>
                    {t('SettingsIntegrations.description1')}
                </Typography>
                <Button variant="outlined" onClick={onGetAuthCode} sx={{ width: 'fit-content' }}>
                    {t('buttons.getAuthCode')}
                </Button>
                <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ marginBottom: '8px', marginTop: '24px' }}
                >
                    {t('SettingsIntegrations.description2')}
                </Typography>
                <Controller
                    control={control}
                    name="userCode"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('SettingsIntegrations.authCode')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                            sx={{ width: '200px' }}
                        />
                    )}
                />
            </Content>
        </Modal>
    );
};

export default IFoodModal;
