import { userConstants } from '@constants/user';

const initialState = {
    user: null,
    loading: true,
};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.user,
            };
        case userConstants.UPDATE_SUCCESS:
            return {
                ...state,
                user: action.user,
            };
        case userConstants.SET_USER_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case userConstants.LOGIN_FAILURE:
        case userConstants.LOGOUT:
            return {
                loading: false,
                user: null,
            };
        default:
            return state;
    }
}
