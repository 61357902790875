import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Input } from '@components/Input';
import { MultiSelect } from '@components/MultiSelect';
import { Switch } from '@components/Switch';
import { Skeleton } from '@mui/material';
import { useTranslation } from '@hooks/useTranslation';
import { instagramChannelActions } from '@actions';
import { CHANNEL } from '@constants/channels';
import { AccIntegrationName } from '@constants/integrations';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';

const Container = styled('div')({
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateAreas: '"input1" "select0" "select1"',
    gridTemplateColumns: '100%',
    '@media (max-width: 600px)': {
        gridGap: '16px',
    },
});

const FacebookForm = ({ labelsOptions, type }) => {
    const { t } = useTranslation();
    const { controlFacebook, watchFacebook, setValueFacebook } = useFormContext();
    const companies = useSelector(state => state.account?.account?.companies || []);
    const instagramData = useSelector(state => state.instagramChannel.data);
    const instagramLocationsLoading = useSelector(state => state.instagramChannel.loading);

    const dispatch = useDispatch();
    const isInstagramModal = type === CHANNEL.INSTAGRAM;
    const selectedLocationLabels = watchFacebook('locationLabels');

    useEffect(() => {
        isInstagramModal && dispatch(instagramChannelActions.getInstagramLocations());
    }, []);

    const facebookLocations = useMemo(() => {
        const filteredLocations = companies.filter(item =>
            item.profiles.some(i => i.type === AccIntegrationName.Facebook),
        );
        return selectedLocationLabels.length === labelsOptions.length
            ? filteredLocations.map(item => ({ value: item.companyId, label: item.internalName }))
            : filteredLocations
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [companies, labelsOptions, selectedLocationLabels]);

    const instagramLocations = useMemo(() => {
        return selectedLocationLabels.length === labelsOptions.length
            ? instagramData.map(item => ({ value: item.id, label: item.name }))
            : instagramData
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.id, label: item.name }));
    }, [instagramData, labelsOptions, selectedLocationLabels]);

    const companyOptions = isInstagramModal ? instagramLocations : facebookLocations;

    return (
        <>
            {!instagramLocationsLoading && (
                <Container>
                    <Controller
                        control={controlFacebook}
                        name="name"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('SettingsChannels.channelName')}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                required
                                sx={{ gridArea: 'input1' }}
                            />
                        )}
                    />
                    <Controller
                        control={controlFacebook}
                        name="locationLabels"
                        render={({ field, ref }) => (
                            <MultiSelect
                                {...field}
                                ref={ref}
                                label={t('common.locationLabels')}
                                onChange={({ target: { value } }) => {
                                    const selectedLabels = getMultiselectSelectedOptions(labelsOptions, value);
                                    setValueFacebook('locationLabels', selectedLabels, { shouldDirty: true });
                                    let availableCompanies = [];
                                    if (isInstagramModal) {
                                        availableCompanies =
                                            selectedLabels.length === labelsOptions.length
                                                ? instagramData.map(item => item.id)
                                                : instagramData
                                                      .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                                                      .map(item => item.id);
                                    } else {
                                        const filteredLocations = companies.filter(item =>
                                            item.profiles.some(i => i.type === AccIntegrationName.Facebook),
                                        );
                                        availableCompanies =
                                            selectedLabels.length === labelsOptions.length
                                                ? filteredLocations.map(item => item.companyId)
                                                : filteredLocations
                                                      .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                                                      .map(item => item.companyId);
                                    }
                                    setValueFacebook('companies', availableCompanies, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                    });
                                }}
                                options={labelsOptions}
                                style={{ gridArea: 'select0' }}
                                countable
                            />
                        )}
                    />
                    <Controller
                        control={controlFacebook}
                        name="companies"
                        render={({ field, fieldState: { error }, ref }) => (
                            <MultiSelect
                                {...field}
                                ref={ref}
                                onChange={({ target: { value } }) => {
                                    setValueFacebook(
                                        'companies',
                                        getMultiselectSelectedOptions(companyOptions, value),
                                        {
                                            shouldValidate: true,
                                            shouldDirty: true,
                                        },
                                    );
                                }}
                                options={companyOptions}
                                label={t('common.locations')}
                                style={{ gridArea: 'select1' }}
                                fullWidth
                                countable
                                required
                                error={error}
                            />
                        )}
                    />
                    {isInstagramModal && (
                        <Controller
                            control={controlFacebook}
                            name="saveInstagramReplies"
                            render={({ field, ref }) => (
                                <Switch
                                    {...field}
                                    ref={ref}
                                    checked={field.value}
                                    handleChange={event => {
                                        setValueFacebook('saveInstagramReplies', event.target.checked, {
                                            shouldDirty: true,
                                        });
                                    }}
                                    label={t('SettingsChannels.saveInstagramReplies')}
                                />
                            )}
                        />
                    )}
                </Container>
            )}
            {instagramLocationsLoading && (
                <div style={{ display: 'grid', rowGap: '32px' }}>
                    <Skeleton variant="rounded" width="90%" height={40} />
                    <Skeleton variant="rounded" width="90%" height={40} />
                    <Skeleton variant="rounded" width="50%" height={40} />
                </div>
            )}
        </>
    );
};

export default FacebookForm;
