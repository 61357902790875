import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { FormControlLabel, Grid } from '@mui/material';
import TimeframePicker from '@components/TimeframePicker';
import IconButton from '@mui/material/IconButton';
import { Switch } from '@components/Switch';
import { Select } from '@components/Select';
import Button from '@components/Button';
import { Typography } from '@components/Typography';
import { DeleteIcon } from 'assets/images/icons';
import cloneDeep from 'lodash.clonedeep';
import { useTranslation } from '@hooks/useTranslation';
import { generateTimeOptions } from '@helpers/dates';

const RowWrapper = styled(Grid)({
    minHeight: '40px',
    padding: '4px',
    '& .MuiFormControl-root': {
        paddingBottom: 0,
    },
});

const TimeSelectWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
        paddingTop: '6px',
    },
});

const HoursSelectWrapper = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
        paddingLeft: '8px !important',
        paddingBottom: '8px !important',
    },
});

const StyledTimeframePicker = styled(TimeframePicker)({
    '& input': {
        paddingRight: 0,
    },
    '@media (max-width: 600px)': {
        width: 130,
    },
});

const ALL_DAY = '24:00';

const SELECT_TYPE = {
    FROM: 'from',
    TO: 'to',
};

const SpecialHoursSelection = ({ data, onChange }) => {
    const { t } = useTranslation();

    const workingDays = useMemo(() => data, [data]);
    const timeOptions = useMemo(() => [{ value: ALL_DAY, label: t('common.24hours') }, ...generateTimeOptions()], []);

    const handleChange = (e, dayIndex, type) => {
        const newWorkingDays = cloneDeep(workingDays);
        if (type) {
            const timeItem = newWorkingDays[dayIndex].hours;
            if (timeItem.from === '00:00' && timeItem.to === ALL_DAY) {
                const nextTimeIndex = timeOptions.findIndex(i => i.value === e.target.value) + 1;
                timeItem.from = e.target.value;
                timeItem.to = timeOptions[nextTimeIndex].value;
            } else {
                timeItem[type] = e.target.value;
            }
        } else {
            newWorkingDays[dayIndex].isClosed = !e.target.checked;
        }
        onChange(
            newWorkingDays.map(day => {
                if (day.hours.from === ALL_DAY || day.hours.to === ALL_DAY) {
                    return { ...day, hours: { from: '00:00', to: '24:00' } };
                } else return day;
            }),
        );
    };

    const onChangeDatePicker = (value, dayIndex) => {
        const newWorkingDays = cloneDeep(workingDays);
        newWorkingDays[dayIndex].date = value;
        onChange(newWorkingDays);
    };

    const onDeleteNewDay = dayIndex => {
        const newWorkingDays = cloneDeep(workingDays);
        newWorkingDays.splice(dayIndex, 1);
        onChange(newWorkingDays);
    };

    const onAddNewDay = () => {
        const newWorkingDays = cloneDeep(workingDays);
        const newDay = {
            date: new Date(),
            isClosed: true,
            hours: { from: '09:00', to: '18:00' },
        };
        newWorkingDays.push(newDay);
        onChange(newWorkingDays);
    };

    return (
        <div style={{ marginBottom: '8px' }}>
            <Grid container rowSpacing={2} mt={0}>
                {workingDays.map((currentDay, dayIndex) => (
                    <RowWrapper container item spacing={{ xs: 1, md: 1 }} columns={{ xs: 9, md: 16 }} key={dayIndex}>
                        <Grid item pt="0 !important" style={{ flexBasis: '27%' }}>
                            <StyledTimeframePicker
                                value={[currentDay.date]}
                                range={false}
                                onChangeTimeframe={value => onChangeDatePicker(value, dayIndex)}
                            />
                        </Grid>
                        <Grid item md={4} pt="0 !important">
                            <FormControlLabel
                                sx={{ height: '40px', margin: 0 }}
                                control={
                                    <Switch
                                        sx={{ margin: 0 }}
                                        checked={!currentDay.isClosed}
                                        handleChange={e => handleChange(e, dayIndex)}
                                    />
                                }
                                label={
                                    <Typography variant="body2" color="textPrimary">
                                        {currentDay.isClosed ? t('common.closed') : t('common.open')}
                                    </Typography>
                                }
                            />
                        </Grid>
                        {!currentDay.isClosed && (
                            <HoursSelectWrapper item md={7} pt={'0 !important'}>
                                {currentDay.hours.from === ALL_DAY ? (
                                    <TimeSelectWrapper>
                                        <Select
                                            value={currentDay.hours.from}
                                            onChange={e => handleChange(e, dayIndex, SELECT_TYPE.FROM)}
                                            options={timeOptions}
                                            style={{ width: '100%' }}
                                            dropdownStyles={{ maxHeight: '300px' }}
                                        />
                                    </TimeSelectWrapper>
                                ) : currentDay.hours.to === ALL_DAY ? (
                                    <TimeSelectWrapper>
                                        <Select
                                            value={currentDay.hours.to}
                                            onChange={e => handleChange(e, dayIndex, SELECT_TYPE.TO)}
                                            options={timeOptions}
                                            style={{ width: '100%' }}
                                            dropdownStyles={{ maxHeight: '300px' }}
                                        />
                                    </TimeSelectWrapper>
                                ) : (
                                    <TimeSelectWrapper>
                                        <Select
                                            value={currentDay.hours.from}
                                            onChange={e => handleChange(e, dayIndex, SELECT_TYPE.FROM)}
                                            options={timeOptions}
                                            dropdownStyles={{ maxHeight: '300px' }}
                                            style={{ width: '84px' }}
                                        />
                                        <span> — </span>
                                        <Select
                                            value={currentDay.hours.to}
                                            onChange={e => handleChange(e, dayIndex, SELECT_TYPE.TO)}
                                            options={timeOptions}
                                            dropdownStyles={{ maxHeight: '300px' }}
                                            style={{ width: '84px' }}
                                        />
                                    </TimeSelectWrapper>
                                )}
                                <IconButton
                                    sx={{
                                        marginLeft: '10px',
                                        padding: '4px',
                                        '& path': {
                                            fill: 'rgba(0, 0, 0, 0.6)',
                                        },
                                    }}
                                    onClick={() => onDeleteNewDay(dayIndex)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </HoursSelectWrapper>
                        )}
                    </RowWrapper>
                ))}
            </Grid>
            <Button variant="outlined" onClick={onAddNewDay} sx={{ marginTop: '8px', marginLeft: '4px' }}>
                {t('buttons.addNewDate')}
            </Button>
        </div>
    );
};

export default SpecialHoursSelection;
