import MuiModal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import Button from '@components/Button';

const Modal = styled(MuiModal)({
    left: 280,
    zIndex: 9999,
    '@media (max-width: 1024px)': {
        left: 0,
    },
    '& .MuiBackdrop-root': {
        left: 280,
        '@media (max-width: 1024px)': {
            left: 0,
        },
    },
});

const StyledPaper = styled(Paper)({
    width: 'auto',
    maxHeight: 'calc(var(--100vh) - 32px)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    boxShadow:
        '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2) !important',
    '@media (max-width: 600px)': {
        borderRadius: 0,
        maxHeight: 'none',
    },
});

const Header = styled('div')({
    padding: 16,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
});

const Content = styled('div')({
    height: 'calc(100% - 118px)',
    overflowY: 'auto',
    overflowX: 'hidden',
});

const Footer = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
});

const FooterButtonGroup = styled('div')({
    display: 'flex',
    gap: 8,
    padding: 16,
});

const ModalBase = ({
    isOpen,
    children,
    handleClose,
    className,
    title,
    subtitle,
    primaryButton: PrimaryButton = null,
    onPrimaryAction,
    primaryActionText,
    primaryActionType = 'contained',
    primaryActionDisabled,
    onSecondaryAction,
    secondaryActionText,
    secondaryActionDisabled = false,
    onAdditionalAction,
    additionalTextButton,
    additionalButtonType = 'danger',
    additionalActionDisabled = false,
    onSecondAdditionalAction,
    secondAdditionalTextButton,
    secondAdditionalButtonType = 'outlined',
    secondAdditionalActionDisabled = false,
}) => (
    <Modal
        className={className}
        classes={{ root: 'modal-base-root' }}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <StyledPaper className="modal-base-paper">
            <Header className="modal-base-header">
                <Typography variant="h6" color="textPrimary">
                    {title}
                </Typography>
                {subtitle}
            </Header>
            <Content className="modal-base-content">{children}</Content>
            <Footer className="modal-base-footer">
                <FooterButtonGroup className="modal-base-footer-btn-group">
                    {onAdditionalAction && (
                        <Button
                            variant={additionalButtonType}
                            onClick={e => {
                                e.stopPropagation();
                                onAdditionalAction();
                            }}
                            disabled={additionalActionDisabled}
                        >
                            {additionalTextButton}
                        </Button>
                    )}
                    {onSecondAdditionalAction && (
                        <Button
                            variant={secondAdditionalButtonType}
                            onClick={e => {
                                e.stopPropagation();
                                onSecondAdditionalAction();
                            }}
                            disabled={secondAdditionalActionDisabled}
                        >
                            {secondAdditionalTextButton}
                        </Button>
                    )}
                </FooterButtonGroup>
                <FooterButtonGroup className="modal-base-footer-btn-group">
                    {onSecondaryAction && (
                        <Button
                            variant="outlined"
                            onClick={e => {
                                e.stopPropagation();
                                onSecondaryAction();
                            }}
                            disabled={secondaryActionDisabled}
                        >
                            {secondaryActionText}
                        </Button>
                    )}
                    {onPrimaryAction && (
                        <Button
                            variant={primaryActionType}
                            onClick={e => {
                                e.stopPropagation();
                                onPrimaryAction();
                            }}
                            disabled={primaryActionDisabled}
                        >
                            {primaryActionText}
                        </Button>
                    )}
                    {PrimaryButton && !onPrimaryAction ? PrimaryButton : null}
                </FooterButtonGroup>
            </Footer>
        </StyledPaper>
    </Modal>
);

export default ModalBase;
