import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@components/Typography';
import { ModalBase } from '@components/Modal';
import { Table } from '@components/Table';
import { useTranslation } from '@hooks/useTranslation';
import { CHANNEL } from '@constants/channels';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 'calc(100% - 32px)',
        height: 'calc(var(--100vh) - 32px)',
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

const GetContactsColumnNames = channelType => {
    const { t } = useTranslation();
    return [
        { key: 0, component: <Typography variant="subtitle2">{t('SendInvites.id')}</Typography> },
        {
            key: 1,
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('common.name')}
                </Typography>
            ),
        },
        ...(channelType !== CHANNEL.EMAIL
            ? [
                  {
                      key: 2,
                      component: (
                          <Typography align="right" variant="subtitle2">
                              {t('common.phoneNumber')}
                          </Typography>
                      ),
                  },
              ]
            : [
                  {
                      key: 2,
                      component: (
                          <Typography align="right" variant="subtitle2">
                              {t('common.email')}
                          </Typography>
                      ),
                  },
              ]),
    ];
};

const getContactsRows = (channelType, contacts) =>
    contacts.map(({ First_Name, Last_Name, Mobile_Number, Email }, index) => {
        const firstName = `${First_Name || '-'} ${Last_Name || '-'}`;
        return [
            {
                component: (
                    <Typography variant="subtitle2" align="left" sx={{ maxWidth: '56px' }}>
                        {index + 1}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography
                        variant="body2"
                        align="left"
                        sx={{ maxWidth: '222px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {firstName}
                    </Typography>
                ),
            },
            ...(channelType !== CHANNEL.EMAIL
                ? [
                      {
                          component: (
                              <Typography variant="body2" align="right">
                                  {Mobile_Number || '--'}
                              </Typography>
                          ),
                      },
                  ]
                : [
                      {
                          component: (
                              <Typography variant="body2" align="right">
                                  {Email || '--'}
                              </Typography>
                          ),
                      },
                  ]),
        ];
    });

const AddedContactsModal = ({ isOpen, onClose, contacts, channelType }) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            handleClose={onClose}
            title={t('SendInvites.addedContacts')}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.close')}
        >
            <TableContainer>
                <Table
                    columnNames={GetContactsColumnNames(channelType)}
                    rows={getContactsRows(channelType, contacts)}
                />
            </TableContainer>
        </Modal>
    );
};

export default AddedContactsModal;
