import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const autoreplyRulesService = {
    getAutoreplyRules,
    addOrUpdateAutoreplyRules,
    deleteAutoreplyRule,
};

function getAutoreplyRules(page, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/reviews/reply-rules?page=${page}&pageSize=${pageSize}`, requestOptions).then(
        handleResponse,
    );
}

function addOrUpdateAutoreplyRules(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/reviews/reply-rules`, requestOptions).then(handleResponse);
}

function deleteAutoreplyRule(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/reviews/reply-rules/${id}`, requestOptions).then(handleResponse);
}
