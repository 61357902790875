import { authHeader, BASE_URL, handleResponse, handleResponseBlob } from '@helpers/api';

export const feedbacksService = {
    getAll,
    markAsViewed,
    addOrUpdateTicket,
    checkNewExist,
    exportCSV,
};

function getAll(query) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(query),
    };
    return fetch(`${BASE_URL}api/feedbacks/query`, requestOptions).then(handleResponse);
}

function checkNewExist(query) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(query),
    };
    return fetch(`${BASE_URL}api/feedbacks/new`, requestOptions).then(handleResponse);
}

function markAsViewed(reviewId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(reviewId),
    };

    return fetch(`${BASE_URL}api/feedbacks/viewed`, requestOptions).then(handleResponse);
}

function addOrUpdateTicket(ticket, reviewId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(ticket),
    };

    return fetch(`${BASE_URL}api/feedbacks/${reviewId}/tickets`, requestOptions).then(handleResponse);
}

function exportCSV(query) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(query),
    };
    return fetch(`${BASE_URL}api/export/feedbacks`, requestOptions).then(handleResponseBlob);
}
