import { socialPostsConstants } from '@constants/social-posts';

const initialState = {
    data: [],
    loading: true,
    error: '',
    totalCount: 0,
    pages: 1,
};

export function socialPosts(state = initialState, action) {
    switch (action.type) {
        case socialPostsConstants.SOCIAL_POSTS_FETCH:
            return { ...state, error: '' };
        case socialPostsConstants.SOCIAL_POSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.items,
                totalCount: action.data.totalCount,
                pages: action.data.pages,
                error: '',
            };
        case socialPostsConstants.SOCIAL_POSTS_ERROR:
            return { ...state, error: action.message, loading: false };
        case socialPostsConstants.SET_SOCIAL_POSTS:
            return { ...state, data: action.data };
        default:
            return state;
    }
}
