import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from '@hooks/useTranslation';
import MetricItem from '../Common/MetricItem';
import ChannelsBreakdown from './ChannelsBreakdown';

export const MetricGrid = styled('div')(() => ({
    display: 'grid',
    gridGap: 16,
    gridTemplateAreas:
        '"metric1 metric2 metric3 metric4" "metric5 metric6 doughnut doughnut" "metric7 metric8 doughnut doughnut"',
    gridAutoColumns: 'minmax(255px, auto) minmax(255px, auto) minmax(255px, auto) minmax(255px, auto)',
    '@media (max-width: 1439px)': {
        gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
        gridTemplateAreas:
            '"metric1 metric2" "metric3 metric4" "metric5 metric6" "metric7 metric8" "doughnut doughnut"',
    },
    '@media (max-width: 600px)': {
        gridTemplateColumns: '100%',
        gridTemplateAreas: '"metric1" "metric2" "metric3" "metric4" "metric5" "metric6" "metric7" "metric8" "doughnut"',
    },
    marginBottom: 16,
}));

const ConversationsRates = memo(({ data, isLoading }) => {
    const { t } = useTranslation();

    return (
        <MetricGrid>
            <MetricItem
                title={t('AnalyticsConversations.newConversations')}
                data={{ metric: data?.conversationsCount, delta: data?.conversationsCountDelta }}
                styles={{ gridArea: 'metric1' }}
                withPercentageDelta
            />
            <MetricItem
                title={t('AnalyticsConversations.resolvedConversations')}
                data={{ metric: data?.closedConversationsCount, delta: data?.closedConversationsCountDelta }}
                styles={{ gridArea: 'metric2' }}
                withPercentageDelta
            />
            <MetricItem
                title={t('AnalyticsConversations.openConversations')}
                data={{ metric: data?.openConversationsCount, delta: 0 }}
                styles={{ gridArea: 'metric3' }}
            />
            <MetricItem
                title={t('AnalyticsConversations.unassignedConversations')}
                data={{ metric: data?.unassignedConversationsCount, delta: 0 }}
                styles={{ gridArea: 'metric4' }}
            />
            <MetricItem
                title={t('AnalyticsConversations.responseTime')}
                data={{ metric: data?.responseTime, delta: data?.responseTimeDelta }}
                styles={{ gridArea: 'metric5' }}
                withPercentageDelta
                withInvertion
            />
            <MetricItem
                title={t('AnalyticsConversations.responseRate')}
                data={{ metric: data?.responseRate, delta: data?.responseRateDelta }}
                styles={{ gridArea: 'metric6' }}
                withPercentage
                withPercentageDelta
            />
            <MetricItem
                title={t('AnalyticsConversations.resolutionTime')}
                data={{ metric: data?.resolutionTime, delta: data?.resolutionTimeDelta }}
                styles={{ gridArea: 'metric7' }}
                withPercentageDelta
                withInvertion
            />
            <MetricItem
                title={t('AnalyticsConversations.resolutionRate')}
                data={{ metric: data?.resolutionRate, delta: data?.resolutionRateDelta }}
                styles={{ gridArea: 'metric8' }}
                withPercentage
                withPercentageDelta
            />
            <ChannelsBreakdown data={data} isLoading={isLoading} />
        </MetricGrid>
    );
});

ConversationsRates.displayName = 'ConversationsRates';

export default ConversationsRates;
