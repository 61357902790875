import { pricingDrawerUiConstaints, PRICING_DRAWER_TYPE } from '@constants/pricing-drawer-ui';

const initialState = {
    isOpen: false,
    customIsOpen: false,
    type: PRICING_DRAWER_TYPE.UPGRADE,
    step: 0,
    data: {},
    loading: true,
};

export function pricingDrawerUi(state = initialState, action) {
    switch (action.type) {
        case pricingDrawerUiConstaints.SET_PRICING_DRAWER_OPEN:
            return {
                ...state,
                isOpen: action.payload.open,
                customIsOpen: false,
                type: action.payload.type,
                step: 0,
                data: {},
                loading: true,
            };
        case pricingDrawerUiConstaints.SET_CUSTOM_PRICING_DRAWER_OPEN:
            return {
                ...state,
                customIsOpen: action.payload,
                isOpen: false,
                type: PRICING_DRAWER_TYPE.CHANGE,
                step: 0,
                data: {},
                loading: true,
            };
        case pricingDrawerUiConstaints.SET_PRICING_DRAWER_STEP:
            return { ...state, step: action.payload, loading: true, data: {} };
        case pricingDrawerUiConstaints.PRICING_DRAWER_DATA_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}
