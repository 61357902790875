import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const coverManagerIntegrationService = {
    connectIntegration,
    disconnectIntegration,
    getRestaurants,
    editProfile,
};

function connectIntegration(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/integrations/cover-manager/authenticate`, requestOptions).then(handleResponse);
}

function disconnectIntegration() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/integrations/cover-manager/revoke-access`, requestOptions).then(handleResponse);
}

function getRestaurants() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/integrations/cover-manager/restaurants`, requestOptions).then(handleResponse);
}

function editProfile(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/profiles/cover-manager`, requestOptions).then(handleResponse);
}
