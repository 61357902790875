export const COMPANY_STATUS = {
    OPEN: 1,
    CLOSED: 2,
};

export const AdditionalContactType = {
    Phone: 0,
    Email: 1,
};

export const Completeness = {
    Low: 1,
    Medium: 2,
    High: 3,
};
