import { micrositesConstants } from '@constants/microsites';

const initialState = {
    data: {
        microsites: [],
        totalCount: 0,
    },
    loading: true,
};

export function microsites(state = initialState, action) {
    switch (action.type) {
        case micrositesConstants.MICROSITES_REQUEST:
            return { ...state, loading: true };
        case micrositesConstants.MICROSITES_SUCCESS:
            return { ...state, loading: false, data: action.microsites };
        case micrositesConstants.MICROSITES_ERROR:
            return { ...state, loading: false };
        default:
            return state;
    }
}
