import { authHeader, BASE_URL, handleResponse, handleResponseBlob } from '@helpers/api';

export const inboxService = {
    getHistory,
    sendMessage,
    resendMessage,
    downloadMessageImage,
    uploadImage,
    initLiveChatMessaging,
};

function getHistory(conversationId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/inbox/conversations/${conversationId}/messages`, requestOptions).then(
        handleResponse,
    );
}

function sendMessage(conversationId, message) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ conversationId, message }),
    };
    return fetch(`${BASE_URL}api/inbox/messages`, requestOptions).then(
        handleResponse,
    );
}

function resendMessage(messageId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(
        `${BASE_URL}api/inbox/messages/${messageId}:resend`,
        requestOptions,
    ).then(handleResponse);
}

function downloadMessageImage(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/assets/download-message-image?url=${url}`, requestOptions).then(handleResponseBlob);
}

function uploadImage(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + user.token,
        },
        body: data,
    };
    return fetch(`${BASE_URL}api/inbox/messages/attachments`, requestOptions).then(
        handleResponse,
    );
}

function initLiveChatMessaging(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/messaging/live-chat/create-feedback-conversation`, requestOptions).then(
        handleResponse,
    );
}
