export const userConstants = {
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',

    SET_USER_LOADING: 'SET_USER_LOADING',

    LOGOUT: 'USERS_LOGOUT',

    CLEAR_STORE: 'CLEAR_STORE',
};
