import { surveysConstants } from '@constants/surveys';

const initialState = {
    data: [],
    autoSurveys: [],
    totalCount: 0,
    loading: true,
};

export function surveys(state = initialState, action) {
    switch (action.type) {
        case surveysConstants.SURVEYS_REQUEST:
            return { ...state, loading: true };
        case surveysConstants.SURVEYS_SUCCESS:
            return { ...state, loading: false, data: action.data, totalCount: action.totalCount };
        case surveysConstants.AUTO_SURVEYS_SUCCESS:
            return { ...state, loading: false, autoSurveys: action.data };
        case surveysConstants.SURVEYS_ERROR:
            return { ...state, loading: false };
        default:
            return state;
    }
}
