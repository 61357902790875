import InfiniteScroll from 'react-infinite-scroll-component';
import { ReviewsNotSelected, ReviewsAreLoading, ReviewsNotExist } from '@features/ReviewsEmptyFeed';
import { ReviewsItemSkeleton, ReviewsSkeleton } from './ReviewsSkeleton';
import ReviewItem from './ReviewItem';
import { Container } from './styles';

const Reviews = ({
    data,
    language,
    hasMore,
    fetchData,
    feedType,
    loading,
    isMobile,
    selectedCompanies,
    onClickContactDetails,
}) => {
    const getEmptyComponent = () => {
        switch (feedType) {
            case 1:
                return <ReviewsNotSelected type="channel" />;
            case 2:
                return <ReviewsAreLoading />;
            case 3:
                return <ReviewsNotExist />;
            default:
                return null;
        }
    };

    if (loading) {
        return (
            <Container id="pl-feed-reviews">
                <ReviewsSkeleton data={data} />
            </Container>
        );
    }

    if (feedType) {
        return (
            <Container style={{ padding: '16px' }} id="pl-feed-reviews">
                {getEmptyComponent()}
            </Container>
        );
    }

    return (
        <Container id="pl-feed-reviews">
            <InfiniteScroll
                dataLength={data.length}
                next={fetchData}
                hasMore={hasMore}
                loader={<ReviewsItemSkeleton data={data} />}
                style={{ padding: '16px' }}
                scrollableTarget="pl-feed-reviews"
            >
                {data.map((item, index) => (
                    <ReviewItem
                        key={item?.reviewId || `feedback-${index}-${item?.companyId}`}
                        item={item}
                        index={index}
                        language={language}
                        isMobile={isMobile}
                        selectedCompanies={selectedCompanies}
                        onClickContactDetails={onClickContactDetails}
                    />
                ))}
            </InfiniteScroll>
        </Container>
    );
};

export default Reviews;
