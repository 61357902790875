import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import EditContactModal from '@features/EditContactModal';
import ContactInfoComponent from '@features/ContactInfo';
import Avatar from '@components/Avatar';
import { MODAL_TYPE } from '@constants/modal';
import { getInitialsParts, getFullName, getUserContacts } from '@helpers/userName';
import { useTranslation } from '@hooks/useTranslation';
import { contactsActions } from '@actions';
import { UserIcon } from 'assets/images/icons';

const StyledUserIcon = styled(UserIcon)({
    width: '16px !important',
    height: '16px !important',
    '& path': {
        fill: 'rgba(0, 0, 0, 0.38)',
        fillOpacity: 1,
    },
});

const StyledDrawer = styled(Drawer)(() => ({
    width: '360px',
    zIndex: 2,
    '& .MuiDrawer-paper': {
        borderLeft: 'none',
        width: '360px',
        top: '85px',
        right: '24px',
        overflowY: 'unset',
        '@media (max-width: 1480px)': {
            width: '270px',
        },
        '@media (max-width: 1280px)': {
            width: '200px',
        },
        '@media (max-width: 1024px)': {
            width: '100%',
            top: '108px',
            right: 0,
        },
    },
}));

const Header = styled('div')({
    display: 'grid',
    padding: '5px 11px 19px 16px',
    gridGap: '0 16px',
    gridTemplateColumns: '32px 1fr max-content 24px',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '@media (min-width: 1025px) and (max-width: 1280px)': {
        padding: '0px 8px 13px 8px',
        height: 57,
        gridTemplateColumns: '32px max-content 1fr',
        gridGap: '0 8px',
    },
    '@media (max-width: 1024px)': {
        padding: '12px 16px',
    },
});

const Content = styled('div')({
    padding: 16,
    height: 'calc(var(--100vh) - 153px)',
    overflowY: 'auto',
    '@media (min-width: 1025px) and (max-width: 1280px)': {
        padding: 8,
    },
    '@media (max-width: 1024px)': {
        height: 'calc(var(--100vh) - 165px)',
    },
});

const StyledCloseIcon = styled(CloseIcon)({
    fill: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
    '@media (min-width: 1025px) and (max-width: 1280px)': {
        marginLeft: 'auto',
    },
});

const ContactInfo = ({ setSelectedConversation = null }) => {
    const [modal, setModal] = useState({ open: false, type: MODAL_TYPE.EDIT });

    const { contact, contactOpen } = useSelector(state => state.contacts);
    const isSmallScreen = useMediaQuery('@media (min-width: 1025px) and (max-width: 1280px)');
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onClose = () => dispatch(contactsActions.setContactOpen(false));

    return (
        <>
            <StyledDrawer variant="persistent" anchor="right" open={contactOpen}>
                <Header>
                    <Avatar
                        size="medium"
                        fullName={getFullName(contact?.firstName, contact?.lastName)}
                        label={getInitialsParts(contact?.firstName, contact?.lastName)}
                        icon={<StyledUserIcon />}
                    />
                    {!isSmallScreen && (
                        <Typography
                            variant="h6"
                            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {getUserContacts(
                                {
                                    firstName: contact.firstName,
                                    lastName: contact.lastName,
                                    phoneNumber: contact.phoneNumber,
                                    email: contact.email,
                                },
                                '-',
                            )}
                        </Typography>
                    )}
                    <Button variant="outlined" onClick={() => setModal(prev => ({ ...prev, open: true }))}>
                        {t('buttons.edit')}
                    </Button>
                    <StyledCloseIcon onClick={onClose} />
                </Header>
                <Content>
                    <ContactInfoComponent
                        contact={contact}
                        resizable
                        setSelectedConversation={setSelectedConversation}
                    />
                </Content>
            </StyledDrawer>
            {modal.open ? (
                <EditContactModal
                    modal={modal}
                    onClose={() => setModal({ open: false, type: MODAL_TYPE.EDIT })}
                    withDeleteContact={false}
                    withRefetchContacts={false}
                    withRefetchConversations
                    withUpdateConversations
                    setSelectedConversation={setSelectedConversation}
                />
            ) : null}
        </>
    );
};

export default ContactInfo;
