import { campaignsConstants } from '@constants/campaigns';
import { campaignsService } from '@services';

function request() {
    return { type: campaignsConstants.CAMPAIGNS_FETCH };
}

function success(data) {
    return { type: campaignsConstants.CAMPAIGNS_SUCCESS, data };
}

function failure(message) {
    return { type: campaignsConstants.CAMPAIGNS_ERROR, message };
}

const get = (page = 0, pageSize = 10, type) => {
    return dispatch => {
        dispatch(request());
        campaignsService.get(page, pageSize, type).then(
            data => dispatch(success(data)),
            () => dispatch(failure()),
        );
    };
};

const setCampaigns = data => {
    return { type: campaignsConstants.SET_CAMPAIGNS, data };
};

export const campaignsActions = {
    get,
    setCampaigns,
};
