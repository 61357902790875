import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { useTranslation } from '@hooks/useTranslation';
import MetricItem from './MetricItem';

const CardsWrapper = styled('div')({
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    height: 'calc(100% - 57px)',
    '@media (max-width: 375px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
    },
});

const Social = () => {
    const { t } = useTranslation();
    const { socialPosts } = useSelector(state => state.dashboard.dashboard);

    const data = [
        {
            id: 0,
            title: t('common.impressions'),
            value: socialPosts.impressions,
            subvalue: socialPosts.impressionsDelta,
        },
        {
            id: 2,
            title: t('common.reach'),
            value: socialPosts.reach,
            subvalue: socialPosts.reachDelta,
        },
        { id: 3, title: t('common.engagement'), value: socialPosts.engagement, subvalue: socialPosts.engagementDelta },
    ];

    return (
        <CardsWrapper>
            {data.map(item => (
                <MetricItem
                    key={item.id}
                    value={item.value}
                    subvalue={item.subvalue}
                    title={item.title}
                    withSign={item.withSign}
                />
            ))}
        </CardsWrapper>
    );
};

export default Social;
