import DoneIcon from '@mui/icons-material/Done';
import { Typography } from '@components/Typography';
import Card from '@components/Card';
import { BILLING_TYPE_MAP, BILLING_TIME_MAP } from '@constants/billing';
import { getPricingPlans } from '@helpers/billing';
import { useTranslation } from '@hooks/useTranslation';

const labelStyles = { color: 'rgba(0, 0, 0, 0.6)', marginRight: 8, display: 'inline-block' };

const labelWrapperStyles = { marginBottom: '4px', display: 'flex', alignItems: 'center', gap: '8px' };

const tickStyles = { width: 14, height: 14, color: '#1F4C5C' };

const PricingCard = ({
    pricingType,
    billingType,
    setBillingType,
    billingTimePlan,
    companyCounter,
    usersCounter,
    locale,
}) => {
    const { t } = useTranslation();

    const PRICING_PLAN = getPricingPlans(locale);
    const SELECTED_PLAN = PRICING_PLAN[billingTimePlan];
    const PLAN_PRICE = SELECTED_PLAN.price[pricingType.name];
    const ADDITIONAL_USERS_PRICE = SELECTED_PLAN.users * Math.max(0, usersCounter - pricingType.users);

    return (
        <Card
            type="radio"
            selected={pricingType.name === billingType}
            title={pricingType.name}
            value={billingType}
            index={pricingType.name}
            setValue={setBillingType}
        >
            {pricingType.name !== BILLING_TYPE_MAP.ENTERPRISE && (
                <>
                    <Typography variant="caption" sx={labelWrapperStyles}>
                        <DoneIcon sx={tickStyles} />
                        <span style={labelStyles}>
                            {pricingType.users} {t('common.users')}
                        </span>
                    </Typography>
                    <Typography variant="caption" sx={labelWrapperStyles}>
                        <DoneIcon sx={tickStyles} />
                        <span style={labelStyles}>{t('SettingsGeneral.onlineListings')}</span>
                    </Typography>
                    <Typography variant="caption" sx={labelWrapperStyles}>
                        <DoneIcon sx={tickStyles} />
                        <span style={labelStyles}>{t('SettingsGeneral.reviewManagement')}</span>
                    </Typography>
                    <Typography variant="caption" sx={labelWrapperStyles}>
                        <DoneIcon sx={tickStyles} />
                        <span style={labelStyles}>{t('SettingsGeneral.inboxAndLiveChat')}</span>
                    </Typography>
                    <Typography variant="caption" sx={labelWrapperStyles}>
                        <DoneIcon sx={tickStyles} />
                        <span style={labelStyles}>{t('SettingsGeneral.socialPosting')}</span>
                    </Typography>
                    <Typography variant="caption" sx={labelWrapperStyles}>
                        <DoneIcon sx={tickStyles} />
                        <span style={labelStyles}>{t('SettingsGeneral.microsites')}</span>
                    </Typography>
                    <Typography variant="caption" sx={labelWrapperStyles}>
                        <DoneIcon sx={tickStyles} />
                        <span style={labelStyles}>{t('SettingsGeneral.analytics')}</span>
                    </Typography>
                    {pricingType.name === BILLING_TYPE_MAP.PROFESSIONAL && (
                        <>
                            <Typography variant="caption" sx={labelWrapperStyles}>
                                <DoneIcon sx={tickStyles} />
                                <span style={labelStyles}>{t('SettingsGeneral.surveysAndTickets')}</span>
                            </Typography>
                            <Typography variant="caption" sx={labelWrapperStyles}>
                                <DoneIcon sx={tickStyles} />
                                <span style={labelStyles}>{t('SettingsGeneral.campaigns')}</span>
                            </Typography>
                        </>
                    )}
                    <Typography variant="caption" sx={{ display: 'flex', marginBottom: '4px', marginTop: '8px' }}>
                        <span style={{ ...labelStyles, width: 118 }}>{t('SettingsGeneral.pricePerCompany')}</span>
                        <Typography variant="body2">
                            {SELECTED_PLAN.symbol}
                            {new Intl.NumberFormat('en-US').format(PLAN_PRICE)}
                        </Typography>
                    </Typography>
                    <Typography variant="caption" sx={{ display: 'flex', marginBottom: '4px' }}>
                        <span style={{ ...labelStyles, width: 118 }}>{t('SettingsGeneral.additionalUsers')}:</span>
                        <Typography variant="body2">
                            {SELECTED_PLAN.symbol}
                            {new Intl.NumberFormat('en-US').format(ADDITIONAL_USERS_PRICE)}
                        </Typography>
                    </Typography>
                    <Typography variant="caption" sx={{ display: 'flex' }}>
                        <span style={{ ...labelStyles, width: 118 }}>{t('SettingsGeneral.totalPrice')}:</span>
                        <Typography variant="body2" sx={{ fontWeight: '500 !important' }}>
                            {SELECTED_PLAN.symbol}
                            {new Intl.NumberFormat('en-US').format(
                                PLAN_PRICE * companyCounter + ADDITIONAL_USERS_PRICE,
                            )}
                            /
                            {billingTimePlan === BILLING_TIME_MAP.MONTHLY
                                ? t('SettingsGeneral.month')
                                : t('SettingsGeneral.year')}
                        </Typography>
                    </Typography>
                </>
            )}
            {pricingType.name === BILLING_TYPE_MAP.ENTERPRISE && (
                <Typography variant="caption" sx={{ display: 'flex' }}>
                    <span style={{ ...labelStyles, width: 118 }}>{t('SettingsGeneral.totalPrice')}:</span>
                    <Typography variant="body2" sx={{ width: 'calc(100% - 118px)' }}>
                        {t('SettingsGeneral.contactSales')}
                    </Typography>
                </Typography>
            )}
        </Card>
    );
};

export default PricingCard;
