import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const CssMuiButton = styled(MuiButton)({
    '&.MuiButton-text': {
        display: 'none',
    },
});
const CssFormControlLabel = styled(FormControlLabel, {
    shouldForwardProp: prop => prop !== 'isActive',
})(({ isActive }) => ({
    padding: '8px 12px',
    borderRadius: 4,
    backgroundColor: isActive ? '#fff' : 'inherit',
    color: isActive ? '#1F4C5C' : 'rgba(0, 0, 0, 0.6)',
    border: isActive ? '1px solid rgba(0, 0, 0, 0.12)' : '1px solid inherit',
    flex: 1,
    marginLeft: '0',
    marginRight: '0',
    justifyContent: 'center',
    '&:hover': {
        backgroundColor: isActive ? '#fff' : 'rgba(0, 0, 0, 0.04)',
    },
    '.MuiTypography-root': {
        fontFamily: 'Rubik, sans-serif',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.1px',
    },
}));

export const RadioButtonsGroup = ({ options, value, onChange, name, className, id, disabled }) => {
    return (
        <FormControl
            component="fieldset"
            sx={{ width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.04)', borderRadius: '4px' }}
            className={className}
            id={id}
        >
            <RadioGroup name={name} row sx={{ width: '100%' }}>
                {options.map(option => (
                    <CssFormControlLabel
                        isActive={value === option.value}
                        key={option.value}
                        value={option.value}
                        control={<CssMuiButton onClick={() => onChange(option.value)} />}
                        label={option.label}
                        disabled={disabled}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
