import { autoreplyRulesConstants } from '@constants/autoreply-rules';
import { autoreplyRulesService } from '@services';

export const autoreplyRulesActions = {
    get,
};

function get(page = 0, pageSize = 10) {
    return dispatch => {
        dispatch(request());
        return autoreplyRulesService.getAutoreplyRules(page, pageSize).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error)),
        );
    };
}

function request() {
    return { type: autoreplyRulesConstants.AUTOREPLY_RULES_REQUEST };
}

function success(data) {
    return { type: autoreplyRulesConstants.AUTOREPLY_RULES_SUCCESS, data };
}

function failure(message) {
    return { type: autoreplyRulesConstants.AUTOREPLY_RULES_ERROR, message };
}
