import { profilesConstants } from '@constants/profiles';
import { profilesService } from '@services';

function request() {
    return { type: profilesConstants.PROFILES_FETCH };
}

function success(data) {
    return { type: profilesConstants.PROFILES_SUCCESS, data };
}

function failure(message) {
    return { type: profilesConstants.PROFILES_ERROR, message };
}

const get = (query, page = 0, pageSize = 10) => {
    return dispatch => {
        dispatch(request());
        profilesService.get(query, page, pageSize).then(
            data => dispatch(success(data)),
            () => dispatch(failure()),
        );
    };
};

const setSyncStatus = data => {
    return { type: profilesConstants.SET_SYNC_STATUS, data };
};

const setProfiles = data => {
    return { type: profilesConstants.SET_PROFILES, data };
};

export const profilesActions = {
    get,
    setSyncStatus,
    setProfiles,
};
