import { inboxTemplatesConstants } from '@constants/inbox-templates';

const initialState = {
    data: [],
    loading: true,
    totalCount: 0,
    pages: 1,
};

export function inboxTemplates(state = initialState, action) {
    switch (action.type) {
        case inboxTemplatesConstants.INBOX_TEMPLATES_REQUEST:
            return { ...state, loading: true };
        case inboxTemplatesConstants.INBOX_TEMPLATES_SUCCESS:
            return {
                loading: false,
                data: action.data.items,
                totalCount: action.data.totalCount,
                pages: action.data.pages,
            };
        case inboxTemplatesConstants.INBOX_ALL_ACTIVE_TEMPLATES_SUCCESS:
            return { ...state, data: action.data.items };
        default:
            return state;
    }
}
