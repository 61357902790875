import { profilesMediaConstants } from '@constants/profiles-media';
import { profilesMediaService } from '@services';

function request() {
    return { type: profilesMediaConstants.PROFILES_MEDIA_FETCH };
}

function success(data) {
    return { type: profilesMediaConstants.PROFILES_MEDIA_SUCCESS, data };
}

function failure(message) {
    return { type: profilesMediaConstants.PROFILES_MEDIA_ERROR, message };
}

function successLoadMore(data) {
    return { type: profilesMediaConstants.PROFILES_MEDIA_LOAD_MORE_SUCCESS, data };
}

const get = (query, take, skip) => {
    return dispatch => {
        dispatch(request());
        profilesMediaService.get(query, take, skip).then(
            data => dispatch(success(data)),
            () => dispatch(failure()),
        );
    };
};

const loadMore = (query, take, skip) => {
    return dispatch => {
        profilesMediaService.get(query, take, skip).then(
            data => dispatch(successLoadMore(data)),
            () => dispatch(failure()),
        );
    };
};

function set(data) {
    return { type: profilesMediaConstants.PROFILES_MEDIA_SET, data };
}

export const profilesMediaActions = {
    get,
    set,
    loadMore,
};
