import phone from 'phone';
import { PHONE_MASKS } from '@constants/phone';

export const formatPhoneNumber = number => {
    const { isValid, countryIso2, countryCode } = phone(number, { country: null, validateMobilePrefix: false });
    if (!isValid) return number;
    const mask = PHONE_MASKS[countryIso2];

    const numberPart = number.slice(countryCode.length);
    let formattedNumber = '';
    let maskIndex = 0;

    for (let i = 0; i < mask.length; i++) {
        if (mask[i] === '.') {
            formattedNumber += numberPart[maskIndex++] || '';
        } else if (mask[i] === ' ') {
            formattedNumber += mask[i];
        } else if (mask[i] === '(' || mask[i] === ')' || mask[i] === '-') {
            formattedNumber += mask[i];
        }
    }
    return `${countryCode} ${formattedNumber}`;
};
