import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

function get() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/lookups/countries`, requestOptions).then(handleResponse);
}

export const countriesService = {
    get,
};
