import { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@components/Autocomplete';
import { DeleteIcon } from 'assets/images/icons';
import { googleActions } from '@actions';
import { useThrottleValue } from '@hooks/useThrottleValue';
import { useTranslation } from '@hooks/useTranslation';

const Container = styled('div')({
    display: 'grid',
    rowGap: '8px',
    gridTemplateColumns: 'calc(90% - 12px)',
    '@media (max-width: 600px)': {
        gridTemplateColumns: '90%',
        gridGap: '16px',
    },
});

const StyledIconButton = styled(IconButton)({
    position: 'absolute',
    right: '-40px',
    top: '5px',
    padding: '4px',
    '& svg path': {
        fill: 'rgba(0, 0, 0, 0.6)',
    },
});

const CategoriesForm = () => {
    const { t } = useTranslation();
    const { fields, control, setValue, remove, append, isSubmitting } = useFormContext();
    const { searchedCategories } = useSelector(state => state.google);

    const [searches, setSearches] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const primaryCategory = { id: 'primary', search: '' };
        const additionalCategories = fields.map(field => ({ id: field.id, search: '' }));
        setSearches([primaryCategory, ...additionalCategories]);
    }, [fields]);

    const setSearch = (id, value) => {
        setSearches(prev => prev.map(searchObj => (searchObj.id === id ? { ...searchObj, search: value } : searchObj)));
    };

    const firstNonEmptySearch = useMemo(
        () => searches.find(search => search.search.length > 0)?.search ?? '',
        [searches],
    );
    const throttledSearch = useThrottleValue(firstNonEmptySearch, 1000);

    useEffect(() => {
        if (throttledSearch.length === 0) {
            dispatch(googleActions.resetSearchedCategories());
            return;
        }
        if (throttledSearch.length >= 2) {
            dispatch(googleActions.getSearchedCategories(throttledSearch));
        }
    }, [throttledSearch]);

    return (
        <Container>
            <div style={{ position: 'relative' }}>
                <Controller
                    control={control}
                    name="primaryCategory"
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                        <Autocomplete
                            {...field}
                            inputRef={ref}
                            label={t('Listings.primaryCategory')}
                            options={searchedCategories}
                            value={field.value}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            optionLabelProp="displayName"
                            readOnly={isSubmitting}
                            inputProps={{
                                value: searches.find(search => search.id === 'primary')?.search ?? '',
                                onChange: e => setSearch('primary', e.target.value),
                                style: { display: field?.value?.length > 0 ? 'none' : 'block' },
                            }}
                            onChangeAutoComplete={items => {
                                setValue('primaryCategory', items.length ? items : [], {
                                    shouldDirty: true,
                                });
                                setSearch('primary', '');
                            }}
                            required
                            error={error}
                            sx={{ marginBottom: '16px' }}
                        />
                    )}
                />

                {fields.length < 9 && (
                    <StyledIconButton sx={{ right: '-42px', top: '3px' }} onClick={() => append({ category: '' })}>
                        <AddIcon />
                    </StyledIconButton>
                )}
            </div>
            {fields.map((formField, index) => (
                <div style={{ position: 'relative' }} key={formField.id}>
                    <Controller
                        control={control}
                        name={`additionalCategories[${index}].category`}
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                {...field}
                                inputRef={ref}
                                label={t('Listings.secondaryCategory')}
                                options={searchedCategories}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                optionLabelProp="displayName"
                                readOnly={isSubmitting}
                                inputProps={{
                                    value: searches.find(search => search.id === formField.id)?.search ?? '',
                                    onChange: e => setSearch(formField.id, e.target.value),
                                    style: { display: field?.value?.length > 0 ? 'none' : 'block' },
                                }}
                                onChangeAutoComplete={items => {
                                    setValue(`additionalCategories[${index}].category`, items.length ? items : [], {
                                        shouldDirty: true,
                                    });
                                    setSearch(formField.id, '');
                                }}
                                sx={{ marginBottom: '16px' }}
                            />
                        )}
                    />
                    <StyledIconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                    </StyledIconButton>
                </div>
            ))}
        </Container>
    );
};

export default CategoriesForm;
