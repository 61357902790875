import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';
import { useTranslation } from '@hooks/useTranslation';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 830,
        maxWidth: 'calc(100% - 32px)',
        height: 680,
        '@media (max-width: 900px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
});

const Container = styled('div')({
    border: 'none',
    zIndex: 1202,
    overflowY: 'auto',
    padding: 16,
});

const ZapierModal = ({ isOpen, handleClose }) => {
    const { t } = useTranslation();
    const user = useSelector(state => state.authentication.user);

    return (
        <Modal
            isOpen={isOpen}
            handleClose={handleClose}
            title={t('common.zapierIntegration')}
            onSecondaryAction={handleClose}
            secondaryActionText={t('buttons.close')}
        >
            <Container onClick={e => e.stopPropagation()}>
                <zapier-full-experience
                    sign-up-email={user.email}
                    sign-up-first-name={user.firstName}
                    sign-up-last-name={user.lastName || ''}
                    client-id="aGtLvc2IMsF2wBzwtQCqsgSkrPCVzon8lxcyLVF8"
                    theme="light"
                    intro-copy-display="show"
                    app-search-bar-display="show"
                    app-limit={6}
                    zap-limit={3}
                    zap-style="row"
                    zap-call-to-action-display="show"
                    zap-create-from-scratch-display="hide"
                />
            </Container>
        </Modal>
    );
};

export default ZapierModal;
