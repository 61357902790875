import { CAMPAIGN_TYPE } from '@constants/campaigns';
import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

function get(page, pageSize, type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(
        `${BASE_URL}api/messaging/campaigns/${
            type === CAMPAIGN_TYPE.ONETIME ? 'one-time' : 'ongoing'
        }?page=${page}&pageSize=${pageSize}`,
        requestOptions,
    ).then(handleResponse);
}

function cancelCampaign(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/messaging/campaigns/${id}/cancel`, requestOptions).then(handleResponse);
}

function pauseCampaign(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/messaging/campaigns/${id}/pause`, requestOptions).then(handleResponse);
}

function enableCampaign(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/messaging/campaigns/${id}/resume`, requestOptions).then(handleResponse);
}

function createOrUpdateCampaign(data, type) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(
        `${BASE_URL}api/messaging/campaigns/${type === CAMPAIGN_TYPE.ONETIME ? 'one-time' : 'ongoing'}`,
        requestOptions,
    ).then(handleResponse);
}

function getSelectedContactsNumber(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/messaging/campaigns/getContactsCount`, requestOptions).then(handleResponse);
}

function deleteCampaign(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/messaging/campaigns/${id}`, requestOptions).then(handleResponse);
}

function uploadEmailCampaignImage(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + user.token,
        },
        body: data,
    };
    return fetch(`${BASE_URL}api/messaging/campaigns/upload-asset`, requestOptions).then(handleResponse);
}

export const campaignsService = {
    get,
    cancelCampaign,
    createOrUpdateCampaign,
    getSelectedContactsNumber,
    pauseCampaign,
    enableCampaign,
    deleteCampaign,
    uploadEmailCampaignImage,
};
