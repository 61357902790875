import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { ApiIcon, CoverManagerIcon, VTEXIcon } from 'assets/images/integrations';
import zapier from 'assets/images/integrations/zapier.png';
import { EmailIcon } from 'assets/images/icons';
import { AutoSurveysIntegrationName, ApiIntegrationLinks } from '@constants/integrations';
import { useTranslation } from '@hooks/useTranslation';
import AutoSurveyIntegrationModal from '@features/AutoSurveyIntegration/AutoSurveyIntegrationModal';
import ZapierModal from '@features/ZapierModal';
import EmailIntegrationModal from '@features/EmailIntegrationModal';
import { surveysActions, channelsActions } from '@actions';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

const ListWrapper = styled('div')({
    maxWidth: 600,
    margin: '42px 0',
    padding: 16,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    '@media (max-width: 1024px)': {
        margin: '24px 0',
    },
});

const CardsWrapper = styled('div')({
    maxWidth: 600,
    marginTop: 24,
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(auto-fill, 270px)',
    '@media (max-width: 1024px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(270px, 1fr))',
    },
});

const Card = styled('div')({
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const AutomatedSurveys = () => {
    const { t } = useTranslation();
    const account = useSelector(state => state.account?.account);
    const language = useSelector(state => state.authentication.user?.language);
    const [zapierOpen, setZapierOpen] = useState(false);
    const [isEmailIntegrationOpen, setEmailIntegrationOpen] = useState(false);
    const [modal, setModal] = useState({
        step: 1,
        open: false,
        type: null,
    });
    const history = useHistory();
    const dispatch = useDispatch();

    const checkConnectedIntegration = id => account?.integrations.find(i => i.type === id)?.isActive;

    const ITEMS = [
        { id: AutoSurveysIntegrationName.Email, name: 'Email', button: 'configure', isActive: true },
        { id: AutoSurveysIntegrationName.Zapier, name: 'Zapier', button: 'configure', isActive: true },
        {
            id: AutoSurveysIntegrationName['API integration'],
            name: 'API integration',
            button: 'documentation',
            isActive: true,
        },
        {
            id: AutoSurveysIntegrationName.CoverManager,
            name: 'CoverManager',
            button: 'configure',
            isActive: checkConnectedIntegration(AutoSurveysIntegrationName.CoverManager),
        },
        {
            id: AutoSurveysIntegrationName.VTEX,
            name: 'VTEX',
            button: 'configure',
            isActive: checkConnectedIntegration(AutoSurveysIntegrationName.VTEX),
        },
        {
            id: AutoSurveysIntegrationName['Other integrations'],
            name: 'Other integrations',
            button: 'viewMore',
            isActive: true,
        },
    ];

    const ICONS = {
        [AutoSurveysIntegrationName['API integration']]: <ApiIcon style={{ height: 40, width: 40 }} />,
        [AutoSurveysIntegrationName['Other integrations']]: <ApiIcon style={{ height: 40, width: 40 }} />,
        [AutoSurveysIntegrationName.Zapier]: <img src={zapier} alt="zapier" style={{ height: 40 }} />,
        [AutoSurveysIntegrationName.VTEX]: <VTEXIcon height={40} width={40} />,
        [AutoSurveysIntegrationName.CoverManager]: <CoverManagerIcon height={40} width={40} />,
        [AutoSurveysIntegrationName.Email]: (
            <EmailIcon style={{ height: 40, width: 40, fill: 'rgba(31, 76, 92, 1)' }} />
        ),
    };

    useEffect(() => {
        dispatch(channelsActions.get());
        dispatch(surveysActions.getAutoSurveys());
        dispatch(surveysActions.getAll());
    }, [dispatch]);

    const goToIntegrations = () => history.push('/account-settings/integrations');

    const onConnectIntegration = type => {
        switch (type) {
            case AutoSurveysIntegrationName['API integration']:
                window.open(ApiIntegrationLinks[language]);
                break;
            case AutoSurveysIntegrationName['Other integrations']:
                goToIntegrations();
                break;
            case AutoSurveysIntegrationName.Email:
                setEmailIntegrationOpen(true);
                break;
            case AutoSurveysIntegrationName.CoverManager:
                setModal(prev => ({ ...prev, open: true, type: AutoSurveysIntegrationName.CoverManager }));
                break;
            case AutoSurveysIntegrationName.VTEX:
                setModal(prev => ({ ...prev, open: true, type: AutoSurveysIntegrationName.VTEX }));
                break;
            default:
                setZapierOpen(true);
        }
    };

    return (
        <Container>
            <div style={{ maxWidth: 600 }}>
                <Typography variant="h6">{t('FeedbackAutoSurveys.title')}</Typography>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '4px' }}>
                    {t('FeedbackAutoSurveys.subtitle')}
                </Typography>
            </div>
            <ListWrapper>
                <Typography variant="h6">{t('FeedbackAutoSurveys.listTitle')}</Typography>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '8px' }}>
                    1. {t('FeedbackAutoSurveys.listItem1')}
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '8px' }}>
                    2. {t('FeedbackAutoSurveys.listItem2')}
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '8px' }}>
                    3. {t('FeedbackAutoSurveys.listItem3')}
                </Typography>
            </ListWrapper>
            <Typography variant="h6">{t('FeedbackAutoSurveys.integrations')}</Typography>
            <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '4px' }}>
                {t('FeedbackAutoSurveys.integrationsSubtitle')}
            </Typography>
            <CardsWrapper>
                {ITEMS.map(item =>
                    item.isActive ? (
                        <Card key={item.id}>
                            {ICONS[item.id]}
                            <Typography
                                variant="h6"
                                sx={{ color: 'rgba(0, 0, 0, 0.87)', marginTop: '8px', marginBottom: '18px' }}
                            >
                                {item.name}
                            </Typography>
                            <Button
                                variant="outlined"
                                sx={{ marginTop: 'auto' }}
                                onClick={() => onConnectIntegration(item.id)}
                            >
                                {t(`buttons.${item.button}`)}
                            </Button>
                        </Card>
                    ) : null,
                )}
            </CardsWrapper>
            <ZapierModal isOpen={zapierOpen} handleClose={() => setZapierOpen(false)} />
            {isEmailIntegrationOpen && (
                <EmailIntegrationModal isOpen={isEmailIntegrationOpen} setModal={setEmailIntegrationOpen} />
            )}
            {modal.open && <AutoSurveyIntegrationModal modal={modal} setModal={setModal} />}
        </Container>
    );
};

export default AutomatedSurveys;
