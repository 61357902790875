import { autoreplyRulesConstants } from '@constants/autoreply-rules';

const initialState = {
    data: [],
    loading: true,
    error: '',
    totalCount: 0,
    pages: 1,
};

export function autoreplyRules(state = initialState, action) {
    switch (action.type) {
        case autoreplyRulesConstants.AUTOREPLY_RULES_REQUEST:
            return { ...state, loading: true, error: '' };
        case autoreplyRulesConstants.AUTOREPLY_RULES_SUCCESS:
            return {
                loading: false,
                data: action.data.items,
                totalCount: action.data.totalCount,
                pages: action.data.pages,
                error: '',
            };
        case autoreplyRulesConstants.AUTOREPLY_RULES_ERROR:
            return { ...state, error: action.message };
        default:
            return state;
    }
}
