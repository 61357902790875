import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@components/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { Select } from '@components/Select';
import { Switch } from '@components/Switch';
import { Typography } from '@components/Typography';
import TextEditor from '@components/TextEditor';
import { useTranslation } from '@hooks/useTranslation';
import { AITaskType, AI_TASK_TYPE } from '@constants/ai-assistant';
import { parseTipTapJSONToPlainText } from '@helpers/text-editor';

const FollowUpSelect = styled(Select)({
    width: '75px',
    '& .MuiFormControl-root': {
        padding: '0 !important',
    },
});

export const OverSurveyProtectionSelect = styled(Select)({
    maxWidth: 250,
    marginTop: '24px !important',
    '@media (max-width: 600px)': {
        maxWidth: 'none',
    },
    '& .MuiInputBase-adornedEnd': {
        paddingRight: 0,
    },
});

const SelectSecondaryIcon = styled(IconButton)({
    marginRight: 0,
    position: 'absolute',
    left: -60,
    padding: 8,
});

const followUpOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
];

const Message = ({ isView, selectedSurvey, preview, setPreview, location }) => {
    const { t } = useTranslation();
    const { control, setValue, getValues } = useFormContext();

    const protectionOptions = useMemo(
        () => [
            { value: 0, label: t('common.none') },
            { value: 1, label: t('FeedbackSurveys.day') },
            { value: 2, label: t('FeedbackSurveys.days3') },
            { value: 3, label: t('FeedbackSurveys.week') },
            { value: 4, label: t('FeedbackSurveys.weeks2') },
            { value: 5, label: t('FeedbackSurveys.month') },
            { value: 6, label: t('FeedbackSurveys.months3') },
            { value: 7, label: t('FeedbackSurveys.months6') },
            { value: 8, label: t('FeedbackSurveys.year') },
        ],
        [t],
    );

    return (
        <>
            <Controller
                control={control}
                name="message"
                render={({ field, fieldState: { error }, ref }) => (
                    <TextEditor
                        {...field}
                        ref={ref}
                        label={t('FeedbackSurveys.messageTemplate')}
                        initialValue={selectedSurvey?.message || ''}
                        onChange={text =>
                            setValue('message', parseTipTapJSONToPlainText(text), {
                                shouldValidate: true,
                                shouldDirty: true,
                            })
                        }
                        editable={!isView}
                        error={error}
                        aiAssistant={{
                            additionalOptions: [
                                {
                                    text: t('AIAssistant.writeTemplate'),
                                    type: AITaskType.GenerateSurveyTemplateMessage,
                                    taskType: AI_TASK_TYPE.CONTEXTUAL,
                                    companyId: location,
                                },
                            ],
                        }}
                    />
                )}
            />
            <Controller
                control={control}
                name="followUpEnable"
                render={({ field, ref }) => (
                    <Switch
                        {...field}
                        ref={ref}
                        checked={getValues('followUpEnable')}
                        handleChange={event => {
                            setValue('followUpEnable', event.target.checked, { shouldDirty: true });
                            setPreview(prev => ({ ...prev, followUpEnable: event.target.checked }));
                        }}
                        label={t('FeedbackSurveys.followUpLabel')}
                        disabled={isView}
                    />
                )}
            />
            <Typography variant="caption" color="textSecondary">
                {t('FeedbackSurveys.followUpDescription')}
            </Typography>
            {preview.followUpEnable && (
                <>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 16, margin: '16px 0' }}>
                        <Typography variant="body2">{t('FeedbackSurveys.wait')}</Typography>
                        <Controller
                            control={control}
                            name="followUpDelay"
                            render={({ field, ref }) => (
                                <FollowUpSelect
                                    {...field}
                                    ref={ref}
                                    label={t('common.delay')}
                                    options={followUpOptions}
                                    disabled={isView}
                                />
                            )}
                        />
                        <Typography variant="body2">{t('FeedbackSurveys.days')}</Typography>
                    </div>
                    <Controller
                        control={control}
                        name="followUpText"
                        render={({ field, fieldState: { error }, ref }) => (
                            <TextEditor
                                {...field}
                                ref={ref}
                                label={t('FeedbackSurveys.followUpMessage')}
                                initialValue={selectedSurvey?.followUpText || ''}
                                onChange={text =>
                                    setValue('followUpText', parseTipTapJSONToPlainText(text), {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                    })
                                }
                                editable={!isView}
                                error={error}
                                aiAssistant={{
                                    additionalOptions: [
                                        {
                                            text: t('AIAssistant.writeTemplate'),
                                            type: AITaskType.GenerateSurveyTemplateFollowUp,
                                            taskType: AI_TASK_TYPE.CONTEXTUAL,
                                            companyId: location,
                                        },
                                    ],
                                }}
                            />
                        )}
                    />
                </>
            )}
            <Controller
                control={control}
                name="overSurvey"
                render={({ field, ref }) => (
                    <OverSurveyProtectionSelect
                        {...field}
                        ref={ref}
                        label={t('FeedbackSurveys.protectionLabel')}
                        options={protectionOptions}
                        disabled={isView}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{ position: 'relative' }}>
                                    <SelectSecondaryIcon>
                                        <Tooltip title={t('FeedbackSurveys.protectionTooltip')} placement="right">
                                            <HelpIcon sx={{ fontSize: '16px' }} />
                                        </Tooltip>
                                    </SelectSecondaryIcon>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
        </>
    );
};

export default Message;
