import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';
import { MultiSelect } from '@components/MultiSelect';

export const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 564,
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        padding: 16,
        '& .MuiFormControl-root': {
            '& label': {
                textTransform: 'capitalize',
            },
        },
    },
});

export const Content = styled('div')({
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateAreas: '"input1" "select0" "select1" "select2"',
    gridTemplateColumns: '100%',
});

export const StyledMultiSelect = styled(MultiSelect)({
    '& .MuiSelect-select': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 3,
        paddingTop: '10px !important',
        paddingBottom: '10px !important',
    },
});

export const CheckboxWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: 40,
    '& .MuiCheckbox-root': {
        padding: 4,
    },
});
