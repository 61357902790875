import { styled } from '@mui/system';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Button from '@components/Button';
import { useTranslation } from '@hooks/useTranslation';

const StyledButton = styled(Button)({
    flexShrink: 0,
    '@media print': {
        display: 'none',
    },
});

export const PrintButton = props => {
    const { t } = useTranslation();
    return (
        <StyledButton
            {...props}
            variant="outlined"
            startIcon={<LocalPrintshopIcon />}
            onClick={() =>
                setTimeout(() => {
                    window.print();
                }, 100)
            }
        >
            {t('buttons.print')}
        </StyledButton>
    );
};
