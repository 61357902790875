import { LocationIntegrationName, ProfileName } from '@constants/integrations';
import { POST_CHANNEL } from '@constants/social-posts';

export function getPlatformsOptions(companies, selectedCompanies) {
    const { googleConnected, facebookConnected, instagramConnected } = companies
        ?.filter(c => selectedCompanies.includes(c.companyId))
        ?.map(c => c.profiles)
        .flat()
        .reduce(
            (prev, acc) => {
                if (acc.type === LocationIntegrationName.Google) {
                    return { ...prev, googleConnected: true };
                }
                if (acc.type === LocationIntegrationName.Facebook) {
                    return { ...prev, facebookConnected: true };
                }
                if (acc.type === ProfileName.Instagram) {
                    return { ...prev, instagramConnected: true };
                }
                return prev;
            },
            { googleConnected: false, facebookConnected: false, instagramConnected: false },
        );

    const platformsConnectedIds = [
        ...(googleConnected ? [POST_CHANNEL.GOOGLE] : []),
        ...(facebookConnected ? [POST_CHANNEL.FACEBOOK] : []),
        ...(facebookConnected && instagramConnected ? [POST_CHANNEL.INSTAGRAM] : []),
    ];

    const platformsOptions = [
        ...(googleConnected ? [{ value: POST_CHANNEL.GOOGLE, label: 'Google' }] : []),
        ...(facebookConnected ? [{ value: POST_CHANNEL.FACEBOOK, label: 'Facebook' }] : []),
        ...(facebookConnected && instagramConnected ? [{ value: POST_CHANNEL.INSTAGRAM, label: 'Instagram' }] : []),
    ];

    return { platformsConnectedIds, platformsOptions };
}
