import { useMemo, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { ModalConfirm } from '@components/Modal';
import { CssInputLabel, CssFormControl, CssOutlinedInput } from '@components/Input';
import { LabelChip } from '@components/Label';
import { useTranslation } from '@hooks/useTranslation';
import { DeleteLabelIcon } from 'assets/images/icons';
import { labelsService } from '@services';
import { labelsActions, alertActions } from '@actions';
import { CssInput, CssSelect, SearchBar, MenuItem, MenuItemManage } from './styles';

const ManageLabelInput = ({ label, onChange }) => {
    const [labelName, setLabelName] = useState(label);

    const onChangeLabel = e => {
        if (e.target.value) {
            setLabelName(e.target.value);
            onChange(e.target.value);
        }
    };

    return (
        <CssInput
            inputProps={{
                value: labelName,
                onChange: onChangeLabel,
            }}
            fullWidth
        />
    );
};

export const MultiSelectLabels = ({
    className,
    error,
    options = [],
    label,
    type: labelsType,
    value = [],
    id,
    required,
    onChange,
    onUpdateCallback,
    onDeleteCallback,
    onCreateCallback,
    editable = true,
    disabled,
    bulkEditData = [],
    touchedLabels = [],
    style,
    ...rest
}) => {
    const { t } = useTranslation();
    const [select, setSelect] = useState({ search: '', isManageMode: false, newLabels: {} });
    const [isFocused, setIsFocused] = useState(false);
    const [isDeleteLabelModalOpen, setDeleteLabelModalOpen] = useState(false);

    const isMobile = useMediaQuery('@media (max-width: 1024px)');
    const dispatch = useDispatch();
    const labelToDelete = useRef(null);

    const handleClose = () => {
        setSelect({ search: '', isManageMode: false, newLabels: {} });
    };

    const updateLabels = async (updatedLabels, type) => {
        try {
            const newLabels = await labelsService.addOrUpdateLabels(
                updatedLabels.map(l => ({ ...l, type: labelsType })),
            );
            await dispatch(labelsActions.get(labelsType));
            if (onUpdateCallback) {
                await onUpdateCallback();
            }
            if (type === 'create') {
                onCreateCallback(newLabels[0]?.id);
            }
        } catch (_) {
            dispatch(alertActions.error(t('apiErrors.something_wrong')));
        }
    };

    const onLabelClick = label => {
        onChange({ target: { value: value?.filter(val => val !== label) } });
    };

    const onChangeLabel = (id, value) =>
        setSelect(prev => ({ ...prev, newLabels: { ...prev.newLabels, [id]: value } }));

    const onCreateLabel = () => {
        updateLabels([{ name: select.search }], 'create');
        setSelect(prev => ({ ...prev, search: '' }));
    };

    const onClickDelete = (e, label) => {
        e.stopPropagation();
        labelToDelete.current = label;
        setDeleteLabelModalOpen(true);
    };

    const onDelete = async () => {
        try {
            await labelsService.deleteLabel(labelToDelete.current);
            setDeleteLabelModalOpen(false);
            await dispatch(labelsActions.get(labelsType));
            if (onDeleteCallback) {
                await onDeleteCallback();
            }
        } catch (_) {
            dispatch(alertActions.error(t('apiErrors.something_wrong')));
        }
        labelToDelete.current = null;
    };

    const onClickDone = () => {
        if (select.isManageMode) {
            const updatedLabels = Object.entries(select.newLabels)
                .map(([id, newLabelName]) => ({ id, name: newLabelName }))
                .filter(newLabel => !options.some(option => option.label === newLabel.name));
            if (updatedLabels.length) {
                updateLabels(updatedLabels, 'edit');
            }
        }
        setSelect(prev => ({ ...prev, newLabels: {}, isManageMode: !prev.isManageMode }));
    };

    const filteredOptions = useMemo(() => {
        return options
            .filter(option => !value.includes(option.value))
            .filter(option => option.label.toLowerCase().startsWith(select.search.toLowerCase()));
    }, [value, options, select.search]);

    return (
        <div style={style}>
            <CssFormControl fullWidth required={required} className={className} error={error}>
                <CssInputLabel shrink={!!value.length || isFocused}>{label}</CssInputLabel>
                <CssSelect
                    id={id}
                    value={value}
                    onFocus={() => setIsFocused(true)}
                    multiple
                    onChange={e => {
                        const filteredValue = e.target.value.filter(val => Boolean(val));
                        onChange({ target: { value: filteredValue } });
                    }}
                    onClose={handleClose}
                    disabled={disabled}
                    input={<CssOutlinedInput label={label} inputProps={{ onBlur: () => setIsFocused(false) }} />}
                    renderValue={selectedValues => {
                        const selectedOptions = selectedValues
                            .map(selectedValue => options.find(({ value }) => selectedValue === value))
                            .filter(seleted => seleted !== undefined);
                        return selectedOptions.map((option, index) => (
                            <LabelChip
                                key={index}
                                type={option.label}
                                label={
                                    <Typography variant="caption" sx={{ display: 'block', height: 14 }}>
                                        {option.label}
                                    </Typography>
                                }
                                clickable
                                disabled={disabled}
                                isPartlyAssigned={
                                    bulkEditData
                                        .filter(i => !i.assignedForAll)
                                        .map(i => i.id)
                                        .includes(option.value) && !touchedLabels.includes(option.value)
                                }
                                deleteIcon={<CloseIcon onMouseDown={e => e.stopPropagation()} />}
                                onDelete={() => onLabelClick(option.value)}
                            />
                        ));
                    }}
                    MenuProps={{
                        autoFocus: false,
                        style: { zIndex: 9999 },
                        PaperProps: {
                            style: {
                                maxHeight: isMobile ? '35vh' : '55vh',
                                maxWidth: isMobile ? '210px' : 'none',
                            },
                        },
                    }}
                    {...rest}
                >
                    {editable ? (
                        <SearchBar onKeyDown={e => e.stopPropagation()}>
                            <CssInput
                                inputProps={{
                                    value: select.search,
                                    onChange: e => setSelect(prev => ({ ...prev, search: e.target.value })),
                                }}
                                label={t('multiSelectLabels.searchLabel')}
                                fullWidth
                                startAdornment={<SearchIcon />}
                            />
                            <Button variant="outlined" onClick={onClickDone}>
                                {select.isManageMode ? t('multiSelectLabels.done') : t('multiSelectLabels.manage')}
                            </Button>
                        </SearchBar>
                    ) : null}
                    {filteredOptions.length
                        ? filteredOptions.map(option => {
                              const isMainLabel = ['Promoter', 'Neutral', 'Detractor'].includes(option.label);
                              return !select.isManageMode ? (
                                  <MenuItem key={option.value} value={option.value}>
                                      <LabelChip
                                          type={option.label}
                                          label={<Typography variant="caption">{option.label}</Typography>}
                                      />
                                  </MenuItem>
                              ) : (
                                  <MenuItemManage key={option.value} isMainLabel={isMainLabel}>
                                      {!isMainLabel && (
                                          <DeleteLabelIcon
                                              style={{ cursor: 'pointer' }}
                                              onClick={e => onClickDelete(e, option.value)}
                                          />
                                      )}
                                      {!isMainLabel && select.isManageMode ? (
                                          <ManageLabelInput
                                              label={option.label}
                                              onChange={newValue => onChangeLabel(option.value, newValue)}
                                          />
                                      ) : (
                                          <LabelChip
                                              type={option.label}
                                              label={<Typography variant="caption">{option.label}</Typography>}
                                          />
                                      )}
                                  </MenuItemManage>
                              );
                          })
                        : null}
                    {!filteredOptions.length && !!select.search ? (
                        <MenuItem onClick={onCreateLabel}>
                            <LabelChip
                                type={select.search}
                                label={<Typography variant="caption">{`+ ${select.search}`}</Typography>}
                            />
                            <Typography variant="caption">{t('multiSelectLabels.createNewLabel')}</Typography>
                        </MenuItem>
                    ) : null}
                </CssSelect>
            </CssFormControl>
            {isDeleteLabelModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteLabelModalOpen}
                    handleClose={() => setDeleteLabelModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDelete}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                    onSecondaryAction={() => setDeleteLabelModalOpen(false)}
                />
            )}
        </div>
    );
};
