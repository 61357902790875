export const CSVToArray = data => convertToObjects(StringToArray(data));

export const StringToArray = strData => {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    var strDelimiter = ',';

    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
        // Delimiters.
        '(\\' +
            strDelimiter +
            '|\\r?\\n|\\r|^)' +
            // Quoted fields.
            '(?:"([^"]*(?:""[^"]*)*)"|' +
            // Standard fields.
            '([^"\\' +
            strDelimiter +
            '\\r\\n]*))',
        'gi',
    );

    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData = [[]];

    // Create an array to hold our individual pattern
    // matching groups.
    let arrMatches = null;

    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    // eslint-disable-next-line no-cond-assign
    while ((arrMatches = objPattern.exec(strData))) {
        // Get the delimiter that was found.
        const strMatchedDelimiter = arrMatches[1];

        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push([]);
        }

        let strMatchedValue;

        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[2]) {
            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
        } else {
            // We found a non-quoted value.
            strMatchedValue = arrMatches[3];
        }

        // Now that we have our value string, let's add
        // it to the data array.
        //@ts-ignore
        arrData[arrData.length - 1].push(strMatchedValue);
    }

    // Return the parsed data.
    return arrData;
};

const convertToObjects = data => {
    let output = [];
    if (!data[0][0]) {
        data = stripEmpty(data);
    }
    for (var i = 1; i < data.length; i++) {
        const obj = {};
        if (data[i].length === data[0].length) {
            for (let j = 0; j < data[0].length; j++) {
                obj[data[0][j].replace(' ', '_')] = data[i][j];
            }
            output.push(obj);
        }
    }
    return output;
};

const stripEmpty = data => {
    var output = [];
    for (var i = 1; i < data.length; i++) {
        if (!data[i][0]) continue;
        let obj = [];
        for (var j = 0; j < 3; j++) {
            if (!data[i][j]) continue;
            obj.push(data[i][j]);
        }
        if (obj.length === 3) {
            output.push(obj);
        }
    }
    return output;
};
