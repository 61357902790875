import { useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { ExternalLink } from '@components/Link';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { MESSAGE_CONTENT_TYPE, MESSAGE_STATUS } from '@constants/inbox';
import { FileIcon } from 'assets/images/icons';

const ImageBlurWrapper = styled('div')(() => ({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: 60,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    pointerEvents: 'none',
    '& .MuiCircularProgress-root': {
        color: 'rgba(0, 0, 0, 0.38) !important',
    },
}));

const MessageContent = ({ data, withReply, isOutgoing }) => {
    const [isStoryError, setIsStoryError] = useState(false);
    const { t } = useTranslation();
    return (
        <>
            {data.contentType === MESSAGE_CONTENT_TYPE.TEXT ? (
                <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                    {data.content}
                </Typography>
            ) : null}
            {data.contentType === MESSAGE_CONTENT_TYPE.IMAGE ? (
                <ExternalLink
                    href={data.content}
                    underline="none"
                    sx={{
                        position: 'relative',
                        pointerEvents: isOutgoing && data.status === MESSAGE_STATUS.SENT ? 'none' : 'auto',
                    }}
                >
                    {data.status === MESSAGE_STATUS.SENT ? (
                        <ImageBlurWrapper>
                            <CircularProgress />
                        </ImageBlurWrapper>
                    ) : null}
                    <img width={80} height={60} style={{ objectFit: 'contain' }} src={data.content} alt="download" />
                </ExternalLink>
            ) : null}
            {data.contentType === MESSAGE_CONTENT_TYPE.STORY_IMAGE ? (
                !isStoryError ? (
                    <ExternalLink href={data.content} underline="none">
                        <img
                            width={80}
                            height={60}
                            style={{ objectFit: 'contain' }}
                            onError={() => setIsStoryError(true)}
                            src={data.content}
                            alt="download"
                        />
                    </ExternalLink>
                ) : (
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                        {t('InboxPage.storyNotAvailable')}
                    </Typography>
                )
            ) : null}
            {data.contentType === MESSAGE_CONTENT_TYPE.VIDEO ? (
                <ExternalLink href={data.content} underline="none" sx={{ display: 'flex' }}>
                    <video preload="metadata" style={{ width: 80, height: 60, maxWidth: '100%' }} src={data.content} />
                </ExternalLink>
            ) : null}
            {data.contentType === MESSAGE_CONTENT_TYPE.STORY_VIDEO ? (
                !isStoryError ? (
                    <ExternalLink href={data.content} underline="none" sx={{ display: 'flex' }}>
                        <video
                            preload="metadata"
                            style={{ width: 80, height: 60, maxWidth: '100%' }}
                            src={data.content}
                            onError={() => setIsStoryError(true)}
                        />
                    </ExternalLink>
                ) : (
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                        {t('InboxPage.storyNotAvailable')}
                    </Typography>
                )
            ) : null}
            {data.contentType === MESSAGE_CONTENT_TYPE.AUDIO ? (
                <ReactAudioPlayer
                    class="play"
                    src={data.content}
                    controls
                    controlsList="noplaybackrate nodownload"
                    style={{
                        height: '36px',
                        maxWidth: '100%',
                        transform: withReply ? 'translate(-10px, 1px)' : 'translateY(1px)',
                    }}
                />
            ) : null}
            {data.contentType === MESSAGE_CONTENT_TYPE.FILE ? (
                <ExternalLink href={data.content} underline="none" sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <FileIcon />
                    <Typography
                        variant="body2"
                        sx={{ display: 'inline-block', marginLeft: '4px', color: 'rgba(0, 0, 0, 0.87)' }}
                    >
                        {t('common.file')}
                    </Typography>
                </ExternalLink>
            ) : null}
        </>
    );
};

export default MessageContent;
