import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import CancelIcon from '@mui/icons-material/Cancel';
import { InternalLink } from '@components/Link';
import { Typography } from '@components/Typography';
import { Input } from '@components/Input';
import Button from '@components/Button';
import { userService } from '@services';
import { AuthLayout } from '@features/AuthLayout';
import { AuthProgress } from '@components/AuthProgress';
import { alertActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';

const Form = styled(Paper)({
    marginBottom: '24px',
    padding: '24px',
    position: 'relative',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
        padding: '16px',
        width: '100%',
    },
});

const Overlay = styled('div')({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255,255,255,0.6)',
    zIndex: 2,
});

const ForgotPasswordPage = () => {
    const { t } = useTranslation();
    const [isEmailFocused, setEmailFocused] = useState(false);
    const dispatch = useDispatch();

    const ForgotPasswordSchema = yup.object().shape({
        email: yup.string().required('validation.required').email('validation.wrongEmailFormat'),
    });

    const resolver = useYupValidationResolver(ForgotPasswordSchema);

    const {
        getValues,
        handleSubmit,
        setValue,
        control,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver: resolver,
        defaultValues: {
            email: '',
        },
    });

    const onSubmit = async () => {
        const { email } = getValues();
        try {
            await userService.forgotPassword(email);
            dispatch(alertActions.success(t('ForgotPasswordPage.confirmationSuccess')));
        } catch (_) {
            dispatch(alertActions.error(t('apiErrors.something_wrong')));
        }
    };

    return (
        <AuthLayout>
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {isSubmitting && <AuthProgress />}
                <Form elevation={24}>
                    {isSubmitting && <Overlay />}
                    <Typography variant="h5" sx={{ marginBottom: '8px', color: 'rgba(0, 0, 0, .87)' }}>
                        {t('ForgotPasswordPage.title')}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginBottom: '24px' }}>
                        {t('ForgotPasswordPage.description')}
                    </Typography>
                    <Controller
                        control={control}
                        name="email"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, type: 'email' }}
                                onAddonClick={() => setValue('email', '')}
                                addonComponent={isEmailFocused ? <CancelIcon style={{ fontSize: 16 }} /> : null}
                                label={t('common.emailAddress')}
                                fullWidth
                                sx={{ marginBottom: '24px' }}
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                onMouseEnter={() => setEmailFocused(true)}
                                onMouseLeave={() => setEmailFocused(false)}
                            />
                        )}
                    />
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        fullWidth
                        sx={{ marginBottom: '16px' }}
                        disabled={isSubmitting || !isValid || !isDirty}
                    >
                        {t('buttons.sendLink')}
                    </Button>
                </Form>
                <InternalLink to="/login" variant="caption" underline="hover">
                    {t('ForgotPasswordPage.backToLogin')}
                </InternalLink>
            </div>
        </AuthLayout>
    );
};

export default ForgotPasswordPage;
