import { styled } from '@mui/system';
import Button from '@components/Button';
import { Input } from '@components/Input';

export const StyledInput = styled(Input)({
    paddingBottom: '16px',
    '& .MuiInputLabel-root': {
        fontSize: '10px',
    },
    '&.MuiInputBase-inputAdornedEnd': {
        height: '2px',
    },
    '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
        {
            '-webkit-box-shadow': '0 0 0 30px #bde0cc inset',
        },
    '& fieldset': {
        height: '32px',
    },
    '& input': {
        padding: '5px',
    },
    '& label': {
        transform: 'translate(10px, 6px) scale(1)',
    },
});

export const FooterStars = styled('div')({
    width: '100%',
    '& .MuiRating-root, .Mui-disabled': {
        color: '#295563',
        opacity: '1 !important',
        margin: '0 !important',
    },
    '& .MuiRating-root, .MuiRating-icon': {
        margin: '0 7px',
    },
});

export const AgreementWrapper = styled('div')({
    display: 'flex',
    width: '100%',
    margin: 'auto',
    position: 'relative',
    alignItems: 'center',
    marginBottom: '16px',
});

export const FooterTitle = styled('h3')({
    margin: '0 auto 16px',
    fontFamily: 'Lora',
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#1f4c5c',
});

export const HeaderTitle = styled('h2')({
    position: 'relative',
    fontFamily: 'Lora',
    fontSize: '20px',
    fontWeight: 'normal',
    color: '#1f4c5c',
    margin: '0 0 12px 0',
    padding: '0 0 12px',
    '&::after': {
        content: '""',
        width: '70px',
        height: '1px',
        backgroundColor: '#d1d1d1',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        right: '50%',
        marginRight: '-35px',
    },
});

export const Container = styled('div')(
    () => `
    min-height: 540px;
    font-size: 12px;
    color: #849f90;
    font-family: Rubik;
    text-align: center;
    background-color: #bde0cc;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    overflow: auto;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
    & p {
        margin: 0;
    }
`,
);

export const Header = styled('div')(
    () => `
    background-color: #ededed;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    & {
        svg {
            path {
                fill: #1f4c5c;
            }
        }
    }
`,
);

export const Footer = styled('div')(
    () => `
    background-color: #bde0cc;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding:  24px;
`,
);

export const SubmitButton = styled(Button)(() => ({
    height: '40px !important',
    minHeight: '40px !important',
    width: '100%',
    fontSize: '12px !important',
    marginBottom: 8,
    alignSelf: 'center',
    borderRadius: 0,
    backgroundColor: '#1f4c5c',
    cursor: 'default',
    textTransform: 'uppercase',
    '&:hover': {
        backgroundColor: '#1f4c5c',
    },
}));
