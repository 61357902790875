import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';
import TimeframePicker from '@components/TimeframePicker';

export const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 564,
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

export const Content = styled('div')(({ isOneTime, isEmail, coverPhotoEnabled, ctaButtonEnabled }) => ({
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateAreas: isOneTime
        ? `"title title" "locationLabels locations" "channels scheduleTime" 
            "contactLabels contactLabels" "radio-buttons." ${isEmail ? '"subject subject"' : ''} "message message"
            ${
                isEmail
                    ? `"coverPhotoSwitch." ${coverPhotoEnabled ? '"image image"' : ''} "ctaButtonSwitch." ${
                          ctaButtonEnabled ? '"ctaTitle ctaLink" "ctaColor."' : ''
                      }`
                    : ''
            }`
        : `"title title" "locationLabels locations" "triggers triggers" 
        "channels delay" "contactLabels contactLabels" "radio-buttons." ${
            isEmail ? '"subject subject"' : ''
        } "message message"
        ${
            isEmail
                ? `"coverPhotoSwitch." ${coverPhotoEnabled ? '"image image"' : ''} "ctaButtonSwitch." ${
                      ctaButtonEnabled ? '"ctaTitle ctaLink" "ctaColor."' : ''
                  }`
                : ''
        }`,
    gridTemplateColumns: 'calc(50% - 12px) calc(50% - 12px)',
    '@media (max-width: 600px)': {
        gridTemplateAreas: isOneTime
            ? `"title" "locationLabels" "locations"  "channels" "scheduleTime" 
                "contactLabels" "radio-buttons" ${isEmail ? '"subject"' : ''} "message"
                ${
                    isEmail
                        ? `"coverPhotoSwitch" ${coverPhotoEnabled ? '"image"' : ''} "ctaButtonSwitch" ${
                              ctaButtonEnabled ? '"ctaTitle" "ctaLink" "ctaColor"' : ''
                          }`
                        : ''
                }`
            : `"title" "locationLabels" "locations"  "channels" "triggers" 
                "delay" "contactLabels" "radio-buttons" ${isEmail ? '"subject"' : ''} "message"
                ${
                    isEmail
                        ? `"coverPhotoSwitch" ${coverPhotoEnabled ? '"image"' : ''} "ctaButtonSwitch" ${
                              ctaButtonEnabled ? '"ctaTitle" "ctaLink" "ctaColor"' : ''
                          }`
                        : ''
                }`,
        gridTemplateColumns: '100%',
        gridGap: '16px',
    },
}));

export const StyledTimeframePicker = styled(TimeframePicker)({
    gridArea: 'scheduleTime',
    '& .react-datepicker': {
        display: 'flex',
    },
});
