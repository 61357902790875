import { hubspotCalendarUiConstaints } from '@constants/hubspot-calendar-ui';

const initialState = {
    isOpen: false,
};

export function hubspotCalendarUi(state = initialState, action) {
    switch (action.type) {
        case hubspotCalendarUiConstaints.SET_HUBSPOT_CALENDAR_OPEN:
            return { ...state, isOpen: action.payload };
        default:
            return state;
    }
}
