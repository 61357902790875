import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

function get(page, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/surveys/query?page=${page}&pageSize=${pageSize}`, requestOptions).then(handleResponse);
}

export const sentRequestsService = {
    get,
};
