import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@components/Button';
import MenuList from '@components/MenuList';
import { ModalConfirm } from '@components/Modal';
import { useTranslation } from '@hooks/useTranslation';
import { replyTemplatesService } from '@services';
import { alertActions, replyTemplatesActions } from '@actions';
import CreateTemplateModal from './CreateTemplateModal';
import { ArrowDown, ArrowUp, StyledSaveIcon, StyledDeleteIcon } from './styles';

const RespondTemplate = ({ setComment, comment, selectedCompanies, review }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('@media (max-width: 600px)');
    const isTablet = useMediaQuery('@media (max-width: 1024px)');

    const [anchorElMain, setAnchorElMain] = useState(null);
    const [anchorElDelete, setAnchorElDelete] = useState(null);
    const [anchorElEdit, setAnchorElEdit] = useState(null);

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const { data } = useSelector(state => state.replyTemplates);
    const user = useSelector(state => state.authentication?.user);
    const IS_ADMIN = useSelector(state => {
        if (!state.authentication?.user || !state.authentication?.user?.id) {
            return false;
        }
        return selectedCompanies.some(id => {
            return !!state.authentication?.user?.roles?.find(role => role.companyId === id && role.role === 0);
        });
    });
    const IS_ACCOUNT_OWNER = user?.isAccountOwner || user?.isGlobalAdmin;

    const handleOpenMain = event => {
        if (isMobile) {
            setMobileMenuOpen(true);
        } else {
            setAnchorElMain(event.currentTarget);
            setAnchorElDelete(null);
            setAnchorElEdit(null);
        }
    };
    const handleOpenDelete = event => {
        setAnchorElDelete(event.currentTarget);
        setAnchorElEdit(null);
    };
    const handleOpenEdit = event => {
        setAnchorElEdit(event.currentTarget);
        setAnchorElDelete(null);
    };

    const handleCloseMain = () => setAnchorElMain(null);

    const onClickTemplate = template => {
        setComment(
            template.text
                ?.replace(/\[name\]/g, review.userName.split(' ')[0])
                ?.replace(/\[location\]/g, review.profileTitle),
        );
        setMobileMenuOpen(false);
        setAnchorElMain(null);
    };

    const onEditTemplate = async () => {
        try {
            await replyTemplatesService.addOrUpdateReplyTemplate({ ...selectedTemplate, text: comment });
            dispatch(alertActions.success(t('alertMessages.editSuccess')));
            await dispatch(replyTemplatesActions.getAll());
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.editFail')));
        }
        setEditModalOpen(false);
        setAnchorElEdit(null);
        setSelectedTemplate(null);
    };

    const onDeleteTemplate = async () => {
        try {
            await replyTemplatesService.deleteReplyTemplate(selectedTemplate.id);
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
            await dispatch(replyTemplatesActions.getAll());
            setDeleteModalOpen(false);
            setAnchorElDelete(null);
            setSelectedTemplate(null);
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.deleteFail')));
        }
    };

    const ACTIONS = {
        INSERT: template => () => onClickTemplate(template),
        EDIT: template => () => {
            setSelectedTemplate(template);
            setEditModalOpen(true);
        },
        DELETE: template => () => {
            setSelectedTemplate(template);
            setDeleteModalOpen(true);
        },
    };

    function mapTemplates(data, action) {
        return data.map(template => ({
            icon: null,
            text: template.name,
            sub: {},
            onClick: ACTIONS[action](template),
        }));
    }

    const MAIN_MENU = [
        ...mapTemplates(data, 'INSERT'),
        ...(IS_ACCOUNT_OWNER || IS_ADMIN
            ? [
                  {
                      divider: true,
                      icon: <StyledSaveIcon />,
                      text: t('RespondTemplate.saveReplyAsTemplate'),
                      sub: {
                          header: t('RespondTemplate.overwriteTemplate'),
                          list: [
                              ...mapTemplates(data, 'EDIT'),
                              {
                                  divider: true,
                                  icon: <StyledSaveIcon />,
                                  text: t('RespondTemplate.saveAsNewTemplate'),
                                  sub: {},
                                  onClick: () => setCreateModalOpen(true),
                              },
                          ],
                      },
                      anchorEl: anchorElEdit,
                      onClick: handleOpenEdit,
                  },
                  {
                      icon: <StyledDeleteIcon />,
                      text: t('RespondTemplate.deleteTemplate'),
                      sub: {
                          header: t('RespondTemplate.deleteTemplate'),
                          list: mapTemplates(data, 'DELETE'),
                      },
                      anchorEl: anchorElDelete,
                      onClick: handleOpenDelete,
                  },
              ]
            : []),
    ];

    return (
        <>
            {!isTablet && (
                <div
                    style={{
                        width: 1,
                        backgroundColor: 'rgba(0, 0, 0, 0.38)',
                        height: 14,
                        margin: '0 8px 0 16px',
                    }}
                />
            )}
            <MenuList
                options={MAIN_MENU}
                anchorEl={anchorElMain}
                onCloseMenu={handleCloseMain}
                openModal={isMobileMenuOpen}
                handleCloseModal={() => setMobileMenuOpen(false)}
                mainTitle={t('RespondTemplate.insertTemplate')}
                menuButton={
                    <Button
                        type="button"
                        variant="text"
                        sx={{
                            fontSize: '0.875rem',
                            lineHeight: '16px',
                            letterSpacing: '0.1px',
                            height: '24px',
                            padding: '4px 8px',
                        }}
                        onClick={handleOpenMain}
                    >
                        {t('common.templates')}
                        {anchorElMain ? <ArrowUp /> : <ArrowDown />}
                    </Button>
                }
            />
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDeleteTemplate}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                />
            )}
            {isEditModalOpen && (
                <ModalConfirm
                    isOpen={isEditModalOpen}
                    handleClose={() => setEditModalOpen(false)}
                    title={t('RespondTemplate.editTemplate')}
                    description={t('RespondTemplate.editTemplateDescription')}
                    onPrimaryAction={onEditTemplate}
                    primaryActionText={t('buttons.save')}
                />
            )}
            {isCreateModalOpen && (
                <CreateTemplateModal
                    comment={comment}
                    isCreateModalOpen={isCreateModalOpen}
                    setCreateModalOpen={setCreateModalOpen}
                    setAnchorElEdit={setAnchorElEdit}
                />
            )}
        </>
    );
};

export default RespondTemplate;
