export const LOCALE = {
    UNITED_KINGDOM: 0,
    UKRAINE: 1,
    INTERNATIONAL: 2,
    SPAIN: 3,
    PORTUGAL: 4,
    BRAZIL: 5,
    POLAND: 6,
    GERMANY: 7,
};

export const LOCALE_KEY = {
    GB: 'GB',
    UA: 'UA',
    International: 'International',
    ES: 'ES',
    PT: 'PT',
    BR: 'BR',
    PL: 'PL',
    DE: 'DE',
};
