import { styled } from '@mui/system';

export const AnalyticsContainer = styled('section')({
    backgroundColor: '#F2F2F2',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '16px',
    borderRadius: '4px',
    '@media (max-width: 1024px)': {
        margin: '0 -16px',
        height: 'calc(var(--100vh) - 173px)',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    '@media print': {
        height: 'auto',
    },
});

export const HeaderWrapper = styled('div')({
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    // '@media print': {
    //     display: 'none',
    // },
});
