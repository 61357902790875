import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '@hooks/useTranslation';
import Drawer from '@components/Drawer';
import Card from '@components/Card';
import { LocationIntegrationName } from '@constants/integrations';
import { alertActions, ifoodActions, accountActions } from '@actions';
import { ifoodIntegrationService } from '@services';

const IFoodIntegration = ({ onClose, onConnect, isOpen, selectedCompany, setSelectedCompany }) => {
    const { t } = useTranslation();
    const [merchant, setMerchant] = useState();

    const account = useSelector(state => state.account.account);
    const auth = useSelector(state => state.authentication);
    const IS_ADMIN = useSelector(state => {
        if (!state.authentication?.user || !state.authentication?.user?.id) {
            return false;
        }
        return (
            state.authentication?.user?.roles?.filter(u => u.companyId === selectedCompany?.companyId && u.role === 0)
                ?.length > 0
        );
    });
    const MERCHANTS = useSelector(state => state.ifood.data);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ifoodActions.get());
    }, [dispatch]);

    useEffect(() => {
        if (MERCHANTS.length) {
            setMerchant('0');
        }
    }, [MERCHANTS]);

    const IS_ACCOUNT_OWNER = auth.user?.isAccountOwner || auth.user?.isGlobalAdmin;
    const IFOOD_CONNECTED = account?.integrations.find(i => i.type === LocationIntegrationName.iFood)?.isActive;

    const goToIntegrations = () => history.push('/account-settings/integrations');

    const onConnectIFood = async () => {
        try {
            onClose();
            const updatedCompany = await ifoodIntegrationService.editProfile({
                companyId: selectedCompany?.companyId,
                restaurantId: MERCHANTS[merchant].id || null,
                name: MERCHANTS[merchant].name || null,
            });
            await dispatch(accountActions.get());
            setSelectedCompany(updatedCompany);
            dispatch(alertActions.success(t('SettingsCompanies.connectIntegrationMsg')));
        } catch (error) {
            dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`) || t('apiErrors.something_wrong')));
        }
        onConnect();
    };

    const getPrimaryActionProps = () => {
        if (!IFOOD_CONNECTED && IS_ACCOUNT_OWNER) {
            return {
                primaryAction: goToIntegrations,
                primaryText: t('SettingsCompanies.goToIntegrations'),
                primaryDisabled: false,
            };
        } else if (IFOOD_CONNECTED && (IS_ACCOUNT_OWNER || IS_ADMIN)) {
            return {
                primaryAction: onConnectIFood,
                primaryText: t('buttons.connect'),
                primaryDisabled: !merchant,
            };
        } else if (!IFOOD_CONNECTED && !IS_ACCOUNT_OWNER) {
            return {
                primaryAction: goToIntegrations,
                primaryText: t('SettingsCompanies.goToIntegrations'),
                primaryDisabled: true,
            };
        }
    };

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            title={
                IFOOD_CONNECTED
                    ? t('SettingsCompanies.connectLocation')
                    : t('SettingsCompanies.connectDrawerNotConnected')
            }
            subtitle={
                IFOOD_CONNECTED
                    ? t('SettingsCompanies.connectDrawerDescription')
                    : t('SettingsCompanies.connectDrawerNotConnectedDescription')
            }
            {...getPrimaryActionProps()}
            secondaryText={IFOOD_CONNECTED && (IS_ACCOUNT_OWNER || IS_ADMIN) ? t('buttons.cancel') : null}
        >
            <div style={{ display: 'grid', gridGap: '8px' }}>
                {IFOOD_CONNECTED &&
                    merchant &&
                    MERCHANTS.map((m, index) => (
                        <Card
                            key={m.id}
                            type="radio"
                            selected={index === Number(merchant)}
                            title={m.name}
                            value={merchant}
                            index={index}
                            setValue={setMerchant}
                        />
                    ))}
            </div>
        </Drawer>
    );
};

export default IFoodIntegration;
