import { useState, useMemo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import sortBy from 'lodash.sortby';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { VIEW_TYPE } from '@constants/microsites';
import { ModalBase } from '@components/Modal';
import { RadioButtonsGroup } from '@components/RadioButtonsGroup';
import { useTranslation } from '@hooks/useTranslation';
import { CHANNEL } from '@constants/channels';
import PreviewMicrosite from '../PreviewMicrosite';
import General from './General';
import Landing from './Landing';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 'calc(100% - 32px)',
        height: 'calc(var(--100vh) - 32px)',
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        height: 'calc(100% - 118px)',
        '@media (max-width: 768px)': {
            gridTemplateColumns: '100%',
        },
    },
});

const RadioButtonsWrapper = styled('div')({
    overflowX: 'auto',
    height: 32,
    marginBottom: 24,
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
});

const RadioButtons = styled(RadioButtonsGroup)({
    width: '100%',
    display: 'block',
    marginBottom: '24px',
    '& .MuiFormControlLabel-root': {
        height: '32px',
        padding: '0 4px',
    },
});

const Tab = {
    Settings: 0,
    Content: 1,
};

const EditModal = ({ modal, onCloseModal, selectedMicrosite, onDelete, onSave, account, isSubmitting, isUser }) => {
    const { t } = useTranslation();
    const [tab, setTab] = useState(Tab.Settings);

    const [location, setLocation] = useState(null);

    // landing landingPreview
    const [previewLanding, setPreviewLanding] = useState({
        socialMediaEnable: false,
        logoUrl: '',
        showReviews: false,
        links: {},
        companyId: null,
        id: null,
    });
    const channels = useSelector(state => state.channels.data);

    const initRef = useRef(0);

    const MicrositesSchema = yup.object().shape({
        name: yup.string().required('validation.required'),
        slug: yup
            .string()
            .required('validation.required')
            .not(selectedMicrosite?.restrictedNames || [], 'validation.takenURL')
            .matches(/^(?!unsubscribe+\b)/i, 'validation.takenURL')
            .matches(/^(?!service-review+\b)/i, 'validation.takenURL')
            .min(8, 'validation.minLength8'),
    });

    const resolver = useYupValidationResolver(MicrositesSchema);

    const {
        getValues,
        setValue,
        control,
        formState: { isValid, isDirty },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            name: '',
            companyId: '',
            slug: '',
            socialMediaEnable: false,
            logoUrl: '',
            links: {},
            showReviews: false,
            updateGoogleWebsite: false,
        },
    });

    const allLinksHaveValue = useMemo(
        () =>
            Object.values(previewLanding.links)
                .flat()
                .every(link => !!link.title && (!!link.link || !!link.surveyId || !!link.liveChatChannelId)),
        [previewLanding.links],
    );

    const companyOptions = useMemo(
        () => account?.companies?.map(c => ({ label: c.internalName, value: c.companyId })),
        [account],
    );

    useEffect(() => {
        if (selectedMicrosite && account && !location && modal.open) {
            setLocation(selectedMicrosite.companyId);
        }
    }, [selectedMicrosite, account, location, modal.open]);

    useEffect(() => {
        if (selectedMicrosite && account && modal.open) {
            setValue('name', selectedMicrosite.name, { shouldValidate: true });
            setValue('companyId', selectedMicrosite.companyId);
            setValue('slug', selectedMicrosite.slug, { shouldValidate: true });
            setValue('updateGoogleWebsite', selectedMicrosite.updateGoogleWebsite);
            setValue('socialMediaEnable', selectedMicrosite.socialMediaEnable);
            setValue('logoUrl', selectedMicrosite.logoUrl);
            setValue('links', selectedMicrosite.links || {});
            setValue('showReviews', selectedMicrosite.showReviews);

            setPreviewLanding({
                socialMediaEnable: selectedMicrosite.socialMediaEnable,
                logoUrl: selectedMicrosite.logoUrl,
                links: selectedMicrosite.links || {},
                showReviews: selectedMicrosite.showReviews,
                slug: selectedMicrosite.slug,
                surveyId: selectedMicrosite.surveyId,
                companyId: selectedMicrosite.companyId,
                id: selectedMicrosite.id,
            });
        }
    }, [selectedMicrosite, setValue, account, modal.open]);

    const previewCompany = useMemo(
        () => account?.companies?.find(c => c.companyId === location) || {},
        [location, account],
    );
    const surveyOptions = useMemo(
        () =>
            sortBy(
                previewCompany.surveys?.map(s => ({ label: s.name, value: s.externalId })),
                ['label'],
            ),
        [previewCompany],
    );
    const liveChatChannelOptions = useMemo(
        () =>
            channels
                ?.filter(
                    channel =>
                        channel.type === CHANNEL.LIVE_CHAT && channel.companies.includes(previewCompany?.companyId),
                )
                .map(s => ({ label: s.name, value: s.id })),
        [(channels, previewCompany)],
    );

    const isView = modal.type === VIEW_TYPE.VIEW;

    const onClose = () => {
        onCloseModal();
        setTab(Tab.Settings);
        setLocation(null);
        setPreviewLanding({
            socialMediaEnable: false,
            logoUrl: '',
            links: {},
            showReviews: false,
            companyId: null,
            id: null,
        });
        initRef.current = 0;
    };

    return (
        <Modal
            isOpen={modal.open}
            handleClose={onClose}
            title={t('MicrositesPage.modalTitle')}
            onPrimaryAction={
                isUser
                    ? null
                    : () => {
                          onSave(getValues());
                          onClose();
                      }
            }
            primaryActionText={t('buttons.save')}
            primaryActionDisabled={isSubmitting || !isValid || !isDirty || !allLinksHaveValue}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
            onAdditionalAction={isUser ? null : onDelete}
            additionalTextButton={t('buttons.deleteSite')}
        >
            <FormProvider {...{ getValues, setValue, control, isValid }}>
                <div style={{ padding: '24px 16px', display: 'flex', flexDirection: 'column' }}>
                    <RadioButtonsWrapper>
                        <RadioButtons
                            value={tab}
                            onChange={setTab}
                            options={[
                                { value: Tab.Settings, label: t('MicrositesPage.settings') },
                                { value: Tab.Content, label: t('MicrositesPage.content') },
                            ]}
                        />
                    </RadioButtonsWrapper>
                    {tab === Tab.Settings && (
                        <General
                            isView={isView}
                            account={account}
                            setLocation={setLocation}
                            setPreview={setPreviewLanding}
                            preview={previewLanding}
                            companyOptions={companyOptions}
                            restrictedNames={selectedMicrosite?.restrictedNames}
                            location={location}
                        />
                    )}
                    {tab === Tab.Content && (
                        <Landing
                            isView={isView}
                            preview={previewLanding}
                            surveyOptions={surveyOptions}
                            liveChatChannelOptions={liveChatChannelOptions}
                            setPreview={setPreviewLanding}
                            account={account}
                        />
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f2f2f2',
                    }}
                >
                    <PreviewMicrosite
                        account={account}
                        company={previewCompany}
                        preview={previewLanding}
                        locale={account?.locale}
                    />
                </div>
            </FormProvider>
        </Modal>
    );
};

export default EditModal;
