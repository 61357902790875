import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

function getLabels(labelType) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/labels?labelType=${labelType}`, requestOptions).then(handleResponse);
}

function addOrUpdateLabels(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/labels`, requestOptions).then(handleResponse);
}

function deleteLabel(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/labels/${id}`, requestOptions).then(handleResponse);
}

export const labelsService = {
    getLabels,
    addOrUpdateLabels,
    deleteLabel,
};
