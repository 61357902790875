import { useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';
import { alertActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';

export const useCopyToClipboard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onCopy = async text => {
        try {
            copy(text);
            dispatch(alertActions.success(t('common.clipboardCopied')));
            return true;
        } catch (error) {
            console.error('Copy failed', error);
            dispatch(alertActions.error(t('common.clipboardFailed')));
            return false;
        }
    };
    return { onCopy };
};
