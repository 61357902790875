import { authHeader, BASE_URL, handleResponse, handleResponseBlob } from '@helpers/api';

export const companyService = {
    addCompany,
    updateCompany,
    deleteCompany,
    importGoogleCompanies,
    editProfile,
    deleteProfile,
    getCompaniesCSV,
    uploadCompaniesCSV,
    updateCompaniesBulk,
    uploadCompanyImage,
};

function addCompany(placeId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ placeId }),
    };

    return fetch(`${BASE_URL}api/Companies`, requestOptions).then(handleResponse);
}

function updateCompany(company) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(company),
    };

    return fetch(`${BASE_URL}api/Companies/${company.companyId}`, requestOptions).then(handleResponse);
}

function editProfile(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/profiles/generic`, requestOptions).then(handleResponse);
}

function deleteProfile(companyId, profileId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/profiles?companyId=${companyId}&profileId=${profileId}`, requestOptions).then(
        handleResponse,
    );
}

function deleteCompany(companyId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/Companies/${companyId}`, requestOptions).then(handleResponse);
}

function importGoogleCompanies(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/google/import-locations`, requestOptions).then(handleResponse);
}

function getCompaniesCSV() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/companies/bulk/export`, requestOptions).then(handleResponseBlob);
}

function uploadCompaniesCSV(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + user.token,
        },
        body: data,
    };

    return fetch(`${BASE_URL}api/companies/bulk/import`, requestOptions).then(handleResponse);
}

function updateCompaniesBulk(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/companies/bulk/run`, requestOptions).then(handleResponse);
}

function uploadCompanyImage(data, companyId, type) {
    const user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + user.token,
        },
        body: data,
    };
    return fetch(`${BASE_URL}api/companies/${companyId}/upload-asset?type=${type}`, requestOptions).then(
        handleResponse,
    );
}
