import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { Input } from '@components/Input';
import { Checkbox } from '@components/Checkbox';
import { Select } from '@components/Select';
import { Switch } from '@components/Switch';
import Button from '@components/Button';
import { MultiSelectLabels } from '@components/MultiSelectLabels';
import { Typography } from '@components/Typography';
import { ProfileType } from '@constants/integrations';
import { LABEL_TYPE } from '@constants/labels';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { useTranslation } from '@hooks/useTranslation';
import AdditionalField, { CustomFieldType } from './AdditionalField';
import { excludedProfileTypes } from '.';

const PlatformWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    transform: 'translateX(-7px)',
    '& .MuiCheckbox-root': {
        padding: 4,
    },
});

const FieldWrapper = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
    gridGap: 16,
    marginTop: '8px',
    alignItems: 'center',
});

const General = ({ isView, account, preview, setPreview, setLocation, companyOptions, availablePlatforms }) => {
    const { t } = useTranslation();
    const { control, setValue, getValues, fields, append } = useFormContext();
    const { contact: labels } = useSelector(state => state.labels);

    const labelsOptions = useMemo(() => labels.map(item => ({ value: item.id, label: item.name })), [labels]);

    const emailField = preview.systemFields.Email;
    const phoneField = preview.systemFields.Phone;
    const nameField = preview.systemFields.Name;

    return (
        <>
            <Controller
                control={control}
                name="name"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('FeedbackSurveys.surveyName')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                        disabled={isView}
                        sx={{ marginBottom: '8px' }}
                    />
                )}
            />
            <Controller
                control={control}
                name="companyId"
                render={({ field, ref }) => (
                    <Select
                        {...field}
                        onChange={e => {
                            field.onChange(e.target.value);
                            setLocation(e.target.value);
                            const company = account?.companies?.find(c => c.companyId === e.target.value);
                            const reviewPlatforms = (company?.profiles || [])
                                .filter(p => !excludedProfileTypes.includes(p.type))
                                .map(p => p.type);
                            setValue('reviewPlatforms', reviewPlatforms, { shouldDirty: true });
                            setPreview(prev => ({ ...prev, reviewPlatforms }));
                        }}
                        ref={ref}
                        label={t('common.location')}
                        fullWidth
                        options={companyOptions}
                        disabled={isView}
                    />
                )}
            />
            <Controller
                control={control}
                name="satisfactionSurveyEnabled"
                render={({ field, ref }) => (
                    <Switch
                        {...field}
                        ref={ref}
                        checked={getValues('satisfactionSurveyEnabled')}
                        handleChange={event => {
                            setValue('satisfactionSurveyEnabled', event.target.checked, { shouldDirty: true });
                            setPreview(prev => ({ ...prev, satisfactionSurveyEnabled: event.target.checked }));
                        }}
                        label="Net promoter scoring"
                        disabled={isView}
                        sx={{ marginTop: '8px', marginBottom: '8px' }}
                    />
                )}
            />
            {preview.satisfactionSurveyEnabled && (
                <>
                    <Typography
                        variant="subtitle1"
                        sx={{ color: 'rgba(0, 0, 0, 0.87)', marginTop: '8px', marginBottom: '16px' }}
                    >
                        {t('FeedbackSurveys.contactDetails')}
                    </Typography>
                    <FieldWrapper>
                        <Controller
                            control={control}
                            name="contactEmail"
                            render={({ field, ref }) => (
                                <Switch
                                    {...field}
                                    ref={ref}
                                    checked={getValues('contactEmail')}
                                    handleChange={event => {
                                        setValue('contactEmail', event.target.checked, { shouldDirty: true });
                                        if (!event.target.checked) {
                                            setValue('contactEmailRequired', false, { shouldDirty: true });
                                        }
                                        setPreview(prev => ({
                                            ...prev,
                                            systemFields: {
                                                ...prev.systemFields,
                                                Email: {
                                                    ...prev.systemFields.Email,
                                                    enable: event.target.checked,
                                                    required: event.target.checked
                                                        ? getValues('contactEmailRequired')
                                                        : false,
                                                },
                                            },
                                        }));
                                    }}
                                    label={t('FeedbackSurveys.contactEmail')}
                                    disabled={isView}
                                />
                            )}
                        />
                        {emailField.enable ? (
                            <PlatformWrapper>
                                <Controller
                                    control={control}
                                    name="contactEmailRequired"
                                    render={({ field, ref }) => (
                                        <Checkbox
                                            ref={ref}
                                            disabled={isView}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={e => {
                                                setValue('contactEmailRequired', e.target.checked, {
                                                    shouldDirty: true,
                                                });
                                                setPreview(prev => ({
                                                    ...prev,
                                                    systemFields: {
                                                        ...prev.systemFields,
                                                        Email: {
                                                            ...prev.systemFields.Email,
                                                            required: e.target.checked,
                                                        },
                                                    },
                                                }));
                                            }}
                                            checked={field.value}
                                        />
                                    )}
                                />
                                <Typography variant="body2">{t('common.required')}</Typography>
                            </PlatformWrapper>
                        ) : (
                            <div />
                        )}
                    </FieldWrapper>
                    <FieldWrapper>
                        <Controller
                            control={control}
                            name="contactPhone"
                            render={({ field, ref }) => (
                                <Switch
                                    {...field}
                                    ref={ref}
                                    checked={getValues('contactPhone')}
                                    handleChange={event => {
                                        setValue('contactPhone', event.target.checked, { shouldDirty: true });
                                        if (!event.target.checked) {
                                            setValue('contactPhoneRequired', false, { shouldDirty: true });
                                        }
                                        setPreview(prev => ({
                                            ...prev,
                                            systemFields: {
                                                ...prev.systemFields,
                                                Phone: {
                                                    ...prev.systemFields.Phone,
                                                    enable: event.target.checked,
                                                    required: event.target.checked
                                                        ? getValues('contactPhoneRequired')
                                                        : false,
                                                },
                                            },
                                        }));
                                    }}
                                    label={t('FeedbackSurveys.contactPhone')}
                                    disabled={isView}
                                />
                            )}
                        />
                        {phoneField.enable ? (
                            <PlatformWrapper>
                                <Controller
                                    control={control}
                                    name="contactPhoneRequired"
                                    render={({ field, ref }) => (
                                        <Checkbox
                                            ref={ref}
                                            disabled={isView}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={e => {
                                                setValue('contactPhoneRequired', e.target.checked, {
                                                    shouldDirty: true,
                                                });
                                                setPreview(prev => ({
                                                    ...prev,
                                                    systemFields: {
                                                        ...prev.systemFields,
                                                        Phone: {
                                                            ...prev.systemFields.Phone,
                                                            required: e.target.checked,
                                                        },
                                                    },
                                                }));
                                            }}
                                            checked={field.value}
                                        />
                                    )}
                                />
                                <Typography variant="body2">{t('common.required')}</Typography>
                            </PlatformWrapper>
                        ) : (
                            <div />
                        )}
                    </FieldWrapper>
                    <FieldWrapper>
                        <Controller
                            control={control}
                            name="contactName"
                            render={({ field, ref }) => (
                                <Switch
                                    {...field}
                                    ref={ref}
                                    checked={getValues('contactName')}
                                    handleChange={event => {
                                        setValue('contactName', event.target.checked, { shouldDirty: true });
                                        if (!event.target.checked) {
                                            setValue('contactNameRequired', false, { shouldDirty: true });
                                        }
                                        setPreview(prev => ({
                                            ...prev,
                                            systemFields: {
                                                ...prev.systemFields,
                                                Name: {
                                                    ...prev.systemFields.Name,
                                                    enable: event.target.checked,
                                                    required: event.target.checked
                                                        ? getValues('contactNameRequired')
                                                        : false,
                                                },
                                            },
                                        }));
                                    }}
                                    label={t('FeedbackSurveys.contactName')}
                                    disabled={isView}
                                />
                            )}
                        />
                        {nameField.enable ? (
                            <PlatformWrapper>
                                <Controller
                                    control={control}
                                    name="contactNameRequired"
                                    render={({ field, ref }) => (
                                        <Checkbox
                                            ref={ref}
                                            disabled={isView}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={e => {
                                                setValue('contactNameRequired', e.target.checked, {
                                                    shouldDirty: true,
                                                });
                                                setPreview(prev => ({
                                                    ...prev,
                                                    systemFields: {
                                                        ...prev.systemFields,
                                                        Name: { ...prev.systemFields.Name, required: e.target.checked },
                                                    },
                                                }));
                                            }}
                                            checked={field.value}
                                        />
                                    )}
                                />
                                <Typography variant="body2">{t('common.required')}</Typography>
                            </PlatformWrapper>
                        ) : (
                            <div />
                        )}
                    </FieldWrapper>
                    <div>
                        {fields.map((field, index) => (
                            <AdditionalField key={field.id} index={index} isView={isView} setPreview={setPreview} />
                        ))}
                    </div>
                    <Button
                        variant="outlined"
                        sx={{ width: 'max-content', margin: '16px 0 24px' }}
                        startIcon={<AddIcon />}
                        disabled={isView}
                        onClick={() => {
                            append({ name: '', type: CustomFieldType.TEXT, required: false });
                            setPreview(prev => ({
                                ...prev,
                                customFields: [
                                    ...prev.customFields,
                                    { name: '', type: CustomFieldType.TEXT, required: false },
                                ],
                            }));
                        }}
                    >
                        {t('FeedbackSurveys.addField')}
                    </Button>
                    <Controller
                        control={control}
                        name="labels"
                        render={({ field, ref }) => (
                            <MultiSelectLabels
                                {...field}
                                ref={ref}
                                onChange={({ target: { value } }) => {
                                    setValue('labels', getMultiselectSelectedOptions(labelsOptions, value), {
                                        shouldDirty: true,
                                    });
                                }}
                                options={labelsOptions}
                                label={t('common.labels')}
                                fullWidth
                                type={LABEL_TYPE.CONTACT}
                                onCreateCallback={newLabel => {
                                    const values = getValues();
                                    setValue('labels', [...values.labels, newLabel], {
                                        shouldDirty: true,
                                    });
                                }}
                            />
                        )}
                    />
                </>
            )}
            <div style={{ paddingTop: 8 }}>
                <Typography variant="subtitle1">{t('FeedbackSurveys.reviewSites')}</Typography>
                <div style={{ paddingTop: 16 }}>
                    {availablePlatforms.map((platform, index) => (
                        <PlatformWrapper key={index}>
                            <Controller
                                control={control}
                                name="reviewPlatforms"
                                render={({ field, ref }) => (
                                    <Checkbox
                                        ref={ref}
                                        disabled={isView}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        onChange={e => {
                                            const platforms = !e.target.checked
                                                ? field.value.filter(val => val !== platform.type)
                                                : [...field.value, platform.type];
                                            setValue('reviewPlatforms', platforms, { shouldDirty: true });
                                            setPreview(prev => ({ ...prev, reviewPlatforms: platforms }));
                                        }}
                                        checked={field.value.includes(platform.type)}
                                    />
                                )}
                            />
                            <Typography variant="body2">{ProfileType[platform.type]}</Typography>
                        </PlatformWrapper>
                    ))}
                </div>
            </div>
        </>
    );
};

export default General;
