import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import { WhatsAppSuccessIcon, WhatsAppFailIcon } from 'assets/images/channels';
import { STATUS } from '@constants/channels';
import { useTranslation } from '@hooks/useTranslation';

const Container = styled('div')({
    height: 215,
    display: 'flex',
    alignItems: 'center',
    gap: 75,
    justifyContent: 'center',
    '@media (max-width: 700px)': {
        height: '100%',
        flexDirection: 'column',
        gap: 32,
    },
});

const WhatsAppStatus = ({ status }) => {
    const { t } = useTranslation();

    return (
        <Container>
            <Typography variant="caption" color="textSecondary">
                {status === STATUS.SUCCESS ? t('SettingsChannels.whatsAppSuccess') : t('SettingsChannels.whatsAppFail')}
            </Typography>
            {status === STATUS.SUCCESS ? <WhatsAppSuccessIcon /> : <WhatsAppFailIcon />}
        </Container>
    );
};

export default WhatsAppStatus;
