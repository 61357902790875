import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@components/Typography';
import Drawer from '@components/Drawer';
import { UserIcon } from 'assets/images/icons';

export const StyledDrawer = styled(Drawer)({
    '& .drawer-content': {
        padding: '0 24px',
    },
});

export const Content = styled('div')({
    height: 'calc(var(--100vh) - 198px)',
    overflowY: 'auto',
});

export const StyledIconButton = styled(IconButton, {
    shouldForwardProp: prop => prop !== 'iconSize',
})(({ iconSize }) => ({
    position: 'relative',
    height: iconSize,
    width: iconSize,
    padding: 2,
    '& svg': {
        width: iconSize,
        height: iconSize,
    },
}));

export const Badge = styled(Typography, { shouldForwardProp: prop => prop !== 'isLarge' })(({ isLarge }) => ({
    height: isLarge ? 14 : 12,
    width: isLarge ? 14 : 12,
    fontSize: `${isLarge ? 7 : 6}px !important`,
    lineHeight: `${isLarge ? 7 : 6}px`,
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#fff',
    backgroundColor: '#B00020',
    padding: '2px 6px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const CssRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#1F4C5C',
    },
});

export const StyledUserIcon = styled(UserIcon)({
    width: '18px !important',
    height: '18px !important',
    '& path': {
        fill: 'rgba(0, 0, 0, 0.38)',
        fillOpacity: 1,
    },
});

export const CardWrapper = styled('div')({
    marginBottom: '8px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    padding: '16px',
    '&:nth-of-type(1)': {
        marginTop: '24px',
    },
    '&:nth-last-of-type(1)': {
        marginBottom: '24px',
    },
    '&:hover': {
        borderColor: '#1F4C5C',
    },
});

export const ContentWrapper = styled('div')({
    display: 'grid',
    gridGap: '8px',
    width: '100%',
    gridTemplateColumns: '40px auto',
    cursor: 'pointer',
});

export const IconWrapper = styled('span')({
    '& svg': {
        transform: 'translateY(0)',
    },
    '& svg path': {
        fill: '#1F4C5C',
        fillOpacity: 1,
    },
});
