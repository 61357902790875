import { useState, useMemo, useEffect, useRef } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { VIEW_TYPE } from '@constants/surveys';
import { ProfileName, LocationIntegrationName } from '@constants/integrations';
import PreviewSurvey from '../PreviewSurvey';
import PreviewQR from '../PreviewQR';
import General from './General';
import QRCustomization from './QRCustomization';
import Message from './Message';
import { useTranslation } from '@hooks/useTranslation';
import { TabsWrapper, Tabs, Modal } from './styles';

const Tab = {
    General: 0,
    Customization: 1,
    Message: 2,
};

export const excludedProfileTypes = [
    ProfileName['Booking.com'],
    LocationIntegrationName.iFood,
    LocationIntegrationName.CoverManager,
    LocationIntegrationName.Google,
    LocationIntegrationName.Facebook,
    ProfileName.YouTube,
    ProfileName.X,
    ProfileName.LinkedIn,
    ProfileName.Pinterest,
    ProfileName.Tiktok,
];

const EditModal = ({ modal, onCloseModal, selectedSurvey, onDelete, onSave, account, isSubmitting, isUser }) => {
    const { t } = useTranslation();
    const [tab, setTab] = useState(Tab.General);
    // general
    const [previewGeneral, setPreviewGeneral] = useState({
        systemFields: {},
        customFields: [],
        satisfactionSurveyEnabled: false,
        reviewPlatforms: [],
    });
    const [location, setLocation] = useState(null);
    // customization
    const [previewCustomization, setPreviewCustomization] = useState({
        topText: '',
        bottomText: '',
        backgroundColor: '',
        fillColor: '',
        qrLogoUrl: '',
        customizeQrCodeDesign: false,
    });
    // message
    const [previewMessage, setPreviewMessage] = useState({ followUpEnable: false });
    const [qrImage, setQRImage] = useState(null);
    const initRef = useRef(0);

    const SurveysSchema = yup.object().shape(
        {
            name: yup.string().required('validation.required'),
            customFields: yup.array().of(
                yup.object().shape({
                    name: yup.string().required('validation.required'),
                }),
            ),
            topText: yup.string().when('topText', {
                is: val => val?.length > 0,
                then: yup.string().max(42, 'validation.maxLength'),
                otherwise: yup.string().notRequired(),
            }),
            bottomText: yup.string().when('bottomText', {
                is: val => val?.length > 0,
                then: yup.string().max(42, 'validation.maxLength'),
                otherwise: yup.string().notRequired(),
            }),
            backgroundColor: yup.string().when('backgroundColor', {
                is: val => val?.length > 0,
                then: yup.string().matches(/^#?([a-f0-9]{6}|[a-f0-9]{3})$/gi, 'validation.hexFormat'),
                otherwise: yup.string().notRequired(),
            }),
            fillColor: yup.string().when('fillColor', {
                is: val => val?.length > 0,
                then: yup.string().matches(/^#?([a-f0-9]{6}|[a-f0-9]{3})$/gi, 'validation.hexFormat'),
                otherwise: yup.string().notRequired(),
            }),
            message: yup.string().when('message', {
                is: val => !val?.length,
                then: yup.string().notRequired(),
                otherwise: yup.string().required('validation.required'),
            }),
            followUpText: yup.string().when('followUpText', {
                is: val => !val?.length,
                then: yup.string().notRequired(),
                otherwise: yup.string().required('validation.required'),
            }),
        },
        [
            ['topText', 'topText'],
            ['bottomText', 'bottomText'],
            ['backgroundColor', 'backgroundColor'],
            ['fillColor', 'fillColor'],
            ['message', 'message'],
            ['followUpText', 'followUpText'],
        ],
    );

    const resolver = useYupValidationResolver(SurveysSchema);

    const {
        getValues,
        setValue,
        control,
        reset,
        formState: { isValid, isDirty },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            name: '',
            companyId: '',
            satisfactionSurveyEnabled: false,
            labels: [],
            reviewPlatforms: [],
            topText: '',
            bottomText: '',
            backgroundColor: '',
            fillColor: '',
            qrLogoUrl: '',
            customizeQrCodeDesign: false,
            landingPageEnable: false,
            socialMediaEnable: false,
            logoUrl: '',
            links: {},
            showReviews: false,
            // message
            message: '',
            followUpEnable: false,
            followUpDelay: 7,
            followUpText: '',
            overSurvey: 0,
            // collect information
            contactEmail: false,
            contactEmailRequired: false,
            contactPhone: true,
            contactPhoneRequired: false,
            contactName: true,
            contactNameRequired: false,
            customFields: [],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'customFields',
    });

    const companyOptions = useMemo(
        () => account?.companies?.map(c => ({ label: c.internalName, value: c.companyId })),
        [account],
    );

    const availablePlatforms = useMemo(() => {
        const company = account?.companies?.find(c => c.companyId === location);
        return (company?.profiles || []).filter(p => !excludedProfileTypes.includes(p.type));
    }, [location, account]);

    useEffect(() => {
        if (selectedSurvey && account && !location && modal.open) {
            setLocation(selectedSurvey.companyId);
        }
    }, [selectedSurvey, account, location, modal.open]);

    useEffect(() => {
        if (selectedSurvey && account && availablePlatforms.length && initRef.current === 0 && modal.open) {
            const reviewPlatforms = selectedSurvey.reviewPlatforms.filter(rP =>
                availablePlatforms.some(aP => aP.type === rP),
            );
            setValue('reviewPlatforms', reviewPlatforms);
            setPreviewGeneral(prev => ({ ...prev, reviewPlatforms }));
            initRef.current = 1;
        }
    }, [selectedSurvey, account, availablePlatforms, setValue, modal.open]);

    useEffect(() => {
        if (selectedSurvey && account && modal.open) {
            setValue('name', selectedSurvey.name, { shouldValidate: true });
            setValue('companyId', selectedSurvey.companyId);
            setValue('satisfactionSurveyEnabled', selectedSurvey.satisfactionSurveyEnabled);
            setValue('labels', selectedSurvey.labels || []);
            setValue('contactEmail', selectedSurvey.systemFields.Email.enable);
            setValue('contactEmailRequired', selectedSurvey.systemFields.Email.required);
            setValue('contactPhone', selectedSurvey.systemFields.Phone.enable);
            setValue('contactPhoneRequired', selectedSurvey.systemFields.Phone.required);
            setValue('contactName', selectedSurvey.systemFields.Name.enable);
            setValue('contactNameRequired', selectedSurvey.systemFields.Name.required);
            setValue(
                'customFields',
                selectedSurvey.customFields?.map(field => ({
                    name: field.name,
                    type: field.type,
                    required: field.required,
                })) || [],
            );

            setValue('topText', selectedSurvey.topText || '', { shouldValidate: true });
            setValue('bottomText', selectedSurvey.bottomText || '', { shouldValidate: true });
            setValue('backgroundColor', selectedSurvey.backgroundColor || '#fff', {
                shouldValidate: true,
            });
            setValue('fillColor', selectedSurvey.fillColor || '#1f4c5c', { shouldValidate: true });
            setValue('customizeQrCodeDesign', selectedSurvey.customizeQrCodeDesign);
            setValue('qrLogoUrl', selectedSurvey.qrLogoUrl);
            setValue('message', selectedSurvey.message || '', { shouldValidate: true });
            setValue('followUpEnable', selectedSurvey.followUpEnable);
            setValue('followUpDelay', selectedSurvey.followUpDelay || 7);
            setValue('followUpText', selectedSurvey.followUpText || '', { shouldValidate: true });
            setValue('overSurvey', selectedSurvey.overSurvey || 0);
            setPreviewGeneral({
                systemFields: selectedSurvey.systemFields,
                customFields: selectedSurvey.customFields,
                satisfactionSurveyEnabled: selectedSurvey.satisfactionSurveyEnabled,
                reviewPlatforms: [],
            });
            setPreviewCustomization({
                topText: selectedSurvey.topText || '',
                bottomText: selectedSurvey.bottomText || '',
                backgroundColor: selectedSurvey.backgroundColor || '#fff',
                fillColor: selectedSurvey.fillColor || '#1f4c5c',
                qrLogoUrl: selectedSurvey.qrLogoUrl || '',
                customizeQrCodeDesign: selectedSurvey.customizeQrCodeDesign,
            });
            setPreviewMessage({ followUpEnable: selectedSurvey.followUpEnable });
        }
    }, [selectedSurvey, setValue, account, modal.open]);

    const previewCompany = useMemo(
        () => account?.companies?.find(c => c.companyId === location) || {},
        [location, account],
    );

    const isView = modal.type === VIEW_TYPE.VIEW;

    const onClose = () => {
        onCloseModal();
        reset();
        setTab(Tab.General);
        setLocation(null);
        setPreviewGeneral({
            systemFields: {},
            customFields: [],
            satisfactionSurveyEnabled: false,
            reviewPlatforms: [],
        });
        setPreviewCustomization({ topText: '', bottomText: '', backgroundColor: '', fillColor: '' });
        setPreviewMessage({ followUpEnable: false });
        initRef.current = 0;
    };
    const slug = account?.companies?.find(c => c.companyId === location)?.slug || 'pr';
    const qrLink = `${process.env.REACT_APP_SURVEY_DOMAIN}/service-review/${slug}/${selectedSurvey?.externalId}`;
    return (
        <Modal
            isOpen={modal.open}
            handleClose={onClose}
            title={t('FeedbackSurveys.modalTitle', { surveyName: selectedSurvey?.name || '' })}
            onPrimaryAction={
                isUser
                    ? null
                    : () => {
                          onSave(getValues());
                          onClose();
                      }
            }
            primaryActionText={t('buttons.save')}
            primaryActionDisabled={isSubmitting || !isValid || !isDirty}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
            onAdditionalAction={isUser ? null : onDelete}
            additionalTextButton={t('buttons.delete')}
        >
            <FormProvider {...{ getValues, setValue, control, isValid, fields, append, remove }}>
                <div style={{ padding: '24px 16px', display: 'flex', flexDirection: 'column' }}>
                    <TabsWrapper>
                        <Tabs
                            value={tab}
                            onChange={setTab}
                            options={[
                                { value: Tab.General, label: t('FeedbackSurveys.tabSettings') },
                                { value: Tab.Customization, label: t('FeedbackSurveys.tabQR') },
                                { value: Tab.Message, label: t('FeedbackSurveys.tabMessageTemplate') },
                            ]}
                        />
                    </TabsWrapper>
                    {tab === Tab.General && (
                        <General
                            isView={isView}
                            account={account}
                            setLocation={setLocation}
                            preview={previewGeneral}
                            setPreview={setPreviewGeneral}
                            companyOptions={companyOptions}
                            availablePlatforms={availablePlatforms}
                        />
                    )}
                    {tab === Tab.Customization && (
                        <QRCustomization
                            isView={isView}
                            setPreview={setPreviewCustomization}
                            qrLink={qrLink}
                            preview={previewCustomization}
                            selectedSurvey={selectedSurvey}
                        />
                    )}
                    {tab === Tab.Message && (
                        <Message
                            isView={isView}
                            selectedSurvey={selectedSurvey}
                            preview={previewMessage}
                            setPreview={setPreviewMessage}
                            location={location}
                        />
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f2f2f2',
                    }}
                >
                    {tab === Tab.General || tab === Tab.Message ? (
                        <PreviewSurvey company={previewCompany} preview={previewGeneral} locale={account?.locale} />
                    ) : null}
                    {tab === Tab.Customization ? (
                        <PreviewQR
                            preview={previewCustomization}
                            externalId={selectedSurvey?.externalId}
                            image={qrImage}
                            setImage={setQRImage}
                        />
                    ) : null}
                </div>
            </FormProvider>
        </Modal>
    );
};

export default EditModal;
