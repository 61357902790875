import { styled } from '@mui/material/styles';

export const Container = styled('div')({
    marginBottom: 65,
    '@media (max-width: 1024px)': {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'calc(var(--100vh) - 221px)',
        marginBottom: 80,
    },
});

export const Content = styled('div')({
    display: 'grid',
    gridGap: 16,
});

export const Footer = styled('div')({
    position: 'fixed',
    display: 'flex',
    gap: 8,
    padding: '16px 24px',
    bottom: 0,
    background: 'white',
    left: 280,
    right: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    zIndex: 1,
    '@media (max-width: 1024px)': {
        left: 0,
        padding: 16,
    },
});
