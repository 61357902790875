import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

export const Container = styled('div')({
    backgroundColor: '#F2F2F2',
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(auto-fit, minmax(235px, 1fr))',
    gridAutoRows: 204,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '16px',
    minHeight: 'calc(var(--100vh) - 193px)',
    borderRadius: 4,
    '@media (max-width: 1024px)': {
        minHeight: 'calc(var(--100vh) - 197px)',
    },
});

export const Card = styled(Paper)({
    borderRadius: 4,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

export const Status = styled('p', {
    shouldForwardProp: prop => prop !== 'isConnected' && prop !== 'isHidden',
})(({ isConnected, isHidden }) => ({
    fontFamily: 'Rubik, sans-serif',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '12px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.38)',
    marginTop: 0,
    marginBottom: 16,
    ...(isConnected && {
        color: '#0B8D00 ',
    }),
    ...(isHidden && {
        display: 'none',
    }),
}));
