import { facebookConstants } from '@constants/facebook';
import { facebookIntegrationService } from '@services';

export const facebookActions = {
    getPages,
    getListing,
    setListing,
};

function getPages() {
    return dispatch => {
        dispatch(request());
        return facebookIntegrationService.getPages().then(
            data => {
                dispatch(success(data));
                return data;
            },
            error => dispatch(failure(error)),
        );
    };
}

function request() {
    return { type: facebookConstants.FACEBOOK_PROFILE_PAGES_REQUEST };
}

function success(data) {
    return { type: facebookConstants.FACEBOOK_PROFILE_PAGES_SUCCESS, data };
}

function failure(message) {
    return { type: facebookConstants.FACEBOOK_PROFILE_PAGES_ERROR, message };
}

function getListing(profileId) {
    return dispatch => {
        dispatch(requestListing());
        return facebookIntegrationService.getLocationListing(profileId).then(
            data => {
                dispatch(setListing(data));
            },
            error => dispatch(failureListing(error)),
        );
    };
}

function requestListing() {
    return { type: facebookConstants.FACEBOOK_LISTING_PROFILE_REQUEST };
}

function setListing(data) {
    return { type: facebookConstants.FACEBOOK_LISTING_PROFILE_SUCCESS, data };
}

function failureListing(message) {
    return { type: facebookConstants.FACEBOOK_LISTING_PROFILE_ERROR, message };
}
