import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ContactChip from '@pages/ContactsPage/ContactChip';
import Autocomplete from '@components/Autocomplete';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import Avatar from '@components/Avatar';
import { useTranslation } from '@hooks/useTranslation';
import { getInitialsFull, getUserContacts } from '@helpers/userName';
import { useThrottleValue } from '@hooks/useThrottleValue';
import { contactsActions, reviewActions, alertActions } from '@actions';
import { reviewsService } from '@services';
import { UserIcon } from 'assets/images/icons';

const StyledUserIcon = styled(UserIcon)({
    width: '12px !important',
    height: '12px !important',
    '& path': {
        fill: 'rgba(0, 0, 0, 0.38)',
        fillOpacity: 1,
    },
});

const AutocompleteWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    '@media (max-width: 700px)': {
        width: '100%',
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    width: '100%',
});

const StyledIconButton = styled(IconButton)({
    padding: '4px',
    '& svg path': {
        fill: 'rgba(0, 0, 0, 0.6)',
    },
});

const AssignContact = ({
    contactId,
    contactName,
    contactPhoneNumber,
    contactEmail,
    reviewId,
    onClickContactDetails,
}) => {
    const [search, setSearch] = useState({ open: false, query: '', value: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();

    const { searchedContacts } = useSelector(state => state.contacts);
    const reviews = useSelector(state => state.reviews.reviews || []);
    const dispatch = useDispatch();

    const throttledSearch = useThrottleValue(search.query, 1000);

    useEffect(() => {
        if (throttledSearch.length === 0) {
            dispatch(contactsActions.resetSearchedContacts());
            return;
        }
        if (throttledSearch.length >= 2) {
            dispatch(contactsActions.getSearchedContacts({ searchQuery: throttledSearch }, 0, 10));
        }
    }, [throttledSearch]);

    const onAssign = async items => {
        const value = items.length ? items[0].id : '';
        setSearch(prev => ({ ...prev, value }));
        setIsSubmitting(true);
        try {
            const updatedReview = await reviewsService.assignContact({ contactId: value }, reviewId);
            const reviewsCopy = reviews.slice();
            const reviewIndex = reviewsCopy.findIndex(r => r.reviewId === reviewId);
            if (reviewIndex !== -1 && updatedReview) {
                reviewsCopy[reviewIndex] = updatedReview;
                dispatch(reviewActions.updateReviews(reviewsCopy));
            }
            setSearch({ open: false, query: '', value: '' });
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
        setIsSubmitting(false);
    };

    const onUnassign = async () => {
        setIsSubmitting(true);
        try {
            const updatedReview = await reviewsService.unassignContact(reviewId);
            const reviewsCopy = reviews.slice();
            const reviewIndex = reviewsCopy.findIndex(r => r.reviewId === reviewId);
            if (reviewIndex !== -1 && updatedReview) {
                reviewsCopy[reviewIndex] = updatedReview;
                dispatch(reviewActions.updateReviews(reviewsCopy));
            }
            setSearch({ open: false, query: '', value: '' });
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
        setIsSubmitting(false);
    };
    const [firstName, lastName] = (contactName || '').split(' ');

    return contactId ? (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ cursor: 'pointer' }} onClick={() => onClickContactDetails(contactId, 'view')}>
                <Avatar
                    size="small"
                    fullName={contactName}
                    label={getInitialsFull(contactName)}
                    icon={<StyledUserIcon />}
                />
            </div>
            <Typography
                variant="body2"
                sx={{ cursor: 'pointer' }}
                onClick={() => onClickContactDetails(contactId, 'view')}
            >
                {getUserContacts(
                    {
                        firstName,
                        lastName,
                        phoneNumber: contactPhoneNumber,
                        email: contactEmail,
                    },
                    '-',
                )}
            </Typography>
            <IconButton
                sx={{ padding: '2px', transform: 'translateX(-2px)' }}
                disabled={isSubmitting}
                onClick={onUnassign}
            >
                <CloseIcon sx={{ color: 'rgba(0, 0, 0, 0.87)', width: '20px', height: '20px' }} />
            </IconButton>
        </div>
    ) : search.open ? (
        <AutocompleteWrapper>
            <StyledAutocomplete
                label={t('ReviewsManage.assignContact')}
                options={searchedContacts}
                ValueComponent={ContactChip}
                OptionComponent={ContactChip}
                readOnly={isSubmitting}
                inputProps={{
                    value: search.query,
                    onChange: e => setSearch(prev => ({ ...prev, query: e.target.value })),
                    style: { display: search.value.length > 0 ? 'none' : 'block' },
                }}
                onChangeAutoComplete={onAssign}
                sx={{ width: '250px', '@media (max-width: 700px)': { width: '100%' } }}
            />
            <StyledIconButton onClick={() => setSearch({ open: false, query: '', value: '' })}>
                <CloseIcon sx={{ color: 'rgba(0, 0, 0, 0.87)', width: '20px', height: '20px' }} />
            </StyledIconButton>
        </AutocompleteWrapper>
    ) : (
        <Button
            variant="text"
            startIcon={<AddIcon />}
            disabled={isSubmitting}
            onClick={() => setSearch(prev => ({ ...prev, open: true }))}
        >
            {t('ReviewsManage.assignContact')}
        </Button>
    );
};

export default AssignContact;
