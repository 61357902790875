import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { ProfileName } from '@constants/integrations';
import { alertActions, accountActions } from '@actions';
import { validateUrl } from '@helpers/connect-integration';
import { companyService } from '@services';
import Drawer from '@components/Drawer';
import { Select } from '@components/Select';
import { PROFILES_DICTIONARY } from '@constants/integrations';
import { Input } from '@components/Input';
import { useTranslation } from '@hooks/useTranslation';
import GoogleProfile from './GoogleProfile';

export const PROFILE_MODE = { ADD: 0, EDIT: 1 };

const GeneralConnect = ({ profile, setProfile, selectedCompany, setSelectedCompany }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const profilesOptions = PROFILES_DICTIONARY.filter(
        p =>
            !(selectedCompany?.profiles || []).some(sP =>
                profile.mode === PROFILE_MODE.ADD
                    ? sP.type === p.type
                    : sP.type === p.type && sP.type !== profile.data?.type,
            ),
    ).map(p => ({ value: p.type, label: p.name }));

    const ProfileSchema = yup.object().shape(
        {
            profileUrl: yup
                .string()
                .required('validation.required')
                .test('test-url', 'validation.invalidURL', (value, ctx) => {
                    if (+ctx.parent.profileType === ProfileName.Google) return true;
                    return validateUrl(value, ctx.parent.profileType);
                }),
            profileType: yup.string().required('validation.required'),
        },
        [['profileType', 'profileType']],
    );

    const resolver = useYupValidationResolver(ProfileSchema);

    const {
        getValues,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver: resolver,
        defaultValues: {
            profileUrl: '',
            profileType: profile.mode === PROFILE_MODE.ADD ? profilesOptions[0]?.value : profile.data?.type,
        },
    });

    useEffect(() => {
        if (profile.mode === PROFILE_MODE.EDIT) {
            setValue('profileUrl', profile.data.businessUrl, { shouldValidate: true });
        }
    }, [profile]);

    const onSubmit = async () => {
        const { profileUrl, profileType } = getValues();
        try {
            const updatedCompany = await companyService.editProfile({
                companyId: selectedCompany?.companyId,
                type: profileType,
                url: profileUrl,
            });
            setSelectedCompany(updatedCompany);
            await dispatch(accountActions.get());
            dispatch(alertActions.success(t('SettingsCompanies.connectIntegrationMsg')));
            setProfile(prev => ({ ...prev, open: false, data: null, mode: PROFILE_MODE.ADD }));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
    };

    const SELECTED_PROFILE = watch('profileType');

    return (
        <Drawer
            anchor="right"
            open={profile.open}
            onClose={() => setProfile(prev => ({ ...prev, open: false, mode: PROFILE_MODE.ADD }))}
            title={t(
                profile.mode === PROFILE_MODE.ADD ? 'SettingsCompanies.addNewProfile' : 'SettingsCompanies.editProfile',
            )}
            primaryText={t('buttons.connect')}
            primaryDisabled={isSubmitting || !isValid || !isDirty}
            primaryAction={handleSubmit(onSubmit)}
            secondaryText={t('buttons.cancel')}
        >
            <Controller
                control={control}
                name="profileType"
                render={({ field, ref }) => (
                    <Select
                        {...field}
                        ref={ref}
                        label={t('common.platform')}
                        fullWidth
                        options={profilesOptions}
                        disabled={profile.mode === PROFILE_MODE.EDIT}
                        style={{ marginBottom: '8px' }}
                    />
                )}
            />
            {SELECTED_PROFILE !== ProfileName.Google ? (
                <Controller
                    control={control}
                    name="profileUrl"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field }}
                            label={t('SettingsCompanies.profileURL')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                        />
                    )}
                />
            ) : (
                <FormProvider {...{ setValue }}>
                    <Controller control={control} name="profileUrl" render={() => <GoogleProfile />} />
                </FormProvider>
            )}
        </Drawer>
    );
};

export default GeneralConnect;
