import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';

export const Container = styled('section')({
    height: 'calc(var(--100vh) - 168px)',
    backgroundColor: '#F2F2F2',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    overflowY: 'auto',
    borderRadius: 4,
    '@media (max-width: 1024px)': {
        height: 'calc(var(--100vh) - 172px)',
        margin: '0 -16px',
    },
});

export const IconWrapper = styled('span')({
    height: '20px',
    '& svg': {
        height: '20px',
        width: '20px',
        fill: '#1F4C5C',
    },
});

export const ReplyConnection = styled('span')({
    content: '""',
    width: '2px',
    height: 'calc(100% + 32px)',
    position: 'absolute',
    zIndex: 1,
    left: '19px',
    top: '-16px',
    display: 'block',
    background: '#F2F2F2',
});

const KEYWORD_COLOR_MAP = {
    1: { color: '#0B8D00', backgroundColor: 'rgba(11, 141, 0, 0.1)' }, // positive
    0: { color: '#F68C15', backgroundColor: 'rgba(255, 135, 0, 0.15)' }, // neutral
    2: { color: '#B00020', backgroundColor: 'rgba(176, 0, 32, 0.1)' }, // negative
};

export const Keyword = styled(Typography, {
    shouldForwardProp: prop => prop !== 'type',
})(({ type }) => ({
    padding: '2px 6px',
    color: KEYWORD_COLOR_MAP[type].color,
    backgroundColor: KEYWORD_COLOR_MAP[type].backgroundColor,
    borderRadius: 18,
    height: 'fit-content',
}));

export const KeywordsContainer = styled('div')({
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap',
    width: 'calc(100% - 250px)',
    '@media (max-width: 700px)': {
        width: '100%',
    },
});

export const FooterWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'hasKeywords',
})(({ hasKeywords }) => ({
    justifyContent: !hasKeywords ? 'flex-end' : 'space-between',
    width: '100%',
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 700px)': {
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: '8px',
    },
}));
