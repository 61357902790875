import { styled } from '@mui/material/styles';
import { Table } from '@components/Table';

export const StyledTable = styled(Table)({
    '& .MuiTableBody-root .MuiTableCell-root:last-child': {
        width: 150,
    },
});

export const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: '16px',
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

const KEYWORD_COLOR_MAP = {
    1: '#4CC474', // positive
    0: '#FFCE4D', // neutral
    2: '#FF5853', // negative
};

export const SentimentWrapper = styled('div')({
    width: 168,
    display: 'flex',
    height: 8,
});

export const SentimentPiece = styled('div', {
    shouldForwardProp: prop => prop !== 'type' && prop !== 'percentage',
})(({ type, percentage }) => ({
    backgroundColor: KEYWORD_COLOR_MAP[type],
    width: `${percentage}%`,
}));
