import { inboxConstants } from '@constants/inbox';

const initialState = {
    loading: false,
    data: [],
};

export function inbox(state = initialState, action) {
    switch (action.type) {
        case inboxConstants.INBOX_HISTORY_FETCH:
            return { ...state, loading: true };
        case inboxConstants.INBOX_HISTORY_SUCCESS:
            return { ...state, loading: false, data: action.data };
        case inboxConstants.INBOX_HISTORY_UPDATE:
            return { ...state, data: action.data };
        case inboxConstants.INBOX_RESET:
            return initialState;
        default:
            return state;
    }
}
