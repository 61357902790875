import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDate } from 'date-fns';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import sortBy from 'lodash.sortby';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { useTranslation } from '@hooks/useTranslation';
import { CHANNEL } from '@constants/channels';
import { getChannelOptions, getCommonChannelsByCompanyIds } from '@helpers/channels';
import { accountActions, alertActions, campaignsActions, channelsActions } from '@actions';
import { campaignsService } from '@services';
import { MODAL_TYPE } from '@constants/modal';
import { getSurveysOptions } from '@helpers/send-invites';
import { CAMPAIGN_TYPE, MAX_LENGTH } from '@constants/campaigns';
import ContentSource, { MESSAGE_TYPE } from './ContentSource';
import CoreFormFields from './CoreFormFields';
import AutomatedFields from './AutomatedFields';
import LabelsFields from './LabelsField';
import { StyledTimeframePicker, Modal, Content } from './styles';

const EditModal = ({
    modal,
    setModal,
    campaignType,
    companies,
    selectedCampaign,
    setSelectedCampaign,
    rowsPerPageRequests,
}) => {
    const { t } = useTranslation();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [channelOptions, setChannelOptions] = useState([]);
    const [contactsNumber, setContactsNumber] = useState(0);
    const [channelType, setChannelType] = useState(CHANNEL.SMS);
    const [messageType, setMessageType] = useState(MESSAGE_TYPE.MESSAGE);

    const contactsNumberRef = useRef(0);

    const { contact: labels, location: locationLabels } = useSelector(state => state.labels);
    const { data } = useSelector(state => state.campaigns);
    const channels = useSelector(state => state.channels.data);
    const dispatch = useDispatch();

    const availableLabels = [...new Set(companies.map(item => item.labels).flat())];

    const IS_EMAIL = channelType === CHANNEL.EMAIL && messageType === MESSAGE_TYPE.MESSAGE;

    const CampaignSchema = yup.object().shape({
        campaignType: yup.number(),
        messageType: yup.number(),
        title: yup.string().required('validation.required'),
        message: yup.string().when('messageType', {
            is: messageType => messageType !== MESSAGE_TYPE.MESSAGE,
            then: yup.string().nullable(),
            otherwise: yup.string().required('validation.required'),
        }),
        subject: yup.string().when('messageType', {
            is: () => !IS_EMAIL,
            then: yup.string().nullable(),
            otherwise: yup
                .string()
                .required('validation.required')
                .max(MAX_LENGTH[channelType], 'validation.maxLength'),
        }),
        coverPhotoEnabled: yup.boolean(),
        coverPhotoUrl: yup.string().when('coverPhotoEnabled', {
            is: val => !!val && IS_EMAIL,
            then: yup.string().required('validation.required'),
            otherwise: yup.string().nullable(),
        }),
        ctaButtonEnabled: yup.boolean(),
        ctaTitle: yup.string().when('ctaButtonEnabled', {
            is: val => !!val && IS_EMAIL,
            then: yup.string().required('validation.required'),
            otherwise: yup.string().nullable(),
        }),
        ctaLink: yup.string().when('ctaButtonEnabled', {
            is: val => !!val && IS_EMAIL,
            then: yup.string().url('validation.invalidURL').required('validation.required'),
            otherwise: yup.string().nullable(),
        }),
        ctaColor: yup.string().when('ctaButtonEnabled', {
            is: val => !!val && IS_EMAIL,
            then: yup.string().required('validation.required'),
            otherwise: yup.string().nullable(),
        }),
        channelId: yup.string().required('validation.required'),
        locations: yup.array().min(1, 'validation.required'),
        labels: yup.array().when('campaignType', {
            is: () => campaignType === CAMPAIGN_TYPE.ONGOING,
            then: yup.array().notRequired(),
            otherwise: yup.array().test('messages-quota', 'messages-quota', () => contactsNumberRef.current > 0),
        }),
        scheduledTime: yup.string().when('campaignType', {
            is: () => campaignType === CAMPAIGN_TYPE.ONGOING,
            then: yup.string().notRequired(),
            otherwise: yup.string().nullable().required('validation.required'),
        }),
        triggers: yup.array().when('campaignType', {
            is: () => campaignType === CAMPAIGN_TYPE.ONGOING,
            then: yup.array().min(1, 'validation.required'),
            otherwise: yup.array().notRequired(),
        }),
        delay: yup.number(),
        surveyTemplateId: yup.string().when('messageType', {
            is: messageType => messageType === MESSAGE_TYPE.MESSAGE,
            then: yup.string().notRequired(),
            otherwise: yup.string().nullable().required('validation.required'),
        }),
    });

    const resolver = useYupValidationResolver(CampaignSchema);

    const {
        getValues,
        setValue,
        control,
        reset,
        watch,
        trigger,
        formState: { isValid, isDirty },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            title: '',
            message: '',
            subject: '',
            channelId: '',
            locations: companies.map(c => c.companyId),
            labels: [],
            scheduledTime: '',
            triggers: [1, 5, 7, 8],
            delay: 0,
            surveyTemplateId: '',
            locationLabels: locationLabels.filter(l => availableLabels.includes(l.id)).map(item => item.id),
            coverPhotoEnabled: false,
            coverPhotoUrl: '',
            ctaButtonEnabled: false,
            ctaTitle: '',
            ctaLink: '',
            ctaColor: '',
            messageType: MESSAGE_TYPE.MESSAGE,
        },
    });

    const selectedSurveyTemplateId = watch('surveyTemplateId');
    const selectedChannelId = watch('channelId');
    const coverPhotoEnabled = watch('coverPhotoEnabled');
    const ctaButtonEnabled = watch('ctaButtonEnabled');

    useEffect(() => {
        dispatch(channelsActions.get());
    }, []);

    useEffect(() => {
        if (campaignType === CAMPAIGN_TYPE.ONETIME) {
            dispatch(accountActions.updateQuota());
        }
    }, [dispatch, campaignType]);

    useEffect(() => {
        const isNewCampaign = modal.type === MODAL_TYPE.CREATE;
        if (!isNewCampaign) {
            setValue('title', selectedCampaign?.title || '', { shouldValidate: true });
            setValue('message', selectedCampaign?.message || '', { shouldValidate: true });
            setValue('subject', selectedCampaign?.subject || '', { shouldValidate: true });
            setValue('coverPhotoEnabled', !!selectedCampaign?.coverPhotoEnabled);
            setValue('coverPhotoUrl', selectedCampaign?.coverPhotoUrl || '', { shouldValidate: true });
            setValue('ctaButtonEnabled', !!selectedCampaign?.ctaButtonEnabled);
            setValue('ctaTitle', selectedCampaign?.ctaTitle || '', { shouldValidate: true });
            setValue('ctaLink', selectedCampaign?.ctaLink || '', { shouldValidate: true });
            setValue('ctaColor', selectedCampaign?.ctaColor || '', { shouldValidate: true });
            setValue(
                'channelId',
                selectedCampaign?.channelId === CHANNEL.ARCHIVED ? 'ARCHIVED' : selectedCampaign?.channelId,
                {
                    shouldValidate: true,
                },
            );
            setValue('locations', selectedCampaign?.locations, {
                shouldValidate: true,
            });
            setValue('labels', selectedCampaign?.labels.map(l => l.id) || []);
            if (campaignType === CAMPAIGN_TYPE.ONETIME) {
                setValue('scheduledTime', new Date(selectedCampaign?.scheduledTime), {
                    shouldValidate: true,
                });
            } else {
                setValue('delay', selectedCampaign?.delay);
                setValue('triggers', selectedCampaign?.triggers || []);
            }
            if (selectedCampaign?.surveyTemplateId?.length > 0) {
                setValue('surveyTemplateId', selectedCampaign?.surveyTemplateId);
                setMessageType(MESSAGE_TYPE.SURVEY);
            }
            const channel = channels.find(c => c.id === selectedCampaign?.channelId);
            setChannelType(channel?.type || CHANNEL.SMS);
        }
        const availableChannels = getCommonChannelsByCompanyIds(
            isNewCampaign ? companies.map(c => c.companyId) : selectedCampaign?.locations,
            companies,
        );
        const newChannelOptions = sortBy(
            getChannelOptions({ out: true, withArchivedSelect: isView }, availableChannels, channels),
            ['name'],
        );

        setChannelOptions(newChannelOptions);
        if (isNewCampaign) {
            setValue('channelId', newChannelOptions[0]?.value);
            setChannelType(newChannelOptions[0]?.type || CHANNEL.SMS);
        }
    }, [selectedCampaign, companies, channels, setValue, modal.type, campaignType]);

    useEffect(() => {
        if (campaignType === CAMPAIGN_TYPE.ONETIME) {
            getContactsNumber();
        }
    }, []);

    const onCloseModal = () => {
        setModal({ open: false, type: MODAL_TYPE.CREATE });
        setSelectedCampaign({});
        reset();
    };

    const getContactsNumber = async () => {
        const values = getValues();
        try {
            const response = await campaignsService.getSelectedContactsNumber({
                ...selectedCampaign,
                ...values,
                scheduledTime: isDate(values.scheduledTime)
                    ? new Date(values.scheduledTime).toISOString()
                    : new Date().toISOString(),
                labels: values.labels.length > 0 ? labels.filter(label => values.labels.includes(label.id)) : [],
                ...(modal.type === MODAL_TYPE.EDIT && { id: selectedCampaign?.id }),
            });
            setContactsNumber(response?.count);
            contactsNumberRef.current = response?.count;
            trigger('labels');
        } catch (_) {
            console.error("Couldn't get contacts number");
        }
    };

    const onSaveCampaign = async () => {
        const values = getValues();
        setIsSubmitting(true);
        try {
            const newCampaign = await campaignsService.createOrUpdateCampaign(
                {
                    ...selectedCampaign,
                    ...values,
                    message: messageType === MESSAGE_TYPE.MESSAGE ? values.message : '',
                    surveyTemplateId: messageType === MESSAGE_TYPE.SURVEY ? values.surveyTemplateId : null,
                    ...(campaignType === CAMPAIGN_TYPE.ONETIME && {
                        scheduledTime: new Date(values.scheduledTime).toISOString(),
                    }),
                    labels: labels.filter(label => values.labels.includes(label.id)),
                    ...(modal.type === MODAL_TYPE.EDIT && { id: selectedCampaign?.id }),
                },
                campaignType,
            );
            setIsSubmitting(false);
            if (modal.type === MODAL_TYPE.CREATE) {
                dispatch(campaignsActions.get(0, rowsPerPageRequests, campaignType));
            } else {
                const campaignsCopy = data.slice();
                const updatedCampaignIndex = campaignsCopy.findIndex(c => c.id === selectedCampaign?.id);
                if (updatedCampaignIndex !== -1 && newCampaign) {
                    campaignsCopy[updatedCampaignIndex] = newCampaign;
                    dispatch(campaignsActions.setCampaigns(campaignsCopy));
                }
                setSelectedCampaign(newCampaign);
            }
            dispatch(
                alertActions.success(
                    modal.type === MODAL_TYPE.CREATE
                        ? t('alertMessages.createSuccess')
                        : t('alertMessages.editSuccess'),
                ),
            );
            onCloseModal();
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(
                    alertActions.error(
                        modal.type === MODAL_TYPE.CREATE ? t('alertMessages.createFail') : t('alertMessages.editFail'),
                    ),
                );
            }
            setIsSubmitting(false);
        }
    };

    const handleUpdateSurveyAndChannel = companyIds => {
        if (messageType === MESSAGE_TYPE.SURVEY) {
            const availableSurveys = getSurveysOptions(companyIds, companies);
            if (!availableSurveys.find(s => s.value === selectedSurveyTemplateId)) {
                setValue('surveyTemplateId', '', { shouldDirty: true, shouldValidate: true });
            }
        }
        const availableChannels = getCommonChannelsByCompanyIds(companyIds, companies);
        const newChannelOptions = sortBy(getChannelOptions({ out: true }, availableChannels, channels), [name]);
        setChannelOptions(newChannelOptions);
        if (!newChannelOptions.find(c => c.value === selectedChannelId)) {
            setValue('channelId', newChannelOptions[0]?.value, { shouldValidate: true, shouldDirty: true });
            setChannelType(newChannelOptions[0]?.type || CHANNEL.SMS);
        }
    };

    const isView = modal.type === MODAL_TYPE.VIEW;

    return (
        <Modal
            isOpen={modal.open}
            handleClose={onCloseModal}
            title={
                modal.type === MODAL_TYPE.CREATE
                    ? t('CampaignsPage.createTitle')
                    : isView
                    ? t('CampaignsPage.viewTitle')
                    : t('CampaignsPage.editTitle')
            }
            onPrimaryAction={isView ? null : onSaveCampaign}
            primaryActionText={
                modal.type === MODAL_TYPE.CREATE ? t('buttons.cheduleCampaign') : t('buttons.saveChanges')
            }
            primaryActionDisabled={isSubmitting || !isValid || !isDirty}
            onSecondaryAction={onCloseModal}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content
                isOneTime={campaignType === CAMPAIGN_TYPE.ONETIME}
                isEmail={IS_EMAIL}
                coverPhotoEnabled={coverPhotoEnabled}
                ctaButtonEnabled={ctaButtonEnabled}
            >
                <FormProvider {...{ setValue, watch, control, trigger }}>
                    <CoreFormFields
                        campaignType={campaignType}
                        isView={isView}
                        companies={companies}
                        locationLabels={locationLabels}
                        channels={channels}
                        channelOptions={channelOptions}
                        setChannelType={setChannelType}
                        handleUpdateSurveyAndChannel={handleUpdateSurveyAndChannel}
                        getContactsNumber={getContactsNumber}
                    />

                    {campaignType === CAMPAIGN_TYPE.ONETIME ? (
                        <Controller
                            control={control}
                            name="scheduledTime"
                            render={({ field, fieldState: { error } }) => (
                                <StyledTimeframePicker
                                    value={[field.value]}
                                    range={false}
                                    label={t('common.scheduledTime')}
                                    showTimeSelect
                                    required
                                    disabled={isView}
                                    dateFormat="d MMM yyyy, HH:mm"
                                    onBlurTimeSelect={field.onBlur}
                                    onChangeTimeframe={value => {
                                        setValue('scheduledTime', value, {
                                            shouldValidate: true,
                                            shouldDirty: true,
                                        });
                                    }}
                                    minDate={new Date()}
                                    maxDate={null}
                                    error={error}
                                />
                            )}
                        />
                    ) : (
                        <AutomatedFields isView={isView} />
                    )}
                    <LabelsFields
                        isView={isView}
                        campaignType={campaignType}
                        channelType={channelType}
                        getContactsNumber={getContactsNumber}
                        contactsNumber={contactsNumber}
                        labels={labels}
                    />
                    <ContentSource channelType={channelType} isView={isView} companies={companies} />
                </FormProvider>
            </Content>
        </Modal>
    );
};

export default EditModal;
