import { feedbacksConstants } from '@constants/feedbacks';

const initialState = {
    loading: true,
    hasMore: false,
    newFeedbacksExist: false,
    feedbacks: [],
};

export function feedbacks(state = initialState, action) {
    switch (action.type) {
        case feedbacksConstants.FEEDBACKS_REQUEST:
            return { ...state, loading: true };
        case feedbacksConstants.FEEDBACKS_SUCCESS:
            return {
                ...state,
                feedbacks: action.feedbacks.items,
                hasMore: action.feedbacks.hasMore,
                loading: false,
            };
        case feedbacksConstants.FEEDBACKS_LOAD_MORE_SUCCESS:
            return {
                ...state,
                feedbacks: [...state.feedbacks, ...action.feedbacks.items],
                hasMore: action.feedbacks.hasMore,
            };
        case feedbacksConstants.ADD_OR_UPDATE_TICKET:
        case feedbacksConstants.FEEDBACKS_UPDATE:
            return { ...state, feedbacks: action.feedbacks };
        case feedbacksConstants.NEW_FEEDBACK_EXIST_SUCCESS:
            return { ...state, newFeedbacksExist: action.payload };
        case feedbacksConstants.NEW_FEEDBACK_EXIST_RESET:
            return { ...state, newFeedbacksExist: false };
        case feedbacksConstants.RESET_LOADING:
            return { ...state, loading: false };
        default:
            return state;
    }
}
