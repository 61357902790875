import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Card from '@components/Card';
import { getExpiredAtInfo } from '@helpers/dates';
import { useTranslation } from '@hooks/useTranslation';
import { CURRENCY_MAP } from '@constants/billing';

const CardText = styled('span')(({ isExpiredDate }) => ({
    fontFamily: 'Rubik, sans-serif',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '12px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: isExpiredDate ? '#B00020' : 'rgba(0, 0, 0, 0.6)',
    marginTop: 0,
    marginBottom: '12px',
}));

const BillingCard = ({ subscription = {}, selectedSubscription, setSubscription, index }) => {
    const { t } = useTranslation();
    const language = useSelector(state => state.authentication.user?.language);

    const isExpiredDate = subscription?.billingDiffInDays < 0;
    const { expiredAt } = getExpiredAtInfo(subscription?.expiresAt, language);

    return (
        <Card
            type="radio"
            selected={index === Number(selectedSubscription)}
            title={subscription.stripeAccountName}
            value={selectedSubscription}
            index={index}
            setValue={setSubscription}
        >
            <CardText>
                {t('common.locations')} - {subscription.locationsCount}
            </CardText>
            <CardText>
                {t('SettingsGeneral.additionalUsers')} - {subscription.additionalUsersCount}
            </CardText>
            <CardText>
                {t('SettingsGeneral.totalPrice')} - {CURRENCY_MAP[subscription?.currency]}
                {subscription?.price}
            </CardText>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <CardText>{t('SettingsGeneral.nextPaymentOn')}&nbsp;</CardText>
                <CardText isExpiredDate={isExpiredDate}>{expiredAt}</CardText>
            </div>
        </Card>
    );
};

export default BillingCard;
