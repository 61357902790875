import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { AccIntegrationName } from '@constants/integrations';
import { accountActions, userActions, alertActions, googleActions } from '@actions';
import { companyService } from '@services';
import GTMHelper from '@helpers/gtm';
import GoogleCompanyItem from './GoogleCompanyItem';
import { useTranslation } from '@hooks/useTranslation';
import { Footer, Header, GoogleContentWrapper, GoogleFooter, Progress } from './styles';

const ViewGoogle = ({ onAddManuallyClick, onConnectAnotherAccountClick, user }) => {
    const { t } = useTranslation();
    const [companies, setCompanies] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const account = useSelector(state => state.account?.account);
    const LOCATIONS = useSelector(state => state.google.data);
    const locationsLoading = useSelector(state => state.google.loading);
    const dispatch = useDispatch();

    const GOOGLE_CONNECTED = account?.integrations.find(i => i.type === AccIntegrationName.Google)?.isActive;

    useEffect(() => {
        if (GOOGLE_CONNECTED && LOCATIONS.length === 0) {
            dispatch(googleActions.get());
        }
    }, [dispatch, GOOGLE_CONNECTED, LOCATIONS.length]);

    const onCheckCompany = index => {
        if (companies.includes(index)) {
            setCompanies(companies.filter(c => c !== index));
            return;
        }
        setCompanies([...new Set([...companies, index])]);
    };

    const onCheckAll = () => {
        if (companies.length === LOCATIONS.length) {
            setCompanies([]);
            return;
        }
        setCompanies([...new Set([...LOCATIONS.map((l, index) => index)])]);
    };

    const onComplete = async () => {
        setIsSubmitting(true);
        try {
            const data = companies.map(index => LOCATIONS[index]);
            await companyService.importGoogleCompanies(data);
            const updatedAcc = await dispatch(accountActions.get());
            await dispatch(
                accountActions.updateAccount({ ...updatedAcc, onboardingCompleted: true, onboardingStatus: 5 }),
            );
            GTMHelper.push('onboarding-completed');
            await dispatch(userActions.getUser(user?.id));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
        setIsSubmitting(false);
    };

    return (
        <>
            <Header>
                <Typography variant="h6">{t('OnboardingPage.titleGoogle')}</Typography>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '4px' }}>
                    {t('OnboardingPage.subtitleGoogle')}
                </Typography>
            </Header>
            <div style={{ gridArea: 'main', overflowY: 'auto' }}>
                {!locationsLoading ? (
                    <GoogleContentWrapper>
                        {LOCATIONS.map((l, index) => (
                            <GoogleCompanyItem
                                key={l.name}
                                location={l}
                                selectedCompanies={companies}
                                setCompany={onCheckCompany}
                                index={index}
                            />
                        ))}
                        <>
                            <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '16px' }}>
                                {t('OnboardingPage.canTryNext')}
                            </Typography>
                            <GoogleFooter>
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    onClick={onAddManuallyClick}
                                    disabled={isSubmitting}
                                >
                                    {t('buttons.addManually')}
                                </Button>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        margin: '0 12px',
                                        '@media (max-width: 600px)': {
                                            margin: '8px 0',
                                        },
                                    }}
                                >
                                    {t('common.or')}
                                </Typography>
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    onClick={onConnectAnotherAccountClick}
                                    disabled={isSubmitting}
                                >
                                    {t('buttons.connectAnotherAccount')}
                                </Button>
                            </GoogleFooter>
                        </>
                    </GoogleContentWrapper>
                ) : (
                    <Progress />
                )}
            </div>
            <Footer isSpaceBetween>
                <Button type="submit" variant="outlined" onClick={onCheckAll}>
                    {companies.length === LOCATIONS.length ? t('buttons.deselectAll') : t('buttons.selectAll')}
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    onClick={onComplete}
                    disabled={companies.length === 0 || isSubmitting}
                >
                    {t('buttons.complete')}
                </Button>
            </Footer>
        </>
    );
};

export default ViewGoogle;
