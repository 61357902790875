import { Trans } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { ReviewsEmptyIcon } from 'assets/images/icons';
import { Typography } from '@components/Typography';
import { InternalLink } from '@components/Link';
import { useClickSendSurveys } from '@hooks/useClickSendSurveys';
import { useTranslation } from '@hooks/useTranslation';

const Container = styled('div')({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const Wrapper = styled('div')({
    '& svg': {
        display: 'block',
        margin: '0 auto 24px',
    },
});

const ReviewsNotExist = () => {
    const { onClickSendSurveys } = useClickSendSurveys();
    const { t } = useTranslation();

    return (
        <Container>
            <Wrapper>
                <ReviewsEmptyIcon />
                <Typography variant="h4" sx={{ color: '#1F4C5C', marginBottom: '8px', textAlign: 'center' }}>
                    {t('ReviewsEmptyFeed.reviewsNotExistTitle')}
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'center' }}>
                    <Trans t={t} i18nKey={'ReviewsEmptyFeed.reviewsNotExistDescriptionEx'}>
                        <span
                            style={{ color: '#1F4C5C', cursor: 'pointer', textDecoration: 'none' }}
                            onClick={onClickSendSurveys}
                        >
                            {{ sendSurveys: t('ReviewsEmptyFeed.sendSurveys') }}
                        </span>
                        <InternalLink
                            to="/surveys/templates"
                            sx={{ color: '#1F4C5C', cursor: 'pointer', textDecoration: 'none' }}
                        >
                            {{ setupSurvey: t('ReviewsEmptyFeed.setupSurvey') }}
                        </InternalLink>
                    </Trans>
                </Typography>
            </Wrapper>
        </Container>
    );
};

export default ReviewsNotExist;
