import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

function get(query, page, pageSize) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(query),
    };
    return fetch(`${BASE_URL}api/profiles/syncable?page=${page}&pageSize=${pageSize}`, requestOptions).then(
        handleResponse,
    );
}

function startSync() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/profiles/syncable/start-sync`, requestOptions).then(handleResponse);
}

function resolveConflictsGoogle(profileId, data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/profiles/google/${profileId}/resolve-conflicts`, requestOptions).then(handleResponse);
}

function resolveConflictsFacebook(profileId, data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/profiles/facebook/${profileId}/resolve-conflicts`, requestOptions).then(
        handleResponse,
    );
}

function enableProfileSync(profileId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/profiles/syncable/${profileId}:enableSync`, requestOptions).then(handleResponse);
}

function disableProfileSync(profileId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/profiles/syncable/${profileId}:disableSync`, requestOptions).then(handleResponse);
}

export const profilesService = {
    get,
    startSync,
    resolveConflictsGoogle,
    resolveConflictsFacebook,
    enableProfileSync,
    disableProfileSync,
};
