import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteIcon } from 'assets/images/icons';
import { Typography } from '@components/Typography';
import { ModalConfirm } from '@components/Modal';
import Button from '@components/Button';
import { Input } from '@components/Input';
import { useTranslation } from '@hooks/useTranslation';
import { LocationIntegrationName, ProfileType, ProfileName } from '@constants/integrations';
import { alertActions, accountActions } from '@actions';
import { companyService } from '@services';
import GeneralConnect, { PROFILE_MODE } from './GeneralConnect';

const Container = styled('div')({
    maxWidth: 696,
    '@media (max-width: 1024px)': {
        maxWidth: 'none',
    },
});

const ProfileWrapper = styled('div')(({ isDisabled }) => ({
    display: 'grid',
    width: '100%',
    gridTemplateColumns: isDisabled ? '100%' : 'calc(100% - 84px) 32px 32px',
    gridGap: '24px 10px',
    alignItems: 'center',
    '& .MuiFormControl-root': {
        paddingBottom: '0 !important',
    },
}));

const StyledIconButton = styled(IconButton)({
    height: 32,
    padding: 2,
    '& svg': {
        width: 18,
        height: 18,
        '& path': {
            fill: 'rgba(0, 0, 0, 0.6)',
        },
    },
});

const Profiles = ({ selectedCompany, setSelectedCompany }) => {
    const [profile, setProfile] = useState({ open: false, data: null, mode: PROFILE_MODE.ADD });
    const [deleteModal, setDeleteModal] = useState({ open: false, id: null });
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const PROFILES = (selectedCompany?.profiles || [])
        .map(p => {
            const googleConnected = !!selectedCompany?.profiles.find(sP => sP.type === LocationIntegrationName.Google);
            const facebookConnected = !!selectedCompany?.profiles.find(
                sP => sP.type === LocationIntegrationName.Facebook,
            );
            return {
                ...p,
                disabled:
                    (p.type === ProfileName.Google && googleConnected) ||
                    ((p.type === ProfileName.Facebook || p.type === ProfileName.Instagram) && facebookConnected),
            };
        })
        .filter(p => p.type in ProfileType)
        .sort((a, b) => a.type - b.type);

    const onAddProfileClick = () => setProfile(prev => ({ ...prev, open: true, mode: PROFILE_MODE.ADD }));

    const onDeleteProfileClick = async () => {
        try {
            await companyService.deleteProfile(selectedCompany.companyId, deleteModal.id);
            setSelectedCompany(prev => ({
                ...prev,
                profiles: prev?.profiles?.filter(p => p.profileId !== deleteModal.id),
            }));
            await dispatch(accountActions.get());
            setDeleteModal(prev => ({ ...prev, open: false, id: null }));
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.deleteFail')));
            }
        }
    };

    const onEditProfileClick = data => setProfile(prev => ({ ...prev, open: true, data, mode: PROFILE_MODE.EDIT }));

    return (
        <Container>
            <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px' }}
            >
                <Typography
                    variant="subtitle1"
                    sx={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        '@media (max-width: 500px)': {
                            maxWidth: '180px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        },
                    }}
                >
                    {t('SettingsCompanies.onlineProfiles')}
                </Typography>
                <Button variant="outlined" startIcon={<AddIcon />} onClick={onAddProfileClick}>
                    {t('buttons.add')}
                </Button>
            </div>
            <div style={{ display: 'grid', width: '100%', gridTemplateColumns: '100%', gridGap: '24px' }}>
                {PROFILES.map(p => (
                    <ProfileWrapper isDisabled={p.disabled} key={p.type}>
                        <Input inputProps={{ value: p.businessUrl }} label={ProfileType[p.type]} disabled />
                        {!p.disabled ? (
                            <>
                                <StyledIconButton onClick={() => onEditProfileClick(p)}>
                                    <EditIcon />
                                </StyledIconButton>
                                <StyledIconButton
                                    onClick={() => setDeleteModal(prev => ({ ...prev, open: true, id: p.profileId }))}
                                >
                                    <DeleteIcon />
                                </StyledIconButton>
                            </>
                        ) : null}
                    </ProfileWrapper>
                ))}
            </div>
            {profile.open ? (
                <GeneralConnect
                    profile={profile}
                    setProfile={setProfile}
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                />
            ) : null}
            {deleteModal.open && (
                <ModalConfirm
                    isOpen={deleteModal.open}
                    handleClose={() => setDeleteModal(prev => ({ ...prev, open: false, id: null }))}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDeleteProfileClick}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                />
            )}
        </Container>
    );
};

export default Profiles;
