import { Typography } from '@components/Typography';
import { styled } from '@mui/material/styles';
import { useTranslation } from '@hooks/useTranslation';
import pluspointLogo from 'assets/images/pluspoint-logo.svg';
import authBg from 'assets/images/auth-bg.jpg';

const Root = styled('div')({
    backgroundColor: '#f2f2f2',
    minWidth: '527px',
    height: '100vh',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    '@media (max-width: 500px)': {
        minWidth: 'auto',
    },
    '@media (max-width: 1024px)': {
        height: 'var(--100vh)',
        overflow: 'auto',
        minWidth: 'auto',
    },
});

const LeftColumn = styled('div')({
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '50%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 1024px)': {
        maxWidth: 'none',
    },
});

const RightColumn = styled('div')({
    backgroundColor: '#1F4C5C',
    backgroundImage: `url(${authBg})`,
    backgroundSize: 'cover',
    padding: '46px 46px 46px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    maxWidth: '50%',
    '@media (max-width: 1024px)': {
        display: 'none',
    },
});

const PluspointLogo = styled('div')({
    width: '200px',
    height: '50px',
    marginBottom: '30px',
    backgroundImage: `url(${pluspointLogo})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
});

export const AuthLayout = ({ children, showLogo = true }) => {
    const { t } = useTranslation();

    return (
        <Root>
            <LeftColumn>
                {showLogo && <PluspointLogo />}
                {children}
            </LeftColumn>
            <RightColumn>
                <div>
                    <Typography variant="h3" sx={{ color: '#fff', marginBottom: '32px' }}>
                        {t('AuthLayout.titleTop')}
                        <br />
                        {t('AuthLayout.titleBottom')}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#E4EAEB' }}>
                        {t('AuthLayout.description')}
                    </Typography>
                </div>
            </RightColumn>
        </Root>
    );
};
