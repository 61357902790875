import { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { Input } from '@components/Input';
import Autocomplete from '@components/Autocomplete';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { useTranslation } from '@hooks/useTranslation';
import { brandsService } from '@services';
import { accountActions, alertActions, brandsActions } from '@actions';
import { DeleteIcon } from 'assets/images/icons';
import { Modal, Content } from './styles';

const LocationWrapper = styled('div')({
    display: 'grid',
    rowGap: '8px',
    gridTemplateColumns: 'calc(100% - 40px)',
    '@media (max-width: 600px)': {
        gridTemplateColumns: '90%',
        gridGap: '16px',
    },
});

const StyledIconButton = styled(IconButton)({
    position: 'absolute',
    right: '-40px',
    top: '5px',
    padding: '4px',
    '& svg path': {
        fill: 'rgba(0, 0, 0, 0.6)',
    },
});

export const FORM_TYPE = {
    NEW: 'NEW',
    EDIT: 'EDIT',
};

const EditModal = ({ selectedBrand, setSelectedBrand, modal, setModal, page, setPage, rowsPerPage, locations }) => {
    const [searchMain, setSearchMain] = useState('');
    const [searchLocation, setSearchLocation] = useState({});

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const BrandSchema = yup.object().shape({
        title: yup.string().required('validation.required'),
        primaryLocation: yup.array().min(1, 'validation.required'),
    });

    const resolver = useYupValidationResolver(BrandSchema);

    const {
        getValues,
        setValue,
        control,
        watch,
        handleSubmit,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            title: '',
            primaryLocation: [],
            locations: [],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'locations',
    });

    const selectedMainLocation = watch('primaryLocation');
    const watchLocations = watch('locations');
    const selectedLocations = fields.map((_, index) => {
        return { ...watchLocations[index], id: watchLocations[index]['id'][0]?.id };
    });

    useEffect(() => {
        if (modal.type !== FORM_TYPE.NEW) {
            setValue('title', selectedBrand.title, { shouldValidate: true });
            setValue(
                'primaryLocation',
                selectedBrand.locations
                    .filter(l => l.isMainLocation)
                    .map(item => ({
                        id: `${item.id}`,
                        label: locations.find(l => l.companyId === item.id).internalName,
                    })) || [],
                {
                    shouldValidate: true,
                },
            );
            setValue(
                'locations',
                selectedBrand.locations
                    .filter(l => !l.isMainLocation)
                    .map(l => ({
                        id: [{ id: l.id, label: locations.find(loc => loc.companyId === l.id).internalName }],
                    })) || [],
            );
        }
    }, [selectedBrand, modal.type, setValue]);

    const availableLocations = useMemo(() => {
        return modal.type === FORM_TYPE.NEW
            ? locations.filter(
                  l =>
                      !l.brand &&
                      +selectedMainLocation[0]?.id !== l.companyId &&
                      !selectedLocations.some(sl => +sl.id === l.companyId),
              )
            : locations;
    }, [locations, modal.type, selectedMainLocation, selectedLocations]);

    const mainOptionsWithSearch = useMemo(() => {
        const filteredBySearch = availableLocations.filter(l =>
            l.internalName.toLowerCase().includes(searchMain.toLowerCase()),
        );
        const filteredBySelected =
            modal.type === FORM_TYPE.NEW
                ? filteredBySearch
                : filteredBySearch.filter(l => !selectedLocations.some(sl => +sl.id === l.companyId));

        return filteredBySelected.map(l => ({ id: `${l.companyId}`, label: l.internalName }));
    }, [availableLocations, modal.type, searchMain, selectedLocations]);

    const otherOptionsWithSearch = useCallback(
        fieldIndex => {
            const filteredLocations = searchLocation[fieldIndex]
                ? availableLocations.filter(l =>
                      l.internalName.toLowerCase().includes(searchLocation[fieldIndex]?.toLowerCase()),
                  )
                : availableLocations;
            const filteredBySelectedMain =
                modal.type === FORM_TYPE.NEW
                    ? filteredLocations
                    : filteredLocations.filter(
                          l =>
                              +selectedMainLocation[0]?.id !== l.companyId &&
                              !selectedLocations.some(sl => +sl.id === l.companyId),
                      );
            return filteredBySelectedMain.map(l => ({ id: `${l.companyId}`, label: l.internalName }));
        },
        [availableLocations, modal.type, searchLocation, selectedMainLocation, selectedLocations],
    );

    const onClose = () => {
        setSelectedBrand(null);
        setModal({ open: false, type: FORM_TYPE.NEW });
    };

    const refetchFirstPage = async () => {
        if (page === 0) {
            await dispatch(brandsActions.get(page, rowsPerPage));
        } else {
            setPage(0); // Triggers refetch of templates
        }
    };

    const REFETCH_MAP = {
        [FORM_TYPE.EDIT]: async () => await dispatch(brandsActions.get(page, rowsPerPage)),
        [FORM_TYPE.NEW]: () => refetchFirstPage(),
    };

    const RESPONSE_MSG = {
        [FORM_TYPE.EDIT]: {
            success: t('alertMessages.editSuccess'),
            error: t('alertMessages.editFail'),
        },
        [FORM_TYPE.NEW]: {
            success: t('alertMessages.createSuccess'),
            error: t('alertMessages.createFail'),
        },
    };

    const onSubmit = async () => {
        const values = getValues();
        try {
            await brandsService.addOrUpdateBrand({
                title: values.title,
                locations: [
                    { id: +values.primaryLocation[0]?.id, isMainLocation: true },
                    ...values.locations.filter(l => l.id).map(l => ({ id: +l['id'][0]?.id, isMainLocation: false })),
                ],
                ...(modal.type === FORM_TYPE.EDIT && { id: selectedBrand.id }),
            });
            dispatch(alertActions.success(RESPONSE_MSG[modal.type].success));
            REFETCH_MAP[modal.type]();
            dispatch(accountActions.get());
            setModal({ open: false, type: FORM_TYPE.NEW });
            setSelectedBrand(null);
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(RESPONSE_MSG[modal.type].error));
            }
        }
    };

    return (
        <Modal
            isOpen={modal.open}
            handleClose={onClose}
            title={modal.type === FORM_TYPE.NEW ? t('buttons.createBrand') : t('SettingsBrands.editBrand')}
            onPrimaryAction={handleSubmit(onSubmit)}
            primaryActionText={t('buttons.save')}
            primaryActionDisabled={isSubmitting || !isValid || !isDirty}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content>
                <Controller
                    control={control}
                    name="title"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('common.title')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                        />
                    )}
                />
                <LocationWrapper>
                    {fields.map((formField, index) => (
                        <div style={{ position: 'relative' }} key={formField.id}>
                            <Controller
                                control={control}
                                name={`locations[${index}].id`}
                                render={({ field: { ref, ...field } }) => (
                                    <Autocomplete
                                        {...field}
                                        inputRef={ref}
                                        label={t('common.location')}
                                        options={otherOptionsWithSearch(formField.id)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        readOnly={isSubmitting}
                                        inputProps={{
                                            value: searchLocation[formField.id] ?? '',
                                            onChange: e =>
                                                setSearchLocation(prev => ({
                                                    ...prev,
                                                    [formField.id]: e.target.value,
                                                })),
                                            style: { display: field?.value?.length > 0 ? 'none' : 'block' },
                                        }}
                                        onChangeAutoComplete={items => {
                                            setValue(`locations[${index}].id`, items.length ? items : [], {
                                                shouldDirty: true,
                                            });
                                            setSearchLocation(prev => ({ ...prev, [formField.id]: '' }));
                                        }}
                                        sx={{ marginBottom: '16px' }}
                                    />
                                )}
                            />
                            <StyledIconButton onClick={() => remove(index)}>
                                <DeleteIcon />
                            </StyledIconButton>
                        </div>
                    ))}
                    <div style={{ position: 'relative' }}>
                        <Controller
                            control={control}
                            name="primaryLocation"
                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                <Autocomplete
                                    {...field}
                                    inputRef={ref}
                                    label={t('SettingsBrands.mainLocation')}
                                    options={mainOptionsWithSearch}
                                    value={field.value}
                                    isOptionEqualToValue={(option, value) => option.id === +value.id}
                                    readOnly={isSubmitting}
                                    inputProps={{
                                        value: searchMain,
                                        onChange: e => setSearchMain(e.target.value),
                                        style: { display: field?.value?.length > 0 ? 'none' : 'block' },
                                    }}
                                    onChangeAutoComplete={items => {
                                        setValue('primaryLocation', items.length ? items : [], {
                                            shouldDirty: true,
                                            shouldValidate: true,
                                        });
                                        setSearchMain('');
                                    }}
                                    required
                                    error={error}
                                    sx={{ marginBottom: '16px' }}
                                />
                            )}
                        />
                        {availableLocations.length > 0 && (
                            <StyledIconButton
                                sx={{ right: '-42px', top: '3px' }}
                                onClick={() => append({ id: '', isMainLocation: false })}
                            >
                                <AddIcon />
                            </StyledIconButton>
                        )}
                    </div>
                </LocationWrapper>
            </Content>
        </Modal>
    );
};

export default EditModal;
