import { combineReducers } from 'redux';
import { authentication } from './authentication';
import { alert } from './alert';
import { reviews } from './reviews';
import { feedbacks } from './feedbacks';
import { analytics } from './analytics';
import { account } from './account';
import { google } from './google';
import { replyTemplates } from './reply-templates';
import { autoreplyRules } from './autoreply-rules';
import { sentRequests } from './sent-requests';
import { surveys } from './surveys';
import { keywords } from './keywords';
import { contacts } from './contacts';
import { channels } from './channels';
import { campaigns } from './campaigns';
import { microsites } from './microsites';
import { dashboard } from './dashboard';
import { ifood } from './ifood';
import { conversations } from './conversations';
import { inbox } from './inbox';
import { sendInvitesUi } from './send-invites-ui';
import { coverManager } from './coverManager';
import { facebook } from './facebook';
import { socialPosts } from './social-posts';
import { pricingDrawerUi } from './pricing-drawer-ui';
import { hubspotCalendarUi } from './hubspot-calendar-ui';
import { instagramChannel } from './instagramChannel';
import { inboxTemplates } from './inbox-templates';
import { labels } from './labels';
import { profiles } from './profiles';
import { profilesMedia } from './profiles-media';
import { countries } from './countries';
import { notifications } from './notifications';
import { chatbotContent } from './chatbot-content';
import { brands } from './brands';

const rootReducer = combineReducers({
    authentication,
    reviews,
    feedbacks,
    alert,
    analytics,
    account,
    google,
    replyTemplates,
    autoreplyRules,
    sentRequests,
    surveys,
    keywords,
    contacts,
    channels,
    campaigns,
    microsites,
    dashboard,
    ifood,
    conversations,
    inbox,
    sendInvitesUi,
    coverManager,
    facebook,
    socialPosts,
    pricingDrawerUi,
    hubspotCalendarUi,
    instagramChannel,
    inboxTemplates,
    labels,
    profiles,
    profilesMedia,
    countries,
    notifications,
    chatbotContent,
    brands,
});

export default rootReducer;
