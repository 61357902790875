export const PATH_MAP = {
    '/login': 'login',
    '/signup': 'signup',
    '/reset-password': 'resetPassword',
    '/forgot-password': 'forgotPassword',
    '/verify-email': 'verifyEmail',
    '/error': 'error',
    // In AppLayout paths
    '/': 'home',
    '/surveys/sent': 'sent',
    '/surveys/responses': 'responses',
    '/surveys/templates': 'templates',
    '/surveys/auto-surveys': 'autoSurveys',
    '/reviews/manage': 'reviewsManage',
    '/reviews/templates': 'reviewsTemplates',
    '/reviews/auto-replies': 'reviewsAutoreplies',
    '/reviews/widgets': 'reviewsWidgets',
    '/messaging/inbox': 'messagingInbox',
    '/messaging/templates': 'messagingTemplates',
    '/messaging/chatbot/knowledge-base': 'chatbot',
    '/microsites': 'microsites',
    '/analytics/conversion': 'analyticsConversion',
    '/analytics/reviews': 'analyticsReviews',
    '/analytics/responses': 'analyticsSurveys',
    '/analytics/conversations': 'analyticsConversations',
    '/analytics/social-posts': 'socialPosts',
    '/analytics/presence': 'analyticsPresence',
    '/keywords': 'keywords',
    '/account-settings/profile': 'settingsProfile',
    '/account-settings/general': 'settingsGeneral',
    '/account-settings/companies': 'settingsCompanies',
    '/account-settings/channels': 'settingsChannels',
    '/account-settings/users': 'settingsUsers',
    '/account-settings/integrations': 'settingsIntegrations',
    '/account-settings/brands': 'settingsBrands',
    '/onboarding': 'onboarding',
    '/social-posts': 'socialPosts',
    '/orders': 'ordersIfood',
    '/campaigns': 'campaigns',
    '/campaigns/automated': 'campaignsAutomated',
    '/campaigns/one-time': 'campaignsOneTime',
    '/contacts': 'contacts',
    '/presence/profiles': 'presenceProfiles',
    '/presence/media': 'presenceMedia',
};
