import { inboxConstants } from '@constants/inbox';
import { inboxService } from '@services';

export const inboxActions = {
    getHistory,
    update,
    reset,
};

function getHistory(conversationId) {
    return dispatch => {
        dispatch(request());
        return inboxService.getHistory(conversationId).then(data => dispatch(success(data)));
    };
}

function request() {
    return { type: inboxConstants.INBOX_HISTORY_FETCH };
}

function success(data) {
    return { type: inboxConstants.INBOX_HISTORY_SUCCESS, data };
}

function update(data) {
    return { type: inboxConstants.INBOX_HISTORY_UPDATE, data };
}

function reset() {
    return { type: inboxConstants.INBOX_RESET };
}
