import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Table } from '@components/Table';
import { Typography } from '@components/Typography';
import Tooltip from '@components/Tooltip';
import { getChannelIcon } from '@helpers/channels';
import { formatTriggers } from '@helpers/campaigns';
import { formatCompanies } from '@helpers/companies';
import { LabelPlain } from '@components/Label';
import { useTranslation } from '@hooks/useTranslation';
import { CAMPAIGN_STATUS } from '@constants/campaigns';
import { CHANNEL } from '@constants/channels';

const StyledTable = styled(Table)({
    '& .MuiTableBody-root .MuiTableCell-body:last-child': {
        padding: 4,
    },
});

const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.title')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.location')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.channel')}
                </Typography>
            ),
        },
        {
            key: 3,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('CampaignsPage.audience')}
                </Typography>
            ),
        },
        {
            key: 4,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('CampaignsPage.triggerEvent')}
                </Typography>
            ),
        },
        {
            key: 5,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.delay')}
                </Typography>
            ),
        },
        {
            key: 6,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.status')}
                </Typography>
            ),
        },
        { key: 7, component: <Typography align="left" variant="subtitle2" /> },
    ];
};

const GetTableBody = (data, companies, channels, onClick) => {
    const { t } = useTranslation();

    const TRIGGERS = {
        1: t('CampaignsPage.surveyResponded'),
        2: t('CampaignsPage.surveySent'),
        5: t('CampaignsPage.conversationResolved'),
        7: t('common.contactCreated'),
        8: t('CampaignsPage.reviewLeft'),
    };

    const DELAY = {
        0: t('common.none'),
        1: t('FeedbackSurveys.day'),
        2: t('FeedbackSurveys.days3'),
        3: t('FeedbackSurveys.week'),
        4: t('FeedbackSurveys.weeks2'),
        5: t('FeedbackSurveys.month'),
        6: t('FeedbackSurveys.months3'),
        7: t('FeedbackSurveys.months6'),
        8: t('FeedbackSurveys.year'),
    };
    return data.map(campaign => {
        let channel;
        const isArchived = campaign.channelId === CHANNEL.ARCHIVED;
        if (isArchived) {
            channel = { type: CHANNEL.ARCHIVED, name: t('common.archived') };
        } else {
            channel = channels.find(c => c.id === campaign.channelId);
        }
        return [
            {
                component: (
                    <Typography
                        variant="subtitle2"
                        align="left"
                        sx={{ width: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {campaign.title}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left" sx={{ width: '120px' }}>
                        {formatCompanies(companies, campaign.locations, 30)}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left" sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <span style={{ width: 16 }}>{getChannelIcon(channel?.type)}</span> {channel?.name}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {campaign.labels?.length ? (
                            <Tooltip placement="top" title={campaign.labels.map(l => l.name).join(', ')}>
                                <span style={{ width: 120, overflow: 'hidden', display: 'flex' }}>
                                    {campaign.labels.map(label => (
                                        <LabelPlain key={label.id} type={label.name} variant="caption">
                                            {label.name}
                                        </LabelPlain>
                                    ))}
                                </span>
                            </Tooltip>
                        ) : (
                            '--'
                        )}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left" sx={{ width: 90 }}>
                        {formatTriggers(TRIGGERS, campaign.triggers)}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {DELAY[campaign.delay]}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {t(`CampaignsPage.status${campaign.status}`)}
                    </Typography>
                ),
            },
            {
                component: (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Tooltip
                            title={
                                campaign.status === CAMPAIGN_STATUS.PAUSED ? t('buttons.enable') : t('buttons.pause')
                            }
                            placement="top"
                        >
                            <IconButton
                                onClick={() =>
                                    onClick(campaign, campaign.status === CAMPAIGN_STATUS.PAUSED ? 'enable' : 'pause')
                                }
                            >
                                {campaign.status === CAMPAIGN_STATUS.PAUSED ? (
                                    <PlayArrowIcon viewBox="0 0 22 22" />
                                ) : (
                                    <PauseIcon viewBox="0 0 22 22" />
                                )}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('common.view')} placement="top">
                            <IconButton onClick={() => onClick(campaign, 'view')}>
                                <VisibilityIcon viewBox="0 0 22 22" />
                            </IconButton>
                        </Tooltip>
                        {!isArchived && (
                            <Tooltip title={t('common.edit')} placement="top">
                                <IconButton onClick={() => onClick(campaign, 'edit')}>
                                    <EditIcon viewBox="0 0 22 22" />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title={t('common.delete')} placement="top">
                            <IconButton onClick={() => onClick(campaign, 'delete')}>
                                <DeleteIcon viewBox="0 0 22 22" />
                            </IconButton>
                        </Tooltip>
                    </div>
                ),
            },
        ];
    });
};

const OngoingTable = ({
    companies,
    rowsPerPageRequests,
    pageRequests,
    onPageChange,
    onRowsPerPageChange,
    onClickCampaign,
}) => {
    const { t } = useTranslation();
    const { data, loading, totalCount, pages } = useSelector(state => state.campaigns);
    const { data: channels } = useSelector(state => state.channels);

    return (
        <TableContainer>
            <StyledTable
                withHover
                columnNames={GetTableHeader()}
                rows={GetTableBody(data, companies, channels, onClickCampaign)}
                isDataLoading={loading}
                withPagination
                paginationProps={{
                    colSpan: 8,
                    count: totalCount,
                    rowsPerPage: rowsPerPageRequests,
                    page: pageRequests,
                    onPageChange,
                    onRowsPerPageChange,
                    labelDisplayedRows: ({ from, to, count }) =>
                        `${from}–${to} ${t('Table.of')} ${count !== -1 ? count : `${t('Table.moreThan')} ${to}`}`,
                    labelRowsPerPage: <span>{t('Table.rowsPerPage')}</span>,
                    pages,
                }}
            />
        </TableContainer>
    );
};

export default OngoingTable;
