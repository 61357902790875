import { authHeader, BASE_URL, handleResponse } from '@helpers/api';
import { history } from '@helpers/history';

export const userService = {
    login,
    loginWithGoogle,
    loginWithGoogleOneTap,
    getUser,
    updateUser,
    updatePersonalSettings,
    updateLastVisit,
    forgotPassword,
    resetPassword,
    getEmailByToken,
    deleteUser,
    signup,
    verifyEmail,
    resendEmail,
};

function getEmailByToken(ResetToken) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ResetToken }),
    };

    return fetch(`${BASE_URL}api/User/EmailByToken`, requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data && data.message === 'Invalid token') {
                history.push('/login');
                return;
            }
            return data;
        });
}

function forgotPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
    };

    return fetch(`${BASE_URL}api/User/forgot-password`, requestOptions).then(handleResponse);
}

function resetPassword(password, resetToken) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password, resetToken }),
    };

    return fetch(`${BASE_URL}api/User/reset-password`, requestOptions).then(handleResponse);
}

function updateUser(user) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(user),
    };
    return fetch(`${BASE_URL}api/User`, requestOptions).then(handleResponse);
}

function updatePersonalSettings(values) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(values),
    };
    return fetch(`${BASE_URL}api/User/personalSettings`, requestOptions)
        .then(handleResponse)
        .then(data => data);
}

function updateLastVisit(lastVisit) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ LastVisit: lastVisit }),
    };

    return fetch(`${BASE_URL}api/User/updateVisit`, requestOptions).then(handleResponse);
}

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: username, Password: password }),
    };

    return fetch(`${BASE_URL}api/User/authenticate`, requestOptions).then(handleResponse);
}

function loginWithGoogle(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/user/login-with-google`, requestOptions).then(handleResponse);
}

function loginWithGoogleOneTap(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/user/login-with-google-one-tap`, requestOptions).then(handleResponse);
}

function getUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/User/${id}`, requestOptions)
        .then(handleResponse)
        .then(user => user);
}

function deleteUser(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/User/${id}`, requestOptions).then(handleResponse);
}

function signup(values) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
    };

    return fetch(`${BASE_URL}api/user/registration`, requestOptions)
        .then(handleResponse)
        .then(user => user);
}

function verifyEmail(token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${BASE_URL}api/user/ValidateByToken/${token}`, requestOptions)
        .then(handleResponse)
        .then(user => user);
}

function resendEmail() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/user/resend-email-verification`, requestOptions).then(handleResponse);
}
