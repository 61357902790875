import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import printJS from 'print-js';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Button from '@components/Button';
import { surveyService } from '@services';
import { alertActions } from '@actions';
import { useThrottleValue } from '@hooks/useThrottleValue';
import { useTranslation } from '@hooks/useTranslation';

const PreviewQRImg = styled('img')({
    width: '100%',
    maxWidth: 384,
    '@media (max-width: 600px)': {
        width: 280,
    },
});

const ButtonsWrapper = styled('div')({
    marginTop: 32,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
});

const PreviewQR = ({ preview, externalId, image, setImage }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isValid } = useFormContext();

    const fetchImage = async survey => {
        try {
            const surveyImage = await surveyService.getSurveyImage(externalId, {
                topText: survey.topText,
                bottomText: survey.bottomText,
                serviceId: externalId,
                backgroundColor: survey.backgroundColor,
                fillColor: survey.fillColor,
                logoUrl: survey.qrLogoUrl,
                customizeQrCodeDesign: survey.customizeQrCodeDesign,
            });
            setImage(surveyImage);
        } catch (_) {
            dispatch(alertActions.error(t('alertMessages.createFail')));
        }
    };

    const throttledPreview = useThrottleValue(preview, 3000);

    useEffect(() => {
        if (isValid) {
            fetchImage(throttledPreview);
        }
    }, [throttledPreview]);

    const onClick = async type => {
        try {
            if (type === 'print') {
                printJS({ printable: image, type: 'image' });
            } else {
                const anchor = document.createElement('a');
                anchor.style.display = 'none';
                anchor.href = image;
                anchor.download = 'QR.png';
                document.body.appendChild(anchor);
                anchor.click();
                window.URL.revokeObjectURL(image);
                anchor.remove();
            }
        } catch (_) {
            if (type === 'print') {
                dispatch(alertActions.error(t('alertMessages.printFail')));
            } else {
                dispatch(alertActions.error(t('alertMessages.downloadFail')));
            }
        }
    };

    return (
        <div style={{ padding: 16 }}>
            {image && <PreviewQRImg src={image} alt="qr" />}
            <ButtonsWrapper>
                <Button variant="outlined" onClick={() => onClick('download')} startIcon={<DownloadIcon />}>
                    {t('buttons.download')}
                </Button>
                <Button variant="outlined" onClick={() => onClick('print')} startIcon={<LocalPrintshopIcon />}>
                    {t('buttons.print')}
                </Button>
            </ButtonsWrapper>
        </div>
    );
};

export default PreviewQR;
