import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { DeleteIcon } from 'assets/images/icons';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';

const Card = styled('div')({
    padding: 16,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    maxWidth: 360,
    '@media (max-width: 700px)': {
        maxWidth: 'none',
    },
});

const CardFooter = styled('div', {
    shouldForwardProp: prop => prop !== 'hasAccess',
})(({ hasAccess }) => ({
    paddingTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div:nth-of-type(2)': {
        display: 'grid',
        gridGap: 6,
        gridTemplateColumns: `repeat(${hasAccess ? 4 : 3}, 32px)`,
        '@media (max-width: 700px)': {
            width: '100%',
            gridTemplateColumns: `repeat(${hasAccess ? 4 : 3}, calc(25% - 6px))`,
        },
    },
    '@media (max-width: 700px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& div:nth-of-type(1)': {
            marginBottom: 16,
        },
    },
}));

const StyledIconButton = styled(IconButton)({
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    height: 32,
    padding: 2,
    '& svg': {
        width: 18,
        height: 18,
        '& path': {
            fill: 'rgba(0, 0, 0, 0.6)',
        },
    },
});

const TemplateItem = ({
    data: { survey, collectedFeedbacks },
    companies,
    isSubmitting,
    onClickDelete,
    onClickEdit,
    onClickView,
    onClickPrint,
    onClickDownload,
    isUser,
}) => {
    const { t } = useTranslation();

    const internalName = companies?.find(company => company.companyId === survey.companyId)?.internalName;

    return (
        <Card>
            <Typography variant="subtitle2">{survey.name}</Typography>
            <Typography
                variant="caption"
                sx={{ margin: '4px 0 16px', display: 'inline-block', color: 'rgba(0, 0, 0, 0.6)' }}
            >
                {internalName}
            </Typography>
            <div style={{ width: '100%', height: 1, backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
            <CardFooter hasAccess={!isUser}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                        {t('FeedbackSurveys.responses')}{' '}
                        {<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{collectedFeedbacks}</span>}
                    </Typography>
                </div>
                <div>
                    {!isUser ? (
                        <>
                            <StyledIconButton disabled={isSubmitting} onClick={onClickDownload}>
                                <DownloadIcon />
                            </StyledIconButton>
                            <StyledIconButton disabled={isSubmitting} onClick={onClickPrint}>
                                <LocalPrintshopIcon />
                            </StyledIconButton>
                            <StyledIconButton disabled={isSubmitting} onClick={onClickEdit}>
                                <EditIcon />
                            </StyledIconButton>
                            <StyledIconButton disabled={isSubmitting} onClick={onClickDelete}>
                                <DeleteIcon />
                            </StyledIconButton>
                        </>
                    ) : (
                        <>
                            <StyledIconButton onClick={onClickDownload}>
                                <DownloadIcon />
                            </StyledIconButton>
                            <StyledIconButton onClick={onClickPrint}>
                                <LocalPrintshopIcon />
                            </StyledIconButton>
                            <StyledIconButton onClick={onClickView}>
                                <VisibilityIcon />
                            </StyledIconButton>
                        </>
                    )}
                </div>
            </CardFooter>
        </Card>
    );
};

export default TemplateItem;
