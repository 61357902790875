export const dashboardConstants = {
    DASHBOARD_SUCCESS: 'DASHBOARD_SUCCESS',
    DASHBOARD_ERROR: 'DASHBOARD_ERROR',
    LEADERBOARD_SUCCESS: 'LEADERBOARD_SUCCESS',
    LEADERBOARD_ERROR: 'LEADERBOARD_ERROR',
    RECOMMENDATIONS_SUCCESS: 'RECOMMENDATIONS_SUCCESS',
    RECOMMENDATIONS_ERROR: 'RECOMMENDATIONS_ERROR',
    SET_RECOMMENDATIONS: 'SET_RECOMMENDATIONS',
};

export const Recommendation = {
    UnrepliedReviews: 1,
    OutdatedPhotos: 2,
    IncompleteLocations: 3,
    ActionRequiredProfiles: 4,
    UnreadConversations: 5,
    OutdatedSocialPosts: 6,
    UnreadSurveyResponses: 7,
};
