import { memo, useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { analyticsActions } from '@actions';
import DoughnutRates from '../Common/DoughnutRates';
import SentimentTrend from '../Common/SentimentTrend';
import FiltersPanel from './FiltersPanel';
import NpsChart from './NpsChart';
import FeedbackTable from './FeedbackTable';
import { PrintButton } from '../Common/PrintButton';
import { AccIntegrationName } from '@constants/integrations';
import { GroupByFilter } from '@constants/analytics';
import { INCLUDE_COVER_MANAGER } from '@constants/feedbacks';
import { subtractYears } from '@helpers/dates';
import { getLocationLabels } from '@helpers/locationLabels';
import { useTranslation } from '@hooks/useTranslation';
import { AnalyticsContainer, HeaderWrapper } from '../styles';

const Feedback = memo(() => {
    const { t } = useTranslation();
    const firstRender = useRef(true);

    const [filter, setFilter] = useState({
        labels: [],
        companies: [],
        surveys: [],
        timeframe: {
            start: subtractYears(new Date(), 1),
            end: new Date(),
        },
        grouping: GroupByFilter.Months,
    });
    const [filterNumber, setFilterNumber] = useState(0);
    const [filterOpened, setFiltersOpened] = useState(false);

    const companies = useSelector(state => state.account?.account?.companies || []);
    const language = useSelector(state => state.authentication.user?.language, shallowEqual);
    const account = useSelector(state => state.account?.account);
    const { location: labels, locationLoading: labelsLoading } = useSelector(state => state.labels);
    const { feedbackData, feedbackLoading } = useSelector(state => state.analytics, shallowEqual);
    const dispatch = useDispatch();

    const isCoverManagerConnected = account?.integrations.find(
        i => i.type === AccIntegrationName.CoverManager,
    )?.isActive;

    const availableCompanies = useMemo(() => companies.filter(company => company?.surveys?.length > 0), [companies]);

    const availableLabels = useMemo(
        () => getLocationLabels(labels, availableCompanies, t),
        [labels, availableCompanies],
    );

    useEffect(() => {
        if (companies.length && !labelsLoading) {
            const availableSurveys = companies
                ?.map(company => company.surveys)
                ?.flat()
                ?.map(item => item.id);
            const surveys = isCoverManagerConnected ? [...availableSurveys, INCLUDE_COVER_MANAGER] : availableSurveys;
            setFilter(prev => ({
                ...prev,
                surveys,
                companies: availableCompanies.map(item => item.companyId),
                labels: availableLabels.map(item => item.id),
            }));
        }
    }, [companies, labelsLoading, dispatch, isCoverManagerConnected]);

    const currentQuery = useMemo(() => {
        return {
            companies: filter.companies,
            surveys: isCoverManagerConnected ? filter.surveys.filter(i => i !== INCLUDE_COVER_MANAGER) : filter.surveys,
            includeCoverManager: isCoverManagerConnected ? filter.surveys.includes(INCLUDE_COVER_MANAGER) : false,
            from: filter.timeframe.start ? format(new Date(filter.timeframe.start), 'yyyy-MM-dd') : null,
            to: filter.timeframe.end ? format(new Date(filter.timeframe.end), 'yyyy-MM-dd') : null,
            grouping: filter.grouping,
        };
    }, [filter, isCoverManagerConnected]);

    useEffect(() => {
        if (filter.companies.length && filter.surveys.length) {
            if (firstRender.current) {
                dispatch(analyticsActions.getAnalyticsFeedback(currentQuery));
                firstRender.current = false;
            }
        }
    }, [filter, dispatch, isCoverManagerConnected]);

    const onApplyFilters = () => {
        dispatch(analyticsActions.getAnalyticsFeedback(currentQuery));
    };

    return (
        <>
            <HeaderWrapper>
                <FiltersPanel
                    setFiltersOpened={setFiltersOpened}
                    filtersNumber={filterNumber}
                    filterOpened={filterOpened}
                    companies={availableCompanies}
                    labels={availableLabels}
                    filter={filter}
                    setFilter={setFilter}
                    type="feedbacks"
                    setFilterNumber={setFilterNumber}
                    onApplyFilters={onApplyFilters}
                />
                <PrintButton />
            </HeaderWrapper>
            <AnalyticsContainer id="pl-content-analytics-feedbacks">
                <DoughnutRates data={feedbackData.overall} type="feedbacks" isLoading={feedbackLoading} />
                <SentimentTrend data={feedbackData.sentimentData} language={language} type="feedbacks" />
                <NpsChart data={feedbackData.npsData} language={language} type="feedbacks" />
                <FeedbackTable data={feedbackData.tableData} isLoading={feedbackLoading} />
            </AnalyticsContainer>
        </>
    );
});

Feedback.displayName = 'Feedback';

export default Feedback;
