import { ifoodConstants } from '@constants/ifood';
import { ifoodIntegrationService } from '@services';

export const ifoodActions = {
    get,
};

function get() {
    return dispatch => {
        dispatch(request());
        return ifoodIntegrationService.getMerchants().then(
            data => {
                dispatch(success(data));
                return data;
            },
            error => dispatch(failure(error)),
        );
    };
}

function request() {
    return { type: ifoodConstants.IFOOD_MERCHANTS_REQUEST };
}

function success(data) {
    return { type: ifoodConstants.IFOOD_MERCHANTS_SUCCESS, data };
}

function failure(message) {
    return { type: ifoodConstants.IFOOD_MERCHANTS_ERROR, message };
}
