import { useState, useEffect, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InfoSnackbar from '@components/InfoSnackbar';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { Trans } from 'react-i18next';
import { userService } from '@services';
import { alertActions } from '@actions';

const ResendEmail = memo(({ user }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    useEffect(() => {
        if (!user?.isEmailVerified && !pathname.startsWith('/onboarding')) {
            setIsOpen(true);
        }
    }, []);

    useEffect(() => {
        if (pathname.startsWith('/onboarding')) {
            setIsOpen(false);
        }
    }, [pathname]);

    const handleClose = (_, reason) => {
        if (reason === 'clickaway') return;
        setIsOpen(false);
    };

    const onResendEmail = async () => {
        try {
            await userService.resendEmail();
            dispatch(alertActions.success(t('VerifyEmail.resendSuccess')));
        } catch (_) {
            dispatch(alertActions.error(t('VerifyEmail.resendFail')));
        }
        setIsOpen(false);
    };

    return (
        <InfoSnackbar
            isOpen={isOpen}
            handleClose={handleClose}
            message={
                <Typography variant="body2" sx={{ color: '#416774' }}>
                    <Trans t={t} i18nKey="VerifyEmail.message">
                        {{ email: user?.email || '' }}
                        <b style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onResendEmail}>
                            {{ link: t('VerifyEmail.link') }}
                        </b>
                    </Trans>
                </Typography>
            }
        />
    );
});

ResendEmail.displayName = 'ResendEmail';

export default ResendEmail;
