import { useMemo, memo } from 'react';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import DoughnutChart from '@components/Charts/DoughnutChart';
import { formatNumber, formatPercentNumber } from '@helpers/formatNumbers';

const createChartData = (data, hasData, t) => {
    const EMPTY_SPACE = '                                             ';
    const backgroundColors = ['#BDE0CC', '#98CBAD', '#416774', '#1F4C5C'];
    const borderColors = ['#BDE0CC', '#98CBAD', '#416774', '#1F4C5C'];
    const dataMap = ['googleMapsDesktop', 'googleSearchDesktop', 'googleMapsMobile', 'googleSearchMobile'].map(
        prop => ({
            label: `${t('AnalyticsPresence.' + prop)} - ${formatNumber(data[prop])} (${formatPercentNumber(
                data[prop + 'Percentage'],
            )})${EMPTY_SPACE}`,
            value: data[prop],
        }),
    );
    return {
        labels: dataMap.map(d => d.label),
        datasets: [
            {
                label: '',
                data: !hasData ? [1] : dataMap.map(d => d.value),
                backgroundColor: !hasData ? ['#F2F2F2', ...backgroundColors] : backgroundColors,
                borderColor: !hasData ? ['#F2F2F2', ...borderColors] : borderColors,
                borderWidth: 1,
            },
        ],
    };
};

const DevicesBreakdown = memo(({ data, isLoading, total }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('@media (max-width: 1200px)');

    const hasData = useMemo(
        () =>
            !!data.googleMapsDesktop ||
            !!data.googleMapsMobile ||
            !!data.googleSearchDesktop ||
            !!data.googleSearchMobile,
        [data],
    );
    const datasets = useMemo(() => createChartData(data, hasData, t), [data, hasData]);

    return (
        <Paper
            elevation={1}
            sx={{
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '4px',
                breakInside: 'avoid',
            }}
        >
            <Typography variant="title1" style={{ paddingBottom: '10px' }}>
                {t('AnalyticsPresence.devicesBreakdown')}
            </Typography>
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    height: isMobile ? '460px' : '500px',
                    marginTop: isMobile ? '32px' : 0,
                }}
            >
                {!isLoading ? (
                    <DoughnutChart
                        datasets={datasets}
                        hasData={hasData}
                        optionsProps={{
                            plugins: {
                                legend: {
                                    align: 'start',
                                },
                            },
                        }}
                        overall={{
                            title: t('AnalyticsPresence.totalViews'),
                            data: formatNumber(total),
                        }}
                    />
                ) : null}
            </div>
        </Paper>
    );
});

DevicesBreakdown.displayName = 'DevicesBreakdown';

export default DevicesBreakdown;
