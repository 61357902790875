import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { ModalConfirm } from '@components/Modal';
import Button from '@components/Button';
import CreateChannel from '@features/CreateChannel';
import PageHeader from '@features/PageHeader';
import { useTranslation } from '@hooks/useTranslation';
import { ChannelsIcon } from 'assets/images/icons';
import { channelsActions, alertActions, accountActions, labelsActions } from '@actions';
import { channelsService } from '@services';
import { LABEL_TYPE } from '@constants/labels';
import { CHANNEL, STATUS } from '@constants/channels';
import ChannelItem from './ChannelItem';
import EditModal from './EditModal';

const Container = styled('div')({
    paddingBottom: 24,
    '@media (max-width: 1024px)': {
        overflowY: 'auto',
    },
});

const CardsWrapper = styled('div')({
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(auto-fill, minmax(10px, 320px))',
    '@media (max-width: 700px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))',
    },
});

const Channels = () => {
    const { t } = useTranslation();

    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState({});

    const companies = useSelector(state => state.account?.account?.companies || []);
    const channels = useSelector(state => state.channels.data);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(channelsActions.get());
        dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
    }, []);

    const onClickAddChannel = () => setCreateModalOpen(true);

    const onClickEdit = async channel => {
        setSelectedChannel(channel);
        if (
            channel.type === CHANNEL.WHATSAPP &&
            (channel.status === STATUS.DISCONNECTED || channel.status === STATUS.PENDING)
        ) {
            try {
                const updatedChannel = await channelsService.createOrUpdateChannelWhatsApp(channel);
                setSelectedChannel({ ...updatedChannel, qrCode: null });
                setCreateModalOpen(true);
            } catch (error) {
                if (error.errorCode) {
                    dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
                } else {
                    dispatch(alertActions.error(t('apiErrors.something_wrong')));
                }
            }
            return;
        }
        setEditModalOpen(true);
    };

    const onClickDelete = channel => {
        setSelectedChannel(channel);
        setDeleteModalOpen(true);
    };

    const onDeleteChannel = async () => {
        try {
            if (selectedChannel?.type === CHANNEL.INSTAGRAM) {
                await channelsService.deleteInstagramChannel(selectedChannel?.id);
            } else {
                await channelsService.deleteChannel(selectedChannel?.id);
            }
            await dispatch(channelsActions.get());
            await dispatch(accountActions.get());
        } catch (error) {
            if (error.campaigns) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`, { amount: error.campaigns?.length })));
            } else if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
        setSelectedChannel({});
        setEditModalOpen(false);
        setDeleteModalOpen(false);
    };

    return (
        <Container>
            <PageHeader
                icon={<ChannelsIcon />}
                title={t('SettingsChannels.headerTitle')}
                subtitle={t('SettingsChannels.headerSubtitle')}
                styles={{ marginBottom: 16 }}
            >
                <Button variant="contained" onClick={onClickAddChannel}>
                    {t('buttons.addChannel')}
                </Button>
            </PageHeader>
            <CardsWrapper>
                {channels.map(channel => (
                    <ChannelItem
                        key={channel.id}
                        channel={channel}
                        companies={companies}
                        onClickDelete={onClickDelete}
                        onClickEdit={onClickEdit}
                    />
                ))}
            </CardsWrapper>
            {isCreateModalOpen ? (
                <CreateChannel
                    open={isCreateModalOpen}
                    setOpen={setCreateModalOpen}
                    channel={selectedChannel}
                    setSelectedChannel={setSelectedChannel}
                />
            ) : null}
            {isEditModalOpen ? (
                <EditModal
                    open={isEditModalOpen}
                    setOpen={setEditModalOpen}
                    companies={companies}
                    selectedChannel={selectedChannel}
                    setSelectedChannel={setSelectedChannel}
                    onClickDelete={onClickDelete}
                />
            ) : null}
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDeleteChannel}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                    onSecondaryAction={() => setDeleteModalOpen(false)}
                />
            )}
        </Container>
    );
};

export default Channels;
