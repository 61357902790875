export const profilesMediaConstants = {
    PROFILES_MEDIA_FETCH: 'PROFILES_MEDIA_FETCH',
    PROFILES_MEDIA_SUCCESS: 'PROFILES_MEDIA_SUCCESS',
    PROFILES_MEDIA_LOAD_MORE_SUCCESS: 'PROFILES_MEDIA_LOAD_MORE_SUCCESS',
    PROFILES_MEDIA_ERROR: 'PROFILES_MEDIA_ERROR',
    PROFILES_MEDIA_SET: 'PROFILES_MEDIA_SET',
};

export const AUTHOR = {
    BUSINESS: 1,
    CUSTOMER: 2,
};

export const FORMAT = {
    PHOTO: 1,
    VIDEO: 2,
};

export const MEDIA_STATUS = {
    NORMAL: 1,
    NEW: 2,
    REPORTED: 3,
};
