import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '@reducers';

const loggerMiddleware = createLogger();
const middlewares = process.env.NODE_ENV === 'development' ? [thunkMiddleware, loggerMiddleware] : [thunkMiddleware];

const appReducer = (state, action) => {
    if (action.type === 'CLEAR_STORE') {
        return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
};
// TODO remove devtools in prod mode
export const store = createStore(appReducer, applyMiddleware(...middlewares));
