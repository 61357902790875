import { useEffect } from 'react';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@components/Typography';
import { Checkbox } from '@components/Checkbox';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { useTranslation } from '@hooks/useTranslation';
import TableRowPlain from './TableRowPlain';
import TableRowWithActions from './TableRowWithActions';
import TablePaginationActions from './TablePaginationActions';
import TableCellCheckbox from './TableCellCheckbox';

const TableHeaderCell = styled(TableCell)(() => ({
    borderBottom: 'none',
    padding: '12px 16px',
}));

const TableRowMessage = styled(TableRow)(() => ({
    background: 'rgba(228, 234, 235, 1)',
}));

const TableCellMessage = styled(TableCell)(() => ({
    padding: '12px 16px 12px 56px',
    lineHeight: '16px',
    borderBottom: 'none',
}));

const StyledTablePagination = styled(TablePagination)(() => ({
    '& .MuiTablePagination-input': {
        fontSize: 14,
    },
    '& .MuiToolbar-root': {
        minHeight: 45,
    },
    '& .MuiTablePagination-selectLabel': {
        margin: '8px 0',
    },
    '& .MuiTablePagination-displayedRows': {
        margin: '8px 0',
    },
}));

export const Table = ({
    className,
    columnNames = [],
    data = [],
    rows = [],
    isDataLoading,
    onPrimaryAction,
    onSecondaryAction,
    isInteractive,
    isCustomActionComponent,
    customDataRows = [],
    withHover = false,
    withPagination = false,
    paginationProps = {},
    withCheckbox = false,
    entity = '',
    selectedItems = { list: {}, all: false },
    setSelectedItems,
    CollapsibleRow,
    isCollapsible = false,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (withCheckbox) {
            setSelectedItems(prev => ({ ...prev, list: {}, all: false }));
        }
    }, [data, withCheckbox]);

    const onSelectAll = e => {
        if (e.target.checked) {
            const newSelected = {};
            data.forEach(item => (newSelected[item?.id] = true));
            setSelectedItems(prev => ({ ...prev, list: newSelected }));
            return;
        }
        setSelectedItems(prev => ({ ...prev, list: {}, all: false }));
    };

    const onSelectAllData = () => setSelectedItems(prev => ({ ...prev, all: true }));

    const onClearAllDataSelection = () => setSelectedItems(prev => ({ ...prev, list: {}, all: false }));

    const onSelect = indexChecked => {
        const newItemId = data[indexChecked].id;
        const objectCopy = { ...selectedItems.list };
        if (!objectCopy[newItemId]) {
            objectCopy[newItemId] = true;
        } else {
            delete objectCopy[newItemId];
        }
        setSelectedItems(prev => ({ ...prev, list: objectCopy }));
    };

    const selectedLength = Object.keys(selectedItems.list).length;

    return (
        <MuiTable className={className} sx={{ maxWidth: '100%' }}>
            <TableHead sx={{ background: 'rgba(0, 0, 0, 0.04)' }}>
                <TableRow>
                    {withCheckbox && (
                        <TableCellCheckbox sx={{ borderBottom: 'none' }}>
                            <Checkbox
                                checked={(selectedLength > 0 && selectedLength === data.length) || selectedItems.all}
                                indeterminate={selectedLength > 0 && selectedLength !== data.length}
                                onChange={e => onSelectAll(e)}
                            />
                        </TableCellCheckbox>
                    )}
                    {columnNames.map((th, index) => (
                        <TableHeaderCell
                            key={th.key || index}
                            align={index > 0 ? 'center' : 'left'}
                            width={th.width || 0}
                        >
                            {th.component || th.data || th}
                        </TableHeaderCell>
                    ))}
                </TableRow>
                {withCheckbox &&
                    !!selectedLength &&
                    selectedLength === paginationProps.rowsPerPage &&
                    !selectedItems.all &&
                    paginationProps.count > paginationProps.rowsPerPage && (
                        <TableRowMessage>
                            <TableCellMessage colSpan={columnNames.length + 1}>
                                <Typography variant="caption" color="textSecondary">
                                    {t('Table.allOnPageSelected', { selected: data.length, entity: entity })}
                                </Typography>
                                <Typography variant="caption" color="primary">
                                    <span style={{ fontWeight: '500', cursor: 'pointer' }} onClick={onSelectAllData}>
                                        {t('Table.selectAll', { data: paginationProps.count, entity: entity })}
                                    </span>
                                </Typography>
                            </TableCellMessage>
                        </TableRowMessage>
                    )}
                {withCheckbox && selectedItems.all && (
                    <TableRowMessage>
                        <TableCellMessage colSpan={columnNames.length + 1}>
                            <Typography variant="caption" color="textSecondary">
                                {t('Table.allDataSelected', { data: paginationProps.count, entity: entity })}
                            </Typography>
                            <Typography variant="caption" color="primary">
                                <span
                                    style={{ fontWeight: '500', cursor: 'pointer' }}
                                    onClick={onClearAllDataSelection}
                                >
                                    {t('Table.clearSelection')}
                                </span>
                            </Typography>
                        </TableCellMessage>
                    </TableRowMessage>
                )}
            </TableHead>
            {isDataLoading ? (
                <TableBody>
                    <TableRow>
                        <TableHeaderCell colSpan={withCheckbox ? columnNames.length + 1 : columnNames.length}>
                            <Skeleton variant="rectangular" width="100%" height={118} />
                        </TableHeaderCell>
                    </TableRow>
                </TableBody>
            ) : (
                <TableBody>
                    {!rows.length ? (
                        <TableRow>
                            <TableCell colSpan={columnNames.length} align="center">
                                {t('common.noData')}
                            </TableCell>
                        </TableRow>
                    ) : (
                        rows?.map((row, index) =>
                            row ? (
                                isInteractive ? (
                                    <TableRowWithActions
                                        key={`row-${index}-${row[0]?.value || ''}`}
                                        rowData={row}
                                        onPrimaryAction={onPrimaryAction}
                                        onSecondaryAction={onSecondaryAction}
                                        isCustomActionComponent={isCustomActionComponent}
                                        withCheckbox={withCheckbox}
                                        checked={selectedItems.list[data[index]?.id] || selectedItems.all}
                                        onSelect={() => onSelect(index)}
                                    />
                                ) : (
                                    <TableRowPlain
                                        key={`row-${index}-${row[0]?.value || ''}`}
                                        hover={withHover}
                                        rowData={row}
                                        dataRow={customDataRows.includes(index) ? true : false}
                                        withCheckbox={withCheckbox}
                                        checked={selectedItems.list[data[index]?.id] || selectedItems.all}
                                        onSelect={() => onSelect(index)}
                                        collapsibleRow={isCollapsible && <CollapsibleRow data={data} index={index} />}
                                        isCollapsible={isCollapsible}
                                        colSpan={columnNames.length}
                                    />
                                )
                            ) : null,
                        )
                    )}
                </TableBody>
            )}
            {withPagination && (
                <TableFooter>
                    <TableRow>
                        <StyledTablePagination
                            {...paginationProps}
                            rowsPerPageOptions={paginationProps.rowsPerPageOptions ?? [10, 20, 50]}
                            SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                            ActionsComponent={props => (
                                <TablePaginationActions {...props} pages={paginationProps.pages} />
                            )}
                        />
                    </TableRow>
                </TableFooter>
            )}
        </MuiTable>
    );
};
