import { styled } from '@mui/system';
import ArrowDownIcon from 'assets/images/icons/select-arrow-down.svg';
import { SURVEY_LANGUAGE } from '@constants/language';

const I18nWrapper = styled('div')({
    paddingRight: 10,
    alignSelf: 'flex-end',
    display: 'flex',
    marginBottom: 25,
    position: 'relative',
    '&::before': {
        content: '""',
        width: 5,
        height: 3,
        display: 'block',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: 0,
        backgroundImage: `url(${ArrowDownIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '5px 3px',
        zIndex: 2,
    },
});

const I18nSelect = styled('div')({
    width: '100%',
    outline: 'none',
    border: 'none',
    background: 'inherit',
    appearance: 'none',
    MoxAppearance: 'none',
    WebkitAppearance: 'none',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '14px',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
    zIndex: 3,
    '&:disabled': {
        opacity: 1,
    },
});

const I18N_MAP = {
    [SURVEY_LANGUAGE.GB]: '🇬🇧 English',
    [SURVEY_LANGUAGE.ES]: '🇪🇸 Español',
    [SURVEY_LANGUAGE.DE]: '🇩🇪 Deutsch',
    [SURVEY_LANGUAGE.FR]: '🇫🇷 Français',
    [SURVEY_LANGUAGE.AR]: 'العربية 🇾🇪',
    [SURVEY_LANGUAGE.UA]: '🇺🇦 Українська',
    [SURVEY_LANGUAGE.PT]: '🇵🇹 Português',
    [SURVEY_LANGUAGE.BR]: '🇧🇷 Português',
    [SURVEY_LANGUAGE.PL]: '🇵🇱 Polska',
};

const LanguageSelector = ({ selectedLanguage = SURVEY_LANGUAGE.GB }) => (
    <I18nWrapper>
        <I18nSelect value={SURVEY_LANGUAGE.GB} disabled>
            <option value="GB">{I18N_MAP[selectedLanguage]}</option>
        </I18nSelect>
    </I18nWrapper>
);

export default LanguageSelector;
