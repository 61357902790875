import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const billingService = {
    createSubscription,
    updateSubscription,
    createCustomerPortal,
    getUpdateSubscriptionData,
};

function createSubscription(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/stripe/create-subscription`, requestOptions)
        .then(handleResponse)
        .catch(error => error);
}

function updateSubscription(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/stripe/update-subscription`, requestOptions)
        .then(handleResponse)
        .catch(error => error);
}

function createCustomerPortal(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/stripe/create-customer-portal`, requestOptions).then(handleResponse);
}

function getUpdateSubscriptionData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/stripe/subscription-proration`, requestOptions).then(handleResponse);
}
