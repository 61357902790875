import { styled } from '@mui/material/styles';
import { Input } from '@components/Input';
import Button from '@components/Button';
import { Typography } from '@components/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MoveIcon } from 'assets/images/icons';
import { Select } from '@components/Select';
import { useTranslation } from '@hooks/useTranslation';

const Form = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
    gridGap: 16,
    margin: '8px 0',
    paddingTop: 8,
    '@media (max-width: 1200px)': {
        gridTemplateColumns: '100%',
    },
    '@media (max-width: 768px)': {
        gridTemplateColumns: '100%',
    },
});

const CustomLink = styled('div', {
    shouldForwardProp: prop => prop !== 'isExpanded',
})(({ isExpanded }) => ({
    padding: isExpanded ? '4px 16px 16px' : '4px 16px',
    marginBottom: 8,
    cursor: 'pointer',
    backgroundColor: '#fff',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    position: 'relative',
}));

const MicrositeLink = ({
    listeners,
    attributes,
    isView,
    link,
    isOpen,
    index,
    surveyOptions,
    liveChatChannelOptions,
    linkClick,
    onChangeDefaultLink,
    onChangeSurvey,
    onChangeLiveChat,
    setLocalizeModal,
    onClickDelete,
}) => {
    const { t } = useTranslation();

    const isSurvey = link.surveyId !== undefined && link.surveyId !== null;
    const isLiveChat = link.liveChatChannelId !== undefined && link.liveChatChannelId !== null;
    const isLink = !isSurvey && !isLiveChat;

    return (
        <CustomLink isExpanded={isOpen}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 15,
                    left: 0,
                }}
                onClick={() => linkClick(+index)}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <span {...listeners} {...attributes} style={{ cursor: 'grab' }}>
                        <MoveIcon />
                    </span>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                        <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', margin: '4px 0 8px' }}>
                            {isSurvey
                                ? t('common.survey')
                                : isLiveChat
                                ? t('common.liveChat')
                                : t('MicrositesPage.link')}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: '500 !important' }}>
                            {link?.title}
                        </Typography>
                    </div>
                </div>
                {isOpen ? (
                    <ExpandLessIcon className="sidebar-menu-arrow" />
                ) : (
                    <ExpandMoreIcon className="sidebar-menu-arrow" />
                )}
            </div>
            {isOpen ? (
                <>
                    <Form>
                        <Input
                            inputProps={{
                                name: 'title',
                                value: link?.title,
                                onChange: e => onChangeDefaultLink(e, index, link?.language),
                            }}
                            label={t('MicrositesPage.linkTitle')}
                            error={!link?.title}
                            helperText={!link?.title ? t('validation.required') : ''}
                            fullWidth
                            required
                            disabled={isView}
                        />
                        {isSurvey && (
                            <Select
                                onChange={e => onChangeSurvey(e, index)}
                                label={t('common.survey')}
                                fullWidth
                                value={link.surveyId}
                                options={surveyOptions}
                                disabled={isView}
                            />
                        )}
                        {isLiveChat && (
                            <Select
                                onChange={e => onChangeLiveChat(e, index)}
                                label={t('common.liveChat')}
                                fullWidth
                                value={link.liveChatChannelId}
                                options={liveChatChannelOptions}
                                disabled={isView}
                            />
                        )}
                        {isLink && (
                            <Input
                                inputProps={{
                                    name: 'link',
                                    value: link?.link,
                                    onChange: e => onChangeDefaultLink(e, index, link?.language),
                                }}
                                label={t('MicrositesPage.link')}
                                error={!link?.link}
                                helperText={!link?.link ? t('validation.required') : ''}
                                fullWidth
                                disabled={isView}
                            />
                        )}
                    </Form>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => setLocalizeModal({ open: true, ctx: index, isSurvey, isLiveChat })}
                            disabled={isView}
                        >
                            {t('buttons.localization')}
                        </Button>
                        <div>
                            <Button
                                variant="danger"
                                size="large"
                                onClick={() => onClickDelete(index)}
                                disabled={isView}
                            >
                                {t('buttons.delete')}
                            </Button>
                        </div>
                    </div>
                </>
            ) : null}
        </CustomLink>
    );
};

export default MicrositeLink;
