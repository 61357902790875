import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { Typography } from '@components/Typography';

const AUDIENCE_LABEL_COLOR = {
    Promoter: {
        bg: 'rgba(11, 141, 0, 0.35)',
        bgLight: 'rgba(11, 141, 0, 0.15)',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    Neutral: {
        bg: 'rgba(255, 135, 0, 0.35)',
        bgLight: 'rgba(255, 135, 0, 0.15)',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    Detractor: {
        bg: 'rgba(176, 0, 32, 0.35)',
        bgLight: 'rgba(176, 0, 32, 0.15)',
        color: 'rgba(0, 0, 0, 0.87)',
    },
};

export const LabelPlain = styled(Typography, {
    shouldForwardProp: prop => prop !== 'type' && prop !== 'size',
})(({ type, size }) => ({
    backgroundColor: AUDIENCE_LABEL_COLOR[type]?.bg || 'rgba(188, 201, 206, 1)',
    borderRadius: 4,
    color: AUDIENCE_LABEL_COLOR[type]?.color || 'rgba(0, 0, 0, 0.87)',
    padding: size === 'small' ? '3px 6px' : '5px 8px',
    height: size === 'small' ? 20 : 24,
    display: 'inline-block',
    whiteSpace: 'nowrap',
    '&:not(:last-child)': {
        marginRight: 4,
    },
}));

export const LabelChip = styled(Chip, {
    shouldForwardProp: prop => prop !== 'type' && prop !== 'isPartlyAssigned',
})(({ type, isPartlyAssigned }) => ({
    backgroundColor: !isPartlyAssigned
        ? AUDIENCE_LABEL_COLOR[type]?.bg || 'rgba(188, 201, 206, 1)'
        : AUDIENCE_LABEL_COLOR[type]?.bgLight || 'rgba(228, 234, 235, 1)',
    borderRadius: 4,
    color: AUDIENCE_LABEL_COLOR[type]?.color || 'rgba(0, 0, 0, 0.87)',
    padding: '3px 6px',
    height: 20,
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    opacity: '1 !important',
    '&:hover': {
        backgroundColor: !isPartlyAssigned
            ? AUDIENCE_LABEL_COLOR[type]?.bg || 'rgba(188, 201, 206, 1)'
            : AUDIENCE_LABEL_COLOR[type]?.bgLight || 'rgba(228, 234, 235, 1)',
        color: AUDIENCE_LABEL_COLOR[type]?.color || 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiChip-label': {
        padding: 0,
        textOverflow: 'initial',
        overflow: 'visible',
        height: 20,
        display: 'flex',
        alignItems: 'center',
    },
    '& .MuiChip-deleteIcon': {
        width: 13,
        height: 13,
        color: 'rgba(0, 0, 0, 0.6)',
        margin: 0,
    },
}));
