import { useState, useEffect, useCallback, useRef } from 'react';

const useThrottle = (function_, timeout = 300) => {
    const [ready, setReady] = useState(true);
    const timerRef = useRef(undefined);

    if (!function_ || typeof function_ !== 'function') {
        throw new Error('As a first argument, you need to pass a function to useThrottle hook.');
    }

    const throttledFunction = useCallback(
        (...args) => {
            if (!ready) {
                return;
            }

            setReady(false);
            function_(...args);
        },
        [ready, function_],
    );

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (!ready) {
                timerRef.current = window.setTimeout(() => {
                    setReady(true);
                }, timeout);

                return () => window.clearTimeout(timerRef.current);
            }
        } else {
            console.warn('useThrottle: window is undefined.');
        }
    }, [ready, timeout]);

    return [throttledFunction, ready];
};

export { useThrottle };
