import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Typography } from '@components/Typography';
import { Input } from '@components/Input';
import { alertActions, accountActions } from '@actions';
import { coverManagerIntegrationService } from '@services';
import { useTranslation } from '@hooks/useTranslation';
import { Content, Modal } from './styles';

const CoverManagerModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const CoverManagerSchema = yup.object().shape({
        apiKey: yup.string().required('validation.required'),
    });

    const resolver = useYupValidationResolver(CoverManagerSchema);

    const {
        getValues,
        control,
        formState: { isValid, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            apiKey: '',
        },
    });

    const onConnect = async () => {
        try {
            const values = getValues();
            await coverManagerIntegrationService.connectIntegration(values);
            dispatch(alertActions.success(t('alertMessages.connectIntegrationSuccess')));
            dispatch(accountActions.get());
            onClose();
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.connectIntegrationFail')));
            }
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            handleClose={onClose}
            title={t('SettingsIntegrations.coverManagerTitle')}
            subtitle={
                <Typography variant="caption" color="textSecondary">
                    {t('SettingsIntegrations.coverManagerSubtitle')}
                </Typography>
            }
            onPrimaryAction={onConnect}
            primaryActionText={t('buttons.connect')}
            primaryActionDisabled={isSubmitting || !isValid}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content>
                <Controller
                    control={control}
                    name="apiKey"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            sx={{ gridArea: 'input1' }}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('SettingsIntegrations.apiKey')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                        />
                    )}
                />
            </Content>
        </Modal>
    );
};

export default CoverManagerModal;
