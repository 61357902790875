import { memo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { labelsActions } from '@actions';
import { LABEL_TYPE } from '@constants/labels';
import Conversion from './Conversion';
import Reviews from './Reviews';
import Feedback from './Feedback';
import Conversations from './Conversations';
import SocialPosts from './SocialPosts';
import Presence from './Presence';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
    },
});

const Overlay = styled('div')({
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    zIndex: 3,
    transition: 'opacity 725ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '@media print': {
        display: 'none',
    },
});

const AnalyticsPage = memo(() => {
    const [isPrintOverlay, setPrintOverlay] = useState(false);
    const dispatch = useDispatch();

    const handleBeforePrint = () =>
        ReactDOM.flushSync(() => {
            setPrintOverlay(true);
        });
    const handleAfterPrint = () => setPrintOverlay(false);

    useEffect(() => {
        dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
    }, []);

    useEffect(() => {
        window.addEventListener('beforeprint', handleBeforePrint);
        window.addEventListener('afterprint', handleAfterPrint);

        return () => {
            window.removeEventListener('beforeprint', handleBeforePrint);
            window.removeEventListener('afterprint', handleAfterPrint);
        };
    }, []);

    return (
        <>
            {isPrintOverlay && <Overlay />}
            <Container>
                <Switch>
                    <Route path="/analytics/conversion" component={Conversion} />
                    <Route path="/analytics/reviews" component={Reviews} />
                    <Route path="/analytics/responses" component={Feedback} />
                    <Route path="/analytics/conversations" component={Conversations} />
                    <Route path="/analytics/social-posts" component={SocialPosts} />
                    <Route path="/analytics/presence" component={Presence} />
                    <Redirect from="/analytics" to="/analytics/conversion" />
                </Switch>
            </Container>
        </>
    );
});

AnalyticsPage.displayName = 'AnalyticsPage';

export default AnalyticsPage;
