import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@components/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { Input } from '@components/Input';
import { MultiSelect } from '@components/MultiSelect';
import { Select } from '@components/Select';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { BASE_URL } from '@helpers/api';
import { useCopyToClipboard } from '@hooks/useCopyToClipboard';
import { useThrottle } from '@hooks/useThrottle';
import { SmsProviderType } from '@constants/channels';
import { useTranslation } from '@hooks/useTranslation';
import { CopyIcon } from 'assets/images/icons';

const Container = styled('div')({
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
    '@media (max-width: 600px)': {
        gridTemplateColumns: '100%',
    },
});

const providerTypeOptions = [
    { value: 0, label: 'Twilio' },
    { value: 1, label: 'TurboSMS' },
    { value: 2, label: 'Lifecell' },
    { value: 3, label: ' SMS.to' },
];

const SmsForm = ({ labelsOptions }) => {
    const companies = useSelector(state => state.account?.account?.companies || []);

    const { t } = useTranslation();
    const { onCopy } = useCopyToClipboard();
    const { controlSms, setValueSms, watchSms, twillioUid } = useFormContext();
    const isMobile = useMediaQuery('@media (max-width: 600px)');

    const selectedProvider = watchSms('provider');
    const selectedLocationLabels = watchSms('locationLabels');

    const [onCopyClick] = useThrottle(value => onCopy(value), 1000);

    const companyOptions = useMemo(() => {
        return selectedLocationLabels.length === labelsOptions.length
            ? companies.map(item => ({ value: item.companyId, label: item.internalName }))
            : companies
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [companies, labelsOptions, selectedLocationLabels]);

    return (
        <Container>
            <Controller
                control={controlSms}
                name="name"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsChannels.channelName')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                    />
                )}
            />
            <Controller
                control={controlSms}
                name="provider"
                render={({ field, fieldState: { error }, ref }) => (
                    <Select
                        {...field}
                        ref={ref}
                        onChange={({ target: { value } }) => {
                            setValueSms('provider', value, { shouldDirty: true });
                        }}
                        options={providerTypeOptions}
                        label={t('SettingsChannels.provider')}
                        fullWidth
                        required
                        error={!!error}
                    />
                )}
            />
            <Controller
                control={controlSms}
                name="locationLabels"
                render={({ field, ref }) => (
                    <MultiSelect
                        {...field}
                        ref={ref}
                        label={t('common.locationLabels')}
                        onChange={({ target: { value } }) => {
                            const selectedLabels = getMultiselectSelectedOptions(labelsOptions, value);
                            setValueSms('locationLabels', selectedLabels, { shouldDirty: true });
                            const availableCompanies =
                                selectedLabels.length === labelsOptions.length
                                    ? companies.map(item => item.companyId)
                                    : companies
                                          .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                                          .map(item => item.companyId);
                            setValueSms('companies', availableCompanies, {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                        }}
                        options={labelsOptions}
                        countable
                    />
                )}
            />
            <Controller
                control={controlSms}
                name="companies"
                render={({ field, fieldState: { error }, ref }) => (
                    <MultiSelect
                        {...field}
                        ref={ref}
                        onChange={({ target: { value } }) => {
                            setValueSms('companies', getMultiselectSelectedOptions(companyOptions, value), {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                        }}
                        options={companyOptions}
                        label={t('common.locations')}
                        fullWidth
                        countable
                        required
                        error={error}
                    />
                )}
            />
            {selectedProvider === SmsProviderType.Twilio && (
                <>
                    <Controller
                        control={controlSms}
                        name="accountId"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('SettingsChannels.accountSid')}
                                type="password"
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                required
                            />
                        )}
                    />
                    <Controller
                        control={controlSms}
                        name="apiKey"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('SettingsChannels.authToken')}
                                type="password"
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                required
                            />
                        )}
                    />
                    <Controller
                        control={controlSms}
                        name="phoneNumber"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('common.phoneNumber')}
                                fullWidth
                                error={!!error}
                                required
                                helperText={error ? t(error.message) : null}
                            />
                        )}
                    />
                </>
            )}
            {[SmsProviderType.TurboSms, SmsProviderType.SmsTo].includes(selectedProvider) && (
                <Controller
                    control={controlSms}
                    name="token"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('SettingsChannels.apiToken')}
                            type="password"
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                        />
                    )}
                />
            )}
            {selectedProvider === SmsProviderType.Lifecell && (
                <>
                    <Controller
                        control={controlSms}
                        name="login"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('common.login')}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                required
                            />
                        )}
                    />
                    <Controller
                        control={controlSms}
                        name="password"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('common.password')}
                                type="password"
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                required
                            />
                        )}
                    />
                </>
            )}
            <Controller
                control={controlSms}
                name="senderId"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsChannels.senderId')}
                        fullWidth
                        error={!!error}
                        helperText={
                            error ? (
                                <div style={{ position: 'absolute', top: '-14px', width: '250px' }}>
                                    {t(error.message, { count: 11 })}
                                </div>
                            ) : null
                        }
                        required={selectedProvider !== SmsProviderType.Twilio}
                        addonComponent={
                            <Tooltip title={t('SettingsChannels.senderIdNotice')} placement="right">
                                <HelpIcon sx={{ fontSize: '16px' }} />
                            </Tooltip>
                        }
                    />
                )}
            />
            {selectedProvider === SmsProviderType.Twilio && (
                <Input
                    inputProps={{ value: `${BASE_URL}api/inbox/webhooks/twilio-message/${twillioUid}` }}
                    onAddonClick={() => onCopyClick(`${BASE_URL}api/inbox/webhooks/twilio-message/${twillioUid}`)}
                    addonComponent={<CopyIcon style={{ fill: 'rgba(0, 0, 0, 0.6)' }} />}
                    label="Webhook URL"
                    disabled
                    sx={{ gridColumn: isMobile ? '1 / span 1' : '1 / span 2' }}
                />
            )}
        </Container>
    );
};

export default SmsForm;
