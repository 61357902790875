import PropTypes from 'prop-types';
import { Typography as MuiTypography } from '@mui/material';
import { styled } from '@mui/system';
import theme from '@theme';

const StyledTypohraphy = styled(MuiTypography)({
    '&.MuiTypography-root': theme.typography,
    '&.MuiTypography-h1': theme.typography.h1,
    '&.MuiTypography-h2': theme.typography.h2,
    '&.MuiTypography-h3': theme.typography.h3,
    '&.MuiTypography-h4': theme.typography.h4,
    '&.MuiTypography-h5': theme.typography.h5,
    '&.MuiTypography-h6': theme.typography.h6,
    '&.MuiTypography-body1': theme.typography.body1,
    '&.MuiTypography-body2': theme.typography.body2,
    '&.MuiTypography-caption': theme.typography.caption,
    '&.MuiTypography-title1': theme.typography.title1,
    '&.MuiTypography-subtitle1': theme.typography.subtitle1,
    '&.MuiTypography-subtitle2': theme.typography.subtitle2,
    '&.MuiTypography-button': theme.typography.button,
    '&.MuiTypography-overline': theme.typography.overline,
});

/** Common app typography component */
export const Typography = ({ children, variant, color, align, disabled, className, ...rest }) => {
    let themeColor = '';
    switch (color) {
        case 'textPrimary':
        case undefined:
            themeColor = theme && (!disabled ? theme.palette.text.primary : theme.palette.action.disabled);
            break;
        case 'textSecondary':
            themeColor = theme && (!disabled ? theme.palette.text.secondary : theme.palette.action.disabled);
            break;
        default:
            themeColor = theme && (!disabled ? theme.palette[color].main : theme.palette.action.disabled);
            break;
    }
    return (
        <StyledTypohraphy className={className} variant={variant} sx={{ color: themeColor }} align={align} {...rest}>
            {children}
        </StyledTypohraphy>
    );
};

Typography.propTypes = {
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'textPrimary',
        'textSecondary',
        'error',
        'success',
        'initial',
        'inherit',
    ]),
    variant: PropTypes.oneOf([
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'title1',
        'subtitle1',
        'subtitle2',
        'body1',
        'body2',
        'caption',
        'overline',
        'button',
    ]),
    align: PropTypes.oneOf(['left', 'right', 'center', 'inherit', 'justify']),
    disabled: PropTypes.bool,
    className: PropTypes.string,
};
