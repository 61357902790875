import { useState } from 'react';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Checkbox } from '@components/Checkbox';
import TableCellCheckbox from './TableCellCheckbox';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const TableCellStyled = styled(TableCell)(() => ({
    padding: '4px 16px',
    minHeight: 45,
    height: 45,
    '& .MuiIconButton-root': {
        padding: 8,
        '&:not(:first-of-type)': {
            marginLeft: 16,
        },
        '& svg': {
            color: 'rgba(0, 0, 0, 0.6)',
            width: 20,
            height: 20,
        },
    },
}));
const TableCellCollapsible = styled(TableCell)(() => ({
    padding: 0,
    border: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
}));

const TableRowStyled = styled(TableRow)(() => ({
    '&.MuiTableRow-hover:hover': {
        background: '#F7FBF9',
    },
}));

const TableRowPlain = ({
    rowData,
    dataRow,
    hover = false,
    withCheckbox,
    checked,
    onSelect,
    onClick,
    collapsibleRow,
    isCollapsible,
    colSpan,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRowStyled data-row={dataRow} hover={hover} onClick={(onClick, () => setOpen(!open))}>
                {isCollapsible && (
                    <TableCellStyled align="center">
                        <IconButton onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCellStyled>
                )}
                {withCheckbox && (
                    <TableCellCheckbox>
                        <Checkbox checked={checked} onChange={onSelect} />
                    </TableCellCheckbox>
                )}
                {rowData?.length &&
                    rowData.map((cell, position) => (
                        <TableCellStyled
                            align={position === 0 ? 'left' : 'center'}
                            component="th"
                            scope="row"
                            key={cell?.value || `row-cell-${position}`}
                            width={cell.width || 0}
                        >
                            {cell?.component || cell?.value || cell}
                        </TableCellStyled>
                    ))}
            </TableRowStyled>
            {isCollapsible && (
                <TableRowStyled>
                    <TableCellCollapsible component="th" scope="row" colSpan={colSpan}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {collapsibleRow}
                        </Collapse>
                    </TableCellCollapsible>
                </TableRowStyled>
            )}
        </>
    );
};

export default TableRowPlain;
