import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@components/Input';
import { ColorPicker } from '@components/ColorPicker';
import { Typography } from '@components/Typography';
import { Switch } from '@components/Switch';
import ImageUploaderSingle from '@components/ImageUploaderSingle';
import { useThrottle } from '@hooks/useThrottle';
import { useCopyToClipboard } from '@hooks/useCopyToClipboard';
import { surveyService } from '@services';
import { CopyIcon } from 'assets/images/icons';
import { useTranslation } from '@hooks/useTranslation';
import { MB } from '@constants/file';
import { TwoColumnsForm } from './styles';

const QRCustomization = ({ isView, preview, setPreview, qrLink, selectedSurvey }) => {
    const { t } = useTranslation();
    const [isUploading, setIsUploading] = useState(false);

    const { control, setValue } = useFormContext();
    const { onCopy } = useCopyToClipboard();
    const [onCopyClick] = useThrottle(value => onCopy(value), 1000);

    const uploadLogo = async e => {
        try {
            setIsUploading(true);
            const data = new FormData();
            data.append('file', e.target.files[0]);
            data.append('fileName', e.target.files[0].name);
            const response = await surveyService.uploadQRLogoImage(data, selectedSurvey.id);
            setPreview(prev => ({ ...prev, qrLogoUrl: response.publicUrl }));
            setValue('qrLogoUrl', response.publicUrl, { shouldDirty: true });
        } catch (_) {
            console.error("Couldn't upload logo for survey");
        }
        setIsUploading(false);
        e.target.value = '';
    };

    const onDeleteLogo = () => {
        setPreview(prev => ({ ...prev, qrLogoUrl: null }));
        setValue('qrLogoUrl', null, { shouldDirty: true });
    };

    return (
        <>
            <Input
                inputProps={{ value: qrLink }}
                label={t('common.url')}
                fullWidth
                onAddonClick={() => onCopyClick(qrLink)}
                addonComponent={<CopyIcon style={{ fill: 'rgba(0, 0, 0, 0.6)' }} />}
                sx={{ marginBottom: '8px' }}
                disabled
            />
            <Controller
                control={control}
                name="customizeQrCodeDesign"
                render={({ field, ref }) => (
                    <Switch
                        {...field}
                        ref={ref}
                        checked={field.value}
                        handleChange={event => {
                            field.onChange(event.target.checked);
                            setPreview(prev => ({ ...prev, customizeQrCodeDesign: event.target.checked }));
                        }}
                        label={t('FeedbackSurveys.customizeQrCodeDesign')}
                        sx={{ marginBottom: '8px' }}
                    />
                )}
            />
            {preview.customizeQrCodeDesign ? (
                <>
                    <Typography variant="subtitle1">{t('common.logo')}</Typography>
                    <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', margin: '4px 0 8px' }}>
                        {t('FeedbackSurveys.recommendedDimension')}
                    </Typography>
                    <ImageUploaderSingle
                        maxSize={10 * MB}
                        logoUrl={preview.qrLogoUrl}
                        disabled={isUploading || isView}
                        onDelete={onDeleteLogo}
                        uploadLogo={uploadLogo}
                        style={{ marginBottom: 24 }}
                        isUploading={isUploading}
                    />
                    <Controller
                        control={control}
                        name="topText"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{
                                    ...field,
                                    onChange: e => {
                                        field.onChange(e.target.value);
                                        setPreview(prev => ({ ...prev, topText: e.target.value }));
                                    },
                                }}
                                label={t('FeedbackSurveys.topText')}
                                fullWidth
                                error={!!error}
                                sx={{ marginBottom: '8px' }}
                                helperText={error ? t(error.message, { count: 42 }) : null}
                                disabled={isView}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="bottomText"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{
                                    ...field,
                                    onChange: e => {
                                        field.onChange(e.target.value);
                                        setPreview(prev => ({ ...prev, bottomText: e.target.value }));
                                    },
                                }}
                                label={t('FeedbackSurveys.bottomText')}
                                fullWidth
                                error={!!error}
                                sx={{ marginBottom: '8px' }}
                                helperText={error ? t(error.message, { count: 42 }) : null}
                                disabled={isView}
                            />
                        )}
                    />
                    <Typography variant="subtitle1" sx={{ marginBottom: '8px' }}>
                        {t('FeedbackSurveys.colors')}
                    </Typography>
                    <TwoColumnsForm>
                        <Controller
                            control={control}
                            name="backgroundColor"
                            render={({ field, fieldState: { error }, ref }) => (
                                <ColorPicker
                                    ref={ref}
                                    inputProps={{
                                        ...field,
                                        onChange: e => {
                                            const val = e?.target?.value ? e.target.value : e;
                                            field.onChange(val);
                                            setPreview(prev => ({ ...prev, backgroundColor: val }));
                                        },
                                    }}
                                    label={t('FeedbackSurveys.backgroundColor')}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? t(error.message) : null}
                                    disabled={isView}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="fillColor"
                            render={({ field, fieldState: { error }, ref }) => (
                                <ColorPicker
                                    ref={ref}
                                    inputProps={{
                                        ...field,
                                        onChange: e => {
                                            const val = e?.target?.value ? e.target.value : e;
                                            field.onChange(val);
                                            setPreview(prev => ({ ...prev, fillColor: val }));
                                        },
                                    }}
                                    label={t('FeedbackSurveys.fillColor')}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? t(error.message) : null}
                                    disabled={isView}
                                />
                            )}
                        />
                    </TwoColumnsForm>
                </>
            ) : null}
        </>
    );
};

export default QRCustomization;
