import { authHeader, BASE_URL } from '@helpers/api';

export const browserService = {
    createWebPushSubscription,
    deleteWebPushSubscription,
};

function createWebPushSubscription(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/push-notifications/subscriptions`, requestOptions);
}

function deleteWebPushSubscription(endpoint) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/push-notifications/subscriptions?endpoint=${endpoint}`, requestOptions);
}
