import { notificationsConstants } from '@constants/notifications';

const initialState = {
    data: [],
    totalCount: 0,
    hasMore: false,
    loading: true,
};

export function notifications(state = initialState, action) {
    switch (action.type) {
        case notificationsConstants.NOTIFICATIONS_FETCH:
            return { ...state };
        case notificationsConstants.NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.items,
                hasMore: action.data.hasMore,
                totalCount: action.data.totalCount,
            };
        case notificationsConstants.NOTIFICATIONS_LOAD_MORE_SUCCESS:
            return {
                ...state,
                data: [...state.data, ...action.data.items],
                hasMore: action.data.hasMore,
            };
        case notificationsConstants.NOTIFICATIONS_ERROR:
            return { ...state, loading: false };
        case notificationsConstants.NOTIFICATIONS_SET:
            return { ...state, data: action.data.items, totalCount: action.data.totalCount };
        default:
            return state;
    }
}
