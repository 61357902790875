import { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';
import LogoutModal from '@features/LogoutModal';
import Notifications from '@features/Notifications';
import { ExternalLink } from '@components/Link';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { pricingDrawerUiActions } from '@actions';
import { PRICING_DRAWER_TYPE } from '@constants/pricing-drawer-ui';
import {
    HomeIcon,
    SurveysIcon,
    ReviewsIcon,
    AnalyticsIcon,
    SocialIcon,
    MessagingIcon,
    MicrositesIcon,
    SettingsIcon,
    LogoutIcon,
    HelpCenterIcon,
    CampaignsIcon,
    ContactsIcon,
    PresenceIcon,
} from 'assets/images/sidebar';
import { ReactComponent as LogoIcon } from 'assets/images/logo.svg';
import { ReactComponent as PluspointLogo } from 'assets/images/pluspoint-logo.svg';
import MenuItem from './MenuItem';
import BurgerButton from './BurgerButton';
import { Header, PluspointText, LogoWrapper, LogoPrintWrapper, List, ListItem, SidebarDrawer } from './styles';

const Sidebar = memo(({ isMobile }) => {
    const { t, i18n } = useTranslation();

    const [isExpanded, setExpanded] = useState(false);
    const [logoutIsOpen, setLogoutIsOpen] = useState(false);

    const account = useSelector(state => state.account?.account);
    const dispatch = useDispatch();

    const productsMaxNumberExceeded =
        account?.companiesMaxCount < account?.companies?.length || account?.usersMaxCount < account?.users?.length;
    const billingExpired = account && account?.billingDiffInSeconds < 0;
    const advancedFeaturesLocked = !account?.campaignsEnabled && !account?.surveysEnabled;

    const onClickUpgradeItem = () => {
        dispatch(pricingDrawerUiActions.setOpen({ open: true, type: PRICING_DRAWER_TYPE.UPGRADE }));
    };

    const getHelpLink = () => {
        switch (i18n.language) {
            case 'ES':
                return 'https://pluspoint.notion.site/Help-Center-ES-60e61ca791c04b65b160f508fe422c6d';
            case 'PT':
                return 'https://pluspoint.notion.site/Help-Center-PT-5fb5ca9f13fa4671a0ef656f16ae973f';
            case 'UA':
                return 'https://pluspoint.notion.site/Help-Center-UK-e685fa7caca14eeaa268982eb3d4d172';
            default:
                return 'https://pluspoint.notion.site/Help-Center-04ab5ad456f148c3aa7c079f7c4711f5';
        }
    };

    const MENU_ITEMS = [
        {
            id: 'pl-menu-left-link-home',
            title: t('Sidebar.home'),
            icon: <HomeIcon />,
            to: '/',
        },
        {
            id: 'pl-menu-left-link-contacts',
            title: t('Sidebar.contacts'),
            icon: <ContactsIcon />,
            to: '/contacts',
        },
        {
            id: 'pl-menu-left-link-presence',
            title: t('Sidebar.presence'),
            icon: <PresenceIcon />,
            base: '/presence',
            children: [
                { title: t('Sidebar.presenceProfiles'), to: '/presence/profiles' },
                { title: t('Sidebar.presenceMedia'), to: '/presence/media' },
            ],
        },
        {
            id: 'pl-menu-left-link-reviews',
            title: t('Sidebar.reviews'),
            icon: <ReviewsIcon />,
            base: '/reviews',
            children: [
                { title: t('Sidebar.reviewsManage'), to: '/reviews/manage' },
                { title: t('Sidebar.reviewsTemplates'), to: '/reviews/templates' },
                { title: t('Sidebar.reviewsAutoreplies'), to: '/reviews/auto-replies' },
                { title: t('Sidebar.reviewsWidgets'), to: '/reviews/widgets' },
            ],
        },
        {
            id: 'pl-menu-left-link-messaging',
            title: t('Sidebar.messaging'),
            icon: <MessagingIcon />,
            base: '/messaging',
            children: [
                { title: t('Sidebar.messagingInbox'), to: '/messaging/inbox' },
                { title: t('common.templates'), to: '/messaging/templates' },
                // { title: t('Sidebar.chatbot'), to: '/messaging/chatbot/knowledge-base' },
            ],
        },
        {
            id: 'pl-menu-left-link-social-posts',
            title: t('Sidebar.socialPosts'),
            icon: <SocialIcon />,
            to: '/social-posts',
        },
        {
            id: 'pl-menu-left-link-microsites',
            title: t('Sidebar.microsites'),
            icon: <MicrositesIcon />,
            to: '/microsites',
        },
        {
            id: 'pl-menu-left-link-feedback',
            title: t('Sidebar.surveys'),
            icon: <SurveysIcon />,
            base: '/surveys',
            children: [
                {
                    title: t('Sidebar.sent'),
                    to: '/surveys/sent',
                    upgradeRequired: !account?.surveysEnabled,
                    upgradeClick: onClickUpgradeItem,
                },
                {
                    title: t('Sidebar.templates'),
                    to: '/surveys/templates',
                    upgradeRequired: !account?.surveysEnabled,
                    upgradeClick: onClickUpgradeItem,
                },
                {
                    title: t('Sidebar.responses'),
                    to: '/surveys/responses',
                    upgradeRequired: !account?.surveysEnabled,
                    upgradeClick: onClickUpgradeItem,
                },
                {
                    title: t('Sidebar.autoSurveys'),
                    to: '/surveys/auto-surveys',
                    upgradeRequired: !account?.surveysEnabled,
                    upgradeClick: onClickUpgradeItem,
                },
            ],
        },
        {
            id: 'pl-menu-left-link-campaigns',
            title: t('Sidebar.campaigns'),
            icon: <CampaignsIcon />,
            base: '/campaigns',
            children: [
                {
                    title: t('Sidebar.campaignsOneTime'),
                    to: '/campaigns/one-time',
                    upgradeRequired: !account?.campaignsEnabled,
                    upgradeClick: onClickUpgradeItem,
                },
                {
                    title: t('Sidebar.campaignsAutomated'),
                    to: '/campaigns/automated',
                    upgradeRequired: !account?.campaignsEnabled,
                    upgradeClick: onClickUpgradeItem,
                },
            ],
        },
        {
            id: 'pl-menu-left-link-analytics',
            title: t('Sidebar.analytics'),
            icon: <AnalyticsIcon />,
            base: '/analytics',
            children: [
                {
                    title: t('Sidebar.analyticsConversion'),
                    to: '/analytics/conversion',
                    upgradeRequired: advancedFeaturesLocked,
                    upgradeClick: onClickUpgradeItem,
                },
                { title: t('Sidebar.analyticsReviews'), to: '/analytics/reviews' },
                {
                    title: t('Sidebar.analyticsSurveys'),
                    to: '/analytics/responses',
                    upgradeRequired: advancedFeaturesLocked,
                    upgradeClick: onClickUpgradeItem,
                },
                { title: t('Sidebar.analyticsConversations'), to: '/analytics/conversations' },
                { title: t('Sidebar.analyticsSocialPosts'), to: '/analytics/social-posts' },
                { title: t('Sidebar.analyticsPresence'), to: '/analytics/presence' },
                { title: t('Sidebar.keywords'), to: '/keywords' },
            ],
        },
    ];

    return (
        <SidebarDrawer
            PaperProps={{ id: 'pl-menu-left-list' }}
            variant="permanent"
            anchor={isMobile ? 'top' : 'left'}
            open
            isExpanded={isExpanded}
        >
            <Header>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LogoPrintWrapper>
                            <PluspointLogo />
                        </LogoPrintWrapper>
                        <LogoWrapper>
                            <LogoIcon />
                        </LogoWrapper>
                        <PluspointText>Pluspoint</PluspointText>
                    </div>
                </Link>
                <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                    {isMobile && <Notifications />}
                    {isMobile && <BurgerButton isExpanded={isExpanded} setExpanded={setExpanded} />}
                </div>
            </Header>
            <List isExpanded={isExpanded} isMobile={isMobile}>
                {MENU_ITEMS.map((link, index) => (
                    <MenuItem
                        key={index}
                        exact
                        item={link}
                        callback={() => setExpanded(false)}
                        disabled={productsMaxNumberExceeded || billingExpired}
                    />
                ))}
                <Divider sx={{ backgroundColor: '#373737', margin: '16px 0' }} />
                <ExternalLink href={getHelpLink()} sx={{ textDecoration: 'none', width: '100%' }}>
                    <ListItem button onClick={() => setExpanded(false)}>
                        <span />
                        <HelpCenterIcon />
                        <Typography variant="body1">{t('Sidebar.helpCenter')}</Typography>
                    </ListItem>
                </ExternalLink>
                <MenuItem
                    exact={false}
                    item={{
                        id: 'pl-menu-left-link-settings',
                        to: '/account-settings/',
                        title: t('Sidebar.settings'),
                        icon: <SettingsIcon />,
                    }}
                    callback={() => setExpanded(false)}
                />
                {isMobile && (
                    <ListItem
                        button
                        onClick={() => {
                            setExpanded(false);
                            setLogoutIsOpen(true);
                        }}
                    >
                        <span />
                        <LogoutIcon />
                        <Typography variant="body1">{t('Sidebar.logout')}</Typography>
                    </ListItem>
                )}
            </List>
            <LogoutModal isOpen={logoutIsOpen} setIsOpen={setLogoutIsOpen} />
        </SidebarDrawer>
    );
});

Sidebar.displayName = 'Sidebar';

export default Sidebar;
