import { Component } from 'react';
import ErrorPage from '@pages/Error';
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        /* eslint-disable no-console */
        console.log('ERROR: ', error);
        console.log('INFO: ', info);
        /* eslint-enable no-console */
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
