import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { MultiSelect } from '@components/MultiSelect';
import { Typography } from '@components/Typography';
import { MultiSelectLabels } from '@components/MultiSelectLabels';
import { useTranslation } from '@hooks/useTranslation';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { CAMPAIGN_TYPE } from '@constants/campaigns';
import { CHANNEL } from '@constants/channels';

export const MultiSelectWithIcon = styled(MultiSelect)({
    '& .MuiSelect-outlined': {
        paddingRight: '50px !important',
    },
    '& .MuiInputBase-adornedEnd': {
        paddingRight: 0,
    },
});

const LabelsFields = ({ isView, campaignType, channelType, getContactsNumber, contactsNumber, labels }) => {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();

    const labelsOptions = useMemo(() => labels.map(item => ({ value: item.id, label: item.name })), [labels]);

    return (
        <div style={{ gridArea: 'contactLabels' }}>
            <Controller
                control={control}
                name="labels"
                render={({ field, fieldState: { error }, ref }) => (
                    <div>
                        <MultiSelectLabels
                            {...field}
                            ref={ref}
                            onChange={({ target: { value } }) => {
                                setValue('labels', getMultiselectSelectedOptions(labelsOptions, value), {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                                if (campaignType === CAMPAIGN_TYPE.ONETIME) {
                                    getContactsNumber();
                                }
                            }}
                            options={labelsOptions}
                            label={t('CampaignsPage.audience')}
                            fullWidth
                            editable={false}
                            disabled={isView}
                            error={!!error}
                        />
                    </div>
                )}
            />
            {!isView && campaignType === CAMPAIGN_TYPE.ONETIME && (
                <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                    {t('CampaignsPage.contactsNumber', { count: contactsNumber })}
                </Typography>
            )}
            {!isView &&
                campaignType === CAMPAIGN_TYPE.ONETIME &&
                channelType === CHANNEL.WHATSAPP &&
                contactsNumber > 49 && (
                    <div>
                        <Typography variant="caption" sx={{ color: '#FF8700' }}>
                            {t('CampaignsPage.warningMessage')}
                        </Typography>
                    </div>
                )}
        </div>
    );
};

export default LabelsFields;
