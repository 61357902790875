export function getFacebookLoginStatus() {
    return new Promise(resolve => {
        window.FB.getLoginStatus(response => {
            resolve(response);
        });
    });
}

export function loginFacebook() {
    return new Promise(resolve =>
        window.FB.login(
            response => {
                resolve(response);
            },
            {
                config_id: '649437327208138',
                response_type: 'code',
                auth_type: 'rerequest',
            },
        ),
    );
}

export function loginMetaBusiness() {
    return new Promise(resolve =>
        window.FB.login(
            response => {
                resolve(response);
            },
            {
                config_id: '8492726760741889',
                response_type: 'code',
                auth_type: 'rerequest',
                override_default_response_type: true,
            },
        ),
    );
}
