import { feedbacksService } from '@services';
import { feedbacksConstants } from '@constants/feedbacks';

export const feedbacksActions = {
    getAll,
    loadMore,
    successAddOrUpdateTicket,
    resetLoading,
    resetNewExist,
    checkNewExistSuccess,
    update,
};

function getAll(query) {
    return dispatch => {
        dispatch(request());
        feedbacksService.getAll(query).then(
            data => {
                dispatch(successAll(data));
                feedbacksService.markAsViewed(data.items.map(r => r.id));
            },
            () => {},
        );
    };
}

function loadMore(query) {
    return dispatch => {
        feedbacksService.getAll(query).then(
            data => {
                dispatch(successLoadMore(data));
                feedbacksService.markAsViewed(data.items.map(r => r.id));
            },
            () => {},
        );
    };
}

function request() {
    return { type: feedbacksConstants.FEEDBACKS_REQUEST };
}

function successAll(feedbacks) {
    return { type: feedbacksConstants.FEEDBACKS_SUCCESS, feedbacks };
}

function successLoadMore(feedbacks) {
    return { type: feedbacksConstants.FEEDBACKS_LOAD_MORE_SUCCESS, feedbacks };
}

function successAddOrUpdateTicket(feedbacks) {
    return { type: feedbacksConstants.ADD_OR_UPDATE_TICKET, feedbacks };
}

function resetLoading() {
    return { type: feedbacksConstants.RESET_LOADING };
}

function checkNewExistSuccess(data) {
    return { type: feedbacksConstants.NEW_FEEDBACK_EXIST_SUCCESS, payload: data };
}

function resetNewExist() {
    return { type: feedbacksConstants.NEW_FEEDBACK_EXIST_RESET };
}

function update(feedbacks) {
    return { type: feedbacksConstants.FEEDBACKS_UPDATE, feedbacks };
}
