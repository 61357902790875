import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { alertActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';

const NotFoundPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(alertActions.error(t('ErrorsPages.notFoundPage')));
    }, [dispatch]);

    return <Redirect to="/" />;
};

export default NotFoundPage;
