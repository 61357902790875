export const inboxConstants = {
    INBOX_HISTORY_FETCH: 'INBOX_HISTORY_FETCH',
    INBOX_HISTORY_SUCCESS: 'INBOX_HISTORY_SUCCESS',
    INBOX_HISTORY_UPDATE: 'INBOX_HISTORY_UPDATE',
    INBOX_RESET: 'INBOX_RESET',
};

export const MESSAGE_STATUS = {
    SENT: 0,
    DELIVERED: 1,
    FAILED: 2,
    READ: 3,
};

export const MESSAGE_CONTENT_TYPE = {
    TEXT: 0,
    IMAGE: 1,
    FILE: 2,
    VIDEO: 3,
    AUDIO: 4,
    STORY_VIDEO: 5,
    STORY_IMAGE: 6,
};

export const MESSAGE_TYPE = {
    INCOMING: 0,
    OUTGOING: 1,
};

export const INBOX_HISTORY = {
    MESSAGE: 0,
    CONVERSATION: 1,
};

export const SENDER_TYPE = {
    USER: 0,
    BOT: 1,
};
