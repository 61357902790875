import i18next from 'i18next';
import { styled } from '@mui/material/styles';
import { SmsIcon, WhatsAppIcon, EmailIcon, LiveChatIcon, MessengerIcon, InstagramIcon } from 'assets/images/channels';
import { DeleteIcon } from 'assets/images/icons';
import { CHANNEL, STATUS } from '@constants/channels';

const StyledSvgWrapper = styled('span')({
    '& svg path': {
        fill: '#1F4C5C',
    },
});

export const getChannelIcon = type => {
    switch (type) {
        case 0:
            return <SmsIcon />;
        case 1:
            return <WhatsAppIcon />;
        case 2:
            return <EmailIcon />;
        case 4:
            return <LiveChatIcon />;
        case 5:
            return <MessengerIcon />;
        case 6:
            return <InstagramIcon />;
        case null:
            return (
                <StyledSvgWrapper>
                    <DeleteIcon />
                </StyledSvgWrapper>
            );
        default:
            return <SmsIcon />;
    }
};

export function getCommonChannelsByCompanyIds(companyIds, companies) {
    let commonChannels = null;
    companyIds.forEach(id => {
        const channelIds = new Set(companies.find(company => company.companyId === id)?.channels || []);
        if (commonChannels === null) {
            commonChannels = channelIds;
        } else {
            commonChannels = new Set([...commonChannels].filter(id => channelIds.has(id)));
        }
    });
    return commonChannels ? [...commonChannels] : [];
}

export const getFilteredByCompaniesChannelsIds = (companyIds, companies) => {
    return companies
        ?.filter(company => companyIds.includes(company.companyId))
        ?.map(company => company.channels)
        ?.flat();
};

export const getFilteredByCompanyChannelsIds = (companyId, companies) =>
    companyId ? companies?.find(company => company.companyId === companyId)?.channels : companies[0]?.channels;

const FILTER = {
    in: false,
    out: false,
    status: true,
    contact: null,
    smsTwoWay: false,
    withArchivedMultiselect: false,
    withArchivedSelect: false,
    allowDisconnected: false,
};
/**
 * Solves equations of the form a * x = b
 * @param {Object} filter - Object with in and out boolean values for filtering incoming and outgoing channels and status
 *                          for filtering by status SUCCESS and contact for filtering by email and phoneNumber
 * @param {Array} availableChannels - Array of channel ids.
 * @param {Array} channels - Array of channels.
 * @returns {ReactNode} Returns filtered channel option for multiselect.
 */
export const getChannelOptions = (filter, availableChannels, channels = []) => {
    const updatedFilter = { ...FILTER, ...filter };
    const options = channels
        .filter(channel => {
            if (!availableChannels.includes(channel?.id)) {
                return false;
            }
            if (channel?.incoming !== updatedFilter.in && channel?.outgoing !== updatedFilter.out) {
                return false;
            }
            if (
                updatedFilter.status &&
                channel?.status !== STATUS.SUCCESS &&
                (channel?.status !== STATUS.DISCONNECTED || !updatedFilter.allowDisconnected)
            ) {
                return false;
            }
            if (
                updatedFilter?.contact &&
                ((channel?.type === CHANNEL.EMAIL && !updatedFilter?.contact?.email) ||
                    ((channel?.type === CHANNEL.SMS || channel?.type === CHANNEL.WHATSAPP) &&
                        !updatedFilter?.contact?.phoneNumber))
            ) {
                return false;
            }
            //to filter out twilio two way sms channels, only the ones don't have senderId
            if (updatedFilter?.smsTwoWay && channel?.type === CHANNEL.SMS && channel?.smsParameters?.senderId) {
                return false;
            }
            return true;
        })
        ?.map(channel => ({
            value: channel?.id,
            label: (
                <span style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    {getChannelIcon(channel?.type)} {channel?.name}
                </span>
            ),
            name: channel?.name,
            type: channel?.type,
        }));

    return updatedFilter.withArchivedMultiselect || updatedFilter.withArchivedSelect
        ? [
              ...options,
              {
                  value: updatedFilter.withArchivedMultiselect ? CHANNEL.ARCHIVED : 'ARCHIVED',
                  label: (
                      <span style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                          {getChannelIcon(CHANNEL.ARCHIVED)} {i18next.t('common.archived')}
                      </span>
                  ),
                  name: i18next.t('common.archived'),
              },
          ]
        : options;
};

export const checkTwilioTwoWaySms = channel =>
    channel.type === CHANNEL.SMS && !!channel.smsParameters && !channel.smsParameters?.senderId;

export const checkWhatsAppStatus = channel =>
    channel.type === CHANNEL.WHATSAPP && (channel.status === STATUS.SUCCESS || channel.status === STATUS.DISCONNECTED);
