import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const sendMessageService = {
    sendMessage,
    sendBulkMessages,
    sendContactMessage,
    sendBulkContactMessages,
};

function sendMessage(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/surveys/send-single`, requestOptions).then(handleResponse);
}

function sendBulkMessages(list) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(list),
    };

    return fetch(`${BASE_URL}api/surveys/send-bulk`, requestOptions).then(handleResponse);
}

function sendContactMessage(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/contacts/direct-messages`, requestOptions).then(handleResponse);
}

function sendBulkContactMessages(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/contacts/bulk-messages`, requestOptions).then(handleResponse);
}
