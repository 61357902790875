import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';

const CssCharCounter = styled(Typography)({
    position: 'absolute',
    bottom: 24,
    right: 12,
});

const SmsContent = styled('div', {
    shouldForwardProp: prop => prop !== 'hasLimit',
})(({ hasLimit }) => ({
    width: '100%',
    maxWidth: '100%',
    minWidth: '100%',
    minHeight: '104px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    padding: hasLimit ? '16.5px 14px' : '16.5px 14px 24px',
    height: 'auto',
    backgroundColor: 'transparent',
    outline: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Rubik, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.15px',
    '&:hover': {
        border: '1px solid #1F4C5C',
    },
    '&:focus': {
        border: '2px solid #1F4C5C',
        width: 'calc(100% - 2px)',
    },
    '&::after': {
        content: '"[link]"',
        color: '#9E9E9E',
        fontWeight: 'normal',
    },
}));

const TagName = styled('span')({
    display: 'contents',
    fontWeight: 'normal',
    color: '#9e9e9e',
});

const Legend = styled('p')({
    display: 'block',
    height: '11px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'Rubik',
    fontSize: '14px',
    transform: 'scale(0.8)',
    background: 'white',
    position: 'absolute',
    left: '0px',
    margin: '0',
    top: '-8px',
    padding: '0 3px',
});

export const SmsTemplate = ({
    userName = '',
    location = '',
    smsContent = '',
    onEdit = () => {},
    setDisabled = () => {},
    label,
    maxLength,
}) => {
    const [text, setText] = useState('');
    const [withOutNameContent, setWithOutNameContent] = useState('');
    const [content, setContent] = useState([]);
    const [isNamePresents, setIsNamePresents] = useState(false);

    useEffect(() => {
        const onChangeSms = () => {
            const content = document.getElementById('sms') && document.getElementById('sms').innerText;
            setText(content);
            onEdit(content);
        };
        const onChangeSmsWithOut = () => {
            const content = document.getElementById('smsWithOut') && document.getElementById('smsWithOut').innerText;
            setText(content);
            onEdit(content);
        };

        if (document.getElementById('sms')) {
            document.getElementById('sms').addEventListener('input', onChangeSms, 500);
        }
        if (document.getElementById('smsWithOut')) {
            document.getElementById('smsWithOut').addEventListener('input', onChangeSmsWithOut, 500);
        }
        return () => {
            document.getElementById('sms')?.removeEventListener('input', onChangeSms, 500);
            document.getElementById('smsWithOut')?.removeEventListener('input', onChangeSmsWithOut, 500);
        };
    }, [onEdit]);

    useEffect(() => {
        if (text) {
            setDisabled(!checkText(text));
            if (text !== smsContent) {
                onEdit();
            }
        }
    }, [text]);

    const checkText = text => {
        let tokens = [];
        var valid = true;
        if (text) {
            text.replace(/\[(.*?)]/g, function (a, b) {
                tokens.push(b);
            });
            for (var token of tokens) {
                if (token !== 'name') {
                    valid = false;
                }
            }
        }
        return valid;
    };

    useEffect(() => {
        const isPresents = smsContent && smsContent.includes('[name]');
        setIsNamePresents(isPresents);
        setText(smsContent?.replace(/\[location\]/g, location) || '');
        if (isPresents) {
            setWithOutNameContent('');
            setContent(smsContent.replace(/\[location\]/g, location).split('[name]'));
        } else {
            setContent([]);
            setWithOutNameContent(smsContent?.replace(/\[location\]/g, location) || '');
        }
    }, [smsContent]);

    useEffect(() => {
        if (document.getElementById('sms') && document.getElementById('sms').innerText) {
            setText(document.getElementById('sms').innerText);
        }
    }, [userName, content]);

    return (
        smsContent && (
            <div style={{ position: 'relative', paddingBottom: '16px' }}>
                {label && <Legend>{label}</Legend>}
                {isNamePresents ? (
                    <SmsContent id="sms" contentEditable suppressContentEditableWarning={true} hasLimit={!maxLength}>
                        {content[0]}
                        <span suppressContentEditableWarning={true} contentEditable="false">
                            {userName && userName.length ? userName : <TagName>[name]</TagName>}
                        </span>
                        {content[1]}
                    </SmsContent>
                ) : (
                    <SmsContent
                        id="smsWithOut"
                        contentEditable
                        suppressContentEditableWarning={true}
                        hasLimit={!maxLength}
                    >
                        {withOutNameContent}
                    </SmsContent>
                )}
                {maxLength ? (
                    <CssCharCounter
                        variant="caption"
                        sx={{
                            color:
                                text.length > maxLength
                                    ? '#B00020'
                                    : text.length > maxLength - 20
                                    ? '#ff9900'
                                    : 'rgba(0, 0, 0, 0.6)',
                        }}
                    >
                        {text.length}
                        <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>/{maxLength}</span>
                    </CssCharCounter>
                ) : null}
            </div>
        )
    );
};
