export const surveysPreview = {
    GB: {
        headerTitle: 'Thank you\n for choosing us!',
        footerTitle: 'How likely are you to recommend us to others?',
        footerButton: 'NEXT STEP',
        positiveTitle: 'We appreciate your positive response',
        positiveMessage: 'If you have one minute, your public review would help us a lot',
        positiveFooterButton: 'Leave a review',
        negativeTitle: 'Your opinion matters!',
        negativeMessage:
            'Take a minute to share your thoughts on how to improve our service. Your feedback goes straight to our director.',
        negativeFooterTitle: 'What could we do better?',
        negativeFooterPlaceholder: 'Leave your comment or advice here',
        negativeFooterButton: 'Leave my feedback',
        confirmationTitle: 'Thank you for your feedback!',
        confirmationMessage: 'We will review it as soon as possible and take action',
        iAgreeTo: 'I agree to',
        pp: 'Privacy Policy',
        name: 'Name',
        phone: 'Mobile number',
        email: 'Email',
        reviewHelpful: 'Your opinion counts – share yours now',
        redirecting: 'Redirecting you to our {{0}} page',
        reviewHelpfulInstagram: 'We appreciate your positive response',
        redirectingInstagram: 'Follow us on Instagram - be the first to know about promotions and discounts',
        leaveReview: 'Leave a review',
        somethingWrong: 'Something went wrong',
    },
    ES: {
        headerTitle: '¡Gracias por elegirnos!',
        footerTitle: '¿Qué tan probable es que nos recomiende a otros?',
        footerButton: 'PRÓXIMO PASO',
        positiveTitle: 'Agradecemos su respuesta positiva!',
        positiveMessage: 'Si tiene un minuto, nos ayudaría mucho que escribiera una reseña pública.',
        positiveFooterButton: 'DEJAR UNA RESEÑA',
        negativeTitle: '¡Tu opinion importa!',
        negativeMessage:
            'Tómese un minuto para compartir sus ideas sobre cómo mejorar nuestro servicio. Sus comentarios van directamente a nuestro director.',
        negativeFooterTitle: '¿Que podriamos hacer mejor?',
        negativeFooterPlaceholder: 'Deja aquí tu queja o consejo',
        negativeFooterButton: 'DEJAR COMENTARIOS',
        confirmationTitle: '¡Gracias por tus comentarios!',
        confirmationMessage: 'Lo revisaremos lo antes posible y tomaremos medidas.',
        iAgreeTo: 'Acepto la',
        pp: 'Política de privacidad',
        name: 'Nombre',
        phone: 'Número de móvil',
        email: 'Email',
        reviewHelpful: 'Tu reseña nos ayudaría mucho',
        redirecting: 'Te redirigimos a nuestra página de {{0}}',
        reviewHelpfulInstagram: 'Agradecemos su respuesta positiva',
        redirectingInstagram: 'Síguenos en Instagram:  sé el primero en enterarte de promociones y descuentos',
        leaveReview: 'Dejar un comentario',
        somethingWrong: 'Algo salió mal',
    },
    PT: {
        headerTitle: 'Obrigado por nos teres escolhido!',
        footerTitle: 'Qual é a probabilidade de nos recomendares?',
        footerButton: 'PRÓXIMO PASSO',
        positiveTitle: 'Agradecemos a tua resposta!',
        positiveMessage: 'Se tiveres um minuto avalia-nos nas seguintes plataformas',
        positiveFooterButton: 'Deixe uma REVISÃO',
        negativeTitle: 'A tua opinião é muito importante para nós!',
        negativeMessage:
            'As tuas sugestões ajudam-nos a melhorar continuamente e a oferecer-te um serviço cada vez melhor.',
        negativeFooterTitle: 'O que poderíamos ter feito melhor?',
        negativeFooterPlaceholder: 'Deixa-nos aqui a tua sugestão',
        negativeFooterButton: 'DAR A MINHA OPINIÃO',
        confirmationTitle: 'Obrigado pela sua opinião!',
        confirmationMessage: 'Iremos analisá-lo o mais rapidamente possível e tomaremos medidas',
        iAgreeTo: 'Concordo com a',
        pp: 'Política de Privacidade',
        name: 'Nome',
        phone: 'Número de telemóvel',
        email: 'Email',
        reviewHelpful: 'A sua opinião conta - partilhe agora a sua connosco',
        redirecting: 'Redireccionando-o para a nossa página {{0}}.',
        reviewHelpfulInstagram: 'Agradecemos a tua resposta!',
        redirectingInstagram: 'Siga-nos no Instagram - seja o primeiro a saber sobre promoções e descontos',
        leaveReview: 'Deixa um comentário',
        somethingWrong: 'Alguma coisa correu mal',
    },
    BR: {
        headerTitle: 'Obrigado por nos escolher!',
        footerTitle: 'Qual a probabilidade de você nos recomendar?',
        footerButton: 'PRÓXIMO PASSO',
        positiveTitle: 'Agradecemos sua resposta positiva',
        positiveMessage: 'Se você tiver um minuto, sua avaliação pública nos ajudaria muito',
        positiveFooterButton: 'Deixe uma REVISÃO',
        negativeTitle: 'A sua opinião é importante para nós!',
        negativeMessage:
            'Levará apenas um minuto para compartilhar suas ideias sobre como podemos melhorar o nosso serviço. O seu feedback irá diretamente ao nosso diretor.',
        negativeFooterTitle: 'O que poderíamos fazer melhor?',
        negativeFooterPlaceholder: 'Deixe aqui a sua reclamação ou conselho',
        negativeFooterButton: 'DEIXE MEU COMENTÁRIO',
        confirmationTitle: 'Obrigado pelo seu feedback!',
        confirmationMessage: 'Vamos analisá-lo o mais rápido possível e tomar medidas',
        iAgreeTo: 'Concordo com a',
        pp: 'Política de Privacidade',
        name: 'Nome',
        phone: 'Número de celular',
        email: 'Email',
        reviewHelpful: 'Sua opinião conta - compartilhe a sua agora',
        redirecting: 'Redirecionando você para nossa página do {{0}}',
        reviewHelpfulInstagram: 'Agradecemos sua resposta positiva',
        redirectingInstagram: 'Siga-nos no Instagram - seja o primeiro a saber das promoções e descontos',
        leaveReview: 'Deixe um comentário',
        somethingWrong: 'Algo deu errado',
    },
    UA: {
        headerTitle: 'Дякуємо,\n  що обрали нас!',
        footerTitle: 'Наскільки ймовірно, що ви порекомендуєте нас іншим?',
        footerButton: 'НАСТУПНИЙ КРОК',
        positiveTitle: 'Дякуємо за високу оцінку!',
        positiveMessage: 'Якщо у вас є хвилина, ми були б дуже вдячні за вашу рекомендацію.',
        positiveFooterButton: 'Рекомендувати',
        negativeTitle: 'Ваша думка важлива!',
        negativeMessage:
            'Будь ласка, поділіться своїми думками про те, як покращити наш сервіс. Ваш відгук направляється безпосередньо нашому директору.',
        negativeFooterTitle: 'Як нам покращити свій сервіс?',
        negativeFooterPlaceholder: 'Залиште своє зауваження чи побажання тут',
        negativeFooterButton: 'Залишити відгук',
        confirmationTitle: 'Дякуємо за ваш відгук!',
        confirmationMessage: 'Ми ознайомимося з ним якомога швидше і приймемо необхідні заходи.',
        iAgreeTo: 'Я погоджуюся з',
        pp: 'Політикою конфіденційності',
        name: "Ім'я",
        phone: 'Телефон',
        email: 'Email',
        reviewHelpful: 'Ваш відгук нам дуже допоможе',
        redirecting: 'Переводимо на нашу сторінку {{0}}',
        reviewHelpfulInstagram: 'Дякуємо за високу оцінку!',
        redirectingInstagram: 'Підпишіться на нас в Instagram - дізнавайтеся першими про акції та знижки',
        leaveReview: 'Залишити відгук',
        somethingWrong: 'Щось пішло не так',
    },
    PL: {
        headerTitle: 'Dziękujemy\n za wybranie nas!',
        'headerTitle[ly]': 'Dziękujemy\n za wybranie\n Lyca Mobile!',
        footerTitle: 'Jak prawdopodobne jest, że polecisz nas innym?',
        footerButton: 'NASTĘPNY KROK',
        positiveTitle: 'Doceniamy Twoją pozytywną odpowiedź',
        positiveMessage: 'Jeśli masz minutę, Twoja publiczna recenzja bardzo nam pomoże.',
        positiveFooterButton: 'Zostaw recenzję',
        negativeTitle: 'Twoja opinia ma znaczenie!',
        negativeMessage:
            'Poświęć minutę, aby podzielić się swoimi przemyśleniami na temat tego, jak ulepszyć nasze usługi. Twoja opinia trafia prosto do naszego dyrektora.',
        negativeFooterTitle: 'What could we do better?',
        negativeFooterPlaceholder: 'Zostaw swój komentarz lub poradę tutaj',
        negativeFooterButton: 'Zostaw moją opinię',
        confirmationTitle: 'Dziękujemy za Twoją opinię!',
        confirmationMessage: 'Zapoznamy się z nią jak najszybciej i podejmiemy odpowiednie działania.',
        iAgreeTo: 'Zgadzam się z',
        pp: 'Polityką prywatności',
        name: 'Imię',
        phone: 'Numer telefonu',
        email: 'Email',
        reviewHelpful: 'Twoja opinia się liczy - podziel się nią teraz',
        redirecting: 'Przekierowanie do naszej strony {{0}}',
        reviewHelpfulInstagram: 'Doceniamy Twoją pozytywną odpowiedź',
        redirectingInstagram: 'Śledź nas na Instagramie - bądź pierwszym, który dowie się o promocjach i rabatach',
        leaveReview: 'Zostaw recenzję',
        somethingWrong: 'Coś poszło nie tak',
    },
    DE: {
        headerTitle: 'Danke, dass Sie uns gewählt haben!',
        'headerTitle[ly]': 'Vielen Dank, dass Sie sich für Lyca Mobile entschieden haben!',
        footerTitle: 'Wie wahrscheinlich ist es, dass Sie uns weiterempfehlen?',
        footerButton: 'NÄCHSTER SCHRITT',
        positiveTitle: 'Wir freuen uns über Ihre positive Antwort!',
        positiveMessage: 'Wenn Sie eine Minute haben, würde uns Ihre öffentliche Bewertung sehr helfen.',
        positiveFooterButton: 'HINTERLASSEN SIE EINE BEWERTUNG',
        negativeTitle: 'Deine Meinung zählt!',
        negativeMessage:
            'Nehmen Sie sich eine Minute Zeit, um uns Ihre Gedanken darüber mitzuteilen, wie Sie unseren Service verbessern können. Ihr Feedback geht direkt an unseren Direktor.',
        negativeFooterTitle: 'Was könnten wir besser machen?',
        negativeFooterPlaceholder: 'Hinterlassen Sie hier Ihre Nachricht',
        negativeFooterButton: 'HINTERLASSE FEEDBACK',
        confirmationTitle: 'Danke für deine Rückmeldung!',
        confirmationMessage: 'Wir werden es so schnell wie möglich prüfen und Maßnahmen ergreifen.',
        iAgreeTo: 'Ich stimme der',
        pp: 'Datenschutzrichtlinie zu',
        name: 'Name',
        phone: 'Handynummer',
        email: 'Email',
        reviewHelpful: 'Ihre Bewertung würde uns sehr helfen',
        redirecting: 'Sie werden auf unsere {{0}}-Seite weitergeleitet',
        reviewHelpfulInstagram: 'Wir freuen uns über Ihre positive Antwort',
        redirectingInstagram: 'Folge uns auf Instagram – erfahre als Erster von Aktionen und Rabatten',
        leaveReview: 'Hinterlassen Sie eine Bewertung',
        somethingWrong: 'Etwas ist schief gelaufen',
    },
};
