import { styled } from '@mui/material/styles';
import TimeframePicker from '@components/TimeframePicker';
import { MultiSelect } from '@components/MultiSelect';
import { MultiSelectLabels } from '@components/MultiSelectLabels';
import { Select } from '@components/Select';
import { Switch } from '@components/Switch';

const Form = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
    gridGap: '16px',
    '@media (max-width: 1024px)': {
        gridTemplateColumns: '100%',
    },
});

const StyledTimeframePicker = styled(TimeframePicker)({
    '& .react-datepicker-wrapper, .react-datepicker__input-container': {
        maxWidth: 'none',
    },
});

export const FiltersFormCreator = ({ options, type }) => {
    return (
        <Form id={`pl-filters-${type}`}>
            {options.map((option, index) => {
                switch (option.type) {
                    case 'multiSelect':
                        return (
                            <MultiSelect
                                key={index}
                                label={option.label}
                                value={option.value}
                                onChange={option.onChange}
                                options={option.options}
                                countable={option.countable}
                                nameBased={option.nameBased}
                                style={option.style}
                                error={option.error}
                            />
                        );
                    case 'multiSelectLabels':
                        return (
                            <MultiSelectLabels
                                key={index}
                                label={option.label}
                                value={option.value}
                                onChange={option.onChange}
                                options={option.options}
                                fullWidth
                                editable={false}
                                style={option.style}
                            />
                        );
                    case 'select':
                        return (
                            <Select
                                key={index}
                                label={option.label}
                                value={option.value}
                                onChange={option.onChange}
                                options={option.options}
                            />
                        );
                    case 'timeframe':
                        return (
                            <StyledTimeframePicker
                                key={index}
                                label={option.label}
                                value={option.value}
                                onChangeTimeframe={option.onChange}
                            />
                        );
                    case 'switch':
                        return (
                            <Switch
                                key={index}
                                label={option.label}
                                checked={option.value}
                                handleChange={option.onChange}
                            />
                        );
                    default:
                        return null;
                }
            })}
        </Form>
    );
};
