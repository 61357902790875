import { useState, useEffect, memo, useRef } from 'react';
import { Typography } from '@components/Typography';
import InfoSnackbar from '@components/InfoSnackbar';
import { useTranslation } from '@hooks/useTranslation';
import { Trans } from 'react-i18next';
import { useWebPushNotifications } from '@hooks/useWebPushNotifications';
import { PERMISSION } from '@constants/browser';

const ServiceWorkerUpdatesSnackbar = memo(() => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const { isSupported, permission, isSubscribed } = useWebPushNotifications();
    const refreshingRef = useRef(false);

    useEffect(() => {
        const checkAndUpdateServiceWorker = async () => {
            const registration = await navigator.serviceWorker.register('/webpush-sw.js');
            if (registration.waiting) {
                setIsOpen(true);
            }
            registration.addEventListener('updatefound', () => {
                if (registration.installing) {
                    // wait until the new Service worker is actually installed (ready to take over)
                    registration.installing.addEventListener('statechange', () => {
                        if (registration.waiting) {
                            // if there's an existing controller (previous Service Worker), show the prompt
                            if (navigator.serviceWorker.controller) {
                                setIsOpen(true);
                            } else {
                                // otherwise it's the first install, nothing to do
                                // eslint-disable-next-line no-console
                                console.log('Service Worker initialized for the first time');
                            }
                        }
                    });
                }
            });
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (!refreshingRef.current) {
                    window.location.reload();
                    refreshingRef.current = true;
                }
            });
        };

        const hideSWUpdates = JSON.parse(localStorage.getItem('hideSWUpdates'));
        if (isSupported && permission === PERMISSION.GRANTED && isSubscribed && !hideSWUpdates) {
            checkAndUpdateServiceWorker();
        }
    }, [isSupported, permission, isSubscribed]);

    const handleClose = (_, reason) => {
        if (reason === 'clickaway') return;
        setIsOpen(false);
        // if true - don't show snackbar again
        localStorage.setItem('hideSWUpdates', true);
    };

    const onUpdate = async () => {
        const registration = await navigator.serviceWorker.register('/webpush-sw.js');
        if (registration.waiting) {
            // let waiting Service Worker know it should became active
            registration.waiting.postMessage('SKIP_WAITING');
        }
        setIsOpen(false);
    };

    return (
        <InfoSnackbar
            isOpen={isOpen}
            handleClose={handleClose}
            onClick={onUpdate}
            message={
                <Typography variant="body2" sx={{ color: '#416774', textAlign: 'center' }}>
                    <Trans t={t} i18nKey="SettingsProfile.serviceWorkerUpdateSnackbar">
                        <b
                            style={{ textDecoration: 'underline', cursor: 'pointer', textTransform: 'uppercase' }}
                            onClick={onUpdate}
                        >
                            {{ refresh: t('common.refresh') }}
                        </b>
                    </Trans>
                </Typography>
            }
        />
    );
});

ServiceWorkerUpdatesSnackbar.displayName = 'ServiceWorkerUpdatesSnackbar';

export default ServiceWorkerUpdatesSnackbar;
