import { useEffect } from 'react';
import { styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from '@hooks/useTranslation';
import { alertActions } from '@actions';

const Container = styled('div')({
    '& .gmnoprint': {
        display: 'block !important',
    },
});

const GoogleMap = ({ id, options, onMapLoad, style, onChange }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const loadMap = async () => {
        try {
            const { Map } = await window.google.maps.importLibrary('maps');
            const { AdvancedMarkerElement } = await window.google.maps.importLibrary('marker');

            const map = new Map(document.getElementById(id), {
                ...options,
                mapId: id,
                disableDefaultUI: true,
                zoomControl: true,
                zoomControlOptions: {
                    style: window.google.maps.ZoomControlStyle.SMALL,
                    position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
                },
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            });
            const marker = new AdvancedMarkerElement({
                map,
                position: options.center,
                gmpDraggable: true,
            });

            const infoWindow = new window.google.maps.InfoWindow();
            marker.addListener('dragend', event => {
                const position = event.latLng;
                const latitude = position.lat();
                const longitude = position.lng();
                const latitudeShort = Number(latitude).toFixed(6);
                const longitudeShort = Number(longitude).toFixed(6);

                onChange({ latitude, longitude });

                infoWindow.close();
                infoWindow.setContent(`Pin dropped at: ${latitudeShort}, ${longitudeShort}`);
                infoWindow.open(marker.map, marker);
            });

            onMapLoad(map, marker);
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
    };

    useEffect(() => {
        loadMap();
    }, []);

    return <Container id={id} sx={style} />;
};

export default GoogleMap;
