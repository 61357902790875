export const baseStyles = `
    .phoneNumber {
        border-radius: 4px;
        & .special-label {
            font-family: Rubik,sans-serif;
            line-height: 14px;
            font-size: 12px;
            letter-spacing: 0.38px;
            color: rgba(0, 0, 0, 0.6);
            top: -7px;
            left: 10px;
            & p {
                margin: 0;
            }
        }
        &:focus-within .special-label {
            color: #1F4C5C;
        }
        & .form-control {
            border-radius: 4px;
            outline: none;
            padding: 12px 12px 12px 54px;
            font-size: 14px;
            background-color: inherit;
            border: 1px solid rgba(0, 0, 0, 0.12);
            height: 40px;
            font-family: Rubik,sans-serif;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.87);
            width: 100%;
        }
        & .form-control:hover {
            border: 1px solid #1F4C5C;
        }
        & .form-control:focus {
            border: 2px solid #1F4C5C;
            box-shadow: none;
            padding-left: 53px;
        }
        & .country-list {
            margin: 3px 0 0px 2px;
            border-radius: 4px;
            max-height: 180px;
            width: max-content;
        }
        & .country-list .country {
            display: flex;
            padding: 8px 8px 8px 46px;
            font-family: Rubik,sans-serif;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
        }
        & .country-list .flag {
            margin-top: 0;
            top: 3px;
            left: 12px;
        }
        & .selected-flag {
            padding: 0px 0 0 12px;
        }
        & .selected-flag:focus .arrow {
            border-top: 4px solid #555;
        }
        @media (max-width: 350px) {
            & .country-list {
                width: 284px;
            }
        }
    }
    .phoneError {
        & .special-label {
            color: #B00020;
        }
        &:focus-within .special-label {
            color: #B00020;
        }
        & .form-control {
            border: 1px solid #B00020;
        }
        & .form-control:hover {
            border: 1px solid #B00020;
        }
        & .form-control:focus {
            border: 2px solid #B00020;
        }
    }
`;
