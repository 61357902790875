export const accountConstants = {
    ACCOUNT_SUCCESS: 'ACCOUNT_SUCCESS',
    ACCOUNT_ERROR: 'ACCOUNT_ERROR',
    ACCOUNT_REQUEST: 'ACCOUNT_REQUEST',
    ACCOUNT_UPDATE_QUOTA: 'ACCOUNT_UPDATE_QUOTA',
    CLEAR: 'ALERT_CLEAR',
    BILLING_EXPIRED: 0, // TODO come up with a new approach
    DASHBOARD_ONBOARDING_SUCCESS: 'DASHBOARD_ONBOARDING_SUCCESS',
    LOCATIONS_FETCH: 'LOCATIONS_FETCH',
    LOCATIONS_SUCCESS: 'LOCATIONS_SUCCESS',
    LOCATIONS_ERROR: 'LOCATIONS_ERROR',
};

export const Industry = {
    Automotive: 1,
    BeautySpaAndSalon: 2,
    ClothingAndApparel: 3,
    Education: 4,
    Entertainment: 5,
    EventPlanningAndService: 6,
    FinanceAndBanking: 7,
    FoodAndGrocery: 8,
    Pharmacy: 9,
    PublicService: 10,
    HotelAndLodging: 11,
    HealthcareAndDental: 12,
    ProfessionalServices: 13,
    ShoppingAndRetail: 14,
    TravelAndTransportation: 15,
    Restaurant: 16,
    Veterinary: 17,
    WellnessAndFitness: 18,
    Other: 0,
};
