import { memo, useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { analyticsActions } from '@actions';
import DoughnutRates from '../Common/DoughnutRates';
import SentimentTrend from '../Common/SentimentTrend';
import { PrintButton } from '../Common/PrintButton';
import FiltersPanel from './FiltersPanel';
import ReviewsChannelTrend from './ReviewsChannelTrend';
import ReviewsTable from './ReviewsTable';
import { FiltersIntegrationType } from '@constants/integrations';
import { GroupByFilter } from '@constants/analytics';
import { subtractYears } from '@helpers/dates';
import { getLocationLabels } from '@helpers/locationLabels';
import { useTranslation } from '@hooks/useTranslation';
import { AnalyticsContainer, HeaderWrapper } from '../styles';

const Reviews = memo(() => {
    const { t } = useTranslation();
    const firstRender = useRef(true);

    const [filter, setFilter] = useState({
        labels: [],
        companies: [],
        integrations: [],
        timeframe: {
            start: subtractYears(new Date(), 1),
            end: new Date(),
        },
        grouping: GroupByFilter.Months,
    });
    const [filterNumber, setFilterNumber] = useState(0);
    const [filterOpened, setFiltersOpened] = useState(false);

    const companies = useSelector(state => state.account?.account?.companies || []);
    const { location: labels, locationLoading: labelsLoading } = useSelector(state => state.labels);
    const language = useSelector(state => state.authentication.user?.language, shallowEqual);
    const { reviewsData, reviewsLoading } = useSelector(state => state.analytics, shallowEqual);
    const dispatch = useDispatch();

    const availableCompanies = useMemo(
        () =>
            companies.filter(
                company =>
                    company?.profiles?.length > 0 && company?.profiles.some(p => p.type in FiltersIntegrationType),
            ),
        [companies],
    );

    const availableLabels = useMemo(
        () => getLocationLabels(labels, availableCompanies, t),
        [labels, availableCompanies],
    );

    useEffect(() => {
        if (companies.length && !labelsLoading) {
            const integrations = [
                ...new Set(
                    companies
                        .map(company => company.profiles)
                        .flat()
                        .filter(p => p.type in FiltersIntegrationType)
                        .map(p => p.type),
                ),
            ];
            setFilter(prev => ({
                ...prev,
                integrations,
                companies: availableCompanies.map(item => item.companyId),
                labels: availableLabels.map(item => item.id),
            }));
        }
    }, [companies, labelsLoading, dispatch]);

    const onApplyFilters = () => {
        dispatch(
            analyticsActions.getAnalyticsReviews({
                companies: filter.companies,
                profileTypes: filter.integrations,
                from: filter.timeframe.start ? format(new Date(filter.timeframe.start), 'yyyy-MM-dd') : null,
                to: filter.timeframe.end ? format(new Date(filter.timeframe.end), 'yyyy-MM-dd') : null,
                grouping: filter.grouping,
            }),
        );
    };

    useEffect(() => {
        if (filter.companies.length && filter.integrations.length) {
            if (firstRender.current) {
                onApplyFilters();
                firstRender.current = false;
            }
        }
    }, [filter, dispatch]);

    return (
        <>
            <HeaderWrapper>
                <FiltersPanel
                    setFiltersOpened={setFiltersOpened}
                    filtersNumber={filterNumber}
                    filterOpened={filterOpened}
                    companies={availableCompanies}
                    labels={availableLabels}
                    filter={filter}
                    setFilter={setFilter}
                    type="reviews"
                    setFilterNumber={setFilterNumber}
                    onApplyFilters={onApplyFilters}
                />
                <PrintButton />
            </HeaderWrapper>
            <AnalyticsContainer id="pl-content-analytics-reviews">
                <DoughnutRates data={reviewsData.overall} type="reviews" isLoading={reviewsLoading} />
                <SentimentTrend data={reviewsData.sentimentData} language={language} type="reviews" />
                <ReviewsChannelTrend data={reviewsData.channelData} language={language} />
                <ReviewsTable data={reviewsData.tableData} isLoading={reviewsLoading} />
            </AnalyticsContainer>
        </>
    );
});

Reviews.displayName = 'Reviews';

export default Reviews;
