import { styled } from '@mui/material/styles';
import { Table } from '@components/Table';
import { Typography } from '@components/Typography';

export const Container = styled('div')({
    maxWidth: 696,
    '@media (max-width: 1024px)': {
        maxWidth: 'none',
    },
});

export const StyledTable = styled(Table)({
    '& .MuiTableBody-root .MuiTableCell-root:last-child': {
        width: 133,
        textAlign: 'center',
    },
    '@media (max-width: 600px)': {
        '& .MuiTableBody-root .MuiTableCell-root:last-child': {
            width: 'auto',
        },
    },
});

export const TableText = styled(Typography)({
    '@media (max-width: 600px)': {
        fontSize: '14px !important',
    },
});

export const Point = styled('span', {
    shouldForwardProp: prop => prop !== 'type',
})(({ type }) => ({
    width: 8,
    height: 8,
    display: 'inline-block',
    marginRight: 8,
    borderRadius: '50%',
    backgroundColor: '#F2F2F2',
    ...(type === 'green' && { backgroundColor: '#4CC474' }),
    ...(type === 'red' && { backgroundColor: '#FF5853' }),
}));
