import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@components/Drawer';
import { billingService } from '@services/billing';
import { alertActions, pricingDrawerUiActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';
import BillingCard from './BillingCard';

const BillingDrawerCustom = () => {
    const { t } = useTranslation();
    const [selectedSubscription, setSubscription] = useState(null);
    const [customerPortalIsLoading, setCustomerPortalIsLoading] = useState(false);

    const subscriptions = useSelector(state => state.account?.account?.subscriptions || []);
    const { customIsOpen } = useSelector(state => state.pricingDrawerUi);
    const dispatch = useDispatch();

    useEffect(() => {
        if (subscriptions.length) {
            setSubscription('0');
        }
    }, [subscriptions]);

    const onSubmit = async () => {
        setCustomerPortalIsLoading(true);
        try {
            const data = await billingService.createCustomerPortal({
                subscriptionId: subscriptions[selectedSubscription].id || null,
            });
            if (data.paymentUrl) {
                window.location = data.paymentUrl;
            }
        } catch (_) {
            dispatch(alertActions.error(t('apiErrors.something_wrong')));
        }
        setCustomerPortalIsLoading(false);
    };

    const onClose = () => dispatch(pricingDrawerUiActions.setOpenCustom(false));

    return (
        <Drawer
            anchor="right"
            open={customIsOpen}
            onClose={onClose}
            title={'Manage plan'}
            primaryText={t('buttons.continue')}
            primaryDisabled={customerPortalIsLoading}
            primaryAction={onSubmit}
            secondaryText={t('buttons.cancel')}
        >
            <div style={{ display: 'grid', gridGap: '8px' }}>
                {subscriptions.length > 0 &&
                    subscriptions.map((item, index) => (
                        <BillingCard
                            key={item.id}
                            subscription={item}
                            selectedSubscription={selectedSubscription}
                            setSubscription={setSubscription}
                            index={index}
                        />
                    ))}
            </div>
        </Drawer>
    );
};

export default BillingDrawerCustom;
