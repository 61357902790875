import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import { ModalConfirm } from '@components/Modal';
import Button from '@components/Button';
import { Input } from '@components/Input';
import ContactSendMessage from '@features/ContactSendMessage';
import { ImportIcon } from 'assets/images/icons';
import { useTranslation } from '@hooks/useTranslation';
import { useThrottleValue } from '@hooks/useThrottleValue';
import { contactsActions, alertActions, channelsActions, labelsActions } from '@actions';
import { contactsService } from '@services';
import { MODAL_TYPE, CONTACT_MODAL_TYPE } from '@constants/modal';
import { LABEL_TYPE } from '@constants/labels';
import { findDuplicate } from '@helpers/bulk-contacts-upload';
import { getLocationLabels } from '@helpers/locationLabels';
import { SendIcon } from 'assets/images/icons';
import FiltersPanel from './FiltersPanel';
import ContactInfo from './ContactInfo';
import ContactsTable from './ContactsTable';
import BulkUploadModal from './BulkUploadModal';
import BulkEditModal from './BulkEditModal';
import { Container, FiltersWrapper, ToolbarWrapper, SearchWrapper } from './styles';

const ContactsPage = () => {
    const { t } = useTranslation();
    const firstRender = useRef(true);
    const isTablet = useMediaQuery('@media (max-width: 1024px)');
    const isMobile = useMediaQuery('@media (max-width: 768px)');

    const [filter, setFilter] = useState({
        labels: [],
        companies: [],
        timeframe: { start: null, end: null },
        contactLabels: [],
        searchQuery: '',
    });
    const [filterNumber, setFilterNumber] = useState(0);
    const [filterOpened, setFiltersOpened] = useState(false);
    const [pageRequests, setPageRequests] = useState(0);
    const [rowsPerPageRequests, setRowsPerPageRequests] = useState(20);

    const [selectedContacts, setSelectedContacts] = useState({ list: {}, all: false });
    const [deleteModal, setDeleteModal] = useState({ open: false, type: CONTACT_MODAL_TYPE.DIRECT });
    const [editModal, setEditModal] = useState({ open: false, type: MODAL_TYPE.CREATE });
    const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);
    const [sendMessageModal, setSendMessageModal] = useState({ open: false, type: CONTACT_MODAL_TYPE.DIRECT });
    const [isBulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
    const [contactDrawerOpened, setContactDrawerOpened] = useState(false);

    const language = useSelector(state => state.authentication.user?.language);
    const companies = useSelector(state => state.account?.account?.companies || []);
    const { totalCount } = useSelector(state => state.contacts);
    const {
        contact: contactLabels,
        location: labels,
        contactLoading: contactLabelsLoading,
        locationLoading: labelsLoading,
    } = useSelector(state => state.labels);

    const dispatch = useDispatch();
    const throttledSearch = useThrottleValue(filter.searchQuery, 1000);

    useEffect(() => {
        dispatch(labelsActions.get(LABEL_TYPE.CONTACT));
        dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
        dispatch(channelsActions.get());
    }, [dispatch]);

    useEffect(() => {
        return () => dispatch(contactsActions.setContact({}));
    }, [dispatch]);

    const availableLabels = useMemo(() => getLocationLabels(labels, companies, t), [labels, companies]);

    useEffect(() => {
        if (companies.length && !contactLabelsLoading && !labelsLoading) {
            setFilter(prev => ({
                ...prev,
                companies: companies.map(item => item.companyId),
                labels: availableLabels.map(l => l.id),
            }));
        }
    }, [companies, availableLabels, contactLabelsLoading, labelsLoading, dispatch]);

    const currentQuery = useMemo(() => {
        return {
            companies: filter.companies,
            labels: filter.contactLabels,
            searchQuery: filter.searchQuery,
            startDate: filter.timeframe.start ? format(new Date(filter.timeframe.start), 'dd/MMM/yyyy') : null,
            endDate: filter.timeframe.end ? format(new Date(filter.timeframe.end), 'dd/MMM/yyyy') : null,
        };
    }, [filter]);

    useEffect(() => {
        if (currentQuery.companies.length) {
            if (firstRender.current) {
                dispatch(contactsActions.get(currentQuery, pageRequests, rowsPerPageRequests));
                firstRender.current = false;
            }
        }
    }, [pageRequests, currentQuery, rowsPerPageRequests, dispatch]);

    useEffect(() => {
        if (!firstRender.current) {
            dispatch(contactsActions.get(currentQuery, pageRequests, rowsPerPageRequests));
        }
    }, [throttledSearch]);

    const onPageChange = (_, newPage) => {
        setPageRequests(newPage);
        dispatch(contactsActions.get(currentQuery, newPage, rowsPerPageRequests));
    };

    const onRowsPerPageChange = event => {
        setRowsPerPageRequests(parseInt(event.target.value, 10));
        setPageRequests(0);
        dispatch(contactsActions.get(currentQuery, 0, parseInt(event.target.value, 10)));
    };

    const onCloseContactDrawer = () => setContactDrawerOpened(false);

    const importContactsBulk = async (contacts, duplicates) => {
        try {
            let contactsUpdated = [];

            contactsUpdated = contacts.map(contact => {
                const duplicate = findDuplicate(contact, duplicates);
                if (duplicate) {
                    Object.entries(contact).forEach(([key]) => {
                        if (!contact[key] && duplicate[key]) {
                            contact[key] = duplicate[key];
                        }
                    });
                    return {
                        ...contact,
                        id: duplicate.id,
                        labels: duplicate.labels,
                        companies: duplicate.companies,
                    };
                }
                return contact;
            });
            await contactsService.importContactsBulk(contactsUpdated);
            await dispatch(contactsActions.get(currentQuery, pageRequests, rowsPerPageRequests));
            dispatch(alertActions.success(t('alertMessages.importSuccess')));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.importFail')));
            }
        }
        setBulkUploadModalOpen(false);
    };
    const onApplyFilters = () => {
        dispatch(contactsActions.get(currentQuery, 0, rowsPerPageRequests));
        setPageRequests(0);
    };

    const onClickContact = (contact, type) => {
        dispatch(contactsActions.setContact(contact));
        switch (type) {
            case 'delete':
                setDeleteModal({ open: true, type: CONTACT_MODAL_TYPE.DIRECT });
                break;
            case 'edit':
                setEditModal({ open: true, type: MODAL_TYPE.EDIT });
                break;
            case 'view':
                setContactDrawerOpened(true);
                break;
            case 'sendMessage':
                setSendMessageModal({ open: true, type: CONTACT_MODAL_TYPE.DIRECT });
                break;
            default:
                break;
        }
    };

    const getDuplicates = async contacts => {
        try {
            const data = await contactsService.getDuplicates(contacts);
            return data;
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
    };

    const onExportContacts = async () => {
        const exportQuery = {
            ...currentQuery,
            contacts: Object.keys(selectedContacts.list),
            allContacts: selectedContacts.all,
        };
        try {
            const file = await contactsService.exportCSVBulk(exportQuery);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = file;
            anchor.download = 'contacts.csv';
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(file);
            anchor.remove();
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('common.exportError')));
            }
        }
        setSelectedContacts(prev => ({ ...prev, list: {}, all: false }));
    };

    const onDeleteContacts = async () => {
        const deleteQuery = {
            ...currentQuery,
            contacts: Object.keys(selectedContacts.list),
            allContacts: selectedContacts.all,
        };
        try {
            await contactsService.deleteBulkContacts(deleteQuery);
            await dispatch(contactsActions.get(currentQuery, pageRequests, rowsPerPageRequests));
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.deleteFail')));
            }
        }
        setEditModal({ open: false, type: MODAL_TYPE.CREATE });
        setDeleteModal(prev => ({ ...prev, open: false }));
        setSelectedContacts(prev => ({ ...prev, list: {}, all: false }));
    };

    const refetchCallBack = () => {
        const page = editModal.type === MODAL_TYPE.CREATE ? 0 : pageRequests;
        dispatch(contactsActions.get(currentQuery, page, rowsPerPageRequests));
    };

    const selectedLength = Object.keys(selectedContacts.list).length;

    return (
        <Container>
            <FiltersWrapper>
                <SearchWrapper>
                    <Input
                        inputProps={{
                            value: filter.searchQuery,
                            onChange: e => setFilter(prev => ({ ...prev, searchQuery: e.target.value })),
                        }}
                        label={t(isTablet ? 'ContactsPage.search' : 'ContactsPage.searchLabel')}
                        startAdornment={<SearchIcon />}
                    />
                </SearchWrapper>
                <FiltersPanel
                    type="contacts"
                    setFiltersOpened={setFiltersOpened}
                    filtersNumber={filterNumber}
                    filterOpened={filterOpened}
                    onApplyFilters={onApplyFilters}
                    companies={companies}
                    labels={availableLabels}
                    contactLabels={contactLabels}
                    filter={filter}
                    setFilter={setFilter}
                    setFilterNumber={setFilterNumber}
                    withChips={false}
                />
                {!!selectedLength && (
                    <ToolbarWrapper>
                        <Button
                            variant="text"
                            startIcon={<SendIcon />}
                            onClick={() => setSendMessageModal({ open: true, type: CONTACT_MODAL_TYPE.BULK })}
                        >
                            {t('buttons.sendMessage')}
                        </Button>
                        <Button
                            sx={{ minWidth: 'min-content' }}
                            variant="text"
                            startIcon={<EditIcon />}
                            onClick={() => setIsBulkEditModalOpen(true)}
                        >
                            {!isMobile ? t('buttons.edit') : ''}
                        </Button>
                        <Button
                            sx={{ minWidth: 'min-content' }}
                            variant="text"
                            startIcon={<FileDownloadIcon sx={{ fontSize: '20px', transform: 'translateY(1px)' }} />}
                            onClick={onExportContacts}
                        >
                            {!isMobile ? t('buttons.export') : ''}
                        </Button>
                        <Button
                            sx={{ minWidth: 'min-content' }}
                            variant="text"
                            startIcon={<DeleteIcon />}
                            onClick={() => setDeleteModal({ open: true, type: CONTACT_MODAL_TYPE.BULK })}
                        >
                            {!isMobile ? t('buttons.delete') : ''}
                        </Button>
                    </ToolbarWrapper>
                )}
                <div style={{ gridArea: 'controls', display: 'flex', gap: 8, justifyContent: 'flex-end' }}>
                    {!isMobile ? (
                        <Button
                            variant="outlined"
                            startIcon={<ImportIcon />}
                            onClick={() => setBulkUploadModalOpen(true)}
                        >
                            {t('buttons.import')}
                        </Button>
                    ) : null}
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setEditModal({ open: true, type: MODAL_TYPE.CREATE })}
                    >
                        {t('buttons.create')}
                    </Button>
                </div>
            </FiltersWrapper>
            <ContactsTable
                companies={companies}
                onClickContact={onClickContact}
                rowsPerPageRequests={rowsPerPageRequests}
                pageRequests={pageRequests}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
            />
            <ContactInfo
                onClickContact={onClickContact}
                contactDrawerOpened={contactDrawerOpened}
                onCloseContactDrawer={onCloseContactDrawer}
                editModal={editModal}
                setEditModal={setEditModal}
                sendMessageModal={sendMessageModal}
                setSendMessageModal={setSendMessageModal}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                withRefetchContacts
                refetchCallBack={refetchCallBack}
            />
            {isBulkEditModalOpen ? (
                <BulkEditModal
                    modal={isBulkEditModalOpen}
                    setModal={setIsBulkEditModalOpen}
                    companies={companies}
                    selectedContacts={selectedContacts}
                    currentQuery={currentQuery}
                    rowsPerPageRequests={rowsPerPageRequests}
                />
            ) : null}
            {isBulkUploadModalOpen ? (
                <BulkUploadModal
                    isOpen={isBulkUploadModalOpen}
                    setModal={setBulkUploadModalOpen}
                    locale={language}
                    companies={companies}
                    getDuplicates={getDuplicates}
                    importContactsBulk={importContactsBulk}
                />
            ) : null}
            {sendMessageModal.open ? (
                <ContactSendMessage
                    modal={sendMessageModal}
                    setModal={setSendMessageModal}
                    selectedContacts={selectedContacts}
                    setSelectedContacts={setSelectedContacts}
                    currentQuery={currentQuery}
                />
            ) : null}
            {deleteModal.open && deleteModal.type === CONTACT_MODAL_TYPE.BULK && (
                <ModalConfirm
                    isOpen={deleteModal.open}
                    handleClose={() => setDeleteModal(prev => ({ ...prev, open: false }))}
                    title={t('common.deleteTitle')}
                    description={t('ContactsPage.deleteContactsDescription', {
                        selectedContacts: selectedContacts.all ? totalCount : selectedLength,
                    })}
                    onPrimaryAction={onDeleteContacts}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                    onSecondaryAction={() => setDeleteModal(prev => ({ ...prev, open: false }))}
                />
            )}
        </Container>
    );
};

export default ContactsPage;
