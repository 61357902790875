import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { alertActions } from '@actions';
import Alert from './Alert';

const Toaster = () => {
    const [open, setOpen] = useState(false);
    const alerts = useSelector(state => state.alert.data);
    const dispatch = useDispatch();

    useEffect(() => {
        setOpen(!!alerts.length);
    }, [alerts]);

    useEffect(() => {
        if (alerts.length) {
            const timeout = setTimeout(() => {
                if (alerts.length) {
                    dispatch(alertActions.clear(alerts[0]?.id));
                }
            }, 6000);

            return () => clearTimeout(timeout);
        }
    }, [alerts]);

    const handleClose = (reason, id) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        dispatch(alertActions.clear(id));
    };

    return (
        <Snackbar style={{ zIndex: 99999 }} open={open}>
            <div style={{ display: 'grid', gridGap: '8px' }}>
                {alerts.map(alert => (
                    <Alert
                        key={alert.id}
                        severity={alert?.type || 'success'}
                        onClose={(_, reason) => handleClose(reason, alert.id)}
                    >
                        {alert?.message}
                    </Alert>
                ))}
            </div>
        </Snackbar>
    );
};

export default Toaster;
