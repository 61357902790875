import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MuiTextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useTranslation } from '@hooks/useTranslation';

export const CssTextFieldOutlined = styled(MuiTextField)({
    '&.MuiFormControl-root': {
        fontFamily: 'Rubik, sans-serif',
        fontSize: '0.875rem',
        paddingBottom: 16,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: 4,
            borderColor: 'rgba(0, 0, 0, 0.12)',
            legend: {
                letterSpacing: '0.6px',
            },
        },
        '& .Mui-disabled+fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
        },
        '& input': {
            padding: '12px',
            height: '1rem',
            lineHeight: '16px',
            fontSize: '14px',
            fontFamily: 'Rubik, sans-serif',
            borderColor: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.87)',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#1F4C5C',
        },
        '& .MuiSelect-icon': {
            color: 'rgba(0, 0, 0, 0.88)',
        },
        '& .MuiSelect-icon.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.38)',
        },
        '& .MuiSelect-select': {
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 12,
            boxSizing: 'border-box',
            fontSize: '0.875rem',
            fontFamily: 'Rubik, sans-serif',
            lineHeight: '16px',
        },
        '&.Mui-error': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#B00020',
            },
        },
    },
    '.MuiFormLabel-root': {
        fontSize: '0.875rem',
        fontFamily: 'Rubik, sans-serif',
        lineHeight: '1rem',
        letterSpacing: '0.5px',
        transform: 'translate(12px, 12px) scale(1)',
        '&.Mui-focused': {
            color: '#1F4C5C',
        },
        '&.Mui-error': {
            color: '#B00020',
        },
        '& .MuiFormLabel-asterisk': {
            color: '#B00020',
        },
    },
    '.MuiFormHelperText-root': {
        position: 'absolute',
        bottom: -3,
        left: 0,
        margin: '0 12px',
        fontFamily: 'Rubik, sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '0.4px',
        '&.Mui-error': {
            color: '#B00020',
        },
    },
    '.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.8)',
    },
});

export const CssMenuItem = styled(MenuItem)({
    '&.MuiMenuItem-root': {
        padding: '8px 18px',
        width: '100%',
        color: 'rgba(0, 0, 0, 0.88)',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        '@media (max-width: 1024px)': {
            minHeight: '32px',
        },
        '&:hover': {
            cursor: 'pointer',
        },
        '&.Mui-selected': {
            backgroundColor: '#E4EAEB',
        },
    },
});

export const CssNoOptions = styled(MenuItem)({
    '&.MuiMenuItem-root': {
        padding: '8px 18px',
        width: '100%',
        color: 'rgba(0, 0, 0, 0.88)',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        '@media (max-width: 1024px)': {
            minHeight: '32px',
        },
        '&:hover': {
            cursor: 'default',
        },
    },
    pointerEvents: 'none',
    '&:hover': {
        cursor: 'default',
        backgroundColor: 'transparent',
    },
});

export const Select = ({
    className,
    options,
    style,
    dropdownStyles,
    withEndAdornment = false,
    onClickEndAdornment = () => {},
    ...rest
}) => {
    const { t } = useTranslation();

    return (
        <Box noValidate autoComplete="off" className={className} style={style}>
            <CssTextFieldOutlined
                select
                fullWidth
                SelectProps={{
                    MenuProps: {
                        style: { zIndex: 9999 },
                        PaperProps: { style: { maxWidth: '280px', ...dropdownStyles } },
                    },
                }}
                InputProps={{
                    endAdornment: withEndAdornment ? (
                        <InputAdornment position="end" sx={{ marginRight: '18px' }}>
                            <IconButton
                                disabled={rest.disabled}
                                sx={{
                                    padding: '2px',
                                    '& svg path': {
                                        fill: rest.disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.6)',
                                    },
                                }}
                                onClick={onClickEndAdornment}
                            >
                                <CloseIcon sx={{ height: '18px', width: '18px' }} />
                            </IconButton>
                        </InputAdornment>
                    ) : null,
                }}
                {...rest}
            >
                {options?.length ? (
                    options.map(option => (
                        <CssMenuItem key={option.value} value={option.value}>
                            {option.label}
                        </CssMenuItem>
                    ))
                ) : (
                    <CssNoOptions>{t('common.noOptions')}</CssNoOptions>
                )}
            </CssTextFieldOutlined>
        </Box>
    );
};
