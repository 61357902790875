import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { useTranslation } from '@hooks/useTranslation';
import MetricItem from './MetricItem';
import NpsChart from './NpsChart';

const CardsWrapper = styled('div')({
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    height: 'calc(100% - 57px)',
    '@media (max-width: 375px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
    },
});

const Surveys = () => {
    const { t } = useTranslation();
    const { surveyResponses } = useSelector(state => state.dashboard.dashboard);
    const language = useSelector(state => state.authentication.user?.language);

    const data = [
        {
            id: 0,
            title: t('AnalyticsConversion.responses'),
            value: surveyResponses.responsesCount,
            subvalue: surveyResponses.responsesCountDelta,
        },
        {
            id: 2,
            title: t('common.rating'),
            value: surveyResponses.averageRating,
            subvalue: surveyResponses.averageRatingDelta,
            withSign: false,
        },
        {
            id: 3,
            title: t('AnalyticsReviews.nps'),
            value: surveyResponses.nps,
            subvalue: surveyResponses.npsDelta,
            withSign: false,
        },
    ];

    return (
        <>
            <CardsWrapper>
                {data.map(item => (
                    <MetricItem
                        key={item.id}
                        value={item.value}
                        subvalue={item.subvalue}
                        title={item.title}
                        withSign={item.withSign}
                    />
                ))}
            </CardsWrapper>
            <NpsChart data={surveyResponses.npsDistribution} language={language} />
        </>
    );
};

export default Surveys;
