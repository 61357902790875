import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@components/Typography';
import { Checkbox } from '@components/Checkbox';

const CardWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'selected' && prop !== 'type' && prop !== 'disabled',
})(({ selected, type, disabled }) => ({
    padding: type === 'radio' ? '4px 16px 16px 16px' : '4px 16px 16px 9px',
    display: 'flex',
    width: '100%',
    border: selected ? '1px solid #1F4C5C' : '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: selected ? '#EBF6F0' : '',
    cursor: 'pointer',
    borderRadius: '4px',
    ...(disabled
        ? {
              pointerEvents: 'none',
              border: '1px solid rgba(0, 0, 0, 0.12)',
          }
        : {}),
}));

const CardRadioControl = styled(RadioGroup)({
    '& .MuiFormControlLabel-root': {
        marginRight: '0 !important',
    },
});

const CardCheckbox = styled(Checkbox)({
    height: '30px',
    padding: '7px',
    marginTop: '5px',
    '& svg': {
        width: '18px',
    },
});

const Card = ({ type, selected, disabled, title, titleStyles = {}, value, index, setValue, children }) => {
    return (
        <CardWrapper
            selected={selected}
            disabled={disabled}
            onClick={() => setValue(type === 'radio' ? `${index}` : index)}
        >
            {type === 'radio' ? (
                <CardRadioControl value={value} onChange={e => setValue(e.target.value)}>
                    <FormControlLabel
                        value={`${index}`}
                        control={<Radio sx={{ color: 'rgba(0, 0, 0, 0.6)', '&.Mui-checked': { color: '#1F4C5C' } }} />}
                        label=""
                    />
                </CardRadioControl>
            ) : (
                <CardCheckbox
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={() => setValue(index)}
                    checked={selected}
                    disabled={location.company}
                />
            )}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                    variant="title1"
                    sx={{
                        color: disabled ? 'rgba(0, 0, 0, 0.38)' : '#1F4C5C',
                        fontSize: '20px !important',
                        marginTop: '7px',
                        marginBottom: '12px',
                        display: 'block',
                        ...titleStyles,
                    }}
                >
                    {title}
                </Typography>
                {children}
            </div>
        </CardWrapper>
    );
};

export default Card;
