import { useFormContext, Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Input } from '@components/Input';
import TimeframePicker from '@components/TimeframePicker';
import { useTranslation } from '@hooks/useTranslation';

const Form = styled('div')({
    maxWidth: 698,
    display: 'grid',
    rowGap: 16,
    gridTemplateColumns: 'minmax(0, 1fr)',
    '@media (max-width: 1024px)': {
        gridTemplateColumns: 'minmax(0, 1fr)',
        maxWidth: 'none',
    },
});

const OpeningDate = styled('div')({
    width: 'calc(50% - 6px)',
    '@media (max-width: 600px)': {
        width: '100%',
    },
});

const AdditionalInfo = () => {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();

    return (
        <Form>
            <Controller
                control={control}
                name="shortDescription"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsCompanies.descriptionShort')}
                        fullWidth
                        multiline
                        minRows={2}
                        maxLength={75}
                        error={!!error}
                        helperText={error ? t(error.message, { count: 75 }) : null}
                    />
                )}
            />
            <Controller
                control={control}
                name="longDescription"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsCompanies.descriptionLong')}
                        fullWidth
                        multiline
                        minRows={4}
                        maxLength={750}
                        error={!!error}
                        helperText={error ? t(error.message, { count: 750 }) : null}
                    />
                )}
            />
            <OpeningDate>
                <Controller
                    control={control}
                    name="openingDate"
                    render={({ field }) => (
                        <TimeframePicker
                            value={[field.value]}
                            range={false}
                            label={t('Listings.openingDate')}
                            onChangeTimeframe={value => {
                                setValue('openingDate', value, { shouldDirty: true });
                            }}
                            minDate={null}
                            maxDate={new Date()}
                        />
                    )}
                />
            </OpeningDate>
        </Form>
    );
};

export default AdditionalInfo;
