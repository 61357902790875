import { brandsConstants } from '@constants/brands';
import { brandsService } from '@services';

const get = (page = 0, pageSize = 10) => {
    return dispatch => {
        dispatch(request());
        brandsService.get(page, pageSize).then(
            data => dispatch(success(data)),
            () => dispatch(failure()),
        );
    };
};

const getAll = () => {
    return dispatch => {
        dispatch(request());
        brandsService.getAll().then(
            data => dispatch(successAll(data)),
            () => dispatch(failure()),
        );
    };
};

function request() {
    return { type: brandsConstants.BRANDS_REQUEST };
}

function success(data) {
    return { type: brandsConstants.BRANDS_SUCCESS, data };
}

function successAll(data) {
    return { type: brandsConstants.BRANDS_ALL_SUCCESS, data };
}

function failure() {
    return { type: brandsConstants.BRANDS_FAILURE };
}

export const brandsActions = {
    get,
    getAll,
};
