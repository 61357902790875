import { styled } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popper from '@mui/material/Popper';
import { Typography } from '@components/Typography';

const StyledPopper = styled(Popper)({
    boxShadow:
        'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
    zIndex: 3,
    background: '#fff',
    padding: '8px 0',
    borderRadius: 8,
    '.MuiMenuItem-root': {
        padding: '8px 16px',
        fontSize: '14px',
        lineHeight: '16px',
        width: '100%',
        justifyContent: 'flex-start',
    },
});

const MoreMenu = ({ className, id, menu, handleOpenEl, handleCloseEl, anchor }) => {
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <IconButton onClick={e => handleOpenEl(e, id)} className={className}>
                    <MoreVertIcon viewBox="0 0 22 22" />
                </IconButton>
            </div>
            {anchor.id === id && (
                <ClickAwayListener onClickAway={handleCloseEl}>
                    <StyledPopper
                        anchorEl={anchor.el}
                        open={!!anchor.el}
                        disablePortal={true}
                        popperOptions={{ placement: 'bottom' }}
                    >
                        {menu.map(item => (
                            <MenuItem key={item.id} sx={{ gap: '8px', minWidth: '150px' }} onClick={item.onClick}>
                                {item.icon}
                                <Typography variant="body2">{item.title}</Typography>
                            </MenuItem>
                        ))}
                    </StyledPopper>
                </ClickAwayListener>
            )}
        </>
    );
};

export default MoreMenu;
