import { cloneElement } from 'react';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
    horizontalListSortingStrategy,
    rectSortingStrategy,
    useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const SortableItem = ({ children, id }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isSorting } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: isSorting ? transition : 'none',
    };

    const childWithProps = cloneElement(children, { listeners, attributes });

    return (
        <div ref={setNodeRef} style={style}>
            {childWithProps}
        </div>
    );
};

/**
    @param {ReactNode} children
    @param {Array} items list of ids
    @param {Function} onDragEnd
    *@param {String} strategy
*/
const DragAndDrop = ({ children, items, data, strategy = 'vertical', onDragEndCb }) => {
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
    );

    const TYPE = {
        ['vertical']: verticalListSortingStrategy,
        ['horizontal']: horizontalListSortingStrategy,
        ['multi']: rectSortingStrategy,
    };

    const reorder = (items, fromIndex, toIndex) => {
        let result;
        if (Array.isArray(items)) {
            result = Array.from(items);
            const [removed] = result.splice(fromIndex, 1);
            result.splice(toIndex, 0, removed);
        } else {
            const entries = Object.entries(items);
            const [removed] = entries.splice(fromIndex, 1);
            entries.splice(toIndex, 0, removed);
            result = entries.reduce((acc, [, value], index) => {
                acc[index] = value;
                return acc;
            }, {});
        }
        return result;
    };

    const onDragEnd = e => {
        const { active, over } = e;
        if (over && active.id !== over.id) {
            const oldIndex = items.findIndex(item => item === active.id);
            const newIndex = items.findIndex(item => item === over.id);
            const newOrder = reorder(data, oldIndex, newIndex);
            onDragEndCb(newOrder);
        }
    };

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
            <SortableContext items={items} strategy={TYPE[strategy]}>
                <div>{children}</div>
            </SortableContext>
        </DndContext>
    );
};

export default DragAndDrop;
