import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

const TableCellCheckbox = styled(TableCell)({
    padding: 7,
    '& .MuiSvgIcon-root': {
        fontSize: '1.125rem',
    },
    '& .MuiCheckbox-root': {
        padding: 4,
    },
});

export default TableCellCheckbox;
