import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import { Table } from '@components/Table';
import Button from '@components/Button';
import { Typography } from '@components/Typography';
import { ModalConfirm } from '@components/Modal';
import LockedButton from '@features/LockedButton';
import PageHeader from '@features/PageHeader';
import { companyService } from '@services';
import { accountActions, alertActions, userActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';
import { getCompanyAddress } from '@helpers/companies';
import { getIntegrationNames } from '@helpers/integrations';
import { PRICING_DRAWER_TYPE } from '@constants/pricing-drawer-ui';
import { COMPANY_STATUS } from '@constants/companies';
import { LocationIntegrationName } from '@constants/integrations';
import { LocationsSeoIcon } from 'assets/images/icons';
import { GoogleIcon, IFoodIcon, CoverManagerIcon, FacebookIcon } from 'assets/images/integrations';
import ImportGoogleCompanies from '../ImportGoogleCompanies';
import AddGoogleCompany from '../AddGoogleCompany';
import UpdateLocations from '../UpdateLocations';
import ProfileProgress from './ProfileProgress';
import FiltersPanel from '../FiltersPanel';

const StyledLockedButton = styled(LockedButton)({
    marginRight: 8,
    '& svg': {
        width: 16,
        height: 14,
    },
});

const IconsWrapper = styled('span')({
    display: 'flex',
    height: '18px',
    gap: '8px',
    '& svg': {
        height: 18,
        width: 'auto',
    },
});

const GetCompaniesColumnNames = () => {
    const { t } = useTranslation();
    return [
        { key: 0, component: <Typography variant="subtitle2">{t('common.location')}</Typography> },
        {
            key: 1,
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('SettingsCompanies.address')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.brand')}
                </Typography>
            ),
        },
        {
            key: 3,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.status')}
                </Typography>
            ),
        },
        {
            key: 4,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('SettingsCompanies.integrations')}
                </Typography>
            ),
        },
        {
            key: 5,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('SettingsCompanies.profileCompleteness')}
                </Typography>
            ),
        },
        { key: 6, component: <Typography align="left" variant="subtitle2"></Typography> },
    ];
};

const LocationIntegrationIcon = ({ type }) => {
    switch (type) {
        case LocationIntegrationName.Google:
            return <GoogleIcon />;
        case LocationIntegrationName.Facebook:
            return <FacebookIcon />;
        case LocationIntegrationName.iFood:
            return <IFoodIcon />;
        case LocationIntegrationName.CoverManager:
            return <CoverManagerIcon />;
        default:
            return null;
    }
};

const getRowsData = companies => {
    const { t } = useTranslation();
    return companies.map(company => {
        const fullAddress = company.hasBusinessAddress ? getCompanyAddress(company.addressLine1, company.city) : '--';
        const profilesConnected = getIntegrationNames(company.profiles);
        return [
            {
                component: (
                    <Typography variant="subtitle2" align="left">
                        {company.internalName}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left" noWrap sx={{ maxWidth: '150px' }}>
                        {fullAddress}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {company.brand?.title || '--'}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography
                        variant="body2"
                        align="left"
                        noWrap
                        sx={{
                            maxWidth: '150px',
                            color: company.status === COMPANY_STATUS.OPEN ? 'rgba(0, 0, 0, 0.87)' : '#B00020',
                        }}
                    >
                        {company.status === COMPANY_STATUS.OPEN ? t('common.open') : t('common.closed')}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {profilesConnected.length ? (
                            <IconsWrapper>
                                {profilesConnected.map(type => (
                                    <LocationIntegrationIcon key={type} type={type} />
                                ))}
                            </IconsWrapper>
                        ) : (
                            t('common.none')
                        )}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <ProfileProgress value={company?.completeness} />{' '}
                            {t(
                                `common.${
                                    company?.completeness <= 49
                                        ? 'low'
                                        : company?.completeness >= 50 && company?.completeness <= 79
                                        ? 'medium'
                                        : 'high'
                                }`,
                            )}
                        </span>
                    </Typography>
                ),
            },
            {
                value: company,
            },
        ];
    });
};

const CompaniesTable = ({
    filter,
    setFilter,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    account,
    view,
    setView,
    selectedCompany,
    setSelectedCompany,
}) => {
    const { t } = useTranslation();
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isAddLocationOpen, setIsAddLocationOpen] = useState(false);
    const [isUpdateLocationsOpen, setIsUpdateLocationsOpen] = useState(false);

    const { locations, locationsLoading, totalCount, pages } = useSelector(state => state.account);
    const user = useSelector(state => state.authentication.user);

    const dispatch = useDispatch();

    const onDelete = async () => {
        try {
            await companyService.deleteCompany(selectedCompany?.companyId);
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
            dispatch(accountActions.get());
            dispatch(userActions.getUser(user?.id));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.deleteFail')));
            }
        }
        setDeleteModalOpen(false);
        setSelectedCompany({});
    };

    const onPageChange = (_, newPage) => {
        setPage(newPage);
        dispatch(accountActions.getLocations(filter, newPage, rowsPerPage));
    };

    const onRowsPerPageChange = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        dispatch(accountActions.getLocations(filter, 0, parseInt(event.target.value, 10)));
    };

    const onApplyFilters = () => {
        dispatch(accountActions.getLocations(filter, 0, rowsPerPage));
        setPage(0);
    };

    const limitExceeded = account?.companies?.length >= account?.companiesMaxCount;

    return (
        <div style={{ paddingBottom: '16px' }}>
            <PageHeader
                styles={{ marginBottom: '16px' }}
                icon={<LocationsSeoIcon />}
                title={t('SettingsCompanies.title')}
                subtitle={t('SettingsCompanies.subtitle')}
                metrics={
                    <Typography
                        variant="body1"
                        sx={{
                            color: 'rgba(0, 0, 0, 0.6)',
                            '@media (max-width: 700px)': {
                                textAlign: 'center',
                            },
                        }}
                    >
                        {t('common.locations')}{' '}
                        {account?.companiesMaxCount === Infinity ? (
                            <span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{account?.companies?.length}</span>
                        ) : (
                            <span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                                {t('common.outOf', {
                                    total: account?.companies?.length,
                                    max: account?.companiesMaxCount,
                                })}
                            </span>
                        )}
                    </Typography>
                }
            >
                {limitExceeded || account?.billingDiffInSeconds < 0 ? (
                    <StyledLockedButton
                        tooltipType={1}
                        variant="contained"
                        addonRight={<ImportGoogleCompanies translation={t} disabled={true} />}
                        drawerType={PRICING_DRAWER_TYPE.CHANGE}
                    >
                        {t('buttons.addLocation')}
                    </StyledLockedButton>
                ) : (
                    <>
                        <Button type="button" variant="contained" onClick={() => setIsAddLocationOpen(true)}>
                            {t('buttons.addLocation')}
                        </Button>
                        <ImportGoogleCompanies translation={t} />
                    </>
                )}
                <Button variant="outlined" onClick={() => setIsUpdateLocationsOpen(true)}>
                    {t('buttons.updateLocations')}
                </Button>
                <FiltersPanel onApplyFilters={onApplyFilters} filter={filter} setFilter={setFilter} />
            </PageHeader>
            <TableContainer>
                <Table
                    columnNames={GetCompaniesColumnNames()}
                    rows={getRowsData(locations)}
                    isDataLoading={locationsLoading}
                    onPrimaryAction={({ value }) => {
                        setSelectedCompany(value);
                        setView(view.FORM);
                    }}
                    onSecondaryAction={({ value }) => {
                        setSelectedCompany(value);
                        setDeleteModalOpen(true);
                    }}
                    isInteractive
                    withPagination
                    paginationProps={{
                        colSpan: 7,
                        count: totalCount,
                        rowsPerPage: rowsPerPage,
                        page: page,
                        onPageChange,
                        onRowsPerPageChange,
                        labelDisplayedRows: ({ from, to, count }) =>
                            `${from}–${to} ${t('Table.of')} ${count !== -1 ? count : `${t('Table.moreThan')} ${to}`}`,
                        labelRowsPerPage: <span>{t('Table.rowsPerPage')}</span>,
                        pages,
                    }}
                />
            </TableContainer>
            {isAddLocationOpen && (
                <AddGoogleCompany
                    isOpen={isAddLocationOpen}
                    onClose={() => setIsAddLocationOpen(false)}
                    setSelectedCompany={setSelectedCompany}
                    changeView={() => setView(view.FORM)}
                />
            )}
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDelete}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                    onSecondaryAction={() => {
                        setDeleteModalOpen(false);
                        setSelectedCompany({});
                    }}
                />
            )}
            {isUpdateLocationsOpen && (
                <UpdateLocations open={isUpdateLocationsOpen} setOpen={setIsUpdateLocationsOpen} />
            )}
        </div>
    );
};

export default CompaniesTable;
