import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { formatDistanceStrict } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import IconButton from '@mui/material/IconButton';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Avatar from '@components/Avatar';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { getInitialsFull } from '@helpers/userName';
import { getChannelIcon } from '@helpers/channels';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { getIntegrationIcon } from '@helpers/integrations';
import { NOTIFICATION_TYPE } from '@constants/notifications';
import { notificationsActions, alertActions } from '@actions';
import { notificationsService, socketService } from '@services';
import { NotificationsDarkIcon, NotificationsLightIcon } from 'assets/images/icons';
import { SurveysIcon } from 'assets/images/sidebar';
import {
    StyledDrawer,
    Content,
    StyledIconButton,
    Badge,
    CssRating,
    StyledUserIcon,
    CardWrapper,
    ContentWrapper,
    IconWrapper,
} from './styles';

const DEFAULT_SKIP = 20;

const Notifications = ({ buttonType }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [deleteQueue, setDeleteQueue] = useState({});

    const language = useSelector(state => state.authentication.user?.language);
    const { data, loading, totalCount, hasMore } = useSelector(state => state.notifications);
    const paginationStart = useRef(0);
    const notificationsRef = useRef();
    const totalCountRef = useRef(0);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(notificationsActions.get(DEFAULT_SKIP, paginationStart.current));
        return () => socketService.stopConnection('notification');
    }, []);

    useEffect(() => {
        notificationsRef.current = data;
        totalCountRef.current = totalCount;
    }, [data]);

    useEffect(() => {
        socketService.registerOnServerEvents('notification', payload => {
            const notificationsCopy = notificationsRef.current.slice();
            if (payload.type === NOTIFICATION_TYPE.SURVEY) {
                const updatedSurveyIndex = notificationsCopy.findIndex(n => n.id === payload?.id);
                if (updatedSurveyIndex !== -1) {
                    notificationsCopy[updatedSurveyIndex] = payload;
                    dispatch(
                        notificationsActions.set({
                            items: notificationsCopy,
                            totalCount: totalCountRef.current,
                        }),
                    );
                    return;
                }
            }
            dispatch(
                notificationsActions.set({
                    items: [payload, ...notificationsCopy],
                    totalCount: totalCountRef.current + 1,
                }),
            );
        });
    }, [dispatch]);

    const loadMore = () => {
        if (!hasMore) return;
        const newPaginationStart =
            paginationStart.current === 0 ? DEFAULT_SKIP : paginationStart.current + DEFAULT_SKIP;
        paginationStart.current = newPaginationStart;
        dispatch(notificationsActions.loadMore(DEFAULT_SKIP, newPaginationStart));
    };

    const onClose = () => setIsOpen(false);

    const onHide = async id => {
        try {
            if (id) {
                setDeleteQueue(prev => ({ ...prev, [id]: true }));
            }
            const response = await notificationsService.readNotifications(id);
            if (id) {
                if (paginationStart.current > 0) {
                    paginationStart.current -= 1;
                }
                const isLastItem = data.length === 1;
                dispatch(
                    notificationsActions.set({
                        totalCount: response.count,
                        items: data.filter(item => item.id !== id),
                    }),
                );
                // eslint-disable-next-line no-unused-vars
                const { [id]: _, ...newDeleteQueue } = deleteQueue;
                setDeleteQueue(newDeleteQueue);
                if (isLastItem) {
                    setIsOpen(false);
                }
            } else {
                paginationStart.current = 0;
                dispatch(notificationsActions.set({ items: [], totalCount: 0 }));
                setIsOpen(false);
            }
        } catch (error) {
            dispatch(alertActions.error('alertMessages.deleteFail'));
        }
    };

    const onClickNotification = item => {
        switch (item.type) {
            case NOTIFICATION_TYPE.SURVEY:
                history.replace(`/surveys/responses?feedbackId=${item.attributes?.surveyResponseId}`);
                break;
            case NOTIFICATION_TYPE.REVIEW:
                history.replace(`/reviews/manage?reviewId=${item.attributes?.reviewId}`);
                break;
            case NOTIFICATION_TYPE.MESSAGE:
                history.replace(
                    `/messaging/inbox?conversationId=${item.attributes?.conversationId}&conversationStatus=${item.attributes?.conversationStatus}`,
                );
                break;
            default:
                break;
        }
        setIsOpen(false);
    };

    const getTypeIcon = item => {
        switch (item.type) {
            case NOTIFICATION_TYPE.SURVEY:
                return (
                    <IconWrapper>
                        <SurveysIcon />
                    </IconWrapper>
                );
            case NOTIFICATION_TYPE.REVIEW:
                return getIntegrationIcon(item.attributes?.reviewSourceType);
            case NOTIFICATION_TYPE.MESSAGE:
                return getChannelIcon(item.attributes?.channelType);
            default:
                return null;
        }
    };

    const isHeader = buttonType === 'dark';

    const TYPE = {
        [NOTIFICATION_TYPE.SURVEY]: t('Sidebar.surveys'),
        [NOTIFICATION_TYPE.REVIEW]: t('Sidebar.reviews'),
        [NOTIFICATION_TYPE.MESSAGE]: t('Sidebar.messaging'),
    };

    return (
        <>
            <StyledIconButton iconSize={isHeader ? 28 : 24} onClick={() => setIsOpen(!isOpen)}>
                {isHeader ? <NotificationsDarkIcon /> : <NotificationsLightIcon />}
                {totalCount ? (
                    <Badge variant="caption" isLarge={isHeader}>
                        {totalCount > 99 ? '' : totalCount}
                    </Badge>
                ) : null}
            </StyledIconButton>
            <StyledDrawer
                anchor="right"
                open={isOpen}
                onClose={onClose}
                title={
                    <span style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        {t('common.notifications')}
                        <Typography
                            variant="caption"
                            style={{
                                backgroundColor: '#B00020',
                                color: '#fff',
                                padding: '3px 6px',
                                borderRadius: '4px',
                            }}
                        >
                            {totalCount}
                        </Typography>
                    </span>
                }
                primaryText={t('buttons.hideAll')}
                primaryAction={() => onHide()}
                primaryDisabled={totalCount === 0}
                primaryButtonStartIcon={<VisibilityOff />}
                secondaryText={t('buttons.close')}
            >
                {!loading && totalCount === 0 ? (
                    <Typography variant="body2" sx={{ textAlign: 'center', marginTop: '24px' }}>
                        {t('common.noNotifications')}
                    </Typography>
                ) : (
                    <Content id="pl-notifications-list">
                        {!loading && totalCount > 0 && (
                            <InfiniteScroll
                                dataLength={data.length}
                                next={loadMore}
                                hasMore={hasMore}
                                loader={<div />}
                                scrollableTarget="pl-notifications-list"
                            >
                                {data.map(item => (
                                    <CardWrapper key={item.id}>
                                        <div
                                            style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                                        >
                                            <Typography
                                                variant="caption"
                                                sx={{ color: 'rgba(0, 0, 0, 0.6)', display: 'block' }}
                                            >
                                                {TYPE[item.type]} •{' '}
                                                {formatDistanceStrict(new Date(item?.createdAt), new Date(), {
                                                    addSuffix: true,
                                                    locale: I18N_DATE_FORMAT[language],
                                                })}
                                            </Typography>
                                            <IconButton
                                                disabled={deleteQueue[item.id]}
                                                onClick={() => onHide(item.id)}
                                                sx={{
                                                    padding: '4px',
                                                    transform: 'translate(4px, -4px)',
                                                    '& svg': { width: '16px', height: '16px' },
                                                }}
                                            >
                                                <VisibilityOff />
                                            </IconButton>
                                        </div>
                                        <ContentWrapper onClick={() => onClickNotification(item)}>
                                            <Avatar
                                                size="large"
                                                image={item.attributes?.reviewUserPhotoUrl}
                                                fullName={item.title}
                                                label={getInitialsFull(item.title)}
                                                icon={<StyledUserIcon />}
                                                withBadge
                                                badge={getTypeIcon(item)}
                                            />
                                            <div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography variant="subtitle2">{item.title}</Typography>
                                                    {item.attributes?.rating ? (
                                                        <CssRating
                                                            size="small"
                                                            name="read-only"
                                                            value={item.attributes?.rating}
                                                            readOnly
                                                            icon={<StarRoundedIcon fontSize="inherit" />}
                                                            emptyIcon={
                                                                <StarRoundedIcon
                                                                    fontSize="inherit"
                                                                    sx={{ color: '#E4EAEB' }}
                                                                />
                                                            }
                                                        />
                                                    ) : null}
                                                </div>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                        marginTop: '2px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        WebkitLineClamp: '2',
                                                        WebkitBoxOrient: 'vertical',
                                                        display: '-webkit-box',
                                                    }}
                                                >
                                                    {item.message}
                                                </Typography>
                                            </div>
                                        </ContentWrapper>
                                    </CardWrapper>
                                ))}
                            </InfiniteScroll>
                        )}
                    </Content>
                )}
            </StyledDrawer>
        </>
    );
};

export default Notifications;
