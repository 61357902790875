import { memo } from 'react';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const CssSnackbar = styled(Snackbar)({
    top: '0 !important',
    left: '0 !important',
    right: '0 !important',
    zIndex: '9998 !important',
    transform: 'none !important',
    '@media (max-width: 1024px)': {
        top: '0 !important',
        left: '0 !important',
    },
    '& .MuiPaper-root': {
        borderRadius: '0 !important',
        width: '100% !important',
        backgroundColor: '#BCC9CE !important',
        flexWrap: 'nowrap',
        padding: '2px 16px !important',
    },
    '& .MuiSnackbarContent-message': {
        width: '100%',
    },
    '& path': {
        fill: '#416774 !important',
    },
});

const SlideTransition = memo(props => <Slide {...props} direction="down" />);

SlideTransition.displayName = 'SlideTransition';

const InfoSnackbar = ({ isOpen, handleClose, message }) => {
    return (
        <CssSnackbar
            open={isOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            TransitionComponent={SlideTransition}
            message={message}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
        />
    );
};

InfoSnackbar.displayName = 'InfoSnackbar';

export default InfoSnackbar;
