import { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Typography } from '@components/Typography';
import { Select } from '@components/Select';
import { useThrottle } from '@hooks/useThrottle';
import { useCopyToClipboard } from '@hooks/useCopyToClipboard';
import { useQueryParams } from '@hooks/useQueryParams';
import { CopyIcon } from 'assets/images/icons';
import { Input } from '@components/Input';
import Button from '@components/Button';
import { LOCALE } from '@constants/locale';
import { Industry } from '@constants/account';
import { accountActions, alertActions, userActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';
import Billing from './Billing';
import Quota from './Quota';

export const Container = styled('div')({
    marginBottom: 65,
    '@media (max-width: 1024px)': {
        marginBottom: 80,
    },
});

const Form = styled('div')({
    marginBottom: 24,
    maxWidth: '698px',
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateColumns: '1fr 1fr',
    '@media (max-width: 1024px)': {
        gridTemplateColumns: '1fr',
        maxWidth: 'none',
    },
});

const Footer = styled('div')({
    position: 'fixed',
    padding: '16px 24px',
    bottom: 0,
    background: 'white',
    left: 280,
    right: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    '@media (max-width: 1024px)': {
        left: 0,
        padding: 16,
        '& button': {
            width: '100%',
        },
    },
});

export const GeneralSettings = () => {
    const { t } = useTranslation();

    const { onCopy } = useCopyToClipboard();
    const dispatch = useDispatch();
    const query = useQueryParams();
    const history = useHistory();

    const account = useSelector(state => state.account?.account);
    const user = useSelector(state => state.authentication?.user);

    const AccountSchema = yup.object().shape({
        accountName: yup.string().required('validation.required').max(255, 'validation.maxLength'),
        industry: yup.number().required('validation.required'),
    });

    const resolver = useYupValidationResolver(AccountSchema);

    const {
        getValues,
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver: resolver,
        defaultValues: {
            accountName: '',
            ownerId: '',
            locale: '',
            apiKey: '',
            industry: '',
        },
    });

    useEffect(() => {
        const checkSubscriptionType = async () => {
            const sessionId = query.get('sessionId');
            if (sessionId) {
                await dispatch(accountActions.checkSubscriptionType(sessionId));
                history.replace('/account-settings/general');
            }
        };
        checkSubscriptionType();
    }, [query]);

    useEffect(() => {
        if (account) {
            setValue('accountName', account?.accountName || '', { shouldValidate: true });
            setValue('ownerId', account?.ownerId || '', { shouldValidate: true });
            setValue('locale', account?.locale ?? '', { shouldValidate: true });
            setValue('apiKey', account?.apiKey || '', { shouldValidate: true });
            setValue('industry', account?.industry, { shouldValidate: true });
        }
    }, [account]);

    const userOptions = useMemo(
        () =>
            account?.users?.map(user => ({
                value: user.id,
                label: `${user?.firstName} ${user?.lastName}`,
            })),
        [account],
    );

    const industryOptions = Object.entries(Industry).map(([key, value]) => ({
        value,
        label: t(`industry.${key}`),
    }));

    const [onCopyClick] = useThrottle(value => onCopy(value), 1000);

    const onSubmit = async () => {
        const values = getValues();
        try {
            await dispatch(accountActions.updateAccount({ ...account, ...values }));
            if (values.ownerId !== user?.id) {
                await dispatch(userActions.getUser(user?.id));
            }
            dispatch(alertActions.success(t('alertMessages.updateSuccess')));
            reset(values);
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.updateFail')));
            }
        }
    };

    if (!account) return null;

    return (
        <Container>
            <Typography variant="h6" sx={{ marginBottom: '24px' }}>
                {t('SettingsGeneral.accountNameOwnership')}
            </Typography>
            <Form>
                <Controller
                    control={control}
                    name="accountName"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field }}
                            label={t('SettingsGeneral.accountName')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message, { count: 255 }) : null}
                            required
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="ownerId"
                    render={({ field, ref }) => (
                        <Select
                            {...field}
                            value={String(getValues('ownerId'))}
                            ref={ref}
                            label={t('SettingsGeneral.accountOwner')}
                            options={userOptions}
                            disabled={!user.isAccountOwner}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="locale"
                    render={({ field, ref }) => (
                        <Select
                            {...field}
                            ref={ref}
                            label={t('SettingsGeneral.accountLocale')}
                            options={[
                                { value: LOCALE.BRAZIL, label: 'Brazil' },
                                { value: LOCALE.INTERNATIONAL, label: 'International' },
                                { value: LOCALE.PORTUGAL, label: 'Portugal' },
                                { value: LOCALE.SPAIN, label: 'Spain' },
                                { value: LOCALE.UKRAINE, label: 'Ukraine' },
                                { value: LOCALE.UNITED_KINGDOM, label: 'United Kingdom' },
                                { value: LOCALE.POLAND, label: 'Poland' },
                                { value: LOCALE.GERMANY, label: 'Germany' },
                            ]}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="industry"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Select
                            {...field}
                            ref={ref}
                            label={t('common.industry')}
                            options={industryOptions}
                            required
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="apiKey"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field }}
                            onAddonClick={() => onCopyClick(getValues('apiKey'))}
                            addonComponent={<CopyIcon style={{ fill: 'rgba(0, 0, 0, 0.6)' }} />}
                            label="ApiKey"
                            error={!!error}
                            helperText={error ? error.message : null}
                            disabled
                        />
                    )}
                />
            </Form>
            <Quota account={account} />
            <Billing account={account} />
            <Footer>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    disabled={isSubmitting || !isValid || !isDirty}
                >
                    {t('buttons.saveChanges')}
                </Button>
            </Footer>
        </Container>
    );
};
