import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { ArrowDownIcon } from 'assets/images/icons';

export const StyledPopper = styled(Popper)({
    boxShadow:
        'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
    zIndex: 3,
    background: '#fff',
    padding: '8px 0',
    borderRadius: 4,
    '.MuiMenuItem-root': {
        padding: '8px 16px',
        fontSize: '14px',
        lineHeight: '16px',
        width: '100%',
        justifyContent: 'flex-start',
    },
});

export const ArrowDown = styled(ArrowDownIcon, { shouldForwardProp: prop => prop !== 'isUp' })(({ isUp }) => ({
    width: 4,
    marginLeft: 6,
    '& path': {
        fill: '#416774',
        fillOpacity: 1,
    },
    ...(isUp && {
        transform: 'rotate(180deg)',
    }),
}));
