import { memo, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Switch } from '@components/Switch';
import { MultiSelect } from '@components/MultiSelect';
import { Select } from '@components/Select';
import { ColorPicker } from '@components/ColorPicker';
import { FiltersIntegrationType } from '@constants/integrations';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { useTranslation } from '@hooks/useTranslation';

const Form = styled('div')({
    display: 'grid',
    columnGap: '16px',
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
    '@media (max-width: 1024px)': {
        gridTemplateColumns: 'minmax(0, 1fr)',
        maxWidth: 'none',
    },
    '.react-colorful__wrapper': {
        position: 'absolute',
        top: '-70px !important',
        right: '-184px !important',
        zIndex: 2,
    },
    '@media (max-width: 768px)': {
        '.react-colorful__wrapper': {
            top: '-190px !important',
            left: '0 !important',
        },
    },
});

const reviesToShowOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '8', label: '8' },
    { value: '16', label: '16' },
    { value: '24', label: '24' },
];

const ConfigForm = memo(({ widgetData, setWidgetData, companies, labels, type }) => {
    const { t } = useTranslation();

    const labelsOptions = useMemo(() => {
        const availableLabels = [...new Set(companies.map(item => item.labels).flat())];
        return labels.filter(l => availableLabels.includes(l.id)).map(item => ({ value: item.id, label: item.name }));
    }, [companies, labels]);

    const companyOptions = useMemo(() => {
        return widgetData.labels.length === labelsOptions.length
            ? companies.map(item => ({ value: item.companyId, label: item.internalName }))
            : companies
                  .filter(item => item.labels.some(l => widgetData.labels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [companies, widgetData.labels]);

    const companiesMap = useMemo(
        () => companies.reduce((obj, item) => ({ ...obj, [item.companyId]: item }), {}),
        [companies],
    );

    const integrationsOptions = useMemo(() => {
        const availableCompanies = widgetData.company.map(id => companiesMap[id]).filter(Boolean);
        return [
            ...new Set(
                availableCompanies
                    .map(company => company.profiles)
                    .flat()
                    .filter(p => p.type in FiltersIntegrationType)
                    .map(p => p.type),
            ),
        ].map(type => ({ value: type, label: FiltersIntegrationType[type] }));
    }, [companiesMap, widgetData.company]);

    const update = {
        labels: e => {
            const selectedLabels = getMultiselectSelectedOptions(labelsOptions, e.target.value);
            const availableCompanies =
                selectedLabels.length === labelsOptions.length
                    ? companies.map(item => item.companyId)
                    : companies
                          .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                          .map(item => item.companyId);
            const integrations = [
                ...new Set(
                    availableCompanies
                        .map(selectedItem => companies.find(company => company.companyId === selectedItem))
                        .map(company => company.profiles)
                        .flat()
                        .filter(p => p.type in FiltersIntegrationType)
                        .map(p => p.type),
                ),
            ];
            setWidgetData(prev => ({ ...prev, integrations, company: availableCompanies, labels: selectedLabels }));
        },
        company: e => {
            const newCompanies = getMultiselectSelectedOptions(companyOptions, e.target.value);
            const integrations = [
                ...new Set(
                    newCompanies
                        .map(selectedItem => companies.find(company => company.companyId === selectedItem))
                        .map(company => company.profiles)
                        .flat()
                        .filter(p => p.type in FiltersIntegrationType)
                        .map(p => p.type),
                ),
            ];
            setWidgetData(prev => ({ ...prev, integrations, company: newCompanies }));
        },
        integrations: e =>
            setWidgetData(prev => ({
                ...prev,
                integrations: getMultiselectSelectedOptions(integrationsOptions, e.target.value),
            })),
        sorting: e => setWidgetData({ ...widgetData, sorting: e.target.value }),
        count: e => setWidgetData({ ...widgetData, count: e.target.value }),
        hideEmptyReviews: e => setWidgetData({ ...widgetData, hideEmptyReviews: e.target.checked }),
    };

    return (
        <>
            <Form>
                <MultiSelect
                    label={t('common.locationLabels')}
                    value={widgetData.labels}
                    onChange={update.labels}
                    options={labelsOptions}
                    countable
                />
                <MultiSelect
                    label={t('common.locations')}
                    value={widgetData.company}
                    onChange={update.company}
                    options={companyOptions}
                    countable
                />
                <MultiSelect
                    label={t('common.reviewSites')}
                    value={widgetData.integrations}
                    onChange={update.integrations}
                    options={integrationsOptions}
                    countable
                />
                <Select
                    label={t('ShowcaseWidgets.reviewsToShow')}
                    value={widgetData.count}
                    onChange={event => setWidgetData({ ...widgetData, count: event.target.value })}
                    options={reviesToShowOptions}
                />
                <Select
                    label={t('ShowcaseWidgets.reviewsSorting')}
                    value={widgetData.sorting}
                    onChange={event => setWidgetData({ ...widgetData, sorting: event.target.value })}
                    options={[
                        { value: '0', label: t('ShowcaseWidgets.random') },
                        { value: '1', label: t('ShowcaseWidgets.recent') },
                    ]}
                />
                {type !== 'popup' && (
                    <ColorPicker
                        label={t('FeedbackSurveys.backgroundColor')}
                        value={widgetData.backgroundColor}
                        inputProps={{
                            value: widgetData.backgroundColor,
                            onChange: e => {
                                const val = e?.target?.value ? e.target.value : e;
                                setWidgetData({ ...widgetData, backgroundColor: val });
                            },
                        }}
                    />
                )}
            </Form>
            <Switch
                checked={widgetData.hideEmptyReviews}
                handleChange={update.hideEmptyReviews}
                label={t('ShowcaseWidgets.hideEmptyReviews')}
            />
        </>
    );
});

ConfigForm.displayName = 'ConfigForm';

export default ConfigForm;
