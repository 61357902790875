import { useState } from 'react';
import { EditorProvider, useCurrentEditor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import CharacterCount from '@tiptap/extension-character-count';
import Highlight from '@tiptap/extension-highlight';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Placeholder from '@tiptap/extension-placeholder';
import { markInputRule, markPasteRule } from '@tiptap/core';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@components/Typography';
import AIAssistant from '@features/AIAssistant';
import { addTipTapMarkTag, parseTipTapJSONToPlainText } from '@helpers/text-editor';

const CssCharCounter = styled(Typography)({
    position: 'absolute',
    bottom: 33.5,
    right: 14,
});

const Asterisc = styled('span')(() => ({
    color: '#B00020',
    fontWeight: 500,
}));

const Legend = styled('span', {
    shouldForwardProp: prop => prop !== 'isError',
})(({ isError }) => ({
    height: '11px',
    color: isError ? '#B00020' : 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'Rubik',
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.38px',
    transform: 'scale(0.8)',
    background: 'white',
    position: 'absolute',
    left: '5px',
    top: '-7px',
    padding: '0 3px',
    zIndex: 3,
}));

const Container = styled('div', {
    shouldForwardProp: prop => prop !== 'hasNoLimit' && prop !== 'isEditable' && prop !== 'isError',
})(({ hasNoLimit, isEditable, isError }) => ({
    width: '100%',
    minHeight: '104px',
    padding: hasNoLimit ? '16.5px 14px' : '16.5px 14px 38px',
    borderRadius: 4,
    border: `1px solid ${isError ? '#B00020' : 'rgba(0, 0, 0, 0.12)'} ${isEditable ? '' : '!important'}`,
    '&:hover': {
        border: `1px solid ${isError ? '#B00020' : '#1F4C5C'}`,
    },
    '&:focus-within': {
        border: `2px solid ${isError ? '#B00020' : '#1F4C5C'}`,
        width: 'calc(100%)',
        padding: hasNoLimit ? '15.5px 13px' : '15.5px 13px 37px',
    },
    '& .ProseMirror': {
        width: '100%',
        maxWidth: '100%',
        minWidth: '100%',
        overflow: 'auto',
        height: 'auto',
        outline: 'none',
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: 'Rubik, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.15px',
        '& p': {
            margin: 0,
        },
    },
    '& .pl-custom-highlight': {
        backgroundColor: '#bcc9ce',
        padding: '0px 5px',
        borderRadius: 4,
    },
    '& p.is-editor-empty:first-of-type::before': {
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: '400',
        fontSize: ' 0.875rem',
        content: 'attr(data-placeholder)',
        float: 'left',
        height: '0',
        pointerEvents: 'none',
    },
    '& .ProseMirror-focused p.is-editor-empty:first-of-type::before': {
        content: 'none',
        display: 'none',
    },
}));

const InputBlurWrapper = styled('div')(() => ({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100% - 16px)',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    pointerEvents: 'none',
    '& .MuiCircularProgress-root': {
        color: 'rgba(0, 0, 0, 0.38) !important',
    },
}));

export const EDITOR_TYPES = {
    TEXT_AREA: 0,
    INBOX: 1,
};

const getRegexRules = editorType => {
    switch (editorType) {
        case EDITOR_TYPES.INBOX:
            return {
                input: /(^|\s)\[((?:survey))\]$/,
                paste: /(^|\s)\[((?:survey))\]/g,
            };
        default:
            return {
                input: /(^|\s)\[((?:name|last_name|location))\]$/,
                paste: /(^|\s)\[((?:name|last_name|location))\]/g,
            };
    }
};

const CustomHighlight = Highlight.extend({
    addOptions() {
        return {
            editorType: EDITOR_TYPES.TEXT_AREA,
        };
    },
    addInputRules() {
        return [
            markInputRule({
                find: getRegexRules(this.options.editorType).input,
                type: this.type,
            }),
        ];
    },
    addPasteRules() {
        return [
            markPasteRule({
                find: getRegexRules(this.options.editorType).paste,
                type: this.type,
            }),
        ];
    },
    addKeyboardShortcuts() {
        return {
            Backspace: () =>
                this.editor.commands.command(({ tr, state }) => {
                    let isHighlight = false;
                    const { selection } = state;
                    const { empty, anchor } = selection;

                    if (!empty) {
                        return false;
                    }
                    state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
                        const mark = node.marks[0];
                        if (mark && mark.type.name === this.name) {
                            isHighlight = true;
                            tr.insertText('', pos, pos + node.nodeSize);

                            return false;
                        }
                    });

                    return isHighlight;
                }),
        };
    },
});

const Counter = ({ limit }) => {
    const { editor } = useCurrentEditor();
    const count = editor.storage.characterCount.characters();
    const breaksCount = editor.getJSON().content.filter(node => node.type === 'paragraph').length - 1;

    return (
        <CssCharCounter
            variant="caption"
            sx={{
                color: 'rgba(0, 0, 0, 0.6)',
            }}
        >
            {count + breaksCount}/{limit}
        </CssCharCounter>
    );
};

const AIExtension = ({ onChange, btnStyles, isLoading, setIsLoading, editable, additionalOptions }) => {
    const { editor } = useCurrentEditor();
    return (
        <AIAssistant
            btnStyles={btnStyles}
            value={parseTipTapJSONToPlainText(editor.getJSON())}
            setValue={newValue => {
                editor?.commands.setContent(addTipTapMarkTag(newValue));
                onChange(JSON.stringify(editor.getJSON()));
            }}
            disabled={!editable || isLoading}
            setLoading={setIsLoading}
            additionalOptions={additionalOptions}
        />
    );
};

const TextEditor = ({
    ref,
    editorType,
    initialValue = '',
    onChange,
    label,
    limit,
    editable = true,
    error,
    aiAssistant,
    toolbar,
    required = false,
    placeholder,
    ...rest
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const extensions = [
        Document,
        Paragraph,
        Text,
        HardBreak,
        CharacterCount,
        CustomHighlight.configure({
            HTMLAttributes: {
                class: 'pl-custom-highlight',
            },
            editorType: editorType,
        }),
        Placeholder.configure({
            placeholder: placeholder,
        }),
    ];

    const hasNoExtension = !limit || limit === Infinity;

    return (
        <div style={{ paddingBottom: '16px', position: 'relative' }} {...rest}>
            {label && (
                <Legend isError={!!error}>
                    {label} {required && <Asterisc>*</Asterisc>}
                </Legend>
            )}
            {isLoading && (
                <InputBlurWrapper>
                    <CircularProgress />
                </InputBlurWrapper>
            )}
            <Container
                hasNoLimit={hasNoExtension && !toolbar && !aiAssistant}
                isEditable={editable && !isLoading}
                isError={!!error}
                className="editor-container"
            >
                <EditorProvider
                    ref={ref}
                    {...rest}
                    placeholder={editable ? 'Type here...' : ''}
                    editable={editable && !isLoading}
                    extensions={extensions}
                    content={addTipTapMarkTag(initialValue)}
                    editorProps={{ attributes: { tabindex: !editable ? '-1' : undefined } }}
                    onUpdate={({ editor }) => onChange(editor.isEmpty ? '' : JSON.stringify(editor.getJSON()))}
                >
                    <div
                        className="editor-toolbar"
                        style={{
                            position: 'absolute',
                            bottom: 32.5,
                            left: 12,
                            display: 'flex',
                            gap: 6,
                            width: 'calc(100% - 24px)',
                            alignItems: 'flex-end',
                        }}
                    >
                        {aiAssistant && (
                            <AIExtension
                                onChange={onChange}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                editable={editable}
                                additionalOptions={aiAssistant.additionalOptions || []}
                            />
                        )}
                        {toolbar && toolbar}
                    </div>
                    {!hasNoExtension && <Counter limit={limit} />}
                </EditorProvider>
            </Container>
        </div>
    );
};

export default TextEditor;
