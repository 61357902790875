export const feedbacksConstants = {
    FEEDBACKS_REQUEST: 'FEEDBACKS_REQUEST',
    FEEDBACKS_SUCCESS: 'FEEDBACKS_SUCCESS',
    FEEDBACKS_LOAD_MORE_SUCCESS: 'FEEDBACKS_LOAD_MORE_SUCCESS',
    RESET_LOADING: 'RESET_LOADING',
    NEW_FEEDBACK_EXIST_SUCCESS: 'NEW_FEEDBACK_EXIST_SUCCESS',
    NEW_FEEDBACK_EXIST_RESET: 'NEW_FEEDBACK_EXIST_RESET',
    ADD_OR_UPDATE_TICKET: 'ADD_OR_UPDATE_TICKET',
    FEEDBACKS_UPDATE: 'FEEDBACKS_UPDATE',
};

export const INCLUDE_COVER_MANAGER = 'coverManager';

export const CustomFieldType = {
    Text: 0,
    Number: 1,
    Date: 2,
};
