import { brandsConstants } from '@constants/brands';

const initialState = {
    data: [],
    loading: true,
    totalCount: 0,
    pages: 1,
};

export function brands(state = initialState, action) {
    switch (action.type) {
        case brandsConstants.BRANDS_REQUEST:
            return { ...state, loading: true };
        case brandsConstants.BRANDS_SUCCESS:
            return {
                loading: false,
                data: action.data.items,
                totalCount: action.data.totalCount,
                pages: action.data.pages,
            };
        case brandsConstants.BRANDS_FAILURE:
            return { ...state, loading: false };
        case brandsConstants.BRANDS_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.items,
                totalCount: action.data.totalCount,
                pages: action.data.pages,
            };
        default:
            return state;
    }
}
