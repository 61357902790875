import yelp from 'assets/images/social-survey/yelp-logo.png';
import fb from 'assets/images/social-survey/fb-logo.png';
import ta from 'assets/images/social-survey/ta-logo.svg';
import google from 'assets/images/social-survey/google-logo.png';
import trustpilot from 'assets/images/social-survey/trustpilot-logo.png';
import instagram from 'assets/images/social-survey/instagram-logo.svg';

export const surveysConstants = {
    SURVEYS_REQUEST: 'SURVEYS_REQUEST',
    SURVEYS_SUCCESS: 'SURVEYS_SUCCESS',
    AUTO_SURVEYS_SUCCESS: 'AUTO_SURVEYS_SUCCESS',
    SURVEYS_ERROR: 'SURVEYS_ERROR',
};

export const VIEW_TYPE = {
    EDIT: 0,
    VIEW: 1,
};

export const BUTTONS_MAP = {
    3: { name: 'Google', src: google },
    2: { name: 'Facebook', src: fb },
    9: { name: 'Tripadvisor', src: ta },
    1: { name: 'Yelp', src: yelp },
    8: { name: 'Trustpilot', src: trustpilot },
    12: { name: 'Instagram', src: instagram },
};
