import { Typography } from '@components/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { POST_CHANNEL_NAME } from '@constants/social-posts';
import { formatNumber } from '@helpers/formatNumbers';
import { getTableArrow, TableCellText } from '../../Feedback/FeedbackTable';

export const getCollapsibleTableBody = (data, index) => {
    const rowPostId = data?.find((item, dataIndex) => dataIndex === index)?.socialPostId;
    const tableData = data?.filter(item => item.socialPostId === rowPostId && !item.socialPostTotal);

    return tableData.map(row => {
        const {
            socialPostTotal = false,
            platform,
            impressions,
            impressionsDelta,
            reach,
            reachDelta,
            likes,
            likesDelta,
            comments,
            commentsDelta,
            shares,
            sharesDelta,
        } = row;

        return !socialPostTotal
            ? [
                  {
                      component: (
                          <IconButton style={{ opacity: 0, cursor: 'auto' }}>
                              <KeyboardArrowUpIcon />
                          </IconButton>
                      ),
                      width: '5%',
                  },
                  {
                      component: (
                          <Typography variant="subtitle2" align="left">
                              {POST_CHANNEL_NAME[platform] || 'NA'}
                          </Typography>
                      ),
                      width: '25%',
                  },
                  {
                      component: (
                          <TableCellText variant="body2" align="right">
                              <span style={{ marginRight: impressionsDelta === 0 ? '28px' : '8px' }}>
                                  {formatNumber(impressions)}
                              </span>
                              {getTableArrow(impressionsDelta)}
                          </TableCellText>
                      ),
                      width: '14%',
                  },
                  {
                      component: (
                          <TableCellText variant="body2" align="right">
                              <span style={{ marginRight: reachDelta === 0 ? '28px' : '8px' }}>
                                  {formatNumber(reach)}
                              </span>
                              {getTableArrow(reachDelta)}
                          </TableCellText>
                      ),
                      width: '14%',
                  },
                  {
                      component: (
                          <TableCellText variant="body2" align="right">
                              <span style={{ marginRight: likesDelta === 0 ? '28px' : '8px' }}>
                                  {formatNumber(likes)}
                              </span>
                              {getTableArrow(likesDelta)}
                          </TableCellText>
                      ),
                      width: '14%',
                  },
                  {
                      component: (
                          <TableCellText variant="body2" align="right">
                              <span style={{ marginRight: commentsDelta === 0 ? '28px' : '8px' }}>
                                  {formatNumber(comments)}
                              </span>
                              {getTableArrow(commentsDelta)}
                          </TableCellText>
                      ),
                      width: '14%',
                  },
                  {
                      component: (
                          <TableCellText variant="body2" align="right">
                              <span style={{ marginRight: sharesDelta === 0 ? '28px' : '8px' }}>
                                  {formatNumber(shares)}
                              </span>
                              {getTableArrow(sharesDelta)}
                          </TableCellText>
                      ),
                      width: '14%',
                  },
              ]
            : null;
    });
};
