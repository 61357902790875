import { authHeader, BASE_URL, handleResponse, handleResponseBlob } from '@helpers/api';

function get(params, page, pageSize) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params),
    };
    return fetch(`${BASE_URL}api/contacts?page=${page}&pageSize=${pageSize}`, requestOptions).then(handleResponse);
}

function getContact(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/contacts/${id}`, requestOptions).then(handleResponse);
}

function deleteContact(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/contacts/${id}`, requestOptions).then(handleResponse);
}

function deleteBulkContacts(params) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ filter: params }),
    };

    return fetch(`${BASE_URL}api/contacts/bulk-delete`, requestOptions).then(handleResponse);
}

function createOrUpdateContact(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/contacts`, requestOptions).then(handleResponse);
}

function getBulkEditData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/contacts/bulk-edit-contacts-state`, requestOptions).then(handleResponse);
}

function updateBulkContacts(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/contacts/bulk-edit`, requestOptions).then(handleResponse);
}

function importContactsBulk(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/contacts/import`, requestOptions).then(handleResponse);
}

function getDuplicates(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/contacts/duplicates`, requestOptions).then(handleResponse);
}

function mergeContacts(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/contacts/merge`, requestOptions).then(handleResponse);
}

function exportCSVBulk(query) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ filter: query }),
    };
    return fetch(`${BASE_URL}api/contacts/export`, requestOptions).then(handleResponseBlob);
}

export const contactsService = {
    get,
    getContact,
    deleteContact,
    deleteBulkContacts,
    createOrUpdateContact,
    updateBulkContacts,
    getBulkEditData,
    importContactsBulk,
    getDuplicates,
    mergeContacts,
    exportCSVBulk,
};
