import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { Typography } from '@components/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import { EmailIcon } from 'assets/images/icons';

const CssPaper = styled(Paper)({
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    gap: 6,
    padding: '0px 16px !important',
    boxShadow: 'none',
    width: '100%',
    overflow: 'hidden',
});

const ContactLabelChip = styled(Chip)({
    backgroundColor: 'transparent',
    borderRadius: 0,
    '& .MuiChip-icon': {
        marginLeft: '8px',
        marginRight: '6px',
    },
    '& .MuiChip-label': {
        padding: 0,
        fontSize: '12px',
    },
    '& svg': {
        width: '12px',
        height: '12px',
        '& path': {
            fill: ' rgba(0, 0, 0, 0.6)',
        },
    },
});

const ContactChip = ({ option, ...props }) => {
    return (
        <CssPaper key={option.id} component="li" {...props}>
            <ContactLabelChip label={<Typography variant="body2">{option.firstName}</Typography>} />
            <ContactLabelChip label={<Typography variant="body2">{option.lastName}</Typography>} />
            {option.phoneNumber ? (
                <ContactLabelChip
                    icon={<PhoneIcon sx={{ width: 12, height: 12 }} />}
                    label={<Typography variant="caption">{option.phoneNumber}</Typography>}
                />
            ) : null}
            {option.email ? (
                <ContactLabelChip
                    icon={<EmailIcon sx={{ width: 12, height: 12 }} />}
                    label={<Typography variant="caption">{option.email}</Typography>}
                />
            ) : null}
        </CssPaper>
    );
};

export default ContactChip;
