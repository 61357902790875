import { useEffect, useState, useRef } from 'react';
import { Input } from '@components/Input';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import AutocompleteCompanyItem from './AutocompleteCompanyItem';

const LocationAutocomplete = ({ label, autocompleteRequestOptions = {}, onSelectOption, onClearPlace }) => {
    const { t } = useTranslation();
    const [suggestions, setSuggestions] = useState([]);
    const [selectedSuggestion, setSelectedSuggestion] = useState();
    const [search, setSearch] = useState('');
    const autocompleteService = useRef(null);

    const loadMap = async () => {
        try {
            const { AutocompleteService } = await window.google.maps.importLibrary('places');
            autocompleteService.current = new AutocompleteService();
        } catch (error) {
            console.error('Could not load the AutocompleteService', error);
        }
    };

    useEffect(() => {
        loadMap();
    }, []);

    const handleSelectOption = selectedOption => {
        setSearch(selectedOption?.description);
        onSelectOption?.(selectedOption);
    };

    const onInputValueChange = inputValue => {
        // If the inputs value is empty we can return as we will get an error if we provide the empty
        // string when we perform a search. Set our suggestions to empty here as well so we don't render
        // the old suggestions.
        if (inputValue === '') {
            onClearPlace();
            setSuggestions([]);
            return;
        }
        autocompleteService.current.getPlacePredictions(
            { input: inputValue, ...autocompleteRequestOptions },
            (predictions, serviceStatus) => {
                // If the response doesn't contain a valid result then set our state as if no suggestions
                // were returned
                if (serviceStatus !== window.google.maps.places.PlacesServiceStatus.OK) {
                    setSuggestions([]);
                    return;
                }
                setSuggestions(predictions);
            },
        );
    };

    return (
        <>
            <Input
                inputProps={{
                    value: search,
                    onChange: event => {
                        setSearch(event.target.value);
                        onInputValueChange(event.target.value);
                    },
                }}
                label={label}
                fullWidth
            />
            <div style={{ height: 'calc(100% - 56px)', overflowY: 'auto', padding: '2px' }}>
                {search && suggestions.length
                    ? suggestions.map((option, index) => {
                          return (
                              <AutocompleteCompanyItem
                                  location={{
                                      name: option.structured_formatting.main_text,
                                      address: option.structured_formatting.secondary_text,
                                  }}
                                  selectedLocation={selectedSuggestion}
                                  setLocation={location => {
                                      setSelectedSuggestion(location);
                                      handleSelectOption(option);
                                  }}
                                  index={index}
                                  key={option?.place_id}
                              />
                          );
                      })
                    : null}
                {search && !suggestions.length ? (
                    <Typography variant="body2" sx={{ textAlign: 'center' }}>
                        {t('common.noOptions')}
                    </Typography>
                ) : null}
            </div>
        </>
    );
};

export default LocationAutocomplete;
