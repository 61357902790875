import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import MuiList from '@mui/material/List';
import Tooltip from '@components/Tooltip';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { UpgradeIcon } from 'assets/images/icons';
import { ListItem } from './styles';

const MultiLevelItem = ({ item: { children, base, icon, id, title }, callback }) => {
    const [open, setOpen] = useState(false);

    const user = useSelector(state => state.authentication.user);
    const isAccountOwner = user?.isAccountOwner || user?.isGlobalAdmin;
    const { pathname } = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if (pathname.startsWith(base)) {
            setOpen(true);
        }
    }, [pathname, base]);

    const handleClick = () => setOpen(prev => !prev);

    return (
        <>
            <ListItem
                button
                isExpanded={open}
                isActive={pathname.startsWith(base)}
                type="multi"
                onClick={handleClick}
                id={id}
            >
                <span />
                {icon}
                <Typography variant="body1">{title}</Typography>
                {open ? (
                    <ExpandLessIcon className="sidebar-menu-arrow" />
                ) : (
                    <ExpandMoreIcon className="sidebar-menu-arrow" />
                )}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <MuiList component="div" disablePadding>
                    {children.map(child =>
                        child.upgradeRequired ? (
                            isAccountOwner ? (
                                <ListItem onClick={child.upgradeClick} type="single" key={child.to}>
                                    <span />
                                    <Typography variant="body2">{child.title}</Typography>
                                    <UpgradeIcon style={{ marginLeft: 'auto' }} />
                                </ListItem>
                            ) : (
                                <Tooltip key={child.to} placement="top" title={t('common.upgradeToUnlock')}>
                                    <ListItem type="single">
                                        <span />
                                        <Typography variant="body2">{child.title}</Typography>
                                        <UpgradeIcon style={{ marginLeft: 'auto' }} />
                                    </ListItem>
                                </Tooltip>
                            )
                        ) : (
                            <Link
                                key={child.to}
                                to={child.to}
                                id={id}
                                style={{ textDecoration: 'none', width: '100%' }}
                            >
                                <ListItem isActive={pathname.startsWith(child.to)} onClick={callback} type="single">
                                    <span />
                                    <Typography variant="body2">{child.title}</Typography>
                                </ListItem>
                            </Link>
                        ),
                    )}
                </MuiList>
            </Collapse>
        </>
    );
};

export default MultiLevelItem;
