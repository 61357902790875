import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import { styled } from '@mui/system';

const Link = styled(MuiLink)(({ variant, color }) => {
    return {
        color: '#1F4C5C',
        ...(variant === 'caption' && {
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontFamily: 'Rubik, sans-serif',
            fontWeight: 400,
            lineHeight: '14px',
            letterSpacing: '0.4px',
        }),
        ...(color && { color }),
    };
});

export const InternalLink = ({ children, variant, ...rest }) => {
    return (
        <Link component={RouterLink} variant={variant} {...rest}>
            {children}
        </Link>
    );
};

export const ExternalLink = ({ children, isBlank = true, variant, ...rest }) => {
    const blankProps = isBlank ? { target: '_blank', rel: 'noopener' } : {};
    return (
        <Link variant={variant} {...blankProps} {...rest}>
            {children}
        </Link>
    );
};
