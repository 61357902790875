import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import sortBy from 'lodash.sortby';
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { dashboardService } from '@services';
import { alertActions, dashboardActions } from '@actions';
import { Recommendation } from '@constants/dashboard';
import { SurveysIcon, ReviewsIcon, SocialIcon, MessagingIcon, PresenceIcon } from 'assets/images/sidebar';
import { ListingsIcon } from 'assets/images/icons';

const CardsWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
});

const RecommendationCard = styled('div', {
    shouldForwardProp: prop => prop !== 'isHidden',
})(({ isHidden }) => ({
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    padding: 16,
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gridGap: 16,
    opacity: isHidden ? 0.4 : 1,
    '& svg': {
        width: 24,
        height: 24,
        '& path': {
            fill: '#1F4C5C',
            fillOpacity: 1,
        },
    },
}));

const StyledIconButton = styled(IconButton)({
    padding: 4,
    '& svg:nth-of-type(1)': {
        width: 16,
        height: 16,
        '& path': {
            fill: 'rgba(0, 0, 0, 0.6)',
        },
    },
});

const Recommendations = () => {
    const [showHidden, setShowHidden] = useState(false);
    const { t } = useTranslation();
    const { recommendations } = useSelector(state => state.dashboard);
    const history = useHistory();
    const dispatch = useDispatch();

    const onTogleVisibility = async item => {
        try {
            const response = await dashboardService.updateRecommendations({
                type: item.type,
                isHidden: !item.isHidden,
            });
            const recommendationsCopy = recommendations.slice();
            const updatedIndex = recommendationsCopy.findIndex(r => r.type === item.type);
            if (updatedIndex === -1) return;
            recommendationsCopy[updatedIndex] = response;
            dispatch(dashboardActions.setRecommendations(recommendationsCopy));
        } catch (error) {
            dispatch(alertActions.error('alertMessages.updateFail'));
        }
    };

    const onClickLink = async item => {
        if (item.isHidden) return;
        history.push(item.url);
        try {
            const response = await dashboardService.updateRecommendations({
                type: item.type,
                isActual: false,
            });
            const recommendationsCopy = recommendations.slice();
            const updatedIndex = recommendationsCopy.findIndex(r => r.type === item.type);
            if (updatedIndex === -1) return;
            recommendationsCopy[updatedIndex] = response;
            dispatch(dashboardActions.setRecommendations(recommendationsCopy));
        } catch (error) {
            dispatch(alertActions.error('alertMessages.updateFail'));
        }
    };

    const RECOMMENDATIONS_MAP = {
        [Recommendation.UnrepliedReviews]: {
            icon: <ReviewsIcon />,
            url: '/reviews/manage?withoutReplies=true',
            urlText: t('HomePage.recommendation1UrlText'),
            title: t('HomePage.recommendation1Title'),
            subtitle: t('HomePage.recommendation1Subtitle'),
            text: t('HomePage.recommendation1Text'),
            chip: value => `${value} ${t('common.review', { count: value })}`,
        },
        [Recommendation.OutdatedPhotos]: {
            icon: <PresenceIcon />,
            url: '/presence/media',
            urlText: t('HomePage.recommendation2UrlText'),
            title: t('HomePage.recommendation2Title'),
            subtitle: t('HomePage.recommendation2Subtitle'),
            text: t('HomePage.recommendation2Text'),
            chip: value => `${value} ${t('common.location', { count: value })}`,
        },
        [Recommendation.IncompleteLocations]: {
            icon: <ListingsIcon />,
            url: '/account-settings/companies',
            urlText: t('HomePage.recommendation3UrlText'),
            title: t('HomePage.recommendation3Title'),
            subtitle: t('HomePage.recommendation3Subtitle'),
            text: t('HomePage.recommendation3Text'),
            chip: value => `${value} ${t('common.location', { count: value })}`,
        },
        [Recommendation.ActionRequiredProfiles]: {
            icon: <PresenceIcon />,
            url: '/presence/profiles',
            urlText: t('HomePage.recommendation4UrlText'),
            title: t('HomePage.recommendation4Title'),
            subtitle: t('HomePage.recommendation4Subtitle'),
            text: t('HomePage.recommendation4Text'),
            chip: value => `${value} ${t('common.profile', { count: value })}`,
        },
        [Recommendation.UnreadConversations]: {
            icon: <MessagingIcon />,
            url: '/messaging/inbox',
            urlText: t('HomePage.recommendation5UrlText'),
            title: t('HomePage.recommendation5Title'),
            subtitle: t('HomePage.recommendation5Subtitle'),
            text: t('HomePage.recommendation5Text'),
            chip: value => `${value} ${t('common.message', { count: value })}`,
        },
        [Recommendation.OutdatedSocialPosts]: {
            icon: <SocialIcon />,
            url: '/social-posts',
            urlText: t('HomePage.recommendation6UrlText'),
            title: t('HomePage.recommendation6Title'),
            subtitle: t('HomePage.recommendation6Subtitle'),
            text: t('HomePage.recommendation6Text'),
            chip: value => `${value} ${t('common.location', { count: value })}`,
        },
        [Recommendation.UnreadSurveyResponses]: {
            icon: <SurveysIcon />,
            url: '/surveys/responses?rating=[1,2,3]&hideAnonimous=true',
            urlText: t('HomePage.recommendation7UrlText'),
            title: t('HomePage.recommendation7Title'),
            subtitle: t('HomePage.recommendation7Subtitle'),
            text: t('HomePage.recommendation7Text'),
            chip: value => `${value} ${t('common.complaint', { count: value })}`,
        },
    };

    const data = sortBy(
        recommendations
            .map(item => ({
                ...RECOMMENDATIONS_MAP[item.type],
                ...item,
                id: item.type,
                isHidden: item.isHidden,
                chip: RECOMMENDATIONS_MAP[item.type].chip(+item.value),
            }))
            .filter(item => (showHidden ? true : !item.isHidden)),
        ['isHidden', 'id'],
    );

    const hasHidden = recommendations.some(item => item.isHidden);
    const hasVisible = recommendations.some(item => !item.isHidden);

    return (
        <>
            <CardsWrapper>
                {data.map(item => (
                    <RecommendationCard key={item.id} isHidden={item.isHidden}>
                        {item.icon}
                        <div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div>
                                    <Typography variant="subtitle2">{item.title}</Typography>
                                    <Typography
                                        variant="caption"
                                        sx={{ color: 'rgba(0, 0, 0, 0.6)', display: 'block' }}
                                    >
                                        {item.subtitle}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '8px' }}>
                                        {item.text}
                                    </Typography>
                                </div>
                                <div>
                                    <StyledIconButton onClick={() => onTogleVisibility(item)}>
                                        {item.isHidden ? <Visibility /> : <VisibilityOff />}
                                    </StyledIconButton>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: '8px',
                                    flexWrap: 'wrap',
                                    gap: '8px',
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: '#416774',
                                        backgroundColor: '#E4EAEB',
                                        borderRadius: '18px',
                                        padding: '2px 6px',
                                        textTransform: 'lowercase',
                                    }}
                                >
                                    {item.chip}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: '#1F4C5C', cursor: 'pointer' }}
                                    onClick={() => onClickLink(item)}
                                >
                                    {item.urlText}
                                </Typography>
                            </div>
                        </div>
                    </RecommendationCard>
                ))}
            </CardsWrapper>
            {hasHidden ? (
                <Typography
                    variant="caption"
                    sx={{
                        color: '#1F4C5C',
                        cursor: 'pointer',
                        marginTop: !hasVisible && !showHidden ? 0 : '16px',
                        display: 'block',
                    }}
                    onClick={() => setShowHidden(!showHidden)}
                >
                    {t(`HomePage.show${showHidden ? 'Less' : 'All'}Recommendation`)}
                </Typography>
            ) : null}
        </>
    );
};

export default Recommendations;
