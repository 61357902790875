import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

function get() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/channels/`, requestOptions).then(handleResponse);
}

function deleteChannel(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/channels/${id}`, requestOptions).then(handleResponse);
}

function createOrUpdateChannelSms(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/channels/sms`, requestOptions).then(handleResponse);
}

function createOrUpdateChannelWhatsApp(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/channels/whatsapp`, requestOptions).then(handleResponse);
}

function createOrUpdateChannelLiveChat(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/channels/live-chat`, requestOptions).then(handleResponse);
}

function createOrUpdateChannelFacebook(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/channels/facebook`, requestOptions).then(handleResponse);
}

function createOrUpdateChannelInstagram(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/channels/instagram`, requestOptions).then(handleResponse);
}

function getConectedInstagramLocations() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/channels/instagram/available-companies`, requestOptions).then(handleResponse);
}

function deleteInstagramChannel(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/channels/instagram/${id}`, requestOptions).then(handleResponse);
}

function createOrUpdateChannelEmail(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/channels/email`, requestOptions).then(handleResponse);
}

export const channelsService = {
    get,
    deleteChannel,
    createOrUpdateChannelSms,
    createOrUpdateChannelWhatsApp,
    createOrUpdateChannelLiveChat,
    createOrUpdateChannelFacebook,
    createOrUpdateChannelInstagram,
    getConectedInstagramLocations,
    deleteInstagramChannel,
    createOrUpdateChannelEmail,
};
