export const getMultiselectSelectedOptions = (options, value) => {
    let optionsSelected = value.filter(item => item !== 'all');
    if (value.includes('all')) {
        if (optionsSelected.length < options.length) {
            optionsSelected = options.map(item => item.value);
        } else {
            optionsSelected = [];
        }
    }
    return optionsSelected;
};
