import { chatbotContentConstants } from '@constants/chatbot-content';

const initialState = {
    data: [],
    loading: true,
    totalCount: 0,
    pages: 1,
};

export function chatbotContent(state = initialState, action) {
    switch (action.type) {
        case chatbotContentConstants.CHATBOT_CONTENT_REQUEST:
            return { ...state, loading: true };
        case chatbotContentConstants.CHATBOT_CONTENT_SUCCESS:
            return {
                loading: false,
                data: action.data.items,
                totalCount: action.data.totalCount,
                pages: action.data.pages,
            };
        case chatbotContentConstants.CHATBOT_CONTENT_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
}
