import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { useThrottle } from '@hooks/useThrottle';
import { ModalBase } from '@components/Modal';
import Button from '@components/Button';
import { alertActions, accountActions } from '@actions';
import { companyService } from '@services';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        width: 'calc(100% - 32px)',
        maxWidth: 564,
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100% !important',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

const FileInputContainer = styled('div')({
    display: 'flex',
    gap: 8,
    marginTop: 8,
    width: '100%',
    height: 32,
    alignItems: 'center',
});

const UpdateLocations = ({ open, setOpen }) => {
    const [fileName, setFileName] = useState('');
    const [locationsData, setLocationsData] = useState({ data: [], updated: 0 });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [onDownloadClick] = useThrottle(async () => {
        try {
            const file = await companyService.getCompaniesCSV();
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = file;
            anchor.download = 'locations_import_example.csv';
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(file);
            anchor.remove();
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
    }, 1000);

    const submitCSV = async event => {
        try {
            const file = event.target.files[0];
            setFileName(file.name);
            const data = new FormData();
            data.append('file', event.target.files[0]);
            data.append('fileName', event.target.files[0].name);
            const result = await companyService.uploadCompaniesCSV(data);
            setLocationsData(result);
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
        event.target.value = null;
    };

    const onUpdateLocations = async () => {
        setIsSubmitting(true);
        try {
            await companyService.updateCompaniesBulk(locationsData.data);
            await dispatch(accountActions.get());
            setOpen(false);
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
        setIsSubmitting(false);
    };

    return (
        <Modal
            isOpen={open}
            handleClose={() => setOpen(false)}
            title={t('UpdateLocations.title')}
            subtitle={
                <Typography variant="caption" color="textSecondary">
                    {t('UpdateLocations.subtitle')}
                </Typography>
            }
            onPrimaryAction={onUpdateLocations}
            primaryActionText={t('buttons.updateLocations')}
            primaryActionDisabled={isSubmitting || locationsData.updated === 0}
            onSecondaryAction={() => setOpen(false)}
            secondaryActionText={t('buttons.cancel')}
        >
            <Typography variant="subtitle2">1. {t('UpdateLocations.list1Title')}</Typography>
            <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginBottom: '8px', display: 'block' }}>
                {t('UpdateLocations.list1Description')}
            </Typography>
            <Button variant="outlined" onClick={onDownloadClick} sx={{ marginBottom: '24px' }}>
                {t('buttons.download')}
            </Button>
            <Typography variant="subtitle2">2. {t('UpdateLocations.list2Title')}</Typography>
            <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginBottom: '8px', display: 'block' }}>
                {t('UpdateLocations.list2Description')}
            </Typography>
            <FileInputContainer>
                <input
                    accept=".csv,.xlsx,.xls"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={submitCSV}
                    name="contactsList"
                />
                <label htmlFor="raised-button-file">
                    <Button type="button" component="span" fullWidth variant="outlined">
                        {t(fileName ? 'buttons.replace' : 'buttons.upload')}
                    </Button>
                </label>
                <Typography variant="caption" color="textSecondary">
                    {fileName}
                </Typography>
            </FileInputContainer>
            {locationsData.data?.length ? (
                <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '8px', display: 'block' }}>
                    {t('UpdateLocations.locationsToUpdate')}: {locationsData?.updated}
                </Typography>
            ) : null}
        </Modal>
    );
};

export default UpdateLocations;
