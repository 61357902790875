import { memo, useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { analyticsActions } from '@actions';
import { subtractYears } from '@helpers/dates';
import { LocationIntegrationName } from '@constants/integrations';
import { GroupByFilter } from '@constants/analytics';
import { getLocationLabels } from '@helpers/locationLabels';
import { getPresenceOptions } from '@helpers/analytics';
import { PrintButton } from '../Common/PrintButton';
import { AnalyticsContainer, HeaderWrapper } from '../styles';
import FiltersPanel from './FiltersPanel';
import PresenceRates from './PresenceRates';
import SearchKeywordsTable from './SearchKeywordsTable';
import DevicesBreakdown from './DevicesBreakdown';
import PresenceTable from './PresenceTable';
import PresenceViewsTrend from './PresenceViewsTrend';
import PresenceEngagementsTrend from './PresenceEngagementsTrend';
import { useTranslation } from '@hooks/useTranslation';

const Wrapper = styled('div')({
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
    marginBottom: 16,
    '@media (max-width: 1366px)': {
        gridTemplateColumns: '100%',
    },
});

const Presence = memo(() => {
    const { t } = useTranslation();
    const firstRender = useRef(true);

    const [filter, setFilter] = useState({
        labels: [],
        companies: [],
        platforms: [],
        timeframe: {
            start: subtractYears(new Date(), 1),
            end: new Date(),
        },
        grouping: GroupByFilter.Months,
    });
    const [filterNumber, setFilterNumber] = useState(0);
    const [filterOpened, setFiltersOpened] = useState(false);

    const companies = useSelector(state => state.account?.account?.companies || []);
    const { location: labels, locationLoading: labelsLoading } = useSelector(state => state.labels);
    const { presenceData, presenceLoading } = useSelector(state => state.analytics, shallowEqual);
    const language = useSelector(state => state.authentication.user?.language, shallowEqual);
    const dispatch = useDispatch();

    const availableCompanies = useMemo(
        () =>
            companies.filter(c =>
                c.profiles?.some(
                    p => p.type === LocationIntegrationName.Google || p.type === LocationIntegrationName.Facebook,
                ),
            ),
        [companies],
    );

    const companiesConnectedIds = useMemo(() => availableCompanies.map(c => c.companyId), [availableCompanies]);
    const { platformsConnectedIds } = useMemo(
        () => getPresenceOptions(companies, companiesConnectedIds),
        [companies, companiesConnectedIds],
    );

    const availableLabels = useMemo(
        () => getLocationLabels(labels, availableCompanies, t),
        [labels, availableCompanies],
    );

    useEffect(() => {
        if (companies.length && !labelsLoading) {
            setFilter(prev => ({
                ...prev,
                companies: companiesConnectedIds,
                platforms: platformsConnectedIds,
                labels: availableLabels.map(item => item.id),
            }));
        }
    }, [companies, labelsLoading, dispatch]);

    useEffect(() => {
        if (filter.companies.length) {
            if (firstRender.current) {
                onApplyFilters();
                firstRender.current = false;
            }
        }
    }, [filter, dispatch]);

    const onApplyFilters = () => {
        dispatch(
            analyticsActions.getAnalyticsPresence({
                companies: filter.companies,
                platforms: filter.platforms,
                from: filter.timeframe.start ? format(new Date(filter.timeframe.start), 'yyyy-MM-dd') : null,
                to: filter.timeframe.end ? format(new Date(filter.timeframe.end), 'yyyy-MM-dd') : null,
                grouping: filter.grouping,
            }),
        );
    };

    return (
        <>
            <HeaderWrapper>
                <FiltersPanel
                    setFiltersOpened={setFiltersOpened}
                    filtersNumber={filterNumber}
                    filterOpened={filterOpened}
                    companies={availableCompanies}
                    labels={availableLabels}
                    filter={filter}
                    setFilter={setFilter}
                    type="presence"
                    setFilterNumber={setFilterNumber}
                    onApplyFilters={onApplyFilters}
                    withPresence
                />
                <PrintButton />
            </HeaderWrapper>
            <AnalyticsContainer id="pl-content-analytics-presence">
                <PresenceRates data={presenceData.rates} />
                <PresenceViewsTrend data={presenceData.viewsDistribution} language={language} />
                <PresenceEngagementsTrend data={presenceData.engagementsDistribution} language={language} />
                <Wrapper>
                    <DevicesBreakdown
                        total={presenceData.rates.views}
                        data={presenceData.devicesDistribution}
                        isLoading={presenceLoading}
                    />
                    <SearchKeywordsTable data={presenceData.keywords} isLoading={presenceLoading} />
                </Wrapper>
                <PresenceTable
                    data={presenceData.locationsDistribution}
                    isLoading={presenceLoading}
                    companies={companies}
                />
            </AnalyticsContainer>
        </>
    );
});

Presence.displayName = 'Presence';

export default Presence;
