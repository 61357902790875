import { v4 as uuidv4 } from 'uuid';
import { alertConstants } from '@constants/alert';

const initialState = {
    data: [],
};

export function alert(state = initialState, action) {
    switch (action.type) {
        case alertConstants.SUCCESS:
            return {
                ...state,
                data: [
                    ...state.data,
                    {
                        id: uuidv4(),
                        message: action.message,
                        type: 'success',
                    },
                ],
            };
        case alertConstants.ERROR:
            return {
                ...state,
                data: [
                    ...state.data,
                    {
                        id: uuidv4(),
                        message: action.message,
                        type: 'error',
                    },
                ],
            };
        case alertConstants.CLEAR:
            return {
                ...state,
                data: state.data.filter(message => message.id !== action.id),
            };
        case alertConstants.CLEAR_ALL:
            return initialState;
        default:
            return state;
    }
}
