import { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import cloneDeep from 'lodash.clonedeep';
import { useFormContext } from 'react-hook-form';
import { ModalBase } from '@components/Modal';
import IconButton from '@mui/material/IconButton';
import { DeleteIcon } from 'assets/images/icons';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import Button from '@components/Button';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { LOCALISE_MODAL_LANGUAGES } from '@constants/microsites';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: '100%',
        height: 420,
        maxWidth: 652,
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
            maxWidth: 'none',
        },
    },
    '& .modal-base-content': {
        padding: 16,
        overflow: 'auto',
        maxHeight: 'calc(100% - 118px)',
    },
});

const StyledIconButton = styled(IconButton)({
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    height: 40,
    width: 40,
    padding: 2,
    gridArea: 'button',
    '&:not(:last-child)': {
        marginRight: 8,
    },
    '& svg': {
        width: '18px',
        height: '18px',
        '& path': {
            fill: 'rgba(0, 0, 0, 0.6)',
        },
    },
});

const Overline = styled(Typography)({
    fontWeight: '500 !important',
    fontSize: '10px !important',
    lineHeight: '12px !important',
    letterSpacing: '1.5px !important',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'block',
});

const LanguageWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'isSurvey',
})(({ isSurvey }) => ({
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: isSurvey ? '160px 1fr' : '160px 160px 1fr',
    marginBottom: 8,
    '@media (max-width: 768px)': {
        gridTemplateColumns: isSurvey ? '160px 1fr' : '100%',
    },
    ...(isSurvey && {
        '@media (max-width: 600px)': {
            gridTemplateColumns: '100%',
            gridGap: 0,
        },
    }),
}));

const AdditionalLanguageWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'isSurvey',
})(({ isSurvey }) => ({
    display: 'grid',
    gridGap: 16,
    gridTemplateAreas: isSurvey ? '"select input1 button"' : '"select input1 input2 button"',
    gridTemplateColumns: isSurvey ? '160px 1fr 40px' : '160px 160px 1fr 40px',
    marginBottom: 8,
    '@media (max-width: 768px)': {
        gridTemplateAreas: '"select button" "input1 input1" "input2 input2"',
        gridTemplateColumns: isSurvey ? '160px 1fr 40px' : '1fr 40px',
        gridColumnGap: 8,
        gridRowGap: 10,
    },
    ...(isSurvey && {
        '@media (max-width: 600px)': {
            gridTemplateAreas: '"select button" "input1 input1"',
            gridTemplateColumns: '1fr 40px',
        },
    }),
}));

const LocalizeModal = ({ modal, onClose, preview, setPreview, locale, currentLocale }) => {
    const { t } = useTranslation();
    const [links, setLinks] = useState({});
    const [isDirty, setIsDirty] = useState(false);
    const { setValue } = useFormContext();

    useEffect(() => {
        if (!modal.ctx) return;
        setLinks(preview.links);
    }, [modal, preview]);

    const languageOptions = LOCALISE_MODAL_LANGUAGES[locale];

    const availableLanguages = useMemo(() => {
        return languageOptions?.filter(lang => !links[modal.ctx]?.some(l => l.language === lang.value));
    }, [links, modal.ctx]);

    const onChangeDefaultLink = (e, key) => {
        const linksCopy = cloneDeep(links);
        localLinks[key] = { ...localLinks[key], [e.target.name]: e.target.value };
        linksCopy[modal.ctx] = localLinks;
        setLinks(linksCopy);
        setIsDirty(true);
    };

    const onChangeAdditionalLink = (e, key) => {
        const linksCopy = cloneDeep(links);
        localLinks[key] = { ...localLinks[key], [e.target.name]: e.target.value };
        linksCopy[modal.ctx] = localLinks;
        setLinks(linksCopy);
        setIsDirty(true);
    };
    const onAddLanguage = () => {
        if (!availableLanguages.length) return;
        const linksCopy = cloneDeep(links);
        if (modal.isSurvey) {
            localLinks.push({
                surveyId: links[modal.ctx]?.[0]?.surveyId,
                title: '',
                link: '',
                language: availableLanguages[0].value || '',
            });
        } else if (modal.isLiveChat) {
            localLinks.push({
                liveChatChannelId: links[modal.ctx]?.[0]?.liveChatChannelId,
                title: '',
                link: '',
                language: availableLanguages[0].value || '',
            });
        } else {
            localLinks.push({ title: 'Default title', link: '', language: availableLanguages[0].value || '' });
        }
        linksCopy[modal.ctx] = localLinks;
        setLinks(linksCopy);
        setIsDirty(true);
    };

    const onDeleteAdditional = index => {
        const linksCopy = cloneDeep(links);
        localLinks.splice(index, 1);
        linksCopy[modal.ctx] = localLinks;
        setLinks(linksCopy);
        setIsDirty(true);
    };

    const onSave = () => {
        setPreview({ ...preview, links: links });
        setValue('links', links, { shouldDirty: true });
        setLinks({});
        onCloseModal();
    };

    const onCloseModal = () => {
        setIsDirty(false);
        onClose();
    };

    const isValid = useMemo(
        () =>
            links[modal.ctx]?.every(
                link => !!link.title && (!!link.link || !!link.surveyId || !!link.liveChatChannelId),
            ),
        [links, modal.ctx],
    );

    if (!links[modal.ctx]) return null;

    const firstLink = links[modal.ctx].find(l => l.language === currentLocale) || links[modal.ctx][0];
    const localLinks = [firstLink, ...links[modal.ctx].filter(l => l.language !== currentLocale)];

    return (
        <Modal
            isOpen={modal.open}
            handleClose={onCloseModal}
            title={t('MicrositesPage.modalLocalizeTitle', { link: firstLink?.title || '' })}
            onPrimaryAction={onSave}
            primaryActionDisabled={!isValid || !isDirty}
            primaryActionText={t('buttons.save')}
            onSecondaryAction={onCloseModal}
            secondaryActionText={t('buttons.cancel')}
        >
            <div>
                <Overline variant="caption" sx={{ marginBottom: '16px' }}>
                    {t('MicrositesPage.defaultLanguages')}
                </Overline>
                <LanguageWrapper isSurvey={modal.isSurvey || modal.isLiveChat}>
                    <Select
                        value={firstLink?.language}
                        name="language"
                        onChange={e => onChangeDefaultLink(e, 0)}
                        label={t('MicrositesPage.language')}
                        disabled
                        fullWidth
                        options={languageOptions}
                    />
                    <Input
                        inputProps={{
                            name: 'title',
                            value: firstLink?.title,
                            onChange: e => onChangeDefaultLink(e, 0),
                        }}
                        error={!firstLink?.title}
                        helperText={!firstLink?.title ? t('validation.required') : ''}
                        required
                        label={t('MicrositesPage.linkTitle')}
                        fullWidth
                    />
                    {!modal.isSurvey && !modal.isLiveChat ? (
                        <Input
                            inputProps={{
                                name: 'link',
                                value: firstLink?.link,
                                onChange: e => onChangeDefaultLink(e, 0),
                            }}
                            label={t('MicrositesPage.link')}
                            required
                            error={!firstLink?.link}
                            helperText={!firstLink?.link ? t('validation.required') : ''}
                            fullWidth
                        />
                    ) : null}
                </LanguageWrapper>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}
                >
                    <Overline variant="caption">{t('MicrositesPage.additionalLanguages')}</Overline>
                    <Button variant="outlined" onClick={onAddLanguage}>
                        {t('buttons.addLanguage')}
                    </Button>
                </div>
                {localLinks
                    .filter(l => l.language !== firstLink.language)
                    .map((link, index) => {
                        const selectedLanguage = languageOptions.find(l => l.value === link.language);
                        const langOptions = [...(selectedLanguage ? [selectedLanguage] : []), ...availableLanguages];
                        return (
                            <AdditionalLanguageWrapper isSurvey={modal.isSurvey || modal.isLiveChat} key={index}>
                                <Select
                                    value={link?.language}
                                    name="language"
                                    onChange={e => onChangeAdditionalLink(e, index + 1)}
                                    label={t('MicrositesPage.language')}
                                    fullWidth
                                    options={langOptions}
                                    style={{ gridArea: 'select' }}
                                />
                                <Input
                                    inputProps={{
                                        name: 'title',
                                        value: link?.title,
                                        onChange: e => onChangeAdditionalLink(e, index + 1),
                                    }}
                                    required
                                    error={!link?.title}
                                    helperText={!link?.title ? t('validation.required') : ''}
                                    sx={{ gridArea: 'input1' }}
                                    label={t('MicrositesPage.linkTitle')}
                                    fullWidth
                                />
                                {!modal.isSurvey && !modal.isLiveChat ? (
                                    <Input
                                        inputProps={{
                                            name: 'link',
                                            value: link?.link,
                                            onChange: e => onChangeAdditionalLink(e, index + 1),
                                        }}
                                        required
                                        error={!link?.link}
                                        helperText={!link?.link ? t('validation.required') : ''}
                                        sx={{ gridArea: 'input2' }}
                                        label={t('MicrositesPage.link')}
                                        fullWidth
                                    />
                                ) : null}

                                <StyledIconButton onClick={() => onDeleteAdditional(index + 1)}>
                                    <DeleteIcon />
                                </StyledIconButton>
                            </AdditionalLanguageWrapper>
                        );
                    })}
            </div>
        </Modal>
    );
};

export default LocalizeModal;
