import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AccIntegrationName } from '@constants/integrations';
import { useQueryParams } from '@hooks/useQueryParams';
import { GOOGLE_ONBOARDING_OAUTH_URL } from '@constants/integrations';
import { googleIntegrationService } from '@services';
import { alertActions, accountActions, googleActions } from '@actions';
import ViewInit from './ViewInit';
import ViewGoogleEmpty from './ViewGoogleEmpty';
import ViewManual from './ViewManual';
import ViewGoogle from './ViewGoogle';
import ViewCompleted from './ViewCompleted';
import { useTranslation } from '@hooks/useTranslation';
import { Container } from './styles';

const VIEW = {
    INIT: 1,
    GOOGLE_EMPTY: 2,
    GOOGLE: 3,
    GOOGLE_RIGHTS_PROBLEM: 4,
    COMPLETED: 5,
    MANUAL: 6,
};

const GOOGLE_ERROR = {
    CANCEL: 'access_denied',
};

const SecondStep = ({ account, user }) => {
    const { t } = useTranslation();
    const [view, setView] = useState();
    const dispatch = useDispatch();
    const query = useQueryParams();
    const history = useHistory();

    const GOOGLE_CONNECTED = account?.integrations.find(i => i.type === AccIntegrationName.Google)?.isActive;

    useEffect(() => {
        const code = query.get('code');
        const error = query.get('error');
        if (code && account) {
            setView(VIEW.GOOGLE);
            connectGoogleIntegration(code);
        } else if (error === GOOGLE_ERROR.CANCEL) {
            history.replace('/onboarding');
        } else if (!view) {
            setView(account?.onboardingStatus);
        }
    }, [query, history, account]);

    const onAddManuallyClick = () => setView(VIEW.MANUAL);

    /* if locations exist and Google connected -> show user select locations view
        if Google disconnected -> try to connect
    */
    const onImportFromGoogleClick = () => {
        if (account?.onboardingStatus === VIEW.GOOGLE && view !== VIEW.GOOGLE) {
            setView(VIEW.GOOGLE);
        }

        if (!GOOGLE_CONNECTED) {
            window.location = GOOGLE_ONBOARDING_OAUTH_URL;
        } else {
            setView(VIEW.GOOGLE);
        }
    };

    // disconnect Google and try to connect again
    const onConnectAnotherAccountClick = async () => {
        try {
            await googleIntegrationService.revokeGoogleIntegration();
            const updatedAcc = await dispatch(accountActions.get());
            await dispatch(accountActions.updateAccount({ ...updatedAcc, onboardingStatus: VIEW.INIT }));
            window.location = GOOGLE_ONBOARDING_OAUTH_URL;
        } catch (error) {
            dispatch(alertActions.error(t('apiErrors.google_access_revoke_error')));
        }
    };

    const connectGoogleIntegration = async code => {
        try {
            await googleIntegrationService.authenticateGoogleIntegration({
                accountId: account?.id,
                code,
                redirectUri: `${window.location.origin}/onboarding`,
            });
            history.replace('/onboarding');
            const locations = await dispatch(googleActions.get());
            if (locations.length === 0) {
                await dispatch(accountActions.updateAccount({ ...account, onboardingStatus: VIEW.GOOGLE_EMPTY }));
                setView(VIEW.GOOGLE_EMPTY);
            } else {
                await dispatch(accountActions.updateAccount({ ...account, onboardingStatus: VIEW.GOOGLE }));
            }
        } catch (error) {
            history.replace('/onboarding');
            setView(VIEW.INIT);
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
    };

    return (
        <Container isNotMain={view === VIEW.INIT || view === VIEW.GOOGLE_EMPTY || view === VIEW.GOOGLE_RIGHTS_PROBLEM}>
            {(view === VIEW.INIT || view === VIEW.GOOGLE_RIGHTS_PROBLEM) && (
                <ViewInit onImportFromGoogleClick={onImportFromGoogleClick} onAddManuallyClick={onAddManuallyClick} />
            )}
            {view === VIEW.GOOGLE_EMPTY && (
                <ViewGoogleEmpty
                    onConnectAnotherAccountClick={onConnectAnotherAccountClick}
                    onAddManuallyClick={onAddManuallyClick}
                />
            )}
            {view === VIEW.MANUAL && <ViewManual onImportFromGoogleClick={onImportFromGoogleClick} user={user} />}
            {view === VIEW.GOOGLE && (
                <ViewGoogle
                    onConnectAnotherAccountClick={onConnectAnotherAccountClick}
                    onAddManuallyClick={onAddManuallyClick}
                    user={user}
                    account={account}
                />
            )}
            {view === VIEW.COMPLETED && <ViewCompleted />}
        </Container>
    );
};

export default SecondStep;
