import { styled } from '@mui/system';
import { Typography } from '@components/Typography';

const HeaderWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    padding: 24,
    width: '100%',
    '& svg': {
        width: 100,
        height: 100,
    },
    '@media (max-width: 700px)': {
        flexDirection: 'column',
        gap: 0,
    },
});

const HeaderTextWrapper = styled('div')({
    width: 'inherit',
    '@media (max-width: 700px)': {
        display: 'flex',
        flexDirection: 'column',
    },
});

const Footer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 8,
    '@media (max-width: 700px)': {
        flexDirection: 'column',
    },
});

const ControlsWrapper = styled('div')({
    display: 'flex',
    gap: 8,
    '& a': {
        textDecoration: 'none',
    },
    '@media (max-width: 700px)': {
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
});

const PageHeader = ({ children, icon, title, subtitle, metrics, styles }) => {
    return (
        <HeaderWrapper style={styles}>
            {icon}
            <HeaderTextWrapper>
                <Typography
                    variant="h6"
                    color="textPrimary"
                    sx={{
                        '@media (max-width: 700px)': {
                            textAlign: 'center !important',
                        },
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        marginTop: '4px',
                        marginBottom: '16px',
                        color: 'rgba(0, 0, 0, 0.6)',
                        '@media (max-width: 700px)': {
                            textAlign: 'center !important',
                        },
                    }}
                >
                    {subtitle}
                </Typography>
                <Footer>
                    <ControlsWrapper>{children}</ControlsWrapper>
                    {metrics}
                </Footer>
            </HeaderTextWrapper>
        </HeaderWrapper>
    );
};

export default PageHeader;
