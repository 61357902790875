import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@components/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@components/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GoogleIcon, FacebookIcon, InstagramIcon } from 'assets/images/integrations';
import { Table } from '@components/Table';
import { POST_STATUS, POST_CHANNEL } from '@constants/social-posts';
import { formatCompanies } from '@helpers/companies';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { useTranslation } from '@hooks/useTranslation';

const StyledTable = styled(Table)({
    '& .MuiTableBody-root .MuiTableCell-root .MuiIconButton-root:not(:first-of-type)': {
        marginLeft: 8,
    },
    '& .MuiTableBody-root .MuiTableCell-body:last-child': {
        padding: 4,
    },
});

const getSocialPostPlatformIcon = type => {
    switch (type) {
        case POST_CHANNEL.GOOGLE:
            return <GoogleIcon width={18} height={18} />;
        case POST_CHANNEL.FACEBOOK:
            return <FacebookIcon width={18} height={18} />;
        case POST_CHANNEL.INSTAGRAM:
            return <InstagramIcon width={18} height={18} />;
        default:
            return null;
    }
};

const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.title')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.locations')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('SocialPosts.socialChannels')}
                </Typography>
            ),
        },
        {
            key: 4,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.scheduledTime')}
                </Typography>
            ),
        },
        {
            key: 5,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.status')}
                </Typography>
            ),
        },
        { key: 6, component: <Typography align="left" variant="subtitle2" /> },
    ];
};

const GetTableBody = (data, language, companies, onClick) => {
    const { t } = useTranslation();
    const getStatus = status => {
        switch (status) {
            case POST_STATUS.SCHEDULED:
                return t('common.scheduled');
            case POST_STATUS.PUBLISHED:
                return t('common.published');
            case POST_STATUS.FAILED:
                return t('common.unpublished');
            case POST_STATUS.DRAFT:
                return t('common.draft');
            default:
                return '';
        }
    };
    return data.map(post => {
        const allCompanies = companies.length === post.companies.length;
        return [
            {
                component: (
                    <Typography
                        variant="subtitle2"
                        align="left"
                        sx={{ width: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {post.title}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left" sx={{ width: '120px' }}>
                        {allCompanies ? t('common.all') : formatCompanies(companies, post.companies, 30)}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left" sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {post.platforms.map(platform => (
                            <span key={platform} style={{ width: 18, height: 18 }}>
                                {getSocialPostPlatformIcon(platform)}
                            </span>
                        ))}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left" sx={{ width: 90 }}>
                        {format(new Date(post.scheduledTime), 'dd MMM yyyy, HH:mm', {
                            locale: I18N_DATE_FORMAT[language],
                        })}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {getStatus(post.status)}
                    </Typography>
                ),
            },
            {
                component: (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Tooltip title={t('common.view')} placement="top">
                            <IconButton onClick={() => onClick(post, 'view')}>
                                <VisibilityIcon viewBox="0 0 22 22" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('common.edit')} placement="top">
                            <IconButton onClick={() => onClick(post, 'edit')}>
                                <EditIcon viewBox="0 0 22 22" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('common.delete')} placement="top">
                            <IconButton onClick={() => onClick(post, 'delete')}>
                                <DeleteIcon viewBox="0 0 22 22" />
                            </IconButton>
                        </Tooltip>
                    </div>
                ),
            },
        ];
    });
};

const SocialPostsTable = ({
    rowsPerPageRequests,
    pageRequests,
    onPageChange,
    onRowsPerPageChange,
    onClickSocialPost,
}) => {
    const { t } = useTranslation();
    const { data, loading, totalCount, pages } = useSelector(state => state.socialPosts);
    const language = useSelector(state => state.authentication.user?.language);
    const companies = useSelector(state => state.account?.account?.companies || []);

    return (
        <TableContainer>
            <StyledTable
                withHover
                columnNames={GetTableHeader()}
                rows={GetTableBody(data, language, companies, onClickSocialPost)}
                isDataLoading={loading}
                withPagination
                paginationProps={{
                    colSpan: 6,
                    count: totalCount,
                    rowsPerPage: rowsPerPageRequests,
                    page: pageRequests,
                    onPageChange,
                    onRowsPerPageChange,
                    labelDisplayedRows: ({ from, to, count }) =>
                        `${from}–${to} ${t('Table.of')} ${count !== -1 ? count : `${t('Table.moreThan')} ${to}`}`,
                    labelRowsPerPage: <span>{t('Table.rowsPerPage')}</span>,
                    pages,
                }}
            />
        </TableContainer>
    );
};

export default SocialPostsTable;
