export const updateState = (prevState, newValue, isAllSelected) => {
    if (isAllSelected) return newValue;
    return newValue.length > prevState.length ? newValue.filter(value => prevState.includes(value)) : newValue;
};

export const getChipsLabel = value => {
    if (!value) return '';
    const label = value?.map(item => item.label).join(', ');
    const countString = value?.length > 1 ? `(${value?.length})` : '';
    return `${countString} ${label}`;
};

export const getAvailableCompanies = (companies, selectedLabels) => {
    const companiesWithLabels = companies
        .filter(item => item.labels.some(l => selectedLabels?.includes(l)))
        .map(item => item.companyId);
    const companiesWithoutLabels = companies.filter(item => item.labels?.length === 0).map(item => item.companyId);
    const availableCompanies = selectedLabels?.includes('none')
        ? [...companiesWithLabels, ...companiesWithoutLabels]
        : companiesWithLabels;
    return availableCompanies;
};
