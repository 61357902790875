import { useEffect } from 'react';
import { Controller, useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { formatISO } from 'date-fns';
import { useMediaQuery } from '@mui/material';
import { Skeleton } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@components/Typography';
import { Input } from '@components/Input';
import { Switch } from '@components/Switch';
import { Select } from '@components/Select';
import SpecialHoursSelection from '@features/SpecialHoursSelection';
import { googleIntegrationService, facebookIntegrationService } from '@services';
import { googleActions, facebookActions, alertActions, accountActions } from '@actions';
import { LocationIntegrationName } from '@constants/integrations';
import { STORE_TYPE } from '@constants/profiles';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { useTranslation } from '@hooks/useTranslation';
import CategoriesForm from './CategoriesForm';
import { Modal, Content } from './styles';

const ManageProfileModal = ({ type, open, onCloseModal, selectedCompany, setDisconnectModalOpen }) => {
    const isMobile = useMediaQuery('@media (max-width: 600px)');
    const { t } = useTranslation();
    const isFacebookModal = type === LocationIntegrationName.Facebook;
    const isGoogleModal = type === LocationIntegrationName.Google;

    const data = isGoogleModal
        ? useSelector(state => state.google.listing)
        : useSelector(state => state.facebook.listing);
    const loadingGoogle = useSelector(state => state.google.loadingListing);
    const loadingFacebook = useSelector(state => state.facebook.loadingListing);
    const loading = isFacebookModal ? loadingFacebook : loadingGoogle;

    const dispatch = useDispatch();

    const profileId = selectedCompany?.profiles?.find(profile => profile.type === type)?.profileId;

    const ProfileSchema = yup.object().shape({
        primaryCategory: isGoogleModal ? yup.array().min(1, 'validation.required') : yup.array().nullable(),
        storeLocationType: yup.string().nullable(),
        selectedStoreLocation: yup.string().when('storeLocationType', {
            is: STORE_TYPE.CONNECT_STORE,
            then: yup.string().required('validation.required'),
            otherwise: yup.string().nullable(),
        }),
    });

    const resolver = useYupValidationResolver(ProfileSchema);

    const {
        setValue,
        control,
        handleSubmit,
        watch,
        reset,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            name: '',
            primaryCategory: [],
            additionalCategories: [],
            specialHours: [],
            storeLocations: [],
            storeLocationType: STORE_TYPE.MAIN_PAGE,
            selectedStoreLocation: '',
            syncEnabled: true,
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'additionalCategories',
    });

    useEffect(() => {
        if (selectedCompany) {
            isGoogleModal && dispatch(googleActions.getListing(profileId));
            isFacebookModal && dispatch(facebookActions.getListing(profileId));
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (Object.keys(data).length > 0 && isGoogleModal) {
            setValue('name', data?.locationName || '');
            setValue('primaryCategory', [data.primaryCategory]);
            setValue('additionalCategories', data.additionalCategories.map(item => ({ category: [item] })) || []);
            setValue('syncEnabled', data.syncEnabled);
            if (data.specialHours?.length) {
                setValue(
                    'specialHours',
                    data.specialHours.map(day => {
                        return {
                            ...day,
                            date: new Date(day.date),
                        };
                    }),
                );
            } else {
                setValue('specialHours', []);
            }
        }
        if (Object.keys(data).length > 0 && isFacebookModal) {
            setValue('name', data?.locationName || '');
            setValue('storeLocations', data?.storeLocations || []);
            setValue('storeLocationType', data?.type?.toString() || '1');
            setValue('selectedStoreLocation', data?.selectedStoreLocation?.id || '');
            setValue('syncEnabled', data.syncEnabled);
        }
    }, [data, setValue]);

    const onSubmit = async values => {
        const googleValues = {
            primaryCategory: values.primaryCategory[0],
            additionalCategories: values.additionalCategories.map(item => item.category[0]),
            specialHours:
                values.specialHours.length > 0
                    ? values.specialHours.map(day => ({
                          ...day,
                          date: formatISO(day.date, { representation: 'date' }),
                      }))
                    : null,
            syncEnabled: values.syncEnabled,
        };
        const facebookValues = {
            locationName: values.name,
            type: +values.storeLocationType,
            selectedStoreLocation:
                data?.storeLocations?.find(location => location.id === values.selectedStoreLocation) || null,
            syncEnabled: values.syncEnabled,
            locationId: selectedCompany?.companyId,
        };
        try {
            if (isGoogleModal) {
                await googleIntegrationService.updateLocationListing(profileId, googleValues);
            }
            if (isFacebookModal) {
                await facebookIntegrationService.updateLocationListing(profileId, facebookValues);
                await dispatch(accountActions.get());
            }
            dispatch(alertActions.success(t('alertMessages.editSuccess')));
            onClose();
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.editFail')));
            }
        }
    };

    const onClose = () => {
        reset();
        onCloseModal();
        dispatch(facebookActions.setListing({}));
        dispatch(googleActions.setListing({}));
    };

    const storeType = watch('storeLocationType');

    const RADIO_OPTIONS = [
        {
            label: t('Listings.connectMainPage'),
            value: STORE_TYPE.MAIN_PAGE,
            disabled: false,
        },
        // {
        //     label: t('Listings.createStoreLocation'),
        //     value: STORE_TYPE.CREATE_STORE,
        //     disabled: false,
        // },
        {
            label: t('Listings.connectStoreLocation'),
            value: STORE_TYPE.CONNECT_STORE,
            disabled: !data?.storeLocations?.length,
        },
    ];

    return (
        <Modal
            isOpen={open}
            handleClose={onClose}
            title={isGoogleModal ? t('Listings.modalTitleGoogle') : t('Listings.modalTitleFacebook')}
            onPrimaryAction={handleSubmit(onSubmit)}
            primaryActionText={t('buttons.saveChanges')}
            primaryActionDisabled={isSubmitting || !isDirty || !isValid}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
            onAdditionalAction={() => setDisconnectModalOpen(true)}
            additionalTextButton={t('buttons.disconnect')}
            additionalActionDisabled={isSubmitting}
        >
            {data && !loading && (
                <Content>
                    <Controller
                        control={control}
                        name="name"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('SettingsCompanies.locationName')}
                                required
                                disabled
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                sx={{ width: isMobile ? '100%' : '50%' }}
                            />
                        )}
                    />
                    {isGoogleModal && (
                        <>
                            <Typography variant="subtitle1" color="textPrimary" sx={{ marginBottom: '16px' }}>
                                {t('Listings.categories')}
                            </Typography>
                            <FormProvider {...{ control, fields, append, remove, setValue, isSubmitting }}>
                                <CategoriesForm />
                            </FormProvider>
                            <Typography variant="subtitle1" color="textPrimary" sx={{ marginBottom: '8px' }}>
                                {t('Listings.specialHours')}
                            </Typography>
                            <Controller
                                control={control}
                                name="specialHours"
                                render={({ field, ref }) => (
                                    <SpecialHoursSelection
                                        {...field}
                                        data={field?.value}
                                        ref={ref}
                                        onChangeHours={value => {
                                            setValue('specialHours', value, { shouldDirty: true });
                                        }}
                                    />
                                )}
                            />
                        </>
                    )}
                    {isFacebookModal && (
                        <Controller
                            control={control}
                            name="storeLocationType"
                            render={({ field, ref }) =>
                                RADIO_OPTIONS.map((item, index) => (
                                    <RadioGroup key={index} {...field} ref={ref}>
                                        <FormControlLabel
                                            value={`${item.value}`}
                                            control={
                                                <Radio
                                                    disabled={item.disabled}
                                                    sx={{
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                        padding: '6px',
                                                        '&.Mui-checked': { color: '#1F4C5C' },
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography variant="body2" sx={{ marginLeft: '10px' }}>
                                                    {item.label}
                                                </Typography>
                                            }
                                            disabled={item.disabled}
                                            sx={{ marginLeft: '-3px' }}
                                        />
                                    </RadioGroup>
                                ))
                            }
                        />
                    )}
                    {isFacebookModal && storeType === STORE_TYPE.CONNECT_STORE && (
                        <Controller
                            control={control}
                            name="selectedStoreLocation"
                            render={({ field, fieldState: { error }, ref }) => (
                                <Select
                                    {...field}
                                    ref={ref}
                                    onChange={({ target: { value } }) => {
                                        setValue('selectedStoreLocation', value, {
                                            shouldDirty: true,
                                            shouldValidate: true,
                                        });
                                    }}
                                    options={
                                        data?.storeLocations?.map(location => ({
                                            value: location.id,
                                            label: location.name,
                                        })) || []
                                    }
                                    required
                                    label={t('Listings.storeLocation')}
                                    fullWidth
                                    dropdownStyles={{ maxHeight: '300px' }}
                                    error={!!error}
                                    helperText={error ? t(error.message) : null}
                                    sx={{ width: isMobile ? '100%' : '50%', marginTop: '8px' }}
                                />
                            )}
                        />
                    )}
                    <Controller
                        control={control}
                        name="syncEnabled"
                        render={({ field, ref }) => (
                            <Switch
                                {...field}
                                ref={ref}
                                sx={{ marginBottom: '16px' }}
                                checked={field.value}
                                handleChange={event => {
                                    setValue('syncEnabled', event.target.checked, {
                                        shouldDirty: true,
                                    });
                                }}
                                label={t('Listings.enableProfileSync')}
                            />
                        )}
                    />
                </Content>
            )}
            {loading && (
                <div style={{ display: 'grid', rowGap: '32px' }}>
                    <Skeleton variant="rounded" width="50%" height={40} />
                    <Skeleton variant="rounded" width="100%" height={100} />
                    <Skeleton variant="rounded" width="50%" height={40} />
                    <Skeleton variant="rounded" width="50%" height={40} />
                </div>
            )}
        </Modal>
    );
};

export default ManageProfileModal;
