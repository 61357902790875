import { memo, useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import { RadioButtonsGroup } from '@components/RadioButtonsGroup';
import { analyticsActions } from '@actions';
import FiltersPanel from './FiltersPanel';
import ConversionTable from './ConversionTable';
import { PrintButton } from '../Common/PrintButton';
import { subtractYears } from '@helpers/dates';
import ConversionRates from './ConversionRates';
import { getLocationLabels } from '@helpers/locationLabels';
import { useTranslation } from '@hooks/useTranslation';
import { AnalyticsContainer, HeaderWrapper } from '../styles';

const FiltersWrapperCss = styled(HeaderWrapper)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const RadioButtonsGroupCss = styled(RadioButtonsGroup)({
    width: '160px !important',
    height: 'fit-content !important',
    '& .MuiFormControlLabel-root': {
        height: '32px !important',
    },
});

const Conversion = memo(() => {
    const { t } = useTranslation();
    const firstRender = useRef(true);

    const [filter, setFilter] = useState({
        labels: [],
        companies: [],
        timeframe: {
            start: subtractYears(new Date(), 1),
            end: new Date(),
        },
    });
    const [filterNumber, setFilterNumber] = useState(0);
    const [filterOpened, setFiltersOpened] = useState(false);
    const [conversionView, setConversionView] = useState(0);

    const companies = useSelector(state => state.account?.account?.companies || []);
    const { location: labels, locationLoading: labelsLoading } = useSelector(state => state.labels);
    const language = useSelector(state => state.authentication.user?.language, shallowEqual);
    const { surveysConversionData, qrConversionData, conversionLoading } = useSelector(
        state => state.analytics,
        shallowEqual,
    );
    const dispatch = useDispatch();

    const availableLabels = useMemo(() => getLocationLabels(labels, companies, t), [labels, companies]);

    useEffect(() => {
        if (companies.length && !labelsLoading) {
            setFilter(prev => ({
                ...prev,
                companies: companies.map(item => item.companyId),
                labels: availableLabels.map(item => item.id),
            }));
        }
    }, [companies, labelsLoading, dispatch]);

    const onApplyFilters = () => {
        dispatch(
            analyticsActions.getAnalyticsConversion({
                companies: filter.companies,
                from: filter.timeframe.start ? format(new Date(filter.timeframe.start), 'yyyy-MM-dd') : null,
                to: filter.timeframe.end ? format(new Date(filter.timeframe.end), 'yyyy-MM-dd') : null,
            }),
        );
    };

    useEffect(() => {
        if (filter.companies.length) {
            if (firstRender.current) {
                onApplyFilters();
                firstRender.current = false;
            }
        }
    }, [filter, dispatch]);

    return (
        <>
            <FiltersWrapperCss>
                <FiltersPanel
                    setFiltersOpened={setFiltersOpened}
                    filtersNumber={filterNumber}
                    filterOpened={filterOpened}
                    companies={companies}
                    labels={availableLabels}
                    filter={filter}
                    setFilter={setFilter}
                    type="conversion"
                    setFilterNumber={setFilterNumber}
                    onApplyFilters={onApplyFilters}
                />
                <div style={{ flexShrink: 0, alignSelf: 'start' }}>
                    <RadioButtonsGroupCss
                        id="pl-radiobuttons-conversion"
                        value={conversionView}
                        onChange={setConversionView}
                        options={[
                            { value: 0, label: 'SMS/Email' },
                            { value: 1, label: 'QR' },
                        ]}
                    />
                    <PrintButton style={{ marginLeft: '20px' }} />
                </div>
            </FiltersWrapperCss>
            <AnalyticsContainer id="pl-content-analytics-conversion">
                {conversionView === 1 ? (
                    <>
                        <ConversionRates
                            language={language}
                            data={qrConversionData.overall}
                            isLoading={conversionLoading}
                            type="qr"
                        />
                        <ConversionTable isLoading={conversionLoading} data={qrConversionData} type="qr" />
                    </>
                ) : (
                    <>
                        <ConversionRates
                            language={language}
                            data={surveysConversionData.overall}
                            isLoading={conversionLoading}
                            type="surveys"
                        />
                        <ConversionTable isLoading={conversionLoading} data={surveysConversionData} type="surveys" />
                    </>
                )}
            </AnalyticsContainer>
        </>
    );
});

Conversion.displayName = 'Conversion';

export default Conversion;
