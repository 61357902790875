import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';
import { Typography } from '@components/Typography';
import ContactChip from '@pages/ContactsPage/ContactChip';
import Autocomplete from '@components/Autocomplete';
import { alertActions, contactsActions, conversationsActions } from '@actions';
import { contactsService } from '@services';
import { useTranslation } from '@hooks/useTranslation';
import { useThrottleValue } from '@hooks/useThrottleValue';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 564,
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        padding: 20,
        '& .MuiFormControl-root': {
            '& label': {
                textTransform: 'capitalize',
            },
        },
    },
});

const Content = styled('div')({
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateAreas: '"select1" "select2"',
    gridTemplateColumns: '100%',
});

const StyledAutocomplete = styled(Autocomplete)({
    // only for merge
    '& .MuiOutlinedInput-notchedOutline legend span': {
        paddingRight: '20px',
    },
    // only for merge
    '& .MuiPaper-root': {
        flexWrap: 'nowrap',
    },
});

const MergeContactsModal = ({
    modal,
    setModal,
    closeEditModal,
    refetchCallBack,
    withRefetchContacts,
    withRefetchConversations,
    withRefetchSurveys,
    withRefetchReviews,
}) => {
    const { t } = useTranslation();

    const [searchQuery, setSearchQuery] = useState('');

    const { contact, data, searchedContacts } = useSelector(state => state.contacts);
    const dispatch = useDispatch();

    const throttledSearch = useThrottleValue(searchQuery, 1000);

    useEffect(() => {
        if (throttledSearch.length === 0) {
            dispatch(contactsActions.resetSearchedContacts());
            return;
        }
        if (throttledSearch.length >= 2) {
            dispatch(contactsActions.getSearchedContacts({ searchQuery: throttledSearch }, 0, 10));
        }
    }, [throttledSearch]);

    const {
        getValues,
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { isDirty, isSubmitting },
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            primaryContactId: contact.id,
            secondaryContactId: '',
        },
    });

    const onCloseModal = () => {
        setModal(false);
        dispatch(contactsActions.resetSearchedContacts());
        reset();
        closeEditModal();
    };

    const onSubmit = async () => {
        const values = getValues();
        try {
            const newContact = await contactsService.mergeContacts(values);
            dispatch(alertActions.success(t('ContactsPage.mergeSuccess')));
            if (withRefetchContacts || withRefetchSurveys || withRefetchReviews) {
                refetchCallBack && refetchCallBack();
            }
            if (newContact) {
                dispatch(contactsActions.setContact(newContact));
            }
            if (withRefetchConversations) {
                dispatch(conversationsActions.setRequiresRefetch(true));
            }
            onCloseModal();
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('ContactsPage.mergeFail')));
            }
        }
    };

    const filteredSearchedContacts = useMemo(() => {
        return searchedContacts.filter(contact => contact.id !== getValues().primaryContactId);
    }, [searchedContacts]);

    return (
        <Modal
            isOpen={modal}
            handleClose={onCloseModal}
            title={t('ContactsPage.mergeContactsTitle')}
            subtitle={
                <Typography variant="caption" color="textSecondary">
                    {t('ContactsPage.mergeContactsSubtitle')}
                </Typography>
            }
            onPrimaryAction={handleSubmit(onSubmit)}
            primaryActionText={t('buttons.mergeContacts')}
            primaryActionDisabled={isSubmitting || !isDirty}
            onSecondaryAction={onCloseModal}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content>
                <Controller
                    control={control}
                    name="primaryContactId"
                    render={({ field, ref }) => (
                        <div style={{ gridArea: 'select1' }}>
                            <StyledAutocomplete
                                {...field}
                                ref={ref}
                                label={t('ContactsPage.mergeContactPrimary')}
                                options={data}
                                value={[contact]}
                                readOnly
                                ValueComponent={ContactChip}
                                inputProps={{
                                    style: { display: field?.value?.length > 0 ? 'none' : 'block' },
                                }}
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="secondaryContactId"
                    render={({ field, ref }) => (
                        <div style={{ gridArea: 'select2' }}>
                            <StyledAutocomplete
                                {...field}
                                ref={ref}
                                value={null}
                                label={t('ContactsPage.mergeContactSecondary')}
                                options={filteredSearchedContacts}
                                ValueComponent={ContactChip}
                                OptionComponent={ContactChip}
                                inputProps={{
                                    value: searchQuery,
                                    onChange: e => setSearchQuery(e.target.value),
                                    style: { display: field?.value?.length > 0 ? 'none' : 'block' },
                                }}
                                onChangeAutoComplete={items => {
                                    setValue('secondaryContactId', items.length ? items[0].id : '', {
                                        shouldDirty: true,
                                    });
                                }}
                            />
                        </div>
                    )}
                />
            </Content>
        </Modal>
    );
};

export default MergeContactsModal;
