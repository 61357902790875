import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';
import TimeframePicker from '@components/TimeframePicker';

export const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 564,
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

export const Content = styled('div')({
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateAreas: '"input1 input1" "select0 select1" "select2 input3" "input2 input2" "select3 input4"',
    gridTemplateColumns: 'calc(50% - 12px) calc(50% - 12px)',
    '@media (max-width: 600px)': {
        gridTemplateAreas: '"input1" "select0" "select1"  "select2" "input3" "input2" "select3"',
        gridTemplateColumns: '100%',
        gridGap: '16px',
    },
});

export const StyledTimeframePicker = styled(TimeframePicker)({
    gridArea: 'input3',
    '& .react-datepicker': {
        display: 'flex',
    },
});
