import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const vtexIntegrationService = {
    connectIntegration,
    disconnectIntegration,
};

function connectIntegration(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/integrations/vtex/authenticate`, requestOptions).then(handleResponse);
}

function disconnectIntegration() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/integrations/vtex/revoke-access`, requestOptions).then(handleResponse);
}
