import { notificationsConstants } from '@constants/notifications';
import { notificationsService } from '@services';

function request() {
    return { type: notificationsConstants.NOTIFICATIONS_FETCH };
}

function success(data) {
    return { type: notificationsConstants.NOTIFICATIONS_SUCCESS, data };
}

function failure() {
    return { type: notificationsConstants.NOTIFICATIONS_ERROR };
}

function successLoadMore(data) {
    return { type: notificationsConstants.NOTIFICATIONS_LOAD_MORE_SUCCESS, data };
}

const get = (take, skip) => {
    return dispatch => {
        dispatch(request());
        notificationsService.get(take, skip).then(
            data => dispatch(success(data)),
            () => dispatch(failure()),
        );
    };
};

const loadMore = (take, skip) => {
    return dispatch => {
        notificationsService.get(take, skip).then(
            data => dispatch(successLoadMore(data)),
            () => dispatch(failure()),
        );
    };
};

function set(data) {
    return { type: notificationsConstants.NOTIFICATIONS_SET, data };
}

export const notificationsActions = {
    get,
    set,
    loadMore,
};
