import { memo, useMemo } from 'react';
import merge from 'lodash.merge';
import { Line } from 'react-chartjs-2';

const createChartOptions = (legend, labels, optionsOverride) => {
    const defaultOptions = {
        elements: {
            point: {
                radius: 0,
            },
            line: {
                borderWidth: 2,
                tension: 0.5,
                pointStyle: 'circle',
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            chartAreaBorder: {
                borderColor: 'rgba(0, 0, 0, 0.5)',
                borderWidth: 1,
            },
            legend: {
                display: true,
                position: 'bottom',
                align: 'start',
                reverse: true,
                onClick: () => null,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    font: {
                        family: 'Rubik',
                        size: 10,
                        weight: 400,
                    },
                },
            },
            tooltip: {
                displayColors: false,
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 1)',
                callbacks: {
                    title: function () {},
                    label: function (context) {
                        return `${context.dataset.label} - ${context.formattedValue}`;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    tickLength: 0,
                    drawTicks: false,
                    drawBorder: true,
                    drawOnChartArea: false,
                    borderColor: 'rgba(0, 0, 0, 0.3)',
                },
                ticks: {
                    autoSkip: false,
                    padding: 8,
                    font: {
                        family: 'Rubik',
                        size: 10,
                        weight: 400,
                    },
                    callback: function (_, index) {
                        const parts = labels[index].toUpperCase().split(' ');
                        return `${parts[0]} ${parts[1].substring(0, 3).toUpperCase()}`;
                    },
                },
            },
            y: {
                grid: {
                    tickLength: 0,
                    drawTicks: false,
                    drawBorder: true,
                    borderDash: [5, 5],
                    borderColor: 'rgba(0, 0, 0, 0.3)',
                },
                ticks: {
                    font: {
                        family: 'Rubik',
                        size: 10,
                    },
                    color: 'rgba(0, 0, 0, 0.6)',
                    padding: 10,
                },
                title: {
                    display: true,
                    position: 'left',
                    align: 'center',
                    font: {
                        family: 'Rubik',
                        size: 10,
                        weight: 500,
                    },
                    color: 'rgba(0, 0, 0, 0.6)',
                    text: legend.toUpperCase(),
                    padding: {
                        bottom: 14,
                    },
                },
            },
        },
    };
    return merge({}, defaultOptions, optionsOverride);
};

const chartAreaBorder = {
    id: 'chartAreaBorder',
    beforeDraw(chart) {
        const { ctx } = chart;
        ctx.save();

        ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.lineWidth = chart.config.options.scales.x.grid.lineWidth || 3;

        ctx.beginPath();
        ctx.moveTo(chart.chartArea.right, chart.chartArea.top);
        ctx.lineTo(chart.chartArea.right, chart.chartArea.bottom);
        ctx.stroke();

        ctx.restore();
    },
};

const LineChart = memo(({ data, labels, legend = '', optionsProps = {}, withBorderPlugin = true }) => {
    const options = useMemo(() => createChartOptions(legend, labels, optionsProps), [labels, legend, optionsProps]);

    return <Line data={data} options={options} plugins={[...(withBorderPlugin ? [chartAreaBorder] : [])]} />;
});

LineChart.displayName = 'LineChart';

export default LineChart;
