import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';

export const Modal = styled(ModalBase, {
    shouldForwardProp: prop => prop !== 'showContactList',
})(({ showContactList }) => ({
    '& .modal-base-paper': {
        outline: 'none',
        width: showContactList ? 764 : 564,
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
}));

export const FileInputContainer = styled('div')({
    display: 'flex',
    gap: 8,
    marginTop: 8,
    width: '100%',
    height: 32,
    alignItems: 'center',
});
