import { accountConstants } from '@constants/account';

const initialState = {
    loading: false,
    message: '',
    onboarding: {
        googleConnected: true,
        facebookConnected: true,
        channelsCreated: true,
        surveyTemplatedCreated: true,
        autoRepliesCreated: true,
        usersCreated: true,
    },
    locations: [],
    totalCount: 0,
    pages: 1,
    locationsLoading: false,
};

export function account(state = initialState, action) {
    switch (action.type) {
        case accountConstants.ACCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
                message: '',
            };
        case accountConstants.ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                account: action.account,
                message: '',
            };
        case accountConstants.ACCOUNT_ERROR:
            return {
                ...state,
                message: action.message,
            };
        case accountConstants.ACCOUNT_UPDATE_QUOTA:
            return {
                ...state,
                account: { ...state.account, ...action.data },
            };
        case accountConstants.DASHBOARD_ONBOARDING_SUCCESS:
            return {
                ...state,
                onboarding: action.data,
            };
        case accountConstants.LOCATIONS_FETCH:
            return { ...state, locationsLoading: true };
        case accountConstants.LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: action.data.items,
                locationsLoading: false,
                totalCount: action.data.totalCount,
                pages: action.data.pages,
            };
        case accountConstants.LOCATIONS_ERROR:
            return { ...state, locationsLoading: false };
        default:
            return state;
    }
}
