import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Typography } from '@components/Typography';
import { Input } from '@components/Input';
import PhoneInput from '@components/PhoneInput';
import { Select } from '@components/Select';
import Button from '@components/Button';
import { accountActions, userActions, alertActions } from '@actions';
import { userService } from '@services';
import { LOCALE } from '@constants/locale';
import { Industry } from '@constants/account';
import { useTranslation } from '@hooks/useTranslation';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Footer, Container, Header, Form } from './styles';

const MAP_PHONES = {
    [LOCALE.INTERNATIONAL]: '1',
    [LOCALE.UNITED_KINGDOM]: '44',
    [LOCALE.SPAIN]: '34',
    [LOCALE.UKRAINE]: '380',
    [LOCALE.PORTUGAL]: '351',
    [LOCALE.BRAZIL]: '55',
    [LOCALE.POLAND]: '48',
    [LOCALE.GERMANY]: '49',
};

const WelcomeForm = () => {
    const { t } = useTranslation();

    const [isSubmitting, setSubmitting] = useState(false);
    const account = useSelector(state => state.account?.account, shallowEqual);
    const user = useSelector(state => state.authentication.user, shallowEqual);

    const dispatch = useDispatch();

    const OnboardingFormSchema = yup.object().shape({
        firstName: yup.string().required('validation.required'),
        lastName: yup.string().required('validation.required'),
        phoneNumber: yup.string().validatePhone('validation.wrongPhoneFormat', true).required('validation.required'),
        industry: yup.number().required('validation.required').nullable(true),
    });

    const resolver = useYupValidationResolver(OnboardingFormSchema);

    const {
        getValues,
        control,
        setValue,
        formState: { isValid },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            industry: null,
        },
    });

    useEffect(() => {
        setValue('phoneNumber', MAP_PHONES[account?.locale], { shouldDirty: true });
    }, [setValue, account?.locale]);

    const industryOptions = Object.entries(Industry).map(([key, value]) => ({ value, label: t(`industry.${key}`) }));

    const handleSubmit = async () => {
        const { firstName, lastName, phoneNumber, industry } = getValues();
        setSubmitting(true);
        try {
            const updatedUser = await userService.updatePersonalSettings({
                ...user,
                firstName,
                lastName,
                phoneNumber: `+${phoneNumber}`,
                systemLanguage: user?.language,
            });
            dispatch(userActions.setUser(updatedUser));
            await dispatch(accountActions.updateAccount({ ...account, industry, onboardingStatus: 1 }));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
        setSubmitting(false);
    };

    return (
        <Container>
            <Header>
                <Typography variant="h6">{t('OnboardingPage.titleFirst')}</Typography>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '4px' }}>
                    {t('OnboardingPage.subtitleFirst')}
                </Typography>
            </Header>
            <div style={{ gridArea: 'main', overflowY: 'unset' }}>
                <Form>
                    <Controller
                        control={control}
                        name="firstName"
                        render={({ field, fieldState: { error } }) => (
                            <Input
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                id="firstName"
                                label={t('common.firstName')}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                required
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="lastName"
                        rules={{ required: t('validation.required') }}
                        render={({ field, fieldState: { error } }) => (
                            <Input
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                id="lastName"
                                label={t('common.lastName')}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                required
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="phoneNumber"
                        render={({ field, fieldState: { error } }) => (
                            <PhoneInput
                                placeholder=""
                                label={t('common.phoneNumber')}
                                inputProps={{ name: 'phoneNumber' }}
                                value={field.value}
                                autoFormat
                                onChange={value => field.onChange(value)}
                                error={error ? t(error.message) : null}
                                required
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="industry"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Select
                                {...field}
                                value={field.value ?? ''}
                                ref={ref}
                                label={t('common.industry')}
                                options={industryOptions}
                                required
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                            />
                        )}
                    />
                </Form>
            </div>
            <Footer>
                <Button type="submit" variant="contained" disabled={isSubmitting || !isValid} onClick={handleSubmit}>
                    {t('buttons.continue')}
                </Button>
            </Footer>
        </Container>
    );
};

export default WelcomeForm;
