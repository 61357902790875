import { forwardRef } from 'react';
import MuiAlert from '@mui/material/Alert';
import { CheckCircle as CheckCircleIcon, Error as ErrorIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const CssMuiAlert = styled(MuiAlert)({
    '&.MuiAlert-root': {
        padding: '8px 12px',
        borderRadius: 4,
        alignItems: 'center',
    },
    '& .MuiAlert-icon': {
        opacity: 1,
        padding: 0,
    },
    '& .MuiAlert-message': {
        padding: 0,
    },
    '& .MuiAlert-action': {
        paddingTop: 0,
    },
    '&.MuiAlert-filledSuccess': {
        backgroundColor: '#BDE0CC',
        color: '#1F4C5C',
    },
    '&.MuiAlert-filledInfo': {
        backgroundColor: '#BCC9CE',
        color: '#416774',
    },
    '& .MuiSvgIcon-root': {
        fontSize: '20px',
    },
    '&.MuiAlert-filledError': {
        backgroundColor: '#ff99ac',
        color: '#b00020',
    },
});

const Alert = forwardRef((props, ref) => {
    return (
        <CssMuiAlert
            ref={ref}
            variant="filled"
            iconMapping={{ success: <CheckCircleIcon />, error: <ErrorIcon /> }}
            {...props}
        />
    );
});

Alert.displayName = 'Alert';

export default Alert;
