import { useMemo, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import { Typography } from '@components/Typography';
import TextEditor from '@components/TextEditor';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { useTranslation } from '@hooks/useTranslation';
import { getChannelOptions, getFilteredByCompanyChannelsIds } from '@helpers/channels';
import { CHANNEL } from '@constants/channels';
import { MAX_LENGTH } from '@constants/campaigns';
import { alertActions, channelsActions, contactsActions } from '@actions';
import { sendMessageService } from '@services';
import { CONTACT_MODAL_TYPE } from '@constants/modal';
import { getFullName } from '@helpers/userName';
import { parseTipTapJSONToPlainText, testEditorLength } from '@helpers/text-editor';
import { Content, Modal } from './styles';

const ContactSendMessage = ({
    modal,
    setModal,
    selectedContacts = { list: {}, all: false },
    setSelectedContacts,
    currentQuery,
}) => {
    const { t } = useTranslation();
    const [channelOptions, setChannelOptions] = useState([]);
    const channelType = useRef(CHANNEL.SMS);
    const isRefetchedAfterBrokenChannel = useRef(false);

    const companies = useSelector(state => state.account?.account?.companies || []);
    const channels = useSelector(state => state.channels.data);
    const { data: contacts, contact, totalCount } = useSelector(state => state.contacts);
    const dispatch = useDispatch();

    const isBulk = modal.type === CONTACT_MODAL_TYPE.BULK;

    const selectedLength = useMemo(() => {
        return Object.keys(selectedContacts.list).length;
    }, [selectedContacts]);

    const companyOptions = useMemo(
        () =>
            isBulk
                ? companies.map(item => ({ value: item.companyId, label: item.internalName }))
                : companies
                      .filter(c => contact.companies.includes(c.companyId))
                      .map(item => ({ value: item.companyId, label: item.internalName })),
        [companies, contact],
    );

    const SendMessageSchema = yup.object().shape({
        contactId: yup.string().required('validation.required'),
        message: yup
            .string()
            .required('validation.required')
            .test('text-editor-length', `text-editor-length`, value =>
                testEditorLength(value, MAX_LENGTH[channelType.current]),
            ),
        channelId: yup.string().required('validation.required'),
        companyId: yup.string().required('validation.required'),
    });

    const resolver = useYupValidationResolver(SendMessageSchema);

    const {
        getValues,
        setValue,
        control,
        handleSubmit,
        reset,
        formState: { isValid, errors, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            contactId: isBulk
                ? t('ContactsPage.contactsSelected', {
                      selectedContacts: selectedContacts.all ? totalCount : selectedLength,
                  })
                : getFullName(contact?.firstName, contact?.lastName, '-'),
            message: '',
            channelId: '',
            companyId: !isBulk ? companyOptions[0]?.value : '',
        },
    });

    useEffect(() => {
        if (isBulk) {
            dispatch(channelsActions.get());
        }
    }, [isBulk]);

    useEffect(() => {
        let newChannelOptions = [];
        if (!isBulk) {
            const availableChannels = getFilteredByCompanyChannelsIds(getValues('companyId'), companies);
            newChannelOptions = getChannelOptions({ out: true, contact }, availableChannels, channels);
            if (!isRefetchedAfterBrokenChannel.current) {
                setValue('channelId', newChannelOptions[0]?.value, { shouldValidate: true, shouldDirty: true });
            } else {
                setValue('channelId', '', { shouldDirty: true });
            }
            channelType.current = newChannelOptions[0]?.type || CHANNEL.SMS;
        }
        setChannelOptions(newChannelOptions);
    }, [companies, channels, setValue]);

    const onCloseModal = () => {
        setModal(prev => ({ ...prev, open: false }));
        if (isBulk) {
            setSelectedContacts(prev => ({ ...prev, list: {}, all: false }));
        }
        reset();
    };

    const onSendMessage = async () => {
        const values = getValues();
        try {
            await sendMessageService.sendContactMessage({
                ...values,
                message: values.message,
                contactId: contact?.id,
            });
            if (contacts.length) {
                const updatedContact = await dispatch(contactsActions.getContact(contact?.id));
                const contactsCopy = contacts.slice();
                const updatedContactIndex = contactsCopy.findIndex(c => c.id === contact?.id);
                if (updatedContactIndex !== -1 && updatedContact) {
                    contactsCopy[updatedContactIndex] = updatedContact;
                    dispatch(contactsActions.setContacts(contactsCopy));
                }
            }
            dispatch(alertActions.success(t('alertMessages.sendSuccess')));
            onCloseModal();
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.sendFail')));
            }
            if (error.errorCode === 'channel_configuration_error') {
                isRefetchedAfterBrokenChannel.current = true;
                dispatch(channelsActions.get());
            }
        }
    };

    const onSendBulkMessages = async () => {
        const values = getValues();
        const { message, channelId, companyId } = values;

        try {
            await sendMessageService.sendBulkContactMessages({
                data: { message, channelId, companyId },
                filter: {
                    ...currentQuery,
                    contacts: Object.keys(selectedContacts.list),
                    allContacts: selectedContacts.all,
                },
            });
            dispatch(alertActions.success(t('alertMessages.sendSuccess')));
            onCloseModal();
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.sendFail')));
            }
        }
    };

    return (
        <Modal
            isOpen={modal.open}
            handleClose={onCloseModal}
            title={t('ContactsPage.newMessage')}
            onPrimaryAction={isBulk ? handleSubmit(onSendBulkMessages) : handleSubmit(onSendMessage)}
            primaryActionText={t('buttons.sendMessage')}
            primaryActionDisabled={isSubmitting || !isValid || !!errors?.message}
            onSecondaryAction={onCloseModal}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content>
                <Controller
                    control={control}
                    name="contactId"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('ContactsPage.recipient')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                            disabled
                            sx={{ gridArea: 'input1' }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="companyId"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Select
                            {...field}
                            ref={ref}
                            onChange={({ target: { value } }) => {
                                setValue('companyId', value, { shouldValidate: true, shouldDirty: true });
                                const availableChannels = getFilteredByCompanyChannelsIds(value, companies);
                                const newChannelOptions = isBulk
                                    ? getChannelOptions({ out: true }, availableChannels, channels)
                                    : getChannelOptions({ out: true, contact }, availableChannels, channels);
                                setChannelOptions(newChannelOptions);
                                setValue('channelId', newChannelOptions[0]?.value ?? '', {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                                channelType.current = newChannelOptions[0]?.type || CHANNEL.SMS;
                            }}
                            options={companyOptions}
                            label={t('common.location')}
                            fullWidth
                            style={{ gridArea: 'select1' }}
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="channelId"
                    rules={{ required: t('validation.required') }}
                    render={({ field, fieldState: { error }, ref }) => (
                        <Select
                            {...field}
                            onChange={({ target: { value } }) => {
                                setValue('channelId', value, { shouldValidate: true, shouldDirty: true });
                                const channel = channels.find(c => c.id === value);
                                channelType.current = channel?.type || CHANNEL.SMS;
                            }}
                            ref={ref}
                            label={t('common.channel')}
                            fullWidth
                            options={channelOptions}
                            required
                            style={{ gridArea: 'select2' }}
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="message"
                    render={({ field, fieldState: { error }, ref }) => (
                        <div style={{ gridArea: 'input2' }}>
                            <Typography
                                variant="caption"
                                sx={{ marginBottom: '16px', color: 'rgba(0, 0, 0, 0.6)', display: 'inline-block' }}
                            >
                                {t('ContactsPage.messageText')}
                            </Typography>
                            <TextEditor
                                {...field}
                                ref={ref}
                                label={t('common.message', { count: 1 })}
                                limit={MAX_LENGTH[channelType.current]}
                                initialValue={''}
                                onChange={text =>
                                    setValue('message', parseTipTapJSONToPlainText(text), {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                    })
                                }
                                error={error}
                                aiAssistant
                            />
                        </div>
                    )}
                />
            </Content>
        </Modal>
    );
};

export default ContactSendMessage;
