import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

export const Container = styled('div')(({ isNotMain }) => ({
    background: '#fff',
    boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    width: 564,
    height: 'fit-content',
    display: 'grid',
    gridTemplateAreas: `"header" "main" "footer"`,
    gridTemplateRows: 'max-content auto 76px',
    ...(isNotMain && {
        gridTemplateAreas: `"header" "footer"`,
        gridTemplateRows: 'max-content max-content',
    }),
    '@media (max-width: 1024px)': {
        width: '100%',
        borderRadius: 0,
        height: '100%',
        gridTemplateRows: 'minmax(76px, max-content) auto 60px',
    },
}));

export const Header = styled('div')({
    gridArea: 'header',
    padding: 24,
    '@media (max-width: 1024px)': {
        padding: 16,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
});

export const Form = styled('div')({
    padding: '6px 24px 24px',
    display: 'grid',
    columnGap: '24px',
    rowGap: '16px',
    gridTemplateColumns: '1fr 1fr',
    '@media (max-width: 1024px)': {
        padding: 16,
    },
    '@media (max-width: 600px)': {
        gridTemplateColumns: '1fr',
    },
});

export const GoogleContentWrapper = styled('div')({
    padding: '6px 24px 4px',
    height: 392,
    overflowY: 'auto',
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'initial',
    },
});

export const GoogleFooter = styled('div')({
    paddingTop: 16,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 600px)': {
        flexDirection: 'column',
        '& button': {
            width: '100% !important',
        },
    },
});

export const Progress = styled(CircularProgress)({
    color: 'rgba(0, 0, 0, 0.38)',
    width: '58px !important',
    height: '58px !important',
    display: 'block',
    margin: '0 auto 24px',
});

export const Footer = styled('div')(({ isNotMain, isSpaceBetween }) => ({
    gridArea: 'footer',
    padding: 24,
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 1024px)': {
        padding: 16,
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '& .MuiButton-startIcon': {
        marginLeft: 0,
        '& svg': {
            height: 17,
            width: 17,
            '& path': {
                fill: '#fff',
            },
        },
    },
    ...(isNotMain && {
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 0,
        '@media (max-width: 1024px)': {
            borderTop: 'none',
            paddingTop: 16,
        },
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            '& button': {
                width: '100% !important',
            },
        },
    }),
    ...(isSpaceBetween && {
        justifyContent: 'space-between',
        '& .MuiButton-startIcon svg path': {
            fill: '#1F4C5C',
        },
        '@media (max-width: 600px)': {
            '& .MuiButton-startIcon': {
                display: 'none',
            },
        },
    }),
}));
