import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Button from '@components/Button';
import { GoogleNativeDarkIcon } from 'assets/images/icons';
import { GOOGLE_OAUTH_SIGN_IN_URL, GOOGLE_OAUTH_SIGN_UP_URL } from '@constants/integrations';
import { useQueryParams } from '@hooks/useQueryParams';
import GTMHelper from '@helpers/gtm';
import { alertActions, userActions } from '@actions';
import { userService } from '@services';
import { useTranslation } from '@hooks/useTranslation';

const StyledButton = styled(Button)({
    backgroundColor: '#4285F4',
    marginBottom: '16px',
    minWidth: 'fit-content',
    '&:hover': {
        backgroundColor: '#2472f2',
    },
    '& .MuiButton-startIcon': {
        display: 'block',
    },
});

const ButtonIconWrapper = styled('div')({
    backgroundColor: '#fff',
    height: 30,
    width: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomLeftRadius: 3,
    borderTopLeftRadius: 3,
    position: 'absolute',
    left: 1,
});

const GOOGLE_ERROR = {
    CANCEL: 'access_denied',
};

const GoogleButtonAuth = ({ text, path, onClickCallback, language, action }) => {
    const { t } = useTranslation();
    const query = useQueryParams();
    const dispatch = useDispatch();

    useEffect(() => {
        const code = query.get('code');
        const jwtToken = query.get('idToken');
        const error = query.get('error');
        if (error === GOOGLE_ERROR.CANCEL) {
            //
        } else if (code) {
            signInWithGoogle(() =>
                userService.loginWithGoogle({
                    language,
                    code,
                    action,
                    redirectUri: `${window.location.origin}/${path}`,
                    // eslint-disable-next-line no-useless-escape
                    hubspotToken: document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1'),
                    pageUrl: `${window.location.origin}/${path}`,
                }),
            );
        } else if (jwtToken) {
            signInWithGoogle(() =>
                userService.loginWithGoogleOneTap({
                    language,
                    jwtToken,
                    // eslint-disable-next-line no-useless-escape
                    hubspotToken: document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1'),
                    pageUrl: `${window.location.origin}/${path}`,
                }),
            );
        }
    }, [query]);

    const signInWithGoogle = async callback => {
        onClickCallback(true);
        try {
            const user = await callback();
            GTMHelper.update(user);
            if (path === 'signup') {
                GTMHelper.push('registered-with-google');
            }
            dispatch(userActions.setUser(user));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
        onClickCallback(false);
    };

    const onClick = () => (window.location = path === 'signup' ? GOOGLE_OAUTH_SIGN_UP_URL : GOOGLE_OAUTH_SIGN_IN_URL);

    return (
        <StyledButton
            id={`pl-button-google-${path === 'signup' ? 'signup' : 'signin'}`}
            type="button"
            variant="contained"
            fullWidth
            onClick={onClick}
        >
            <ButtonIconWrapper>
                <GoogleNativeDarkIcon />
            </ButtonIconWrapper>
            {text}
        </StyledButton>
    );
};

export default GoogleButtonAuth;
