import { chatbotContentConstants } from '@constants/chatbot-content';
import { chatbotContentService } from '@services';

const get = (params, page = 0, pageSize = 10) => {
    return dispatch => {
        dispatch(request());
        chatbotContentService.getChatbotContent(params, page, pageSize).then(
            data => dispatch(success(data)),
            () => dispatch(failure()),
        );
    };
};

function request() {
    return { type: chatbotContentConstants.CHATBOT_CONTENT_REQUEST };
}

function success(data) {
    return { type: chatbotContentConstants.CHATBOT_CONTENT_SUCCESS, data };
}

function failure() {
    return { type: chatbotContentConstants.CHATBOT_CONTENT_FAILURE };
}

export const chatbotContentActions = {
    get,
};
