import { CHANNEL } from './channels';

export const campaignsConstants = {
    CAMPAIGNS_FETCH: 'CAMPAIGNS_FETCH',
    CAMPAIGNS_SUCCESS: 'CAMPAIGNS_SUCCESS',
    CAMPAIGNS_ERROR: 'CAMPAIGNS_ERROR',
    SET_CAMPAIGNS: 'SET_CAMPAIGNS',
};

export const CAMPAIGN_STATUS = {
    SCHEDULED: 0,
    INPROGRESS: 1,
    COMPLETED: 2,
    CANCELED: 3,
    FAILED: 4,
    PAUSED: 5,
};

export const CAMPAIGN_TYPE = {
    ONETIME: 0,
    ONGOING: 1,
};

export const MAX_LENGTH = {
    [CHANNEL.EMAIL]: Infinity,
    [CHANNEL.SMS]: Infinity,
    [CHANNEL.WHATSAPP]: 1024,
};
