import { useState, useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { Typography } from '@components/Typography';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { useTranslation } from '@hooks/useTranslation';
import { channelsActions, alertActions, accountActions } from '@actions';
import { channelsService, socketService } from '@services';
import { CHANNEL, STATUS, SmsProviderType } from '@constants/channels';
import { SmsSchema, WhatsAppSchema, LiveChatSchema, FacebookSchema, EmailSchema } from './schemas';
import SmsForm from './SmsForm';
import WhatsAppForm from './WhatsAppForm';
import SelectChannel from './SelectChannel';
import WhatsAppStatus from './WhatsAppStatus';
import WhatsAppCheck from './WhatsAppCheck';
import LiveChatForm from './LiveChatForm';
import LiveChatCode from './LiveChatHTMLCode';
import FacebookForm from './FacebookForm';
import EmailForm from './EmailForm';
import { Modal } from './styles';
import { AccIntegrationName } from '@constants/integrations';

const CreateChannel = ({ open, setOpen, channel = {}, setSelectedChannel = () => {}, selectedCompany = {} }) => {
    const { t } = useTranslation();

    const [modal, setModal] = useState({
        step: 1,
        channel: CHANNEL.SMS,
        status: STATUS.PENDING,
        qrWhatsApp: '',
        liveChatPublicId: null,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { location: labels } = useSelector(state => state.labels);
    const companies = useSelector(state => state.account?.account?.companies || []);
    const dispatch = useDispatch();
    const twillioUid = useRef(uuidv4());

    useEffect(() => {
        if (channel?.id) {
            setModal({ step: 3, channel: channel?.type, status: channel?.status, qrWhatsApp: channel?.qrCode });
            subscribeToQrCode();
            subscribeToStatus();
        }
        return () => {
            if (channel?.id) {
                unsubscribeFromQrCode();
                unsubscribeFromStatus();
            }
        };
    }, [channel]);

    const resolverSms = useYupValidationResolver(SmsSchema);

    const {
        getValues: getValuesSms,
        setValue: setValueSms,
        control: controlSms,
        reset: resetSms,
        watch: watchSms,
        formState: { isValid: isValidSms },
    } = useForm({
        mode: 'onBlur',
        resolver: resolverSms,
        defaultValues: {
            name: '',
            companies: [],
            phoneNumber: '',
            senderId: '',
            provider: 0,
            accountId: '',
            apiKey: '',
            token: '',
            login: '',
            password: '',
            locationLabels: [],
        },
    });

    const resolverWhatsApp = useYupValidationResolver(WhatsAppSchema);

    const {
        getValues: getValuesWhatsApp,
        setValue: setValueWhatsApp,
        control: controlWhatsApp,
        reset: resetWhatsApp,
        watch: watchWhatsApp,
        formState: { isValid: isValidWhatsApp },
    } = useForm({
        mode: 'onBlur',
        resolver: resolverWhatsApp,
        defaultValues: { name: '', companies: [], locationLabels: [], newWhatsAppAccount: false },
    });

    const resolverLiveChat = useYupValidationResolver(LiveChatSchema);

    const {
        getValues: getValuesLiveChat,
        setValue: setValueLiveChat,
        control: controlLiveChat,
        reset: resetLiveChat,
        watch: watchLiveChat,
        trigger: triggerLiveChat,
        formState: { isValid: isValidLiveChat },
    } = useForm({
        mode: 'all',
        resolver: resolverLiveChat,
        defaultValues: {
            name: '',
            companies: [],
            smsChannelId: '',
            emailChannelId: '',
            labels: [],
            notifyAboutMissingConversations: true,
            welcomeHeading: '',
            welcomeTagline: '',
            workingHoursEnabled: false,
            offlineMessage: '',
            workingHours: {},
            // collect information
            contactEmail: false,
            contactEmailRequired: false,
            contactPhone: true,
            contactPhoneRequired: true,
            contactName: true,
            contactNameRequired: true,
            locationLabels: [],
        },
    });

    const resolverFacebook = useYupValidationResolver(FacebookSchema);

    const {
        getValues: getValuesFacebook,
        setValue: setValueFacebook,
        control: controlFacebook,
        reset: resetFacebook,
        watch: watchFacebook,
        formState: { isValid: isValidFacebook },
    } = useForm({
        mode: 'onBlur',
        resolver: resolverFacebook,
        defaultValues: { name: '', companies: [], locationLabels: [], id: undefined, saveInstagramReplies: true },
    });

    const resolverEmail = useYupValidationResolver(EmailSchema);

    const {
        getValues: getValuesEmail,
        setValue: setValueEmail,
        control: controlEmail,
        reset: resetEmail,
        watch: watchEmail,
        formState: { isValid: isValidEmail },
    } = useForm({
        mode: 'onBlur',
        resolver: resolverEmail,
        defaultValues: {
            name: '',
            companies: [],
            provider: 0,
            smtpHost: '',
            smtpPort: '',
            username: '',
            isSSL: true,
            email: '',
            password: '',
            locationLabels: [],
        },
    });

    const getAvailableLabels = () => {
        let availableLabels = [];
        if (modal.channel === CHANNEL.FACEBOOK) {
            availableLabels = [
                ...new Set(
                    companies
                        .filter(c => c.profiles.some(i => i.type === AccIntegrationName.Facebook))
                        .map(item => item.labels)
                        .flat(),
                ),
            ];
        } else {
            availableLabels = [...new Set(companies.map(item => item.labels).flat())];
        }
        return availableLabels;
    };

    const setLabelsToForm = () => {
        const availableLabels = getAvailableLabels();
        const labelsIds = labels.filter(l => availableLabels.includes(l.id)).map(item => item.id);
        setValueSms('locationLabels', labelsIds, { shouldDirty: true });
        setValueWhatsApp('locationLabels', labelsIds, { shouldDirty: true });
        setValueLiveChat('locationLabels', labelsIds, { shouldDirty: true });
        setValueFacebook('locationLabels', labelsIds, { shouldDirty: true });
        setValueEmail('locationLabels', labelsIds, { shouldDirty: true });
    };

    useEffect(() => {
        if (selectedCompany?.companyId) {
            setValueSms('companies', [selectedCompany?.companyId], { shouldDirty: true });
            setValueWhatsApp('companies', [selectedCompany?.companyId], { shouldDirty: true });
            setValueLiveChat('companies', [selectedCompany?.companyId], { shouldDirty: true });
            setValueFacebook('companies', [selectedCompany?.companyId], { shouldDirty: true });
            setValueEmail('companies', [selectedCompany?.companyId], { shouldDirty: true });
        }
    }, [selectedCompany]);

    useEffect(() => {
        setLabelsToForm();
    }, [labels, modal.channel]);

    const labelsOptions = useMemo(() => {
        const availableLabels = getAvailableLabels();
        return labels.filter(l => availableLabels.includes(l.id)).map(item => ({ value: item.id, label: item.name }));
    }, [companies, labels, modal.channel]);

    const channelMapCb = {
        [CHANNEL.SMS]: {
            apiCall: () => {
                const smsValues = getValuesSms();
                return channelsService.createOrUpdateChannelSms({
                    ...(smsValues?.provider === SmsProviderType.Twilio && { id: twillioUid.current }),
                    name: smsValues.name,
                    companies: smsValues.companies,
                    smsParameters: {
                        senderId: smsValues?.senderId,
                        provider: smsValues?.provider,
                        accountId: smsValues?.accountId,
                        apiKey: smsValues?.apiKey,
                        token: smsValues?.token,
                        phoneNumber: smsValues.phoneNumber,
                        login: smsValues?.login,
                        password: smsValues?.password,
                    },
                });
            },
            nextStep: () => onCloseModal(),
        },
        [CHANNEL.WHATSAPP]: {
            apiCall: () => {
                const whatsAppValues = getValuesWhatsApp();
                return channelsService.createOrUpdateChannelWhatsApp(whatsAppValues);
            },
            nextStep: () => setModal(prev => ({ ...prev, step: 3 })),
        },
        [CHANNEL.LIVE_CHAT]: {
            apiCall: () => {
                const liveChatValues = getValuesLiveChat();

                if (!liveChatValues?.contactEmailRequired && !liveChatValues?.contactPhoneRequired) {
                    setIsSubmitting(false);
                    const error = new Error('error');
                    error.errorCode = 'phoneOrEmailRequired';
                    return Promise.reject(error);
                }

                return channelsService.createOrUpdateChannelLiveChat({
                    name: liveChatValues.name,
                    companies: liveChatValues.companies,
                    labels: liveChatValues.labels,
                    liveChatParameters: {
                        smsChannelId: liveChatValues.smsChannelId,
                        emailChannelId: liveChatValues.emailChannelId,
                        notifyAboutMissingConversations: liveChatValues.notifyAboutMissingConversations,
                        welcomeHeading: liveChatValues.welcomeHeading,
                        welcomeTagline: liveChatValues.welcomeTagline,
                        workingHoursEnabled: liveChatValues.workingHoursEnabled,
                        offlineMessage: liveChatValues.offlineMessage,
                        workingHours: liveChatValues.workingHours,
                        fields: {
                            Email: {
                                type: 0,
                                enable: liveChatValues.contactEmail,
                                required: liveChatValues.contactEmailRequired,
                            },
                            Phone: {
                                type: 1,
                                enable: liveChatValues.contactPhone,
                                required: liveChatValues.contactPhoneRequired,
                            },
                            Name: {
                                type: 2,
                                enable: liveChatValues.contactName,
                                required: liveChatValues.contactNameRequired,
                            },
                        },
                    },
                });
            },
            nextStep: newChannel => setModal(prev => ({ ...prev, step: 3, liveChatPublicId: newChannel.publicId })),
        },
        [CHANNEL.FACEBOOK]: {
            apiCall: () => {
                const facebookValues = getValuesFacebook();
                return channelsService.createOrUpdateChannelFacebook({
                    ...facebookValues,
                    saveInstagramReplies: undefined,
                });
            },
            nextStep: () => onCloseModal(),
        },
        [CHANNEL.INSTAGRAM]: {
            apiCall: () => {
                const facebookValues = getValuesFacebook();
                return channelsService.createOrUpdateChannelInstagram(facebookValues);
            },
            nextStep: () => onCloseModal(),
        },
        [CHANNEL.EMAIL]: {
            apiCall: () => {
                const emailValues = getValuesEmail();
                return channelsService.createOrUpdateChannelEmail({
                    name: emailValues.name,
                    companies: emailValues.companies,
                    emailParameters: {
                        provider: emailValues.provider,
                        smtpHost: emailValues.smtpHost,
                        smtpPort: emailValues.smtpPort,
                        username: emailValues.username,
                        isSSL: emailValues.isSSL,
                        email: emailValues.email,
                        password: emailValues.password,
                    },
                });
            },
            nextStep: () => onCloseModal(),
        },
    };

    const onClickSubmit = async () => {
        switch (true) {
            case modal.step === 1:
                setModal(prev => ({ ...prev, step: 2 }));
                break;
            case modal.step === 2 && modal.channel === CHANNEL.SMS:
            case modal.step === 2 && modal.channel === CHANNEL.WHATSAPP:
            case modal.step === 2 && modal.channel === CHANNEL.LIVE_CHAT:
            case modal.step === 2 && modal.channel === CHANNEL.FACEBOOK:
            case modal.step === 2 && modal.channel === CHANNEL.INSTAGRAM:
            case modal.step === 2 && modal.channel === CHANNEL.EMAIL:
                setIsSubmitting(true);
                try {
                    const newChannel = await channelMapCb[modal.channel].apiCall();
                    modal.channel === CHANNEL.WHATSAPP && setSelectedChannel(newChannel);
                    await dispatch(channelsActions.get());
                    await dispatch(accountActions.get());
                    channelMapCb[modal.channel].nextStep(newChannel);

                    dispatch(alertActions.success(t('alertMessages.createSuccess')));
                    setIsSubmitting(false);
                } catch (error) {
                    if (error.errorCode) {
                        dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
                    } else {
                        dispatch(alertActions.error(t('apiErrors.something_wrong')));
                    }
                }
                break;
            case modal.step === 3 && modal.channel === CHANNEL.LIVE_CHAT:
                onCloseModal();
                break;
            case modal.step === 4 && modal.channel === CHANNEL.WHATSAPP:
                try {
                    await dispatch(channelsActions.get());
                    await dispatch(accountActions.get());
                    onCloseModal();
                } catch (error) {
                    if (error.errorCode) {
                        dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
                    } else {
                        dispatch(alertActions.error(t('apiErrors.something_wrong')));
                    }
                }
                break;
            default:
                break;
        }
    };

    const resetForm = () => {
        resetSms();
        resetWhatsApp();
        resetLiveChat();
        resetFacebook();
        resetEmail();
    };

    const onClickBack = () => {
        setModal(prev => ({ ...prev, step: prev.step - 1 }));
        resetForm();
        setLabelsToForm();
    };

    const onCloseModal = async () => {
        setModal(prev => ({ ...prev, step: 1, channel: CHANNEL.SMS, status: STATUS.PENDING, qrWhatsApp: '' }));
        setSelectedChannel({});
        resetForm();
        setOpen(false);
    };

    const getPrimaryActionState = () => {
        switch (true) {
            case modal.step === 2 && modal.channel === CHANNEL.SMS && (isSubmitting || !isValidSms):
            case modal.step === 2 && modal.channel === CHANNEL.WHATSAPP && (isSubmitting || !isValidWhatsApp):
            case modal.step === 2 && modal.channel === CHANNEL.LIVE_CHAT && (isSubmitting || !isValidLiveChat):
            case modal.step === 2 && modal.channel === CHANNEL.FACEBOOK && (isSubmitting || !isValidFacebook):
            case modal.step === 2 && modal.channel === CHANNEL.INSTAGRAM && (isSubmitting || !isValidFacebook):
            case modal.step === 2 && modal.channel === CHANNEL.EMAIL && (isSubmitting || !isValidEmail):
                return true;
            default:
                return false;
        }
    };

    const getActionsText = () => {
        switch (true) {
            case modal.step === 1:
            case modal.step === 2 && modal.channel === CHANNEL.WHATSAPP:
            case modal.step === 2 && modal.channel === CHANNEL.LIVE_CHAT:
                return { primaryText: t('buttons.continue'), secondaryText: t('buttons.cancel') };
            case modal.step === 2 && modal.channel === CHANNEL.SMS:
            case modal.step === 2 && modal.channel === CHANNEL.FACEBOOK:
            case modal.step === 2 && modal.channel === CHANNEL.INSTAGRAM:
            case modal.step === 2 && modal.channel === CHANNEL.EMAIL:
            case modal.step === 3 && modal.channel === CHANNEL.LIVE_CHAT:
                return { primaryText: t('buttons.done'), secondaryText: t('buttons.cancel') };
            case modal.step === 3 && modal.channel === CHANNEL.WHATSAPP:
                return { primaryText: '', secondaryText: t('buttons.cancel') };
            case modal.step === 4 && modal.channel === CHANNEL.WHATSAPP && modal.status === STATUS.SUCCESS:
                return { primaryText: t('buttons.done'), secondaryText: '' };
            case modal.step === 4 && modal.channel === CHANNEL.WHATSAPP && modal.status === STATUS.NUMBER_EXISTS:
                return { primaryText: '', secondaryText: t('buttons.close') };
            default:
                return { primaryText: '', secondaryText: '' };
        }
    };

    const getChannelText = () => {
        switch (modal.channel) {
            case CHANNEL.SMS:
                return t('common.sms');
            case CHANNEL.WHATSAPP:
                return t('common.whatsApp');
            case CHANNEL.LIVE_CHAT:
                return t('common.liveChatLowercase');
            case CHANNEL.FACEBOOK:
                return t('common.facebookMessenger');
            case CHANNEL.INSTAGRAM:
                return t('common.instagram');
            case CHANNEL.EMAIL:
                return t('common.email');
            default:
                return '';
        }
    };

    const getTitleText = () => {
        if (modal.step === 1) {
            return { title: t('SettingsChannels.firstStepTitle'), subtitle: t('SettingsChannels.firstStepSubtitle') };
        } else if (modal.step > 1) {
            return {
                title: t('SettingsChannels.createChannelTitle', { channelType: getChannelText() }),
                subtitle: t('SettingsChannels.editChannelSubtitle', { channelType: getChannelText() }),
            };
        }
        return { title: '', subtitle: '' };
    };

    const subscribeToQrCode = () => {
        socketService.registerOnServerEvents(`QrCode_${channel?.id}`, qrCode => {
            setModal(prev => ({ ...prev, qrWhatsApp: qrCode }));
        });
    };

    const subscribeToStatus = () => {
        socketService.registerOnServerEvents(`Status_${channel?.id}`, status => {
            if (status === 'Ready') {
                setModal(prev => ({ ...prev, step: 4, status: STATUS.SUCCESS }));
            } else if (status === 'Disabled') {
                setModal(prev => ({ ...prev, step: 4, status: STATUS.NUMBER_EXISTS }));
            } else if (status === 'Pending') {
                setModal(prev => ({ ...prev, step: 3, status: STATUS.PENDING }));
            }
        });
    };

    const unsubscribeFromQrCode = () => {
        if (channel?.id) {
            socketService.stopConnection(`QrCode_${channel?.id}`);
        }
    };

    const unsubscribeFromStatus = () => {
        if (channel?.id) {
            socketService.stopConnection(`Status_${channel?.id}`);
        }
    };

    return (
        <Modal
            isOpen={open}
            handleClose={onCloseModal}
            title={getTitleText().title}
            subtitle={
                <Typography variant="caption" color="textSecondary">
                    {getTitleText().subtitle}
                </Typography>
            }
            onPrimaryAction={
                (modal.step === 3 && modal.channel === CHANNEL.WHATSAPP) ||
                (modal.step === 4 && modal.channel === CHANNEL.WHATSAPP && modal.status === STATUS.NUMBER_EXISTS)
                    ? null
                    : onClickSubmit
            }
            primaryActionText={getActionsText().primaryText}
            primaryActionDisabled={getPrimaryActionState()}
            onSecondaryAction={
                modal.step === 4 && modal.channel === CHANNEL.WHATSAPP && modal.status === STATUS.SUCCESS
                    ? null
                    : onCloseModal
            }
            secondaryActionText={getActionsText().secondaryText}
            secondaryActionDisabled={isSubmitting}
            onAdditionalAction={modal.step === 2 ? onClickBack : null}
            additionalTextButton={modal.step === 3 && modal.channel === CHANNEL.LIVE_CHAT ? null : t('buttons.back')}
            additionalButtonType="outlined"
            additionalActionDisabled={isSubmitting}
        >
            {modal.step === 1 ? <SelectChannel modal={modal} setModal={setModal} /> : null}
            {modal.step === 2 && modal.channel === CHANNEL.SMS ? (
                <FormProvider {...{ controlSms, setValueSms, watchSms, twillioUid: twillioUid.current }}>
                    <SmsForm labelsOptions={labelsOptions} />
                </FormProvider>
            ) : null}
            {modal.step === 2 && modal.channel === CHANNEL.WHATSAPP ? (
                <FormProvider {...{ controlWhatsApp, setValueWhatsApp, watchWhatsApp, getValuesWhatsApp }}>
                    <WhatsAppForm labelsOptions={labelsOptions} />
                </FormProvider>
            ) : null}
            {modal.step === 2 && modal.channel === CHANNEL.LIVE_CHAT ? (
                <FormProvider
                    {...{ controlLiveChat, setValueLiveChat, getValuesLiveChat, watchLiveChat, triggerLiveChat }}
                >
                    <LiveChatForm labelsOptions={labelsOptions} />
                </FormProvider>
            ) : null}
            {modal.step === 2 && (modal.channel === CHANNEL.FACEBOOK || modal.channel === CHANNEL.INSTAGRAM) ? (
                <FormProvider {...{ controlFacebook, setValueFacebook, watchFacebook, getValuesFacebook }}>
                    <FacebookForm type={modal.channel} labelsOptions={labelsOptions} />
                </FormProvider>
            ) : null}
            {modal.step === 2 && modal.channel === CHANNEL.EMAIL ? (
                <FormProvider {...{ controlEmail, setValueEmail, watchEmail, getValuesEmail }}>
                    <EmailForm labelsOptions={labelsOptions} />
                </FormProvider>
            ) : null}
            {modal.step === 3 && modal.channel === CHANNEL.LIVE_CHAT ? (
                <LiveChatCode liveChatPublicId={modal.liveChatPublicId} />
            ) : null}
            {modal.step === 3 && modal.channel === CHANNEL.WHATSAPP ? (
                <WhatsAppCheck qrWhatsApp={modal.qrWhatsApp} />
            ) : null}
            {modal.step === 4 && modal.channel === CHANNEL.WHATSAPP ? <WhatsAppStatus status={modal.status} /> : null}
        </Modal>
    );
};

export default CreateChannel;
