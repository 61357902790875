import { Link, useLocation } from 'react-router-dom';
import { Typography } from '@components/Typography';
import Tooltip from '@components/Tooltip';
import { useTranslation } from '@hooks/useTranslation';
import { ListItem } from './styles';

const SingleLevelItem = ({ exact, item: { to, id, title, icon }, callback = {}, disabled }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    return disabled ? (
        <Tooltip key={to} placement="top" title={t('common.subscriptionExpiredMsg')}>
            <ListItem isActive={exact ? pathname === to : pathname.includes(to)} type="single">
                <span />
                {icon}
                <Typography variant="body1">{title}</Typography>
            </ListItem>
        </Tooltip>
    ) : (
        <Link to={to} id={id} style={{ textDecoration: 'none', width: '100%' }} key={to}>
            <ListItem isActive={exact ? pathname === to : pathname.includes(to)} onClick={callback} type="single">
                <span />
                {icon}
                <Typography variant="body1">{title}</Typography>
            </ListItem>
        </Link>
    );
};

export default SingleLevelItem;
