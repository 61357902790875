import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import PageHeader from '@features/PageHeader';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { ModalConfirm } from '@components/Modal';
import { useTranslation } from '@hooks/useTranslation';
import { alertActions, channelsActions, micrositesActions } from '@actions';
import { micrositesService } from '@services';
import { VIEW_TYPE } from '@constants/microsites';
import MicrositeItem from './MicrositeItem';
import EditModal from './EditModal';
import { MicrositesIcon } from 'assets/images/icons';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

const CardsWrapper = styled('div')({
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
});

const MicrositesPage = () => {
    const { t } = useTranslation();
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedMicrosite, setSelectedMicrosite] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modal, setModal] = useState({ open: false, type: VIEW_TYPE.EDIT });
    const [restrictedNames, setRestrictedNames] = useState([]);

    const user = useSelector(state => state.authentication.user);
    const account = useSelector(state => state.account?.account);
    const data = useSelector(state => state.microsites.data);
    const isAdmin = useSelector(state => {
        if (!state.authentication?.user?.id) return false;
        return state.authentication?.user?.roles?.filter(u => u.role === 0)?.length > 0;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            try {
                const restrictedNames = await micrositesService.getRestrictedNames();
                setRestrictedNames(restrictedNames);
            } catch (_) {
                //
            }
        }
        dispatch(micrositesActions.getAll());
        dispatch(channelsActions.get());
        fetchData();
    }, []);

    const onCreateEmptyMicrosite = async () => {
        setIsSubmitting(true);
        try {
            const newMicrosite = await micrositesService.createEmptyMicrosite();
            await dispatch(micrositesActions.getAll());
            setSelectedMicrosite({ ...newMicrosite, restrictedNames });
            const rn = await micrositesService.getRestrictedNames();
            setRestrictedNames(rn);
            setModal({ open: true, type: VIEW_TYPE.EDIT });
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.createFail')));
        }
        setIsSubmitting(false);
    };

    const onDelete = async () => {
        setIsSubmitting(true);
        try {
            await micrositesService.deleteMicrosite(selectedMicrosite.id);
            await dispatch(micrositesActions.getAll());
            const rn = await micrositesService.getRestrictedNames();
            setRestrictedNames(rn);
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.deleteFail')));
        }
        setSelectedMicrosite(null);
        if (modal.open) {
            setModal({ open: false, type: VIEW_TYPE.EDIT });
        }
        setDeleteModalOpen(false);
        setIsSubmitting(false);
    };

    const onCloseModal = () => {
        setModal({ open: false, type: VIEW_TYPE.EDIT });
        setSelectedMicrosite(null);
    };

    const onSave = async values => {
        setIsSubmitting(true);
        try {
            await micrositesService.updateMicrosite({ ...selectedMicrosite, ...values });
            await dispatch(micrositesActions.getAll());
            const rn = await micrositesService.getRestrictedNames();
            setRestrictedNames(rn);
            dispatch(alertActions.success(t('alertMessages.editSuccess')));
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.editFail')));
        }
        setSelectedMicrosite(null);
        if (modal.open) {
            setModal({ open: false, type: VIEW_TYPE.EDIT });
        }
        setIsSubmitting(false);
    };

    const isUser = !isAdmin && !user?.isAccountOwner && !user?.isGlobalAdmin;

    return (
        <Container>
            <PageHeader
                styles={{ marginBottom: '16px' }}
                icon={<MicrositesIcon />}
                title={t('MicrositesPage.createCustomLending')}
                subtitle={t('MicrositesPage.createCustomLendingSubTitle')}
                metrics={
                    <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                        {t('MicrositesPage.created')} {data?.totalCount}
                    </Typography>
                }
            >
                {!isUser && (
                    <Button variant="contained" onClick={onCreateEmptyMicrosite} disabled={isSubmitting}>
                        {t('buttons.createNewMicrosite')}
                    </Button>
                )}
            </PageHeader>
            <CardsWrapper>
                {data?.microsites.map(item => (
                    <MicrositeItem
                        key={item.id}
                        microsite={item}
                        isUser={isUser}
                        companies={account?.companies}
                        isSubmitting={isSubmitting}
                        onClickEdit={() => {
                            setSelectedMicrosite({
                                ...item,
                                restrictedNames: restrictedNames.filter(n => n !== item.slug),
                            });
                            setModal({ open: true, type: VIEW_TYPE.EDIT });
                        }}
                        onClickView={() => {
                            setSelectedMicrosite(item);
                            setModal({ open: true, type: VIEW_TYPE.VIEW });
                        }}
                        onClickDelete={() => {
                            setSelectedMicrosite(item);
                            setDeleteModalOpen(true);
                        }}
                    />
                ))}
            </CardsWrapper>
            {modal.open ? (
                <EditModal
                    modal={modal}
                    account={account}
                    onCloseModal={onCloseModal}
                    selectedMicrosite={selectedMicrosite}
                    onDelete={() => setDeleteModalOpen(true)}
                    onSave={onSave}
                    isSubmitting={isSubmitting}
                    isUser={isUser}
                />
            ) : null}
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDelete}
                    primaryActionText={t('buttons.deleteSite')}
                    primaryActionType="danger"
                />
            )}
        </Container>
    );
};

export default MicrositesPage;
