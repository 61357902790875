import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { ImportIcon } from 'assets/images/icons';
import Button from '@components/Button';
import { useTranslation } from '@hooks/useTranslation';
import { alertActions } from '@actions';
import { validateFileSize, validateFileParams } from '@helpers/upload-file';
import { MEDIA_TYPE } from '@constants/file';

const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const ImageWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
    marginRight: 8,
    width: 74,
    height: 74,
    background: 'rgba(0, 0, 0, 0.14)',
    borderRadius: 4,
});

const UploadImage = styled('img')({
    display: 'inline-block',
    width: 58,
    height: 58,
    borderRadius: 4,
    backgroundColor: '#fff',
    objectFit: 'contain',
});

/**
 * @param {Object} style for container
 * @param {number} maxSize max size of the picture in bytes
 * @param {number} minSize min size of the picture in bytes
 * @param {string} extensions allowed extensions
 * @param {Array} dimension [minWidth, minHeight, maxWidth, maxHeight]
 * @param {string} logoUrl url of the picture
 * @param {boolean} disabled disabled state
 * @param {function} onDelete function to clear picture
 * @param {function} uploadLogo function to upload the picture
 */
const ImageUploaderSingle = ({
    style = {},
    maxSize,
    minSize,
    extensions,
    dimension,
    logoUrl,
    disabled,
    onDelete,
    uploadLogo,
    isUploading,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onUpload = async e => {
        const file = e.target.files[0];
        if (!file) return;
        if (dimension) {
            const objUrl = URL.createObjectURL(file);
            await validateFileParams(MEDIA_TYPE.IMAGE, objUrl, { dimension }, () => {
                dispatch(alertActions.error(t('apiErrors.invalid_format')));
                e.target.value = '';
                URL.revokeObjectURL(objUrl);
                return;
            });
        }
        const { error, metric, size } = validateFileSize(file, maxSize, minSize);
        if (error) {
            dispatch(alertActions.error(t(`apiErrors.${error}`, { size, metric })));
            e.target.value = '';
            return;
        }
        uploadLogo(e);
    };

    return (
        <Container style={{ ...style }}>
            {logoUrl && (
                <ImageWrapper>
                    <UploadImage src={logoUrl} alt="logo" />
                </ImageWrapper>
            )}
            <Button
                variant="outlined"
                aria-label="upload picture"
                component="label"
                disabled={disabled}
                startIcon={
                    !isUploading ? <ImportIcon /> : <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.38)' }} size={16} />
                }
            >
                <input hidden accept={extensions ? extensions : 'image/*'} type="file" onChange={onUpload} />
                {logoUrl ? t('buttons.replace') : t('buttons.upload')}
            </Button>
            {logoUrl ? (
                <Button variant="outlined" sx={{ minWidth: 'auto', marginLeft: '8px' }} onClick={onDelete}>
                    <DeleteIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                </Button>
            ) : null}
        </Container>
    );
};

export default ImageUploaderSingle;
