import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import Button from '@components/Button';
import { Typography } from '@components/Typography';
import { useThrottle } from '@hooks/useThrottle';
import { useCopyToClipboard } from '@hooks/useCopyToClipboard';
import { useTranslation } from '@hooks/useTranslation';
import { getChatWidgetScript } from '@constants/widget';

const Container = styled('div')({
    display: 'grid',
    gridGap: '0 24px',
    gridTemplateAreas: '"select1" "input2"',
    gridTemplateColumns: '100%',
});

const LiveChatCode = ({ liveChatPublicId }) => {
    const [alignment, setAlignment] = useState('left');
    const { t } = useTranslation();

    const { onCopy } = useCopyToClipboard();
    const [onCopyClick] = useThrottle(value => onCopy(value), 1000);

    return (
        <Container>
            <Select
                label={t('SettingsChannels.widgetPosition')}
                value={alignment}
                onChange={e => setAlignment(e.target.value)}
                options={[
                    { label: t('common.left'), value: 'left' },
                    { label: t('common.right'), value: 'right' },
                ]}
                required
                style={{ gridArea: 'select1' }}
            />

            <div style={{ gridArea: 'input2' }}>
                <Typography variant="caption" sx={{ marginBottom: '8px', display: 'block' }}>
                    {t('SettingsChannels.addCodeBelow')}
                </Typography>
                <Input
                    inputProps={{ value: getChatWidgetScript(liveChatPublicId, alignment) }}
                    fullWidth
                    multiline
                    minRows={3}
                />
                <Button
                    type="submit"
                    variant="outlined"
                    onClick={() => onCopyClick(getChatWidgetScript(liveChatPublicId, alignment))}
                >
                    {t('buttons.copyCode')}
                </Button>
            </div>
        </Container>
    );
};

export default LiveChatCode;
