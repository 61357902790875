import { memo } from 'react';
import sortBy from 'lodash.sortby';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@components/Typography';
import { Table } from '@components/Table';
import { useTranslation } from '@hooks/useTranslation';
import { getFullName } from '@helpers/userName';
import { formatNumber, formatPercentNumber } from '@helpers/formatNumbers';

export const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('common.user')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography variant="subtitle2" align="right">
                    {t('common.new', { count: 0 })}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('common.closed')}
                </Typography>
            ),
        },
        {
            key: 3,
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('common.open')}
                </Typography>
            ),
        },
        {
            key: 4,
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsConversations.responseRate')}
                </Typography>
            ),
        },
        {
            key: 5,
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsConversations.responseTime')}
                </Typography>
            ),
        },
        {
            key: 6,
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsConversations.resolutionTime')}
                </Typography>
            ),
        },
    ];
};

export const getTableBody = data => {
    return data.map(user => {
        return [
            {
                component: (
                    <Typography variant="subtitle2" align="left">
                        {user.userName}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography
                        variant="body2"
                        align="right"
                        sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}
                    >
                        {formatNumber(user.conversationsCount)}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography
                        variant="body2"
                        align="right"
                        sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}
                    >
                        {formatNumber(user.closedConversationsCount)}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography
                        variant="body2"
                        align="right"
                        sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}
                    >
                        {formatNumber(user.openConversationsCount)}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography
                        variant="body2"
                        align="right"
                        sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}
                    >
                        {formatPercentNumber(user.responseRate)}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography
                        variant="body2"
                        align="right"
                        sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}
                    >
                        {formatNumber(user?.responseTime)}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography
                        variant="body2"
                        align="right"
                        sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}
                    >
                        {formatNumber(user?.resolutionTime)}
                    </Typography>
                ),
            },
        ];
    });
};

const ConversationsTable = memo(({ isLoading, data, users }) => {
    const dataWithUserName = data.map(item => {
        const user = users.find(u => u.id === item.userId);
        return {
            ...item,
            userName: getFullName(user?.firstName, user?.lastName),
        };
    });

    const dataSortedByName = sortBy(dataWithUserName, item => item.userName.toLowerCase());

    return (
        <Paper elevation={1} sx={{ padding: '24px', display: 'flex', width: '100%', borderRadius: '4px' }}>
            <TableContainer>
                <Table columnNames={GetTableHeader()} rows={getTableBody(dataSortedByName)} isDataLoading={isLoading} />
            </TableContainer>
        </Paper>
    );
});

ConversationsTable.displayName = 'ConversationsTable';

export default ConversationsTable;
