import { labelsConstants, LABEL_NAME } from '@constants/labels';
import { labelsService } from '@services';

const setLabels = data => {
    return { type: labelsConstants.LABELS_SUCCESS, payload: data };
};

const get = labelType => {
    return dispatch => {
        labelsService
            .getLabels(labelType)
            .then(data => dispatch(setLabels({ data, labelType: LABEL_NAME[labelType] })));
    };
};

export const labelsActions = {
    get,
};
