import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { styled } from '@mui/system';
import LineChart from '@components/Charts/LineChart';
import { useTranslation } from '@hooks/useTranslation';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { formatPercentNumber, formatNumber } from '@helpers/formatNumbers';
import MetricItem from './MetricItem';

const CardsWrapper = styled('div')({
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    height: 'calc(100% - 57px)',
    '@media (max-width: 375px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
    },
});

const createChartData = (data, labels, t) => {
    return {
        labels,
        datasets: [
            {
                label: t('common.engagement'),
                data: data.map(({ engagement }) => engagement),
                type: 'line',
                fill: true,
                backgroundColor: 'rgba(183, 220, 199, 0.75)',
                borderColor: 'rgba(183, 220, 199, 1)',
                pointRadius: 0,
                pointHoverRadius: 0,
                order: 1,
            },
            {
                label: t('AnalyticsPresence.views'),
                data: data.map(({ views }) => views),
                type: 'line',
                fill: true,
                backgroundColor: 'rgba(239, 244, 203, 0.20)',
                borderColor: 'rgba(239, 244, 203, 1)',
                pointRadius: 0,
                pointHoverRadius: 0,
                order: 0,
            },
            {
                label: t('AnalyticsConversion.conversion'),
                data: data.map(({ conversion }) => conversion),
                backgroundColor: 'rgba(235, 246, 240, 0)',
                borderColor: 'rgba(183, 220, 199, 0)',
                pointRadius: 0,
                pointHoverRadius: 0,
                order: 2,
            },
        ],
    };
};

const Presence = () => {
    const { t } = useTranslation();
    const language = useSelector(state => state.authentication.user?.language);
    const { presence } = useSelector(state => state.dashboard.dashboard);

    const data = [
        {
            id: 0,
            title: t('AnalyticsPresence.views'),
            value: presence.views,
            subvalue: presence.viewsDelta,
        },
        {
            id: 2,
            title: t('AnalyticsSocialPosts.engagements'),
            value: presence.engagement,
            subvalue: presence.engagementDelta,
        },
        {
            id: 3,
            title: t('AnalyticsConversion.conversion'),
            value: presence.conversion,
            subvalue: presence.conversionDelta,
            withPercent: true,
        },
    ];

    const labels = useMemo(
        () =>
            presence.distribution.map(({ date }) =>
                format(parseISO(date), 'MMM', { locale: I18N_DATE_FORMAT[language] }),
            ),
        [presence.distribution],
    );

    const datasets = useMemo(
        () => createChartData(presence.distribution, labels, t),
        [presence.distribution, labels, t],
    );

    return (
        <>
            <CardsWrapper>
                {data.map(item => (
                    <MetricItem
                        key={item.id}
                        value={item.value}
                        subvalue={item.subvalue}
                        title={item.title}
                        withSign={item.withSign}
                        withPercent={item.withPercent}
                    />
                ))}
            </CardsWrapper>
            <div style={{ position: 'relative', height: '170px', padding: '0 16px', marginTop: '16px' }}>
                <LineChart
                    data={datasets}
                    labels={labels}
                    withBorderPlugin={false}
                    optionsProps={{
                        interaction: {
                            mode: 'nearest',
                            axis: 'x',
                            intersect: false,
                        },
                        plugins: {
                            legend: { display: false },
                            tooltip: {
                                itemSort: function (a, b) {
                                    return a.dataset.order - b.dataset.order;
                                },
                                callbacks: {
                                    title: function () {},
                                    label: function (context) {
                                        return [
                                            ...(context.datasetIndex === 0 || context.datasetIndex === 1
                                                ? [
                                                      `${context.dataset.label}: ${formatNumber(
                                                          context.parsed.y || 0,
                                                          'compact',
                                                      )}`,
                                                  ]
                                                : []),
                                            ...(context.datasetIndex === 2
                                                ? [
                                                      `${context.dataset.label}: ${formatPercentNumber(
                                                          +context.formattedValue,
                                                      )}`,
                                                  ]
                                                : []),
                                        ];
                                    },
                                },
                            },
                        },
                        scales: {
                            x: {
                                border: {
                                    display: false,
                                },
                                ticks: {
                                    autoSkip: false,
                                    padding: 0,
                                    font: {
                                        family: 'Rubik',
                                        size: 12,
                                        weight: 400,
                                    },
                                    callback: (_, index) => labels[index],
                                },
                                grid: {
                                    drawOnChartArea: false,
                                    display: false,
                                    drawBorder: false,
                                },
                            },
                            y: { display: false },
                        },
                    }}
                />
            </div>
        </>
    );
};

export default Presence;
