import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@components/Typography';
import Button from '@components/Button';

const StyledDrawer = styled(MuiDrawer)({
    '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: 356,
        overflow: 'hidden',
        backgroundColor: '#fff',
        height: '100%',
        boxShadow:
            '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        '@media (max-width: 1024px)': {
            width: '100%',
        },
    },
});

const Header = styled('div')({
    width: '100%',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    zIndex: 2,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
});

const Content = styled('div')({
    overflowY: 'auto',
    padding: '24px 24px 16px',
});

const TitleWrapper = styled('div')(({ hasSubtitle }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
    ...(!hasSubtitle && { marginBottom: 0 }),
}));

const Footer = styled('div')(({ isOneAction }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    backgroundColor: '#FFF',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    '& > button': {
        width: '100%',
    },
    '& > button:not(:last-child)': {
        marginBottom: 8,
    },
    ...(isOneAction && { height: 80 }),
}));

const Drawer = ({
    children,
    anchor,
    variant,
    className,
    open,
    onClose,
    title,
    subtitle = null,
    primaryText,
    primaryDisabled,
    primaryButtonType = 'contained',
    primaryButtonStartIcon = null,
    primaryAction,
    secondaryText,
    secondaryButtonType = 'outlined',
    secondaryAction = null,
    additionalText,
    additionalButtonType = 'outlined',
    additionalAction = null,
}) => (
    <StyledDrawer variant={variant} anchor={anchor} open={open} onClose={onClose} className={className}>
        <div style={{ height: '100%', display: 'grid', gridTemplateRows: 'max-content auto max-content' }}>
            <Header>
                <TitleWrapper hasSubtitle={subtitle}>
                    <Typography variant="h5" color="textPrimary">
                        {title}
                    </Typography>
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
                </TitleWrapper>
                {subtitle && (
                    <Typography variant="caption" color="textSecondary">
                        {subtitle}
                    </Typography>
                )}
            </Header>
            <Content className="drawer-content">{children}</Content>
            <Footer isOneAction={!secondaryText}>
                {additionalAction && (
                    <Button variant={additionalButtonType} onClick={additionalAction}>
                        {additionalText}
                    </Button>
                )}
                <Button
                    variant={primaryButtonType}
                    disabled={primaryDisabled}
                    onClick={primaryAction}
                    startIcon={primaryButtonStartIcon}
                >
                    {primaryText}
                </Button>
                {secondaryText && (
                    <Button variant={secondaryButtonType} onClick={secondaryAction || onClose}>
                        {secondaryText}
                    </Button>
                )}
            </Footer>
        </div>
    </StyledDrawer>
);

export default Drawer;
