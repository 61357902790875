import * as yup from 'yup';
import { SmsProviderType } from '@constants/channels';

export const SmsSchema = yup.object().shape({
    name: yup.string().required('validation.required'),
    senderId: yup.string().when('provider', {
        is: provider => provider !== SmsProviderType.Twilio,
        then: yup
            .string()
            .max(11, 'validation.maxLength')
            .matches(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/, 'validation.onlyAlphanumericSpace')
            .matches(/(?!^\d+$)^.+$/, 'validation.onlyAlphanumericSpace')
            .required('validation.required'),
        otherwise: yup.string().when('senderId', {
            is: senderId => senderId && senderId.length > 0,
            then: yup
                .string()
                .max(11, 'validation.maxLength')
                .matches(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/, 'validation.onlyAlphanumericSpace')
                .matches(/(?!^\d+$)^.+$/, 'validation.onlyAlphanumericSpace')
                .notRequired(),
            otherwise: yup.string().nullable(),
        }),
    }),
    companies: yup.array().min(1, 'validation.required'),
    provider: yup.number().required('validation.required'),
    accountId: yup.string().when('provider', {
        is: SmsProviderType.Twilio,
        then: yup.string().required('validation.required'),
        otherwise: yup.string().nullable(),
    }),
    apiKey: yup.string().when('provider', {
        is: SmsProviderType.Twilio,
        then: yup.string().required('validation.required'),
        otherwise: yup.string().nullable(),
    }),
    phoneNumber: yup.string().when('provider', {
        is: SmsProviderType.Twilio,
        then: yup.string().validatePhone('validation.wrongPhoneFormat').required('validation.required'),
        otherwise: yup.string().nullable(),
    }),
    token: yup.string().when('provider', {
        is: provider => provider === SmsProviderType.TurboSms || provider === SmsProviderType.SmsTo,
        then: yup.string().required('validation.required'),
        otherwise: yup.string().nullable(),
    }),
    login: yup.string().when('provider', {
        is: SmsProviderType.Lifecell,
        then: yup.string().required('validation.required'),
        otherwise: yup.string().nullable(),
    }),
    password: yup.string().when('provider', {
        is: SmsProviderType.Lifecell,
        then: yup.string().required('validation.required'),
        otherwise: yup.string().nullable(),
    }),
});

export const WhatsAppSchema = yup.object().shape({
    name: yup.string().required('validation.required'),
    companies: yup.array().min(1, 'validation.required'),
});

export const LiveChatSchema = yup.object().shape({
    name: yup.string().required('validation.required'),
    companies: yup.array().min(1, 'validation.required'),
    smsChannelId: yup.string().required('validation.required'),
    emailChannelId: yup.string().required('validation.required'),
    notifyAboutMissingConversations: yup.boolean(),
    welcomeHeading: yup.string().required('validation.required'),
    welcomeTagline: yup.string().required('validation.required'),
    workingHoursEnabled: yup.boolean(),
    offlineMessage: yup.string().when('workingHoursEnabled', {
        is: true,
        then: yup.string().required('validation.required'),
        otherwise: yup.string().nullable(),
    }),
});

export const FacebookSchema = yup.object().shape({
    name: yup.string().required('validation.required'),
    companies: yup.array().min(1, 'validation.required'),
});

export const EmailSchema = yup.object().shape({
    name: yup.string().required('validation.required'),
    companies: yup.array().min(1, 'validation.required'),
    provider: yup.number().required('validation.required'),
    smtpHost: yup.string().required('validation.required'),
    smtpPort: yup.number().required('validation.required').typeError('validation.required'),
    username: yup.string().required('validation.required'),
    isSSL: yup.boolean().required('validation.required'),
    email: yup.string().email('validation.wrongEmailFormat').required('validation.required'),
    password: yup.string().required('validation.required'),
});
