import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

export const Container = styled('div')({
    padding: 16,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    maxWidth: 360,
    '@media (max-width: 700px)': {
        maxWidth: 'none',
    },
});

export const Footer = styled('div')({
    paddingTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 700px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& div:nth-child(1)': {
            marginBottom: 16,
        },
    },
});

export const Controls = styled('div')({
    '@media (max-width: 700px)': {
        width: '100%',
    },
});

export const StyledIconButton = styled(IconButton)({
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    height: 32,
    width: 32,
    padding: 2,
    '@media (max-width: 700px)': {
        width: 'calc(50% - 6px)',
    },
    '&:not(:last-child)': {
        marginRight: 6,
    },
    '& svg': {
        width: 18,
        height: 18,
        '& path': {
            fill: 'rgba(0, 0, 0, 0.6)',
        },
    },
    '&.Mui-disabled': {
        '& svg': {
            '& path': {
                fill: 'rgba(0, 0, 0, 0.26)',
            },
        },
    },
});
