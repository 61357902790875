export const notificationsConstants = {
    NOTIFICATIONS_FETCH: 'NOTIFICATIONS_FETCH',
    NOTIFICATIONS_SUCCESS: 'NOTIFICATIONS_SUCCESS',
    NOTIFICATIONS_LOAD_MORE_SUCCESS: 'NOTIFICATIONS_LOAD_MORE_SUCCESS',
    NOTIFICATIONS_ERROR: 'NOTIFICATIONS_ERROR',
    NOTIFICATIONS_SET: 'NOTIFICATIONS_SET',
};

export const NOTIFICATION_TYPE = {
    SURVEY: 0,
    REVIEW: 1,
    MESSAGE: 2,
};
