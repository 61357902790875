import Skeleton from '@mui/material/Skeleton';

const ConversationsItemSkeleton = () => {
    return (
        <div style={{ border: '1px solid rgba(0, 0, 0, 0.12)', padding: 8, borderRadius: 4, marginBottom: 8 }}>
            <div style={{ display: 'grid', gridGap: '0 8px', gridTemplateColumns: '40px calc(100% - 48px)' }}>
                <Skeleton variant="rectangular" width={40} height={40} sx={{ borderRadius: '50%' }} />
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', marginBottom: 2 }}>
                        <Skeleton variant="rectangular" width={'50%'} height={16} sx={{ borderRadius: '2px' }} />
                        <Skeleton variant="rectangular" width={'20%'} height={14} sx={{ borderRadius: '2px' }} />
                    </div>
                    <Skeleton
                        variant="rectangular"
                        width={'50%'}
                        height={16}
                        sx={{ borderRadius: '2px', marginBottom: '2px' }}
                    />
                    <Skeleton variant="rectangular" width={'100%'} height={14} sx={{ borderRadius: '2px' }} />
                </div>
            </div>
            <div style={{ width: '100%', overflow: 'hidden', display: 'flex', marginTop: '8px', gap: 4 }}>
                <Skeleton variant="rectangular" width={60} height={20} sx={{ borderRadius: '4px' }} />
                <Skeleton variant="rectangular" width={60} height={20} sx={{ borderRadius: '4px' }} />
            </div>
        </div>
    );
};

export default ConversationsItemSkeleton;
