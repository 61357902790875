import { socialPostsConstants } from '@constants/social-posts';
import { socialPostsService } from '@services';

function request() {
    return { type: socialPostsConstants.SOCIAL_POSTS_FETCH };
}

function success(data) {
    return { type: socialPostsConstants.SOCIAL_POSTS_SUCCESS, data };
}

function failure(message) {
    return { type: socialPostsConstants.SOCIAL_POSTS_ERROR, message };
}

const get = (page = 0, pageSize = 10) => {
    return dispatch => {
        dispatch(request());
        socialPostsService.get(page, pageSize).then(
            data => dispatch(success(data)),
            () => dispatch(failure()),
        );
    };
};

const setSocialPosts = data => {
    return { type: socialPostsConstants.SET_SOCIAL_POSTS, data };
};

export const socialPostsActions = {
    get,
    setSocialPosts,
};
