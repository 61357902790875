export const PLUSPOINT_INTEGRATION = 4;

export const GOOGLE_OAUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${window.location.origin}/account-settings/integrations&access_type=offline&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusiness.manage%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusinesscommunications%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&include_granted_scopes=true&state=pass-through%20value&flowName=GeneralOAuthFlow&prompt=consent`;
export const GOOGLE_ONBOARDING_OAUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${window.location.origin}/onboarding&access_type=offline&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusiness.manage%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusinesscommunications%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&include_granted_scopes=true&state=pass-through%20value&flowName=GeneralOAuthFlow&prompt=consent`;
export const ZAPIER_URL = 'https://zapier.com/apps/pluspoint/integrations';
export const GOOGLE_OAUTH_SIGN_IN_URL = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${window.location.origin}/login&access_type=offline&response_type=code&scope=openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&include_granted_scopes=true&state=pass-through%20value&flowName=GeneralOAuthFlow`;
export const GOOGLE_OAUTH_SIGN_UP_URL = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${window.location.origin}/signup&access_type=offline&response_type=code&scope=openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusiness.manage%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusinesscommunications&include_granted_scopes=true&state=pass-through%20value&flowName=GeneralOAuthFlow`;

// Profiles List
export const PROFILES_DICTIONARY = [
    { name: 'Google', type: 3 },
    { name: 'Facebook', type: 2 },
    { name: 'Trustpilot', type: 8 },
    { name: 'Yelp', type: 1 },
    { name: 'TripAdvisor', type: 9 },
    { name: 'Instagram', type: 12 },
    { name: 'Booking.com', type: 16 },
    { name: 'YouTube', type: 24 },
    { name: 'X', type: 25 },
    { name: 'LinkedIn', type: 26 },
    { name: 'Pinterest', type: 27 },
    { name: 'Tiktok', type: 28 },
];

export const ProfileType = {
    1: 'Yelp',
    2: 'Facebook',
    3: 'Google',
    8: 'Trustpilot',
    9: 'TripAdvisor',
    12: 'Instagram',
    16: 'Booking.com',
    24: 'Youtube',
    25: 'X',
    26: 'LinkedIn',
    27: 'Pinterest',
    28: 'Tiktok',
};

export const ProfileName = {
    Yelp: 1,
    Facebook: 2,
    Google: 3,
    Trustpilot: 8,
    TripAdvisor: 9,
    Instagram: 12,
    'Booking.com': 16,
    YouTube: 24,
    X: 25,
    LinkedIn: 26,
    Pinterest: 27,
    Tiktok: 28,
};

// Location Integrations
export const INTEGRATIONS_LOCATION_DICTIONARY = [
    { name: 'Google', type: 10 },
    { name: 'Facebook & Instagram', type: 20 },
    { name: 'CoverManager', type: 19 },
    { name: 'iFood', type: 17 },
];

export const LocationIntegrationName = {
    Google: 10,
    Facebook: 20,
    CoverManager: 19,
    iFood: 17,
};

export const LocationIntegrationType = {
    10: 'Google',
    17: 'iFood',
    19: 'CoverManager',
    20: 'Facebook',
};

// Account Integrations
export const INTEGRATIONS_ACCOUNT_DICTIONARY = [
    { id: 11, name: 'Zapier' },
    { id: 10, name: 'Google' },
    { id: 20, name: 'Facebook' },
    { id: 0, name: 'API integration' },
    { id: 17, name: 'iFood' },
    { id: 19, name: 'CoverManager' },
    { id: 18, name: 'VTEX' },
    { id: 1, name: 'Salesforce' },
    { id: 2, name: 'Xero' },
    { id: 3, name: 'Pipedrive' },
    { id: 4, name: 'Hubspot' },
    { id: 5, name: 'QuickBooks' },
];

export const AccIntegrationName = {
    Zapier: 11,
    Google: 10,
    Facebook: 20,
    'API integration': 0,
    iFood: 17,
    CoverManager: 19,
    VTEX: 18,
    Salesforce: 1,
    Xero: 2,
    Pipedrive: 3,
    Hubspot: 4,
    QuickBooks: 5,
};

// Automated Surveys
export const AutoSurveysIntegrationName = {
    'API integration': 0,
    'Other integrations': 1,
    Zapier: 11,
    VTEX: 18,
    CoverManager: 19,
    Email: 99,
};

// Filters Integrations and Profiles
export const FiltersIntegrationName = {
    Yelp: 1,
    Facebook: 2,
    Google: 3,
    Trustpilot: 8,
    TripAdvisor: 9,
    'Booking.com': 16,
    iFood: 17,
};

export const FiltersIntegrationType = {
    1: 'Yelp',
    2: 'Facebook',
    3: 'Google',
    8: 'Trustpilot',
    9: 'TripAdvisor',
    16: 'Booking.com',
    17: 'iFood',
};

export const ApiIntegrationLinks = {
    GB: 'https://pluspoint.notion.site/04f71770bcf34578b7df44a020603eaa',
    ES: 'https://pluspoint.notion.site/58d59aed334043e7b70a540f5c67c581',
    PT: 'https://pluspoint.notion.site/16b6bbc063d3422a91c5f3a9b92417ba',
    UA: 'https://pluspoint.notion.site/641fbe2ac16241b1a02dbdc368f932f7',
};
