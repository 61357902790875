export const autoreplyRulesConstants = {
    AUTOREPLY_RULES_SUCCESS: 'AUTOREPLY_RULES_SUCCESS',
    AUTOREPLY_RULES_ERROR: 'AUTOREPLY_RULES_ERROR',
    AUTOREPLY_RULES_REQUEST: 'AUTOREPLY_RULES_REQUEST',
};

export const REVIEW_TYPE = {
    WITHOUT_COMMENT: 0,
    WITH_COMMENT: 1,
};

export const AUTOREPLY_TYPE = {
    TEMPLATES: 0,
    AI: 1,
};
