import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import Tooltip from '@components/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import { Input } from '@components/Input';
import { MultiSelect } from '@components/MultiSelect';
import { Checkbox } from '@components/Checkbox';
import { Typography } from '@components/Typography';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { useTranslation } from '@hooks/useTranslation';

const Container = styled('div')({
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateAreas: '"input1" "select1" "select2"',
    gridTemplateColumns: '100%',
    '@media (max-width: 600px)': {
        gridGap: '16px',
    },
});

const CheckboxWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '& .MuiCheckbox-root': {
        padding: 4,
    },
});

const WhatsAppForm = ({ labelsOptions }) => {
    const companies = useSelector(state => state.account?.account?.companies || []);

    const { t } = useTranslation();
    const { controlWhatsApp, watchWhatsApp, setValueWhatsApp, getValuesWhatsApp } = useFormContext();

    const selectedLocationLabels = watchWhatsApp('locationLabels');

    const companyOptions = useMemo(() => {
        return selectedLocationLabels.length === labelsOptions.length
            ? companies.map(item => ({ value: item.companyId, label: item.internalName }))
            : companies
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [companies, labelsOptions, selectedLocationLabels]);

    return (
        <Container>
            <Controller
                control={controlWhatsApp}
                name="name"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsChannels.channelName')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                        sx={{ gridArea: 'input1' }}
                    />
                )}
            />
            <Controller
                control={controlWhatsApp}
                name="locationLabels"
                render={({ field, ref }) => (
                    <MultiSelect
                        {...field}
                        ref={ref}
                        label={t('common.locationLabels')}
                        onChange={({ target: { value } }) => {
                            const selectedLabels = getMultiselectSelectedOptions(labelsOptions, value);
                            setValueWhatsApp('locationLabels', selectedLabels, { shouldDirty: true });
                            const availableCompanies =
                                selectedLabels.length === labelsOptions.length
                                    ? companies.map(item => item.companyId)
                                    : companies
                                          .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                                          .map(item => item.companyId);
                            setValueWhatsApp('companies', availableCompanies, {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                        }}
                        options={labelsOptions}
                        style={{ gridArea: 'select1' }}
                        countable
                    />
                )}
            />
            <Controller
                control={controlWhatsApp}
                name="companies"
                render={({ field, fieldState: { error }, ref }) => (
                    <MultiSelect
                        {...field}
                        ref={ref}
                        onChange={({ target: { value } }) => {
                            setValueWhatsApp('companies', getMultiselectSelectedOptions(companyOptions, value), {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                        }}
                        options={companyOptions}
                        label={t('common.locations')}
                        style={{ gridArea: 'select2' }}
                        fullWidth
                        countable
                        required
                        error={error}
                    />
                )}
            />
            <CheckboxWrapper>
                <Controller
                    control={controlWhatsApp}
                    name="newWhatsAppAccount"
                    render={({ ref }) => (
                        <Checkbox
                            ref={ref}
                            inputProps={{ 'aria-label': 'controlled' }}
                            onChange={e => setValueWhatsApp('newWhatsAppAccount', e.target.checked)}
                            checked={getValuesWhatsApp('newWhatsAppAccount')}
                        />
                    )}
                />
                <Typography variant="body2" sx={{ marginRight: '8px' }}>
                    {t('SettingsChannels.isNewWhatsAppAcc')}
                </Typography>
                <IconButton>
                    <Tooltip title={t('SettingsChannels.whatsAppTooltip')} placement="top" enterTouchDelay={0}>
                        <HelpIcon sx={{ fontSize: '16px' }} />
                    </Tooltip>
                </IconButton>
            </CheckboxWrapper>
        </Container>
    );
};

export default WhatsAppForm;
