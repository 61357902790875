import { useState, Fragment, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from '@hooks/useTranslation';
import MenuItem from '@mui/material/MenuItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import { ModalBase } from '@components/Modal';
import Submenu from './Submenu';
import { StyledPopper, StyledArrowRight, StyledMenuHeader, ScrollableSection } from './styles';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        '@media (max-width: 1024px)': {
            width: 'calc(100% - 32px)',
        },
        '@media (max-width: 600px)': {
            maxHeight: '600px',
        },
    },
    '& .modal-base-content': {
        padding: '8px 0',
    },
});

export const DesktopMenuItem = ({ item }) => {
    return (
        <Fragment key={item.text}>
            {item.divider && <Divider sx={{ width: '100%', marginTop: '8px', marginBottom: '8px' }} />}
            <MenuItem onClick={item.onClick}>
                {item.icon}
                <span style={{ marginRight: 16 }}>{item.text}</span>
                {item.sub?.header && <StyledArrowRight />}
            </MenuItem>
            {item.sub?.header && <Submenu anchorEl={item.anchorEl} menu={item.sub} />}
        </Fragment>
    );
};

const DesktopMenu = ({ options, menuButton, anchorEl, onCloseMenu, mainTitle }) => {
    return (
        <>
            {menuButton}
            {anchorEl && (
                <ClickAwayListener onClickAway={onCloseMenu}>
                    <StyledPopper
                        anchorEl={anchorEl}
                        open
                        disablePortal={true}
                        popperOptions={{ placement: 'auto-start' }}
                    >
                        <StyledMenuHeader>{mainTitle}</StyledMenuHeader>
                        <ScrollableSection>
                            {options.map(item => (
                                <DesktopMenuItem key={item.text} item={item} />
                            ))}
                        </ScrollableSection>
                    </StyledPopper>
                </ClickAwayListener>
            )}
        </>
    );
};

const MobileMenuItem = ({ item, onClick, onClickSub }) => (
    <Fragment key={item.text}>
        {item.divider && <Divider sx={{ width: '100%' }} />}
        <MenuItem onClick={item.sub?.header ? onClickSub : onClick}>
            {item.icon}
            <span style={{ marginRight: 16 }}>{item.text}</span>
            {item.sub?.header && <StyledArrowRight />}
        </MenuItem>
    </Fragment>
);

const MobileMenu = ({ options, openModal, handleCloseModal, menuButton, mainTitle }) => {
    const [subMenu, setSubMenu] = useState(null);
    const { t } = useTranslation();

    const onClickItem = item => {
        item.onClick();
        setSubMenu(null);
        handleCloseModal();
    };

    const handleBack = () => {
        setSubMenu(null);
    };

    const onCloseModal = () => {
        setSubMenu(null);
        handleCloseModal();
    };

    const currentData = useMemo(() => (subMenu ? subMenu.list : options), [subMenu, options]);

    return (
        <>
            {menuButton}
            {openModal && (
                <Modal
                    isOpen={openModal}
                    handleClose={onCloseModal}
                    title={subMenu ? subMenu.header : mainTitle}
                    onSecondaryAction={onCloseModal}
                    secondaryActionText={t('buttons.cancel')}
                    onAdditionalAction={subMenu && handleBack}
                    additionalTextButton={t('buttons.back')}
                    additionalButtonType="outlined"
                >
                    {currentData.map(item => (
                        <MobileMenuItem
                            key={item.text}
                            item={item}
                            onClick={() => onClickItem(item)}
                            onClickSub={() => setSubMenu(item.sub)}
                        />
                    ))}
                </Modal>
            )}
        </>
    );
};

const MenuList = ({ options = [], anchorEl, onCloseMenu, openModal, handleCloseModal, menuButton, mainTitle }) => {
    const isMobile = useMediaQuery('@media (max-width: 600px)');

    return isMobile ? (
        <MobileMenu
            options={options}
            openModal={openModal}
            handleCloseModal={handleCloseModal}
            menuButton={menuButton}
            mainTitle={mainTitle}
        />
    ) : (
        <DesktopMenu
            options={options}
            anchorEl={anchorEl}
            onCloseMenu={onCloseMenu}
            mainTitle={mainTitle}
            menuButton={menuButton}
        />
    );
};

export default MenuList;
