import { analyticsConstants } from '@constants/analytics';

const conversionOverallPrototype = {
    id: 'Overall',
    surveysSent: 0,
    surveysSentPercent: 100,
    invitesSentPercent: 100,
    responsesReceived: 0,
    responsesReceivedPercent: 0,
    leaveReviewClicked: 0,
    leaveReviewClickedPercent: 0,
    linksClicked: 0,
    linksClickedPercent: 0,
    reviewsReceived: 0,
    reviewsReceivedPercent: 0,
    responseRate: '0.00',
    responseRateDelta: '0.00',
    reviewRate: '0.00',
    reviewRateDelta: '0.00',
};

const initialState = {
    conversionLoading: false,
    surveysConversionData: {
        tableData: [],
        overall: { ...conversionOverallPrototype },
    },
    qrConversionData: {
        tableData: [],
        overall: { ...conversionOverallPrototype },
    },
    conversionError: null,
    reviewsLoading: false,
    reviewsData: {
        tableData: [],
        overall: {
            averageRatingDelta: '0.00',
            averageRating: '0.00',
            totalReviewsDelta: '0.00',
            totalReviews: 0,
            ratingDistributions: [],
        },
        channelData: [],
        sentimentData: [],
    },
    reviewsError: null,
    feedbackLoading: false,
    feedbackData: {
        tableData: [],
        overall: {
            averageRatingDelta: '0.00',
            averageRating: '0.00',
            totalResponsesDelta: '0.00',
            totalResponses: 0,
            netPromoterScore: 0,
            netPromoterScoreDelta: '0.00',
            negativeResponses: 0,
            negativeResponsesDelta: '0.00',
            ratingDistributions: [],
        },
        sentimentData: [],
        npsData: [],
    },
    feedbackError: null,
    conversationsLoading: true,
    conversationsData: {
        tableData: [],
        trendData: [],
        rates: {
            closedConversationsCount: 0,
            closedConversationsCountDelta: 0,
            conversationsCount: 0,
            conversationsCountDelta: 0,
            openConversationsCount: 0,
            resolutionRate: 0,
            resolutionRateDelta: 0,
            resolutionTime: 0,
            resolutionTimeDelta: 0,
            responseRate: 0,
            responseRateDelta: 0,
            responseTime: 0,
            responseTimeDelta: 0,
            unassignedConversationsCount: 0,
            channelsBreakdown: [],
        },
    },
    conversationsError: null,
    socialPostsLoading: true,
    socialPostsData: {
        impressionsTrendData: [],
        reachTrendData: [],
        engagementsTrendData: [],
        tableData: [],
        rates: {
            impressions: 0,
            impressionsDelta: '0.00',
            reach: 0,
            reachDelta: '0.00',
            likes: 0,
            likesDelta: '0.00',
            comments: 0,
            commentsDelta: '0.00',
            shares: 0,
            sharesDelta: '0.00',
        },
    },
    socialPostsError: null,
    presenceLoading: true,
    presenceData: {
        keywords: [],
        devicesDistribution: {
            googleMapsDesktop: 0,
            googleMapsDesktopPercentage: 0.0,
            googleMapsMobile: 0,
            googleMapsMobilePercentage: 0.0,
            googleSearchDesktop: 0,
            googleSearchDesktopPercentage: 0.0,
            googleSearchMobile: 0,
            googleSearchMobilePercentage: 0.0,
        },
        locationsDistribution: [],
        engagementsDistribution: [],
        viewsDistribution: [],
        rates: {
            searchAppearance: 0,
            searchAppearanceChange: 0,
            views: 0,
            viewsChange: 0,
            engagements: 0,
            engagementsChange: 0,
            conversion: 0,
            conversionChange: 0,
        },
    },
    presenceError: null,
};

export function analytics(state = initialState, action) {
    switch (action.type) {
        case analyticsConstants.ANALYTICS_CONVERSION_REQUEST:
            return { ...state, conversionLoading: true };
        case analyticsConstants.ANALYTICS_CONVERSION_SUCCESS:
            return {
                ...state,
                conversionLoading: false,
                surveysConversionData: action.payload.surveys,
                qrConversionData: action.payload.qr,
            };
        case analyticsConstants.ANALYTICS_CONVERSION_FAILURE:
            return { ...state, conversionLoading: false, conversionError: action.payload };
        case analyticsConstants.ANALYTICS_REVIEWS_REQUEST:
            return { ...state, reviewsLoading: true };
        case analyticsConstants.ANALYTICS_REVIEWS_SUCCESS:
            return { ...state, reviewsLoading: false, reviewsData: action.payload };
        case analyticsConstants.ANALYTICS_REVIEWS_FAILURE:
            return { ...state, reviewsLoading: false, reviewsError: action.payload };
        case analyticsConstants.ANALYTICS_FEEDBACK_REQUEST:
            return { ...state, feedbackLoading: true };
        case analyticsConstants.ANALYTICS_FEEDBACK_SUCCESS:
            return { ...state, feedbackLoading: false, feedbackData: action.payload };
        case analyticsConstants.ANALYTICS_FEEDBACK_FAILURE:
            return { ...state, feedbackLoading: false, feedbackError: action.payload };
        case analyticsConstants.ANALYTICS_CONVERSATIONS_SUCCESS:
            return { ...state, conversationsLoading: false, conversationsData: action.payload };
        case analyticsConstants.ANALYTICS_CONVERSATIONS_FAILURE:
            return { ...state, conversationsLoading: false, conversationsError: action.payload };
        case analyticsConstants.ANALYTICS_SOCIAL_POSTS_REQUEST:
            return { ...state, socialPostsLoading: true };
        case analyticsConstants.ANALYTICS_SOCIAL_POSTS_SUCCESS:
            return { ...state, socialPostsLoading: false, socialPostsData: action.payload };
        case analyticsConstants.ANALYTICS_SOCIAL_POSTS_FAILURE:
            return { ...state, socialPostsLoading: false, socialPostsError: action.payload };
        case analyticsConstants.ANALYTICS_PRESENCE_REQUEST:
            return { ...state, presenceLoading: true };
        case analyticsConstants.ANALYTICS_PRESENCE_SUCCESS:
            return { ...state, presenceLoading: false, presenceData: action.payload };
        case analyticsConstants.ANALYTICS_PRESENCE_FAILURE:
            return { ...state, presenceLoading: false, presenceError: action.payload };
        default:
            return state;
    }
}
