import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@components/Typography';
import { ModalConfirm } from '@components/Modal';
import MoreMenu from '@components/MoreMenu';
import { INTEGRATIONS_LOCATION_DICTIONARY } from '@constants/integrations';
import { accountActions, alertActions } from '@actions';
import { companyService } from '@services';
import { LocationIntegrationName } from '@constants/integrations';
import IFoodIntegration from './IFoodIntegration';
import GenericIntegration from './GenericIntegration';
import ManageProfileModal from '../ManageProfileModal';
import { useTranslation } from '@hooks/useTranslation';
import { Container, TableText, StyledTable, Point } from './styles';

const IntegrationDrawer = props => {
    switch (Number(props.integrationType)) {
        case 17:
            return <IFoodIntegration {...props} />;
        case 10: // Google
        case 19: // CoverManager
        case 20: // Facebook
            return <GenericIntegration {...props} />;
        default:
            return null;
    }
};

const getColumnNames = t => [
    {
        key: 0,
        component: <Typography variant="subtitle2">{t('SettingsCompanies.integrationName')}</Typography>,
    },
    {
        key: 1,
        component: (
            <Typography align="left" variant="subtitle2">
                {t('common.status')}
            </Typography>
        ),
    },
    {
        key: 2,
        component: <div />,
    },
];

const ACTION = { CONNECT: 0, DISCONNECT: 1, MANAGE: 2 };

const getRowsData = (account, company, onActionClick, anchor, handleOpenEl, handleCloseEl) => {
    const { t } = useTranslation();
    const history = useHistory();
    return INTEGRATIONS_LOCATION_DICTIONARY.map(({ name, type }, index) => {
        const profile = company?.profiles?.find(profile => profile.type === type);
        const integrationConnected = account?.integrations.find(i => i.type === type)?.isActive;
        const isGoogleOrFb = type === LocationIntegrationName.Google || type === LocationIntegrationName.Facebook;
        const isDisconnected = !profile && !integrationConnected;

        const getMenu = () => {
            if (isDisconnected) {
                return [
                    {
                        id: 0,
                        title: t('buttons.connect'),
                        onClick: () => history.push('/account-settings/integrations'),
                    },
                ];
            } else if (profile && !integrationConnected) {
                return [
                    {
                        id: 0,
                        title: t('buttons.connect'),
                        onClick: () => history.push('/account-settings/integrations'),
                    },
                    {
                        id: 1,
                        title: t('buttons.disconnect'),
                        onClick: () => onActionClick(type, ACTION.DISCONNECT, profile?.profileId),
                    },
                ];
            } else if (!profile && integrationConnected) {
                return [
                    {
                        id: 0,
                        title: t('buttons.connect'),
                        onClick: () => onActionClick(type, ACTION.CONNECT, profile?.profileId),
                    },
                ];
            } else {
                return [
                    {
                        id: 0,
                        title: isGoogleOrFb ? t('buttons.manage') : t('buttons.connect'),
                        onClick: () =>
                            onActionClick(type, isGoogleOrFb ? ACTION.MANAGE : ACTION.CONNECT, profile?.profileId),
                    },
                    {
                        id: 1,
                        title: t('buttons.disconnect'),
                        onClick: () => onActionClick(type, ACTION.DISCONNECT, profile?.profileId),
                    },
                ];
            }
        };
        return [
            {
                component: (
                    <TableText variant="subtitle2" align="left">
                        {name}
                    </TableText>
                ),
            },
            {
                component:
                    profile && !integrationConnected ? (
                        <TableText variant="body2" align="left">
                            <Point type="red" />
                            {t('SettingsCompanies.disconnected')}
                        </TableText>
                    ) : (
                        <TableText variant="body2" align="left">
                            <Point type={integrationConnected && profile ? 'green' : ''} />
                            {t(`SettingsCompanies.${integrationConnected && profile ? 'connected' : 'notConnected'}`)}
                        </TableText>
                    ),
            },
            {
                component: (
                    <MoreMenu
                        id={index}
                        menu={getMenu()}
                        handleOpenEl={handleOpenEl}
                        handleCloseEl={handleCloseEl}
                        anchor={anchor}
                    />
                ),
            },
        ];
    });
};

const IntegrationsTable = ({ account, selectedCompany, setSelectedCompany }) => {
    const { t } = useTranslation();
    const [integration, setIntegration] = useState({ open: false, type: null, id: null });
    const [isDisconnectModalOpen, setDisconnectModalOpen] = useState(false);
    const [isProfileModalOpen, setProfileModalOpen] = useState(false);
    const [anchor, setAnchor] = useState({ el: null, id: null });

    const dispatch = useDispatch();

    const handleOpenEl = (event, id) => {
        event.stopPropagation();
        setAnchor({ id, el: event.currentTarget });
    };

    const handleCloseEl = () => setAnchor(prev => ({ ...prev, el: null }));

    const onIntegrationClick = async (type, action, id) => {
        handleCloseEl();
        switch (action) {
            case ACTION.CONNECT:
                setIntegration(prev => ({ ...prev, open: true, type }));
                return;
            case ACTION.DISCONNECT:
                setIntegration(prev => ({ ...prev, type, id }));
                setDisconnectModalOpen(true);
                return;
            case ACTION.MANAGE:
                setIntegration(prev => ({ ...prev, type, id }));
                setProfileModalOpen(true);
                return;
            default:
                return;
        }
    };

    const disconnectIntegration = async () => {
        try {
            await companyService.deleteProfile(selectedCompany.companyId, integration.id);
            await dispatch(accountActions.get());
            setSelectedCompany(prev => ({
                ...prev,
                profiles: prev?.profiles?.filter(p => p.profileId !== integration.id),
            }));
            if (isProfileModalOpen) {
                setProfileModalOpen(false);
            }
            setDisconnectModalOpen(false);
            setIntegration(prev => ({ ...prev, type: null, id: null }));
            dispatch(alertActions.success(t('SettingsCompanies.profileDisconnected')));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
    };

    const onCloseDrawer = () => setIntegration(prev => ({ ...prev, open: false, type: null }));

    const onCloseProfileModal = () => {
        setProfileModalOpen(false);
    };

    return (
        <>
            <Container>
                <Typography variant="subtitle1" sx={{ color: 'rgba(0, 0, 0, 0.87)', marginBottom: '16px' }}>
                    {t('SettingsCompanies.integrations')}
                </Typography>
                <TableContainer>
                    <StyledTable
                        columnNames={getColumnNames(t)}
                        rows={getRowsData(
                            account,
                            selectedCompany,
                            onIntegrationClick,
                            anchor,
                            handleOpenEl,
                            handleCloseEl,
                        )}
                    />
                </TableContainer>
            </Container>
            {integration.open ? (
                <IntegrationDrawer
                    isOpen={integration.open}
                    onClose={onCloseDrawer}
                    onConnect={onCloseDrawer}
                    integrationType={integration.type}
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                />
            ) : null}
            {isDisconnectModalOpen && (
                <ModalConfirm
                    isOpen={isDisconnectModalOpen}
                    handleClose={() => setDisconnectModalOpen(false)}
                    title={t('SettingsCompanies.disconnectIntegration')}
                    description={t('SettingsCompanies.disconnectIntegrationMessage')}
                    onPrimaryAction={disconnectIntegration}
                    primaryActionText={t('buttons.disconnect')}
                    primaryActionType="danger"
                />
            )}
            {isProfileModalOpen && (
                <ManageProfileModal
                    type={integration.type}
                    open={isProfileModalOpen}
                    onCloseModal={onCloseProfileModal}
                    selectedCompany={selectedCompany}
                    setDisconnectModalOpen={setDisconnectModalOpen}
                />
            )}
        </>
    );
};

export default IntegrationsTable;
