import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@components/Button';
import { ModalConfirm } from '@components/Modal';
import PageHeader from '@features/PageHeader';
import { accountActions, alertActions, brandsActions } from '@actions';
import { brandsService } from '@services';
import { useTranslation } from '@hooks/useTranslation';
import { BrandsIcon } from 'assets/images/icons';
import BrandsTable from './BrandsTable';
import EditModal, { FORM_TYPE } from './EditModal';

const Brands = () => {
    const [selectedBrand, setSelectedBrand] = useState({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [modal, setModal] = useState({ open: false, type: FORM_TYPE.NEW });

    const locations = useSelector(state => state.account?.account?.companies || []);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            dispatch(brandsActions.get(page, rowsPerPage));
            firstRender.current = false;
        }
    }, [page, rowsPerPage, dispatch]);

    const onDelete = async () => {
        try {
            await brandsService.deleteBrand(selectedBrand?.id);
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
            dispatch(accountActions.get());
            if (page === 0) {
                await dispatch(brandsActions.get(page, rowsPerPage));
            } else {
                setPage(0); // Triggers refetch of templates
            }
            setDeleteModalOpen(false);
            setSelectedBrand({});
        } catch (_) {
            dispatch(alertActions.error(t('alertMessages.deleteFail')));
        }
    };

    return (
        <div style={{ paddingBottom: '16px' }}>
            <PageHeader
                styles={{ marginBottom: '16px' }}
                icon={<BrandsIcon />}
                title={t('SettingsBrands.title')}
                subtitle={t('SettingsBrands.subtitle')}
            >
                <Button variant="contained" onClick={() => setModal(prev => ({ ...prev, open: true }))}>
                    {t('buttons.createBrand')}
                </Button>
            </PageHeader>
            <BrandsTable
                locations={locations}
                setSelectedBrand={setSelectedBrand}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setModal={setModal}
                setDeleteModalOpen={setDeleteModalOpen}
            />
            {modal.open ? (
                <EditModal
                    selectedBrand={selectedBrand}
                    setSelectedBrand={setSelectedBrand}
                    modal={modal}
                    setModal={setModal}
                    setPage={setPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    locations={locations}
                />
            ) : null}
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDelete}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                    onSecondaryAction={() => {
                        setDeleteModalOpen(false);
                        setSelectedBrand({});
                    }}
                />
            )}
        </div>
    );
};

export default Brands;
