import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCurrentEditor } from '@tiptap/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import MenuList from '@components/MenuList';
import { ModalConfirm } from '@components/Modal';
import { useTranslation } from '@hooks/useTranslation';
import { addTipTapMarkTag } from '@helpers/text-editor';
import { inboxTemplatesService } from '@services';
import { alertActions, inboxTemplatesActions } from '@actions';
import { InboxTemplatesIcon } from 'assets/images/icons';
import CreateTemplateModal from './CreateTemplateModal';
import { StyledSaveIcon, StyledDeleteIcon } from './styles';

const TemplateMenu = ({ disabled, setMessage, message, selectedConversation }) => {
    const { t } = useTranslation();
    const { editor } = useCurrentEditor();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('@media (max-width: 600px)');

    const [anchorElMain, setAnchorElMain] = useState(null);
    const [anchorElDelete, setAnchorElDelete] = useState(null);
    const [anchorElEdit, setAnchorElEdit] = useState(null);

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const { data } = useSelector(state => state.inboxTemplates);
    const companies = useSelector(state => state.account?.account?.companies || []);
    const user = useSelector(state => state.authentication?.user);
    const IS_ACCOUNT_OWNER = user?.isAccountOwner || user?.isGlobalAdmin;

    const handleOpenMain = event => {
        if (isMobile) {
            setMobileMenuOpen(true);
        } else {
            setAnchorElMain(event.currentTarget);
            setAnchorElDelete(null);
            setAnchorElEdit(null);
        }
    };
    const handleOpenDelete = event => {
        setAnchorElDelete(event.currentTarget);
        setAnchorElEdit(null);
    };
    const handleOpenEdit = event => {
        setAnchorElEdit(event.currentTarget);
        setAnchorElDelete(null);
    };

    const handleCloseMain = () => setAnchorElMain(null);

    const onClickTemplate = template => {
        const newMessage = template.message
            ?.replace(/\[name\]/g, selectedConversation?.firstName || '')
            ?.replace(/\[last_name\]/g, selectedConversation?.lastName || '')
            ?.replace(
                /\[location\]/g,
                companies.find(c => c.companyId === selectedConversation?.companyId)?.locationName || '',
            );
        setMessage(newMessage);
        editor?.commands.setContent(addTipTapMarkTag(newMessage));
        setMobileMenuOpen(false);
        setAnchorElMain(null);
    };

    const onEditTemplate = async () => {
        try {
            await inboxTemplatesService.addOrUpdateInboxTemplate({ ...selectedTemplate, message });
            dispatch(alertActions.success(t('alertMessages.editSuccess')));
            await dispatch(inboxTemplatesActions.getAll());
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.editFail')));
        }
        setEditModalOpen(false);
        setAnchorElEdit(null);
        setSelectedTemplate(null);
    };

    const onDeleteTemplate = async () => {
        try {
            await inboxTemplatesService.deleteInboxTemplate(selectedTemplate.id);
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
            await dispatch(inboxTemplatesActions.getAll());
            setDeleteModalOpen(false);
            setAnchorElDelete(null);
            setSelectedTemplate(null);
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.deleteFail')));
        }
    };

    const ACTIONS = {
        INSERT: template => () => onClickTemplate(template),
        EDIT: template => () => {
            setSelectedTemplate(template);
            setEditModalOpen(true);
        },
        DELETE: template => () => {
            setSelectedTemplate(template);
            setDeleteModalOpen(true);
        },
    };

    function mapTemplates(data, action) {
        return data
            .filter(t => {
                if (action === 'INSERT') return true;
                const IS_TEMPLATE_OWNER = t.authorId === user.id;
                return IS_TEMPLATE_OWNER || IS_ACCOUNT_OWNER;
            })
            .map(template => ({
                icon: null,
                text: template.name,
                sub: {},
                onClick: ACTIONS[action](template),
            }));
    }

    const MAIN_MENU = [
        ...mapTemplates(data, 'INSERT'),
        ...[
            {
                divider: true,
                icon: <StyledSaveIcon />,
                text: t('RespondTemplate.saveReplyAsTemplate'),
                sub: {
                    header: t('RespondTemplate.overwriteTemplate'),
                    list: [
                        ...mapTemplates(data, 'EDIT'),
                        {
                            divider: true,
                            icon: <StyledSaveIcon />,
                            text: t('RespondTemplate.saveAsNewTemplate'),
                            sub: {},
                            onClick: () => setCreateModalOpen(true),
                        },
                    ],
                },
                anchorEl: anchorElEdit,
                onClick: handleOpenEdit,
            },
            {
                icon: <StyledDeleteIcon />,
                text: t('RespondTemplate.deleteTemplate'),
                sub: {
                    header: t('RespondTemplate.deleteTemplate'),
                    list: mapTemplates(data, 'DELETE'),
                },
                anchorEl: anchorElDelete,
                onClick: handleOpenDelete,
            },
        ],
    ];

    return (
        <>
            <MenuList
                options={MAIN_MENU}
                anchorEl={anchorElMain}
                onCloseMenu={handleCloseMain}
                openModal={isMobileMenuOpen}
                handleCloseModal={() => setMobileMenuOpen(false)}
                mainTitle={t('RespondTemplate.insertTemplate')}
                menuButton={
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={handleOpenMain}
                        disabled={disabled}
                        sx={{ height: 18, width: 18, padding: '2px !important' }}
                    >
                        <InboxTemplatesIcon />
                    </IconButton>
                }
            />
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDeleteTemplate}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                />
            )}
            {isEditModalOpen && (
                <ModalConfirm
                    isOpen={isEditModalOpen}
                    handleClose={() => setEditModalOpen(false)}
                    title={t('RespondTemplate.editTemplate')}
                    description={t('RespondTemplate.editTemplateDescription')}
                    onPrimaryAction={onEditTemplate}
                    primaryActionText={t('buttons.save')}
                />
            )}
            {isCreateModalOpen && (
                <CreateTemplateModal
                    user={user}
                    message={message}
                    isCreateModalOpen={isCreateModalOpen}
                    setCreateModalOpen={setCreateModalOpen}
                    setAnchorElEdit={setAnchorElEdit}
                />
            )}
        </>
    );
};

export default TemplateMenu;
