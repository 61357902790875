import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { useTranslation } from '@hooks/useTranslation';
import { OpenConversationsIcon, MineConversationsIcon, UnassignedConversationsIcon } from 'assets/images/icons';
import { ASSIGNED_STATUS } from '@constants/conversations';
import MetricItem from './MetricItem';

const CardsWrapper = styled('div')({
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    height: 'calc(100% - 57px)',
    '@media (max-width: 375px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
    },
});

const Inbox = () => {
    const { t } = useTranslation();
    const { inboxes } = useSelector(state => state.dashboard.dashboard);
    const history = useHistory();

    const data = [
        {
            id: 0,
            title: t('common.open'),
            value: inboxes.open,
            subvalue: null,
            icon: <OpenConversationsIcon />,
            onClick: () => history.push(`/messaging/inbox?assignment=${ASSIGNED_STATUS.ALL}`),
        },
        {
            id: 2,
            title: t('InboxPage.mine'),
            value: inboxes.mine,
            subvalue: null,
            icon: <MineConversationsIcon />,
            onClick: () => history.push(`/messaging/inbox?assignment=${ASSIGNED_STATUS.MINE}`),
        },
        {
            id: 3,
            title: t('InboxPage.unassigned'),
            value: inboxes.unassigned,
            subvalue: null,
            icon: <UnassignedConversationsIcon />,
            onClick: () => history.push(`/messaging/inbox?assignment=${ASSIGNED_STATUS.UNASSIGNED}`),
        },
    ];

    return (
        <CardsWrapper>
            {data.map(item => (
                <MetricItem
                    key={item.id}
                    value={item.value}
                    subvalue={item.subvalue}
                    title={item.title}
                    withSign={item.withSign}
                    withBorder
                    Icon={item.icon}
                    onClick={item.onClick}
                />
            ))}
        </CardsWrapper>
    );
};

export default Inbox;
