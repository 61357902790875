import { styled } from '@mui/material/styles';
import { Input } from '@components/Input';
import Button from '@components/Button';
import { Typography } from '@components/Typography';
import { useThrottle } from '@hooks/useThrottle';
import { useCopyToClipboard } from '@hooks/useCopyToClipboard';
import { getSingleWidgetScript, getGridWidgetScript } from '@constants/widget';
import { useTranslation } from '@hooks/useTranslation';

const WidgetForm = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    '& button': {
        width: 'max-content',
    },
});

const Code = ({ widgetKey, type, data }) => {
    const { t } = useTranslation();
    const { onCopy } = useCopyToClipboard();
    const [onCopyClick] = useThrottle(value => onCopy(value), 1000);

    return (
        <WidgetForm>
            <Typography variant="caption" sx={{ marginBottom: '16px', color: 'rgba(0, 0, 0, 0.87)' }}>
                {t('ShowcaseWidgets.addCodeSubtitle')}
            </Typography>
            <Input
                inputProps={{
                    value:
                        type === 'popup'
                            ? getSingleWidgetScript(widgetKey, data)
                            : getGridWidgetScript(widgetKey, data),
                }}
                label={null}
                fullWidth
                multiline
                minRows={3}
            />
            <Button
                type="submit"
                variant="outlined"
                onClick={() =>
                    type === 'popup'
                        ? onCopyClick(getSingleWidgetScript(widgetKey, data))
                        : onCopyClick(getGridWidgetScript(widgetKey, data))
                }
            >
                {t('buttons.copyCode')}
            </Button>
        </WidgetForm>
    );
};

export default Code;
