export const profilesConstants = {
    PROFILES_FETCH: 'PROFILES_FETCH',
    PROFILES_SUCCESS: 'PROFILES_SUCCESS',
    PROFILES_ERROR: 'PROFILES_ERROR',
    SET_SYNC_STATUS: 'SET_SYNC_STATUS',
    SET_PROFILES: 'SET_PROFILES',
};

export const SYNC_TYPE = {
    ['N/A']: 0,
    SYNCED: 1,
    ACTION_REQUIRED: 2,
    IN_PROGRESS: 3,
    SYNCED_WITH_CONFLICTS: 4,
};

export const STORE_TYPE = {
    MAIN_PAGE: '1',
    CREATE_STORE: '2',
    CONNECT_STORE: '3',
};

export const SyncDiff = {
    LocationName: 1,
    Status: 2,
    PhoneNumber: 3,
    Email: 4,
    Website: 5,
    Address: 6,
    BusinessHours: 7,
    Description: 8,
    OpeningDate: 9,
    Logo: 10,
    CoverPhoto: 11,
    Facebook: 12,
    Instagram: 13,
    Youtube: 14,
    X: 15,
    LinkedIn: 16,
    Tiktok: 17,
    Pinterest: 18,
};
