import { alertConstants } from '@constants/alert';

export const alertActions = {
    success,
    error,
    clear,
    clearAll,
};

function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

function error(message) {
    return { type: alertConstants.ERROR, message };
}

function clear(id) {
    return { type: alertConstants.CLEAR, id };
}

function clearAll() {
    return { type: alertConstants.CLEAR_ALL };
}
