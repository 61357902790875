import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const aiAssistantService = {
    getStandartTask,
    getContextualTask,
};

function getStandartTask(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/integrations/ai/standard-tasks`, requestOptions).then(handleResponse);
}

function getContextualTask(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/integrations/ai/contextual-tasks`, requestOptions).then(handleResponse);
}
