import { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import ImageListItem from '@mui/material/ImageListItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import IntegrationRequired from '@features/IntegrationRequired';
import { ModalConfirm } from '@components/Modal';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { ImportIcon } from 'assets/images/icons';
import { labelsActions, profilesMediaActions, alertActions } from '@actions';
import { LABEL_TYPE } from '@constants/labels';
import { profilesMediaService } from '@services';
import { AUTHOR, FORMAT, MEDIA_STATUS } from '@constants/profiles-media';
import { AccIntegrationName } from '@constants/integrations';
import { getLocationLabels } from '@helpers/locationLabels';
import { useTranslation } from '@hooks/useTranslation';
import FiltersPanel from './FiltersPanel';
import ViewModal from './ViewModal';
import ReportModal from './ReportModal';
import UploadModal from './UploadModal';
import MediaInfo from './MediaInfo';
import { Container, FiltersWrapper, Content, Badge, StyledPopper } from './styles';

const DEFAULT_SKIP = 20;

const ProfilesMediaPage = () => {
    const [filter, setFilter] = useState({
        labels: [],
        companies: [],
        formats: [FORMAT.PHOTO, FORMAT.VIDEO],
        categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        authors: [AUTHOR.BUSINESS, AUTHOR.CUSTOMER],
        timeframe: {
            start: null,
            end: null,
        },
    });
    const [filterNumber, setFilterNumber] = useState(0);
    const [filterOpened, setFiltersOpened] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isViewModalOpen, setViewModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isReportModalOpen, setReportModalOpen] = useState(false);
    const [isUploadModalOpen, setUploadModalOpen] = useState(false);
    const [anchor, setAnchor] = useState({ el: null, id: null });
    const [loadedImages, setLoadedImages] = useState({});

    const account = useSelector(state => state.account?.account);
    const companies = useSelector(state => state.account?.account?.companies || []);
    const language = useSelector(state => state.authentication.user?.language);
    const { location: labels, locationLoading: labelsLoading } = useSelector(state => state.labels);
    const { data, loading, hasMore } = useSelector(state => state.profilesMedia);

    const firstRender = useRef(true);
    const paginationStart = useRef(0);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const GOOGLE_CONNECTED = account?.integrations.find(i => i.type === AccIntegrationName.Google)?.isActive;

    useEffect(() => {
        dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
    }, []);

    const availableCompanies = useMemo(
        () => companies.filter(item => item.profiles.some(i => i.type === AccIntegrationName.Google)),
        [companies],
    );

    const availableLabels = useMemo(
        () => getLocationLabels(labels, availableCompanies, t),
        [labels, availableCompanies],
    );

    useEffect(() => {
        if (companies.length && !labelsLoading) {
            setFilter(prev => ({
                ...prev,
                companies: availableCompanies.map(item => item.companyId),
                labels: availableLabels.map(item => item.id),
            }));
        }
    }, [companies, labelsLoading, dispatch]);

    const onApplyFilters = () => {
        paginationStart.current = 0;
        dispatch(
            profilesMediaActions.get(
                {
                    locations: filter.companies,
                    formats: filter.formats,
                    categories: filter.categories,
                    authors: filter.authors,
                    from: filter.timeframe.start ? format(new Date(filter.timeframe.start), 'yyyy-MM-dd') : null,
                    to: filter.timeframe.end ? format(new Date(filter.timeframe.end), 'yyyy-MM-dd') : null,
                },
                DEFAULT_SKIP,
                paginationStart.current,
            ),
        );
    };

    useEffect(() => {
        if (filter.companies.length && GOOGLE_CONNECTED) {
            if (firstRender.current) {
                onApplyFilters();
                firstRender.current = false;
            }
        }
    }, [filter, GOOGLE_CONNECTED, dispatch]);

    const handleOpenTop = (event, id) => {
        event.stopPropagation();
        setAnchor({ id, el: event.currentTarget });
    };
    const handleCloseTop = () => setAnchor({ id: null, el: null });

    const loadMore = () => {
        if (!hasMore) return;
        const newPaginationStart =
            paginationStart.current === 0 ? DEFAULT_SKIP : paginationStart.current + DEFAULT_SKIP;
        paginationStart.current = newPaginationStart;
        dispatch(
            profilesMediaActions.loadMore(
                {
                    locations: filter.companies,
                    formats: filter.formats,
                    categories: filter.categories,
                    authors: filter.authors,
                    from: filter.timeframe.start ? format(new Date(filter.timeframe.start), 'yyyy-MM-dd') : null,
                    to: filter.timeframe.end ? format(new Date(filter.timeframe.end), 'yyyy-MM-dd') : null,
                },
                DEFAULT_SKIP,
                newPaginationStart,
            ),
        );
    };

    const onDelete = async () => {
        try {
            await profilesMediaService.deleteMedia(selectedImage.id);
            dispatch(profilesMediaActions.set(data.filter(item => item.id !== selectedImage.id)));
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.deleteFail')));
        }
        setDeleteModalOpen(false);
        setViewModalOpen(false);
        setSelectedImage(null);
    };

    const STATUS = {
        [MEDIA_STATUS.NORMAL]: '',
        [MEDIA_STATUS.NEW]: t('common.new', { count: 1 }),
        [MEDIA_STATUS.REPORTED]: t('common.reported'),
    };

    return !GOOGLE_CONNECTED ? (
        <IntegrationRequired googleOnly />
    ) : (
        <Container>
            <FiltersWrapper>
                <FiltersPanel
                    setFiltersOpened={setFiltersOpened}
                    filtersNumber={filterNumber}
                    filterOpened={filterOpened}
                    companies={availableCompanies}
                    labels={availableLabels}
                    filter={filter}
                    setFilter={setFilter}
                    setFilterNumber={setFilterNumber}
                    onApplyFilters={onApplyFilters}
                />
                <Button
                    variant="outlined"
                    style={{ flexShrink: 0 }}
                    startIcon={<ImportIcon />}
                    onClick={() => setUploadModalOpen(true)}
                >
                    {t('buttons.upload')}
                </Button>
            </FiltersWrapper>
            <Content id="pl-media-list">
                {!loading && !!data.length && (
                    <InfiniteScroll
                        dataLength={data.length}
                        next={loadMore}
                        hasMore={hasMore}
                        loader={<div />}
                        scrollableTarget="pl-media-list"
                    >
                        <ResponsiveMasonry columnsCountBreakPoints={{ 320: 1, 601: 2, 1024: 3, 1200: 4 }}>
                            <Masonry id="pl-media-list" gutter="10px">
                                {data.map(item => (
                                    <ImageListItem
                                        key={item.id}
                                        sx={{
                                            position: 'relative',
                                            cursor: 'zoom-in',
                                            '& .MuiImageListItem-img': {
                                                borderRadius: '4px',
                                            },
                                            '&:hover': {
                                                '& .MuiImageListItemBar-root': {
                                                    opacity: 1,
                                                },
                                            },
                                        }}
                                        onClick={() => {
                                            setSelectedImage(item);
                                            setViewModalOpen(true);
                                        }}
                                    >
                                        {loadedImages[item.id] && item.status !== MEDIA_STATUS.NORMAL && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    top: 16,
                                                    left: 16,
                                                    borderRadius: '18px',
                                                    backgroundColor: '#fff',
                                                }}
                                            >
                                                <Badge variant="caption" type={item.status}>
                                                    {STATUS[item.status]}
                                                </Badge>
                                            </div>
                                        )}
                                        <img
                                            src={item.format === FORMAT.PHOTO ? item.originalUrl : item.thumbnailUrl}
                                            alt="google-photo"
                                            loading="lazy"
                                            referrerPolicy="no-referrer"
                                            onLoad={() => setLoadedImages(prev => ({ ...prev, [item.id]: true }))}
                                        />
                                        {loadedImages[item.id] && (
                                            <div>
                                                <MediaInfo
                                                    handleOpenTop={handleOpenTop}
                                                    data={item}
                                                    language={language}
                                                />
                                                {anchor.id === item.id && (
                                                    <ClickAwayListener onClickAway={handleCloseTop}>
                                                        <StyledPopper
                                                            anchorEl={anchor.el}
                                                            open={!!anchor.el}
                                                            disablePortal={true}
                                                            popperOptions={{ placement: 'bottom' }}
                                                            sx={{ borderRadius: '8px' }}
                                                        >
                                                            <MenuItem
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    setSelectedImage(item);
                                                                    item.authorType === AUTHOR.BUSINESS
                                                                        ? setDeleteModalOpen(true)
                                                                        : setReportModalOpen(true);
                                                                    handleCloseTop();
                                                                }}
                                                                sx={{ minWidth: '150px' }}
                                                            >
                                                                <Typography variant="body2">
                                                                    {item.authorType === AUTHOR.BUSINESS
                                                                        ? t('buttons.delete')
                                                                        : t('buttons.report')}
                                                                </Typography>
                                                            </MenuItem>
                                                        </StyledPopper>
                                                    </ClickAwayListener>
                                                )}
                                            </div>
                                        )}
                                    </ImageListItem>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </InfiniteScroll>
                )}
            </Content>
            {isViewModalOpen && (
                <ViewModal
                    isOpen={isViewModalOpen}
                    handleClose={() => setViewModalOpen(false)}
                    selectedImage={selectedImage}
                    setDeleteModalOpen={setDeleteModalOpen}
                    setReportModalOpen={setReportModalOpen}
                />
            )}
            {isReportModalOpen && (
                <ReportModal
                    isOpen={isReportModalOpen}
                    handleClose={() => setReportModalOpen(false)}
                    data={data}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                />
            )}
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDelete}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                />
            )}
            {isUploadModalOpen && (
                <UploadModal isOpen={isUploadModalOpen} handleClose={() => setUploadModalOpen(false)} />
            )}
        </Container>
    );
};

export default ProfilesMediaPage;
