import { memo } from 'react';
import { styled } from '@mui/material/styles';
import DoughnutItem from './DoughnutItem';
import { useTranslation } from '@hooks/useTranslation';
import MetricItem from './MetricItem';

export const MetricGrid = styled('div', { shouldForwardProp: prop => prop !== 'type' })(({ type }) => ({
    display: 'grid',
    gridGap: 16,
    gridTemplateAreas:
        type === 'reviews'
            ? '"metric1 doughnut" "metric2 doughnut"'
            : '"metric1 metric3 doughnut" "metric2 metric4 doughnut"',
    gridAutoColumns:
        type === 'reviews' ? 'minmax(270px, 300px) auto' : 'minmax(270px, 300px) minmax(270px, 300px) auto',
    marginBottom: 16,
    ...(type === 'feedbacks'
        ? {
              '@media (max-width: 1420px)': {
                  gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
                  gridTemplateAreas: '"metric1 metric2" "metric3 metric4" "doughnut doughnut"',
              },
          }
        : {}),
    '@media (max-width: 1120px)': {
        gridTemplateColumns: type === 'reviews' ? 'calc(50% - 8px) calc(50% - 8px)' : '',
        gridTemplateAreas: type === 'reviews' ? '"metric1 metric2" "doughnut doughnut"' : '',
    },
    '@media (max-width: 600px)': {
        gridTemplateColumns: '100%',
        gridTemplateAreas:
            type === 'reviews'
                ? '"metric1" "metric2" "doughnut"'
                : '"metric1" "metric2" "metric3" "metric4" "doughnut"',
    },
}));

const DoughnutRates = memo(({ data, isLoading, type }) => {
    const { t } = useTranslation();

    return (
        <MetricGrid type={type}>
            <MetricItem
                title={t('AnalyticsReviews.averageRating')}
                data={{ metric: data.averageRating, delta: data.averageRatingDelta }}
                styles={{ gridArea: 'metric1' }}
            />
            <MetricItem
                title={type === 'feedbacks' ? t('AnalyticsReviews.totalResponses') : t('AnalyticsReviews.totalReviews')}
                data={{
                    metric: type === 'feedbacks' ? data.totalResponses : data.totalReviews,
                    delta: type === 'feedbacks' ? data.totalResponsesDelta : data.totalReviewsDelta,
                }}
                styles={{ gridArea: 'metric2' }}
                withPercentageDelta
            />
            {type === 'feedbacks' ? (
                <>
                    <MetricItem
                        title={t('AnalyticsReviews.negativeResponses')}
                        data={{ metric: data.negativeResponses, delta: data.negativeResponsesDelta }}
                        styles={{ gridArea: 'metric3' }}
                        withPercentageDelta
                    />
                    <MetricItem
                        title={t('AnalyticsReviews.nps')}
                        data={{ metric: data.netPromoterScore, delta: data.netPromoterScoreDelta }}
                        styles={{ gridArea: 'metric4' }}
                    />
                </>
            ) : null}
            <DoughnutItem data={data} isLoading={isLoading} type={type} />
        </MetricGrid>
    );
});

DoughnutRates.displayName = 'DoughnutRates';

export default DoughnutRates;
