import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const inboxTemplatesService = {
    getInboxTemplates,
    addOrUpdateInboxTemplate,
    deleteInboxTemplate,
    getAllInboxTemplates,
};

function getInboxTemplates(page, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/inbox/message-templates?page=${page}&pageSize=${pageSize}`, requestOptions).then(
        handleResponse,
    );
}

function getAllInboxTemplates() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/inbox/message-templates?returnAll=true`, requestOptions).then(handleResponse);
}

function addOrUpdateInboxTemplate(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/inbox/message-templates`, requestOptions).then(handleResponse);
}

function deleteInboxTemplate(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/inbox/message-templates/${id}`, requestOptions).then(handleResponse);
}
