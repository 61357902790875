import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@components/Button';
import { Typography } from '@components/Typography';
import Avatar from '@components/Avatar';
import { getInitialsFull } from '@helpers/userName';
import { UserIcon } from 'assets/images/icons';

const StyledAvatar = styled(Avatar)({
    fontSize: '12px',
    marginRight: '10px',
});

const StyledArrow = styled(ArrowDropDownIcon)({
    height: 32,
    '& path': {
        fill: 'rgba(0, 0, 0, 0.87)',
    },
});

const StyledUserIcon = styled(UserIcon)({
    width: '12px !important',
    height: '12px !important',
    '& path': {
        fill: 'rgba(0, 0, 0, 0.38)',
        fillOpacity: 1,
    },
});

export const UsersMenu = ({ value, usersOptions, onClick, onClose, onSelect, anchorEl, open }) => {
    const selectedOption = useMemo(() => usersOptions.find(user => user.value === value), [usersOptions, value]);

    return (
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: '8px' }}>
            <Button
                variant="text"
                id="menu-button"
                aria-controls={open ? 'users-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={onClick}
                sx={{ padding: 0, minWidth: '20px', borderRadius: '45px' }}
            >
                <Avatar
                    size="medium"
                    fullName={selectedOption?.label}
                    label={getInitialsFull(selectedOption?.label)}
                    icon={<StyledUserIcon />}
                />
            </Button>
            <StyledArrow size="small" onClick={onClick} />
            <Menu
                id="users-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
                MenuListProps={{
                    'aria-labelledby': 'menu-button',
                }}
                sx={{
                    '.MuiMenu-paper': {
                        borderRadius: '8px',
                    },
                }}
            >
                {usersOptions.map(user => (
                    <MenuItem
                        key={user.value}
                        onClick={() => {
                            onSelect(user.value);
                            onClose();
                        }}
                        selected={user.value === value}
                        sx={{
                            '&.Mui-selected': {
                                backgroundColor: '#E4EAEB !important',
                            },
                        }}
                    >
                        <StyledAvatar size="small" fullName={user.label} label={getInitialsFull(user.label)} />
                        <Typography variant="body2">{user.label}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
