import { useRef, useMemo, useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Switch } from '@components/Switch';
import { Select } from '@components/Select';
import { Input } from '@components/Input';
import GoogleMap from '@components/GoogleMap';
import { useTranslation } from '@hooks/useTranslation';

export const Form = styled('div')({
    maxWidth: 698,
    display: 'grid',
    marginTop: 24,
    columnGap: 24,
    rowGap: 16,
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
    '@media (max-width: 1024px)': {
        gridTemplateColumns: 'minmax(0, 1fr)',
        maxWidth: 'none',
    },
});

const Address = ({ language, countries }) => {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const { t } = useTranslation();
    const { control, setValue, watch, trigger } = useFormContext();
    const mapRef = useRef(null);

    const hasBusinessAddress = watch('hasBusinessAddress');
    const latitude = watch('latitude');
    const longitude = watch('longitude');
    const country = watch('country');

    useEffect(() => {
        if (country) {
            setSelectedCountry(countries.find(item => item.iso2 === country));
        }
    }, [country]);

    const countriesOptions = useMemo(
        () =>
            countries.map(item => ({
                value: item.iso2,
                label: item.translations[language.toLowerCase()] || item.name,
            })),
        [countries],
    );

    const regionsOptions = useMemo(
        () => (selectedCountry ? selectedCountry.states.map(item => ({ value: item.name, label: item.name })) : []),
        [selectedCountry],
    );

    return (
        <>
            <Controller
                control={control}
                name="hasBusinessAddress"
                render={({ field, ref }) => (
                    <Switch
                        {...field}
                        ref={ref}
                        checked={field.value}
                        handleChange={event => {
                            setValue('hasBusinessAddress', event.target.checked, {
                                shouldDirty: true,
                            });
                            trigger(['addressLine1', 'city', 'country', 'postalCode']);
                        }}
                        label={t('SettingsCompanies.showAddress')}
                    />
                )}
            />
            {hasBusinessAddress && (
                <>
                    <GoogleMap
                        id="address-map"
                        options={{ center: { lat: +latitude, lng: +longitude }, zoom: 17 }}
                        onMapLoad={map => {
                            mapRef.current = map;
                        }}
                        style={{ height: 400, maxWidth: 698, marginTop: '8px' }}
                        onChange={value => {
                            setValue('latitude', value.latitude, { shouldDirty: true });
                            setValue('longitude', value.longitude, { shouldDirty: true });
                        }}
                    />
                    <Form>
                        <Controller
                            control={control}
                            name="addressLine1"
                            render={({ field, fieldState: { error }, ref }) => (
                                <Input
                                    ref={ref}
                                    inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                    label={t('SettingsCompanies.addressLine1')}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? t(error.message) : null}
                                    required
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="addressLine2"
                            render={({ field, fieldState: { error }, ref }) => (
                                <Input
                                    ref={ref}
                                    inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                    label={t('SettingsCompanies.addressLine2')}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? t(error.message) : null}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="city"
                            render={({ field, fieldState: { error }, ref }) => (
                                <Input
                                    ref={ref}
                                    inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                    label={t('SettingsCompanies.city')}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? t(error.message) : null}
                                    required
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="region"
                            render={({ field, ref }) => (
                                <Select
                                    {...field}
                                    ref={ref}
                                    inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                    onChange={({ target: { value } }) => {
                                        setValue('region', value, { shouldDirty: true });
                                    }}
                                    options={regionsOptions}
                                    label={t('SettingsCompanies.state')}
                                    fullWidth
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="country"
                            render={({ field, fieldState: { error }, ref }) => (
                                <Select
                                    {...field}
                                    ref={ref}
                                    inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                    onChange={({ target: { value } }) => {
                                        setValue('country', value, { shouldDirty: true });
                                        setValue('region', '', { shouldDirty: true });
                                    }}
                                    options={countriesOptions}
                                    label={t('SettingsCompanies.country')}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? t(error.message) : null}
                                    required
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="postalCode"
                            render={({ field, fieldState: { error }, ref }) => (
                                <Input
                                    ref={ref}
                                    inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                    label={t('SettingsCompanies.postalCode')}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? t(error.message) : null}
                                    required
                                />
                            )}
                        />
                    </Form>
                </>
            )}
        </>
    );
};

export default Address;
