import { contactsConstants } from '@constants/contacts';

const initialState = {
    data: [],
    loading: true,
    error: '',
    totalCount: 0,
    pages: 1,
    contact: {},
    searchedContacts: [],
    bulkEditData: {},
    contactOpen: false,
};

export function contacts(state = initialState, action) {
    switch (action.type) {
        case contactsConstants.CONTACTS_FETCH:
            return { ...state, error: '' };
        case contactsConstants.CONTACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.items,
                totalCount: action.data.totalCount,
                pages: action.data.pages,
                error: '',
            };
        case contactsConstants.SEARCHED_CONTACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                searchedContacts: action.data.items,
                totalCount: action.data.totalCount,
                pages: action.data.pages,
                error: '',
            };
        case contactsConstants.SEARCHED_CONTACTS_RESET:
            return { ...state, searchedContacts: [] };
        case contactsConstants.CONTACTS_ERROR:
            return { ...state, error: action.message, loading: false };
        case contactsConstants.SET_CONTACTS:
            return { ...state, data: action.data };
        case contactsConstants.SET_CONTACT:
            return { ...state, contact: action.data };
        case contactsConstants.BULK_EDIT_DATA_SUCCESS:
            return { ...state, bulkEditData: action.data };
        case contactsConstants.CONTACT_OPEN:
            return { ...state, contactOpen: action.data };
        default:
            return state;
    }
}
