import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import PageHeader from '@features/PageHeader';
import IntegrationRequired from '@features/IntegrationRequired';
import { ModalConfirm } from '@components/Modal';
import Button from '@components/Button';
import { Typography } from '@components/Typography';
import { replyTemplatesActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';
import { replyTemplatesService } from '@services';
import { AccIntegrationName } from '@constants/integrations';
import { alertActions } from '@actions';
import { ReplyTemplatesIcon } from 'assets/images/icons';
import EditModal, { FORM_TYPE } from './EditModal';
import ReplyTemplateTable from './ReplyTemplateTable';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

const ReplyTemplates = () => {
    const { t } = useTranslation();

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [modal, setModal] = useState({ open: false, type: FORM_TYPE.NEW });
    const [pageTemplates, setPageTemplates] = useState(0);
    const [rowsPerPageTemplates, setRowsPerPageTemplates] = useState(10);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const user = useSelector(state => state.authentication.user);
    const account = useSelector(state => state.account?.account);
    const isAdmin = useSelector(state => {
        if (!state.authentication?.user?.id) return false;
        return state.authentication?.user?.roles?.filter(u => u.role === 0)?.length > 0;
    });
    const IS_USER = !isAdmin && !user?.isAccountOwner && !user?.isGlobalAdmin;
    const { totalCount } = useSelector(state => state.replyTemplates);
    const GOOGLE_CONNECTED = account?.integrations.find(i => i.type === AccIntegrationName.Google)?.isActive;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(replyTemplatesActions.get(pageTemplates, rowsPerPageTemplates));
    }, [pageTemplates, rowsPerPageTemplates, dispatch]);

    const onDelete = async () => {
        try {
            await replyTemplatesService.deleteReplyTemplate(selectedTemplate.id);
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
            if (pageTemplates === 0) {
                await dispatch(replyTemplatesActions.get(pageTemplates, rowsPerPageTemplates));
            } else {
                setPageTemplates(0); // Triggers refetch of templates
            }
            setDeleteModalOpen(false);
            setSelectedTemplate(null);
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.deleteFail')));
        }
    };

    const onAddTemplateClick = () => setModal({ open: true, type: FORM_TYPE.NEW });

    return (
        <Container>
            {!GOOGLE_CONNECTED ? (
                <IntegrationRequired googleOnly />
            ) : (
                <PageHeader
                    styles={{ marginBottom: '16px' }}
                    icon={<ReplyTemplatesIcon />}
                    title={t('ReviewsReplyTemplates.tenfoldProductivity')}
                    subtitle={t('ReviewsReplyTemplates.googleConnected')}
                    metrics={
                        <Typography variant="body1">
                            {t('ReviewsReplyTemplates.created')} {totalCount}
                        </Typography>
                    }
                >
                    {!IS_USER && (
                        <Button variant="contained" onClick={onAddTemplateClick}>
                            {t('buttons.addTemplate')}
                        </Button>
                    )}
                </PageHeader>
            )}
            {GOOGLE_CONNECTED && (
                <ReplyTemplateTable
                    setModal={setModal}
                    setSelectedTemplate={setSelectedTemplate}
                    setPage={setPageTemplates}
                    page={pageTemplates}
                    setRowsPerPage={setRowsPerPageTemplates}
                    rowsPerPage={rowsPerPageTemplates}
                    setDeleteModalOpen={setDeleteModalOpen}
                    isUser={IS_USER}
                />
            )}
            {modal.open ? (
                <EditModal
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    modal={modal}
                    setModal={setModal}
                    user={user}
                    setPage={setPageTemplates}
                    page={pageTemplates}
                    rowsPerPage={rowsPerPageTemplates}
                />
            ) : null}
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDelete}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                />
            )}
        </Container>
    );
};

export default ReplyTemplates;
