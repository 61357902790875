import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import PageHeader from '@features/PageHeader';
import IntegrationRequired from '@features/IntegrationRequired';
import Button from '@components/Button';
import { ModalConfirm } from '@components/Modal';
import { SocialPostsIcon } from 'assets/images/icons';
import { useTranslation } from '@hooks/useTranslation';
import { socialPostsActions, alertActions, labelsActions } from '@actions';
import { AccIntegrationName } from '@constants/integrations';
import { LABEL_TYPE } from '@constants/labels';
import { socialPostsService } from '@services';
import { MODAL_TYPE } from '@constants/modal';
import SocialPostsTable from './SocialPostsTable';
import EditModal from './EditModal';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

const PostsPage = () => {
    const { t } = useTranslation();
    const [pageRequests, setPageRequests] = useState(0);
    const [rowsPerPageRequests, setRowsPerPageRequests] = useState(10);
    const [selectedSocialPost, setSelectedSocialPost] = useState({});
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [modal, setModal] = useState({ open: false, type: MODAL_TYPE.CREATE });

    const account = useSelector(state => state.account?.account);
    const INTEGRATION_CONNECTED = account?.integrations.find(
        i => i.type === AccIntegrationName.Google || i.type === AccIntegrationName.Facebook,
    )?.isActive;

    const firstRender = useRef(true);
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            if (firstRender.current && INTEGRATION_CONNECTED) {
                await dispatch(socialPostsActions.get(pageRequests, rowsPerPageRequests));
                await dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
                firstRender.current = false;
            }
        }
        fetchData();
    }, [INTEGRATION_CONNECTED, dispatch]);

    useEffect(() => {
        if (!firstRender.current) {
            dispatch(socialPostsActions.get(pageRequests, rowsPerPageRequests));
        }
    }, [pageRequests, rowsPerPageRequests]);

    const onPageChange = (_, newPage) => setPageRequests(newPage);

    const onRowsPerPageChange = event => {
        setRowsPerPageRequests(parseInt(event.target.value, 10));
        setPageRequests(0);
    };

    const onClickSocialPost = (post, type) => {
        setSelectedSocialPost(post);
        switch (type) {
            case 'delete':
                setDeleteModalOpen(true);
                break;
            case 'edit':
                setModal({ open: true, type: MODAL_TYPE.EDIT });
                break;
            case 'view':
                setModal({ open: true, type: MODAL_TYPE.VIEW });
                break;
            default:
                break;
        }
    };

    const onDeleteSocialPost = async () => {
        try {
            await socialPostsService.deleteSocialPost(selectedSocialPost.id);
            dispatch(socialPostsActions.get(pageRequests, rowsPerPageRequests));
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.deleteFail')));
            }
        }
        setDeleteModalOpen(false);
        setSelectedSocialPost({});
    };

    return (
        <Container>
            {!INTEGRATION_CONNECTED ? (
                <IntegrationRequired />
            ) : (
                <>
                    <PageHeader
                        icon={<SocialPostsIcon />}
                        title={t('SocialPosts.title')}
                        subtitle={t('SocialPosts.subtitle')}
                        styles={{ marginBottom: '16px' }}
                    >
                        <Button variant="contained" onClick={() => setModal({ open: true, type: MODAL_TYPE.CREATE })}>
                            {t('buttons.createNewPost')}
                        </Button>
                    </PageHeader>
                    <SocialPostsTable
                        rowsPerPageRequests={rowsPerPageRequests}
                        pageRequests={pageRequests}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                        onClickSocialPost={onClickSocialPost}
                    />
                </>
            )}
            {modal.open ? (
                <EditModal
                    modal={modal}
                    setModal={setModal}
                    selectedSocialPost={selectedSocialPost}
                    setSelectedSocialPost={setSelectedSocialPost}
                    rowsPerPageRequests={rowsPerPageRequests}
                />
            ) : null}
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDeleteSocialPost}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                    onSecondaryAction={() => setDeleteModalOpen(false)}
                />
            )}
        </Container>
    );
};

export default PostsPage;
