import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const conversationsService = {
    get,
    close,
    open,
    getCounters,
    assign,
    getArchivedCount,
};

function get(params, skip, take) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params),
    };
    return fetch(`${BASE_URL}api/inbox/conversations?skip=${skip}&take=${take}`, requestOptions).then(handleResponse);
}

function close(conversationId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/inbox/conversations/${conversationId}/close`, requestOptions).then(handleResponse);
}

function open(conversationId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/inbox/conversations/${conversationId}/open`, requestOptions).then(handleResponse);
}

function getCounters(params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params),
    };
    return fetch(`${BASE_URL}api/inbox/conversations/counters`, requestOptions).then(handleResponse);
}

function getArchivedCount() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({}),
    };
    return fetch(`${BASE_URL}api/inbox/conversations/archived`, requestOptions).then(handleResponse);
}

function assign(conversationId, params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params),
    };
    return fetch(`${BASE_URL}api/inbox/conversations/${conversationId}/assign`, requestOptions).then(handleResponse);
}
