import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { useTranslation } from '@hooks/useTranslation';
import { useClickSendSurveys } from '@hooks/useClickSendSurveys';
import { getInitialsParts } from '@helpers/userName';
import { SettingsIcon, UserIcon, PuzzleIcon, UsersIcon, LogoutIcon } from 'assets/images/icons';
import { InternalLink } from '@components/Link';
import LogoutModal from '@features/LogoutModal';
import Notifications from '@features/Notifications';
import { PATH_MAP } from '@constants/header';
import { Container, RightWrapper, CssMenu, UserAvatar } from './styles';

export const getHeaderTitle = path => PATH_MAP[path];

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const user = useSelector(state => state.authentication.user);
    const isAccountOwner = user?.isAccountOwner || user?.isGlobalAdmin;
    const isAdmin = useSelector(state => {
        if (!state.authentication?.user?.id) return false;
        return state.authentication?.user?.roles?.filter(u => u.role === 0)?.length > 0;
    });
    const { onClickSendSurveys } = useClickSendSurveys();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const isMobile = useMediaQuery('@media (max-width: 1024px)', { noSsr: true });

    const MENU = [
        { to: 'profile', icon: UserIcon, title: 'settingsProfile', access: true },
        { to: 'users', icon: UsersIcon, title: 'menuTeamSettings', access: isAccountOwner || isAdmin },
        {
            to: isAccountOwner ? 'general' : 'companies',
            icon: SettingsIcon,
            title: 'menuGeneralSettings',
            access: isAccountOwner || isAdmin,
        },
        { to: 'integrations', icon: PuzzleIcon, title: 'settingsIntegrations', access: isAccountOwner },
    ];

    const handleClick = event => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const onClickLogout = () => {
        handleClose();
        setIsOpen(true);
    };

    return (
        <>
            <Container>
                <Typography variant={isMobile ? 'subtitle2' : 'title1'} color="textPrimary">
                    {t(`Header.${getHeaderTitle(pathname)}`)}
                </Typography>

                <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                    {pathname.startsWith('/surveys') && (
                        <Button variant="contained" onClick={onClickSendSurveys}>
                            {t('buttons.sendSurveys')}
                        </Button>
                    )}
                    {!isMobile && <Notifications buttonType="dark" />}
                    <RightWrapper>
                        <UserAvatar id="pl-menu-top-avatar" onClick={handleClick}>
                            {getInitialsParts(user?.firstName, user?.lastName)}
                        </UserAvatar>
                        <CssMenu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={!!anchorEl}
                            onClose={handleClose}
                            PaperProps={{ id: 'pl-menu-top-list' }}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                        >
                            {MENU.map(({ to, icon: Icon, title, access }) =>
                                access ? (
                                    <InternalLink
                                        key={title}
                                        to={`/account-settings/${to}`}
                                        sx={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)', width: '100%' }}
                                        onClick={handleClose}
                                    >
                                        <MenuItem>
                                            <Icon style={{ width: 18, marginRight: 16 }} />
                                            <Typography variant="body1">{t(`Header.${title}`)}</Typography>
                                        </MenuItem>
                                    </InternalLink>
                                ) : null,
                            )}
                            <MenuItem onClick={onClickLogout} sx={{ width: '100%' }}>
                                <LogoutIcon style={{ width: 18, marginRight: 16 }} />
                                <Typography variant="body1">{t('Header.logout')}</Typography>
                            </MenuItem>
                        </CssMenu>
                    </RightWrapper>
                </div>
            </Container>
            <LogoutModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    );
};

export default Header;
