import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import initPlugins from '@plugins';
import '@helpers/yup-config';
import '@i18n';
import App from 'App';
import { store } from 'store';
// import reportWebVitals from 'reportWebVitals';

import './index.css';

initPlugins();

function AppWithCallbackAfterRender() {
    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log('Pluspoint rendered');
    });

    return (
        <Provider store={store}>
            <App />
        </Provider>
    );
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))

// reportWebVitals();
