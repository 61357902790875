import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import * as yup from 'yup';
import { ModalBase } from '@components/Modal';
import { Select } from '@components/Select';
import { Input } from '@components/Input';
import { getChannelOptions } from '@helpers/channels';
import { getFullName } from '@helpers/userName';
import { CHANNEL } from '@constants/channels';
import { useTranslation } from '@hooks/useTranslation';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { alertActions, feedbacksActions } from '@actions';
import { inboxService } from '@services';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: '564px !important',
        '@media (max-width: 600px)': {
            height: 'var(--100vh) !important',
            width: '100% !important',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

const Content = styled('div')({
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateAreas: '"select1" "select2" "select3" "input1"',
    gridTemplateColumns: '100%',
    '@media (max-width: 600px)': {
        gridTemplateAreas: '"select1" "select2" "select3" "input1"',
        gridGap: '16px',
    },
    '& .MuiSelect-icon.Mui-disabled': {
        display: 'none',
    },
});

const InitLiveChat = ({ data, open, onCloseModal }) => {
    const { t } = useTranslation();
    const [channelOptions, setChannelOptions] = useState([]);

    const dispatch = useDispatch();
    const feedbacks = useSelector(state => state.feedbacks.feedbacks);
    const companies = useSelector(state => state.account?.account?.companies || []);
    const users = useSelector(state => state.account?.account?.users || []);
    const channels = useSelector(state => state.channels.data);

    const companiesOptions = useMemo(
        () => companies?.map(c => ({ value: c.companyId, label: c.internalName })),
        [companies],
    );

    const availableChannels = useMemo(() => {
        return channels.filter(
            channel => channel.type === CHANNEL.LIVE_CHAT && channel.companies.includes(data.companyId),
        );
    }, [channels, data]);

    const InitLiveChatSchema = yup.object().shape({
        contact: yup.string().required('validation.required'),
        companyId: yup.string().required('validation.required'),
        channelId: yup.string().required('validation.required'),
        message: yup.string().required('validation.required'),
    });

    const resolver = useYupValidationResolver(InitLiveChatSchema);

    const {
        getValues,
        setValue,
        control,
        reset,
        formState: { isValid, isSubmitting },
        handleSubmit,
    } = useForm({
        mode: 'all',
        resolver: resolver,
        defaultValues: {
            contact: data.contactName || '--',
            companyId: data.companyId,
            channelId: '',
            message: '',
        },
    });

    useEffect(() => {
        const newChannelOptions = getChannelOptions(
            { in: true },
            availableChannels.map(c => c.id),
            channels,
        );
        setChannelOptions(newChannelOptions);
        if (newChannelOptions.length === 1) {
            setValue('channelId', newChannelOptions[0].value);
        }
    }, [availableChannels, channels]);

    const onClose = () => {
        reset();
        onCloseModal();
    };

    const onInitChat = async () => {
        try {
            const { channelId, companyId, message } = getValues();
            const conversation = await inboxService.initLiveChatMessaging({
                channelId,
                companyId,
                message,
                feedbackId: data.id,
                contactId: data.contactId,
            });
            const assignedUser = users.find(user => user.id === conversation.assignedUserId);
            const updatedFeedbacks = [...feedbacks];
            const updatedFeedbackIndex = feedbacks.findIndex(r => r.id === data.id);
            if (updatedFeedbackIndex !== -1) {
                updatedFeedbacks[updatedFeedbackIndex].liveChatStatus = conversation.status;
                updatedFeedbacks[updatedFeedbackIndex].liveChatAssigneeName = getFullName(
                    assignedUser.firstName,
                    assignedUser.lastName,
                );
                updatedFeedbacks[updatedFeedbackIndex].contactId = conversation.contactId;
                updatedFeedbacks[updatedFeedbackIndex].liveChatConversationId = conversation.id;
            }
            dispatch(feedbacksActions.successAddOrUpdateTicket(updatedFeedbacks));
            dispatch(alertActions.success(t('alertMessages.createSuccess')));
            onClose();
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.createFail')));
            }
        }
    };

    return (
        <Modal
            isOpen={open}
            handleClose={onClose}
            title={t('FeedbackResponses.initChatTitle')}
            onPrimaryAction={handleSubmit(onInitChat)}
            primaryActionText={t('buttons.create')}
            primaryActionDisabled={isSubmitting || !isValid}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
            secondaryActionDisabled={isSubmitting}
        >
            <Content>
                <Controller
                    control={control}
                    name="contact"
                    render={({ field, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field }}
                            label={t('FeedbackResponses.recipient')}
                            fullWidth
                            disabled
                            style={{ gridArea: 'select1' }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="companyId"
                    render={({ field, ref }) => (
                        <Select
                            {...field}
                            ref={ref}
                            label={t('common.location')}
                            options={companiesOptions}
                            fullWidth
                            disabled
                            style={{ gridArea: 'select2' }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="channelId"
                    render={({ field, ref }) => (
                        <Select
                            {...field}
                            ref={ref}
                            label={t('common.channel')}
                            options={channelOptions}
                            fullWidth
                            required
                            style={{ gridArea: 'select3' }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="message"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            fullWidth
                            multiline
                            minRows={4}
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                            label={t('common.message', { count: 1 })}
                        />
                    )}
                />
            </Content>
        </Modal>
    );
};

export default InitLiveChat;
