import { useMemo } from 'react';
import sortBy from 'lodash.sortby';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@components/Typography';
import { Table } from '@components/Table';
import { useTranslation } from '@hooks/useTranslation';
import { formatNumber, formatPercentNumber } from '@helpers/formatNumbers';

const TableCellText = styled(Typography)({
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
});

export const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('common.location')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography variant="subtitle2" align="right">
                    {t('AnalyticsPresence.views')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsSocialPosts.engagements')}
                </Typography>
            ),
        },
        {
            key: 3,
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsPresence.calls')}
                </Typography>
            ),
        },
        {
            key: 4,
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsPresence.directions')}
                </Typography>
            ),
        },
        {
            key: 5,
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsPresence.websiteClicks')}
                </Typography>
            ),
        },
        {
            key: 6,
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsConversion.conversion')}
                </Typography>
            ),
        },
    ];
};

const getTableArrow = field => {
    switch (field) {
        case 1:
            return <ArrowUpwardIcon sx={{ color: '#0B8D00', fontSize: '20px' }} />;
        case -1:
            return <ArrowDownwardIcon sx={{ color: '#B00020', fontSize: '20px' }} />;
        case 0:
            return null;
        default:
            return null;
    }
};

export const getTableBody = data => {
    return data.map(item => {
        return [
            {
                component: (
                    <Typography variant="subtitle2" align="left">
                        {item.internalName}
                    </Typography>
                ),
            },
            {
                component: (
                    <TableCellText variant="body2" align="right">
                        <span style={{ marginRight: item.viewsChange === 0 ? '28px' : '8px' }}>
                            {formatNumber(item.views)}
                        </span>
                        {getTableArrow(item.viewsChange)}
                    </TableCellText>
                ),
            },
            {
                component: (
                    <TableCellText variant="body2" align="right">
                        <span style={{ marginRight: item.engagementsChange === 0 ? '28px' : '8px' }}>
                            {formatNumber(item.engagements)}
                        </span>
                        {getTableArrow(item.engagementsChange)}
                    </TableCellText>
                ),
            },
            {
                component: (
                    <TableCellText variant="body2" align="right">
                        <span style={{ marginRight: item.callsChange === 0 ? '28px' : '8px' }}>
                            {formatNumber(item.calls)}
                        </span>
                        {getTableArrow(item.callsChange)}
                    </TableCellText>
                ),
            },
            {
                component: (
                    <TableCellText variant="body2" align="right">
                        <span style={{ marginRight: item.directionsChange === 0 ? '28px' : '8px' }}>
                            {formatNumber(item.directions)}
                        </span>
                        {getTableArrow(item.directionsChange)}
                    </TableCellText>
                ),
            },
            {
                component: (
                    <TableCellText variant="body2" align="right">
                        <span style={{ marginRight: item.websiteChange === 0 ? '28px' : '8px' }}>
                            {formatNumber(item.website)}
                        </span>
                        {getTableArrow(item.websiteChange)}
                    </TableCellText>
                ),
            },
            {
                component: (
                    <TableCellText variant="body2" align="right">
                        <span style={{ marginRight: item.conversionChange === 0 ? '28px' : '8px' }}>
                            {formatPercentNumber(item.conversion)}
                        </span>
                        {getTableArrow(item.conversionChange)}
                    </TableCellText>
                ),
            },
        ];
    });
};

const PresenceTable = ({ isLoading, data, companies }) => {
    const dataSortedByName = useMemo(() => {
        const dataWithLocationNames = data.map(item => ({
            ...item,
            internalName: companies.find(company => company.companyId === item.companyId)?.internalName || '',
        }));
        return sortBy(dataWithLocationNames, item => item.internalName.toLowerCase());
    }, [data, companies]);

    return (
        <Paper
            elevation={1}
            sx={{
                padding: '24px',
                display: 'flex',
                width: '100%',
                borderRadius: '4px',
                '@media print': {
                    breakInside: 'avoid',
                },
            }}
        >
            <TableContainer>
                <Table columnNames={GetTableHeader()} rows={getTableBody(dataSortedByName)} isDataLoading={isLoading} />
            </TableContainer>
        </Paper>
    );
};

export default PresenceTable;
