import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const replyTemplatesService = {
    getReplyTemplates,
    addOrUpdateReplyTemplate,
    deleteReplyTemplate,
    getAllReplyTemplates,
};

function getReplyTemplates(page, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/reviews/reply-templates?page=${page}&pageSize=${pageSize}`, requestOptions).then(
        handleResponse,
    );
}

function getAllReplyTemplates() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/reviews/reply-templates?returnAll=true`, requestOptions).then(handleResponse);
}

function addOrUpdateReplyTemplate(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/reviews/reply-templates`, requestOptions).then(handleResponse);
}

function deleteReplyTemplate(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/reviews/reply-templates/${id}`, requestOptions).then(handleResponse);
}
