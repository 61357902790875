import { Fragment } from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { Input } from '@components/Input';
import Button from '@components/Button';
import { Select } from '@components/Select';
import PhoneInput from '@components/PhoneInput';
import { useTranslation } from '@hooks/useTranslation';
import { AdditionalContactType } from '@constants/companies';
import { DeleteIcon } from 'assets/images/icons';

export const Form = styled('div')({
    maxWidth: 698,
    display: 'grid',
    columnGap: 24,
    rowGap: 16,
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
    '@media (max-width: 1024px)': {
        gridTemplateColumns: 'minmax(0, 1fr)',
        maxWidth: 'none',
    },
});

const StyledIconButton = styled(IconButton)({
    width: 32,
    height: 32,
    transform: 'translateY(4px)',
    padding: '4px',
    '& svg path': {
        fill: 'rgba(0, 0, 0, 0.6)',
    },
});

const MockDiv = styled('div')({
    '@media (max-width: 1024px)': {
        display: 'none',
    },
});

const Contacts = () => {
    const { t } = useTranslation();
    const { control, setValue, append, trigger, fields, remove, update } = useFormContext();
    const fieldsValues = useWatch({
        name: 'serviceContacts',
        control,
    });

    return (
        <>
            <Form>
                <div style={{ display: 'grid', gridGap: '8px', gridTemplateColumns: 'calc(100% - 73px) 65px' }}>
                    <Controller
                        control={control}
                        name="phoneNumber"
                        render={({ field, fieldState: { error } }) => (
                            <PhoneInput
                                placeholder=""
                                label={t('SettingsCompanies.primaryPhone')}
                                inputProps={{ name: 'phoneNumber' }}
                                value={field.value}
                                autoFormat
                                onChange={value => {
                                    setValue('phoneNumber', value, { shouldValidate: true, shouldDirty: true });
                                }}
                                error={error ? t(error.message) : null}
                                required
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="phoneExtension"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('SettingsCompanies.phoneExtension')}
                                fullWidth
                                error={!!error}
                            />
                        )}
                    />
                </div>
                <Controller
                    control={control}
                    name="email"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('common.email')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="website"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('SettingsCompanies.website')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                        />
                    )}
                />
                <MockDiv />
                {fields.map((field, index) => (
                    <Fragment key={field.id}>
                        {fieldsValues[index]?.type === AdditionalContactType.Phone ? (
                            <div
                                style={{
                                    display: 'grid',
                                    gridGap: '8px',
                                    gridTemplateColumns: 'calc(100% - 73px) 65px',
                                }}
                            >
                                <Controller
                                    control={control}
                                    name={`serviceContacts[${index}].phoneNumber`}
                                    render={({ field, fieldState: { error } }) => (
                                        <PhoneInput
                                            placeholder=""
                                            label={t('SettingsCompanies.phoneNumber')}
                                            value={field.value}
                                            autoFormat
                                            onChange={value => {
                                                setValue(`serviceContacts[${index}].phoneNumber`, value, {
                                                    shouldValidate: true,
                                                    shouldDirty: true,
                                                });
                                            }}
                                            error={error ? t(error.message) : null}
                                            required
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name={`serviceContacts[${index}].phoneExtension`}
                                    render={({ field, fieldState: { error }, ref }) => (
                                        <Input
                                            ref={ref}
                                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                            label={t('SettingsCompanies.phoneExtension')}
                                            fullWidth
                                            error={!!error}
                                        />
                                    )}
                                />
                            </div>
                        ) : (
                            <Controller
                                control={control}
                                name={`serviceContacts[${index}].email`}
                                render={({ field, fieldState: { error }, ref }) => (
                                    <Input
                                        ref={ref}
                                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                        label={t('SettingsCompanies.email')}
                                        fullWidth
                                        required
                                        error={!!error}
                                        helperText={error ? t(error.message) : null}
                                    />
                                )}
                            />
                        )}
                        <div style={{ display: 'grid', gridGap: '8px', gridTemplateColumns: 'calc(100% - 40px) 32px' }}>
                            <Controller
                                control={control}
                                name={`serviceContacts[${index}].type`}
                                render={({ field, ref }) => (
                                    <Select
                                        {...field}
                                        ref={ref}
                                        onChange={({ target: { value } }) => {
                                            update(index, {
                                                type: value,
                                                phoneNumber: '',
                                                phoneExtension: '',
                                                email: '',
                                            });
                                        }}
                                        label={t('common.type')}
                                        fullWidth
                                        disabled={fieldsValues.length === 2}
                                        options={[
                                            {
                                                label: t('SettingsCompanies.phoneNumber'),
                                                value: AdditionalContactType.Phone,
                                            },
                                            {
                                                label: t('SettingsCompanies.email'),
                                                value: AdditionalContactType.Email,
                                            },
                                        ]}
                                    />
                                )}
                            />
                            <StyledIconButton
                                onClick={() => {
                                    remove(index);
                                    trigger('serviceContacts');
                                }}
                            >
                                <DeleteIcon />
                            </StyledIconButton>
                        </div>
                    </Fragment>
                ))}
            </Form>
            <Button
                variant="outlined"
                sx={{ width: 'max-content', marginTop: '8px' }}
                startIcon={<AddIcon />}
                disabled={fieldsValues.length >= 2}
                onClick={() => {
                    append({
                        type:
                            fieldsValues[0]?.type === AdditionalContactType.Phone
                                ? AdditionalContactType.Email
                                : AdditionalContactType.Phone,
                        phoneNumber: '',
                        phoneExtension: '',
                        email: '',
                    });
                }}
            >
                {t('SettingsCompanies.addContactDetails')}
            </Button>
        </>
    );
};

export default Contacts;
