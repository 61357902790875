import sortBy from 'lodash.sortby';
import { Typography } from '@components/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatNumber } from '@helpers/formatNumbers';
import { getTableArrow, TableCellText } from '.';

export const getCollapsibleTableBody = (data, index) => {
    const rowCompanyId = data?.find((item, dataIndex) => dataIndex === index)?.companyId;
    const tableData = sortBy(
        data?.filter(item => item.companyId === rowCompanyId && !item.companyTotal),
        item => item?.surveyTemplateName?.toLowerCase(),
    );

    return tableData.map(row => {
        const {
            companyTotal = false,
            totalResponses,
            totalResponsesChange,
            averageRatingChange,
            negativeResponsesChange,
            averageRating,
            negativeResponses,
            netPromoterScoreChange,
            netPromoterScore,
            surveyTemplateName,
        } = row;

        return !companyTotal
            ? [
                  {
                      component: (
                          <IconButton style={{ opacity: 0, cursor: 'auto' }}>
                              <KeyboardArrowUpIcon />
                          </IconButton>
                      ),
                      width: '5%',
                  },
                  {
                      component: (
                          <Typography variant="subtitle2" align="left">
                              {surveyTemplateName || 'NA'}
                          </Typography>
                      ),
                      width: '23%',
                  },
                  {
                      component: (
                          <TableCellText variant="body2" align="right">
                              <span style={{ marginRight: totalResponsesChange === 0 ? '28px' : '8px' }}>
                                  {formatNumber(totalResponses)}
                              </span>
                              {getTableArrow(totalResponsesChange)}
                          </TableCellText>
                      ),
                      width: '18%',
                  },
                  {
                      component: (
                          <TableCellText variant="body2" align="right">
                              <span style={{ marginRight: averageRatingChange === 0 ? '28px' : '8px' }}>
                                  {formatNumber(averageRating)}
                              </span>
                              {getTableArrow(averageRatingChange)}
                          </TableCellText>
                      ),
                      width: '18%',
                  },
                  {
                      component: (
                          <TableCellText variant="body2" align="right">
                              <span style={{ marginRight: negativeResponsesChange === 0 ? '28px' : '8px' }}>
                                  {formatNumber(negativeResponses)}
                              </span>
                              {getTableArrow(negativeResponsesChange)}
                          </TableCellText>
                      ),
                      width: '18%',
                  },
                  {
                      component: (
                          <TableCellText variant="body2" align="right">
                              <span style={{ marginRight: netPromoterScoreChange === 0 ? '28px' : '8px' }}>
                                  {netPromoterScore}
                              </span>
                              {getTableArrow(netPromoterScoreChange)}
                          </TableCellText>
                      ),
                      width: '18%',
                  },
              ]
            : null;
    });
};
