export const labelsConstants = {
    LABELS_SUCCESS: 'LABELS_SUCCESS',
};

export const LABEL_TYPE = {
    CONTACT: 1,
    LOCATION: 2,
};

export const LABEL_NAME = {
    1: 'contact',
    2: 'location',
};
