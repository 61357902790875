import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

const FeedbackContainer = styled('div')({
    position: 'relative',
    maxWidth: '600px',
    '& .MuiSkeleton-root': {
        height: '15px',
    },
    '@media (max-width: 1024px)': {
        width: '100%',
    },
});

const FeedbackRow = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    '@media (max-width: 1024px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
});

const FeedbackItemSkeleton = styled(Paper, {
    shouldForwardProp: prop => prop !== 'hasData',
})(({ hasData }) => ({
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: 4,
    ...(hasData && { marginTop: 16 }),
}));

const SkeletonWrapper = styled('div')({
    padding: '8px 16px',
    '&:first-of-type': {
        padding: '16px 16px 8px',
    },
});

const ReviewsItemSkeleton = data => (
    <FeedbackItemSkeleton elevation={4} hasData={data?.length}>
        <FeedbackContainer>
            <FeedbackRow>
                <Skeleton sx={{ height: '12px', width: '100%', transform: 'none' }} />
            </FeedbackRow>
            <FeedbackRow>
                <Skeleton sx={{ height: '16px', width: '100%', transform: 'none' }} />
            </FeedbackRow>
            <Skeleton sx={{ height: '40px', transform: 'none' }} />
        </FeedbackContainer>
    </FeedbackItemSkeleton>
);

const ReviewsSkeleton = () => {
    return [...Array(5).keys()].map(item => (
        <SkeletonWrapper key={item}>
            <ReviewsItemSkeleton data={null} />
        </SkeletonWrapper>
    ));
};

export { ReviewsItemSkeleton, ReviewsSkeleton };
