import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const brandsService = {
    get,
    addOrUpdateBrand,
    deleteBrand,
    getAll,
};

function get(page, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/locations/brands?page=${page}&pageSize=${pageSize}`, requestOptions).then(
        handleResponse,
    );
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/locations/brands?returnAll=true`, requestOptions).then(handleResponse);
}

function addOrUpdateBrand(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/locations/brands`, requestOptions).then(handleResponse);
}

function deleteBrand(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/locations/brands/${id}`, requestOptions).then(handleResponse);
}
