import { countriesConstants } from '@constants/countries';
import { countriesService } from '@services';

function request() {
    return { type: countriesConstants.COUNTRIES_FETCH };
}

function success(data) {
    return { type: countriesConstants.COUNTRIES_SUCCESS, data };
}

function failure() {
    return { type: countriesConstants.COUNTRIES_ERROR };
}

const get = () => {
    return dispatch => {
        dispatch(request());
        countriesService.get().then(
            data => dispatch(success(data)),
            () => dispatch(failure()),
        );
    };
};

export const countriesActions = {
    get,
};
