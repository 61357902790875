import { keywordsConstants } from '@constants/keywords';
import { keywordsService } from '@services';

export const keywordsActions = {
    get,
    getAll,
};

function get(data, page = 0, pageSize = 20) {
    return dispatch => {
        dispatch(request());
        return keywordsService.get(data, page, pageSize).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error)),
        );
    };
}

function getAll() {
    return dispatch => {
        return keywordsService.getAll().then(data => dispatch(successAll(data)));
    };
}

function request() {
    return { type: keywordsConstants.KEYWORDS_REQUEST };
}

function success(data) {
    return { type: keywordsConstants.KEYWORDS_SUCCESS, data };
}

function successAll(data) {
    return { type: keywordsConstants.KEYWORDS_ALL_SUCCESS, data };
}

function failure(message) {
    return { type: keywordsConstants.KEYWORDS_ERROR, message };
}
