import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Input } from '@components/Input';
import { ModalBase } from '@components/Modal';
import { replyTemplatesService } from '@services';
import { alertActions, replyTemplatesActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        width: 500,
        '@media (max-width: 600px)': {
            width: 'calc(100% - 32px)',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

const CreateTemplateModal = ({ comment, setAnchorElEdit, isCreateModalOpen, setCreateModalOpen }) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const dispatch = useDispatch();

    const onCreateTemplate = async () => {
        try {
            await replyTemplatesService.addOrUpdateReplyTemplate({ name, status: 0, text: comment });
            dispatch(alertActions.success(t('alertMessages.createSuccess')));
            await dispatch(replyTemplatesActions.getAll());
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.createFail')));
        }
        setName('');
        setCreateModalOpen();
        setAnchorElEdit(null);
    };

    return (
        <Modal
            isOpen={isCreateModalOpen}
            handleClose={() => setCreateModalOpen(false)}
            title={t('RespondTemplate.saveNewTemplate')}
            onPrimaryAction={onCreateTemplate}
            primaryActionText={t('buttons.save')}
            primaryActionDisabled={!name}
            onSecondaryAction={() => setCreateModalOpen(false)}
            secondaryActionText={t('buttons.cancel')}
        >
            <Input
                inputProps={{ name: 'name', value: name, onChange: e => setName(e.target.value) }}
                fullWidth
                label={t('common.templateName')}
                sx={{ paddingBottom: 0 }}
            />
        </Modal>
    );
};

export default CreateTemplateModal;
