import { countriesConstants } from '@constants/countries';

const initialState = {
    data: [],
    loading: true,
    error: '',
};

export function countries(state = initialState, action) {
    switch (action.type) {
        case countriesConstants.COUNTRIES_FETCH:
            return { ...state, loading: true, error: '' };
        case countriesConstants.COUNTRIES_SUCCESS:
            return { ...state, loading: false, data: action.data };
        case countriesConstants.COUNTRIES_ERROR:
            return { ...state, loading: false };
        default:
            return state;
    }
}
