import * as yup from 'yup';
import { phone as phoneValidator } from 'phone';

yup.addMethod(yup.string, 'validatePhone', function (message, requirePlus) {
    return this.test('test-phone', message, function (value) {
        if (!value) return true;
        const { path, createError } = this;
        const val = requirePlus ? `+${value}` : value;

        if (val && !val.startsWith('+')) {
            return createError({ path, message });
        }

        const response = phoneValidator(val, { country: null, validateMobilePrefix: false });
        if (response.isValid && val === response.phoneNumber) return true;
        return createError({ path, message });
    });
});
