import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const notificationsService = {
    get,
    readNotifications,
};

function get(take, skip) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/notifications?take=${take}&skip=${skip}`, requestOptions).then(handleResponse);
}

function readNotifications(id = null) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/notifications/read${id ? `?notificationId=${id}` : ''}`, requestOptions).then(
        handleResponse,
    );
}
