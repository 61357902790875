import cloneDeep from 'lodash.clonedeep';

export const parseTipTapJSONToPlainText = (json = {}) => {
    let data = cloneDeep(json);
    if (typeof json === 'string') {
        try {
            data = JSON.parse(json);
        } catch (error) {
            return json;
        }
    }

    const getTextFromMirrorDocument = mirrorValue => {
        const { content } = mirrorValue;
        if (!content || content.length === 0) {
            return '';
        }
        let out = '';
        for (const node of content) {
            out += getTextFromMirrorNode(node) + '\n';
        }
        out = out.trim();
        return out;
    };

    const getTextFromMirrorNode = node => {
        if (!node) {
            return '';
        }
        const hasMarks = node?.marks?.length > 0;
        const isHighlight = (hasMarks ? node?.marks[0] : {})?.type === 'highlight';
        switch (node.type) {
            case 'text':
                return isHighlight ? `[${node.text}]` : node.text;
            case 'hardBreak':
                return '\n';
            case 'paragraph':
                if (!node.content) {
                    return '';
                }
                return node.content.map(subNode => getTextFromMirrorNode(subNode)).join('');
        }

        return '';
    };
    return getTextFromMirrorDocument(data);
};

export const addTipTapMarkTag = (text = '') =>
    text
        .replace(/\[name\]/g, '<mark class="pl-custom-highlight">name</mark>')
        .replace(/\[last_name\]/g, '<mark class="pl-custom-highlight">last_name</mark>')
        .replace(/\[location\]/g, '<mark class="pl-custom-highlight">location</mark>')
        .replace(/\[survey\]/g, '<mark class="pl-custom-highlight">survey</mark>&#8203;')
        .replace(/\n/g, '<br>');

export const testEditorLength = (value, maxLength) => {
    const placeholders = ['name', 'last_name', 'location', 'survey'];
    let lengthWithoutBrackets = value.length;
    placeholders.forEach(ph => {
        const re = new RegExp(`\\[${ph}\\]`, 'g');
        const match = value.match(re);

        if (match) {
            lengthWithoutBrackets -= 2 * match.length;
        }
    });
    return lengthWithoutBrackets <= maxLength;
};
