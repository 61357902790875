import { styled } from '@mui/material/styles';
import MuiChip from '@mui/material/Chip';

const StyledChip = styled(MuiChip)({
    marginRight: '8px',
    height: '30px',
    maxWidth: '250px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '& .MuiChip-label': {
        fontFamily: 'Rubik, sans-serif',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '12px',
        lineHeight: '14px',
        paddingLeft: '10px',
    },
});

export const Chip = ({ label, onDelete }) => {
    return <StyledChip key={label} label={label} onDelete={onDelete} variant="outlined" size="small" />;
};
