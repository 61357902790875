import { styled } from '@mui/material/styles';

export const Container = styled('div')({
    marginBottom: 65,
    '@media (max-width: 1024px)': {
        overflowY: 'auto',
        marginBottom: 80,
    },
});

export const Footer = styled('div')({
    position: 'fixed',
    padding: '16px 24px',
    bottom: 0,
    background: 'white',
    left: 280,
    right: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    zIndex: 1,
    '@media (max-width: 1024px)': {
        left: 0,
        padding: 16,
    },
});

export const Content = styled('div', {
    shouldForwardProp: prop => prop !== 'isPassword',
})(({ isPassword }) => ({
    maxWidth: '698px',
    display: 'grid',
    columnGap: '24px',
    rowGap: '16px',
    gridTemplateColumns: '1fr 1fr',
    ...(isPassword && { gridTemplateAreas: `"area1 area2" "area3 area4"` }),
    '@media (max-width: 1024px)': {
        gridTemplateColumns: '1fr',
        maxWidth: 'none',
        ...(isPassword && {
            gridTemplateAreas: `"area1" "area3" "area2" "area4"`,
            marginBottom: 0,
        }),
    },
}));
