import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';

export const Modal = styled(ModalBase)(props => ({
    '& .modal-base-paper': {
        outline: 'none',
        width: '564px !important',
        '@media (max-width: 600px)': {
            height: 'var(--100vh) !important',
            width: '100% !important',
        },
    },
    '& .modal-base-content': {
        padding: 16,
        display: 'grid',
        gridGap: '16px 24px',
        gridTemplateAreas: props.step1 ? '"select1"' : '"select2" "select3" "select4"',
        gridTemplateColumns: '100%',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '100%',
            gridGap: '16px',
        },
    },
}));
