import { ifoodConstants } from '@constants/ifood';

const initialState = {
    data: [],
    loading: true,
    error: '',
};

export function ifood(state = initialState, action) {
    switch (action.type) {
        case ifoodConstants.IFOOD_MERCHANTS_REQUEST:
            return { ...state, loading: true, error: '' };
        case ifoodConstants.IFOOD_MERCHANTS_SUCCESS:
            return { ...state, loading: false, data: action.data, error: '' };
        case ifoodConstants.IFOOD_MERCHANTS_ERROR:
            return { ...state, error: action.message };
        default:
            return state;
    }
}
