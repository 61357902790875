import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import { Switch } from '@components/Switch';
import { Select } from '@components/Select';
import { Typography } from '@components/Typography';
import { Table } from '@components/Table';
import { useTranslation } from '@hooks/useTranslation';

const RoleSelect = styled(Select)({
    '& .MuiOutlinedInput-root': {
        width: '88px !important',
        textAlign: 'left',
    },
    '& .MuiFormControl-root': {
        height: '35px !important',
        paddingBottom: '0 !important',
    },
    '& fieldset': {
        border: 'none',
    },
});

const GetAccessRolesColumnNames = () => {
    const { t } = useTranslation();
    return [
        { key: 0, data: <Typography variant="subtitle2"></Typography>, width: '66px' },
        {
            key: 1,
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('common.location')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('SettingsUsers.role')}
                </Typography>
            ),
        },
    ];
};

const getAccessRolesRowsData = (companies, onChange, toggleGlobalAdmin) => {
    return companies.map(({ enabled, name, role, id }) => {
        return [
            {
                value: enabled,
                component: (
                    <Switch
                        checked={enabled}
                        disabled={toggleGlobalAdmin}
                        handleChange={event => onChange(id, 'enabled', event.target.checked)}
                    />
                ),
            },
            {
                value: name,
                component: (
                    <Typography
                        variant="subtitle2"
                        align="left"
                        disabled={!enabled}
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {name}
                    </Typography>
                ),
            },
            {
                value: role,
                component: !enabled ? (
                    <Typography
                        variant="body2"
                        disabled={true}
                        style={{ width: '90px', paddingLeft: '12px' }}
                        align="left"
                    >
                        N/A
                    </Typography>
                ) : (
                    <RoleSelect
                        name="role"
                        id="role"
                        fullWidth
                        disabled={toggleGlobalAdmin}
                        value={role}
                        onChange={event => onChange(id, 'role', Number(event.target.value))}
                        options={[
                            { value: '0', label: 'Admin' },
                            { value: '1', label: 'User' },
                        ]}
                    />
                ),
            },
        ];
    });
};

const UserAccessTable = ({ companies, setCompanies, toggleGlobalAdmin, setDirtyTable }) => {
    const { t } = useTranslation();

    const handleChangeAccess = (id, type, value) => {
        const updatedCompanies = companies.map(company => {
            if (company.id === id) {
                company[type] = value;
                if (type === 'enabled' && !value) {
                    company.role = 1;
                }
            }
            return company;
        });
        setCompanies(updatedCompanies);
        setDirtyTable(true);
    };

    return (
        <div style={{ marginBottom: 40 }}>
            <Typography
                variant="h6"
                sx={{ marginBottom: '16px', display: 'block', color: 'rgba(0, 0, 0, 0.87)' }}
            >
                {t('SettingsUsers.accessRoles')}
            </Typography>
            <Typography
                variant="caption"
                sx={{ marginBottom: '16px', display: 'block', color: 'rgba(0, 0, 0, 0.6)' }}
            >
                {t('SettingsUsers.accessRolesDescription')}
            </Typography>
            <TableContainer>
                <Table
                    columnNames={GetAccessRolesColumnNames()}
                    rows={getAccessRolesRowsData(companies, handleChangeAccess, toggleGlobalAdmin)}
                    isDataLoading={false}
                />
            </TableContainer>
        </div>
    );
};

export default UserAccessTable;
