import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import sortBy from 'lodash.sortby';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import { Typography } from '@components/Typography';
import { CopyIcon } from 'assets/images/icons';
import { useThrottle } from '@hooks/useThrottle';
import { useCopyToClipboard } from '@hooks/useCopyToClipboard';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { useTranslation } from '@hooks/useTranslation';
import { alertActions, surveysActions } from '@actions';
import { surveyService } from '@services';
import { Modal, Content } from './styles';

const EmailIntegrationModal = ({ isOpen, setModal }) => {
    const { t } = useTranslation();
    const data = useSelector(state => state.surveys.data);
    const { onCopy } = useCopyToClipboard();
    const [onCopyClick] = useThrottle(value => onCopy(value), 1000);

    const dispatch = useDispatch();

    const delayOptions = useMemo(
        () => [
            { value: 0, label: t('common.none') },
            { value: 1, label: t('FeedbackAutoSurveys.hour') },
            { value: 2, label: t('FeedbackAutoSurveys.hours2') },
            { value: 3, label: t('FeedbackAutoSurveys.day') },
            { value: 4, label: t('FeedbackAutoSurveys.days2') },
            { value: 5, label: t('FeedbackAutoSurveys.days3') },
            { value: 6, label: t('FeedbackAutoSurveys.week') },
            { value: 7, label: t('FeedbackAutoSurveys.weeks2') },
        ],
        [t],
    );

    const EmailIntegrationSchema = yup.object().shape({
        surveyId: yup.string().required('validation.required'),
        delay: yup.number().required('validation.required'),
        email: yup.string().required('validation.required'),
    });

    const resolver = useYupValidationResolver(EmailIntegrationSchema);

    const {
        getValues,
        setValue,
        control,
        handleSubmit,
        reset,
        formState: { isValid, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            surveyId: '',
            delay: 0,
            email: '',
        },
    });

    const surveysOptions = useMemo(
        () =>
            sortBy(
                data?.map(item => ({
                    value: item.survey.id,
                    label: item.survey.name,
                    delay: item.survey.surveyEmailDelay,
                    email: item.survey.surveyEmail,
                })),
                ['label'],
            ),
        [data],
    );

    useEffect(() => {
        if (data.length === 1) {
            setValue('surveyId', data[0].survey.id);
            setValue('delay', data[0].survey.surveyEmailDelay);
            setValue('email', data[0].survey.surveyEmail);
        }
    }, [data]);

    const onCloseModal = () => {
        setModal(false);
        reset();
    };

    const onSubmit = async () => {
        const values = getValues();
        const { delay, surveyId } = values;
        try {
            await surveyService.updateSurveyDelay({ delay }, surveyId);
            dispatch(alertActions.success(t('alertMessages.connectIntegrationSuccess')));
            dispatch(surveysActions.getAll());
            onCloseModal();
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.connectIntegrationFail')));
            }
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            handleClose={onCloseModal}
            title={t('FeedbackAutoSurveys.emailIntegrationTitle')}
            subtitle={
                <Typography variant="caption" color="textSecondary">
                    {t('FeedbackAutoSurveys.emailIntegrationSubtitle')}
                </Typography>
            }
            onPrimaryAction={handleSubmit(onSubmit)}
            primaryActionText={t('buttons.saveChanges')}
            primaryActionDisabled={isSubmitting || !isValid}
            onSecondaryAction={onCloseModal}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content>
                <div style={{ display: 'flex', flexDirection: 'column', gridArea: 'select1' }}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('FeedbackAutoSurveys.surveySelectTitle')}
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{ marginBottom: '16px', color: 'rgba(0, 0, 0, 0.6)', display: 'inline-block' }}
                    >
                        {t('FeedbackAutoSurveys.surveySelectSubtitle')}
                    </Typography>
                    <Controller
                        control={control}
                        name="surveyId"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Select
                                {...field}
                                ref={ref}
                                onChange={({ target: { value } }) => {
                                    setValue('surveyId', value, { shouldValidate: true, shouldDirty: true });
                                    const survey = surveysOptions.find(i => i.value === value);
                                    setValue('delay', survey.delay);
                                    setValue('email', survey.email);
                                }}
                                options={surveysOptions}
                                label={t('common.survey')}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                required
                                dropdownStyles={{ maxHeight: '300px' }}
                            />
                        )}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gridArea: 'select2' }}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('FeedbackAutoSurveys.delaySelectTitle')}
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{ marginBottom: '16px', color: 'rgba(0, 0, 0, 0.6)', display: 'inline-block' }}
                    >
                        {t('FeedbackAutoSurveys.delaySelectSubtitle')}
                    </Typography>
                    <Controller
                        control={control}
                        name="delay"
                        render={({ field, ref }) => (
                            <Select {...field} ref={ref} label={t('common.delay')} options={delayOptions} />
                        )}
                    />
                </div>
                <div style={{ gridArea: 'input' }}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '16px' }}>
                        {t('FeedbackAutoSurveys.emailInputTitle')}
                    </Typography>
                    <Controller
                        control={control}
                        name="email"
                        render={({ field, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('common.email')}
                                onAddonClick={() => onCopyClick(field.value)}
                                addonComponent={<CopyIcon style={{ fill: 'rgba(0, 0, 0, 0.6)' }} />}
                                fullWidth
                                disabled
                            />
                        )}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gridArea: 'textArea' }}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('FeedbackAutoSurveys.uniqueEmailTitle')}
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{ marginBottom: '16px', color: 'rgba(0, 0, 0, 0.6)', display: 'inline-block' }}
                    >
                        {t('FeedbackAutoSurveys.uniqueEmailSubtitle')}
                    </Typography>
                </div>
            </Content>
        </Modal>
    );
};

export default EmailIntegrationModal;
