import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { BILLING_TYPE_MAP } from '@constants/billing';
import { accountActions } from '@actions';

const QuotaWrapper = styled('div')(
    () => `
    display: grid;
    grid-gap: 8px 16px;
    grid-template-areas: "type0 bar0 value0" "type1 bar1 value1" "type2 bar2 value2";
    grid-template-columns: 80px minmax(auto, 372px) minmax(100px, auto);
    margin-bottom: 24px;
    align-items: center;
    width: max-content;
    @media (max-width: 600px) {
        width: 100%;
        grid-gap: 8px;
        grid-template-columns: 60px minmax(auto, 372px) minmax(100px, auto);
    }
`,
);

const Bar = styled('div', { shouldForwardProp: prop => prop !== 'area' })(
    ({ area }) => `
    background-color: #E4EAEB;
    height: 4px;
    border-radius: 2px;
    grid-area: bar${area};
`,
);

const ProgressBar = styled('div', { shouldForwardProp: prop => prop !== 'total' })(
    ({ total }) => `
    background-color: #1F4C5C;
    height: 4px;
    border-radius: 2px;
    width: ${total}%;
`,
);

const percentage = (value, total) => {
    if (!total || !value) return 0;
    const percent = Math.round((value * 100) / total);
    return percent > 100 ? 100 : percent;
};

const Quota = ({ account }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(accountActions.updateQuota());
    }, [dispatch]);

    const isEnterprisePlan = account?.subscriptions[0]?.billingPlanName === BILLING_TYPE_MAP.ENTERPRISE;

    return (
        <div style={{ maxWidth: 698 }}>
            <Typography variant="h6" color="textPrimary" sx={{ marginBottom: '16px' }}>
                {t('SettingsGeneral.quotaTitle')}
            </Typography>
            <Typography variant="caption" sx={{ marginBottom: '16px', color: 'rgba(0, 0, 0, 0.6)', display: 'block' }}>
                {t('SettingsGeneral.quotaDescription')}
            </Typography>
            <QuotaWrapper>
                <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', gridArea: 'type0' }}>
                    {t('common.email')}
                </Typography>
                <Bar area={0}>
                    <ProgressBar
                        total={percentage(account?.emailQuotaUsage, isEnterprisePlan ? 0 : account?.emailQuota)}
                    />
                </Bar>
                <Typography
                    variant="caption"
                    sx={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'right', gridArea: 'value0' }}
                >
                    {account?.emailQuotaUsage?.toLocaleString('en-US')}/
                    {isEnterprisePlan ? t('SettingsGeneral.unlimited') : account?.emailQuota?.toLocaleString('en-US')}
                </Typography>
                <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', gridArea: 'type1' }}>
                    {t('common.sms')}
                </Typography>
                <Bar area={1}>
                    <ProgressBar total={percentage(account?.smsQuotaUsage, isEnterprisePlan ? 0 : account?.smsQuota)} />
                </Bar>
                <Typography
                    variant="caption"
                    sx={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'right', gridArea: 'value1' }}
                >
                    {account?.smsQuotaUsage?.toLocaleString('en-US')}/
                    {isEnterprisePlan ? t('SettingsGeneral.unlimited') : account?.smsQuota?.toLocaleString('en-US')}
                </Typography>
                <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', gridArea: 'type2' }}>
                    {t('common.whatsApp')}
                </Typography>
                <Bar area={2}>
                    <ProgressBar total={0} />
                </Bar>
                <Typography
                    variant="caption"
                    sx={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'right', gridArea: 'value2' }}
                >
                    {account?.whatsAppQuotaUsage?.toLocaleString('en-US')}/{t('SettingsGeneral.unlimited')}
                </Typography>
            </QuotaWrapper>
        </div>
    );
};

export default Quota;
