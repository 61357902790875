export const AI_TASK_TYPE = {
    STANDARD: 0,
    CONTEXTUAL: 1,
};

export const AITaskType = {
    // Standard Tasks
    TranslateToEnglish: 1,
    TranslateToSpanish: 2,
    TranslateToPortuguese: 3,
    TranslateToFrench: 4,
    TranslateToGerman: 5,
    TranslateToItalian: 6,
    TranslateToRussian: 7,
    TranslateToUkrainian: 8,
    TranslateToPolish: 22, //FIXME: Change according to the backend
    ChangeToneToProfessional: 9,
    ChangeToneToFriendly: 10,
    FixSpellingAndGrammar: 11,
    MakeShorter: 12,
    MakeLonger: 13,
    // Contextual Tasks
    GenerateInboxMessage: 14,
    GenerateSocialPost: 15,
    GenerateCampaignMessage: 16,
    GenerateSurveyTemplateMessage: 17,
    GenerateSurveyTemplateFollowUp: 18,
    GenerateGoogleLocationDescription: 19,
    GenerateFacebookLocationDescription: 20,
    GenerateReplyToReview: 21,
};
