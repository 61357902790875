import { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import HelpIcon from '@mui/icons-material/Help';
import { Table } from '@components/Table';
import Tooltip from '@components/Tooltip';
import MoreMenu from '@components/MoreMenu';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { formatCompanies, getCompanyFullAddress } from '@helpers/companies';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { formatPhoneNumber } from '@helpers/phone';
import { alertActions, profilesActions } from '@actions';
import { profilesService } from '@services';
import { SYNC_TYPE, SyncDiff } from '@constants/profiles';
import { LocationIntegrationType, LocationIntegrationName } from '@constants/integrations';
import { GoogleIcon, IFoodIcon, CoverManagerIcon, FacebookIcon } from 'assets/images/integrations';
import { MagicWandIcon } from 'assets/images/icons';

const IconWrapper = styled('span')({
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& svg': {
        height: 18,
        width: 'auto',
    },
});

const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('common.platform')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('ProfilesPage.profileInformation')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.locations')}
                </Typography>
            ),
        },
        {
            key: 3,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('ProfilesPage.syncStatus')}
                </Typography>
            ),
        },
        { key: 4, component: <Typography align="left" variant="subtitle2" /> },
    ];
};

const LocationIntegrationIcon = ({ type }) => {
    switch (type) {
        case LocationIntegrationName.Google:
            return <GoogleIcon />;
        case LocationIntegrationName.Facebook:
            return <FacebookIcon />;
        case LocationIntegrationName.iFood:
            return <IFoodIcon />;
        case LocationIntegrationName.CoverManager:
            return <CoverManagerIcon />;
        default:
            return null;
    }
};

const GetTableBody = (data, locations, isUser, language, anchor, handleOpenTop, handleCloseTop, menu) => {
    const { t } = useTranslation();

    const SYNC_MAP = {
        [SYNC_TYPE['N/A']]: t('common.na'),
        [SYNC_TYPE.SYNCED]: t('ProfilesPage.synced'),
        [SYNC_TYPE.ACTION_REQUIRED]: t('common.actionRequired'),
        [SYNC_TYPE.IN_PROGRESS]: t('common.inProgress'),
        [SYNC_TYPE.SYNCED_WITH_CONFLICTS]: t('common.syncedWithConflicts'),
    };
    const SYNC_DIFF_MAP = {
        [SyncDiff.LocationName]: t('SettingsCompanies.locationName'),
        [SyncDiff.Status]: t('common.status'),
        [SyncDiff.PhoneNumber]: t('common.phoneNumber'),
        [SyncDiff.Email]: t('common.email'),
        [SyncDiff.Website]: t('SettingsCompanies.website'),
        [SyncDiff.Address]: t('SettingsCompanies.address'),
        [SyncDiff.BusinessHours]: t('SettingsCompanies.businessHours'),
        [SyncDiff.Description]: t('Listings.description'),
        [SyncDiff.OpeningDate]: t('SettingsCompanies.openingDate'),
        [SyncDiff.Logo]: t('common.logo'),
        [SyncDiff.CoverPhoto]: t('SettingsCompanies.coverPhoto'),
        [SyncDiff.Facebook]: 'Facebook',
        [SyncDiff.Instagram]: 'Instagram',
        [SyncDiff.Youtube]: 'Youtube',
        [SyncDiff.X]: 'X',
        [SyncDiff.LinkedIn]: 'LinkedIn',
        [SyncDiff.Tiktok]: 'Tiktok',
        [SyncDiff.Pinterest]: 'Pinterest',
    };
    const getProfileStatusTooltip = (status, diff) => {
        switch (status) {
            case SYNC_TYPE.IN_PROGRESS:
                return `${t('ProfilesPage.syncStarted')}: ${format(new Date(), 'dd MMM yyyy, HH:mm', {
                    locale: I18N_DATE_FORMAT[language],
                })}`;
            case SYNC_TYPE.ACTION_REQUIRED:
            case SYNC_TYPE.SYNCED_WITH_CONFLICTS:
                return (
                    <span>
                        {diff.map(d => (
                            <Fragment key={d}>
                                <span>{SYNC_DIFF_MAP[d]}</span>
                                <br />
                            </Fragment>
                        ))}
                        {`${t('ProfilesPage.syncCompleted')}: ${format(new Date(), 'dd MMM yyyy, HH:mm', {
                            locale: I18N_DATE_FORMAT[language],
                        })}`}
                    </span>
                );
            case SYNC_TYPE.SYNCED:
                return `${t('ProfilesPage.syncCompleted')}: ${format(new Date(), 'dd MMM yyyy, HH:mm', {
                    locale: I18N_DATE_FORMAT[language],
                })}`;
            default:
                return '';
        }
    };
    return data.map((profile, index) => {
        const fullAddress = profile.syncData?.hasBusinessAddress ? getCompanyFullAddress(profile.syncData) : '--';
        return [
            {
                component: (
                    <Typography variant="body2" align="left">
                        <IconWrapper>
                            <LocationIntegrationIcon type={profile.platform} />
                            {LocationIntegrationType[profile.platform]}
                        </IconWrapper>
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="subtitle2" align="left">
                        {profile.syncData?.locationName}
                        <Typography
                            variant="body2"
                            sx={{ color: 'rgba(0, 0, 0, 0.87)', marginTop: profile.syncData?.locationName ? '4px' : 0 }}
                        >
                            {fullAddress || '--'}
                            <br />
                            {formatPhoneNumber(profile.syncData?.phoneNumber)}
                        </Typography>
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {formatCompanies(locations, profile.locations, 30)}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography
                        variant="body2"
                        align="left"
                        sx={{ color: 'rgba(0, 0, 0, 0.87)', display: 'flex', alignItems: 'center' }}
                    >
                        <span
                            style={{
                                ...(profile.syncStatus === SYNC_TYPE.ACTION_REQUIRED && {
                                    backgroundColor: '#FF5853',
                                }),
                                ...(profile.syncStatus === SYNC_TYPE.IN_PROGRESS && { backgroundColor: '#FFCE4D' }),
                                ...(profile.syncStatus === SYNC_TYPE.SYNCED && { backgroundColor: '#4CC474' }),
                                ...(profile.syncStatus === SYNC_TYPE['N/A'] && { backgroundColor: '#f2f2f2' }),
                                ...(profile.syncStatus === SYNC_TYPE.SYNCED_WITH_CONFLICTS && {
                                    backgroundColor: '#A6D364',
                                }),
                                display: 'inline-block',
                                height: 8,
                                width: 8,
                                borderRadius: '50%',
                                marginRight: 8,
                            }}
                        />
                        <span style={{ textWrap: 'nowrap' }}>{SYNC_MAP[profile.syncStatus]}</span>
                        {profile.syncStatus !== SYNC_TYPE['N/A'] && (
                            <IconButton sx={{ padding: '4px !important' }}>
                                <Tooltip
                                    title={getProfileStatusTooltip(
                                        profile.syncStatus,
                                        profile.syncStatus === SYNC_TYPE.SYNCED_WITH_CONFLICTS
                                            ? profile.ignoredSyncFields
                                            : profile.syncDiff,
                                    )}
                                    placement="top"
                                    enterTouchDelay={0}
                                >
                                    <HelpIcon sx={{ height: '18px !important', width: '18px !important' }} />
                                </Tooltip>
                            </IconButton>
                        )}
                    </Typography>
                ),
            },
            ...(!isUser && profile.syncStatus !== SYNC_TYPE.SYNCED && profile.locations.length === 1
                ? [
                      {
                          component: (
                              <MoreMenu
                                  id={index}
                                  anchor={anchor}
                                  handleOpenEl={handleOpenTop}
                                  handleCloseEl={handleCloseTop}
                                  menu={menu}
                              />
                          ),
                      },
                  ]
                : [{ component: <div /> }]),
        ];
    });
};

const ProfilesTable = ({ isUser, locations, rowsPerPage, page, onPageChange, onRowsPerPageChange, onClickResolve }) => {
    const [anchor, setAnchor] = useState({ el: null, id: null });

    const { t } = useTranslation();
    const { data, loading, totalCount, pages } = useSelector(state => state.profiles);
    const language = useSelector(state => state.authentication.user?.language);
    const dispatch = useDispatch();

    const handleOpenTop = (event, id) => {
        event.stopPropagation();
        setAnchor({ id, el: event.currentTarget });
    };

    const handleCloseTop = () => setAnchor(prev => ({ ...prev, el: null }));

    const onToggleEnableSync = async e => {
        e.stopPropagation();
        handleCloseTop();
        try {
            const profileId = data[anchor.id].profileId;
            let updatedProfile;
            if (data[anchor.id].syncEnabled) {
                updatedProfile = await profilesService.disableProfileSync(profileId);
            } else {
                updatedProfile = await profilesService.enableProfileSync(profileId);
            }
            const profilesCopy = data.slice();
            profilesCopy[anchor.id] = updatedProfile;
            dispatch(profilesActions.setProfiles(profilesCopy));
            dispatch(alertActions.success(t('alertMessages.editSuccess')));
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.editFail')));
            }
        }
    };

    const onResolve = e => {
        e.stopPropagation();
        handleCloseTop();
        onClickResolve(anchor.id, data[anchor.id]?.platform);
    };

    const menu = [
        ...(data[anchor.id]?.syncStatus === SYNC_TYPE.ACTION_REQUIRED ||
        data[anchor.id]?.syncStatus === SYNC_TYPE.SYNCED_WITH_CONFLICTS
            ? [
                  {
                      id: 1,
                      icon: <MagicWandIcon />,
                      title: t('ProfilesPage.resolveTitle'),
                      onClick: onResolve,
                  },
              ]
            : []),
        {
            id: 2,
            icon: data[anchor.id]?.syncEnabled ? (
                <NotificationsOffIcon sx={{ height: '20px', width: '20px', fill: 'rgba(0, 0, 0, 0.6)' }} />
            ) : (
                <NotificationsIcon sx={{ height: '20px', width: '20px', fill: 'rgba(0, 0, 0, 0.6)' }} />
            ),
            title: data[anchor.id]?.syncEnabled ? t('Listings.disableProfileSync') : t('Listings.enableProfileSync'),
            onClick: onToggleEnableSync,
        },
    ];

    return (
        <TableContainer>
            <Table
                withHover
                columnNames={GetTableHeader()}
                rows={GetTableBody(data, locations, isUser, language, anchor, handleOpenTop, handleCloseTop, menu)}
                isDataLoading={loading}
                withPagination
                paginationProps={{
                    colSpan: 5,
                    count: totalCount,
                    rowsPerPage: rowsPerPage,
                    page: page,
                    onPageChange,
                    onRowsPerPageChange,
                    labelDisplayedRows: ({ from, to, count }) =>
                        `${from}–${to} ${t('Table.of')} ${count !== -1 ? count : `${t('Table.moreThan')} ${to}`}`,
                    labelRowsPerPage: <span>{t('Table.rowsPerPage')}</span>,
                    pages,
                }}
            />
        </TableContainer>
    );
};

export default ProfilesTable;
