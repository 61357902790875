import CheckboxMui from '@mui/material/Checkbox';

export const Checkbox = props => {
    return (
        <CheckboxMui
            sx={{
                color: 'rgba(0, 0, 0, 0.6)',
                '&.Mui-checked': {
                    color: '#1F4C5C',
                },
                '&.MuiCheckbox-indeterminate': {
                    color: '#1F4C5C',
                },
            }}
            {...props}
        />
    );
};
