import { styled } from '@mui/material/styles';
import { Input } from '@components/Input';
import { ModalBase } from '@components/Modal';

export const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 564,
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
    '& .modal-base-footer': {
        '@media (max-width: 500px)': {
            flexWrap: 'wrap',
        },
    },
    '& .modal-base-footer-btn-group': {
        '@media (max-width: 500px)': {
            width: '100%',
            '&:first-of-type': {
                paddingBottom: 0,
            },
            '& button': {
                width: 'calc(50% - 4px)',
            },
        },
    },
});

export const Content = styled('div')({
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateColumns: 'calc(50% - 12px) calc(50% - 12px)',
    '@media (max-width: 600px)': {
        gridTemplateColumns: '100%',
        gridGap: '16px',
    },
});

export const CheckboxWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: 40,
    '& .MuiCheckbox-root': {
        padding: 4,
    },
});

export const ActivityWrapper = styled('div')({
    display: 'flex',
    gap: 24,
    '@media (max-width: 600px)': {
        flexDirection: 'column',
        gap: 0,
    },
});

export const StyledFacebookInput = styled(Input)({
    '& .MuiInputLabel-root': {
        transform: 'translate(113px, 12px)',
    },
    '& .MuiOutlinedInput-notchedOutline legend span': {
        padding: 0,
    },
});

export const StyledInstagramInput = styled(Input)({
    '& .MuiInputLabel-root': {
        transform: 'translate(119px, 12px)',
    },
    '& .MuiOutlinedInput-notchedOutline legend span': {
        padding: 0,
    },
});
