import { useLocation, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ADVANCED_FEATURE_ROUTES = [
    '/surveys/sent',
    '/surveys/templates',
    '/surveys/responses',
    '/surveys/auto-surveys',
    '/messaging/campaigns',
    '/analytics/conversion',
    '/analytics/responses',
];

const BILLING_EXPIRED_ROUTES = [
    '/account-settings/general',
    '/account-settings/companies',
    '/account-settings/brands',
    '/account-settings/profile',
    '/account-settings/users',
];

export const PrivateRoute = ({ component: Component, path, ...rest }) => {
    const { pathname } = useLocation();
    const auth = useSelector(state => state.authentication);
    const account = useSelector(state => state.account.account);
    const isAccountOwner = auth.user?.isAccountOwner || auth.user?.isGlobalAdmin;
    const isAdmin = useSelector(state => {
        if (!state.authentication?.user?.id) return false;
        return state.authentication?.user?.roles?.filter(u => u.role === 0)?.length > 0;
    });
    const isUser = !isAccountOwner && !isAdmin;

    const productsMaxNumberExceeded =
        account &&
        (account?.companiesMaxCount < account?.companies?.length || account?.usersMaxCount < account?.users?.length);
    const billingExpired = account && account?.billingDiffInSeconds < 0;
    const advancedFeaturesLocked = !account?.campaignsEnabled && !account?.surveysEnabled;

    if (!auth.user && !auth.loading) {
        return (
            <Route
                {...rest}
                render={props => <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
            />
        );
    }

    if (!account && !path.startsWith('/onboarding')) return null;

    if (auth.user && account) {
        // Redirect to show billing
        if (
            isAccountOwner &&
            !BILLING_EXPIRED_ROUTES.some(r => r === pathname) &&
            (productsMaxNumberExceeded || billingExpired)
        ) {
            return <Redirect to="/account-settings/general" />;
        }
        // Redirect user from settings pages except /profile
        if (isUser && path.startsWith('/account-settings') && pathname !== '/account-settings/profile') {
            return <Redirect to="/account-settings/profile" />;
        }
        // Redirect admin from /general and /integrations
        if (
            !isAccountOwner &&
            isAdmin &&
            (pathname === '/account-settings/general' ||
                pathname === '/account-settings/integrations' ||
                pathname === '/account-settings/brands')
        ) {
            return <Redirect to="/account-settings/companies" />;
        }
        // Redirect everyone to the onboarding if it isn't completed
        if (!auth.user?.onboardingCompleted && !path.startsWith('/onboarding')) {
            return <Redirect to="/onboarding" />;
        }
        // Redirect everyone from the onboarding if it's completed
        if (auth.user?.onboardingCompleted && path.startsWith('/onboarding')) {
            return <Redirect to="/" />;
        }
        // Redirect everyone from the Surveys + Campaigns + Conversions + NPS and Surveys in Analytics if upgrade required
        if (advancedFeaturesLocked && ADVANCED_FEATURE_ROUTES.some(r => pathname === r)) {
            return <Redirect to="/" />;
        }
    }

    return <Route {...rest} render={props => <Component {...props} />} />;
};

export default PrivateRoute;
