export const getInitialsFull = (fullName, emptySymbol = '') => {
    if (!fullName) {
        return emptySymbol;
    }
    const initials = fullName.split(' ');
    if (initials.length > 1) {
        return initials[0].charAt(0).toUpperCase() + initials[1].charAt(0).toUpperCase();
    }
    return fullName.charAt(0).toUpperCase();
};

export const getInitialsParts = (firstName, lastName, emptySymbol = '') => {
    let firstPart = (firstName || '').substring(0, 1);
    let lastPart = (lastName || '').substring(0, 1);
    if (!firstName && !lastName) {
        return `${emptySymbol}${emptySymbol}`;
    }
    return `${firstPart}${lastPart}`;
};

export const getFullName = (firstName, lastName, emptySymbol = '') => {
    if (!firstName && !lastName) {
        return `${emptySymbol}${emptySymbol}`;
    }
    const firstNameCopy = firstName || '';
    const lastNameCopy = lastName || '';
    return `${firstNameCopy}${firstNameCopy && lastNameCopy ? ` ${lastNameCopy}` : lastNameCopy}`;
};

export const getUserContacts = (data, emptySymbol = '') => {
    const { firstName, lastName, phoneNumber, email } = data;

    if (firstName || lastName) {
        const firstNameCopy = firstName || '';
        const lastNameCopy = lastName || '';
        return `${firstNameCopy}${firstNameCopy && lastNameCopy ? ` ${lastNameCopy}` : lastNameCopy}`;
    }

    if (phoneNumber) {
        return phoneNumber;
    }

    if (email) {
        return email;
    }

    return `${emptySymbol}${emptySymbol}`;
};
