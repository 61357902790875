import { KB, MB, MEDIA_TYPE } from '@constants/file';

export const validateFileSize = (file, max, min) => {
    let error = '';
    let metric = '';
    let size = '';
    if (max && file?.size > max) {
        error = 'file_size_too_large';
        metric = max > MB ? 'MB' : 'KB';
        size = max > MB ? max / MB : max / KB;
    }
    if (min && file?.size < min) {
        error = 'file_size_too_small';
        metric = min > MB ? 'MB' : 'KB';
        size = min > MB ? min / MB : min / KB;
    }
    return { error, metric, size };
};

export const validateFileParams = async (
    mediaType,
    objUrl,
    params = { dimension: [], minDuration: undefined, maxDuration: undefined },
    callback,
) => {
    const { dimension, minDuration, maxDuration } = params;
    const [minWidth, minHeight, maxWidth, maxHeight] = dimension;
    try {
        const media = await (mediaType === MEDIA_TYPE.IMAGE ? loadImage(objUrl) : loadVideo(objUrl));
        const prop = mediaType === MEDIA_TYPE.IMAGE ? ['width', 'height'] : ['videoWidth', 'videoHeight'];
        if (
            (minWidth && media[prop[0]] < minWidth) ||
            (minHeight && media[prop[1]] < minHeight) ||
            (maxWidth && media[prop[0]] > maxWidth) ||
            (maxHeight && media[prop[1]] > maxHeight)
        ) {
            callback();
            return { success: false };
        } else if ((minDuration && media.duration < minDuration) || (maxDuration && media.duration >= maxDuration)) {
            callback();
            return { success: false };
        }
        return { success: true };
    } catch (_) {
        console.error("Couldn't upload picture");
        return { success: false };
    }
};

const loadImage = path => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = path;
        img.onload = () => resolve(img);
        img.onerror = e => reject(e);
    });
};

const loadVideo = path => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = path;
        video.preload = 'metadata';

        video.onloadedmetadata = () => {
            resolve(video);
        };

        video.onerror = e => reject(e);
    });
};
