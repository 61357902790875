import { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '@hooks/useTranslation';
import { ThemeProvider } from '@mui/system';
import { history } from '@helpers/history';
import { alertActions, userActions } from '@actions';
import { userService } from '@services';
import AuthRoute from '@features/AuthRoute';
import PrivateRoute from '@features/PrivateRoute';
import NotFoundPage from '@features/NotFoundPage';
import ErrorPage from '@pages/Error';
import ErrorBoundary from '@features/ErrorBoundary';
import { Toaster } from '@components/Toaster';
import LoginPage from '@pages/Login';
import SignupPage from '@pages/Signup';
import ResetPassword from '@pages/ResetPassword';
import ForgotPassword from '@pages/ForgotPassword';
import AppLayout from '@features/AppLayout';
import AccountSettingsPage from '@pages/AccountSettingsPage';
import FeedbacksPage from '@pages/FeedbacksPage';
import AnalyticsPage from '@pages/AnalyticsPage';
import ReviewsPage from '@pages/ReviewsPage';
import OnboardingPage from '@pages/OnboardingPage';
import VerifyEmail from '@pages/VerifyEmail';
import WidgetsPage from '@pages/WidgetsPage';
import TemplatesPage from '@pages/ReplyTemplates';
import AutoRepliesPage from '@pages/AutoReplies';
import FeedbackOverview from '@pages/FeedbackOverview';
import SurveysPage from '@pages/SurveysPage';
import AutomatedSurveys from '@pages/AutomatedSurveys';
import KeywordsPage from '@pages/Keywords';
import ContactsPage from '@pages/ContactsPage';
import CampaignsPage from '@pages/CampaignsPage';
import HomePage from '@pages/HomePage';
import InboxPage from '@pages/InboxPage';
import MicrositesPage from '@pages/MicrositesPage';
import PostsPage from '@pages/PostsPage';
import InboxTemplates from '@pages/InboxTemplates';
import ProfilesPage from '@pages/ProfilesPage';
import ProfilesMediaPage from '@pages/ProfilesMedia';
import ChatbotPage from '@pages/ChatbotPage';
import SEO from '@features/SEO';

import theme from '@theme';

const App = () => {
    const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    useEffect(() => {
        const unlisten = history.listen(() => {
            dispatch(alertActions.clearAll());
        });
        const user = JSON.parse(localStorage.getItem('user'));
        if (user?.id) {
            dispatch(userActions.getUser(user?.id));
            sendLastVisit();
        } else {
            dispatch(userActions.setUserLoading(false));
        }
        return unlisten;
    }, [dispatch]);

    useEffect(() => {
        if (user?.language && user?.language !== i18n.language) {
            i18n.changeLanguage(user?.language);
        }
    }, [user?.language, i18n]);

    const sendLastVisit = async () => {
        try {
            await userService.updateLastVisit(new Date());
        } catch (error) {
            console.error("Couldn't update last visit");
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Router history={history}>
                <ErrorBoundary>
                    <SEO />
                    <Switch>
                        <Route
                            exact
                            path={[
                                '/',
                                '/keywords',
                                '/microsites',
                                '/contacts',
                                '/social-posts',
                                '/account-settings',
                                '/account-settings/:tabName',
                                '/reviews',
                                '/reviews/:page',
                                '/analytics',
                                '/analytics/:page',
                                '/surveys',
                                '/surveys/:page',
                                '/messaging',
                                '/messaging/:page',
                                '/messaging/:page/:path',
                                '/campaigns',
                                '/campaigns/:page',
                                '/presence',
                                '/presence/:page',
                            ]}
                        >
                            <AppLayout>
                                <Switch>
                                    <PrivateRoute exact path="/" component={HomePage} />
                                    <PrivateRoute exact path="/surveys/sent" component={FeedbackOverview} />
                                    <PrivateRoute exact path="/surveys/responses" component={FeedbacksPage} />
                                    <PrivateRoute exact path="/surveys/templates" component={SurveysPage} />
                                    <PrivateRoute exact path="/surveys/auto-surveys" component={AutomatedSurveys} />
                                    <PrivateRoute exact path="/microsites" component={MicrositesPage} />
                                    <PrivateRoute exact path="/presence/profiles" component={ProfilesPage} />
                                    <PrivateRoute exact path="/presence/media" component={ProfilesMediaPage} />
                                    <PrivateRoute exact path="/reviews/manage" component={ReviewsPage} />
                                    <PrivateRoute exact path="/reviews/templates" component={TemplatesPage} />
                                    <PrivateRoute exact path="/reviews/auto-replies" component={AutoRepliesPage} />
                                    <PrivateRoute exact path="/reviews/widgets" component={WidgetsPage} />
                                    <PrivateRoute
                                        exact
                                        path="/account-settings/:tabName"
                                        component={AccountSettingsPage}
                                    />
                                    <PrivateRoute exact path="/messaging/inbox" component={InboxPage} />
                                    <PrivateRoute exact path="/messaging/templates" component={InboxTemplates} />
                                    <PrivateRoute
                                        exact
                                        path="/messaging/chatbot/knowledge-base"
                                        component={ChatbotPage}
                                    />
                                    <PrivateRoute exact path="/analytics/:page" component={AnalyticsPage} />
                                    <PrivateRoute exact path="/keywords" component={KeywordsPage} />
                                    <PrivateRoute exact path="/social-posts" component={PostsPage} />
                                    <PrivateRoute exact path="/campaigns/one-time" component={CampaignsPage} />
                                    <PrivateRoute exact path="/campaigns/automated" component={CampaignsPage} />
                                    <PrivateRoute exact path="/contacts" component={ContactsPage} />
                                    {/* Redirects from base URL */}
                                    <Redirect from="/surveys" to="/surveys/sent" />
                                    <Redirect from="/reviews" to="/reviews/manage" />
                                    <Redirect from="/messaging/chatbot" to="/messaging/chatbot/knowledge-base" />
                                    <Redirect from="/messaging" to="/messaging/inbox" />
                                    <Redirect from="/analytics" to="/analytics/conversion" />
                                    <Redirect from="/account-settings" to="/account-settings/profile" />
                                    <Redirect from="/campaigns" to="/campaigns/one-time" />
                                    <Redirect from="/presence" to="/presence/profiles" />
                                </Switch>
                            </AppLayout>
                        </Route>
                        <PrivateRoute exact path="/onboarding" component={OnboardingPage} />
                        <AuthRoute exact path="/login" component={LoginPage} />
                        <AuthRoute exact path="/signup" component={SignupPage} />
                        <Route path="/reset-password/:token" component={ResetPassword} />
                        <Route exact path="/forgot-password" component={ForgotPassword} />
                        <Route exact path="/verify-email/:token" component={VerifyEmail} />
                        <Route exact path="/error" component={ErrorPage} />
                        <Route path="*" exact component={NotFoundPage} />
                    </Switch>
                    <Toaster />
                </ErrorBoundary>
            </Router>
        </ThemeProvider>
    );
};

export default App;
