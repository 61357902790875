import {
    format,
    sub,
    startOfWeek,
    startOfMonth,
    startOfYear,
    isDate,
    differenceInCalendarDays,
    differenceInSeconds,
} from 'date-fns';
import { uk, enGB, es, pt } from 'date-fns/locale';
import { LANGUAGE } from '@constants/language';

const DEFAULT_FORMAT = 'dd/MM/yyyy';

const currentTimestamp = new Date();
const thisWeek = startOfWeek(currentTimestamp, { weekStartsOn: 1 });
const thisMonth = startOfMonth(currentTimestamp);
const thisYear = startOfYear(currentTimestamp);

const last7Days = sub(currentTimestamp, { days: 7 });
const lastMonth = sub(currentTimestamp, { months: 1 });
const last3Months = sub(currentTimestamp, { months: 3 });
const last6Months = sub(currentTimestamp, { months: 6 });
const lastYear = sub(currentTimestamp, { years: 1 });

const formatDate = (date, formatString = DEFAULT_FORMAT) => (isDate(date) ? format(date, formatString) : '');

export const getTimePeriod = () => {
    return {
        period: {
            thisWeek,
            thisMonth,
            thisYear,
            last7Days,
            lastMonth,
            last3Months,
            last6Months,
            lastYear,
        },
        currentTimestamp,
        formatDate,
    };
};

export const I18N_DATE_FORMAT = {
    [LANGUAGE.ENGLISH]: enGB,
    [LANGUAGE.UKRAINIAN]: uk,
    [LANGUAGE.PORTUGUESE]: pt,
    [LANGUAGE.SPANISH]: es,
};

export const getExpiredAtInfo = (date, language) => {
    let expiredAt = 'Invalid Date';
    try {
        const expiredAtDate = new Date(date);
        expiredAt = format(expiredAtDate, 'd MMMM yyyy', { locale: I18N_DATE_FORMAT[language] });
    } catch (_) {
        console.error("Coudn't parse expiredAt date");
    }
    return { expiredAt };
};

export function getDifferenceInDates(date, planGracePeriod = 0) {
    let billingDiffInDays = 0;
    let billingDiffInSeconds = 0;
    if (isDate(date)) {
        //expiration account days
        billingDiffInDays = differenceInCalendarDays(date, new Date()) - planGracePeriod;
        //blocked account days
        billingDiffInSeconds = differenceInSeconds(date, new Date());
    }

    return { billingDiffInDays, billingDiffInSeconds };
}

export function subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years);
    return date;
}

function convertUTCToLocal(timeString) {
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setUTCHours(hours, minutes);

    return format(date, 'HH:mm');
}

function convertLocalToUTC(timeString) {
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(hours, minutes);
    const isoString = date.toISOString();

    return isoString.slice(11, 16);
}

export function convertWorkingHours(data, type) {
    return data.map(day => ({
        ...day,
        hours: day.hours.map(hour => ({
            from: type === 'toUTC' ? convertLocalToUTC(hour.from) : convertUTCToLocal(hour.from),
            to: type === 'toUTC' ? convertLocalToUTC(hour.to) : convertUTCToLocal(hour.to),
        })),
    }));
}

export const generateTimeOptions = () => {
    const options = [];

    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const formattedHour = String(hour).padStart(2, '0');
            const formattedMinute = String(minute).padStart(2, '0');
            options.push(`${formattedHour}:${formattedMinute}`);
        }
    }

    return options.map(i => ({ value: i, label: i }));
};

export const getMonthsList = locale => {
    const months = [];
    for (let i = 0; i < 12; i++) {
        const monthDate = new Date(Date.UTC(2024, i, 15)); // 15 is a random day in the mid of the month
        months.push(format(monthDate, 'LLLL', { locale }));
    }
    return months;
};
