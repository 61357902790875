import { memo } from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import MetricItem from '../../Common/MetricItem';
import ConversionQRBarChart from './ConversionQRBarChart';
import ConversionBarChart from './ConversionBarChart';
import { useTranslation } from '@hooks/useTranslation';

export const MetricGrid = styled('div')(() => ({
    display: 'grid',
    gridGap: 16,
    gridTemplateAreas: '"bar metric1 metric2"',
    gridAutoColumns: 'calc(100% - 600px) minmax(270px, 300px) minmax(270px, 300px)',
    marginBottom: 16,
    '@media (max-width: 1280px)': {
        gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
        gridTemplateAreas: '"bar bar" "metric1 metric2"',
    },
    '@media (max-width: 600px)': {
        gridTemplateColumns: '100%',
        gridTemplateAreas: '"bar" "metric1" "metric2"',
    },
}));

const ConversionRates = memo(({ data, isLoading, language, type }) => {
    const { t } = useTranslation();

    return (
        <MetricGrid>
            <Paper
                elevation={1}
                sx={{
                    padding: '24px 24px 6px',
                    gridArea: 'bar',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: '4px',
                }}
            >
                <Typography variant="title1" sx={{ alignSelf: 'center' }}>
                    {t('AnalyticsConversion.reviewsLifecycle')}
                </Typography>
                {type === 'qr' ? (
                    <ConversionQRBarChart data={data} language={language} isLoading={isLoading} />
                ) : (
                    <ConversionBarChart data={data} language={language} isLoading={isLoading} />
                )}
            </Paper>
            <MetricItem
                title={t('AnalyticsConversion.responseRate')}
                data={{ metric: data.responseRate, delta: data.responseRateDelta }}
                styles={{ gridArea: 'metric1' }}
                withPercentage
            />
            <MetricItem
                title={t('AnalyticsConversion.reviewRate')}
                data={{ metric: data.reviewRate, delta: data.reviewRateDelta }}
                styles={{ gridArea: 'metric2' }}
                withPercentage
            />
        </MetricGrid>
    );
});

ConversionRates.displayName = 'ConversionRates';

export default ConversionRates;
