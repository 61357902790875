import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import { Input } from '@components/Input';
import { MultiSelect } from '@components/MultiSelect';
import { Select } from '@components/Select';
import { Switch } from '@components/Switch';
import BusinessHoursSelection from '@features/BusinessHoursSelection';
import CollectInformationBlock from '@pages/AccountSettingsPage/Channels/EditModal/LiveChatForm/CollectInformation';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { getChannelOptions } from '@helpers/channels';
import { useTranslation } from '@hooks/useTranslation';
import { CHANNEL } from '@constants/channels';
import { LABEL_TYPE } from '@constants/labels';
import { labelsActions } from '@actions';

const Container = styled('div')({
    display: 'grid',
    gridGap: '8px',
    gridTemplateColumns: '100%',
});

const EmailSelect = styled(Select)({
    '& .MuiFormControl-root': {
        paddingBottom: '8px !important',
    },
    '& .MuiFormHelperText-root': {
        bottom: '-11px !important',
    },
});

const LiveChatForm = ({ labelsOptions }) => {
    const { t } = useTranslation();
    const [channelSmsOptions, setChannelSmsOptions] = useState([]);
    const [channelEmailOptions, setChannelEmailOptions] = useState([]);
    const [isBusinessHoursEnabled, setIsBusinessHoursEnabled] = useState(false);

    const { contact: labels } = useSelector(state => state.labels);
    const channels = useSelector(state => state.channels.data);
    const companies = useSelector(state => state.account?.account?.companies || []);

    const dispatch = useDispatch();

    const { controlLiveChat, setValueLiveChat, getValuesLiveChat, watchLiveChat, triggerLiveChat } = useFormContext();

    const timeZone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

    const selectedLocationLabels = watchLiveChat('locationLabels');

    useEffect(() => {
        dispatch(labelsActions.get(LABEL_TYPE.CONTACT));
    }, []);

    useEffect(() => {
        const availableSmsChannels = channels
            .filter(channel => channel.type === CHANNEL.SMS || channel.type === CHANNEL.WHATSAPP)
            .map(c => c.id);
        const availableEmailChannels = channels.filter(channel => channel.type === CHANNEL.EMAIL).map(c => c.id);
        const newChannelSmsOptions = getChannelOptions({ out: true, filter: false }, availableSmsChannels, channels);
        const newChannelEmailOptions = getChannelOptions(
            { out: true, filter: false },
            availableEmailChannels,
            channels,
        );
        setChannelSmsOptions(newChannelSmsOptions);
        setChannelEmailOptions(newChannelEmailOptions);
    }, []);

    const companyOptions = useMemo(() => {
        return selectedLocationLabels.length === labelsOptions.length
            ? companies.map(item => ({ value: item.companyId, label: item.internalName }))
            : companies
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [companies, labelsOptions, selectedLocationLabels]);

    return (
        <Container>
            <Controller
                control={controlLiveChat}
                name="name"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsChannels.channelName')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                    />
                )}
            />
            <Controller
                control={controlLiveChat}
                name="locationLabels"
                render={({ field, ref }) => (
                    <MultiSelect
                        {...field}
                        ref={ref}
                        label={t('common.locationLabels')}
                        onChange={({ target: { value } }) => {
                            const selectedLabels = getMultiselectSelectedOptions(labelsOptions, value);
                            setValueLiveChat('locationLabels', selectedLabels, { shouldDirty: true });
                            const availableCompanies =
                                selectedLabels.length === labelsOptions.length
                                    ? companies.map(item => item.companyId)
                                    : companies
                                          .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                                          .map(item => item.companyId);
                            setValueLiveChat('companies', availableCompanies, {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                        }}
                        options={labelsOptions}
                        countable
                    />
                )}
            />
            <Controller
                control={controlLiveChat}
                name="companies"
                render={({ field, fieldState: { error }, ref }) => (
                    <MultiSelect
                        {...field}
                        ref={ref}
                        onChange={({ target: { value } }) => {
                            setValueLiveChat('companies', getMultiselectSelectedOptions(companyOptions, value), {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                        }}
                        options={companyOptions}
                        label={t('common.locations')}
                        fullWidth
                        countable
                        required
                        error={error}
                    />
                )}
            />
            <Typography variant="subtitle1" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {t('FeedbackSurveys.contactDetails')}
            </Typography>
            <CollectInformationBlock
                control={controlLiveChat}
                setValue={setValueLiveChat}
                getValues={getValuesLiveChat}
                watch={watchLiveChat}
                labels={labels}
            />
            <Typography variant="subtitle1" sx={{ marginBottom: '16px', color: 'rgba(0, 0, 0, 0.87)' }}>
                {t('common.notifications')}
            </Typography>
            <Controller
                control={controlLiveChat}
                name="smsChannelId"
                render={({ field, fieldState: { error }, ref }) => (
                    <Select
                        {...field}
                        ref={ref}
                        label={t('SettingsChannels.phoneNotificationsChannel')}
                        options={channelSmsOptions}
                        required
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                    />
                )}
            />
            <Controller
                control={controlLiveChat}
                name="emailChannelId"
                render={({ field, fieldState: { error }, ref }) => (
                    <EmailSelect
                        {...field}
                        ref={ref}
                        label={t('SettingsChannels.emailNotificationsChannel')}
                        options={channelEmailOptions}
                        required
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                    />
                )}
            />
            <Controller
                control={controlLiveChat}
                name="notifyAboutMissingConversations"
                render={({ field, ref }) => (
                    <Switch
                        {...field}
                        ref={ref}
                        checked={field.value}
                        handleChange={event => {
                            setValueLiveChat('notifyAboutMissingConversations', event.target.checked, {
                                shouldDirty: true,
                            });
                        }}
                        label={t('SettingsChannels.missingConversationsSwitch')}
                    />
                )}
            />
            <Typography
                variant="subtitle1"
                sx={{ marginTop: '8px', marginBottom: '16px', color: 'rgba(0, 0, 0, 0.87)' }}
            >
                {t('SettingsChannels.conversationStarters')}
            </Typography>
            <Controller
                control={controlLiveChat}
                name="welcomeHeading"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsChannels.welcomeHeading')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                    />
                )}
            />
            <Controller
                control={controlLiveChat}
                name="welcomeTagline"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsChannels.welcomeTagline')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                    />
                )}
            />
            <Typography variant="subtitle1" sx={{ marginBottom: '8px', color: 'rgba(0, 0, 0, 0.87)' }}>
                {t('Listings.businessHours')}
            </Typography>
            <Controller
                control={controlLiveChat}
                name="workingHoursEnabled"
                render={({ field, ref }) => (
                    <Switch
                        {...field}
                        ref={ref}
                        checked={field.value}
                        handleChange={event => {
                            setValueLiveChat('workingHoursEnabled', event.target.checked, { shouldDirty: true });
                            setIsBusinessHoursEnabled(event.target.checked);
                            triggerLiveChat(['offlineMessage']);
                        }}
                        label={t('SettingsChannels.businessHoursSwitch')}
                    />
                )}
            />
            {isBusinessHoursEnabled && (
                <Controller
                    control={controlLiveChat}
                    name="offlineMessage"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('SettingsChannels.offlineMessage')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                            multiline
                            minRows={4}
                        />
                    )}
                />
            )}
            {isBusinessHoursEnabled && (
                <Controller
                    control={controlLiveChat}
                    name="workingHours"
                    render={({ field, ref }) => (
                        <BusinessHoursSelection
                            {...field}
                            data={field?.value?.days}
                            ref={ref}
                            onChangeHours={value => {
                                setValueLiveChat('workingHours', { timeZone, days: value }, { shouldDirty: true });
                            }}
                        />
                    )}
                />
            )}
        </Container>
    );
};

export default LiveChatForm;
