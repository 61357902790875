export const ACCOUNT_SETTINGS_TABS = [
    {
        label: 'SettingsTabs.profile',
        value: '/account-settings/profile',
    },
    {
        label: 'SettingsTabs.general',
        value: '/account-settings/general',
    },
    {
        label: 'common.locations',
        value: '/account-settings/companies',
    },
    {
        label: 'common.brands',
        value: '/account-settings/brands',
    },
    {
        label: 'SettingsTabs.channels',
        value: '/account-settings/channels',
    },
    {
        label: 'common.users',
        value: '/account-settings/users',
    },
    {
        label: 'SettingsTabs.integrations',
        value: '/account-settings/integrations',
    },
];
