import { useEffect, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Drawer from '@components/Drawer';
import { Select } from '@components/Select';
import { Input } from '@components/Input';
import { feedbacksActions, alertActions } from '@actions';
import { feedbacksService } from '@services';
import { useTranslation } from '@hooks/useTranslation';

const TicketForm = memo(({ ticketData, onClose }) => {
    const { t } = useTranslation();
    const account = useSelector(state => state.account?.account);
    const feedbacks = useSelector(state => state.feedbacks.feedbacks);
    const dispatch = useDispatch();

    const defaultValues = useMemo(
        () => ({
            assigneeId: '',
            ticketStatus: '',
            ticketCategory: '',
            comment: '',
            ticketId: '',
            lastUpdated: '',
        }),
        [],
    );

    const { control, handleSubmit, reset, formState, setValue } = useForm({ mode: 'onTouched', defaultValues });

    useEffect(() => {
        if (ticketData?.ticket) {
            setValue('assigneeId', ticketData?.ticket?.assigneeId || '', { shouldValidate: true, shouldDirty: true });
            setValue('ticketStatus', ticketData?.ticket?.ticketStatus || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setValue('ticketCategory', ticketData?.ticket?.ticketCategory || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setValue('comment', ticketData?.ticket?.comment || '', { shouldValidate: true, shouldDirty: true });
            setValue('ticketId', ticketData?.ticket?.ticketId || '', { shouldValidate: true, shouldDirty: true });
            setValue('lastUpdated', ticketData?.ticket?.lastUpdated || '', { shouldValidate: true, shouldDirty: true });
        }
    }, [setValue, ticketData?.ticket]);

    const usersOptions = useMemo(() => {
        return account?.users
            .filter(user => {
                if (user.isAccountOwner || user.isGlobalAdmin) return true;
                return user.roles?.some(role => role.companyId === ticketData?.companyId);
            })
            .map(user => ({ label: `${user.firstName} ${user.lastName}`, value: user.id }));
    }, [account, ticketData]);

    const statusOptions = [
        { label: t('common.actionRequired'), value: 1 },
        { label: t('common.inProgress'), value: 2 },
        { label: t('FeedbackToolbar.completed'), value: 3 },
    ];

    const categoryOptions = [
        { label: t('FeedbackToolbar.price'), value: 1 },
        { label: t('FeedbackToolbar.quality'), value: 2 },
        { label: t('FeedbackToolbar.cleanliness'), value: 3 },
        { label: t('FeedbackToolbar.communication'), value: 4 },
        { label: t('FeedbackToolbar.empty'), value: 0 },
    ];

    const onSubmit = async ({ comment, assigneeId, ticketCategory, ticketStatus, ticketId, lastUpdated }) => {
        const payload = {
            assigneeId,
            ticketStatus,
            ...(ticketCategory ? { ticketCategory } : {}),
            ...(comment ? { comment } : {}),
            ...(ticketId ? { ticketId } : {}),
            ...(lastUpdated ? { lastUpdated } : {}),
        };
        const message =
            ticketData?.type === 'create' ? t('alertMessages.createSuccess') : t('alertMessages.updateSuccess');
        try {
            const review = await feedbacksService.addOrUpdateTicket(payload, ticketData.id);
            const updatedFeedbackIndex = feedbacks.findIndex(r => r.id === ticketData.id);
            const updatedFeedbacks = [...feedbacks];
            if (updatedFeedbackIndex !== -1) {
                updatedFeedbacks[updatedFeedbackIndex].ticket = review;
            }
            dispatch(feedbacksActions.successAddOrUpdateTicket(updatedFeedbacks));
            dispatch(alertActions.success(message));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                ticketData?.type === 'create'
                    ? dispatch(alertActions.error(t('alertMessages.createFail')))
                    : dispatch(alertActions.error(t('alertMessages.updateFail')));
            }
        }
        reset();
        onClose();
    };

    const { isDirty, isValid, isSubmitting } = formState;

    return (
        <Drawer
            anchor="right"
            open={Boolean(ticketData)}
            onClose={() => {
                reset();
                onClose();
            }}
            title={ticketData?.type === 'create' ? t('FeedbackToolbar.createTicket') : t('FeedbackToolbar.viewTicket')}
            primaryText={t('buttons.saveChanges')}
            primaryDisabled={!isDirty || !isValid || isSubmitting}
            primaryAction={handleSubmit(onSubmit)}
            secondaryText={t('buttons.cancel')}
        >
            <div style={{ display: 'grid', gridGap: '16px' }}>
                <Controller
                    control={control}
                    name="assigneeId"
                    rules={{ required: t('validation.required') }}
                    render={({ field, fieldState: { error }, ref }) => (
                        <Select
                            {...field}
                            ref={ref}
                            id="assigneeId"
                            label={t('common.assignedTo')}
                            fullWidth
                            options={usersOptions}
                            required
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="ticketStatus"
                    rules={{ required: t('validation.required') }}
                    render={({ field, fieldState: { error }, ref }) => (
                        <Select
                            {...field}
                            ref={ref}
                            id="ticketStatus"
                            label={t('common.status')}
                            fullWidth
                            options={statusOptions}
                            required
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="ticketCategory"
                    render={({ field, ref }) => (
                        <Select
                            {...field}
                            ref={ref}
                            id="ticketCategory"
                            label={t('common.category', { count: 1 })}
                            fullWidth
                            options={categoryOptions}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="comment"
                    render={({ field }) => (
                        <Input
                            inputProps={{ ...field }}
                            id="comment"
                            fullWidth
                            multiline
                            minRows={4}
                            label={t('FeedbackToolbar.ticketDescription')}
                        />
                    )}
                />
            </div>
        </Drawer>
    );
});

TicketForm.displayName = 'TicketForm';

export default TicketForm;
