import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import PageHeader from '@features/PageHeader';
import Button from '@components/Button';
import { ModalConfirm } from '@components/Modal';
import { CampaignsIcon } from 'assets/images/icons';
import { useTranslation } from '@hooks/useTranslation';
import { campaignsActions, alertActions, channelsActions, labelsActions } from '@actions';
import { campaignsService } from '@services';
import { MODAL_TYPE } from '@constants/modal';
import OneTimeTable from './OneTimeTable';
import OngoingTable from './OngoingTable';
import EditModal from './EditModal';
import { CAMPAIGN_TYPE } from '@constants/campaigns';
import { LABEL_TYPE } from '@constants/labels';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

const Campaigns = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const PAGE_TYPE = location.pathname === '/campaigns/automated' ? CAMPAIGN_TYPE.ONGOING : CAMPAIGN_TYPE.ONETIME;
    const [pageRequests, setPageRequests] = useState(0);
    const [rowsPerPageRequests, setRowsPerPageRequests] = useState(10);
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [modal, setModal] = useState({ open: false, type: MODAL_TYPE.CREATE });

    const companies = useSelector(state => state.account?.account?.companies || []);
    const selectedCampaignRef = useRef({});
    const firstRender = useRef(true);
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            if (firstRender.current) {
                await dispatch(channelsActions.get());
                await dispatch(campaignsActions.get(pageRequests, rowsPerPageRequests, PAGE_TYPE));
                await dispatch(labelsActions.get(LABEL_TYPE.CONTACT));
                await dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
                firstRender.current = false;
            }
        }
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        if (!firstRender.current) {
            dispatch(campaignsActions.get(pageRequests, rowsPerPageRequests, PAGE_TYPE));
        }
    }, [pageRequests, rowsPerPageRequests, PAGE_TYPE]);

    const onPageChange = (_, newPage) => setPageRequests(newPage);

    const onRowsPerPageChange = event => {
        setRowsPerPageRequests(parseInt(event.target.value, 10));
        setPageRequests(0);
    };

    const onClickCampaign = (campaign, type) => {
        setSelectedCampaign(campaign);
        selectedCampaignRef.current = campaign;
        switch (type) {
            case 'delete':
                setDeleteModalOpen(true);
                break;
            case 'edit':
                setModal({ open: true, type: MODAL_TYPE.EDIT });
                break;
            case 'view':
                setModal({ open: true, type: MODAL_TYPE.VIEW });
                break;
            case 'pause':
                onModifyCampaign('pause');
                break;
            case 'enable':
                onModifyCampaign('enable');
                break;
            default:
                break;
        }
    };

    const campaignStateMachine = {
        delete: { callback: campaignsService.deleteCampaign, withModal: true, setModalOpen: setDeleteModalOpen },
        pause: { callback: campaignsService.pauseCampaign, withModal: false },
        enable: { callback: campaignsService.enableCampaign, withModal: false },
    };

    const onModifyCampaign = async operation => {
        try {
            await campaignStateMachine[operation].callback(selectedCampaignRef.current?.id);
            await dispatch(campaignsActions.get(pageRequests, rowsPerPageRequests, PAGE_TYPE));
            dispatch(alertActions.success(t(`alertMessages.${operation}Success`)));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t(`alertMessages.${operation}Fail`)));
            }
        }
        if (campaignStateMachine[operation].withModal) {
            campaignStateMachine[operation].setModalOpen(false);
        }
        setSelectedCampaign({});
        selectedCampaignRef.current = {};
    };

    return (
        <Container>
            <PageHeader
                styles={{ marginBottom: '16px' }}
                icon={<CampaignsIcon />}
                title={t('CampaignsPage.title')}
                subtitle={t('CampaignsPage.subtitle')}
            >
                <Button variant="contained" onClick={() => setModal({ open: true, type: MODAL_TYPE.CREATE })}>
                    {t('buttons.createCampaign')}
                </Button>
            </PageHeader>
            {PAGE_TYPE === CAMPAIGN_TYPE.ONETIME ? (
                <OneTimeTable
                    companies={companies}
                    rowsPerPageRequests={rowsPerPageRequests}
                    pageRequests={pageRequests}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    onClickCampaign={onClickCampaign}
                />
            ) : (
                <OngoingTable
                    companies={companies}
                    rowsPerPageRequests={rowsPerPageRequests}
                    pageRequests={pageRequests}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    onClickCampaign={onClickCampaign}
                />
            )}
            {modal.open ? (
                <EditModal
                    modal={modal}
                    setModal={setModal}
                    campaignType={PAGE_TYPE}
                    companies={companies}
                    selectedCampaign={selectedCampaign}
                    setSelectedCampaign={setSelectedCampaign}
                    pageRequests={pageRequests}
                    rowsPerPageRequests={rowsPerPageRequests}
                />
            ) : null}
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={() => onModifyCampaign('delete')}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                    onSecondaryAction={() => setDeleteModalOpen(false)}
                />
            )}
        </Container>
    );
};

export default Campaigns;
