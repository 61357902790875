import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import { RadioButtonsGroup } from '@components/RadioButtonsGroup';
import { ModalBase } from '@components/Modal';

export const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 'calc(100% - 32px)',
        height: 'calc(var(--100vh) - 32px)',
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        height: 'calc(100% - 118px)',
        '@media (max-width: 768px)': {
            gridTemplateColumns: '100%',
        },
    },
});

export const KeyboardArrowDown = styled(KeyboardArrowDownIcon, {
    shouldForwardProp: prop => prop !== 'opened',
})(({ opened }) => ({
    transform: opened && 'rotate(180deg)',
}));

export const TabsWrapper = styled('div')({
    overflowX: 'auto',
    height: 32,
    marginBottom: 24,
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
});

export const Tabs = styled(RadioButtonsGroup)({
    width: 505,
    display: 'block',
    '& .MuiFormControlLabel-root': {
        height: 32,
        padding: '0 4px',
    },
});

export const TwoColumnsForm = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
    gridGap: 16,
    margin: '8px 0',
    '@media (max-width: 768px)': {
        gridTemplateColumns: '100%',
        gridGap: 0,
    },
});
