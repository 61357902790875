import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@components/Button';
import { Typography } from '@components/Typography';
import DragAndDrop, { SortableItem } from '@components/DragAndDrop';
import { useTranslation } from '@hooks/useTranslation';
import { validateFileSize, validateFileParams } from '@helpers/upload-file';
import { alertActions } from '@actions';
import { MEDIA_TYPE } from '@constants/file';
import { CloseIcon, ImportIcon } from 'assets/images/icons';

const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap',
});

const SortingContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap',
});

const MediaWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    background: 'rgba(0, 0, 0, 0.14)',
    width: 74,
    height: 74,
    borderRadius: 4,
    padding: 8,
    cursor: 'pointer',
    '& img, video': {
        width: 58,
        borderRadius: 4,
        height: 58,
        display: 'inline-block',
        objectFit: 'contain',
        background: '#fff',
    },
});

const RemoveIconWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
    width: 20,
    height: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: '50%',
    zIndex: 3,
    '& svg': {
        width: '16px',
        '& path': {
            fill: '#fff',
        },
    },
});

const Media = ({ listeners, attributes, item, disabled, onClickMedia, deleteMedia }) => {
    return (
        <MediaWrapper onClick={() => onClickMedia(item.link)}>
            {!disabled ? (
                <RemoveIconWrapper
                    onClick={e => {
                        e.stopPropagation();
                        deleteMedia(item.link);
                    }}
                >
                    <CloseIcon />
                </RemoveIconWrapper>
            ) : null}
            {item.type === MEDIA_TYPE.VIDEO && (
                <span
                    style={{
                        position: 'absolute',
                        color: '#fff',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <PlayArrowIcon sx={{ width: '28px', height: '28px' }} />
                </span>
            )}
            {item.type === MEDIA_TYPE.IMAGE ? (
                <img style={{ objectFit: 'contain' }} src={item.link} alt="logo" {...listeners} {...attributes} />
            ) : (
                <video width="58" height="58" {...listeners} {...attributes}>
                    <source src={item.link} />
                </video>
            )}
        </MediaWrapper>
    );
};

/**
 * @param {Object} imagesValidation {maxSize, minSize, [minWidth, minHeight, maxWidth, maxHeight]} - max, min size of the picture in bytes, required dimension
 * @param {Object} {videoValidation} {maxSize, minSize, [minWidth, minHeight, maxWidth, maxHeight]} - max, min size of the video in bytes, required dimension
 * @param {boolean} disabled disabled state
 * @param {number} limit maximum number of media
 * @param {function} onChange function to update the media list
 * @param {data} list of media links
 * @param {imageServiceCallback} function to upload the picture
 * @param {videoServiceCallback} function to upload the video
 * @param {setIsUploading} function to set the parent uploading state
 */
const MediaUploaderMulti = ({
    limit,
    imagesValidation = { maxSize: undefined, minSize: undefined, dimension: undefined },
    videoValidation = {
        maxSize: undefined,
        minSize: undefined,
        dimension: undefined,
        minDuration: undefined,
        maxDuration: undefined,
    },
    extensions,
    disabled,
    onChange,
    data = [],
    imageServiceCallback,
    videoServiceCallback,
    setIsUploading: setIsParentUploading = () => {},
}) => {
    const [media, setMedia] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const firstRender = useRef(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (firstRender.current) {
            setMedia(data);
            firstRender.current = false;
        }
    }, [data]);

    const { t } = useTranslation();

    const uploadMedia = async e => {
        if (e.target.files.length > 10) {
            dispatch(alertActions.error(t('alertMessages.tooManyFiles', { limit })));
            e.target.value = '';
            return;
        }
        try {
            let files = Array.from(e.target.files);
            if (!files.length) return;

            if (media.length + files.length > limit) {
                files = files.slice(0, limit - media.length);
            }
            const updatedMedia = [...media];

            for (let file of files) {
                const mediaType = file.type.startsWith('image') ? MEDIA_TYPE.IMAGE : MEDIA_TYPE.VIDEO;
                const { maxSize, minSize, dimension, minDuration, maxDuration } =
                    mediaType === MEDIA_TYPE.IMAGE ? imagesValidation : videoValidation;
                if (dimension || minDuration || maxDuration) {
                    const objUrl = URL.createObjectURL(file);
                    const { success } = await validateFileParams(
                        mediaType,
                        objUrl,
                        { dimension, minDuration, maxDuration },
                        () => {
                            dispatch(alertActions.error(t('apiErrors.invalid_format')));
                            e.target.value = '';
                            URL.revokeObjectURL(objUrl);
                        },
                    );
                    if (!success) {
                        continue;
                    }
                }
                // Validate file size
                const { error, metric, size } = validateFileSize(file, maxSize, minSize);
                if (error) {
                    dispatch(alertActions.error(t(`apiErrors.${error}`, { size, metric })));
                    continue;
                }
                setIsUploading(true);
                setIsParentUploading(true);
                const data = new FormData();
                data.append('file', file);
                data.append('fileName', file.name);
                const response = await (mediaType === MEDIA_TYPE.IMAGE
                    ? imageServiceCallback(data)
                    : videoServiceCallback(data));
                updatedMedia.push({ link: response.publicUrl, type: mediaType });
                setMedia(updatedMedia);
                onChange(updatedMedia);
            }
        } catch (_) {
            console.error("Couldn't upload image");
        }
        setIsUploading(false);
        setIsParentUploading(false);
        e.target.value = '';
    };

    const deleteMedia = link => {
        const updatedMedia = media.filter(i => i.link !== link);
        setMedia(updatedMedia);
        onChange(updatedMedia);
    };

    const onClickMedia = link => window.open(link, '_blank');

    return (
        <Container>
            <DragAndDrop
                items={media.map(i => i.link)}
                data={media}
                strategy="multi"
                onDragEndCb={result => {
                    setMedia(result);
                    onChange(result);
                }}
            >
                <SortingContainer>
                    {media.map(item => (
                        <SortableItem key={item.link} id={item.link}>
                            <Media
                                item={item}
                                disabled={disabled}
                                onClickMedia={onClickMedia}
                                deleteMedia={deleteMedia}
                            />
                        </SortableItem>
                    ))}
                </SortingContainer>
            </DragAndDrop>
            <div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content', alignItems: 'center' }}>
                <Button
                    variant="outlined"
                    aria-label="upload picture"
                    component="label"
                    disabled={isUploading || disabled || media.length >= limit}
                    startIcon={
                        !isUploading ? (
                            <ImportIcon />
                        ) : (
                            <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.38)' }} size={16} />
                        )
                    }
                >
                    <input
                        hidden
                        accept={extensions ? extensions : 'image/*'}
                        type="file"
                        onChange={uploadMedia}
                        multiple
                    />
                    {t('buttons.upload')}
                </Button>
                <Typography
                    variant="caption"
                    sx={{ color: 'rgba(0, 0, 0, 0.6)', display: 'inline-block', marginTop: '2px' }}
                >
                    {media.length}/{limit}
                </Typography>
            </div>
        </Container>
    );
};

export default MediaUploaderMulti;
