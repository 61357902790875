import { styled } from '@mui/system';
import { Typography } from '@components/Typography';
import { ArrowDownIcon } from 'assets/images/icons';
import { formatNumber, formatPercentNumber } from '@helpers/formatNumbers';

const ArrowDown = styled(ArrowDownIcon, {
    shouldForwardProp: prop => prop !== 'isUp' && prop !== 'isGreen' && prop !== 'isRed',
})(({ isUp, isGreen, isRed }) => ({
    width: 4,
    marginRight: 5,
    transform: 'translateY(-1px)',
    ...(isUp && {
        transform: 'translateY(-1px) rotate(180deg)',
    }),
    ...(isGreen && {
        '& path': {
            fill: '#0B8D00',
            fillOpacity: 1,
        },
    }),
    ...(isRed && {
        '& path': {
            fill: '#B00020',
            fillOpacity: 1,
        },
    }),
}));

const Percentage = ({ value, withSign = true }) => {
    const isPositive = value > 0;
    const isZero = value === 0;
    return (
        <Typography
            variant="caption"
            color="textSecondary"
            sx={{
                color: isPositive ? '#0B8D00' : isZero ? 'rgba(0, 0, 0, 0.6)' : '#B00020',
                display: 'initial',
                alignItems: 'center',
            }}
        >
            {!isZero ? (
                isPositive ? (
                    <ArrowDown isUp={!!isPositive} isGreen={isPositive} isRed={!isPositive} />
                ) : (
                    <ArrowDown isGreen={isPositive} isRed={!isPositive} />
                )
            ) : null}
            {withSign ? formatPercentNumber(value) : formatNumber(value)}
        </Typography>
    );
};

export default Percentage;
