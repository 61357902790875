import { memo, useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { analyticsActions, channelsActions } from '@actions';
import { CHANNEL } from '@constants/channels';
import { GroupByFilter } from '@constants/analytics';
import { subtractYears } from '@helpers/dates';
import { PrintButton } from '../Common/PrintButton';
import FiltersPanel from './FiltersPanel';
import ConversationsRates from './ConversationsRates';
import ConversationsTable from './ConversationsTable';
import ConversationsTrend from './ConversationsTrend';
import { checkTwilioTwoWaySms, checkWhatsAppStatus } from '@helpers/channels';
import { getLocationLabels } from '@helpers/locationLabels';
import { useTranslation } from '@hooks/useTranslation';
import { AnalyticsContainer, HeaderWrapper } from '../styles';

const Conversations = memo(() => {
    const { t } = useTranslation();
    const firstRender = useRef(true);

    const [filter, setFilter] = useState({
        labels: [],
        companies: [],
        channels: [],
        timeframe: {
            start: subtractYears(new Date(), 1),
            end: new Date(),
        },
        grouping: GroupByFilter.Months,
    });
    const [filterNumber, setFilterNumber] = useState(0);
    const [filterOpened, setFiltersOpened] = useState(false);

    const companies = useSelector(state => state.account?.account?.companies || []);
    const users = useSelector(state => state.account?.account?.users || []);
    const { data: channels, loading: channelsLoading } = useSelector(state => state.channels);
    const { location: labels, locationLoading: labelsLoading } = useSelector(state => state.labels);
    const { conversationsData, conversationsLoading } = useSelector(state => state.analytics, shallowEqual);
    const language = useSelector(state => state.authentication.user?.language, shallowEqual);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(channelsActions.get());
    }, []);

    const availableChannels = useMemo(
        () =>
            channels.filter(
                c =>
                    [CHANNEL.LIVE_CHAT, CHANNEL.FACEBOOK, CHANNEL.INSTAGRAM].includes(c.type) ||
                    checkTwilioTwoWaySms(c) ||
                    (checkWhatsAppStatus(c) && c.companies?.length),
            ),
        [channels],
    );

    const availableCompanies = useMemo(
        () =>
            companies.filter(company =>
                company?.channels?.some(channel => availableChannels?.map(item => item.id).includes(channel)),
            ),
        [companies, availableChannels],
    );

    const availableLabels = useMemo(
        () => getLocationLabels(labels, availableCompanies, t),
        [labels, availableCompanies],
    );

    useEffect(() => {
        if (companies.length && !labelsLoading && !channelsLoading) {
            setFilter(prev => ({
                ...prev,
                channels: [...availableChannels.map(item => item.id), null],
                companies: availableCompanies.map(item => item.companyId),
                labels: availableLabels.map(item => item.id),
            }));
        }
    }, [companies, labelsLoading, channelsLoading, channels, dispatch]);

    useEffect(() => {
        if (filter.companies.length && filter.channels.length) {
            if (firstRender.current) {
                onApplyFilters();
                firstRender.current = false;
            }
        }
    }, [filter, dispatch]);

    const onApplyFilters = () => {
        dispatch(
            analyticsActions.getAnalyticsConversations({
                companies: filter.companies,
                channels: filter.channels,
                from: filter.timeframe.start ? format(new Date(filter.timeframe.start), 'yyyy-MM-dd') : null,
                to: filter.timeframe.end ? format(new Date(filter.timeframe.end), 'yyyy-MM-dd') : null,
                grouping: filter.grouping,
            }),
        );
    };

    return (
        <>
            <HeaderWrapper>
                <FiltersPanel
                    setFiltersOpened={setFiltersOpened}
                    filtersNumber={filterNumber}
                    filterOpened={filterOpened}
                    companies={availableCompanies}
                    channels={availableChannels}
                    labels={availableLabels}
                    filter={filter}
                    setFilter={setFilter}
                    type="conversations"
                    setFilterNumber={setFilterNumber}
                    onApplyFilters={onApplyFilters}
                />
                <PrintButton />
            </HeaderWrapper>
            <AnalyticsContainer id="pl-content-analytics-conversations">
                <ConversationsRates data={conversationsData.rates} isLoading={conversationsLoading} />
                <ConversationsTrend data={conversationsData.trendData} language={language} />
                <ConversationsTable data={conversationsData.tableData} isLoading={conversationsLoading} users={users} />
            </AnalyticsContainer>
        </>
    );
});

Conversations.displayName = 'Conversations';

export default Conversations;
