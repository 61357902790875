export const formatRules = rules => {
    if (rules.length) {
        let plainRules = '';
        let count = 0;
        rules.forEach(rule => {
            if (plainRules.length > 0) {
                plainRules += ', ' + rule.name;
                count += 1;
            } else {
                plainRules += rule.name;
                count += 1;
            }
        });
        return `(${count}) ${plainRules.length > 24 ? plainRules.substring(0, 21) + '...' : plainRules}`;
    }
    return '';
};
