import { facebookConstants } from '@constants/facebook';

const initialState = {
    data: [],
    listing: {},
    loading: true,
    loadingListing: true,
    error: '',
};

export function facebook(state = initialState, action) {
    switch (action.type) {
        case facebookConstants.FACEBOOK_PROFILE_PAGES_REQUEST:
            return { ...state, loading: true, error: '' };
        case facebookConstants.FACEBOOK_LISTING_PROFILE_REQUEST:
            return { ...state, loadingListing: true, error: '' };
        case facebookConstants.FACEBOOK_PROFILE_PAGES_SUCCESS:
            return { ...state, loading: false, data: action.data, error: '' };
        case facebookConstants.FACEBOOK_LISTING_PROFILE_SUCCESS:
            return { ...state, loadingListing: false, listing: action.data, error: '' };
        case facebookConstants.FACEBOOK_PROFILE_PAGES_ERROR:
        case facebookConstants.FACEBOOK_LISTING_PROFILE_ERROR:
            return { ...state, error: action.message };
        default:
            return state;
    }
}
