import { styled } from '@mui/material/styles';
import { RadioButtonsGroup } from '@components/RadioButtonsGroup';
import { ModalBase } from '@components/Modal';

export const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 564,
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        padding: '24px 16px',
    },
});

export const RadioButtons = styled(RadioButtonsGroup)({
    width: 252,
    display: 'block',
    gridArea: 'area5',
    marginBottom: 16,
    '& .MuiFormControlLabel-root': {
        height: 32,
        padding: '0 4px',
    },
});

export const Content = styled('div')({
    display: 'grid',
    gridGap: '8px 24px',
    gridTemplateAreas: '"area1 area1" "area0 area2" "area3 area4" "area5 area5" "area6 area6" "area7 area7"',
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
    '@media (max-width: 768px)': {
        gridTemplateColumns: '100%',
        gridTemplateAreas: '"area1" "area0" "area2" "area3" "area4" "area5" "area6" "area7"',
        gridGap: '16px',
    },
});
