import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import PageHeader from '@features/PageHeader';
import IntegrationRequired from '@features/IntegrationRequired';
import { Typography } from '@components/Typography';
import { ModalConfirm } from '@components/Modal';
import Button from '@components/Button';
import { autoreplyRulesActions, alertActions, replyTemplatesActions, labelsActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';
import { AccIntegrationName } from '@constants/integrations';
import { autoreplyRulesService } from '@services';
import { LABEL_TYPE } from '@constants/labels';
import { AutoRepliesIcon } from 'assets/images/icons';
import EditModal from './EditModal';
import AutoreplyRulesTable from './AutoreplyRulesTable';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

const FORM_TYPE = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
};

const AutoReplies = () => {
    const [modal, setModal] = useState({ open: false, type: FORM_TYPE.NEW });
    const [selectedRule, setSelectedRule] = useState(null);
    const [pageRules, setPageRules] = useState(0);
    const [rowsPerPageRules, setRowsPerPageRules] = useState(10);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const { totalCount } = useSelector(state => state.autoreplyRules);
    const account = useSelector(state => state.account?.account);
    const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const companies = account?.companies || [];
    const GOOGLE_CONNECTED = account?.integrations.find(i => i.type === AccIntegrationName.Google)?.isActive;

    useEffect(() => {
        dispatch(autoreplyRulesActions.get(pageRules, rowsPerPageRules));
        dispatch(replyTemplatesActions.getAll());
        dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
    }, [pageRules, rowsPerPageRules, dispatch]);

    const onDelete = async () => {
        try {
            await autoreplyRulesService.deleteAutoreplyRule(selectedRule.id);
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
            if (pageRules === 0) {
                await dispatch(autoreplyRulesActions.get(pageRules, rowsPerPageRules));
            } else {
                setPageRules(0);
            }
            setDeleteModalOpen(false);
            setSelectedRule(null);
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.deleteFail')));
        }
    };

    const onAddRuleClick = () => setModal({ open: true, type: FORM_TYPE.NEW });

    return (
        <Container>
            {!GOOGLE_CONNECTED ? (
                <IntegrationRequired googleOnly />
            ) : (
                <PageHeader
                    styles={{ marginBottom: '16px' }}
                    icon={<AutoRepliesIcon />}
                    title={t('ReviewsAutoreplies.instantlyRespond')}
                    subtitle={t('ReviewsAutoreplies.googleConnected')}
                    metrics={
                        <Typography variant="body1">
                            {t('ReviewsAutoreplies.created')} {totalCount}
                        </Typography>
                    }
                >
                    {(user?.isAccountOwner || user?.isGlobalAdmin) && (
                        <Button variant="contained" onClick={onAddRuleClick}>
                            {t('buttons.addRule')}
                        </Button>
                    )}
                </PageHeader>
            )}
            {GOOGLE_CONNECTED && (
                <AutoreplyRulesTable
                    setSelectedRule={setSelectedRule}
                    setDeleteModalOpen={setDeleteModalOpen}
                    setModal={setModal}
                    setPage={setPageRules}
                    page={pageRules}
                    setRowsPerPage={setRowsPerPageRules}
                    rowsPerPage={rowsPerPageRules}
                    user={user}
                    companies={companies}
                />
            )}
            {modal.open ? (
                <EditModal
                    selectedRule={selectedRule}
                    setSelectedRule={setSelectedRule}
                    setModal={setModal}
                    modal={modal}
                    companies={companies}
                    page={pageRules}
                    setPage={setPageRules}
                    rowsPerPage={rowsPerPageRules}
                />
            ) : null}
            {isDeleteModalOpen ? (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDelete}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                />
            ) : null}
        </Container>
    );
};

export default AutoReplies;
