import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import cloneDeep from 'lodash.clonedeep';
import MenuItem from '@mui/material/MenuItem';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@components/Button';
import { Typography } from '@components/Typography';
import ImageUploaderSingle from '@components/ImageUploaderSingle';
import DragAndDrop, { SortableItem } from '@components/DragAndDrop';
import { useTranslation } from '@hooks/useTranslation';
import { micrositesService } from '@services';
import { PREVIEW_LOCALES, LANGUAGES_MAP } from '@constants/microsites';
import { MB } from '@constants/file';
import MicrositeLink from './MicrositeLink';
import LocalizeModal from './LocalizeModal';
import { CssMenu, StyledSwitch } from './styles';

const Landing = ({ isView, preview, setPreview, surveyOptions, liveChatChannelOptions, account }) => {
    const { t } = useTranslation();
    const [isUploading, setIsUploading] = useState(false);
    const [localizeModal, setLocalizeModal] = useState({ open: false, ctx: null });
    const { control, setValue, getValues } = useFormContext();
    const [anchorEl, setAnchorEl] = useState(null);

    const availableLanguages = LANGUAGES_MAP[account?.locale];
    const currentLocale = PREVIEW_LOCALES[account?.locale];

    const linkClick = key => {
        const linksCopy = cloneDeep(preview.links);
        linksCopy[key][0].isOpen = !linksCopy[key][0].isOpen;
        setPreview({ ...preview, links: linksCopy });
    };

    const onClickAddLink = () => {
        handleClose();
        const linksCopy = cloneDeep(preview.links);
        const newLinks = {
            ...linksCopy,
            [Object.keys(preview.links).length]: [
                { title: 'Default title', link: '', language: availableLanguages[0] },
            ],
        };
        setPreview({ ...preview, links: newLinks });
        setValue('links', newLinks, { shouldDirty: true });
    };

    const onClickAddSurvey = () => {
        handleClose();
        const linksCopy = cloneDeep(preview.links);
        const newLinks = {
            ...linksCopy,
            [Object.keys(preview.links).length]: [
                {
                    title: 'Default title',
                    link: '',
                    language: availableLanguages[0],
                    surveyId: surveyOptions[0]?.value,
                },
            ],
        };
        setPreview({ ...preview, links: newLinks });
        setValue('links', newLinks, { shouldDirty: true });
    };

    const onClickAddLiveChat = () => {
        handleClose();
        const linksCopy = cloneDeep(preview.links);
        const newLinks = {
            ...linksCopy,
            [Object.keys(preview.links).length]: [
                {
                    title: 'Default title',
                    link: '',
                    language: availableLanguages[0],
                    liveChatChannelId: liveChatChannelOptions[0].value,
                },
            ],
        };
        setPreview({ ...preview, links: newLinks });
        setValue('links', newLinks, { shouldDirty: true });
    };

    const onChangeDefaultLink = (e, key, lang) => {
        const linksCopy = cloneDeep(preview.links);
        // find index of the link to change
        const linkIndex = linksCopy[key].findIndex(l => l.language === lang);
        // update it with necessary field
        linksCopy[key][linkIndex] = { ...linksCopy[key][linkIndex], [e.target.name]: e.target.value };
        setPreview({ ...preview, links: linksCopy });
        setValue('links', linksCopy, { shouldDirty: true });
    };

    const onChangeSurvey = (e, key) => {
        const linksCopy = cloneDeep(preview.links);
        linksCopy[key].forEach(item => {
            item.surveyId = e.target.value;
            item.link = '';
        });
        setPreview({ ...preview, links: linksCopy });
        setValue('links', linksCopy, { shouldDirty: true });
    };

    const onChangeLiveChat = (e, key) => {
        const linksCopy = cloneDeep(preview.links);
        linksCopy[key].forEach(item => {
            item.liveChatChannelId = e.target.value;
            item.link = '';
        });
        setPreview({ ...preview, links: linksCopy });
        setValue('links', linksCopy, { shouldDirty: true });
    };

    const onClickDelete = key => {
        const linksCopy = cloneDeep(preview.links);
        delete linksCopy[key];
        const copyUpdatedIndexes = Object.values(linksCopy).reduce(
            (prev, value, index) => ({ ...prev, [index]: value }),
            {},
        );
        setPreview({ ...preview, links: copyUpdatedIndexes });
        setValue('links', copyUpdatedIndexes, { shouldDirty: true });
    };

    const uploadLogo = async e => {
        try {
            setIsUploading(true);
            const data = new FormData();
            data.append('file', e.target.files[0]);
            data.append('fileName', e.target.files[0].name);
            const response = await micrositesService.uploadMicrositeImage(data, preview.id);
            setPreview({ ...preview, logoUrl: response.publicUrl });
            setValue('logoUrl', response.publicUrl, { shouldDirty: true });
        } catch (_) {
            console.error("Couldn't upload logo microsite");
        }
        setIsUploading(false);
        e.target.value = '';
    };

    const onDeleteLogo = () => {
        setPreview(prev => ({ ...prev, logoUrl: null }));
        setValue('logoUrl', null, { shouldDirty: true });
    };

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const dndEntries = Object.entries(preview.links);

    return (
        <>
            <Typography variant="body2">{t('common.logo')}</Typography>
            <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', margin: '4px 0 8px' }}>
                {t('MicrositesPage.recommendedDimension')}
            </Typography>
            <ImageUploaderSingle
                maxSize={10 * MB}
                logoUrl={preview.logoUrl}
                disabled={isUploading || isView}
                onDelete={onDeleteLogo}
                uploadLogo={uploadLogo}
                isUploading={isUploading}
            />
            <Typography variant="body2" sx={{ paddingTop: '32px', color: 'rgba(0, 0, 0, 0.87)' }}>
                {t('MicrositesPage.additionalContent')}
            </Typography>
            <Controller
                control={control}
                name="socialMediaEnable"
                render={({ field, ref }) => (
                    <StyledSwitch
                        {...field}
                        ref={ref}
                        checked={getValues('socialMediaEnable')}
                        handleChange={event => {
                            setValue('socialMediaEnable', event.target.checked, { shouldDirty: true });
                            setPreview({ ...preview, socialMediaEnable: event.target.checked });
                        }}
                        label={t('MicrositesPage.displaySocialMediaLinks')}
                        disabled={isView}
                    />
                )}
            />
            <Controller
                control={control}
                name="showReviews"
                render={({ field, ref }) => (
                    <StyledSwitch
                        {...field}
                        ref={ref}
                        checked={getValues('showReviews')}
                        handleChange={event => {
                            setValue('showReviews', event.target.checked, { shouldDirty: true });
                            setPreview({ ...preview, showReviews: event.target.checked });
                        }}
                        label={t('MicrositesPage.showReviews')}
                        disabled={isView}
                    />
                )}
            />
            <div
                style={{
                    margin: '16px 0',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <div style={{ margin: '8px 0' }}>
                    <Typography variant="body2">{t('MicrositesPage.links')}</Typography>
                    <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', margin: '4px 0 8px' }}>
                        {t('MicrositesPage.linksSubtitle')}
                    </Typography>
                </div>
                <div>
                    <Button
                        id="pl-menu-add-link"
                        variant="outlined"
                        onClick={handleClick}
                        disabled={isView}
                        startIcon={anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    >
                        {t('buttons.add')}
                    </Button>
                    <CssMenu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={handleClose}
                        sx={{ zIndex: 9999 }}
                        PaperProps={{ id: 'pl-menu-add-link-list' }}
                        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                    >
                        <MenuItem onClick={onClickAddLink}>
                            <Typography variant="body2">{t('MicrositesPage.link')}</Typography>
                        </MenuItem>
                        <MenuItem onClick={onClickAddSurvey}>
                            <Typography variant="body2">{t('common.survey')}</Typography>
                        </MenuItem>
                        {liveChatChannelOptions.length ? (
                            <MenuItem onClick={onClickAddLiveChat}>
                                <Typography variant="body2">{t('common.liveChat')}</Typography>
                            </MenuItem>
                        ) : null}
                    </CssMenu>
                </div>
            </div>
            <div style={{ marginTop: 8, marginBottom: 24 }}>
                <DragAndDrop
                    items={dndEntries.map(i => i[0])}
                    data={preview.links}
                    onDragEndCb={result => {
                        setPreview({ ...preview, links: result });
                        setValue('links', result, { shouldDirty: true });
                    }}
                >
                    {dndEntries.map(([key, links]) => {
                        const link = links.find(l => l.language === currentLocale) || links[0];
                        return (
                            <SortableItem key={key} id={key}>
                                <MicrositeLink
                                    isView={isView}
                                    link={link}
                                    isOpen={links[0]?.isOpen}
                                    index={key}
                                    surveyOptions={surveyOptions}
                                    liveChatChannelOptions={liveChatChannelOptions}
                                    linkClick={linkClick}
                                    onChangeDefaultLink={onChangeDefaultLink}
                                    onChangeSurvey={onChangeSurvey}
                                    onChangeLiveChat={onChangeLiveChat}
                                    setLocalizeModal={setLocalizeModal}
                                    onClickDelete={onClickDelete}
                                />
                            </SortableItem>
                        );
                    })}
                </DragAndDrop>
            </div>
            {Object.keys(preview.links).length > 0 && (
                <LocalizeModal
                    modal={localizeModal}
                    onClose={() => setLocalizeModal({ open: false, ctx: null })}
                    preview={preview}
                    setPreview={setPreview}
                    locale={account?.locale}
                    currentLocale={currentLocale}
                />
            )}
        </>
    );
};

export default Landing;
