import { instagramConstants } from '@constants/instagramChannel';

const initialState = {
    data: [],
    loading: false,
    error: '',
};

export function instagramChannel(state = initialState, action) {
    switch (action.type) {
        case instagramConstants.INSTAGRAM_LOCATIONS_REQUEST:
            return { ...state, loading: true, error: '' };
        case instagramConstants.INSTAGRAM_LOCATIONS_SUCCESS:
            return { ...state, loading: false, data: action.data };
        case instagramConstants.INSTAGRAM_LOCATIONS_FAILURE:
            return { ...state, error: action.message, loading: false };
        default:
            return state;
    }
}
