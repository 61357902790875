import { contactsConstants } from '@constants/contacts';
import { contactsService } from '@services';

function request() {
    return { type: contactsConstants.CONTACTS_FETCH };
}

function success(data) {
    return { type: contactsConstants.CONTACTS_SUCCESS, data };
}

function failure(message) {
    return { type: contactsConstants.CONTACTS_ERROR, message };
}

const get = (params, page = 0, pageSize = 10) => {
    return dispatch => {
        dispatch(request());
        contactsService.get(params, page, pageSize).then(
            data => dispatch(success(data)),
            () => dispatch(failure()),
        );
    };
};

const setContacts = data => {
    return { type: contactsConstants.SET_CONTACTS, data };
};

const getContact = id => {
    return dispatch => {
        dispatch(request());
        contactsService.getContact(id).then(
            data => {
                dispatch(setContact(data));
                return data;
            },
            () => dispatch(failure()),
        );
    };
};

const setContact = data => {
    return { type: contactsConstants.SET_CONTACT, data };
};

const setSearchedContacts = data => {
    return { type: contactsConstants.SEARCHED_CONTACTS_SUCCESS, data };
};

const getSearchedContacts = (params, page = 0, pageSize = 10) => {
    return dispatch => {
        dispatch(request());
        contactsService.get(params, page, pageSize).then(
            data => dispatch(setSearchedContacts(data)),
            () => dispatch(failure()),
        );
    };
};

const resetSearchedContacts = () => {
    return { type: contactsConstants.SEARCHED_CONTACTS_RESET };
};

const setBulkEditData = data => {
    return { type: contactsConstants.BULK_EDIT_DATA_SUCCESS, data };
};

const getBulkEditData = params => {
    return dispatch => {
        contactsService.getBulkEditData(params).then(data => dispatch(setBulkEditData(data)));
    };
};

const setContactOpen = data => {
    return { type: contactsConstants.CONTACT_OPEN, data };
};

export const contactsActions = {
    get,
    setContacts,
    setContact,
    getContact,
    getSearchedContacts,
    resetSearchedContacts,
    getBulkEditData,
    setBulkEditData,
    setContactOpen,
};
