export const PRICING_PLANS = [
    {
        id: 2,
        name: 'Starter',
        users: 3,
    },
    {
        id: 3,
        name: 'Professional',
        users: 5,
    },
    {
        id: 4,
        name: 'Enterprise',
        users: 0,
    },
];

export const BILLING_TIME_MAP = {
    MONTHLY: 1,
    ANNUAL: 2,
};

export const BILLING_TYPE_MAP = {
    TRIAL: 'Free Trial',
    STARTER: 'Starter',
    PROFESSIONAL: 'Professional',
    ENTERPRISE: 'Enterprise',
};

export const CURRENCY_MAP = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    BRL: 'R$',
};
