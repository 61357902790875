import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';

export const Container = styled('div')({
    padding: '22px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff !important',
    borderRadius: '0 !important',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12) !important',
    zIndex: 3,
    position: 'fixed',
    left: 280,
    right: 0,
    height: 72,
    '@media (max-width: 1024px)': {
        height: 48,
        top: 60,
        left: 0,
    },
    '@media print': {
        top: 0,
        position: 'relative',
    },
});

export const RightWrapper = styled('div')({
    '@media (max-width: 1024px)': {
        display: 'none',
    },
});

export const CssMenu = styled(Menu)({
    '.MuiMenu-paper': {
        minWidth: '144px',
        boxShadow:
            '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 8,
        transform: 'translateY(6px) !important',
    },
    '.MuiMenuItem-root': {
        padding: '8px 12px',
    },
    '.MuiMenu-list': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
});

export const UserAvatar = styled('span')({
    width: 28,
    height: 28,
    borderRadius: 8,
    border: '1px solid #1F4C5C',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#62828D',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
});
