import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import Drawer from '@components/Drawer';
import ContactInfoComponent from '@features/ContactInfo';
import Avatar from '@components/Avatar';
import ContactSendMessage from '@features/ContactSendMessage';
import EditContactModal from '@features/EditContactModal';
import { ModalConfirm } from '@components/Modal';
import { useTranslation } from '@hooks/useTranslation';
import { contactsActions, alertActions } from '@actions';
import { contactsService } from '@services';
import { getInitialsParts, getUserContacts } from '@helpers/userName';
import { getFullName } from '@helpers/userName';
import { MODAL_TYPE } from '@constants/modal';
import { UserIcon } from 'assets/images/icons';

const StyledUserIcon = styled(UserIcon)({
    width: '16px !important',
    height: '16px !important',
    '& path': {
        fill: 'rgba(0, 0, 0, 0.38)',
        fillOpacity: 1,
    },
});

const ContactInfo = ({
    onClickContact,
    editModal,
    setEditModal,
    sendMessageModal,
    setSendMessageModal,
    deleteModal,
    setDeleteModal,
    contactDrawerOpened,
    onCloseContactDrawer,
    withRefetchContacts,
    withRefetchSurveys,
    withRefetchReviews,
    refetchCallBack,
}) => {
    const { contact } = useSelector(state => state.contacts);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onDeleteContact = async () => {
        try {
            await contactsService.deleteContact(contact?.id);
            refetchCallBack && refetchCallBack();
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.deleteFail')));
            }
        }
        setEditModal({ open: false, type: MODAL_TYPE.CREATE });
        setDeleteModal(prev => ({ ...prev, open: false }));
        onCloseContactDrawer();
        dispatch(contactsActions.setContact({}));
    };

    return (
        <>
            <Drawer
                anchor="right"
                open={contactDrawerOpened}
                onClose={onCloseContactDrawer}
                title={
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 20 }}>
                        <Avatar
                            size="medium"
                            fullName={getFullName(contact?.firstName, contact?.lastName)}
                            label={getInitialsParts(contact?.firstName, contact?.lastName)}
                            icon={<StyledUserIcon />}
                        />
                        <span style={{ marginLeft: 8 }}>
                            {getUserContacts(
                                {
                                    firstName: contact?.firstName,
                                    lastName: contact?.lastName,
                                    phoneNumber: contact?.phoneNumber,
                                    email: contact?.email,
                                },
                                '-',
                            )}
                        </span>
                    </div>
                }
                primaryText={t('buttons.edit')}
                primaryButtonType="outlined"
                primaryAction={() => onClickContact(contact, 'edit')}
                secondaryText={t('buttons.delete')}
                secondaryButtonType="danger"
                secondaryAction={() => onClickContact(contact, 'delete')}
                additionalText={t('buttons.sendMessage')}
                additionalButtonType="contained"
                additionalAction={() => onClickContact(contact, 'sendMessage')}
            >
                <ContactInfoComponent contact={contact} />
            </Drawer>
            {editModal.open ? (
                <EditContactModal
                    modal={editModal}
                    onClose={() => setEditModal({ open: false, type: MODAL_TYPE.EDIT })}
                    withDeleteContact
                    setDeleteModal={setDeleteModal}
                    withRefetchContacts={withRefetchContacts}
                    withRefetchSurveys={withRefetchSurveys}
                    withRefetchReviews={withRefetchReviews}
                    refetchCallBack={refetchCallBack}
                />
            ) : null}
            {sendMessageModal.open ? (
                <ContactSendMessage modal={sendMessageModal} setModal={setSendMessageModal} />
            ) : null}
            {deleteModal.open && (
                <ModalConfirm
                    isOpen={deleteModal.open}
                    handleClose={() => setDeleteModal(prev => ({ ...prev, open: false }))}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDeleteContact}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                    onSecondaryAction={() => setDeleteModal(prev => ({ ...prev, open: false }))}
                />
            )}
        </>
    );
};

export default ContactInfo;
