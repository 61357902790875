import { useMemo, memo } from 'react';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import DoughnutChart from '@components/Charts/DoughnutChart';
import { formatNumber, formatPercentNumber } from '@helpers/formatNumbers';

const createChartData = (data, hasData, isMobile, t) => {
    const RATING_MAP = {
        1: t('AnalyticsReviews.stars1'),
        2: t('AnalyticsReviews.stars2'),
        3: t('AnalyticsReviews.stars3'),
        4: t('AnalyticsReviews.stars4'),
        5: t('AnalyticsReviews.stars5'),
    };
    const EMPTY_SPACE = '                                   ';
    const backgroundColors = ['#FF5853', '#FFA949', '#FFCE4D', '#99D551', '#4CC474'];
    const borderColors = ['#FF5853', '#FFA949', '#FFCE4D', '#99D551', '#4CC474'];

    return {
        labels: Object.entries(RATING_MAP).map(([key, value]) => {
            const set = data.find(d => d.rating === +key);
            return `${value} - ${set?.count} (${formatPercentNumber(set?.percentage)})${isMobile ? EMPTY_SPACE : ''}`;
        }),
        datasets: [
            {
                label: '',
                data: !hasData
                    ? [1]
                    : Object.keys(RATING_MAP).map(key => data.find(d => d.rating === +key)?.count || 0),
                backgroundColor: !hasData ? ['#F2F2F2', ...backgroundColors] : backgroundColors,
                borderColor: !hasData ? ['#F2F2F2', ...borderColors] : borderColors,
                borderWidth: 1,
            },
        ],
    };
};

const DoughnutItem = memo(({ data, isLoading, type }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('@media (max-width: 1200px)');

    const hasData = useMemo(() => data.ratingDistributions.some(item => !!item.count), [data]);
    const datasets = useMemo(
        () => createChartData(data.ratingDistributions, hasData, isMobile, t),
        [data.ratingDistributions, hasData, isMobile],
    );

    return (
        <Paper
            elevation={1}
            sx={{
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '4px',
                breakInside: 'avoid',
                gridArea: 'doughnut',
            }}
        >
            <Typography variant="title1" style={{ paddingBottom: '10px' }}>
                {type === 'feedbacks'
                    ? t('AnalyticsReviews.responsesRatingDistribution')
                    : t('AnalyticsReviews.reviewsRatingDistribution')}
            </Typography>
            <div
                style={{
                    position: 'relative',
                    width: isMobile ? '100%' : '450px',
                    height: isMobile ? '460px' : '300px',
                    marginTop: isMobile ? '32px' : 0,
                }}
            >
                {!isLoading ? (
                    <DoughnutChart
                        datasets={datasets}
                        hasData={hasData}
                        optionsProps={{
                            plugins: {
                                legend: {
                                    position: isMobile ? 'bottom' : 'right',
                                    ...(isMobile && { align: 'start' }),
                                },
                            },
                        }}
                        overall={{
                            title:
                                type === 'feedbacks'
                                    ? t('AnalyticsReviews.totalResponses')
                                    : t('AnalyticsReviews.totalReviews'),
                            data:
                                type === 'feedbacks'
                                    ? formatNumber(data.totalResponses)
                                    : formatNumber(data.totalReviews),
                        }}
                    />
                ) : null}
            </div>
        </Paper>
    );
});

DoughnutItem.displayName = 'DoughnutItem';

export default DoughnutItem;
