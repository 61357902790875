export const contactsConstants = {
    CONTACTS_FETCH: 'CONTACTS_FETCH',
    CONTACTS_SUCCESS: 'CONTACTS_SUCCESS',
    CONTACTS_ERROR: 'CONTACTS_ERROR',
    SET_CONTACTS: 'SET_CONTACTS',
    SET_CONTACT: 'SET_CONTACT',
    SEARCHED_CONTACTS_SUCCESS: 'SEARCHED_CONTACTS_SUCCESS',
    SEARCHED_CONTACTS_RESET: 'SEARCHED_CONTACTS_RESET',
    BULK_EDIT_DATA_SUCCESS: 'BULK_EDIT_DATA_SUCCESS',
    CONTACT_OPEN: 'CONTACT_OPEN',
};
