import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Button from '@components/Button';
import { ModalBase } from '@components/Modal';
import { FiltersFormCreator } from '@components/FiltersFormCreator';
import { getChannelOptions } from '@helpers/channels';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { useTranslation } from '@hooks/useTranslation';
import { FiltersIcon } from 'assets/images/icons';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        width: 564,
        '@media (max-width: 1024px)': {
            width: '100%',
            height: '100%',
            borderRadius: 0,
            maxHeight: 'none',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
    '& #pl-filters-inbox': {},
});

export const FiltersWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: '8px',
    '@media print': {
        '& button': {
            display: 'none',
        },
    },
});

const FiltersPanel = ({ onApplyFilters, filter, setFilter, assignedUsersOptions }) => {
    const [isFilterOpened, setFilterOpened] = useState(false);
    const [refetchFilters, setRefetchFilters] = useState(false);

    const channels = useSelector(state => state.channels.data);
    const { archived } = useSelector(state => state.conversations);
    const companies = useSelector(state => state.account?.account?.companies || []);
    const users = useSelector(state => state.account?.account?.users || []);

    const { t } = useTranslation();

    useEffect(() => {
        if (refetchFilters) {
            setRefetchFilters(false);
            onApplyFilters();
        }
    }, [refetchFilters]);

    const options = useMemo(() => {
        //statusesOptions
        const statusesOptions = [
            { value: 0, label: t('InboxPage.open') },
            { value: 1, label: t('InboxPage.resolved') },
        ];

        //channelsOptions
        const availableChannels = [...new Set(companies.map(c => c.channels).flat())];
        const channelsOptions = getChannelOptions(
            {
                in: true,
                smsTwoWay: true,
                withArchivedMultiselect: archived > 0,
                allowDisconnected: true,
            },
            availableChannels,
            channels,
        );

        return {
            status: statusesOptions,
            channels: channelsOptions,
            assignedUsers: assignedUsersOptions,
        };
    }, [companies, channels, users, archived, filter]);

    const update = {
        status: e => {
            setFilter(prev => ({ ...prev, status: e.target.value, conversationId: null }));
        },
        channels: e => {
            setFilter(prev => ({
                ...prev,
                channels: getMultiselectSelectedOptions(options.channels, e.target.value),
                conversationId: null,
            }));
        },
        assignedUsers: e => {
            setFilter(prev => ({
                ...prev,
                assignedUsers: getMultiselectSelectedOptions(options.assignedUsers, e.target.value),
                conversationId: null,
            }));
        },
    };

    const formOptions = [
        {
            type: 'select',
            label: t('filters.statuses'),
            value: filter.status,
            onChange: update.status,
            options: options.status,
        },
        {
            type: 'multiSelect',
            label: t('filters.channels'),
            value: filter.channels,
            onChange: update.channels,
            options: options.channels,
            countable: true,
            error: !filter.channels.length,
            nameBased: true,
        },
        {
            type: 'multiSelect',
            label: t('common.assignedTo'),
            value: filter.assignedUsers,
            onChange: update.assignedUsers,
            options: options.assignedUsers,
            countable: true,
            error: !filter.assignedUsers.length,
            style: { gridColumn: '1 / span 2' },
        },
    ];

    const isValid = Object.keys(filter).every(key => {
        if (Array.isArray(filter[key])) {
            return !!filter[key].length;
        } else {
            return true;
        }
    });

    return (
        <>
            <Button
                sx={{
                    minWidth: '40px',
                    marginBottom: '22px',
                    '& .MuiButton-startIcon': {
                        margin: 0,
                    },
                }}
                variant="outlined"
                onClick={() => setFilterOpened(!isFilterOpened)}
                startIcon={<FiltersIcon />}
            />
            <Modal
                isOpen={isFilterOpened}
                handleClose={() => setFilterOpened(false)}
                title={t('buttons.filters')}
                onPrimaryAction={() => {
                    setRefetchFilters(true);
                    setFilterOpened(false);
                }}
                primaryActionDisabled={!isValid}
                primaryActionText={t('buttons.applyFilters')}
                onSecondaryAction={() => setFilterOpened(false)}
                secondaryActionText={t('buttons.cancel')}
            >
                <FiltersFormCreator type="inbox" options={formOptions} />
            </Modal>
        </>
    );
};

export default FiltersPanel;
