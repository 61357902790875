import { format } from 'date-fns';
import { styled } from '@mui/system';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@components/Typography';
import Avatar from '@components/Avatar';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { getInitialsFull } from '@helpers/userName';
import { AUTHOR, MEDIA_STATUS } from '@constants/profiles-media';
import { UserIcon } from 'assets/images/icons';
import { ReplyIcon } from 'assets/images/icons';
import { useTranslation } from '@hooks/useTranslation';

const StyledUserIcon = styled(UserIcon)({
    width: '16px !important',
    height: '16px !important',
    '& path': {
        fill: 'rgba(0, 0, 0, 0.38)',
        fillOpacity: 1,
    },
});
const StyledReplyIcon = styled(ReplyIcon)({
    width: '16px !important',
    height: '16px !important',
    '& path': {
        fill: 'rgba(0, 0, 0, 0.38)',
        fillOpacity: 1,
    },
});

const StyledImageBar = styled(ImageListItemBar)({
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    transition: 'opacity 0.3s',
    opacity: 0,
    height: 72,
    padding: '16px',
    cursor: 'pointer',
    '& .MuiImageListItemBar-titleWrap': {
        padding: 0,
        marginRight: '8px',
    },
});

const MediaInfo = ({ handleOpenTop, data, language }) => {
    const { t } = useTranslation();

    const isBusiness = data.authorType === AUTHOR.BUSINESS;

    return (
        <StyledImageBar
            title={
                <div
                    style={{
                        display: 'grid',
                        gridGap: 8,
                        gridTemplateColumns: '40px calc(100% - 48px)',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ backgroundColor: '#fff', borderRadius: '50%' }}>
                        {isBusiness ? (
                            <Avatar size="large" icon={<StyledReplyIcon />} />
                        ) : (
                            <Avatar
                                size="large"
                                fullName={data.authorName}
                                label={getInitialsFull(data.authorName)}
                                icon={<StyledUserIcon />}
                            />
                        )}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            variant="subtitle1"
                            sx={{ color: 'rgba(0, 0, 0, 0.87)', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {isBusiness ? t('common.business') : data.authorName}
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{ color: 'rgba(0, 0, 0, 0.6)', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {format(new Date(data.createdAt), 'dd MMM yyyy, HH:mm', {
                                locale: I18N_DATE_FORMAT[language],
                            })}{' '}
                            - {data.profileName}
                        </Typography>
                    </div>
                </div>
            }
            actionIcon={
                ((data.authorType === AUTHOR.CUSTOMER && data.status !== MEDIA_STATUS.REPORTED) ||
                    data.authorType === AUTHOR.BUSINESS) && (
                    <IconButton sx={{ height: '28px', width: '28px' }} onClick={e => handleOpenTop(e, data.id)}>
                        <MoreVertIcon sx={{ height: '24px', width: '24px' }} />
                    </IconButton>
                )
            }
        />
    );
};

export default MediaInfo;
