import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

function get(query, take, skip) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(query),
    };
    return fetch(`${BASE_URL}api/presence/media?take=${take}&skip=${skip}`, requestOptions).then(handleResponse);
}

function deleteMedia(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/presence/media/${id}`, requestOptions).then(handleResponse);
}

function reportMedia(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/presence/media/takedown`, requestOptions).then(handleResponse);
}

function uploadMedia(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/presence/media/finish-upload`, requestOptions).then(handleResponse);
}

function uploadProfilesImages(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + user.token,
        },
        body: data,
    };
    return fetch(`${BASE_URL}api/presence/media/upload-photo`, requestOptions).then(handleResponse);
}

export const profilesMediaService = {
    get,
    deleteMedia,
    reportMedia,
    uploadMedia,
    uploadProfilesImages,
};
