import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Checkbox } from '@components/Checkbox';
import { MultiSelectLabels } from '@components/MultiSelectLabels';
import { Switch } from '@components/Switch';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { LABEL_TYPE } from '@constants/labels';

const PlatformWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    transform: 'translateX(-7px)',
    '& .MuiCheckbox-root': {
        padding: 4,
    },
});

const FieldWrapper = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
    gridGap: 16,
    marginTop: '8px',
    alignItems: 'center',
});

const CollectInformationBlock = ({ control, setValue, getValues, watch, labels }) => {
    const { t } = useTranslation();

    const labelsOptions = useMemo(() => labels.map(item => ({ value: item.id, label: item.name })), [labels]);

    const contactEmail = watch('contactEmail');
    const contactPhone = watch('contactPhone');
    const contactName = watch('contactName');

    return (
        <div style={{ paddingBottom: 16 }}>
            <FieldWrapper>
                <Controller
                    control={control}
                    name="contactEmail"
                    render={({ field, ref }) => (
                        <Switch
                            {...field}
                            ref={ref}
                            checked={field.value}
                            handleChange={event => {
                                setValue('contactEmail', event.target.checked, { shouldDirty: true });
                                if (!event.target.checked) {
                                    setValue('contactEmailRequired', false, { shouldDirty: true });
                                }
                            }}
                            label={t('FeedbackSurveys.contactEmail')}
                        />
                    )}
                />
                {contactEmail ? (
                    <PlatformWrapper>
                        <Controller
                            control={control}
                            name="contactEmailRequired"
                            render={({ field, ref }) => (
                                <Checkbox
                                    ref={ref}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={e => {
                                        setValue('contactEmailRequired', e.target.checked, {
                                            shouldDirty: true,
                                        });
                                    }}
                                    checked={field.value}
                                />
                            )}
                        />
                        <Typography variant="body2">{t('common.required')}</Typography>
                    </PlatformWrapper>
                ) : (
                    <div />
                )}
            </FieldWrapper>
            <FieldWrapper>
                <Controller
                    control={control}
                    name="contactPhone"
                    render={({ field, ref }) => (
                        <Switch
                            {...field}
                            ref={ref}
                            checked={field.value}
                            handleChange={event => {
                                setValue('contactPhone', event.target.checked, { shouldDirty: true });
                                if (!event.target.checked) {
                                    setValue('contactPhoneRequired', false, { shouldDirty: true });
                                }
                            }}
                            label={t('FeedbackSurveys.contactPhone')}
                        />
                    )}
                />
                {contactPhone ? (
                    <PlatformWrapper>
                        <Controller
                            control={control}
                            name="contactPhoneRequired"
                            render={({ field, ref }) => (
                                <Checkbox
                                    ref={ref}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={e => {
                                        setValue('contactPhoneRequired', e.target.checked, {
                                            shouldDirty: true,
                                        });
                                    }}
                                    checked={field.value}
                                />
                            )}
                        />
                        <Typography variant="body2">{t('common.required')}</Typography>
                    </PlatformWrapper>
                ) : (
                    <div />
                )}
            </FieldWrapper>
            <FieldWrapper style={{ marginBottom: 16 }}>
                <Controller
                    control={control}
                    name="contactName"
                    render={({ field, ref }) => (
                        <Switch
                            {...field}
                            ref={ref}
                            checked={field.value}
                            handleChange={event => {
                                setValue('contactName', event.target.checked, { shouldDirty: true });
                                if (!event.target.checked) {
                                    setValue('contactNameRequired', false, { shouldDirty: true });
                                }
                            }}
                            label={t('FeedbackSurveys.contactName')}
                        />
                    )}
                />
                {contactName ? (
                    <PlatformWrapper>
                        <Controller
                            control={control}
                            name="contactNameRequired"
                            render={({ field, ref }) => (
                                <Checkbox
                                    ref={ref}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={e => {
                                        setValue('contactNameRequired', e.target.checked, {
                                            shouldDirty: true,
                                        });
                                    }}
                                    checked={field.value}
                                />
                            )}
                        />
                        <Typography variant="body2">{t('common.required')}</Typography>
                    </PlatformWrapper>
                ) : (
                    <div />
                )}
            </FieldWrapper>
            <Controller
                control={control}
                name="labels"
                render={({ field, ref }) => (
                    <MultiSelectLabels
                        {...field}
                        ref={ref}
                        onChange={({ target: { value } }) => {
                            setValue('labels', getMultiselectSelectedOptions(labelsOptions, value), {
                                shouldDirty: true,
                            });
                        }}
                        options={labelsOptions}
                        label={t('common.labels')}
                        fullWidth
                        type={LABEL_TYPE.CONTACT}
                        onCreateCallback={newLabel => {
                            const values = getValues();
                            setValue('labels', [...values.labels, newLabel], {
                                shouldDirty: true,
                            });
                        }}
                    />
                )}
            />
        </div>
    );
};

export default CollectInformationBlock;
