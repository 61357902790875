import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@components/Drawer';
import { accountActions, alertActions, userActions } from '@actions';
import { companyService } from '@services';
import { useTranslation } from '@hooks/useTranslation';
import LocationAutocomplete from '@features/LocationAutocomplete';
import GoogleMap from '@components/GoogleMap';

const AddGoogleCompany = ({ isOpen, onClose, setSelectedCompany, changeView }) => {
    const { t } = useTranslation();

    const [placeId, setPlaceId] = useState('');
    const [mapInited, setMapInited] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // Necessary to use LocationAutocomplete
    const mapRef = useRef();

    const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();

    const onClearPlace = () => setPlaceId('');

    const handleSelect = option => {
        const { place_id: placeId } = option;
        if (placeId) {
            setPlaceId(placeId);
        }
    };

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            const newCompany = await companyService.addCompany(placeId);
            await dispatch(accountActions.get());
            await dispatch(userActions.getUser(user?.id));
            setSelectedCompany(newCompany);
            onClose();
            changeView();
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
            setIsSubmitting(false);
        }
    };

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            title={t('SettingsCompanies.addLocationTitle')}
            subtitle={t('SettingsCompanies.addLocationDescription')}
            primaryText={t('buttons.addLocation')}
            primaryDisabled={!placeId || isSubmitting}
            primaryAction={onSubmit}
            secondaryText={t('buttons.cancel')}
        >
            {mapInited && (
                <LocationAutocomplete
                    label={t('SettingsCompanies.googleAutocomplete')}
                    onSelectOption={handleSelect}
                    autocompleteRequestOptions={{
                        fields: ['address_components', 'geometry', 'icon', 'name'],
                        types: ['establishment'],
                        strictBounds: true,
                    }}
                    onClearPlace={onClearPlace}
                />
            )}
            <GoogleMap
                id="locations-map"
                options={{ center: { lat: -33.8688, lng: 151.2195 }, zoom: 17 }}
                onMapLoad={map => {
                    mapRef.current = map;
                    setMapInited(true);
                }}
                style={{ display: 'none' }}
            />
        </Drawer>
    );
};

export default AddGoogleCompany;
