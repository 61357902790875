import { format } from 'date-fns';
import { Typography } from '@components/Typography';
import Tooltip from '@components/Tooltip';
import ResponseItem from '@components/ResponseItem';
import { FiltersIntegrationType, FiltersIntegrationName, AccIntegrationName } from '@constants/integrations';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { useTranslation } from '@hooks/useTranslation';
import {
    GoogleIcon,
    FacebookIcon,
    TrustpilotIcon,
    YelpIcon,
    TripAdvisorIcon,
    BookingIcon,
    IFoodIcon,
} from 'assets/images/integrations';
import Reply from './Reply';
import AssignContact from './AssignContact';
import { Keyword, KeywordsContainer, ReplyConnection, IconWrapper, FooterWrapper } from './styles';

const FeedbackBody = ({ item, language, isMobile, selectedCompanies }) => {
    const { t } = useTranslation();
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    position: 'relative',
                    flexDirection: 'column',
                    maxWidth: 600,
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        whiteSpace: 'pre-line',
                        marginLeft: '48px',
                        marginTop: isMobile && '8px',
                        ...(!item?.text && { color: 'rgba(0, 0, 0, 0.38)' }),
                        '@media (max-width: 1024px)': {
                            marginLeft: 0,
                        },
                    }}
                >
                    {item?.text || t('ReviewsManage.onlyRating')}
                </Typography>
                {!isMobile && item?.ownerAnswer !== null && <ReplyConnection />}
            </div>
            <Reply language={language} review={item} isMobile={isMobile} selectedCompanies={selectedCompanies} />
        </>
    );
};

const InfoCaption = ({ date, locations, integrationName }) => {
    const { t } = useTranslation();
    const getIntegrationName = integrationType => {
        switch (integrationType) {
            case AccIntegrationName.Facebook:
                return FiltersIntegrationType[FiltersIntegrationName.Facebook];
            case AccIntegrationName.Google:
                return FiltersIntegrationType[FiltersIntegrationName.Google];
            default:
                return FiltersIntegrationType[integrationType];
        }
    };
    return (
        <span>
            {t('ReviewsManage.viaOn', { integration: getIntegrationName(integrationName), date })}
            {' - '}
            {locations.length === 1 ? (
                locations[0]
            ) : (
                <Tooltip title={locations.join(', ')} placement="top" enterTouchDelay={0}>
                    {t('ReviewsManage.locationsCount', { count: locations.length })}
                </Tooltip>
            )}
        </span>
    );
};

const IntegrationLabel = ({ link, integrationName }) => {
    const { t } = useTranslation();

    const getIntegrationIcon = integrationType => {
        switch (integrationType) {
            case FiltersIntegrationName.Yelp:
                return <YelpIcon />;
            case FiltersIntegrationName.Facebook:
            case AccIntegrationName.Facebook:
                return <FacebookIcon />;
            case FiltersIntegrationName.Google:
            case AccIntegrationName.Google:
                return <GoogleIcon />;
            case FiltersIntegrationName.Trustpilot:
                return <TrustpilotIcon />;
            case FiltersIntegrationName.TripAdvisor:
                return <TripAdvisorIcon />;
            case FiltersIntegrationName['Booking.com']:
                return <BookingIcon />;
            case FiltersIntegrationName.iFood:
                return <IFoodIcon />;
            default:
                return null;
        }
    };

    const getIntegrationName = integrationType => {
        switch (integrationType) {
            case AccIntegrationName.Facebook:
                return FiltersIntegrationType[FiltersIntegrationName.Facebook];
            case AccIntegrationName.Google:
                return FiltersIntegrationType[FiltersIntegrationName.Google];
            default:
                return FiltersIntegrationType[integrationType];
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {link && (
                <a
                    href={link}
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ textDecorationColor: '#1F4C5C', lineHeight: '14px' }}
                >
                    <Typography variant="caption" sx={{ marginRight: '18px', color: '#1F4C5C' }}>
                        {t('ReviewsManage.viewOn', {
                            integration: getIntegrationName(integrationName),
                        })}
                    </Typography>
                </a>
            )}
            <IconWrapper>{getIntegrationIcon(integrationName)}</IconWrapper>
        </div>
    );
};

const ReviewItem = ({ item, index, language, isMobile, selectedCompanies, onClickContactDetails }) => {
    const { t } = useTranslation();

    const date = item?.updatedAt
        ? format(new Date(item?.updatedAt), 'dd MMM yyyy', { locale: I18N_DATE_FORMAT[language] })
        : t('common.noData');

    return (
        <ResponseItem
            key={item?.id || `feedback-${index}`}
            index={index}
            contactName={item?.userName}
            viewed={item?.viewed}
            rating={item?.rating}
            userProfileImg={item?.userPhotoUrl}
            infoCaption={
                <InfoCaption
                    date={date}
                    locations={item?.locations || t('common.noData')}
                    integrationName={item?.integrationName}
                />
            }
            body={
                <FeedbackBody
                    item={item}
                    language={language}
                    isMobile={isMobile}
                    selectedCompanies={selectedCompanies}
                />
            }
            footer={
                <FooterWrapper hasKeywords={!!item?.aspects?.length}>
                    {!!item?.aspects?.length && (
                        <KeywordsContainer>
                            {item?.aspects?.map((a, index) => (
                                <Keyword key={index} variant="caption" type={a.sentiment}>
                                    {a.aspect}
                                </Keyword>
                            ))}
                        </KeywordsContainer>
                    )}
                    <AssignContact
                        contactId={item?.contactId}
                        contactName={item?.contactName}
                        reviewId={item?.reviewId}
                        contactPhoneNumber={item?.contactPhoneNumber}
                        contactEmail={item?.contactEmail}
                        onClickContactDetails={onClickContactDetails}
                    />
                </FooterWrapper>
            }
            footerStyles={{ justifyContent: 'flex-start' }}
            integrationLabel={<IntegrationLabel link={item?.link} integrationName={item.integrationName} />}
        />
    );
};

ReviewItem.displayName = 'ReviewItem';

export default ReviewItem;
