import { phone as phoneValidator } from 'phone';
import { regexEmail } from '@constants/regex';
import { LOCALE, LOCALE_KEY } from '@constants/locale';

const PHONE_LOCALE_MAP = {
    [LOCALE.UNITED_KINGDOM]: LOCALE_KEY.GB,
    [LOCALE.UKRAINE]: LOCALE_KEY.UA,
    [LOCALE.INTERNATIONAL]: LOCALE_KEY.International,
};

const checkUniqueContacts = (list, email_field, phone_field) => {
    const uniqueMap = {};
    const uniqueList = [];
    for (const contact of list) {
        if (!uniqueMap[contact[phone_field]]) {
            if (contact[phone_field]) uniqueMap[contact[phone_field]] = true;
            uniqueList.push(contact);
            continue;
        }
    }
    return uniqueList;
};

const checkEmails = (contact, email_field) => {
    const isEmailValid = regexEmail.test(contact[email_field]);
    if ((!contact.emailValid && isEmailValid) || !contact[email_field]) {
        contact.emailValid = true;
    } else {
        contact.emailValid = false;
    }
};

const checkPhoneNumbers = (contact, locale, phone_field) => {
    if (!contact[phone_field]) {
        contact.phoneValid = false;
        return;
    }

    if (!`${contact[phone_field]}`.startsWith('+') && !`${contact[phone_field]}`.startsWith('0')) {
        contact[phone_field] = `+${contact[phone_field]}`;
    }
    if (`${contact[phone_field]}`.startsWith('0') && PHONE_LOCALE_MAP[locale] === LOCALE_KEY.UA) {
        contact[phone_field] = `+38${contact[phone_field]}`;
    }
    if (`${contact[phone_field]}`.startsWith('0') && PHONE_LOCALE_MAP[locale] === LOCALE_KEY.GB) {
        contact[phone_field] = '+44' + contact[phone_field].substring(1);
    }

    const response = phoneValidator(contact[phone_field], {
        country: null,
        validateMobilePrefix: false,
    });

    if (response.isValid) {
        contact[phone_field] = response.phoneNumber;
        contact.phoneValid = true;
    }
};

const contactsModule = {
    checkUniqueContacts,
    checkEmails,
    checkPhoneNumbers,
};

export default contactsModule;
