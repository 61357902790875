import { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isBefore, format } from 'date-fns';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ControlledToaster } from '@components/Toaster';
import { useTranslation } from '@hooks/useTranslation';
import { Trans } from 'react-i18next';
import { reviewsService } from '@services';
import { useQueryParams } from '@hooks/useQueryParams';
import {
    reviewActions,
    replyTemplatesActions,
    keywordsActions,
    accountActions,
    contactsActions,
    labelsActions,
    channelsActions,
} from '@actions';
import ContactInfo from '@pages/ContactsPage/ContactInfo';
import ExportCSV from '@features/ExportCSV';
import { FiltersIntegrationType } from '@constants/integrations';
import { LABEL_TYPE } from '@constants/labels';
import { MODAL_TYPE, CONTACT_MODAL_TYPE } from '@constants/modal';
import { getKeywordsOptions } from '@helpers/keywords';
import { getLocationLabels } from '@helpers/locationLabels';
import FiltersPanel from './FiltersPanel';
import Reviews from './Reviews';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: '16px 16px 0',
    },
});

const DEFAULT_START = 0;
const DEFAULT_SKIP = 50;
const DEFAULT_LOAD_MORE = 20;
const MINUTE = 1000 * 60;

const ReviewsPage = () => {
    const { t } = useTranslation();
    const params = useQueryParams();
    const history = useHistory();
    const { companies } = useSelector(state => state.account?.account);
    const { location: labels, locationLoading: labelsLoading } = useSelector(state => state.labels);
    const { reviews: reviewsList = [], hasMore = true, loading, newReviewsExist } = useSelector(state => state.reviews);
    const { dataAll: keywords, loading: keywordsLoading } = useSelector(state => state.keywords);
    const language = useSelector(state => state.authentication.user?.language);

    const queryParamsReviewId = params.get('reviewId');

    const [filter, setFilter] = useState({
        labels: [],
        companies: [],
        integrations: params.get('integrationNames') ? JSON.parse(params.get('integrationNames')) : [],
        timeframe: { start: null, end: null },
        rating: [1, 2, 3, 4, 5],
        keywords: params.get('keyword') ? [params.get('keyword')] : [],
        withoutReplies: params.get('withoutReplies') ? Boolean(params.get('withoutReplies')) : false,
        ignoreKeywords: params.get('keyword') ? false : true,
        reviewId: queryParamsReviewId ? queryParamsReviewId : undefined,
    });

    const [openAlert, setOpenAlert] = useState(false);
    const [filterNumber, setFilterNumber] = useState(0);
    const [filterOpened, setFiltersOpened] = useState(false);
    const [contactDrawerOpened, setContactDrawerOpened] = useState(false);
    const [deleteModal, setDeleteModal] = useState({ open: false, type: CONTACT_MODAL_TYPE.DIRECT });
    const [editModal, setEditModal] = useState({ open: false, type: MODAL_TYPE.EDIT });
    const [sendMessageModal, setSendMessageModal] = useState({ open: false, type: CONTACT_MODAL_TYPE.DIRECT });

    const firstRender = useRef(true);
    const paginationStart = useRef(DEFAULT_START);

    const isMobile = useMediaQuery('@media (max-width: 1024px)');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(keywordsActions.getAll());
        dispatch(replyTemplatesActions.getAll());
        dispatch(labelsActions.get(LABEL_TYPE.CONTACT));
        dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
        dispatch(channelsActions.get());
    }, [dispatch]);

    const availableCompanies = useMemo(
        () =>
            companies.filter(
                company =>
                    company?.profiles?.length > 0 && company?.profiles.some(p => p.type in FiltersIntegrationType),
            ),
        [companies],
    );

    const availableLabels = useMemo(
        () => getLocationLabels(labels, availableCompanies, t),
        [labels, availableCompanies],
    );

    useEffect(() => {
        if (companies.length && !keywordsLoading && !labelsLoading) {
            const integrationNames = [
                ...new Set(
                    companies
                        .map(company => company.profiles)
                        .flat()
                        .filter(p => p.type in FiltersIntegrationType)
                        .map(p => p.type),
                ),
            ];
            const companiesIds = availableCompanies.map(item => item.companyId);
            setFilter(prev => ({
                ...prev,
                integrations: prev.integrations.length ? prev.integrations : integrationNames,
                companies: companiesIds,
                keywords: params.get('keyword')
                    ? [params.get('keyword')]
                    : getKeywordsOptions(keywords, companiesIds).map(kw => kw.value),
                labels: availableLabels.map(item => item.id),
            }));
            const queryParamsFilter = [
                params.get('keyword'),
                params.get('withoutReplies'),
                params.get('integrationNames'),
            ];
            setFilterNumber(queryParamsFilter.filter(Boolean).length);
        } else if (!companies.length && !keywordsLoading) {
            dispatch(reviewActions.resetLoading());
        }
    }, [companies, keywordsLoading, labelsLoading, keywords, dispatch]);

    const currentQuery = useMemo(() => {
        return {
            ...filter,
            integrationNames: filter.integrations,
            start: DEFAULT_START,
            end: DEFAULT_SKIP,
            startDate: filter.timeframe.start ? format(new Date(filter.timeframe.start), 'dd/MMM/yyyy') : null,
            endDate: filter.timeframe.end ? format(new Date(filter.timeframe.end), 'dd/MMM/yyyy') : null,
        };
    }, [filter]);

    useEffect(() => {
        if (queryParamsReviewId && !firstRender.current) {
            dispatch(reviewActions.getAll({ ...currentQuery, reviewId: queryParamsReviewId }));
            history.replace();
        }
    }, [queryParamsReviewId]);

    useEffect(() => {
        if (currentQuery.companies.length && currentQuery.integrationNames.length && currentQuery.rating.length) {
            paginationStart.current = 0;
            if (firstRender.current) {
                dispatch(reviewActions.getAll(currentQuery));
                firstRender.current = false;
            }
        }
    }, [currentQuery, dispatch]);

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const newExists = await reviewsService.checkNewExist({ ...currentQuery, reviewId: undefined });
                dispatch(reviewActions.checkNewExistSuccess(newExists));
            } catch (_) {
                console.error('Failed to check new reviews');
            }
        }, MINUTE);
        return () => clearInterval(interval);
    }, [currentQuery, dispatch]);

    useEffect(() => {
        const feedType = getFeedType();
        if (newReviewsExist && feedType === 2) {
            getNewReviews();
            dispatch(accountActions.get());
        } else if (newReviewsExist) {
            setOpenAlert(true);
        }
    }, [newReviewsExist]);

    const getNewReviews = () => {
        setOpenAlert(false);
        dispatch(reviewActions.resetNewExist());
        dispatch(reviewActions.getAll({ ...currentQuery, reviewId: undefined }));
    };

    const onCloseToaster = () => dispatch(reviewActions.resetNewExist());

    const loadMore = () => {
        if (!hasMore) return;
        const newPaginationStart =
            paginationStart.current === 0 ? DEFAULT_SKIP : paginationStart.current + DEFAULT_LOAD_MORE;
        paginationStart.current = newPaginationStart;
        dispatch(reviewActions.loadMore({ ...currentQuery, start: newPaginationStart, end: DEFAULT_LOAD_MORE }));
    };

    const onApplyFilters = () => {
        paginationStart.current = 0;
        dispatch(reviewActions.getAll({ ...currentQuery, reviewId: undefined }));
    };

    const getFeedType = () => {
        if (!filter.companies.length || !filter.integrations.length || !filter.rating.length) {
            return 1;
        }
        const isScraping = companies
            .filter(c => filter.companies.includes(c.companyId))
            .map(item => item.profiles)
            .flat()
            .filter(c => filter.integrations.includes(c.type))
            .every(c => isBefore(new Date(c.lastScrapedTime), new Date(2000, 1, 1)));
        if (isScraping && !reviewsList.length && !firstRender.current) {
            return 2;
        }
        if (!reviewsList.length && !firstRender.current) {
            return 3;
        }
        return 0;
    };

    const onClickContactDetails = contactId => {
        dispatch(contactsActions.getContact(contactId));
        setContactDrawerOpened(true);
    };

    const onCloseContactDrawer = () => setContactDrawerOpened(false);

    const handleContactActions = (_, type) => {
        switch (type) {
            case 'delete':
                setDeleteModal({ open: true, type: CONTACT_MODAL_TYPE.DIRECT });
                break;
            case 'edit':
                setEditModal({ open: true, type: MODAL_TYPE.EDIT });
                break;
            case 'sendMessage':
                setSendMessageModal({ open: true, type: CONTACT_MODAL_TYPE.DIRECT });
                break;
            default:
                break;
        }
    };

    const refetchCallBack = () => {
        dispatch(reviewActions.getAll(currentQuery));
    };

    return (
        <Container>
            <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
                <FiltersPanel
                    setFiltersOpened={setFiltersOpened}
                    filtersNumber={filterNumber}
                    filterOpened={filterOpened}
                    companies={availableCompanies}
                    labels={availableLabels}
                    keywords={keywords}
                    filter={filter}
                    setFilter={setFilter}
                    type="reviews"
                    setFilterNumber={setFilterNumber}
                    onApplyFilters={onApplyFilters}
                    paramsKeyword={params.get('keyword')}
                />
                <ExportCSV
                    callback={() => reviewsService.exportCSV({ ...currentQuery, start: 0, end: 0 })}
                    type="reviews"
                />
            </div>
            <Reviews
                isMobile={isMobile}
                data={reviewsList}
                language={language}
                hasMore={hasMore}
                loading={loading}
                fetchData={loadMore}
                selectedCompanies={filter.companies || []}
                feedType={getFeedType()}
                onClickContactDetails={onClickContactDetails}
            />
            <ControlledToaster
                callback={getNewReviews}
                autoHideDuration={null}
                text={
                    <Trans t={t} i18nKey={'ReviewsManage.newReviews'}>
                        <b style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={getNewReviews}>
                            {{ update: t('ReviewsManage.update') }}
                        </b>
                    </Trans>
                }
                type="info"
                open={openAlert}
                setOpen={setOpenAlert}
                clickClose={onCloseToaster}
            />
            <ContactInfo
                onClickContact={handleContactActions}
                editModal={editModal}
                setEditModal={setEditModal}
                sendMessageModal={sendMessageModal}
                setSendMessageModal={setSendMessageModal}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                contactDrawerOpened={contactDrawerOpened}
                onCloseContactDrawer={onCloseContactDrawer}
                withRefetchReviews
                refetchCallBack={refetchCallBack}
            />
        </Container>
    );
};

export default ReviewsPage;
