import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@components/Button';
import { InternalLink } from '@components/Link';
import { useTranslation } from '@hooks/useTranslation';
import { Header, Logo, Title, Text, Container } from './styles';

const ErrorPage = () => {
    const { t } = useTranslation();
    const isAuthenticated = useSelector(state => state.authentication.user);

    const onClickSupport = () => {
        window.open('https://share.hsforms.com/1VAYqLOrmS7K3bD-dCdxdoA5jjzz', '_blank');
    };

    return (
        <Container>
            <Header>
                <Logo />
                <InternalLink
                    to={isAuthenticated ? '/' : '/login'}
                    variant="body2"
                    underline="none"
                    sx={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'absolute',
                        top: '56px',
                    }}
                >
                    <ArrowBackIcon sx={{ marginTop: '1px', marginRight: '15px', fontSize: '20px' }} />
                    {isAuthenticated ? t('ErrorsPages.backToReviews') : t('ErrorsPages.backHome')}
                </InternalLink>
            </Header>

            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Title>{t('ErrorsPages.titleSomethingWrong')}</Title>
                <div style={{ display: 'block', textAlign: 'center' }}>
                    <Text>{t('ErrorsPages.textSomethingWrong')}</Text>
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{
                            width: '420px',
                            '@media only screen and (max-width: 600px)': {
                                width: 'auto',
                            },
                        }}
                        onClick={onClickSupport}
                    >
                        {t('buttons.contact')}
                    </Button>
                </div>
            </div>
        </Container>
    );
};

export default ErrorPage;
