import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import equal from 'fast-deep-equal';
import uniqBy from 'lodash.uniqby';
import { countriesActions, brandsActions, accountActions } from '@actions';
import { Completeness } from '@constants/companies';
import CompaniesTable from './CompaniesTable';
import EditForm from './EditForm';

const VIEW = {
    LIST: 0,
    FORM: 1,
};

const Companies = () => {
    const [view, setView] = useState(VIEW.LIST);
    const [selectedCompany, setSelectedCompany] = useState({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filter, setFilter] = useState({
        brands: [],
        completenessLevels: [Completeness.Low, Completeness.Medium, Completeness.High],
    });

    const account = useSelector(state => state.account?.account);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(brandsActions.getAll());
        dispatch(countriesActions.get());
    }, []);

    useEffect(() => {
        const availableBrandsIds = uniqBy(account?.companies, 'brand.id')
            .filter(item => item.brand)
            .map(item => item.brand.id);
        setFilter(prev => ({ ...prev, brands: [...availableBrandsIds, null] }));
        dispatch(
            accountActions.getLocations(
                {
                    brands: [...availableBrandsIds, null],
                    completenessLevels: [Completeness.Low, Completeness.Medium, Completeness.High],
                },
                page,
                rowsPerPage,
            ),
        );
    }, [account]);

    useEffect(() => {
        const accountCompany = account?.companies?.find(c => c.companyId === selectedCompany?.companyId);
        const companiesEqual = equal(selectedCompany, accountCompany);
        // when the company was updated, update form as well
        if (selectedCompany?.companyId && accountCompany && !companiesEqual) {
            setSelectedCompany(accountCompany);
        }
    }, [selectedCompany, account]);

    return view === VIEW.LIST ? (
        <CompaniesTable
            account={account}
            view={VIEW}
            setView={setView}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            filter={filter}
            setFilter={setFilter}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
        />
    ) : (
        <EditForm
            filter={filter}
            page={page}
            rowsPerPage={rowsPerPage}
            account={account}
            view={VIEW}
            setView={setView}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
        />
    );
};

export default Companies;
