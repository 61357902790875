import Paper from '@mui/material/Paper';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { formatNumber, formatPercentNumber } from '@helpers/formatNumbers';

const getDeltaResult = (field, withInvertion) => {
    if (field < 0) {
        return !withInvertion
            ? { color: '#B00020', icon: <ArrowDownwardIcon sx={{ marginRight: '11px', fontSize: '20px' }} /> }
            : { color: '#0B8D00', icon: <ArrowUpwardIcon sx={{ marginRight: '11px', fontSize: '20px' }} /> };
    } else if (field > 0) {
        return !withInvertion
            ? { color: '#0B8D00', icon: <ArrowUpwardIcon sx={{ marginRight: '11px', fontSize: '20px' }} /> }
            : { color: '#B00020', icon: <ArrowDownwardIcon sx={{ marginRight: '11px', fontSize: '20px' }} /> };
    }
    return { color: '#0B8D00', icon: null };
};

const MetricItem = ({ data, title, styles, withPercentage, withPercentageDelta, withInvertion }) => {
    const { t } = useTranslation();
    const delta = parseFloat(data.delta);
    const { color, icon } = getDeltaResult(delta, withInvertion);
    return (
        <Paper
            elevation={1}
            sx={{
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '4px',
                ...styles,
            }}
        >
            <Typography variant="title1" color="textPrimary" align="center" sx={{ alignSelf: 'center' }}>
                {title}
            </Typography>
            <Typography variant="h2" color="primary" sx={{ alignSelf: 'center', color: '#1F4C5C' }}>
                {withPercentage ? formatPercentNumber(data.metric) : formatNumber(data.metric, 'compact')}
            </Typography>
            <Typography
                variant="caption"
                sx={{ alignSelf: 'center', color, display: 'flex', alignItems: 'flex-end', height: '28px' }}
            >
                {icon}
                {delta !== 0 &&
                    `${delta > 0 ? '+' : ''}${
                        withPercentageDelta ? formatPercentNumber(data.delta) : formatNumber(data.delta)
                    } ${t('AnalyticsConversion.fromPreviousPeriod')}`}
            </Typography>
        </Paper>
    );
};

export default MetricItem;
