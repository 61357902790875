import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/system';
import Button from '@components/Button';
import Tooltip from '@components/Tooltip';
import { pricingDrawerUiActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';
import { ReactComponent as LockIcon } from 'assets/images/icons/lock.svg';

const Icon = styled(LockIcon)(({ variant }) => ({
    height: 14,
    transform: 'translateY(-1px)',
    marginRight: 4,
    ...(variant === 'contained' && {
        '& path': {
            fill: '#fff',
        },
    }),
    ...(variant === 'outlined' && {
        '& path': {
            fill: '#1F4C5C',
        },
    }),
    ...(variant === 'text' && {
        '& path': {
            fill: '#1F4C5C',
        },
    }),
}));

const LockedButton = ({ children, variant, className, drawerType, tooltipType, addonRight = null }) => {
    const user = useSelector(state => state.authentication.user);
    const isAccountOwner = user?.isAccountOwner || user?.isGlobalAdmin;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleOpen = () => dispatch(pricingDrawerUiActions.setOpen({ open: true, type: drawerType }));

    return (
        <Tooltip placement="top" title={tooltipType === 1 ? t('common.upgradeToAdd') : t('common.upgradeToUnlock')}>
            <span>
                <Button className={className} variant={variant} onClick={handleOpen} disabled={!isAccountOwner}>
                    <Icon variant={variant} />
                    {children}
                </Button>
                {addonRight}
            </span>
        </Tooltip>
    );
};

export default LockedButton;
