import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import MenuList from '@components/MenuList';
import { useTranslation } from '@hooks/useTranslation';
import { aiAssistantService } from '@services';
import { alertActions } from '@actions';
import { AI_TASK_TYPE, AITaskType } from '@constants/ai-assistant';
import { MagicWandIcon } from 'assets/images/icons';
import {
    InputBlurWrapper,
    StyledImproveIcon,
    StyledTranslateIcon,
    StyledMakeShorterIcon,
    StyledMakeLongerIcon,
    StyledMicrophoneIcon,
    StyledPanIcon,
} from './styles';

export const AIAssistantLoadingWrapper = ({ styles }) => (
    <InputBlurWrapper style={styles}>
        <CircularProgress />
    </InputBlurWrapper>
);

const AIAssistant = ({ value, setValue, btnStyles = {}, disabled, setLoading, additionalOptions = [] }) => {
    const isMobile = useMediaQuery('@media (max-width: 600px)');

    const [anchorElMain, setAnchorElMain] = useState(null);
    const [anchorElTranslation, setAnchorElTranslation] = useState(null);
    const [anchorElChangeTone, setAnchorElChangeTone] = useState(null);
    const [openModalMenu, setOpenModalMenu] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleOpenMain = event => {
        if (isMobile) {
            setOpenModalMenu(true);
        } else {
            setAnchorElMain(event.currentTarget);
            setAnchorElTranslation(null);
            setAnchorElChangeTone(null);
        }
    };
    const handleOpenTranslation = event => {
        setAnchorElChangeTone(null);
        setAnchorElTranslation(event.currentTarget);
    };
    const handleOpenChangeTone = event => {
        setAnchorElTranslation(null);
        setAnchorElChangeTone(event.currentTarget);
    };

    const onClickOption = async ({ taskType, type, companyId, conversationId, reviewId }) => {
        const callback =
            taskType === AI_TASK_TYPE.STANDARD
                ? aiAssistantService.getStandartTask
                : aiAssistantService.getContextualTask;
        setLoading(true);
        setAnchorElMain(null);
        try {
            const response = await callback({ input: value, type, companyId, conversationId, reviewId });
            setValue(response?.result || '');
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
        setLoading(false);
    };

    const ADDITIONAL_MENU = additionalOptions.map(
        ({ Icon, text, type, taskType, companyId = null, conversationId = null, reviewId = null }) => ({
            icon: Icon ? <Icon /> : <StyledImproveIcon />,
            text: text,
            sub: {},
            onClick: () => onClickOption({ taskType, type, companyId, conversationId, reviewId }),
        }),
    );

    const MAIN_MENU = [
        {
            icon: <StyledTranslateIcon />,
            text: t('AIAssistant.translate'),
            sub: {
                header: t('AIAssistant.translate'),
                list: [
                    {
                        text: t('AIAssistant.english'),
                        onClick: () =>
                            onClickOption({ taskType: AI_TASK_TYPE.STANDARD, type: AITaskType.TranslateToEnglish }),
                    },
                    {
                        text: t('AIAssistant.spanish'),
                        onClick: () =>
                            onClickOption({ taskType: AI_TASK_TYPE.STANDARD, type: AITaskType.TranslateToSpanish }),
                    },
                    {
                        text: t('AIAssistant.portuguese'),
                        onClick: () =>
                            onClickOption({ taskType: AI_TASK_TYPE.STANDARD, type: AITaskType.TranslateToPortuguese }),
                    },
                    {
                        text: t('AIAssistant.french'),
                        onClick: () =>
                            onClickOption({ taskType: AI_TASK_TYPE.STANDARD, type: AITaskType.TranslateToFrench }),
                    },
                    {
                        text: t('AIAssistant.german'),
                        onClick: () =>
                            onClickOption({ taskType: AI_TASK_TYPE.STANDARD, type: AITaskType.TranslateToGerman }),
                    },
                    {
                        text: t('AIAssistant.italian'),
                        onClick: () =>
                            onClickOption({ taskType: AI_TASK_TYPE.STANDARD, type: AITaskType.TranslateToItalian }),
                    },
                    {
                        text: t('AIAssistant.russian'),
                        onClick: () =>
                            onClickOption({ taskType: AI_TASK_TYPE.STANDARD, type: AITaskType.TranslateToRussian }),
                    },
                    {
                        text: t('AIAssistant.ukrainian'),
                        onClick: () =>
                            onClickOption({ taskType: AI_TASK_TYPE.STANDARD, type: AITaskType.TranslateToUkrainian }),
                    },
                    {
                        text: t('AIAssistant.polish'),
                        onClick: () =>
                            onClickOption({ taskType: AI_TASK_TYPE.STANDARD, type: AITaskType.TranslateToPolish }),
                    },
                ],
            },
            anchorEl: anchorElTranslation,
            onClick: handleOpenTranslation,
        },
        {
            icon: <StyledMicrophoneIcon />,
            text: t('AIAssistant.changeTone'),
            sub: {
                header: t('AIAssistant.changeTone'),
                list: [
                    {
                        text: t('AIAssistant.professional'),
                        onClick: () =>
                            onClickOption({
                                taskType: AI_TASK_TYPE.STANDARD,
                                type: AITaskType.ChangeToneToProfessional,
                            }),
                    },
                    {
                        text: t('AIAssistant.friendly'),
                        onClick: () =>
                            onClickOption({ taskType: AI_TASK_TYPE.STANDARD, type: AITaskType.ChangeToneToFriendly }),
                    },
                ],
            },
            anchorEl: anchorElChangeTone,
            onClick: handleOpenChangeTone,
        },
        {
            icon: <StyledPanIcon />,
            text: t('AIAssistant.fixGrammar'),
            sub: {},
            onClick: () => onClickOption({ taskType: AI_TASK_TYPE.STANDARD, type: AITaskType.FixSpellingAndGrammar }),
        },
        {
            icon: <StyledMakeShorterIcon />,
            text: t('AIAssistant.makeShoter'),
            sub: {},
            onClick: () => onClickOption({ taskType: AI_TASK_TYPE.STANDARD, type: AITaskType.MakeShorter }),
        },
        {
            icon: <StyledMakeLongerIcon />,
            text: t('AIAssistant.makeLonger'),
            sub: {},
            onClick: () => onClickOption({ taskType: AI_TASK_TYPE.STANDARD, type: AITaskType.MakeLonger }),
        },
        ...ADDITIONAL_MENU,
    ];

    return (
        <>
            <MenuList
                options={MAIN_MENU}
                anchorEl={anchorElMain}
                onCloseMenu={() => setAnchorElMain(null)}
                openModal={openModalMenu}
                handleCloseModal={() => setOpenModalMenu(false)}
                mainTitle="Pluspoint AI"
                menuButton={
                    <IconButton
                        className="pl-ai-assistant-btn"
                        size="small"
                        color="inherit"
                        onClick={handleOpenMain}
                        disabled={disabled}
                        sx={{ height: 18, width: 18, padding: '2px !important', ...btnStyles }}
                    >
                        <MagicWandIcon />
                    </IconButton>
                }
            />
        </>
    );
};

export default AIAssistant;
