import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { BILLING_TIME_MAP, CURRENCY_MAP } from '@constants/billing';

const LoadingWrapper = styled('div')(() => ({
    '& .MuiCircularProgress-root': {
        color: 'rgba(0, 0, 0, 0.38) !important',
        width: '58px !important',
        height: '58px !important',
        display: 'block !important',
        margin: '0 auto 24px',
    },
}));

const flexStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
};

const UpdateSubscriptionReport = () => {
    const { data, loading } = useSelector(state => state.pricingDrawerUi);
    const { t } = useTranslation();

    return loading ? (
        <LoadingWrapper>
            <CircularProgress />
        </LoadingWrapper>
    ) : (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
            }}
        >
            <div style={flexStyles}>
                <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, .6)' }}>
                    {t('SettingsGeneral.currentPrice')}:
                </Typography>
                <Typography variant="subtitle2">
                    {CURRENCY_MAP[data?.currentCurrency]}
                    {data?.currentPrice}/
                    {t(`SettingsGeneral.${data?.currentProductType === BILLING_TIME_MAP.ANNUAL ? 'year' : 'month'}`)}
                </Typography>
            </div>
            <div style={flexStyles}>
                <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, .6)' }}>
                    {t('SettingsGeneral.newPrice')}:
                </Typography>
                <Typography variant="subtitle2">
                    {CURRENCY_MAP[data?.newCurrency]}
                    {data?.newPrice}/
                    {t(`SettingsGeneral.${data?.newProductType === BILLING_TIME_MAP.ANNUAL ? 'year' : 'month'}`)}
                </Typography>
            </div>
            <div style={flexStyles}>
                <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, .6)' }}>
                    {t('SettingsGeneral.dueToday')}:
                </Typography>
                <Typography variant="subtitle2">
                    {CURRENCY_MAP[data?.amountDueCurrency]}
                    {data?.amountDue}
                </Typography>
            </div>
        </div>
    );
};

export default UpdateSubscriptionReport;
