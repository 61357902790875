import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';
import { Typography } from '@components/Typography';

export const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 'calc(100% - 32px)',
        height: 'calc(var(--100vh) - 32px)',
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

export const Text = styled(Typography, {
    shouldForwardProp: prop => prop !== 'hasDiff',
})(({ hasDiff }) => ({
    color: hasDiff ? '#B00020' : 'rgba(0, 0, 0, 0.87)',
}));

export const CellWithSelect = styled('div')({
    padding: '12px 0',
    textAlign: 'left',
    width: 150,
    '& .MuiFormControl-root': {
        paddingBottom: '0 !important',
    },
});

export const Image = styled('img')({
    width: 'auto',
    height: 74,
    borderRadius: 4,
    objectFit: 'contain',
});
