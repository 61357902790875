import { coverManagerConstants } from '@constants/coverManager';

const initialState = {
    data: [],
    loading: true,
    error: '',
};

export function coverManager(state = initialState, action) {
    switch (action.type) {
        case coverManagerConstants.COVER_MANAGER_RESTAURANTS_REQUEST:
            return { ...state, loading: true, error: '' };
        case coverManagerConstants.COVER_MANAGER_RESTAURANTS_SUCCESS:
            return { ...state, loading: false, data: action.data, error: '' };
        case coverManagerConstants.COVER_MANAGER_RESTAURANTS_ERROR:
            return { ...state, error: action.message };
        default:
            return state;
    }
}
