import { useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { useTranslation } from '@hooks/useTranslation';
import { pricingDrawerUiActions } from '@actions';
import { PRICING_DRAWER_TYPE } from '@constants/pricing-drawer-ui';
import { SurveysIcon } from 'assets/images/icons';

const Container = styled('div')({
    borderRadius: 4,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#fff',
    boxShadow:
        '0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.20)',
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    gap: 16,
    '@media (max-width: 820px)': {
        flexDirection: 'column',
    },
});

const ContentWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '@media (max-width: 820px)': {
        alignItems: 'center',
        textAlign: 'center',
    },
});

const SurveysDisabled = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(pricingDrawerUiActions.setOpen({ open: true, type: PRICING_DRAWER_TYPE.UPGRADE }));
    };

    return (
        <Container>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <SurveysIcon />
            </div>
            <ContentWrapper>
                <Typography variant="subtitle2">{t('HomePage.surveysTitle')}</Typography>
                <Typography variant="caption" color="textSecondary">
                    {t('FeedbackSurveys.subtitle')}
                </Typography>
                <Button variant="contained" sx={{ width: 'fit-content' }} onClick={onClick}>
                    {t('SettingsGeneral.upgradePlan')}
                </Button>
            </ContentWrapper>
        </Container>
    );
};

export default SurveysDisabled;
