import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import sortBy from 'lodash.sortby';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@components/Typography';
import { Table } from '@components/Table';
import { useTranslation } from '@hooks/useTranslation';
import { formatNumber } from '@helpers/formatNumbers';

const Container = styled(Paper)({
    padding: '24px',
    display: 'flex',
    width: '100%',
    borderRadius: 4,
    marginTop: '24px',
});

const TableCellText = styled(Typography)({
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
});

const Text = styled('span', { shouldForwardProp: prop => prop !== 'withIcon' })(({ withIcon }) => ({
    marginRight: '28px',
    ...(withIcon && {
        marginRight: '8px',
    }),
}));

export const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('common.location')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography variant="subtitle2" align="right">
                    {t('AnalyticsReviews.totalReviews')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsReviews.averageRating')}
                </Typography>
            ),
        },
    ];
};

const getTableArrow = field => {
    if (field > 0) {
        return <ArrowUpwardIcon sx={{ color: '#0B8D00', fontSize: '20px' }} />;
    } else if (field < 0) {
        return <ArrowDownwardIcon sx={{ color: '#B00020', fontSize: '20px' }} />;
    }
    return null;
};

export const getTableBody = data => {
    return data.map(({ internalName, totalReviews, averageRatingDelta, totalReviewsDelta, averageRating }) => [
        {
            component: (
                <Typography variant="subtitle2" align="left">
                    {internalName}
                </Typography>
            ),
        },
        {
            component: (
                <TableCellText variant="body2" align="right">
                    <Text withIcon={totalReviewsDelta !== 0}>{formatNumber(totalReviews)}</Text>
                    {getTableArrow(totalReviewsDelta)}
                </TableCellText>
            ),
        },
        {
            component: (
                <TableCellText variant="body2" align="right">
                    <Text withIcon={averageRatingDelta !== 0}>{formatNumber(averageRating)}</Text>
                    {getTableArrow(averageRatingDelta)}
                </TableCellText>
            ),
        },
    ]);
};

const ReviewsTable = memo(({ isLoading, data }) => {
    const companies = useSelector(state => state.account?.account?.companies || []);

    const dataSortedByName = useMemo(() => {
        const dataWithLocationNames = data.map(item => ({
            ...item,
            internalName: companies.find(company => company.companyId === item.companyId)?.internalName || '',
        }));
        return sortBy(dataWithLocationNames, item => item.internalName.toLowerCase());
    }, [data, companies]);

    return (
        <Container elevation={1}>
            <TableContainer>
                <Table columnNames={GetTableHeader()} rows={getTableBody(dataSortedByName)} isDataLoading={isLoading} />
            </TableContainer>
        </Container>
    );
});

ReviewsTable.displayName = 'ReviewsTable';

export default ReviewsTable;
