import { micrositesConstants } from '@constants/microsites';
import { micrositesService } from '@services';

export const micrositesActions = {
    getAll,
};

function getAll() {
    return dispatch => {
        dispatch(request());

        micrositesService.getAll().then(
            microsites => dispatch(success(microsites)),
            error => dispatch(failure(error.toString())),
        );
    };

    function request() {
        return { type: micrositesConstants.MICROSITES_REQUEST };
    }
    function success(microsites) {
        return { type: micrositesConstants.MICROSITES_SUCCESS, microsites };
    }
    function failure(error) {
        return { type: micrositesConstants.MICROSITES_ERROR, error };
    }
}
