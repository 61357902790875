import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';

export const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 564,
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

export const Content = styled('div')({
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateAreas: '"select0 select1" "select2 select2"',
    gridTemplateColumns: 'calc(50% - 12px) calc(50% - 12px)',
    '@media (max-width: 600px)': {
        gridTemplateAreas: '"select0" "select1"  "select2" ',
        gridTemplateColumns: '100%',
        gridGap: '16px',
    },
});
