import { memo, useEffect, useRef } from 'react';
import { Bar, Chart } from 'react-chartjs-2';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';
import { format } from 'date-fns';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@components/Typography';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { handleChartPrint } from '@helpers/analytics';
import { useTranslation } from '@hooks/useTranslation';
import { styled } from '@mui/system';

const PaperStyled = styled(Paper, {
    shouldForwardProp: prop => prop !== 'type',
})(({ type }) => ({
    width: '100%',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    marginBottom: type === 'reviews' ? 0 : '16px',
    marginTop: type === 'feedbacks' ? 0 : '16px',
    '@media print': {
        breakInside: 'avoid',
    },
}));
const ChartWrapper = styled('div')({
    position: 'relative',
    height: '280px',
    '@media (max-width: 1024px)': {
        height: '400px',
        width: '900px',
    },
    '@media print': {
        height: '280px !important',
    },
});

const createChartData = (labels, data, legends) => {
    return {
        labels,
        datasets: [
            {
                type: 'line',
                yAxisID: 'line',
                label: legends[3],
                data: data.map(i => i?.nps),
                backgroundColor: ['#1F4C5C'],
                borderColor: ['#1F4C5C'],
                fill: false,
                borderWidth: 2,
                pointRadius: 6,
                pointHoverRadius: 12,
                pointBackgroundColor: '#BCC9CE',
                pointBorderColor: '#1F4C5C',
                pointBorderWidth: 1,
            },
            {
                type: 'bar',
                yAxisID: 'bar',
                stack: 'stack1',
                label: legends[2],
                data: data.map(i => i?.detractors),
                backgroundColor: ['#FF5853'],
                categoryPercentage: 1.0,
                barPercentage: 0.7,
                borderWidth: 0,
            },
            {
                type: 'bar',
                yAxisID: 'bar',
                stack: 'stack1',
                label: legends[1],
                data: data.map(i => i?.neutrals),
                backgroundColor: ['#FFCE4D'],
                categoryPercentage: 1.0,
                barPercentage: 0.7,
                borderWidth: 0,
            },
            {
                type: 'bar',
                yAxisID: 'bar',
                stack: 'stack1',
                label: legends[0],
                data: data.map(i => i?.promoters),
                backgroundColor: ['#4CC474'],
                categoryPercentage: 1.0,
                barPercentage: 0.7,
                borderWidth: 0,
            },
        ],
    };
};

const createChartOptions = (total, averageRating, labels) => {
    return {
        elements: {
            bar: {
                borderWidth: 0,
            },
            point: {
                radius: 0,
            },
            line: {
                tension: 0.4,
                pointStyle: 'circle',
            },
        },
        clip: false,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            stacked100: { enable: true, axisId: 'bar' },
            legend: {
                position: 'bottom',
                align: 'start',
                reverse: true,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    font: {
                        family: 'Rubik',
                        size: 10,
                        weight: 400,
                    },
                },
                onClick: () => null,
            },
            tooltip: {
                displayColors: false,
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 1)',
                callbacks: {
                    title: function () {},
                    label: function (context) {
                        return `${context.dataset.label} - ${context.formattedValue}`;
                    },
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    tickLength: 0,
                    drawTicks: false,
                    drawBorder: true,
                    drawOnChartArea: false,
                    borderColor: 'rgba(0, 0, 0, 0.3)',
                },
                ticks: {
                    autoSkip: false,
                    padding: 8,
                    font: {
                        family: 'Rubik',
                        size: 10,
                        weight: 400,
                    },
                    callback: function (_, index) {
                        const parts = labels[index].toUpperCase().split(' ');
                        return `${parts[0]} ${parts[1].substring(0, 3).toUpperCase()}`;
                    },
                },
            },
            bar: {
                type: 'linear',
                position: 'left',
                min: 0,
                max: 100,
                grid: {
                    display: false,
                },
                ticks: {
                    stepSize: 25,
                    callback: function (value) {
                        return value + '%';
                    },
                    font: {
                        family: 'Rubik',
                        size: 10,
                    },
                    color: 'rgba(0, 0, 0, 0.6)',
                    padding: 5,
                },
                stacked: true,
                title: {
                    display: true,
                    position: 'left',
                    align: 'center',
                    font: {
                        family: 'Rubik',
                        size: 10,
                        weight: 500,
                    },
                    color: 'rgba(0, 0, 0, 0.6)',
                    text: total.toUpperCase(),
                    padding: {
                        bottom: 14,
                    },
                },
            },
            line: {
                type: 'linear',
                position: 'right',
                max: 100,
                min: -100,
                ticks: {
                    stepSize: 50,
                    font: {
                        family: 'Rubik',
                        size: 10,
                    },
                    color: 'rgba(0, 0, 0, 0.6)',
                    padding: 10,
                },
                grid: {
                    tickLength: 0,
                    drawTicks: false,
                    drawBorder: true,
                    borderDash: [5, 5],
                    borderColor: 'rgba(0, 0, 0, 0.3)',
                },
                title: {
                    display: true,
                    position: 'left',
                    align: 'center',
                    font: {
                        family: 'Rubik',
                        size: 10,
                        weight: 500,
                    },
                    color: 'rgba(0, 0, 0, 0.6)',
                    text: averageRating.toUpperCase(),
                    padding: {
                        bottom: 14,
                    },
                },
            },
        },
    };
};

Chart.register(ChartjsPluginStacked100);

const NpsChart = memo(({ data, language, type }) => {
    const { t } = useTranslation();
    const chartRef = useRef(null);

    useEffect(() => {
        return handleChartPrint(chartRef, 'chart-wrapper-nps');
    }, [() => chartRef.current]);

    const labels = data.map(({ dateLabel }) => format(dateLabel, 'dd MMM', { locale: I18N_DATE_FORMAT[language] }));
    const legends = ['Promoters', 'Neutrals', 'Detractors', t('AnalyticsReviews.nps')];

    return (
        <PaperStyled elevation={1} type={type}>
            <Typography variant="title1" sx={{ color: 'rgba(0,0,0, 0.87)', marginBottom: '50px' }}>
                {t('AnalyticsReviews.nps')}
            </Typography>
            <TableContainer>
                <ChartWrapper id="chart-wrapper-nps">
                    {data.length && (
                        <Bar
                            ref={chartRef}
                            data={createChartData(labels, data, legends)}
                            options={createChartOptions(
                                t('AnalyticsReviews.totalResponses'),
                                t('AnalyticsReviews.nps'),
                                labels,
                            )}
                        />
                    )}
                </ChartWrapper>
            </TableContainer>
        </PaperStyled>
    );
});

NpsChart.displayName = 'NpsChart';

export default NpsChart;
