import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useMediaQuery } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { Input } from '@components/Input';
import { ModalBase } from '@components/Modal';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { profilesMediaService } from '@services';
import { profilesMediaActions, alertActions } from '@actions';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: '564px !important',
        '@media (max-width: 600px)': {
            height: 'var(--100vh) !important',
            width: '100% !important',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

const Container = styled('div')({
    height: '100%',
});

const ReportModal = ({ isOpen, handleClose, data, selectedImage, setSelectedImage }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('@media (max-width: 600px)');

    const reasonOptions = [
        { value: 1, label: t('ProfilesMedia.offensive') },
        { value: 2, label: t('ProfilesMedia.legalIssue') },
        { value: 3, label: t('ProfilesMedia.privacyConcern') },
        { value: 4, label: t('ProfilesMedia.poorQuality') },
        { value: 5, label: t('ProfilesMedia.wrongMedia') },
        { value: 6, label: t('ProfilesMedia.other') },
    ];

    const ProfileSchema = yup.object().shape({
        reason: yup.number(),
        reasonText: yup.string().when('reason', {
            is: value => value === 6,
            then: yup.string().required('validation.required'),
            otherwise: yup.string().nullable(),
        }),
    });

    const resolver = useYupValidationResolver(ProfileSchema);

    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            reason: 1,
            reasonText: '',
        },
    });

    const isOtherSelected = +watch('reason') === 6;

    const onSubmit = async values => {
        try {
            const updatedMediaItem = await profilesMediaService.reportMedia({
                mediaItemId: selectedImage?.id,
                reason: values.reason,
                reasonText: values.reasonText,
            });
            const mediaCopy = data.slice();
            const updatedMediaIndex = mediaCopy.findIndex(c => c.id === updatedMediaItem?.id);
            if (updatedMediaIndex !== -1) {
                mediaCopy[updatedMediaIndex] = updatedMediaItem;
                dispatch(profilesMediaActions.set(mediaCopy));
            }
            setSelectedImage(updatedMediaItem);
            dispatch(alertActions.success(t('alertMessages.updateSuccess')));
            onClose();
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.updateFail')));
        }
    };

    const onClose = () => {
        reset();
        handleClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            handleClose={onClose}
            title={t('ProfilesMedia.confirmReport')}
            onPrimaryAction={handleSubmit(onSubmit)}
            primaryActionText={t('buttons.report')}
            primaryActionType="danger"
            primaryActionDisabled={isSubmitting || !isDirty || !isValid}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
        >
            <Container>
                <Typography
                    variant="caption"
                    sx={{ color: 'rgba(0, 0, 0, 0.6)', marginBottom: '12px', display: 'block' }}
                >
                    {t('ProfilesMedia.reportQuestion')}
                </Typography>
                <Controller
                    control={control}
                    name="reason"
                    render={({ field, ref }) =>
                        reasonOptions.map((item, index) => (
                            <RadioGroup key={index} {...field} ref={ref}>
                                <FormControlLabel
                                    value={`${item.value}`}
                                    control={
                                        <Radio
                                            sx={{
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                padding: '6px',
                                                '&.Mui-checked': { color: '#1F4C5C' },
                                            }}
                                        />
                                    }
                                    label={
                                        <>
                                            <Typography variant="body2" sx={{ marginLeft: '10px' }}>
                                                {item.label}
                                            </Typography>
                                        </>
                                    }
                                    sx={{ marginLeft: '-5px', marginBottom: '10px' }}
                                />
                            </RadioGroup>
                        ))
                    }
                />
                {isOtherSelected && (
                    <Controller
                        control={control}
                        name="reasonText"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('Listings.description')}
                                fullWidth
                                multiline
                                minRows={2}
                                maxLength={1500}
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                sx={{ paddingLeft: isMobile ? 0 : '46px' }}
                            />
                        )}
                    />
                )}
            </Container>
        </Modal>
    );
};

export default ReportModal;
