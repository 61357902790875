import { memo } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@components/Typography';
import { Table } from '@components/Table';
import { getTableBody } from './SocialPostsTableBody';
import { getCollapsibleTableBody } from './CollapsibleRowBody';
import { useTranslation } from '@hooks/useTranslation';
import { PaperStyled, TableStyled } from '../../Feedback/FeedbackTable';

export const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            width: '5%',
            component: <></>,
        },
        {
            key: 1,
            width: '25%',
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('common.title')}
                </Typography>
            ),
        },
        {
            key: 2,
            width: '14%',
            component: (
                <Typography variant="subtitle2" align="right">
                    {t('AnalyticsSocialPosts.impressions')}
                </Typography>
            ),
        },
        {
            key: 3,
            width: '14%',
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsSocialPosts.reach')}
                </Typography>
            ),
        },
        {
            key: 4,
            width: '14%',
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsSocialPosts.likes')}
                </Typography>
            ),
        },
        {
            key: 5,
            width: '14%',
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsSocialPosts.comments')}
                </Typography>
            ),
        },
        {
            key: 6,
            width: '14%',
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsSocialPosts.shares')}
                </Typography>
            ),
        },
    ];
};

const CollapsibleRow = ({ data, index }) => {
    return <Table rows={getCollapsibleTableBody(data, index)} />;
};

const SocialPostsTable = memo(({ isLoading, data }) => {
    return (
        <PaperStyled elevation={1}>
            <TableContainer>
                <TableStyled
                    data={data}
                    columnNames={GetTableHeader()}
                    rows={getTableBody(data)}
                    isDataLoading={isLoading}
                    isCollapsible={true}
                    CollapsibleRow={CollapsibleRow}
                />
            </TableContainer>
        </PaperStyled>
    );
});

SocialPostsTable.displayName = 'SocialPostsTable';

export default SocialPostsTable;
