import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Input } from '@components/Input';
import { Typography } from '@components/Typography';
import { MultiSelect } from '@components/MultiSelect';
import { MultiSelectLabels } from '@components/MultiSelectLabels';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { useTranslation } from '@hooks/useTranslation';
import { chatbotContentService } from '@services';
import { alertActions, chatbotContentActions } from '@actions';
import { Modal, Content } from './styles';

export const FORM_TYPE = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    EDIT_COPY: 'EDIT_COPY',
    DELETE: 'DELETE',
};

const EditModal = ({
    selectedContent,
    setSelectedContent,
    filter,
    modal,
    setModal,
    page,
    setPage,
    rowsPerPage,
    setDeleteModalOpen,
    locations,
}) => {
    const { t } = useTranslation();
    const { contact: labels, location: locationLabels } = useSelector(state => state.labels);
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('@media (max-width: 768px)');

    const KnowledgeBaseSchema = yup.object().shape({
        title: yup.string().required('validation.required'),
        locations: yup.array().min(1, 'validation.required'),
        question: yup.string().required('validation.required'),
        answer: yup.string().required('validation.required').max(2000, 'validation.maxLength'),
    });

    const resolver = useYupValidationResolver(KnowledgeBaseSchema);

    const availableLabels = [...new Set(locations.map(item => item.labels).flat())];

    const {
        getValues,
        setValue,
        control,
        watch,
        handleSubmit,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            title: '',
            locations: [],
            locationLabels: locationLabels.filter(l => availableLabels.includes(l.id)).map(item => item.id),
            question: '',
            answer: '',
            labels: [],
        },
    });

    const selectedLocationLabels = watch('locationLabels');

    const labelsOptions = useMemo(() => labels.map(item => ({ value: item.id, label: item.name })), [labels]);

    const locationLabelsOptions = useMemo(() => {
        return locationLabels
            .filter(l => availableLabels.includes(l.id))
            .map(item => ({ value: item.id, label: item.name }));
    }, [locations, locationLabels]);

    const locationsOptions = useMemo(() => {
        return selectedLocationLabels.length === locationLabelsOptions.length
            ? locations.map(item => ({ value: item.companyId, label: item.internalName }))
            : locations
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [locations, locationLabelsOptions, selectedLocationLabels]);

    useEffect(() => {
        const labelsIds = locationLabels.filter(l => availableLabels.includes(l.id)).map(item => item.id);
        setValue('locationLabels', labelsIds);
    }, [locationLabels]);

    useEffect(() => {
        if (modal.type !== FORM_TYPE.NEW) {
            setValue('title', selectedContent.title, { shouldValidate: true });
            setValue('locations', selectedContent.locations, { shouldValidate: true });
            setValue('question', selectedContent.question, { shouldValidate: true });
            setValue('answer', selectedContent.answer, { shouldValidate: true });
            setValue('labels', selectedContent.labels?.map(l => l.id) || []);
        }
    }, [selectedContent, modal.type, setValue]);

    const onClose = () => {
        setSelectedContent(null);
        setModal({ open: false, type: FORM_TYPE.NEW });
    };

    const refetchFirstPage = async () => {
        if (page === 0) {
            await dispatch(chatbotContentActions.get({ locations: filter.locations }, page, rowsPerPage));
        } else {
            setPage(0); // Triggers refetch of templates
        }
    };

    const REFETCH_MAP = {
        [FORM_TYPE.EDIT]: async () =>
            await dispatch(chatbotContentActions.get({ locations: filter.locations }, page, rowsPerPage)),
        [FORM_TYPE.NEW]: () => refetchFirstPage(),
        [FORM_TYPE.EDIT_COPY]: () => refetchFirstPage(),
    };

    const RESPONSE_MSG = {
        [FORM_TYPE.EDIT]: {
            success: t('alertMessages.editSuccess'),
            error: t('alertMessages.editFail'),
        },
        [FORM_TYPE.NEW]: {
            success: t('alertMessages.createSuccess'),
            error: t('alertMessages.createFail'),
        },
        [FORM_TYPE.EDIT_COPY]: {
            success: t('alertMessages.createSuccess'),
            error: t('alertMessages.createFail'),
        },
    };

    const onSubmit = async () => {
        const values = getValues();
        try {
            await chatbotContentService.addOrUpdateChatbotContent({
                ...values,
                type: 1, // 1 - QA
                labels: labels.filter(label => values.labels.includes(label.id)),
                ...(modal.type === FORM_TYPE.EDIT && { id: selectedContent.id }),
            });
            dispatch(alertActions.success(RESPONSE_MSG[modal.type].success));
            REFETCH_MAP[modal.type]();
            setModal({ open: false, type: FORM_TYPE.NEW });
            setSelectedContent(null);
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(RESPONSE_MSG[modal.type].error));
            }
        }
    };

    return (
        <Modal
            isOpen={modal.open}
            handleClose={onClose}
            title={
                modal.type === FORM_TYPE.NEW || modal.type === FORM_TYPE.EDIT_COPY
                    ? t('buttons.addContent')
                    : t('KnowledgeBase.editContent')
            }
            onPrimaryAction={handleSubmit(onSubmit)}
            primaryActionText={t('buttons.save')}
            primaryActionDisabled={isSubmitting || !isValid || !isDirty}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
            onAdditionalAction={selectedContent?.id ? () => setDeleteModalOpen(true) : null}
            additionalTextButton={t('buttons.delete')}
            additionalActionDisabled={isSubmitting}
        >
            <Content>
                <Controller
                    control={control}
                    name="title"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('common.title')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                            sx={{ gridColumn: isMobile ? '1 / span 1' : '1 / span 2' }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="locationLabels"
                    render={({ field, ref }) => (
                        <MultiSelect
                            {...field}
                            ref={ref}
                            label={t('common.locationLabels')}
                            onChange={({ target: { value } }) => {
                                const selectedLabels = getMultiselectSelectedOptions(locationLabelsOptions, value);
                                setValue('locationLabels', selectedLabels, { shouldDirty: true });
                                const availableCompanies =
                                    selectedLabels.length === locationLabelsOptions.length
                                        ? locations.map(item => item.companyId)
                                        : locations
                                              .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                                              .map(item => item.companyId);
                                setValue('locations', availableCompanies, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                            }}
                            options={locationLabelsOptions}
                            countable
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="locations"
                    render={({ field, fieldState: { error }, ref }) => (
                        <MultiSelect
                            {...field}
                            ref={ref}
                            onChange={({ target: { value } }) => {
                                setValue('locations', getMultiselectSelectedOptions(locationsOptions, value), {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                            }}
                            options={locationsOptions}
                            label={t('common.locations')}
                            fullWidth
                            countable
                            required
                            error={error}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="question"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('common.question')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                            sx={{ gridColumn: isMobile ? '1 / span 1' : '1 / span 2' }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="answer"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('common.answer')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message, { count: 2000 }) : null}
                            multiline
                            required
                            minRows={4}
                            maxLength={2000}
                            sx={{ gridColumn: isMobile ? '1 / span 1' : '1 / span 2' }}
                        />
                    )}
                />
                <Typography
                    variant="caption"
                    sx={{
                        color: 'rgba(0, 0, 0, 0.6)',
                        gridColumn: isMobile ? '1 / span 1' : '1 / span 2',
                        marginBottom: '16px',
                    }}
                >
                    {t('KnowledgeBase.selectLabelsDescription')}
                </Typography>
                <Controller
                    control={control}
                    name="labels"
                    render={({ field, fieldState: { error }, ref }) => (
                        <div style={{ gridColumn: isMobile ? '1 / span 1' : '1 / span 2' }}>
                            <MultiSelectLabels
                                {...field}
                                ref={ref}
                                onChange={({ target: { value } }) => {
                                    setValue('labels', getMultiselectSelectedOptions(labelsOptions, value), {
                                        shouldDirty: true,
                                    });
                                }}
                                options={labelsOptions}
                                label={t('common.labels')}
                                fullWidth
                                editable={false}
                                error={!!error}
                            />
                        </div>
                    )}
                />
            </Content>
        </Modal>
    );
};

export default EditModal;
