import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { MultiSelect } from '@components/MultiSelect';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@components/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { Select } from '@components/Select';
import { useTranslation } from '@hooks/useTranslation';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';

export const MultiSelectWithIcon = styled(MultiSelect)({
    '& .MuiSelect-outlined': {
        paddingRight: '50px !important',
    },
    '& .MuiInputBase-adornedEnd': {
        paddingRight: 0,
    },
});

const AutomatedFields = ({ isView }) => {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();

    const delayOptions = [
        { value: 0, label: t('common.none') },
        { value: 1, label: t('FeedbackSurveys.day') },
        { value: 2, label: t('FeedbackSurveys.days3') },
        { value: 3, label: t('FeedbackSurveys.week') },
        { value: 4, label: t('FeedbackSurveys.weeks2') },
        { value: 5, label: t('FeedbackSurveys.month') },
        { value: 6, label: t('FeedbackSurveys.months3') },
        { value: 7, label: t('FeedbackSurveys.months6') },
        { value: 8, label: t('FeedbackSurveys.year') },
    ];

    const triggersOptions = [
        { value: 1, label: t('CampaignsPage.surveyResponded') },
        { value: 5, label: t('CampaignsPage.conversationResolved') },
        { value: 7, label: t('common.contactCreated') },
        { value: 8, label: t('CampaignsPage.reviewLeft') },
    ];

    return (
        <>
            <Controller
                control={control}
                name="triggers"
                render={({ field, fieldState: { error }, ref }) => (
                    <MultiSelectWithIcon
                        {...field}
                        ref={ref}
                        onChange={({ target: { value } }) => {
                            setValue('triggers', getMultiselectSelectedOptions(triggersOptions, value), {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                        }}
                        options={triggersOptions}
                        label={t('CampaignsPage.triggerEvent')}
                        style={{ gridArea: 'triggers' }}
                        fullWidth
                        countable
                        required
                        disabled={isView}
                        error={error}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{ position: 'relative' }}>
                                    <IconButton
                                        sx={{
                                            marginRight: 0,
                                            position: 'absolute',
                                            left: '-60px',
                                            padding: '8px',
                                        }}
                                    >
                                        <Tooltip
                                            enterTouchDelay={0}
                                            title={t('CampaignsPage.triggerExplanation')}
                                            placement="top"
                                        >
                                            <HelpIcon sx={{ fontSize: '16px' }} />
                                        </Tooltip>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
            <Controller
                control={control}
                name="delay"
                render={({ field, ref }) => (
                    <Select
                        {...field}
                        onChange={({ target: { value } }) => setValue('delay', value, { shouldDirty: true })}
                        ref={ref}
                        label={t('common.delay')}
                        fullWidth
                        options={delayOptions}
                        disabled={isView}
                        style={{ gridArea: 'delay' }}
                    />
                )}
            />
        </>
    );
};

export default AutomatedFields;
