import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@components/Typography';
import { useForm, Controller } from 'react-hook-form';
import { Input } from '@components/Input';
import { Checkbox } from '@components/Checkbox';
import { MultiSelectLabels } from '@components/MultiSelectLabels';
import { LabelChip } from '@components/Label';
import { MultiSelect } from '@components/MultiSelect';
import CloseIcon from '@mui/icons-material/Close';
import { alertActions, contactsActions } from '@actions';
import { contactsService } from '@services';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { LABEL_TYPE } from '@constants/labels';
import { useTranslation } from '@hooks/useTranslation';
import { Modal, Content, StyledMultiSelect, CheckboxWrapper } from './styles';

const BulkEditModal = ({ modal, setModal, companies, selectedContacts, currentQuery, rowsPerPageRequests }) => {
    const { t } = useTranslation();
    const [touchedCompanies, setTouchedCompanies] = useState([]);
    const [touchedLabels, setTouchedLabels] = useState([]);

    const { totalCount, bulkEditData: data } = useSelector(state => state.contacts);
    const { contact: labels, location: locationLabels } = useSelector(state => state.labels);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            contactsActions.getBulkEditData({
                filter: {
                    ...currentQuery,
                    contacts: Object.keys(selectedContacts.list),
                    allContacts: selectedContacts.all,
                },
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setValue('companies', data.companies.map(i => i.id) || [], { shouldDirty: false });
            setValue('labels', data.labels.map(i => i.id) || [], { shouldDirty: false });
            setValue('unsubscribed', data.unsubscribed || null, { shouldDirty: false });
        }
    }, [data]);

    const labelsOptions = useMemo(() => labels.map(item => ({ value: item.id, label: item.name })), [labels]);

    const selectedLength = Object.keys(selectedContacts.list).length;

    const availableLabels = [...new Set(companies.map(item => item.labels).flat())];

    const {
        getValues,
        handleSubmit,
        setValue,
        control,
        reset,
        watch,
        formState: { isDirty, isSubmitting },
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            contactId: t('ContactsPage.contactsSelected', {
                selectedContacts: selectedContacts.all ? totalCount : selectedLength,
            }),
            companies: [],
            unsubscribed: null,
            labels: [],
            locationLabels: locationLabels.filter(l => availableLabels.includes(l.id)).map(item => item.id),
        },
    });

    const selectedLocationLabels = watch('locationLabels');

    const locationLabelsOptions = useMemo(() => {
        return locationLabels
            .filter(l => availableLabels.includes(l.id))
            .map(item => ({ value: item.id, label: item.name }));
    }, [companies, locationLabels]);

    const companyOptions = useMemo(() => {
        return selectedLocationLabels.length === locationLabelsOptions.length
            ? companies.map(item => ({ value: item.companyId, label: item.internalName }))
            : companies
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [companies, locationLabelsOptions, selectedLocationLabels]);

    const onCloseModal = () => {
        setModal(false);
        dispatch(contactsActions.setBulkEditData({}));
        reset();
    };

    const getArrayDiff = (arr1, arr2) => {
        return arr1.filter(i => !arr2.includes(i));
    };

    const onSubmit = async () => {
        const values = getValues();
        const { companies, labels, unsubscribed } = values;
        const dataCompanies = data.companies.map(i => i.id);
        const dataLabels = data.labels.map(i => i.id);

        try {
            await contactsService.updateBulkContacts({
                data: {
                    companiesToAdd: [
                        ...getArrayDiff(companies, dataCompanies),
                        ...companies.filter(i => touchedCompanies.includes(i)),
                    ],
                    companiesToRemove: getArrayDiff(dataCompanies, companies),
                    labelsToAdd: [
                        ...getArrayDiff(labels, dataLabels),
                        ...labels.filter(i => touchedLabels.includes(i)),
                    ],
                    labelsToRemove: getArrayDiff(dataLabels, labels),
                    unsubscribed,
                },
                filter: {
                    ...currentQuery,
                    contacts: Object.keys(selectedContacts.list),
                    allContacts: selectedContacts.all,
                },
            });
            dispatch(contactsActions.get(currentQuery, 0, rowsPerPageRequests));
            dispatch(alertActions.success(t('alertMessages.editSuccess')));
            onCloseModal();
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.editFail')));
            }
        }
    };

    return (
        <Modal
            isOpen={modal}
            handleClose={onCloseModal}
            title={t('ContactsPage.editContactsTitle')}
            subtitle={
                <Typography variant="caption" color="textSecondary">
                    {t('ContactsPage.editBulkContactsSubtitle')}
                </Typography>
            }
            onPrimaryAction={handleSubmit(onSubmit)}
            primaryActionText={t('buttons.saveChanges')}
            primaryActionDisabled={isSubmitting || !isDirty}
            onSecondaryAction={onCloseModal}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content>
                <Controller
                    control={control}
                    name="contactId"
                    render={({ field, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('ContactsPage.contacts')}
                            fullWidth
                            disabled
                            sx={{ gridArea: 'input1' }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="locationLabels"
                    render={({ field, ref }) => (
                        <MultiSelect
                            {...field}
                            ref={ref}
                            label={t('common.locationLabels')}
                            onChange={({ target: { value } }) => {
                                const selectedLabels = getMultiselectSelectedOptions(locationLabelsOptions, value);
                                setValue('locationLabels', selectedLabels, { shouldDirty: true });
                                const availableCompanies =
                                    selectedLabels.length === locationLabelsOptions.length
                                        ? companies.map(item => item.companyId)
                                        : companies
                                              .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                                              .map(item => item.companyId);
                                setValue('companies', availableCompanies, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                            }}
                            options={locationLabelsOptions}
                            style={{ gridArea: 'select0' }}
                            countable
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="companies"
                    render={({ field, ref }) => (
                        <StyledMultiSelect
                            {...field}
                            ref={ref}
                            onChange={({ target: { value } }) => {
                                setValue('companies', getMultiselectSelectedOptions(companyOptions, value), {
                                    shouldDirty: true,
                                });
                                setTouchedCompanies(prev => {
                                    const touched = data.companies.map(i => i.id).filter(i => !value.includes(i));
                                    return [...prev, ...touched];
                                });
                            }}
                            options={companyOptions}
                            label={t('common.locations')}
                            style={{ gridArea: 'select1' }}
                            fullWidth
                            countable
                            renderValue={selected => {
                                const selectedOptions = selected.map(selectedValue =>
                                    companyOptions.find(({ value }) => selectedValue === value),
                                );
                                return selectedOptions.map((option, index) => (
                                    <LabelChip
                                        key={index}
                                        type={option.label}
                                        isPartlyAssigned={
                                            data.companies
                                                .filter(i => !i.assignedForAll)
                                                .map(i => i.id)
                                                .includes(option.value) && !touchedCompanies.includes(option.value)
                                        }
                                        label={
                                            <Typography variant="caption" sx={{ display: 'block', height: 14 }}>
                                                {option.label}
                                            </Typography>
                                        }
                                        clickable
                                        deleteIcon={<CloseIcon onMouseDown={e => e.stopPropagation()} />}
                                        onDelete={() => {
                                            setValue(
                                                'companies',
                                                field.value?.filter(val => val !== option.value),
                                                { shouldDirty: true },
                                            );
                                            setTouchedCompanies(prev => [...prev, option.value]);
                                        }}
                                    />
                                ));
                            }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="labels"
                    render={({ field, ref }) => (
                        <div style={{ gridArea: 'select2' }}>
                            <MultiSelectLabels
                                {...field}
                                ref={ref}
                                onChange={({ target: { value } }) => {
                                    setValue('labels', getMultiselectSelectedOptions(labelsOptions, value), {
                                        shouldDirty: true,
                                    });
                                    setTouchedLabels(prev => {
                                        const touched = data.labels.map(i => i.id).filter(i => !value.includes(i));
                                        return [...prev, ...touched];
                                    });
                                }}
                                options={labelsOptions}
                                label={t('common.labels')}
                                fullWidth
                                type={LABEL_TYPE.CONTACT}
                                onCreateCallback={newLabel => {
                                    const values = getValues();
                                    setValue('labels', [...values.labels, newLabel], {
                                        shouldDirty: true,
                                    });
                                }}
                                bulkEditData={data.labels}
                                touchedLabels={touchedLabels}
                            />
                        </div>
                    )}
                />
                <>
                    <CheckboxWrapper>
                        <Controller
                            control={control}
                            name="unsubscribed"
                            render={({ field, ref }) => (
                                <Checkbox
                                    ref={ref}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={e => setValue('unsubscribed', e.target.checked, { shouldDirty: true })}
                                    checked={field.value}
                                />
                            )}
                        />
                        <Typography variant="body2">{t('ContactsPage.unsubscribe')}</Typography>
                    </CheckboxWrapper>
                </>
            </Content>
        </Modal>
    );
};

export default BulkEditModal;
