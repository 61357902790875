import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@hooks/useTranslation';
import { PATH_MAP } from '@constants/header';

const getHeaderTitle = path => {
    if (path.includes('/reset-password')) {
        return 'resetPassword';
    } else if (path.includes('/verify-email')) {
        return 'verifyEmail';
    }
    return PATH_MAP[path];
};

const SEO = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const title = t(`Header.${getHeaderTitle(pathname)}`);

    return (
        <Helmet>
            <title>{title} | Pluspoint</title>
            <meta
                name="description"
                content="Manage online profiles, customer reviews, and social media. Streamline engagement for multi-location businesses with ease."
            />

            {/* Open Graph Tags */}
            <meta property="og:title" content={`${title} | Pluspoint`} />
            <meta
                property="og:description"
                content="Manage online profiles, customer reviews, and social media. Streamline engagement for multi-location businesses with ease."
            />
            <meta property="og:type" content="website" />
            <meta
                property="og:image"
                content="https://uploads-ssl.webflow.com/62a59a8183e4a12baf5c5bf8/65f473b14c244d62d3f5e242_graph.png"
            />

            {/* Twitter Card Tags */}
            <meta name="twitter:title" content={`${title} | Pluspoint`} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:creator" content="@trypluspoint" />
            <meta
                name="twitter:description"
                content="Manage online profiles, customer reviews, and social media. Streamline engagement for multi-location businesses with ease."
            />
            <meta
                name="twitter:image"
                content="https://uploads-ssl.webflow.com/62a59a8183e4a12baf5c5bf8/65f473b14c244d62d3f5e242_graph.png"
            />
        </Helmet>
    );
};

export default SEO;
