import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';

export const Header = styled('span')({
    width: '100%',
    height: 72,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #373737',
    padding: '22px 19px',
    '@media (max-width: 1024px)': {
        height: 60,
        padding: 16,
    },
});

export const IconWrapper = styled('div')({
    height: 14,
    display: 'flex',
    justifyContent: 'center',
    width: 22,
    '& path': {
        fill: '#fff !important',
    },
    '@media print': {
        display: 'none',
    },
});

export const PluspointText = styled('span')({
    fontFamily: 'Rubik, sans-serif',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    marginLeft: 11,
    '@media print': {
        display: 'none',
    },
});

export const LogoWrapper = styled('span')({
    '@media print': {
        display: 'none',
    },
});

export const LogoPrintWrapper = styled('span')({
    display: 'none',
    '@media print': {
        display: 'block',
        '& svg': {
            height: '32px',
            width: 'auto',
        },
    },
});

export const SidebarDrawer = styled(MuiDrawer, {
    shouldForwardProp: prop => prop !== 'isExpanded',
})(({ isExpanded }) => ({
    '.MuiDrawer-paper': {
        gridArea: 'sidebar',
        backgroundColor: '#272727',
        width: '280px',
        height: '100%',
        '@media (max-width: 1024px)': {
            width: '100%',
            height: !isExpanded ? 60 : '100%',
            overflow: 'hidden',
            transition: 'all 0.2s',
        },
        '@media print': {
            backgroundColor: '#fff',
            position: 'relative',
        },
    },
    '& .MuiPaper-root': {
        zIndex: 5,
    },
}));

export const List = styled(MuiList, {
    shouldForwardProp: prop => prop !== 'isExpanded' && prop !== 'isMobile',
})(({ isExpanded, isMobile }) => ({
    paddingTop: isExpanded && isMobile ? 16 : 0,
    paddingBottom: 0,
    display: isMobile && !isExpanded ? 'none' : 'block',
    overflowY: 'auto',
}));

export const ListItem = styled(MuiListItem, {
    shouldForwardProp: prop => prop !== 'isActive' && prop !== 'isExpanded',
})(({ isActive, isExpanded = false, type }) => ({
    padding: '8px 16px 8px 24px',
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'flex',
    transition: 'all 0.3s ease-out',
    position: 'relative',
    backgroundColor: isActive ? '#2E2E2E' : 'transparent',
    '.MuiTypography-body1': {
        color: isActive ? '#fff' : 'rgba(255, 255, 255, 0.74)',
        marginLeft: '16px',
        fontWeight: isActive ? 500 : 400,
    },
    '.MuiTypography-body2': {
        marginLeft: 41,
        color: isActive ? '#fff' : 'rgba(255, 255, 255, 0.74)',
        fontWeight: isActive ? 500 : 400,
    },
    svg: {
        path: {
            fill: isActive ? '#fff' : 'rgba(255, 255, 255, 0.74)',
            fillOpacity: isActive ? 1 : 0.74,
        },
    },
    span: {
        width: 8,
        height: '100%',
        position: 'absolute',
        left: 0,
        ...(type === 'single' && {
            backgroundColor: !isActive ? 'transparent' : '#1F4C5C',
        }),
        ...(type === 'multi' && {
            backgroundColor: !isActive ? 'transparent' : isExpanded ? '#373737' : '#1F4C5C',
        }),
    },
    '&:hover': {
        '.MuiTypography-body1, .MuiTypography-body2': {
            color: '#fff',
        },
        svg: {
            path: {
                fill: '#fff',
                fillOpacity: 1,
            },
        },
    },
    '.sidebar-menu-arrow': {
        width: 18,
        marginLeft: 'auto',
    },
}));
