import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { InternalLink } from '@components/Link';
import { accountActions, userActions, alertActions } from '@actions';
import GTMHelper from '@helpers/gtm';
import { userService } from '@services';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import { Switch } from '@components/Switch';
import Tooltip from '@components/Tooltip';
import Button from '@components/Button';
import { LANGUAGE } from '@constants/language';
import { regexAnyCharAtLeastOneNumberAndLetter } from '@constants/regex';
import { useTranslation } from '@hooks/useTranslation';
import { useWebPushNotifications } from '@hooks/useWebPushNotifications';
import { Typography } from '@components/Typography';
import { Container, Footer, Content } from './styles';

const PersonalSettingsPage = () => {
    const { t } = useTranslation();

    const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();
    const { isSubscribed, isSupported, togglePermissions } = useWebPushNotifications();

    const PersonalSettingsFormSchema = yup.object().shape(
        {
            firstName: yup.string().required('validation.required'),
            lastName: yup.string().required('validation.required'),
            phoneNumber: yup.string().validatePhone('validation.wrongPhoneFormat'),
            currentPassword: yup.string().when('newPassword', {
                is: newPassword => newPassword?.length > 0,
                then: yup.string().required('validation.required'),
                else: yup.string().notRequired(),
            }),
            newPassword: yup.string().when('currentPassword', {
                is: currentPassword => currentPassword?.length > 0,
                then: yup
                    .string()
                    .required('validation.required')
                    .min(8, 'validation.minPasswordLength')
                    .matches(regexAnyCharAtLeastOneNumberAndLetter, 'validation.wrongPasswordFormat'),
                else: yup.string().notRequired(),
            }),
            confirmPassword: yup.string().when('newPassword', {
                is: newPassword => newPassword?.length > 0,
                then: yup
                    .string()
                    .required('validation.required')
                    .oneOf([yup.ref('newPassword'), null], 'validation.confirmPasswordMatch'),
                else: yup.string().notRequired(),
            }),
        },
        [
            ['currentPassword', 'newPassword', 'confirmPassword'],
            ['phoneNumber', 'phoneNumber'],
        ],
    );

    const resolver = useYupValidationResolver(PersonalSettingsFormSchema);

    const {
        getValues,
        handleSubmit,
        setValue,
        control,
        reset,
        watch,
        formState: { errors, isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver: resolver,
        defaultValues: {
            id: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            systemLanguage: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            reviewReports: '',
            performanceReports: '',
            negativeFeedbacksAlerts: false,
        },
    });

    useEffect(() => {
        if (user) {
            setValue('id', user?.id || '', { shouldValidate: true });
            setValue('firstName', user?.firstName || '', { shouldValidate: true });
            setValue('lastName', user?.lastName || '', { shouldValidate: true });
            setValue('email', user?.email || '', { shouldValidate: true });
            setValue('phoneNumber', user?.phoneNumber || '', { shouldValidate: true });
            setValue('systemLanguage', user?.language || '', { shouldValidate: true });
            setValue('reviewReports', `${user?.reviewReports}` || '', { shouldValidate: true });
            setValue('performanceReports', `${user?.performanceReports}` || '', { shouldValidate: true });
            setValue('negativeFeedbacksAlerts', user?.negativeFeedbacksAlerts || false);
        }
    }, [user]);

    const onSubmit = async () => {
        const values = getValues();
        try {
            const updatedUser = await userService.updatePersonalSettings({
                ...values,
                reviewReports: Number(values.reviewReports),
                performanceReports: Number(values.performanceReports),
                negativeFeedbacksAlerts: values.phoneNumber ? values.negativeFeedbacksAlerts : false,
            });
            dispatch(userActions.setUser(updatedUser));
            await dispatch(accountActions.get());
            GTMHelper.update(updatedUser);
            dispatch(alertActions.success(t('alertMessages.updateSuccess')));
            reset(values);
            setValue('negativeFeedbacksAlerts', updatedUser.negativeFeedbacksAlerts, { shouldDirty: false });
            setValue('currentPassword', '', { shouldDirty: false });
            setValue('newPassword', '', { shouldDirty: false });
            setValue('confirmPassword', '', { shouldDirty: false });
        } catch (_) {
            dispatch(alertActions.error(t('alertMessages.updateFail')));
        }
    };

    const phoneNumberInput = watch('phoneNumber');

    return (
        <Container>
            <Typography variant="h6" sx={{ marginBottom: '24px', color: 'rgba(0, 0, 0, 0.87)' }}>
                {t('SettingsProfile.generalInfo')}
            </Typography>
            <Content>
                <Controller
                    control={control}
                    name="firstName"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field }}
                            label={t('common.firstName')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="lastName"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field }}
                            label={t('common.lastName')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="email"
                    render={({ field, ref }) => (
                        <Input ref={ref} inputProps={{ ...field }} label={t('common.email')} disabled />
                    )}
                />
                <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field }}
                            autoComplete="new-password"
                            label={t('common.phoneNumberOpt')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="systemLanguage"
                    render={({ field, ref }) => (
                        <Select
                            {...field}
                            value={getValues('systemLanguage')}
                            ref={ref}
                            label={t('SettingsProfile.systemLanguage')}
                            options={[
                                { value: LANGUAGE.ENGLISH, label: 'English (US)' },
                                { value: LANGUAGE.UKRAINIAN, label: 'Ukrainian (UA)' },
                                { value: LANGUAGE.PORTUGUESE, label: 'Portuguese (PT)' },
                                { value: LANGUAGE.SPANISH, label: 'Spanish (ES)' },
                            ]}
                        />
                    )}
                />
            </Content>
            <Typography variant="h6" sx={{ marginBottom: '24px', marginTop: '24px', color: 'rgba(0, 0, 0, 0.87)' }}>
                {t('common.password')}
            </Typography>
            <Content isPassword>
                <Controller
                    control={control}
                    name="currentPassword"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field }}
                            type="password"
                            sx={{ gridArea: 'area1' }}
                            label={t('SettingsProfile.currentPassword')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="newPassword"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field }}
                            type="password"
                            sx={{ gridArea: 'area2' }}
                            label={t('SettingsProfile.newPassword')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                        />
                    )}
                />
                <Typography
                    variant="body2"
                    sx={{
                        marginTop: '12px',
                        gridArea: 'area3',
                        color: 'rgba(0, 0, 0, 0.87)',
                        '@media (max-width: 1024px)': {
                            marginTop: '-16px',
                        },
                    }}
                >
                    {t('SettingsProfile.passwordResetText')}{' '}
                    <InternalLink to="/forgot-password" underline="hover">
                        {t('SettingsProfile.passwordResetUrl')}
                    </InternalLink>
                </Typography>
                <Controller
                    control={control}
                    name="confirmPassword"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field }}
                            type="password"
                            sx={{ gridArea: 'area4' }}
                            label={t('SettingsProfile.confirmPassword')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                        />
                    )}
                />
            </Content>
            <Typography variant="h6" sx={{ marginBottom: '24px', marginTop: '24px', color: 'rgba(0, 0, 0, 0.87)' }}>
                {t('SettingsProfile.alertsAndReports')}
            </Typography>
            <Content>
                <Controller
                    control={control}
                    name="reviewReports"
                    render={({ field, ref }) => (
                        <Select
                            {...field}
                            value={getValues('reviewReports')}
                            ref={ref}
                            label={t('SettingsProfile.newReviews')}
                            options={[
                                { value: '0', label: t('SettingsProfile.disabled') },
                                { value: '1', label: t('SettingsProfile.everyHour') },
                                { value: '2', label: t('SettingsProfile.everyDay') },
                            ]}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="performanceReports"
                    render={({ field, ref }) => (
                        <Select
                            {...field}
                            value={getValues('performanceReports')}
                            ref={ref}
                            label={t('SettingsProfile.performanceReport')}
                            options={[
                                { value: '0', label: t('SettingsProfile.disabled') },
                                { value: '1', label: t('SettingsProfile.everyWeek') },
                                { value: '2', label: t('SettingsProfile.everyMonth') },
                            ]}
                        />
                    )}
                />
                {phoneNumberInput && !errors?.phoneNumber ? (
                    <Controller
                        control={control}
                        name="negativeFeedbacksAlerts"
                        render={({ field, ref }) => (
                            <Switch
                                {...field}
                                ref={ref}
                                checked={getValues('negativeFeedbacksAlerts')}
                                handleChange={event =>
                                    setValue('negativeFeedbacksAlerts', event.target.checked, {
                                        shouldDirty: true,
                                    })
                                }
                                label={t('SettingsProfile.negativeFeedbacksSwitch')}
                            />
                        )}
                    />
                ) : (
                    <Tooltip placement="top" title={t('SettingsProfile.emptyNumberTooltip')}>
                        <span>
                            <Controller
                                control={control}
                                name="negativeFeedbacksAlerts"
                                render={({ field, ref }) => (
                                    <Switch
                                        {...field}
                                        ref={ref}
                                        disabled
                                        checked={getValues('negativeFeedbacksAlerts')}
                                        label={t('SettingsProfile.negativeFeedbacksSwitch')}
                                    />
                                )}
                            />
                        </span>
                    </Tooltip>
                )}
                <div />
                {isSupported && (
                    <div>
                        <Switch
                            checked={isSubscribed}
                            handleChange={togglePermissions}
                            label={t('SettingsProfile.pushMessagesSwitch')}
                        />
                        <Typography
                            variant="caption"
                            sx={{
                                color: 'rgba(0, 0, 0, 0.6)',
                                display: 'block',
                            }}
                        >
                            {t('SettingsProfile.pushMessagesDescription')}
                        </Typography>
                    </div>
                )}
            </Content>
            <Footer>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    disabled={isSubmitting || !isValid || !isDirty}
                    sx={{ '@media (max-width: 1024px)': { width: '100%' } }}
                >
                    {t('buttons.saveChanges')}
                </Button>
            </Footer>
        </Container>
    );
};

export default PersonalSettingsPage;
