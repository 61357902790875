import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import Button from '@components/Button';
import { Typography } from '@components/Typography';

const StyledPopper = styled(Popper)({
    boxShadow:
        'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
    zIndex: 3,
    background: '#fff',
    padding: '8px 0',
    borderRadius: 4,
    '.MuiMenuItem-root': {
        padding: '8px 16px',
        fontSize: '14px',
        lineHeight: '16px',
        width: '100%',
        justifyContent: 'flex-start',
    },
});

const StyledButtonGroup = styled(ButtonGroup)({
    '& .MuiButtonGroup-firstButton:hover': {
        borderRightColor: 'transparent',
    },
    '& .MuiButtonGroup-lastButton': {
        padding: '8px 0',
    },
});

const SplitButton = ({ variant, options = [], disabled }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const onClick = () => options[selectedIndex]?.onClick();

    const handleMenuItemClick = index => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <StyledButtonGroup ref={anchorRef} disabled={disabled}>
                <Button variant={variant} onClick={onClick}>
                    {options[selectedIndex]?.text || ''}
                </Button>
                <Button variant={variant} size="small" onClick={handleToggle}>
                    <ArrowDropDownIcon />
                </Button>
            </StyledButtonGroup>
            <ClickAwayListener onClickAway={handleClose}>
                <StyledPopper
                    open={open}
                    anchorEl={anchorRef.current}
                    disablePortal={true}
                    popperOptions={{ placement: 'bottom' }}
                    sx={{ borderRadius: '8px' }}
                >
                    {options.map((item, index) => (
                        <MenuItem key={item.text} onClick={() => handleMenuItemClick(index)}>
                            <Typography variant="body2">{item.text}</Typography>
                        </MenuItem>
                    ))}
                </StyledPopper>
            </ClickAwayListener>
        </>
    );
};

export default SplitButton;
