import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@components/Typography';

const ProfileProgress = ({ value }) => {
    return (
        <span style={{ display: 'inline-block', position: 'relative', width: '40px', height: '40px' }}>
            <CircularProgress
                variant="determinate"
                value={value}
                size={40}
                sx={{ color: value <= 49 ? '#B00020' : value >= 50 && value <= 79 ? '#F68C15' : '#0B8D00' }}
            />
            <Typography
                variant="caption"
                sx={{
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: '10px !important',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {value}%
            </Typography>
        </span>
    );
};

export default ProfileProgress;
