import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Input } from '@components/Input';
import { MultiSelect } from '@components/MultiSelect';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { useTranslation } from '@hooks/useTranslation';

const Container = styled('div')({
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateAreas: '"input1" "input2" "select0" "select1"',
    gridTemplateColumns: '100%',
    '@media (max-width: 600px)': {
        gridTemplateAreas: '"input1" "input2" "select0" "select1"',
        gridGap: '16px',
    },
});

const WhatsAppForm = ({ labelsOptions }) => {
    const companies = useSelector(state => state.account?.account?.companies || []);

    const { t } = useTranslation();
    const { controlWhatsApp, watchWhatsApp, setValueWhatsApp } = useFormContext();

    const selectedLocationLabels = watchWhatsApp('locationLabels');

    const companyOptions = useMemo(() => {
        return selectedLocationLabels.length === labelsOptions.length
            ? companies.map(item => ({ value: item.companyId, label: item.internalName }))
            : companies
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [companies, labelsOptions, selectedLocationLabels]);

    return (
        <Container>
            <Controller
                control={controlWhatsApp}
                name="name"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsChannels.channelName')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                        sx={{ gridArea: 'input1' }}
                    />
                )}
            />
            <Controller
                control={controlWhatsApp}
                name="phoneNumber"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsChannels.whatsAppNumber')}
                        fullWidth
                        error={!!error}
                        disabled
                        helperText={error ? error.message : null}
                        sx={{ gridArea: 'input2' }}
                    />
                )}
            />
            <Controller
                control={controlWhatsApp}
                name="locationLabels"
                render={({ field, ref }) => (
                    <MultiSelect
                        {...field}
                        ref={ref}
                        label={t('common.locationLabels')}
                        onChange={({ target: { value } }) => {
                            const selectedLabels = getMultiselectSelectedOptions(labelsOptions, value);
                            setValueWhatsApp('locationLabels', selectedLabels, { shouldDirty: true });
                            const availableCompanies =
                                selectedLabels.length === labelsOptions.length
                                    ? companies.map(item => item.companyId)
                                    : companies
                                          .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                                          .map(item => item.companyId);
                            setValueWhatsApp('companies', availableCompanies, {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                        }}
                        options={labelsOptions}
                        style={{ gridArea: 'select0' }}
                        countable
                    />
                )}
            />
            <Controller
                control={controlWhatsApp}
                name="companies"
                render={({ field, fieldState: { error }, ref }) => (
                    <MultiSelect
                        {...field}
                        ref={ref}
                        onChange={({ target: { value } }) => {
                            setValueWhatsApp('companies', getMultiselectSelectedOptions(companyOptions, value), {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                        }}
                        options={companyOptions}
                        label={t('common.locations')}
                        style={{ gridArea: 'select1' }}
                        fullWidth
                        countable
                        required
                        error={error}
                    />
                )}
            />
        </Container>
    );
};

export default WhatsAppForm;
