import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';

export const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 564,
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        padding: '24px 16px',
    },
});

export const Content = styled('div')({
    display: 'grid',
    gridGap: '8px 24px',
    gridTemplateColumns: 'calc(50% - 12px) calc(50% - 12px)',
    '@media (max-width: 768px)': {
        gridTemplateColumns: '100%',
        gridGap: '8px',
    },
});
