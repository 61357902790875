import { useState, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import sortBy from 'lodash.sortby';
import Drawer from '@components/Drawer';
import { Select } from '@components/Select';
import { Input } from '@components/Input';
import { SmsTemplate } from '@components/SmsTemplate';
import { alertActions, channelsActions } from '@actions';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { sendMessageService } from '@services';
import { useTranslation } from '@hooks/useTranslation';
import SentryHelper from '@helpers/sentry';
import { CHANNEL } from '@constants/channels';
import { getChannelOptions, getFilteredByCompanyChannelsIds } from '@helpers/channels';
import { getSurveysOptionsById, getMessageSurveyContent, findCompany } from '@helpers/send-invites';
import { COUNTRY_CODES } from '@constants/phone';
import { sendInvitesUiActions } from '@actions';
import BulkForm from './BulkForm';
import { StyledRadioButtons } from './styles';

const FORM_TYPE = { PERSONAL: 1, BULK: 0 };

const SendInvites = ({ isMobile }) => {
    const { t } = useTranslation();

    const [bulk, setBulk] = useState({ list: [], selected: [] });
    const [isSmsTooLong, setSmsTooLong] = useState(false);
    const [channelOptions, setChannelOptions] = useState([]);

    const account = useSelector(state => state.account?.account);
    const channels = useSelector(state => state.channels.data);
    const channelsLoading = useSelector(state => state.channels.loading);
    const { isOpen } = useSelector(state => state.sendInvitesUi);
    const dispatch = useDispatch();
    const isRefetchedAfterBrokenChannel = useRef(false);

    const SendInviteFormSchema = yup.object().shape({
        companyId: yup.string().required('validation.required'),
        surveyId: yup.string().required('validation.required'),
        channelId: yup.string().required('validation.required'),
        userName: yup.string(),
        formType: yup.number(),
        phone: yup.string().when('formType', {
            is: FORM_TYPE.PERSONAL,
            then: yup.string().when('channelType', {
                is: channelType => channelType === CHANNEL.EMAIL,
                then: yup.string().required('validation.required').email('validation.wrongEmailFormat'),
                otherwise: yup.string().required('validation.required').validatePhone('validation.wrongPhoneFormat'),
            }),
        }),
        template: yup.string().required('validation.required'),
        channelType: yup.number(),
    });

    const resolver = useYupValidationResolver(SendInviteFormSchema);

    const {
        getValues,
        handleSubmit,
        setValue,
        control,
        reset,
        watch,
        clearErrors,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver: resolver,
        defaultValues: {
            companyId: '',
            surveyId: '',
            userName: '',
            phone: '',
            template: '',
            channelId: '',
            channelType: CHANNEL.EMAIL,
            formType: FORM_TYPE.PERSONAL,
        },
    });

    const companies = useMemo(() => account?.companies?.filter(c => c.surveys?.length > 0) || [], [account?.companies]);
    const companiesOptions = useMemo(
        () => companies?.map(c => ({ value: c.companyId, label: c.internalName })),
        [companies],
    );
    const formType = watch('formType');
    const isPersonal = formType === FORM_TYPE.PERSONAL;
    const userName = watch('userName');
    const template = watch('template');
    const channelType = watch('channelType');

    useEffect(() => {
        dispatch(channelsActions.get());
    }, [dispatch]);

    useEffect(() => {
        if (companies.length && !channelsLoading && channels.length && !isRefetchedAfterBrokenChannel.current) {
            const initialCompany = companies[0];
            const initialSurvey = (sortBy(initialCompany.surveys, ['name']) || [])[0];
            setValue('companyId', initialCompany?.companyId || '', { shouldValidate: true, shouldDirty: true });
            setValue('surveyId', initialSurvey?.id || '', { shouldValidate: true, shouldDirty: true });
            setValue('template', initialSurvey?.message || '');
            // set channels options
            const availableChannels = getFilteredByCompanyChannelsIds(initialCompany?.companyId, companies);
            const newChannelOptions = getChannelOptions({ out: true }, availableChannels, channels);
            setChannelOptions(newChannelOptions);
            // set channel
            const preselectedChannel = newChannelOptions.length > 0 ? newChannelOptions[0]?.value : '';
            setValue('channelId', preselectedChannel, { shouldValidate: true, shouldDirty: true });
            const initialChannel = channels.find(c => c.id === preselectedChannel || '');
            const initialPhone =
                initialChannel?.type === CHANNEL.WHATSAPP || initialChannel?.type === CHANNEL.SMS
                    ? COUNTRY_CODES[account?.locale]
                    : '';
            setValue('channelType', initialChannel?.type);
            setValue('phone', initialPhone, { shouldValidate: false, shouldDirty: true });
        }
    }, [channelsLoading, companies, channels.length]);

    useEffect(() => {
        if (isRefetchedAfterBrokenChannel.current) {
            // set channels options
            const availableChannels = getFilteredByCompanyChannelsIds(getValues('companyId'), companies);
            const newChannelOptions = getChannelOptions({ out: true }, availableChannels, channels);
            setChannelOptions(newChannelOptions);
            setValue('channelId', '', { shouldDirty: true });
        }
    }, [channels, companies]);

    const onSubmit = async () => {
        const message = document.getElementById('sms')
            ? document.getElementById('sms').innerText
            : document.getElementById('smsWithOut').innerText;
        const { companyId, userName, phone, surveyId, channelId, channelType } = getValues();

        const isEmail = phone?.match(/^[A-Z]/i) || phone?.includes('@');
        const email = isEmail ? phone : '';
        const phoneNumber = !isEmail ? phone : '';

        if (isPersonal) {
            const [firstName = '', lastName = ''] = userName.split(' ');
            try {
                await sendMessageService.sendMessage({
                    phoneNumber,
                    email,
                    message,
                    firstName,
                    lastName,
                    surveyId,
                    companyId,
                    channelId,
                });
                dispatch(alertActions.success(t('alertMessages.sendSuccess')));
                const phone =
                    channelType === CHANNEL.WHATSAPP || channelType === CHANNEL.SMS
                        ? COUNTRY_CODES[account?.locale]
                        : '';
                setValue('phone', phone, { shouldValidate: false, shouldDirty: true });
                setValue('userName', '');
            } catch (error) {
                if (error.delay) {
                    dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`, { delay: error.delay })));
                } else if (error.errorCode) {
                    dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
                    if (error.errorCode === 'channel_configuration_error') {
                        isRefetchedAfterBrokenChannel.current = true;
                        dispatch(channelsActions.get());
                    }
                } else {
                    if (process.env.REACT_APP_STAGE === 'prod') {
                        SentryHelper.push({ context: 'SendInvites personal', error });
                    }
                    dispatch(alertActions.error(t('alertMessages.sendFail')));
                }
            }
        } else {
            const preparedList = bulk.selected.map(item => {
                const isSendSms = item.phoneValid;
                const isSendEmail = !item.phoneValid && item.emailValid;
                return {
                    email: isSendEmail ? item.Email : '',
                    phoneNumber: isSendSms ? item.Mobile_Number : '',
                    message,
                    firstName: item.First_Name,
                    lastName: item.Last_Name,
                    surveyId,
                    companyId,
                    channelId,
                };
            });
            try {
                await sendMessageService.sendBulkMessages(preparedList);
                const message =
                    preparedList.length > 1
                        ? `${preparedList.length} ${t('SendInvites.messagesSent')}`
                        : t('SendInvites.messageSent');
                dispatch(alertActions.success(message));
            } catch (error) {
                if (error.errorCode) {
                    dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
                    if (error.errorCode === 'channel_configuration_error') {
                        isRefetchedAfterBrokenChannel.current = true;
                        dispatch(channelsActions.get());
                    }
                } else {
                    if (process.env.REACT_APP_STAGE === 'prod') {
                        SentryHelper({ context: 'SendInvites bulk', error });
                    }
                    dispatch(alertActions.error(t('alertMessages.sendFail')));
                }
            }
            setBulk({ list: [], selected: [] });
        }
    };

    const isDisabled = isSubmitting || !isValid || !isDirty || isSmsTooLong || (!bulk.selected.length && !isPersonal);

    return (
        <Drawer
            variant="persistent"
            anchor="right"
            open={isOpen}
            onClose={() => {
                reset();
                dispatch(sendInvitesUiActions.setOpen(false));
            }}
            title={t('SendInvites.sendSurveys')}
            primaryText={t('SendInvites.sendSurvey')}
            primaryDisabled={isDisabled}
            primaryAction={handleSubmit(onSubmit)}
        >
            <Controller
                control={control}
                name="companyId"
                render={({ field, fieldState: { error }, ref }) => (
                    <Select
                        {...field}
                        ref={ref}
                        onChange={({ target: { value } }) => {
                            setValue('companyId', value, { shouldValidate: true, shouldDirty: true });
                            const surveysOptions = getSurveysOptionsById(value, companies);
                            if (surveysOptions.length > 0) {
                                setValue(
                                    'template',
                                    getMessageSurveyContent(surveysOptions[0]?.value, value, companies),
                                );
                                setValue('surveyId', surveysOptions[0]?.value, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                            } else {
                                setValue('template', '');
                                setValue('surveyId', '', { shouldValidate: true, shouldDirty: true });
                            }
                            // set channels options
                            const availableChannels = getFilteredByCompanyChannelsIds(value, companies);
                            const newChannelOptions = getChannelOptions({ out: true }, availableChannels, channels);
                            setChannelOptions(newChannelOptions);
                            // set channel
                            const preselectedChannel = newChannelOptions.length > 0 ? newChannelOptions[0]?.value : '';
                            setValue('channelId', preselectedChannel, { shouldValidate: true, shouldDirty: true });
                            const channel = channels.find(c => c.id === preselectedChannel || '');
                            const phone =
                                channel?.type === CHANNEL.WHATSAPP || channel?.type === CHANNEL.SMS
                                    ? COUNTRY_CODES[account?.locale]
                                    : '';
                            setValue('channelType', channel?.type);
                            clearErrors('phone');
                            setValue('phone', phone, { shouldValidate: false, shouldDirty: true });
                            if (channelType !== channel?.type) {
                                setBulk({ list: [], selected: [] });
                            }
                        }}
                        options={companiesOptions}
                        required
                        label={t('common.location')}
                        fullWidth
                        style={{ marginBottom: '16px' }}
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                    />
                )}
            />
            <Controller
                control={control}
                name="surveyId"
                render={({ field, fieldState: { error }, ref }) => (
                    <Select
                        {...field}
                        ref={ref}
                        onChange={({ target: { value } }) => {
                            setValue('surveyId', value, { shouldValidate: true, shouldDirty: true });
                            setValue('template', getMessageSurveyContent(value, getValues('companyId'), companies));
                        }}
                        options={getSurveysOptionsById(getValues('companyId'), companies)}
                        required
                        label={t('common.survey')}
                        fullWidth
                        style={{ marginBottom: '16px' }}
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                    />
                )}
            />
            <Controller
                control={control}
                name="channelId"
                render={({ field, fieldState: { error }, ref }) => (
                    <Select
                        {...field}
                        ref={ref}
                        onChange={({ target: { value } }) => {
                            setValue('channelId', value, { shouldValidate: true, shouldDirty: true });
                            const channel = channels.find(c => c.id === value || '');
                            const phone =
                                channel?.type === CHANNEL.WHATSAPP || channel?.type === CHANNEL.SMS
                                    ? COUNTRY_CODES[account?.locale]
                                    : '';
                            setValue('channelType', channel?.type);
                            clearErrors('phone');
                            setValue('phone', phone, { shouldValidate: false, shouldDirty: true });
                            setBulk({ list: [], selected: [] });
                        }}
                        options={channelOptions}
                        required
                        label={t('common.channel')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                    />
                )}
            />
            {!isMobile && (
                <StyledRadioButtons
                    id="pl-radiobuttons-send-invites"
                    value={formType}
                    onChange={value => {
                        clearErrors('phone');
                        setValue('formType', value, { shouldValidate: true });
                        setValue('userName', '');
                        if (value === 1 && bulk.selected.length > 0) {
                            setBulk({ list: [], selected: [] });
                        }
                    }}
                    options={[
                        { value: FORM_TYPE.PERSONAL, label: t('SendInvites.personal') },
                        { value: FORM_TYPE.BULK, label: t('SendInvites.bulk') },
                    ]}
                />
            )}
            {isPersonal ? (
                <>
                    <Controller
                        control={control}
                        name="userName"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field }}
                                label={t('common.firstName')}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                sx={{ marginBottom: '16px' }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="phone"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field }}
                                label={channelType === CHANNEL.EMAIL ? t('common.email') : t('common.phoneNumber')}
                                fullWidth
                                required
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                sx={{ marginBottom: '16px' }}
                            />
                        )}
                    />
                </>
            ) : (
                <BulkForm locale={account?.locale} bulk={bulk} setBulk={setBulk} channelType={channelType} />
            )}
            <SmsTemplate
                smsContent={template || ''}
                label={t('common.message', { count: 1 })}
                setDisabled={content => setSmsTooLong(!!content)}
                userName={userName}
                location={findCompany(companies, getValues('companyId'))?.locationName}
                maxLength={0}
            />
        </Drawer>
    );
};

export default SendInvites;
