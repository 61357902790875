import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const dashboardService = {
    getDashboardAnalytics,
    getLeaderboardAnalytics,
    getRecommendations,
    updateRecommendations,
};

function getDashboardAnalytics() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/home-page/analytics`, requestOptions).then(handleResponse);
}

function getLeaderboardAnalytics(sorting, type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/home-page/leaderboard?sorting=${sorting}&type=${type}`, requestOptions).then(
        handleResponse,
    );
}

function getRecommendations() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/home-page/recommendations`, requestOptions).then(handleResponse);
}

function updateRecommendations(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/home-page/recommendations`, requestOptions).then(handleResponse);
}
