import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@components/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@components/Button';
import { useTranslation } from '@hooks/useTranslation';
import { alertActions } from '@actions';
import { CSVToArray } from '@helpers/csv-helper';
import { CHANNEL } from '@constants/channels';
import { validateBulkData } from '@helpers/send-invites';
import AddedContactsModal from './AddedContactsModal';
import DataVerificationModal from './DataVerificationModal';

const BulkForm = ({ locale, bulk, setBulk, channelType }) => {
    const [importedModalOpen, setImportedModalOpen] = useState(false);
    const [verificationModalOpen, setVerificationModalOpen] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const submitCSV = event => {
        const file = event.target.files[0];
        let fileReader = new FileReader();
        if (file.name.includes('csv')) {
            const processData = () => {
                const data = fileReader.result;
                const list = validateBulkData(CSVToArray(data), locale, channelType);
                if (!list || list.length === 0) {
                    dispatch(alertActions.error(t('validation.wrongFile')));
                } else {
                    setVerificationModalOpen(true);
                    setBulk(prev => ({ ...prev, list }));
                }
            };
            fileReader.onloadend = processData;
            fileReader.readAsText(file);
        }
        event.target.value = null;
    };

    return (
        <>
            {bulk.selected.length ? (
                <>
                    <Typography variant="caption" color="textSecondary">
                        <b>{`${bulk.selected.length}/1,000 `}</b>
                        {t('SendInvites.contactsAdded')}
                    </Typography>
                    <Typography
                        variant="caption"
                        onClick={() => setImportedModalOpen(true)}
                        sx={{ display: 'block', marginBottom: '12px', cursor: 'pointer', color: '#1F4C5C' }}
                    >
                        {t('SendInvites.viewContacts')}
                    </Typography>
                    <div style={{ display: 'flex', marginBottom: '40px' }}>
                        <input
                            accept=".csv"
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            type="file"
                            onChange={submitCSV}
                        />
                        <label
                            htmlFor="raised-button-file"
                            style={{ flex: 1, marginRight: '8px', display: 'flex', width: '100%' }}
                        >
                            <Button
                                type="button"
                                component="span"
                                variant="outlined"
                                sx={{ flex: 1, marginRight: '8px' }}
                            >
                                {t('buttons.replaceCSV')}
                            </Button>
                        </label>
                        <Button
                            variant="outlined"
                            sx={{ minWidth: 36, width: 36 }}
                            component="span"
                            onClick={() => setBulk({ list: [], selected: [] })}
                        >
                            <DeleteIcon fontSize="small" />
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <Typography
                        variant="caption"
                        sx={{ display: 'block', marginBottom: '12px', color: 'rgba(0, 0, 0, 0.6)' }}
                    >
                        {t('SendInvites.bulkDescription1')}
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{ display: 'block', marginBottom: '12px', color: 'rgba(0, 0, 0, 0.6)' }}
                    >
                        {t('SendInvites.bulkDescription2')}{' '}
                        <a
                            href={
                                channelType === CHANNEL.EMAIL
                                    ? '/pluspoint_import_email.csv'
                                    : '/pluspoint_import_mobile.csv'
                            }
                            style={{ color: '#1F4C5C' }}
                        >
                            {t('SendInvites.bulkFileLink')}
                        </a>
                    </Typography>
                    <div style={{ marginBottom: '40px' }}>
                        <input
                            accept=".csv"
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            type="file"
                            onChange={submitCSV}
                        />
                        <label htmlFor="raised-button-file">
                            <Button type="button" component="span" fullWidth variant="outlined">
                                {t('buttons.uploadCSVFile')}
                            </Button>
                        </label>
                    </div>
                </>
            )}
            {importedModalOpen && (
                <AddedContactsModal
                    isOpen={importedModalOpen}
                    onClose={() => setImportedModalOpen(false)}
                    contacts={bulk.selected}
                    channelType={channelType}
                />
            )}
            {verificationModalOpen && (
                <DataVerificationModal
                    data={bulk}
                    setData={setBulk}
                    channelType={channelType}
                    isOpen={verificationModalOpen}
                    onClose={() => setVerificationModalOpen(false)}
                />
            )}
        </>
    );
};

export default BulkForm;
