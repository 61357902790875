import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Tooltip from '@components/Tooltip';

const CssTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: '#1F4C5C',
    },
});

const CssTab = styled(Tab)({
    fontFamily: 'Rubik, sans-serif',
    justifyContent: 'flex-end',
    padding: '12px 16px 16px 16px',
    letterSpacing: '1.25px',
    fontSize: '0.875rem',
    minHeight: '48px',
    '&.Mui-selected': {
        color: '#1F4C5C',
    },
});

const CssCounter = styled('div')({
    display: 'flex',
    padding: '3px 6px',
    marginLeft: '5px',
    alignItems: 'flex-start',
    borderRadius: '4px',
    background: '#8FA6AE',
    color: '#fff',
    fontSize: '10px',
    lineHeight: '12px',
});

const CounterIcon = ({ value }) => {
    return (
        <CssCounter>
            <span>{value}</span>
        </CssCounter>
    );
};

export const NavTabs = ({ tabLinks = [], onTabClick, currentTabIndex = 0, id, withCounter, withLink = true }) => {
    const linkProps = withLink ? { component: Link } : {};

    return (
        <Box sx={{ width: '100%', mb: '24px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CssTabs
                    id={id}
                    value={currentTabIndex}
                    aria-label="nav tabs"
                    variant="scrollable"
                    scrollButtons={false}
                >
                    {tabLinks.map(({ label, value, disabled = false, disabledTooltip, counter = null }) => {
                        return disabledTooltip ? (
                            <Tooltip placement="top" title={disabledTooltip} key={label}>
                                <CssTab
                                    style={{ pointerEvents: 'auto' }}
                                    label={label}
                                    disabled
                                    {...{ ...linkProps, to: value }}
                                />
                            </Tooltip>
                        ) : (
                            <CssTab
                                key={label}
                                label={label}
                                onClick={() => onTabClick(value)}
                                disabled={disabled}
                                icon={withCounter && <CounterIcon value={counter} />}
                                iconPosition="end"
                                {...{ ...linkProps, to: value }}
                            />
                        );
                    })}
                </CssTabs>
            </Box>
        </Box>
    );
};

NavTabs.propTypes = {
    tabLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ),
    onTabClick: PropTypes.func,
    withCounter: PropTypes.bool,
    withLink: PropTypes.bool,
};
