import { memo, useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { analyticsActions } from '@actions';
import { subtractYears } from '@helpers/dates';
import { LocationIntegrationName } from '@constants/integrations';
import { GroupByFilter } from '@constants/analytics';
import { getPlatformsOptions } from '@helpers/social-posts';
import { getLocationLabels } from '@helpers/locationLabels';
import { PrintButton } from '../Common/PrintButton';
import FiltersPanel from './FiltersPanel';
import SocialPostsTable from './SocialPostsTable';
import SocialRates from './SocialRates';
import SocialImpressionsTrend from './SocialImpressionsTrend';
import SocialReachTrend from './SocialReachTrend';
import SocialEngagementsTrend from './SocialEngagementsTrend';
import { useTranslation } from '@hooks/useTranslation';
import { AnalyticsContainer, HeaderWrapper } from '../styles';

const SocialPosts = memo(() => {
    const { t } = useTranslation();
    const firstRender = useRef(true);

    const [filter, setFilter] = useState({
        labels: [],
        companies: [],
        platforms: [],
        timeframe: {
            start: subtractYears(new Date(), 1),
            end: new Date(),
        },
        grouping: GroupByFilter.Months,
    });
    const [filterNumber, setFilterNumber] = useState(0);
    const [filterOpened, setFiltersOpened] = useState(false);

    const companies = useSelector(state => state.account?.account?.companies || []);
    const { location: labels, locationLoading: labelsLoading } = useSelector(state => state.labels);
    const { socialPostsData, socialPostsLoading } = useSelector(state => state.analytics, shallowEqual);
    const language = useSelector(state => state.authentication.user?.language, shallowEqual);
    const dispatch = useDispatch();

    const availableCompanies = useMemo(
        () =>
            companies.filter(c =>
                c.profiles?.some(
                    p => p.type === LocationIntegrationName.Google || p.type === LocationIntegrationName.Facebook,
                ),
            ),
        [companies],
    );
    const companiesConnectedIds = useMemo(() => availableCompanies.map(c => c.companyId), [availableCompanies]);
    const { platformsConnectedIds } = useMemo(
        () => getPlatformsOptions(companies, companiesConnectedIds),
        [companies, companiesConnectedIds],
    );

    const availableLabels = useMemo(
        () => getLocationLabels(labels, availableCompanies, t),
        [labels, availableCompanies],
    );

    useEffect(() => {
        if (companies.length && !labelsLoading) {
            setFilter(prev => ({
                ...prev,
                companies: companiesConnectedIds,
                platforms: platformsConnectedIds,
                labels: availableLabels.map(item => item.id),
            }));
        }
    }, [companies, labelsLoading, dispatch]);

    useEffect(() => {
        if (filter.companies.length) {
            if (firstRender.current) {
                onApplyFilters();
                firstRender.current = false;
            }
        }
    }, [filter, dispatch]);

    const onApplyFilters = () => {
        dispatch(
            analyticsActions.getAnalyticsSocialPosts({
                companies: filter.companies,
                platforms: filter.platforms,
                from: filter.timeframe.start ? format(new Date(filter.timeframe.start), 'yyyy-MM-dd') : null,
                to: filter.timeframe.end ? format(new Date(filter.timeframe.end), 'yyyy-MM-dd') : null,
                grouping: filter.grouping,
            }),
        );
    };

    return (
        <>
            <HeaderWrapper>
                <FiltersPanel
                    setFiltersOpened={setFiltersOpened}
                    filtersNumber={filterNumber}
                    filterOpened={filterOpened}
                    companies={availableCompanies}
                    labels={availableLabels}
                    filter={filter}
                    setFilter={setFilter}
                    type="social-posts"
                    setFilterNumber={setFilterNumber}
                    onApplyFilters={onApplyFilters}
                />
                <PrintButton />
            </HeaderWrapper>
            <AnalyticsContainer id="pl-content-analytics-social-posts">
                <SocialRates data={socialPostsData.rates} language={language} />
                <SocialImpressionsTrend data={socialPostsData.impressionsTrendData} language={language} />
                <SocialReachTrend data={socialPostsData.reachTrendData} language={language} />
                <SocialEngagementsTrend data={socialPostsData.engagementsTrendData} language={language} />
                <SocialPostsTable data={socialPostsData.tableData} isLoading={socialPostsLoading} />
            </AnalyticsContainer>
        </>
    );
});

SocialPosts.displayName = 'SocialPosts';

export default SocialPosts;
