import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import { channelsActions, conversationsActions, inboxActions, inboxTemplatesActions, labelsActions } from '@actions';
import { socketService } from '@services';
import { useQueryParams } from '@hooks/useQueryParams';
import { CONVERSATION_STATUS, ASSIGNED_STATUS, DEFAULT_START, DEFAULT_TAKE } from '@constants/conversations';
import PageHeader from '@features/PageHeader';
import SubscribeToMessagesSnackbar from '@features/SubscribeToMessagesSnackbar';
import Button from '@components/Button';
import { useTranslation } from '@hooks/useTranslation';
import { CHANNEL } from '@constants/channels';
import { LABEL_TYPE } from '@constants/labels';
import { checkTwilioTwoWaySms, checkWhatsAppStatus } from '@helpers/channels';
import { InboxEmptyIcon } from 'assets/images/icons';
import ContactInfo from './ContactInfo';
import Dialogs from './Dialogs';
import Inbox from './Inbox';

export const PageGrid = styled('div', {
    shouldForwardProp: prop => prop !== 'contactIsOpen',
})(({ contactIsOpen }) => ({
    height: 'calc(var(--100vh) - 85px)',
    display: 'grid',
    gridTemplateColumns: contactIsOpen ? '360px calc(100% - 752px)' : '360px 1fr',
    transition: 'width 0.4s ease-in-out',
    gridTemplateRows: '1fr',
    gridGap: '0 16px',
    '@media (max-width: 1480px)': {
        gridTemplateColumns: contactIsOpen ? '270px calc(100% - 572px)' : '360px 1fr',
    },
    '@media (max-width: 1280px)': {
        gridTemplateColumns: contactIsOpen ? '200px calc(100% - 416px)' : '360px 1fr',
        gridGap: '0 8px',
    },
    '@media (max-width: 768px)': {
        height: '100%',
        gridTemplateColumns: '100%',
    },
}));

const Container = styled('div')(
    () => `
    @media (max-width: 1024px) {
        padding: 16px 16px 0 16px;
        height: calc(var(--100vh) - 108px);
    }
`,
);

const InboxPage = () => {
    const params = useQueryParams();
    const queryConversationStatus = params.get('conversationStatus');
    const queryConversationId = params.get('conversationId');
    const queryConversationAssignment = params.get('assignment');

    const [filter, setFilter] = useState({
        channels: [],
        status: queryConversationStatus || CONVERSATION_STATUS.OPEN,
        conversationId: queryConversationId || null,
        assignment: queryConversationAssignment ? +queryConversationAssignment : ASSIGNED_STATUS.MINE,
        assignedUsers: [],
    });
    // TODO: put in redux because it uses in ContactInfo, EditContactModal, Inbox, Dialogs
    const [selectedConversation, setSelectedConversation] = useState(null);

    const { data: messages } = useSelector(state => state.inbox);
    const { data: conversations, archived, archivedLoading } = useSelector(state => state.conversations);
    const { data: channels, loading: channelsLoading } = useSelector(state => state.channels);
    const currentUser = useSelector(state => state.authentication.user);
    const users = useSelector(state => state.account?.account?.users || []);
    const isAdmin = useSelector(state => {
        if (!state.authentication?.user?.id) return false;
        return state.authentication?.user?.roles?.filter(u => u.role === 0)?.length > 0;
    });
    const { contactOpen } = useSelector(state => state.contacts);
    const isMobile = useMediaQuery('@media (max-width: 768px)');
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const messagesRef = useRef(null);
    const conversationsRef = useRef(null);
    const selectedConversationRef = useRef(null);
    const filterRef = useRef(null);
    const firstRenderRef = useRef(true);

    useEffect(() => {
        /*
            It's necessary to prevent calling socketService.registerOnServerEvents callbacks
            multiple times. Each time useEffect() is triggered, it will call socketService.registerOnServerEvents,
            and all callbacks will be called the same number times. connection.off() method 
            removed each callback for event, so other subscribed components will not receive the event.
        */
        messagesRef.current = messages;
        conversationsRef.current = conversations;
        selectedConversationRef.current = selectedConversation;
        filterRef.current = filter;
    }, [messages, conversations, selectedConversation, filter]);

    useEffect(() => {
        const { channels: filterChannels, status, assignment, conversationId } = filterRef.current;

        if (!channelsLoading && !filterChannels.length && channels.length && !archivedLoading) {
            const filteredChannels = [
                ...channels
                    .filter(
                        c =>
                            [CHANNEL.LIVE_CHAT, CHANNEL.FACEBOOK, CHANNEL.INSTAGRAM].includes(c.type) ||
                            checkTwilioTwoWaySms(c) ||
                            (checkWhatsAppStatus(c) && c.companies?.length > 0),
                    )
                    .map(c => c.id),
                ...(archived > 0 ? [CHANNEL.ARCHIVED] : []),
            ];
            // set to ALL if there is conversationId to let find conversation in the feed

            const assignedUsers = conversationId
                ? [...users.map(u => u.id), null]
                : assignment === ASSIGNED_STATUS.MINE
                ? [currentUser?.id]
                : [null];
            const updatedAssignment = conversationId ? ASSIGNED_STATUS.ALL : assignment;

            setFilter({
                ...filterRef.current,
                channels: filteredChannels,
                assignment: updatedAssignment,
                assignedUsers,
            });
            dispatch(conversationsActions.getCounters({ status, channels: filteredChannels, assignedUsers }));
            dispatch(
                conversationsActions.get(
                    { ...filterRef.current, channels: filteredChannels, assignment: updatedAssignment },
                    DEFAULT_START,
                    DEFAULT_TAKE,
                ),
            );
            firstRenderRef.current = false;
        }
    }, [channels, channelsLoading, archivedLoading]);

    useEffect(() => {
        // works only if redirect from activity or open page with params ONCE after initial render
        const { status, assignment } = filterRef.current;
        if (
            !firstRenderRef.current &&
            queryConversationStatus !== undefined &&
            queryConversationStatus !== null &&
            (queryConversationStatus !== status || assignment !== ASSIGNED_STATUS.ALL)
        ) {
            setFilter(prev => ({
                ...prev,
                status: queryConversationStatus,
                conversationId: queryConversationId,
                assignment: ASSIGNED_STATUS.ALL,
            }));
            dispatch(conversationsActions.getCounters({ ...filterRef.current, status: queryConversationStatus }));
            dispatch(
                conversationsActions.get(
                    {
                        ...filterRef.current,
                        status: queryConversationStatus,
                        conversationId: queryConversationId,
                        assignment: ASSIGNED_STATUS.ALL,
                        assignedUsers: [...users.map(u => u.id), null],
                    },
                    DEFAULT_START,
                    DEFAULT_TAKE,
                ),
            );
        }
    }, [queryConversationStatus, queryConversationId]);

    useEffect(() => {
        socketService.registerOnServerEvents('inbox-message-created', payload => {
            if (filterRef.current?.status === CONVERSATION_STATUS.OPEN && !!filterRef.current?.channels?.length) {
                const { conversation, message } = payload;
                const isFilterMine = filterRef.current?.assignment === ASSIGNED_STATUS.MINE;
                const isFilterAll = filterRef.current?.assignment === ASSIGNED_STATUS.ALL;
                const conversationExists = conversationsRef.current.find(c => c.id === conversation?.id);
                // if conversation doesn't exist -> add to the list and update counters
                if (!conversationExists) {
                    if (
                        (isFilterMine && conversation?.assignedUserId === currentUser?.id) ||
                        (!isFilterMine && !conversation?.assignedUserId) ||
                        isFilterAll
                    ) {
                        dispatch(conversationsActions.update([...conversationsRef.current, conversation]));
                    }
                    dispatch(conversationsActions.getCounters(filterRef.current));
                    return;
                }
                // if conversation exists but not opened -> update it
                const isSelectedConversation = selectedConversationRef.current?.id === conversation.id;
                if (!isSelectedConversation) {
                    dispatch(
                        conversationsActions.update([
                            ...conversationsRef.current.filter(c => c.id !== conversation?.id),
                            conversation,
                        ]),
                    );
                    return;
                }
                // if conversation is opened and unassigned -> change filter to MINE
                const isFilterUnassigned = filterRef.current?.assignment === ASSIGNED_STATUS.UNASSIGNED;
                if (isFilterUnassigned && conversation?.assignedUserId === currentUser?.id) {
                    setFilter({ ...filterRef.current, assignment: ASSIGNED_STATUS.MINE });
                    dispatch(inboxActions.update([...messagesRef.current, message]));
                    dispatch(
                        conversationsActions.get(
                            { ...filterRef.current, assignment: ASSIGNED_STATUS.MINE },
                            DEFAULT_START,
                            DEFAULT_TAKE,
                        ),
                    );
                    dispatch(conversationsActions.getCounters(filterRef.current));
                    return;
                }
                // default update
                dispatch(
                    conversationsActions.update([
                        ...conversationsRef.current.filter(c => c.id !== conversation?.id),
                        { ...conversation, read: true },
                    ]),
                );
                dispatch(inboxActions.update([...messagesRef.current, message]));
            }
        });
    }, [dispatch]);

    useEffect(() => {
        socketService.registerOnServerEvents('inbox-message-deleted', payload => {
            /* {
                conversationId: '',
                messageId: '',
                conversationDeleted: true,
                conversationLastMessageDeleted: true,
                conversationLastMessage: '',
                conversationLastMessageContentType: '',
            }    */
            const messagesCopy = messagesRef.current.slice();
            const conversationsCopy = conversationsRef.current.slice();
            dispatch(inboxActions.update(messagesCopy.filter(m => m.id !== payload?.messageId)));
            if (payload?.conversationDeleted) {
                dispatch(conversationsActions.update(conversationsCopy.filter(c => c.id !== payload?.conversationId)));
            } else if (payload?.conversationLastMessageDeleted) {
                const updatedConversationIndex = conversationsCopy.findIndex(c => c.id === payload?.conversationId);
                if (updatedConversationIndex !== -1) {
                    conversationsCopy[updatedConversationIndex].lastMessage = payload?.conversationLastMessage;
                    conversationsCopy[updatedConversationIndex].lastMessageContentType =
                        payload?.conversationLastMessageContentType;
                    dispatch(conversationsActions.update(conversationsCopy));
                }
            }
        });
    }, [dispatch]);

    useEffect(() => {
        dispatch(conversationsActions.getArchivedCounters());
        dispatch(channelsActions.get());
        dispatch(labelsActions.get(LABEL_TYPE.CONTACT));
        dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
        dispatch(inboxTemplatesActions.getAll());
        return () => {
            socketService.stopConnection('inbox-message-created');
            socketService.stopConnection('inbox-message-deleted');
            dispatch(conversationsActions.reset());
            dispatch(inboxActions.reset());
        };
    }, [dispatch]);

    const isUser = !isAdmin && !currentUser?.isAccountOwner && !currentUser?.isGlobalAdmin;

    return (
        <Container>
            {channels.length ? (
                <PageGrid contactIsOpen={contactOpen}>
                    {(!isMobile || !selectedConversation) && (
                        <Dialogs
                            isMobile={isMobile}
                            filter={filter}
                            setFilter={setFilter}
                            selectedConversation={selectedConversation}
                            setSelectedConversation={setSelectedConversation}
                        />
                    )}
                    {(!isMobile || selectedConversation) && (
                        <Inbox
                            isMobile={isMobile}
                            filter={filter}
                            setFilter={setFilter}
                            selectedConversation={selectedConversation}
                            setSelectedConversation={setSelectedConversation}
                        />
                    )}
                    {contactOpen && <ContactInfo setSelectedConversation={setSelectedConversation} />}
                </PageGrid>
            ) : !channelsLoading ? (
                <PageHeader
                    icon={<InboxEmptyIcon />}
                    title={t('InboxPage.emptyChannelsTitle')}
                    subtitle={t('InboxPage.emptyChannelsSubtitle')}
                >
                    {!isUser && (
                        <Button variant="contained" onClick={() => history.push('/account-settings/channels')}>
                            {t('InboxPage.emptyChannelsButton')}
                        </Button>
                    )}
                </PageHeader>
            ) : null}
            <SubscribeToMessagesSnackbar />
        </Container>
    );
};

export default InboxPage;
