import { useState } from 'react';
import { useSelector } from 'react-redux';
import UserTable from './UserTable';
import UserForm from './UserForm';

const VIEW = {
    TABLE: 'TABLE',
    FORM: 'FORM',
};

const UserManagement = () => {
    const [view, setView] = useState(VIEW.TABLE);
    const [selectedUser, setSelectedUser] = useState(null);

    const user = useSelector(state => state.authentication.user);
    const account = useSelector(state => state.account?.account);

    return (
        <>
            {view === VIEW.TABLE ? (
                <UserTable
                    currentUser={user}
                    account={account}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    view={VIEW}
                    setView={setView}
                />
            ) : (
                <UserForm
                    selectedUser={selectedUser}
                    setView={setView}
                    view={VIEW}
                    account={account}
                    currentUser={user}
                />
            )}
        </>
    );
};

export default UserManagement;
