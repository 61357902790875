import { useMemo, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { styled } from '@mui/material/styles';
import { NavTabs } from '@components/NavTabs';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { useQueryParams } from '@hooks/useQueryParams';
import { DEFAULT_TAKE, DEFAULT_START, ASSIGNED_STATUS } from '@constants/conversations';
import { CHANNEL } from '@constants/channels';
import { contactsActions, conversationsActions, inboxActions } from '@actions';
import Dialog from './Dialog';
import ConversationsItemSkeleton from './ConversationsItemSkeleton';
import FiltersPanel from '../FiltersPanel';

const ConversationsWrapper = styled('div')(({ isEmpty }) => ({
    willChange: 'scroll-position',
    transition: '300ms ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    height: 'calc(var(--100vh) - 154px)',
    ...(isEmpty && {
        justifyContent: 'center',
        alignItems: 'center',
    }),
    '@media (max-width: 1024px)': {
        height: 'calc(var(--100vh) - 197px)',
    },
}));

const TabsWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '& .MuiBox-root': {
        overflow: 'hidden',
        marginBottom: '8px',
    },
    '& .MuiTab-root': {
        minWidth: '60px',
        padding: '13px 10px 25px 10px',
    },
});

const Dialogs = ({ isMobile, filter, setFilter, selectedConversation, setSelectedConversation }) => {
    const language = useSelector(state => state.authentication.user?.language);
    const { data, hasMore, loading, requiresRefetch, counters } = useSelector(state => state.conversations);
    const currentUser = useSelector(state => state.authentication.user);
    const users = useSelector(state => state.account?.account?.users || []);

    const history = useHistory();
    const params = useQueryParams();
    const queryConversationId = params.get('conversationId');
    const queryConversationStatus = params.get('conversationStatus');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const paginationStart = useRef(DEFAULT_START);
    const conversationRef = useRef({});

    const availableTabs = useMemo(
        () => [
            { label: t('InboxPage.mine'), value: ASSIGNED_STATUS.MINE, counter: counters?.assigned || 0 },
            {
                label: t('InboxPage.unassigned'),
                value: ASSIGNED_STATUS.UNASSIGNED,
                counter: counters?.unassigned || 0,
            },
            { label: t('common.all'), value: ASSIGNED_STATUS.ALL, counter: counters?.all || 0 },
        ],
        [counters],
    );

    const assignedUsersOptions = [
        ...users.map(user => ({ label: `${user.firstName} ${user.lastName}`, value: user.id })),
        { value: null, label: t('common.unassigned') },
    ];

    const readSelectedConversation = conversation => {
        if (!conversation?.read) {
            const conversationsCopy = data.slice();
            const updatedConversationIndex = conversationsCopy.findIndex(c => c.id === conversation?.id);
            if (updatedConversationIndex !== -1) {
                conversationsCopy[updatedConversationIndex].read = true;
                dispatch(conversationsActions.update(conversationsCopy));
            }
        }
    };

    const fetchSelectedConversation = (data, searchedConversation) => {
        const conversation = searchedConversation || data[0];
        setSelectedConversation(conversation);
        readSelectedConversation(conversation);
        dispatch(inboxActions.getHistory(conversation?.id));
        dispatch(contactsActions.getContact(conversation?.contactId));
    };

    useEffect(() => {
        if (selectedConversation && conversationRef.current[selectedConversation?.id] && !isMobile && !loading) {
            const selectedRef = conversationRef.current[selectedConversation?.id];
            selectedRef.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        }
    }, [selectedConversation, loading, isMobile]);

    useEffect(() => {
        if (!queryConversationStatus) {
            if ((!isMobile && data.length > 0) || (isMobile && queryConversationId && data.length > 0)) {
                /* on desktop screen, if data was updated and there 
                is no selected conversation, select the first one
            */
                const selectedConversationInList = data.find(c => c.id === selectedConversation?.id);
                if (!selectedConversationInList || !selectedConversation) {
                    const searchedConversation = data.find(c => c.id === queryConversationId);
                    if (isMobile && !searchedConversation) {
                        return;
                    }
                    fetchSelectedConversation(data, searchedConversation);
                }
                /* if unnasigned conversation was assigned by replying a message and the assign tab was changed,
                select the updated conversation from the list
                 */
                if (
                    selectedConversationInList &&
                    selectedConversationInList.assignedUserId !== selectedConversation.assignedUserId
                ) {
                    setSelectedConversation(selectedConversationInList);
                    readSelectedConversation(selectedConversationInList);
                }
            }
        }
    }, [data, isMobile, selectedConversation, queryConversationId, queryConversationStatus]);

    useEffect(() => {
        if (queryConversationStatus && data.length > 0) {
            const searchedConversation = data.find(c => c.id === queryConversationId);
            if (selectedConversation?.id === queryConversationId) {
                return;
            }
            if (isMobile && !searchedConversation) {
                return;
            }
            if (searchedConversation) {
                setSelectedConversation(searchedConversation);
                readSelectedConversation(searchedConversation);
                dispatch(inboxActions.getHistory(searchedConversation?.id));
                dispatch(contactsActions.getContact(searchedConversation?.contactId));
            }
        }
    }, [data, selectedConversation, queryConversationStatus, queryConversationId]);

    useEffect(() => {
        // necessary to refetch data after merge contacts
        if (requiresRefetch) {
            dispatch(conversationsActions.get(filter, DEFAULT_START, DEFAULT_TAKE));
            paginationStart.current = DEFAULT_START;
        }
    }, [requiresRefetch, filter]);

    const loadMoreConversations = () => {
        if (!hasMore) return;
        const newPaginationStart =
            paginationStart.current === 0 ? DEFAULT_TAKE : paginationStart.current + DEFAULT_TAKE;
        paginationStart.current = newPaginationStart;
        dispatch(conversationsActions.loadMore(filter, newPaginationStart, DEFAULT_TAKE));
    };

    const onClickConversation = conversation => {
        if (selectedConversation?.id !== conversation.id) {
            dispatch(inboxActions.getHistory(conversation?.id));
            dispatch(contactsActions.getContact(conversation?.contactId));
            setSelectedConversation(conversation);
            readSelectedConversation(conversation);
            params.delete('conversationId');
            params.delete('conversationStatus');
            history.replace({ search: params.toString() });
        }
    };

    const onApplyFilters = () => {
        if (queryConversationId || queryConversationStatus) {
            history.replace();
        }
        paginationStart.current = DEFAULT_START;
        const selectedUsers = filter.assignedUsers;

        const oneSelected = selectedUsers.length === 1;
        let assignment;
        if (oneSelected) {
            if (selectedUsers[0] === currentUser?.id) {
                assignment = ASSIGNED_STATUS.MINE;
            } else if (selectedUsers[0] === null) {
                assignment = ASSIGNED_STATUS.UNASSIGNED;
            } else if (assignedUsersOptions.length === selectedUsers.length) {
                assignment = ASSIGNED_STATUS.ALL;
            } else {
                assignment = ASSIGNED_STATUS.ASSIGNED;
            }
        } else {
            if (assignedUsersOptions.length === selectedUsers.length) {
                assignment = ASSIGNED_STATUS.ALL;
            } else {
                assignment = ASSIGNED_STATUS.ASSIGNED;
            }
        }
        setFilter(prev => ({ ...prev, assignment }));
        dispatch(conversationsActions.getCounters({ ...filter, assignment }));
        dispatch(conversationsActions.get({ ...filter, assignment }, DEFAULT_START, DEFAULT_TAKE));
    };

    const onAssignTabClick = tabIndex => {
        if (queryConversationId || queryConversationStatus) {
            history.replace();
        }
        paginationStart.current = DEFAULT_START;
        let assignedUsers = [];
        if (tabIndex === ASSIGNED_STATUS.UNASSIGNED) {
            assignedUsers = [null];
        } else if (tabIndex === ASSIGNED_STATUS.MINE) {
            assignedUsers = [currentUser?.id];
        } else if (tabIndex === ASSIGNED_STATUS.ALL) {
            assignedUsers = [...users.map(user => user.id), null];
        }
        setFilter(prev => ({ ...prev, assignment: tabIndex, conversationId: null, assignedUsers }));
        dispatch(conversationsActions.get({ ...filter, assignment: tabIndex }, paginationStart.current, DEFAULT_TAKE));
    };

    const conversations = useMemo(
        () => data.sort((a, b) => new Date(b.lastActivityAt) - new Date(a.lastActivityAt)),
        [data],
    );

    const TabIndexRemap = {
        [ASSIGNED_STATUS.MINE]: 0,
        [ASSIGNED_STATUS.UNASSIGNED]: 1,
        [ASSIGNED_STATUS.ALL]: 2,
        [ASSIGNED_STATUS.ASSIGNED]: false,
    };

    return (
        <div>
            <TabsWrapper>
                <NavTabs
                    tabLinks={availableTabs}
                    onTabClick={onAssignTabClick}
                    currentTabIndex={TabIndexRemap[filter.assignment]}
                    withCounter
                    withLink={false}
                />
                <FiltersPanel
                    onApplyFilters={onApplyFilters}
                    filter={filter}
                    setFilter={setFilter}
                    assignedUsersOptions={assignedUsersOptions}
                />
            </TabsWrapper>
            <ConversationsWrapper id="pl-feed-conversations" isEmpty={!loading && conversations.length === 0}>
                {!loading && conversations.length === 0 && (
                    <Typography variant="body2">{t('InboxPage.noConversations')}</Typography>
                )}
                {loading && [...Array(5).keys()].map(item => <ConversationsItemSkeleton key={item} />)}
                {!loading && !!conversations.length && (
                    <InfiniteScroll
                        dataLength={conversations.length}
                        next={loadMoreConversations}
                        hasMore={hasMore}
                        loader={<ConversationsItemSkeleton />}
                        scrollableTarget="pl-feed-conversations"
                    >
                        {conversations.map(dialog => (
                            <Dialog
                                key={dialog.id}
                                conversationRef={ref => (conversationRef.current[dialog.id] = ref)}
                                data={dialog}
                                channelName={
                                    dialog?.channelId === CHANNEL.ARCHIVED ? t('common.archived') : dialog?.channelName
                                }
                                language={language}
                                onClick={() => onClickConversation(dialog)}
                                selected={dialog.id === selectedConversation?.id}
                                users={users}
                            />
                        ))}
                    </InfiniteScroll>
                )}
            </ConversationsWrapper>
        </div>
    );
};

export default Dialogs;
