import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';

export const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: '564px !important',
        '@media (max-width: 600px)': {
            height: 'var(--100vh) !important',
            width: '100% !important',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

export const Content = styled('div')({
    display: 'grid',
    rowGap: '8px',
});
