import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import Tooltip from '@components/Tooltip';
import { useThrottle } from '@hooks/useThrottle';
import { useCopyToClipboard } from '@hooks/useCopyToClipboard';
import { LocationIntegrationName } from '@constants/integrations';
import { CopyIcon } from 'assets/images/icons';
import { useTranslation } from '@hooks/useTranslation';
import { StyledSwitch } from './styles';

const StyledSlugInput = styled(Input)({
    '& .MuiInputLabel-root': {
        transform: 'translate(278px, 12px)',
    },
    '& .MuiOutlinedInput-notchedOutline legend span': {
        padding: 0,
    },
    '& .MuiInputBase-adornedStart fieldset legend': {
        padding: '0 7px',
    },
});

const CssStartAdornment = styled(InputAdornment)({
    margin: 0,
    '& .MuiTypography-root': {
        fontSize: '14px',
    },
});

const General = ({ isView, account, setPreview, location, setLocation, companyOptions }) => {
    const { t } = useTranslation();
    const { control, setValue, getValues } = useFormContext();
    const { onCopy } = useCopyToClipboard();
    const [onCopyClick] = useThrottle(value => onCopy(value), 1000);

    const googleConnected = useMemo(() => {
        return (
            account?.companies
                ?.find(c => c.companyId === location)
                ?.profiles.find(p => p.type === LocationIntegrationName.Google) !== undefined
        );
    }, [location, account.companies]);

    return (
        <>
            <div style={{ margin: '8px 0' }}>
                <Controller
                    control={control}
                    name="name"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('MicrositesPage.codeName')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                            disabled={isView}
                            sx={{ marginBottom: '8px' }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="companyId"
                    render={({ field, ref }) => (
                        <Select
                            {...field}
                            onChange={e => {
                                field.onChange(e.target.value);
                                setLocation(e.target.value);
                                setPreview(prev => ({ ...prev, companyId: e.target.value }));
                            }}
                            ref={ref}
                            label={t('common.location')}
                            fullWidth
                            options={companyOptions}
                            disabled={isView}
                            sx={{ marginBottom: '8px' }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="slug"
                    render={({ field, fieldState: { error }, ref }) => (
                        <StyledSlugInput
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('common.url')}
                            startAdornment={
                                <CssStartAdornment position="start">
                                    {`${process.env.REACT_APP_SURVEY_DOMAIN}/`}
                                </CssStartAdornment>
                            }
                            onAddonClick={() =>
                                onCopyClick(`${process.env.REACT_APP_SURVEY_DOMAIN}/${getValues('slug')}`)
                            }
                            addonComponent={<CopyIcon style={{ fill: 'rgba(0, 0, 0, 0.6)' }} />}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                            disabled={isView}
                        />
                    )}
                />
            </div>
            {!googleConnected ? (
                <Tooltip placement="top" title={t('MicrositesPage.updateGoogleTooltip')}>
                    <span>
                        <StyledSwitch
                            checked={getValues('updateGoogleWebsite')}
                            label={t('MicrositesPage.updateGoogle')}
                            disabled
                        />
                    </span>
                </Tooltip>
            ) : (
                <Controller
                    control={control}
                    name="updateGoogleWebsite"
                    render={({ field, ref }) => (
                        <StyledSwitch
                            {...field}
                            ref={ref}
                            checked={getValues('updateGoogleWebsite')}
                            handleChange={event => {
                                setValue('updateGoogleWebsite', event.target.checked);
                                setPreview(prev => ({ ...prev, updateGoogleWebsite: event.target.checked }));
                            }}
                            label={t('MicrositesPage.updateGoogle')}
                        />
                    )}
                />
            )}
        </>
    );
};

export default General;
