import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from '@hooks/useTranslation';
import MetricItem from '../Common/MetricItem';

export const MetricGrid = styled('div')(() => ({
    display: 'grid',
    gridGap: 16,
    gridTemplateAreas: '"metric1 metric2 metric3 metric4 metric5"',
    gridAutoColumns: 'minmax(205px, auto) minmax(205px, auto) minmax(205px, auto) minmax(205px, auto)',
    '@media (max-width: 1439px)': {
        gridTemplateColumns: 'calc(33% - 8px) calc(33% - 8px) calc(33% - 8px)',
        gridTemplateAreas: '"metric1 metric2 metric3" "metric4 metric5 metric6"',
    },
    '@media (max-width: 600px)': {
        gridTemplateColumns: '100%',
        gridTemplateAreas: '"metric1" "metric2" "metric3" "metric4" "metric5" ',
    },
    marginBottom: 16,
}));

const SocialRates = memo(({ data }) => {
    const { t } = useTranslation();

    return (
        <MetricGrid>
            <MetricItem
                title={t('AnalyticsSocialPosts.impressions')}
                data={{ metric: data?.impressions, delta: data?.impressionsDelta }}
                styles={{ gridArea: 'metric1' }}
                withPercentageDelta
            />
            <MetricItem
                title={t('AnalyticsSocialPosts.reach')}
                data={{ metric: data?.reach, delta: data?.reachDelta }}
                styles={{ gridArea: 'metric2' }}
                withPercentageDelta
            />
            <MetricItem
                title={t('AnalyticsSocialPosts.likes')}
                data={{ metric: data?.likes, delta: data?.likesDelta }}
                styles={{ gridArea: 'metric3' }}
                withPercentageDelta
            />
            <MetricItem
                title={t('AnalyticsSocialPosts.comments')}
                data={{ metric: data?.comments, delta: data?.commentsDelta }}
                styles={{ gridArea: 'metric4' }}
                withPercentageDelta
            />
            <MetricItem
                title={t('AnalyticsSocialPosts.shares')}
                data={{ metric: data?.shares, delta: data?.sharesDelta }}
                styles={{ gridArea: 'metric5' }}
                withPercentageDelta
            />
        </MetricGrid>
    );
});

SocialRates.displayName = 'SocialRates';

export default SocialRates;
