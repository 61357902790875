import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE } from '@constants/language';
import translationsEngGB from '@i18n/en-GB.json';
import translationsUA from '@i18n/ua.json';
import translationsPT from '@i18n/pt.json';
import translationsES from '@i18n/es.json';

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: LANGUAGE.ENGLISH,
        detection: {
            order: ['querystring', 'localStorage'],
            lookupQuerystring: 'lng',
            lookupLocalStorage: 'i18nextLng',
        },
        interpolation: {
            escapeValue: false,
        },
        resources: {
            [LANGUAGE.ENGLISH]: {
                translation: translationsEngGB,
            },
            [LANGUAGE.UKRAINIAN]: {
                translation: translationsUA,
            },
            [LANGUAGE.PORTUGUESE]: {
                translation: translationsPT,
            },
            [LANGUAGE.SPANISH]: {
                translation: translationsES,
            },
            [LANGUAGE.PORTUGUESE_BRAZIL]: {
                translation: translationsPT,
            },
        },
    });

export default i18next;
