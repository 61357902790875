import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import equal from 'fast-deep-equal';
import { format, parseISO } from 'date-fns';
import TableContainer from '@mui/material/TableContainer';
import { Table } from '@components/Table';
import { Typography } from '@components/Typography';
import { Select } from '@components/Select';
import { useTranslation } from '@hooks/useTranslation';
import { LocationIntegrationName, LocationIntegrationType } from '@constants/integrations';
import { COMPANY_STATUS } from '@constants/companies';
import { SyncDiff } from '@constants/profiles';
import { profilesService } from '@services';
import { accountActions, alertActions } from '@actions';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { formatPhoneNumber } from '@helpers/phone';
import { Modal, Image, Text, CellWithSelect } from './styles';

const GetTableHeader = platform => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('ProfilesPage.field')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography align="left" variant="subtitle2">
                    Pluspoint
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {platform}
                </Typography>
            ),
        },
        {
            key: 3,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.category', { count: 1 })}
                </Typography>
            ),
        },
        {
            key: 4,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.action')}
                </Typography>
            ),
        },
    ];
};

const GetTableBody = (data, platform, selectedData, setSelectedData, fbConnected) => {
    const { t } = useTranslation();
    return data.map(item => {
        const isFbOrInstaLink = item.action === SyncDiff.Facebook || item.action === SyncDiff.Instagram;
        const isGoogle = platform === LocationIntegrationType[LocationIntegrationName.Google];
        return [
            {
                component: (
                    <Typography variant="body2" align="left">
                        {item.field}
                    </Typography>
                ),
            },
            {
                component: <div style={{ minWidth: '200px', padding: '6px 0' }}>{item.plsp}</div>,
            },
            {
                component: <div style={{ minWidth: '200px', padding: '6px 0' }}>{item.platform}</div>,
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {item.category}
                    </Typography>
                ),
            },
            {
                component: (
                    <CellWithSelect>
                        <Select
                            value={selectedData[item.action] === undefined ? '' : selectedData[item.action]}
                            onChange={e => setSelectedData(prev => ({ ...prev, [item.action]: e.target.value }))}
                            label={t('ProfilesPage.useSource')}
                            fullWidth
                            options={[
                                { value: ORIGIN.LOCAL, label: 'Pluspoint' },
                                ...(isFbOrInstaLink && isGoogle && fbConnected
                                    ? []
                                    : [{ value: ORIGIN.SYNC, label: platform }]),
                                { value: ORIGIN.IGNORE, label: t('ProfilesPage.ignore') },
                            ]}
                        />
                    </CellWithSelect>
                ),
            },
        ];
    });
};

const ORIGIN = { LOCAL: 0, SYNC: 1, IGNORE: 2 };

const ResolveModal = ({ isOpen, handleClose, selectedProfile, onRefetchData }) => {
    const [selectedData, setSelectedData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { data } = useSelector(state => state.profiles);
    const language = useSelector(state => state.authentication.user?.language);
    const locations = useSelector(state => state.account?.account?.companies || []);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const diff = data[selectedProfile.index]?.syncDiff;
        const ignoredDiff = data[selectedProfile.index]?.ignoredSyncFields;
        setSelectedData(
            diff.reduce((acc, cur) => ({ ...acc, [cur]: ignoredDiff.includes(cur) ? ORIGIN.IGNORE : undefined }), {}),
        );
    }, [selectedProfile.index]);

    const profile = data[selectedProfile.index];
    const plspData = profile?.locationData;
    const syncData = profile?.syncData;
    const fbConnected =
        profile?.locations?.length === 1 &&
        locations
            .find(l => l.companyId === profile?.locations[0])
            ?.profiles.some(p => p.type === LocationIntegrationName.Facebook);

    const category = {
        1: ['locationName'],
        2: ['status'],
        3: ['phoneNumber', 'phoneExtension'],
        4: ['email'],
        5: ['website'],
        6: ['hasBusinessAddress', 'latitude', 'longitude', 'addressLine1', 'addressLine2', 'city'],
        7: ['workingHours', 'workingHoursEnabled'],
        8: ['description'],
        9: ['openingDate'],
        10: ['logoUrl'],
        11: ['coverPhotoUrl'],
        12: ['socialMediaLinks.Facebook'],
        13: ['socialMediaLinks.Instagram'],
        14: ['socialMediaLinks.Youtube'],
        15: ['socialMediaLinks.X'],
        16: ['socialMediaLinks.LinkedIn'],
        17: ['socialMediaLinks.Tiktok'],
        18: ['socialMediaLinks.Pinterest'],
    };
    const workingDays = useMemo(() => {
        return [
            ...new Set([
                ...plspData.workingHours.map(({ day }) => day),
                ...syncData.workingHours.map(({ day }) => day),
            ]),
        ];
    }, [plspData, syncData]);

    const plslWorkingHours = plspData.workingHours.reduce((acc, { day, hours }) => ({ ...acc, [day]: hours }), {});
    const syncWorkingHours = syncData.workingHours.reduce((acc, { day, hours }) => ({ ...acc, [day]: hours }), {});

    const DAY_NAMES = {
        0: t('common.sunday'),
        1: t('common.monday'),
        2: t('common.tuesday'),
        3: t('common.wednesday'),
        4: t('common.thursday'),
        5: t('common.friday'),
        6: t('common.saturday'),
    };

    const API = {
        [LocationIntegrationName.Google]: profilesService.resolveConflictsGoogle,
        [LocationIntegrationName.Facebook]: profilesService.resolveConflictsFacebook,
    };

    const onSubmit = async () => {
        try {
            setIsSubmitting(true);

            const resolvedData = { ...plspData };
            for (const [cat, fields] of Object.entries(category)) {
                const categoryValue = selectedData[cat];

                if (categoryValue === ORIGIN.SYNC) {
                    fields.forEach(field => {
                        const fieldParts = field.split('.');

                        if (fieldParts.length === 1) {
                            resolvedData[field] = syncData[field];
                        } else if (fieldParts.length === 2) {
                            const [outerField, innerField] = fieldParts;
                            if (!resolvedData[outerField]) {
                                resolvedData[outerField] = {};
                            }
                            resolvedData[outerField][innerField] = syncData[outerField][innerField];
                        }
                    });
                }
            }
            await API[selectedProfile.platform](data[selectedProfile.index]?.profileId, {
                data: resolvedData,
                options: selectedData,
            });
            await dispatch(accountActions.get());
            handleClose();
            dispatch(alertActions.success(t('alertMessages.editSuccess')));
            onRefetchData();
        } catch (error) {
            if (error.errorCode === 'google_validation_failed' && error.reasons?.length > 0) {
                error.reasons.forEach(reason => {
                    dispatch(alertActions.error(reason));
                });
            } else if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.editFail')));
            }
        }
        setIsSubmitting(false);
    };

    const TABLE_BODY_BASE = [
        {
            field: t('SettingsCompanies.locationName'),
            plsp: (
                <Text variant="body2" align="left">
                    {plspData.locationName || '-'}
                </Text>
            ),
            platform: (
                <Text variant="body2" align="left" hasDiff={plspData.locationName !== syncData.locationName}>
                    {syncData.locationName || '-'}
                </Text>
            ),
            category: t('SettingsCompanies.generalInfo'),
            action: SyncDiff.LocationName,
        },
        {
            field: t('common.status'),
            plsp: (
                <Text variant="body2" align="left">
                    {plspData.status === COMPANY_STATUS.OPEN ? t('common.open') : t('common.closed')}
                </Text>
            ),
            platform: (
                <Text variant="body2" align="left" hasDiff={plspData.status !== syncData.status}>
                    {syncData.status === COMPANY_STATUS.OPEN ? t('common.open') : t('common.closed')}
                </Text>
            ),
            category: t('SettingsCompanies.generalInfo'),
            action: SyncDiff.Status,
        },
        {
            field: t('SettingsCompanies.primaryPhone'),
            plsp: (
                <Text variant="body2" align="left">
                    {formatPhoneNumber(plspData.phoneNumber) || '-'}
                    {selectedProfile.platform === LocationIntegrationName.Google &&
                        plspData.phoneExtension &&
                        ` ${t('SettingsCompanies.phoneExtension')} ${plspData.phoneExtension || '-'}`}
                </Text>
            ),
            platform: (
                <Text
                    variant="body2"
                    align="left"
                    hasDiff={
                        plspData.phoneNumber !== syncData.phoneNumber ||
                        (selectedProfile.platform === LocationIntegrationName.Google &&
                            plspData.phoneExtension !== syncData.phoneExtension)
                    }
                >
                    {formatPhoneNumber(syncData.phoneNumber) || '-'}
                    {selectedProfile.platform === LocationIntegrationName.Google &&
                        syncData.phoneExtension &&
                        ` ${t('SettingsCompanies.phoneExtension')} ${syncData.phoneExtension || '-'}`}
                </Text>
            ),
            category: t('SettingsCompanies.contact'),
            action: SyncDiff.PhoneNumber,
        },
        {
            field: t('common.email'),
            plsp: (
                <Text variant="body2" align="left">
                    {plspData.email || '-'}
                </Text>
            ),
            platform: (
                <Text variant="body2" align="left" hasDiff={plspData.email !== syncData.email}>
                    {syncData.email || '-'}
                </Text>
            ),
            category: t('SettingsCompanies.contact'),
            action: SyncDiff.Email,
        },
        {
            field: t('SettingsCompanies.website'),
            plsp: (
                <Text variant="body2" align="left" sx={{ wordBreak: 'break-all' }}>
                    {plspData.website || '-'}
                </Text>
            ),
            platform: (
                <Text
                    variant="body2"
                    align="left"
                    hasDiff={plspData.website !== syncData.website}
                    sx={{ wordBreak: 'break-all' }}
                >
                    {syncData.website || '-'}
                </Text>
            ),
            category: t('SettingsCompanies.contact'),
            action: SyncDiff.Website,
        },
        {
            field: t('SettingsCompanies.address'),
            plsp: plspData.hasBusinessAddress ? (
                <>
                    {selectedProfile.platform === LocationIntegrationName.Google && (
                        <Text variant="body2" align="left">
                            {t('common.latitude')}: {plspData.latitude ? Number(plspData.latitude)?.toFixed(6) : '-'}
                        </Text>
                    )}
                    {selectedProfile.platform === LocationIntegrationName.Google && (
                        <Text variant="body2" align="left">
                            {t('common.longitude')}: {plspData.longitude ? Number(plspData.longitude)?.toFixed(6) : '-'}
                        </Text>
                    )}
                    <Text variant="body2" align="left">
                        {selectedProfile.platform === LocationIntegrationName.Google &&
                            `${t('SettingsCompanies.addressLine1')}:`}{' '}
                        {plspData.addressLine1 || '-'}
                    </Text>
                    {selectedProfile.platform === LocationIntegrationName.Google && (
                        <Text variant="body2" align="left">
                            {t('SettingsCompanies.addressLine2')}: {plspData.addressLine2 || '-'}
                        </Text>
                    )}
                    {selectedProfile.platform === LocationIntegrationName.Google && (
                        <Text variant="body2" align="left">
                            {t('SettingsCompanies.city')}: {plspData.city || '-'}
                        </Text>
                    )}
                </>
            ) : (
                <Text variant="body2" align="left">
                    {t('ProfilesPage.noAddress')}
                </Text>
            ),
            platform: syncData.hasBusinessAddress ? (
                <>
                    {selectedProfile.platform === LocationIntegrationName.Google && (
                        <Text
                            variant="body2"
                            align="left"
                            hasDiff={syncData.latitude !== plspData.latitude || !plspData.hasBusinessAddress}
                        >
                            {t('common.latitude')}: {syncData.latitude ? Number(syncData.latitude)?.toFixed(6) : '-'}
                        </Text>
                    )}
                    {selectedProfile.platform === LocationIntegrationName.Google && (
                        <Text
                            variant="body2"
                            align="left"
                            hasDiff={syncData.longitude !== plspData.longitude || !plspData.hasBusinessAddress}
                        >
                            {t('common.longitude')}: {syncData.longitude ? Number(syncData.longitude)?.toFixed(6) : '-'}
                        </Text>
                    )}
                    <Text
                        variant="body2"
                        align="left"
                        hasDiff={syncData.addressLine1 !== plspData.addressLine1 || !plspData.hasBusinessAddress}
                    >
                        {selectedProfile.platform === LocationIntegrationName.Google &&
                            `${t('SettingsCompanies.addressLine1')}:`}{' '}
                        {syncData.addressLine1 || '-'}
                    </Text>
                    {selectedProfile.platform === LocationIntegrationName.Google && (
                        <Text
                            variant="body2"
                            align="left"
                            hasDiff={syncData.addressLine2 !== plspData.addressLine2 || !plspData.hasBusinessAddress}
                        >
                            {t('SettingsCompanies.addressLine2')}: {syncData.addressLine2 || '-'}
                        </Text>
                    )}
                    {selectedProfile.platform === LocationIntegrationName.Google && (
                        <Text
                            variant="body2"
                            align="left"
                            hasDiff={syncData.city !== plspData.city || !plspData.hasBusinessAddress}
                        >
                            {t('SettingsCompanies.city')}: {syncData.city || '-'}
                        </Text>
                    )}
                </>
            ) : (
                <Text variant="body2" align="left" hasDiff={plspData.hasBusinessAddress}>
                    {t('ProfilesPage.noAddress')}
                </Text>
            ),
            category: t('SettingsCompanies.address'),
            action: SyncDiff.Address,
        },
        {
            field: t('SettingsCompanies.businessHours'),
            plsp: !workingDays.length ? (
                <Text variant="body2" align="left">
                    -
                </Text>
            ) : (
                workingDays.map(day => (
                    <Text variant="body2" align="left" key={day} sx={{ display: 'flex', gap: '4px' }}>
                        <span>{DAY_NAMES[day]}: </span>
                        <span>
                            {plslWorkingHours[day]
                                ? plslWorkingHours[day].map((h, index) => (
                                      <span key={index} style={{ display: 'block' }}>
                                          {h.from}-{h.to}
                                      </span>
                                  ))
                                : '-'}
                        </span>
                    </Text>
                ))
            ),
            platform: !workingDays.length ? (
                <Text variant="body2" align="left">
                    -
                </Text>
            ) : (
                workingDays.map(day => (
                    <Text
                        key={day}
                        variant="body2"
                        sx={{ display: 'flex', gap: '4px' }}
                        hasDiff={!equal(plslWorkingHours[day], syncWorkingHours[day])}
                    >
                        <span>{DAY_NAMES[day]}: </span>
                        <span>
                            {syncWorkingHours[day]
                                ? syncWorkingHours[day].map((h, index) => (
                                      <span key={index} style={{ display: 'block' }}>
                                          {h.from}-{h.to}
                                      </span>
                                  ))
                                : '-'}
                        </span>
                    </Text>
                ))
            ),
            category: t('SettingsCompanies.businessHours'),
            action: SyncDiff.BusinessHours,
        },
        {
            field: t('Listings.description'),
            plsp: (
                <Text variant="body2" align="left">
                    {plspData.description || '-'}
                </Text>
            ),
            platform: (
                <Text variant="body2" align="left" hasDiff={plspData.description !== syncData.description}>
                    {syncData.description || '-'}
                </Text>
            ),
            category: t('SettingsCompanies.additionalInformation'),
            action: SyncDiff.Description,
        },
        {
            field: t('SettingsCompanies.openingDate'),
            plsp: (
                <Text variant="body2" align="left">
                    {plspData.openingDate
                        ? format(new Date(parseISO(plspData.openingDate)), 'dd MMM yyyy', {
                              locale: I18N_DATE_FORMAT[language],
                          })
                        : '-'}
                </Text>
            ),
            platform: (
                <Text variant="body2" align="left" hasDiff={plspData.openingDate !== syncData.openingDate}>
                    {syncData.openingDate
                        ? format(new Date(parseISO(syncData.openingDate)), 'dd MMM yyyy', {
                              locale: I18N_DATE_FORMAT[language],
                          })
                        : '-'}
                </Text>
            ),
            category: t('SettingsCompanies.additionalInformation'),
            action: SyncDiff.OpeningDate,
        },
        {
            field: t('common.logo'),
            plsp: !plspData.logoUrl ? (
                <Text variant="body2" align="left">
                    -
                </Text>
            ) : (
                <div style={{ display: 'flex' }}>
                    <Image referrerPolicy="no-referrer" src={plspData.logoUrl} alt="logo" />
                </div>
            ),
            platform: !syncData.logoUrl ? (
                <Text variant="body2" align="left">
                    -
                </Text>
            ) : (
                <div style={{ display: 'flex' }}>
                    <Image referrerPolicy="no-referrer" src={syncData.logoUrl} alt="logo" />
                </div>
            ),
            category: t('SettingsCompanies.appearance'),
            action: SyncDiff.Logo,
        },
        {
            field: t('SettingsCompanies.coverPhoto'),
            plsp: !plspData.coverPhotoUrl ? (
                <Text variant="body2" align="left">
                    -
                </Text>
            ) : (
                <div style={{ display: 'flex' }}>
                    <Image referrerPolicy="no-referrer" src={plspData.coverPhotoUrl} alt="logo" />
                </div>
            ),
            platform: !syncData.coverPhotoUrl ? (
                <Text variant="body2" align="left">
                    -
                </Text>
            ) : (
                <div style={{ display: 'flex' }}>
                    <Image referrerPolicy="no-referrer" src={syncData.coverPhotoUrl} alt="logo" />
                </div>
            ),
            category: t('SettingsCompanies.appearance'),
            action: SyncDiff.CoverPhoto,
        },
        {
            field: 'Facebook',
            plsp: (
                <Text variant="body2" align="left" sx={{ wordBreak: 'break-all' }}>
                    {plspData.socialMediaLinks?.Facebook || '-'}
                </Text>
            ),
            platform: (
                <Text
                    variant="body2"
                    align="left"
                    hasDiff={plspData.socialMediaLinks?.Facebook !== syncData.socialMediaLinks?.Facebook}
                    sx={{ wordBreak: 'break-all' }}
                >
                    {syncData.socialMediaLinks?.Facebook || '-'}
                </Text>
            ),
            category: t('SettingsCompanies.onlineProfiles'),
            action: SyncDiff.Facebook,
        },
        {
            field: 'Instagram',
            plsp: (
                <Text variant="body2" align="left" sx={{ wordBreak: 'break-all' }}>
                    {plspData.socialMediaLinks?.Instagram || '-'}
                </Text>
            ),
            platform: (
                <Text
                    variant="body2"
                    align="left"
                    hasDiff={plspData.socialMediaLinks?.Instagram !== syncData.socialMediaLinks?.Instagram}
                    sx={{ wordBreak: 'break-all' }}
                >
                    {syncData.socialMediaLinks?.Instagram || '-'}
                </Text>
            ),
            category: t('SettingsCompanies.onlineProfiles'),
            action: SyncDiff.Instagram,
        },
        {
            field: 'Youtube',
            plsp: (
                <Text variant="body2" align="left" sx={{ wordBreak: 'break-all' }}>
                    {plspData.socialMediaLinks?.Youtube || '-'}
                </Text>
            ),
            platform: (
                <Text
                    variant="body2"
                    align="left"
                    hasDiff={plspData.socialMediaLinks?.Youtube !== syncData.socialMediaLinks?.Youtube}
                    sx={{ wordBreak: 'break-all' }}
                >
                    {syncData.socialMediaLinks?.Youtube || '-'}
                </Text>
            ),
            category: t('SettingsCompanies.onlineProfiles'),
            action: SyncDiff.Youtube,
        },
        {
            field: 'X',
            plsp: (
                <Text variant="body2" align="left" sx={{ wordBreak: 'break-all' }}>
                    {plspData.socialMediaLinks?.X || '-'}
                </Text>
            ),
            platform: (
                <Text
                    variant="body2"
                    align="left"
                    hasDiff={plspData.socialMediaLinks?.X !== syncData.socialMediaLinks?.X}
                    sx={{ wordBreak: 'break-all' }}
                >
                    {syncData.socialMediaLinks?.X || '-'}
                </Text>
            ),
            category: t('SettingsCompanies.onlineProfiles'),
            action: SyncDiff.X,
        },
        {
            field: 'LinkedIn',
            plsp: (
                <Text variant="body2" align="left" sx={{ wordBreak: 'break-all' }}>
                    {plspData.socialMediaLinks?.LinkedIn || '-'}
                </Text>
            ),
            platform: (
                <Text
                    variant="body2"
                    align="left"
                    hasDiff={plspData.socialMediaLinks?.LinkedIn !== syncData.socialMediaLinks?.LinkedIn}
                    sx={{ wordBreak: 'break-all' }}
                >
                    {syncData.socialMediaLinks?.LinkedIn || '-'}
                </Text>
            ),
            category: t('SettingsCompanies.onlineProfiles'),
            action: SyncDiff.LinkedIn,
        },
        {
            field: 'Pinterest',
            plsp: (
                <Text variant="body2" align="left" sx={{ wordBreak: 'break-all' }}>
                    {plspData.socialMediaLinks?.Pinterest || '-'}
                </Text>
            ),
            platform: (
                <Text
                    variant="body2"
                    align="left"
                    hasDiff={plspData.socialMediaLinks?.Pinterest !== syncData.socialMediaLinks?.Pinterest}
                    sx={{ wordBreak: 'break-all' }}
                >
                    {syncData.socialMediaLinks?.Pinterest || '-'}
                </Text>
            ),
            category: t('SettingsCompanies.onlineProfiles'),
            action: SyncDiff.Pinterest,
        },
        {
            field: 'Tiktok',
            plsp: (
                <Text variant="body2" align="left" sx={{ wordBreak: 'break-all' }}>
                    {plspData.socialMediaLinks?.Tiktok || '-'}
                </Text>
            ),
            platform: (
                <Text
                    variant="body2"
                    align="left"
                    hasDiff={plspData.socialMediaLinks?.Tiktok !== syncData.socialMediaLinks?.Tiktok}
                    sx={{ wordBreak: 'break-all' }}
                >
                    {syncData.socialMediaLinks?.Tiktok || '-'}
                </Text>
            ),
            category: t('SettingsCompanies.onlineProfiles'),
            action: SyncDiff.Tiktok,
        },
    ];

    const FILTERED_BODY = TABLE_BODY_BASE.filter(item => data[selectedProfile.index]?.syncDiff.includes(item.action));

    return (
        <Modal
            isOpen={isOpen}
            handleClose={handleClose}
            title={t('ProfilesPage.resolveTitle')}
            subtitle={
                <Typography variant="caption" color="textSecondary">
                    {t('ProfilesPage.resolveSubtitle')}
                </Typography>
            }
            onPrimaryAction={onSubmit}
            primaryActionText={t('buttons.save')}
            onSecondaryAction={handleClose}
            secondaryActionText={t('buttons.cancel')}
            primaryActionDisabled={isSubmitting || !Object.values(selectedData).every(value => value !== undefined)}
        >
            <TableContainer>
                <Table
                    withHover
                    columnNames={GetTableHeader(LocationIntegrationType[profile?.platform])}
                    rows={GetTableBody(
                        FILTERED_BODY,
                        LocationIntegrationType[profile?.platform],
                        selectedData,
                        setSelectedData,
                        fbConnected,
                    )}
                />
            </TableContainer>
        </Modal>
    );
};

export default ResolveModal;
