import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@components/Button';
import { AuthLayout } from '@features/AuthLayout';
import { useTranslation } from '@hooks/useTranslation';
import { InternalLink, ExternalLink } from '@components/Link';
import { AuthProgress } from '@components/AuthProgress';
import { Input } from '@components/Input';
import { Typography } from '@components/Typography';
import GoogleButtonAuth from '@features/GoogleButtonAuth';
import { regexAnyCharAtLeastOneNumberAndLetter } from '@constants/regex';
import GTMHelper from '@helpers/gtm';
import { userService } from '@services';
import { alertActions, userActions } from '@actions';

const Form = styled(Paper)({
    marginBottom: '24px',
    padding: '24px',
    width: 400,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
        padding: '16px',
        width: '100%',
    },
});

const Overlay = styled('div')({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255,255,255,0.6)',
    zIndex: 2,
});

const TnC = styled(Typography)({
    width: 352,
    textAlign: 'center',
    '@media (max-width: 600px)': {
        width: '100%',
    },
});

const ProgressGoogleWrapper = styled('div')({
    position: 'absolute',
    zIndex: 3,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    bottom: 52,
    top: 0,
    background: 'rgba(255, 255, 255, .7)',
    '& .MuiCircularProgress-root': {
        color: '#416774',
        width: '58px !important',
        height: '58px !important',
        display: 'block',
        margin: '0 auto 24px',
    },
});

const ResetPasswordLink = styled(InternalLink)({
    width: '100%',
    paddingRight: 16,
    marginBottom: 16,
    display: 'block',
});

const LoginPage = () => {
    const { t, i18n } = useTranslation();

    const [googleAuthLoading, setGoogleAuthLoading] = useState(false);
    const [isEmailFocused, setEmailFocused] = useState(false);

    const accountMsg = useSelector(state => state.account?.message);
    const dispatch = useDispatch();

    const LoginFormSchema = yup.object().shape({
        email: yup.string().required('validation.required').email('validation.wrongEmailFormat'),
        password: yup
            .string()
            .min(8, 'validation.minPasswordLength')
            .matches(regexAnyCharAtLeastOneNumberAndLetter, 'validation.wrongPasswordFormat')
            .required('validation.required'),
    });

    const resolver = useYupValidationResolver(LoginFormSchema);

    const {
        getValues,
        handleSubmit,
        setValue,
        control,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver: resolver,
        defaultValues: {
            email: '',
            password: '',
        },
    });

    useEffect(() => {
        if (accountMsg) {
            dispatch(alertActions.error(accountMsg));
        }
    }, [accountMsg, dispatch]);

    const onSubmit = async () => {
        const { email, password } = getValues();
        try {
            const user = await userService.login(email, password);
            GTMHelper.update(user);
            dispatch(userActions.setUser(user));
        } catch (error) {
            dispatch(userActions.setUserFail());
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
    };

    const getSignupLink = () => {
        switch (i18n.language) {
            case 'ES':
                return 'https://www.pluspoint.io/es/signup';
            case 'PT':
                return 'https://www.pluspoint.io/pt/signup';
            default:
                return 'https://www.pluspoint.io/signup';
        }
    };

    return (
        <AuthLayout>
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {googleAuthLoading && (
                    <ProgressGoogleWrapper>
                        <CircularProgress />
                    </ProgressGoogleWrapper>
                )}
                {isSubmitting && <AuthProgress />}
                <Form elevation={24}>
                    {isSubmitting && <Overlay />}
                    <Typography variant="h5" sx={{ marginBottom: '8px', color: 'rgba(0, 0, 0, .87)' }}>
                        {t('LoginPage.title')}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginBottom: '24px' }}>
                        {t('LoginPage.description')}
                    </Typography>
                    <GoogleButtonAuth
                        language={i18n.language}
                        text={t('LoginPage.signinWithGoogle')}
                        path="login"
                        action="signin"
                        onClickCallback={setGoogleAuthLoading}
                    />
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '16px' }}>
                        <span style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)', width: '100%' }} />
                        <Typography variant="caption" color="textSecondary" style={{ padding: '0 16px' }}>
                            {t('common.or')}
                        </Typography>
                        <span style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)', width: '100%' }} />
                    </div>
                    <Controller
                        control={control}
                        name="email"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, type: 'email' }}
                                onAddonClick={() => setValue('email', '')}
                                addonComponent={isEmailFocused ? <CancelIcon style={{ fontSize: 16 }} /> : null}
                                label={t('common.emailAddress')}
                                fullWidth
                                sx={{ marginBottom: '16px' }}
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                onMouseEnter={() => setEmailFocused(true)}
                                onMouseLeave={() => setEmailFocused(false)}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="password"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field }}
                                type="password"
                                label={t('common.password')}
                                sx={{ marginBottom: '16px' }}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                            />
                        )}
                    />
                    <ResetPasswordLink to="/forgot-password" variant="caption" align="right" underline="hover">
                        {t('LoginPage.forgotPassword')}
                    </ResetPasswordLink>
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        fullWidth
                        sx={{ marginBottom: '16px' }}
                        disabled={isSubmitting || !isValid || !isDirty}
                    >
                        {t('buttons.logIn')}
                    </Button>
                    <TnC variant="caption">
                        <Trans t={t} i18nKey="LoginPage.notRegistered">
                            <ExternalLink
                                href={getSignupLink()}
                                isBlank={false}
                                variant="caption"
                                align="center"
                                underline="hover"
                            >
                                {{ link: t('LoginPage.signUp') }}
                            </ExternalLink>
                        </Trans>
                    </TnC>
                </Form>
                <TnC variant="caption">
                    <Trans t={t} i18nKey="LoginPage.termsConditions">
                        <ExternalLink href="https://www.pluspoint.io/legal/terms" variant="caption" underline="hover">
                            {{ terms: t('LoginPage.terms') }}
                        </ExternalLink>
                        <ExternalLink href="https://www.pluspoint.io/legal/privacy" variant="caption" underline="hover">
                            {{ privacy: t('LoginPage.privacy') }}
                        </ExternalLink>
                        <ExternalLink href="https://www.pluspoint.io/legal/dpa" variant="caption" underline="hover">
                            {{ dpa: t('LoginPage.dpa') }}
                        </ExternalLink>
                    </Trans>
                </TnC>
            </div>
        </AuthLayout>
    );
};

export default LoginPage;
