/* eslint-disable no-console */
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

let connection = null;

function createConnection() {
    if (!connection) {
        console.log('SOCKET: Registering on server events');

        const user = JSON.parse(localStorage.getItem('user'));
        const withCredentials = !window.location.origin.includes('localhost');
        const hub = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_API_URL}websockets`, {
                logger: LogLevel.Information,
                accessTokenFactory: () => user.token,
                headers: { Authorization: 'Bearer ' + user.token },
                withCredentials,
            })
            .withAutomaticReconnect()
            .build();

        connection = { hub, started: false };
        return connection.hub;
    } else {
        return connection.hub;
    }
}

function startConnection() {
    if (!connection.started) {
        connection.hub.start().catch(err => console.error('SOCKET: ', err.toString()));
        connection.started = true;
    }
}

function stopConnection() {
    if (connection) {
        console.log('SOCKET: Stoping connection');
        connection.hub.stop();
        connection.started = false;
    }
}

function registerOnServerEvents(messageType, callback) {
    try {
        const connection = createConnection();
        /* Workaround to prevent multiple subscriptions to the same message
            INFO: https://stackoverflow.com/questions/73929390/remove-subscribed-handler-from-a-method-when-using-signalr-in-react
        */
        connection.off(messageType);
        connection.on(messageType, payload => callback(payload));
        connection.onclose(() => stopConnection());
        startConnection();
    } catch (error) {
        console.error('SOCKET: ', error);
    }
}

export const socketService = {
    registerOnServerEvents,
    stopConnection,
};
