import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { regexAnyCharAtLeastOneNumberAndLetter } from '@constants/regex';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import { history } from '@helpers/history';
import { userService } from '@services';
import { Input } from '@components/Input';
import Button from '@components/Button';
import { AuthLayout } from '@features/AuthLayout';
import { useTranslation } from '@hooks/useTranslation';
import { Typography } from '@components/Typography';
import { alertActions } from '@actions';

const Form = styled(Paper)({
    marginBottom: '24px',
    padding: '24px',
    position: 'relative',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
        padding: '16px',
        width: '100%',
    },
});

const Overlay = styled('div')({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255,255,255,0.6)',
    zIndex: 2,
});

const FormSuccess = styled(Form)({
    padding: '24px',
    width: '400px',
    height: '176px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
        padding: '16px',
        width: '100%',
    },
});

const ResetPasswordPage = () => {
    const { t } = useTranslation();

    const [isSubmitSuccess, setSubmitSuccess] = useState(false);

    const { token } = useParams();
    const alert = useSelector(state => state.alert.data);
    const dispatch = useDispatch();

    const ResetPasswordSchema = yup.object().shape({
        password: yup
            .string()
            .min(8, 'validation.minPasswordLength')
            .matches(regexAnyCharAtLeastOneNumberAndLetter, 'validation.wrongPasswordFormat')
            .required('validation.required'),
        confirmPassword: yup
            .string()
            .min(8, 'validation.minPasswordLength')
            .matches(regexAnyCharAtLeastOneNumberAndLetter, 'validation.wrongPasswordFormat')
            .oneOf([yup.ref('password'), null], 'validation.confirmPasswordMatch')
            .required('validation.required'),
    });

    const resolver = useYupValidationResolver(ResetPasswordSchema);

    const {
        getValues,
        handleSubmit,
        control,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver: resolver,
        defaultValues: {
            password: '',
            confirmPassword: '',
        },
    });

    useEffect(() => {
        if (token) {
            getEmailByToken();
        } else {
            history.push('/error');
        }
    }, []);

    const getEmailByToken = async () => await userService.getEmailByToken(token);

    const onSubmit = async () => {
        const values = getValues();
        setSubmitSuccess(false);
        try {
            await userService.resetPassword(values.password, token);
            setSubmitSuccess(true);
        } catch (_) {
            dispatch(alertActions.error(alert[0]?.message || t('apiErrors.something_wrong')));
        }
    };

    return (
        <AuthLayout showLogo={false}>
            {isSubmitSuccess ? (
                <FormSuccess elevation={24}>
                    <Typography variant="h5" sx={{ marginBottom: '8px', color: 'rgba(0, 0, 0, .87)' }}>
                        {t('ResetPasswordPage.titleChanged')}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginBottom: '24px' }}>
                        {t('ResetPasswordPage.descriptionChanged')}
                    </Typography>
                    <Button type="button" variant="contained" fullWidth onClick={() => history.push('/login')}>
                        {t('buttons.logIn')}
                    </Button>
                </FormSuccess>
            ) : (
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Form elevation={24}>
                        {isSubmitting && <Overlay />}
                        <Typography variant="h5" sx={{ marginBottom: '8px', color: 'rgba(0, 0, 0, .87)' }}>
                            {t('ResetPasswordPage.titleReset')}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginBottom: '24px' }}>
                            {t('ResetPasswordPage.descriptionReset')}
                        </Typography>
                        <Controller
                            control={control}
                            name="password"
                            render={({ field, fieldState: { error }, ref }) => (
                                <Input
                                    ref={ref}
                                    inputProps={{ ...field }}
                                    type="password"
                                    label={t('common.password')}
                                    sx={{ marginBottom: '24px' }}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? t(error.message) : null}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="confirmPassword"
                            render={({ field, fieldState: { error }, ref }) => (
                                <Input
                                    ref={ref}
                                    inputProps={{ ...field }}
                                    type="password"
                                    label={t('ResetPasswordPage.confirmPassword')}
                                    fullWidth
                                    sx={{ marginBottom: '24px' }}
                                    error={!!error}
                                    helperText={error ? t(error.message) : null}
                                />
                            )}
                        />
                        <Button
                            onClick={handleSubmit(onSubmit)}
                            variant="contained"
                            fullWidth
                            sx={{ marginBottom: '16px' }}
                            disabled={isSubmitting || !isValid || !isDirty}
                        >
                            {t('buttons.changePassword')}
                        </Button>
                    </Form>
                </div>
            )}
        </AuthLayout>
    );
};

export default ResetPasswordPage;
