import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const googleIntegrationService = {
    revokeGoogleIntegration,
    authenticateGoogleIntegration,
    getLocations,
    editProfile,
    getLocationListing,
    updateLocationListing,
    getCategories,
};

function getLocations() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/google/locations`, requestOptions).then(handleResponse);
}

function revokeGoogleIntegration() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/google/revoke-access`, requestOptions).then(handleResponse);
}

function authenticateGoogleIntegration(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/google/authenticate`, requestOptions).then(handleResponse);
}

function editProfile(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/profiles/google`, requestOptions).then(handleResponse);
}

function getLocationListing(profileId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/profiles/google/${profileId}/location`, requestOptions).then(handleResponse);
}

function updateLocationListing(profileId, data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/profiles/google/${profileId}/location`, requestOptions).then(handleResponse);
}

function getCategories(searchQuery = '') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/google/categories?searchQuery=${searchQuery}`, requestOptions).then(handleResponse);
}
