import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import ModalBase from './ModalBase';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: '500px',
        '@media (max-width: 600px)': {
            width: 'calc(100% - 32px)',
        },
    },
    '& .modal-base-header': {
        borderBottom: 'none',
    },
    '& .modal-base-content': {
        padding: 16,
    },
    '& .modal-base-footer': {
        borderTop: 'none',
    },
});

const ModalConfirm = ({
    isOpen,
    handleClose,
    title,
    description,
    onPrimaryAction,
    primaryActionText,
    primaryActionType,
    className,
    onSecondaryAction = null,
    isLogout = false,
}) => {
    const { t } = useTranslation();

    const [isDisabled, setIsDisabled] = useState(false);

    return (
        <Modal
            isOpen={isOpen}
            handleClose={isDisabled ? null : handleClose}
            className={className}
            title={title}
            onPrimaryAction={async () => {
                setIsDisabled(true);
                await onPrimaryAction();
                if (!isLogout) {
                    setIsDisabled(false);
                }
            }}
            primaryActionText={primaryActionText}
            primaryActionType={primaryActionType}
            primaryActionDisabled={isDisabled}
            onSecondaryAction={onSecondaryAction || handleClose}
            secondaryActionText={t('buttons.cancel')}
            secondaryActionDisabled={isDisabled}
        >
            <Typography variant="body2" color="textSecondary">
                {description}
            </Typography>
        </Modal>
    );
};

export default ModalConfirm;
