import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MultiSelect } from '@components/MultiSelect';
import { Select } from '@components/Select';
import { Input } from '@components/Input';
import { CHANNEL } from '@constants/channels';
import { useTranslation } from '@hooks/useTranslation';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { CAMPAIGN_TYPE } from '@constants/campaigns';

const CoreFormFields = ({
    campaignType,
    isView,
    companies,
    locationLabels,
    channels,
    channelOptions,
    setChannelType,
    handleUpdateSurveyAndChannel,
    getContactsNumber,
}) => {
    const { t } = useTranslation();
    const { control, setValue, trigger, watch } = useFormContext();

    const selectedLocationLabels = watch('locationLabels');

    const availableLabels = [...new Set(companies.map(item => item.labels).flat())];

    const locationLabelsOptions = useMemo(() => {
        return locationLabels
            .filter(l => availableLabels.includes(l.id))
            .map(item => ({ value: item.id, label: item.name }));
    }, [locationLabels]);

    const companyOptions = useMemo(() => {
        return selectedLocationLabels.length === locationLabelsOptions.length
            ? companies.map(item => ({ value: item.companyId, label: item.internalName }))
            : companies
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [selectedLocationLabels]);

    return (
        <>
            <Controller
                control={control}
                name="title"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('CampaignsPage.name')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                        disabled={isView}
                        sx={{ gridArea: 'title' }}
                    />
                )}
            />
            <Controller
                control={control}
                name="locationLabels"
                render={({ field, ref }) => (
                    <MultiSelect
                        {...field}
                        ref={ref}
                        label={t('common.locationLabels')}
                        onChange={({ target: { value } }) => {
                            const selectedLabels = getMultiselectSelectedOptions(locationLabelsOptions, value);
                            setValue('locationLabels', selectedLabels, { shouldDirty: true });
                            const availableCompanies =
                                selectedLabels.length === locationLabelsOptions.length
                                    ? companies
                                    : companies.filter(item => item.labels.some(l => selectedLabels.includes(l)));
                            const companyIds = availableCompanies.map(item => item.companyId);
                            setValue('locations', companyIds, {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                            handleUpdateSurveyAndChannel(companyIds);
                            if (campaignType === CAMPAIGN_TYPE.ONETIME) {
                                getContactsNumber();
                            }
                        }}
                        options={locationLabelsOptions}
                        style={{ gridArea: 'locationLabels' }}
                        countable
                        disabled={isView}
                    />
                )}
            />
            <Controller
                control={control}
                name="locations"
                render={({ field, fieldState: { error }, ref }) => (
                    <MultiSelect
                        {...field}
                        ref={ref}
                        onChange={({ target: { value } }) => {
                            const companyIds = getMultiselectSelectedOptions(companyOptions, value);
                            setValue('locations', companyIds, {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                            handleUpdateSurveyAndChannel(companyIds);
                            if (campaignType === CAMPAIGN_TYPE.ONETIME) {
                                getContactsNumber();
                            }
                        }}
                        options={companyOptions}
                        label={t('common.locations')}
                        disabled={isView}
                        required
                        countable
                        style={{ gridArea: 'locations' }}
                        error={error}
                    />
                )}
            />
            <Controller
                control={control}
                name="channelId"
                rules={{ required: t('validation.required') }}
                render={({ field, fieldState: { error }, ref }) => (
                    <Select
                        {...field}
                        onChange={({ target: { value } }) => {
                            setValue('channelId', value, { shouldValidate: true, shouldDirty: true });
                            const channel = channels.find(c => c.id === value);
                            setChannelType(channel?.type || CHANNEL.SMS);
                            if (campaignType === CAMPAIGN_TYPE.ONETIME) {
                                getContactsNumber();
                            }
                            trigger('message');
                        }}
                        ref={ref}
                        label={t('common.channel')}
                        fullWidth
                        options={channelOptions}
                        required
                        disabled={isView}
                        style={{ gridArea: 'channels' }}
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                    />
                )}
            />
        </>
    );
};

export default CoreFormFields;
