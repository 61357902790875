import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import sortBy from 'lodash.sortby';
import { Select } from '@components/Select';
import { Typography } from '@components/Typography';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { useTranslation } from '@hooks/useTranslation';
import { getChannelOptions, getFilteredByCompanyChannelsIds } from '@helpers/channels';
import { getSurveysOptionsById } from '@helpers/send-invites';
import { AutoSurveysIntegrationName } from '@constants/integrations';
import { alertActions, surveysActions } from '@actions';
import { surveyService } from '@services';
import { Modal } from './styles';

const AutoSurveyIntegrationModal = ({ modal, setModal }) => {
    const { t } = useTranslation();
    const [channelOptions, setChannelOptions] = useState([]);
    const account = useSelector(state => state.account?.account);
    const { data: channels, loading: channelsLoading } = useSelector(state => state.channels);
    const surveys = useSelector(state => state.surveys.data);
    const autoSurveys = useSelector(state => state.surveys?.autoSurveys);

    const isVtexModal = modal.type === AutoSurveysIntegrationName.VTEX;

    const dispatch = useDispatch();

    const delayOptions = useMemo(
        () => [
            { value: 0, label: t('common.none') },
            { value: 1, label: t('FeedbackAutoSurveys.hour') },
            { value: 2, label: t('FeedbackAutoSurveys.hours2') },
            { value: 3, label: t('FeedbackAutoSurveys.day') },
            { value: 4, label: t('FeedbackAutoSurveys.days2') },
            { value: 5, label: t('FeedbackAutoSurveys.days3') },
            { value: 6, label: t('FeedbackAutoSurveys.week') },
            { value: 7, label: t('FeedbackAutoSurveys.weeks2') },
        ],
        [t],
    );

    const AutoSurveyIntegrationSchema = yup.object().shape({
        companyId: !isVtexModal ? yup.string().required('validation.required') : yup.string().nullable(),
        surveyTemplateId: yup.string().when('step', {
            is: 1,
            then: yup.string().nullable(),
            otherwise: yup.string().required('validation.required'),
        }),
        channelId: yup.string().when('step', {
            is: 1,
            then: yup.string().nullable(),
            otherwise: yup.string().required('validation.required'),
        }),
        delay: yup.number().required('validation.required'),
        step: yup.number(),
    });

    const resolver = useYupValidationResolver(AutoSurveyIntegrationSchema);

    const {
        getValues,
        setValue,
        control,
        handleSubmit,
        reset,
        formState: { isValid, isSubmitting, isDirty },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            type: modal.type,
            companyId: '',
            surveyTemplateId: '',
            channelId: '',
            delay: 0,
            step: 1,
        },
    });

    useEffect(() => {
        if (isVtexModal) {
            setModal(prev => ({ ...prev, step: 2 }));
            setValue('step', 2);
        }
    }, [dispatch]);

    const companies = useMemo(() => account?.companies?.filter(c => c.surveys?.length > 0) || [], [account?.companies]);
    const companiesFilteredByProfile = useMemo(() => {
        if (modal.type === AutoSurveysIntegrationName.CoverManager) {
            return companies?.filter(c => c.profiles.some(p => p.type === AutoSurveysIntegrationName.CoverManager));
        } else {
            return companies;
        }
    }, [companies]);

    const companiesOptions = useMemo(
        () => companiesFilteredByProfile?.map(c => ({ value: c.companyId, label: c.internalName })),
        [companiesFilteredByProfile],
    );
    const surveysAllOptions = useMemo(
        () =>
            sortBy(
                surveys?.map(item => ({
                    value: item.survey.id,
                    label: item.survey.name,
                })),
                ['label'],
            ),
        [surveys],
    );

    const updateChannelOptions = companyId => {
        const availableChannels = getFilteredByCompanyChannelsIds(companyId, companies);
        const newChannelOptions = getChannelOptions({ out: true }, availableChannels, channels);
        setChannelOptions(newChannelOptions);
    };

    useEffect(() => {
        if (isVtexModal && companies.length && !channelsLoading) {
            const savedAutoSurveys = autoSurveys.filter(i => i.type === modal.type);
            if (savedAutoSurveys.length > 0) {
                updateChannelOptions(savedAutoSurveys[0].companyId);

                setValue('id', savedAutoSurveys[0].id);
                setValue('companyId', savedAutoSurveys[0].companyId);
                setValue('surveyTemplateId', savedAutoSurveys[0].surveyTemplateId);
                setValue('channelId', savedAutoSurveys[0].channelId);
                setValue('delay', savedAutoSurveys[0].delay);
            }
        }
    }, [companies, channelsLoading]);

    const onClickBack = () => {
        setModal(prev => ({ ...prev, step: prev.step - 1 }));
        reset();
    };

    const onCloseModal = () => {
        setModal(prev => ({ ...prev, open: false, step: 1 }));
        reset();
    };

    const onSubmit = async () => {
        const values = getValues();
        if (modal.step === 1) {
            setValue('step', 2);
            setModal(prev => ({ ...prev, step: 2 }));
            return;
        }
        if (modal.step === 2) {
            try {
                await surveyService.createAutoSurvey({
                    ...values,
                    step: undefined,
                });
                dispatch(alertActions.success(t('alertMessages.connectIntegrationSuccess')));
                dispatch(surveysActions.getAutoSurveys());
                onCloseModal();
            } catch (error) {
                if (error.errorCode) {
                    dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
                } else {
                    dispatch(alertActions.error(t('alertMessages.connectIntegrationFail')));
                }
            }
            return;
        }
    };

    return (
        <Modal
            isOpen={modal.open}
            handleClose={onCloseModal}
            title={
                modal.step === 1 ? t('FeedbackAutoSurveys.modalTitleStep1') : t('FeedbackAutoSurveys.modalTitleStep2')
            }
            subtitle={
                <Typography variant="caption" color="textSecondary">
                    {modal.step === 1
                        ? t('FeedbackAutoSurveys.modalSubtitleStep1')
                        : t('FeedbackAutoSurveys.modalSubtitleStep2')}
                </Typography>
            }
            onPrimaryAction={handleSubmit(onSubmit)}
            primaryActionText={modal.step === 1 ? t('buttons.continue') : t('buttons.saveChanges')}
            primaryActionDisabled={isSubmitting || !isValid || !isDirty}
            onSecondaryAction={onCloseModal}
            secondaryActionText={t('buttons.cancel')}
            onAdditionalAction={modal.step === 2 && !isVtexModal ? onClickBack : null}
            additionalTextButton={t('buttons.back')}
            additionalButtonType="outlined"
            step1={modal.step === 1}
        >
            <>
                {modal.step === 1 && (
                    <Controller
                        control={control}
                        name="companyId"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Select
                                {...field}
                                ref={ref}
                                style={{ gridArea: 'select1' }}
                                onChange={({ target: { value } }) => {
                                    setValue('companyId', value, { shouldValidate: true, shouldDirty: true });
                                    const savedAutoSurvey = autoSurveys
                                        .filter(i => i.type === modal.type)
                                        .find(i => i.companyId === value);
                                    if (savedAutoSurvey) {
                                        setValue('id', savedAutoSurvey.id);
                                        setValue('surveyTemplateId', savedAutoSurvey.surveyTemplateId);
                                        setValue('channelId', savedAutoSurvey.channelId);
                                        setValue('delay', savedAutoSurvey.delay);
                                    }

                                    updateChannelOptions(value);
                                }}
                                options={companiesOptions}
                                required
                                label={t('common.location')}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                            />
                        )}
                    />
                )}
                {modal.step === 2 && (
                    <>
                        <Controller
                            control={control}
                            name="surveyTemplateId"
                            render={({ field, fieldState: { error }, ref }) => (
                                <Select
                                    {...field}
                                    ref={ref}
                                    style={{ gridArea: 'select2' }}
                                    onChange={({ target: { value } }) => {
                                        setValue('surveyTemplateId', value, {
                                            shouldValidate: true,
                                            shouldDirty: true,
                                        });
                                        setValue('channelId', '');
                                        setValue('delay', 0);

                                        if (isVtexModal) {
                                            const companyId = surveys?.find(item => item.survey.id === value)?.survey
                                                .companyId;
                                            updateChannelOptions(companyId);
                                        }
                                    }}
                                    options={
                                        isVtexModal
                                            ? surveysAllOptions
                                            : getSurveysOptionsById(getValues('companyId'), companies)
                                    }
                                    required
                                    label={t('common.survey')}
                                    fullWidth
                                    dropdownStyles={{ maxHeight: '300px' }}
                                    error={!!error}
                                    helperText={error ? t(error.message) : null}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="channelId"
                            render={({ field, fieldState: { error }, ref }) => (
                                <Select
                                    {...field}
                                    ref={ref}
                                    style={{ gridArea: 'select3' }}
                                    onChange={({ target: { value } }) => {
                                        setValue('channelId', value, { shouldValidate: true, shouldDirty: true });
                                    }}
                                    options={channelOptions}
                                    required
                                    label={t('common.channel')}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? t(error.message) : null}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="delay"
                            render={({ field, ref }) => (
                                <Select
                                    {...field}
                                    ref={ref}
                                    style={{ gridArea: 'select4' }}
                                    label={t('common.delay')}
                                    options={delayOptions}
                                />
                            )}
                        />
                    </>
                )}
            </>
        </Modal>
    );
};

export default AutoSurveyIntegrationModal;
