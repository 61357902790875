import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import DoughnutChart from '@components/Charts/DoughnutChart';
import { useTranslation } from '@hooks/useTranslation';
import { formatPercentNumber } from '@helpers/formatNumbers';
import MetricItem from './MetricItem';

const CardsWrapper = styled('div')({
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    height: 'calc(100% - 57px)',
    '@media (max-width: 375px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
    },
});

const createChartData = (data, hasData, isTablet, t) => {
    const RATING_MAP = {
        1: t('AnalyticsReviews.stars1'),
        2: t('AnalyticsReviews.stars2'),
        3: t('AnalyticsReviews.stars3'),
        4: t('AnalyticsReviews.stars4'),
        5: t('AnalyticsReviews.stars5'),
    };
    const EMPTY_SPACE = '                                   ';
    const backgroundColors = ['#FF5853', '#FFA949', '#FFCE4D', '#99D551', '#4CC474'];
    const borderColors = ['#FF5853', '#FFA949', '#FFCE4D', '#99D551', '#4CC474'];

    return {
        labels: Object.entries(RATING_MAP).map(([key, value]) => {
            const set = data.find(d => d.rating === +key);
            return `${value} - ${set?.count} (${formatPercentNumber(set?.percentage)})${isTablet ? EMPTY_SPACE : ''}`;
        }),
        datasets: [
            {
                label: '',
                data: !hasData
                    ? [1]
                    : Object.keys(RATING_MAP).map(key => data.find(d => d.rating === +key)?.count || 0),
                backgroundColor: !hasData ? ['#F2F2F2', ...backgroundColors] : backgroundColors,
                borderColor: !hasData ? ['#F2F2F2', ...borderColors] : borderColors,
                borderWidth: 1,
            },
        ],
    };
};

const Reviews = () => {
    const { t } = useTranslation();
    const isTablet = useMediaQuery('@media (max-width: 1200px)');
    const isMobile = useMediaQuery('@media (max-width: 850px)');

    const { reviews } = useSelector(state => state.dashboard.dashboard);

    const hasData = useMemo(() => reviews.ratingDistribution.some(item => !!item.count), [reviews]);
    const datasets = useMemo(
        () => createChartData(reviews.ratingDistribution, hasData, isTablet, t),
        [reviews.ratingDistribution, hasData, isTablet],
    );

    const data = [
        { id: 0, title: t('Sidebar.reviews'), value: reviews.collected, subvalue: reviews.collectedDelta },
        {
            id: 2,
            title: t('common.rating'),
            value: reviews.averageRating,
            subvalue: reviews.averageRatingDelta,
            withSign: false,
        },
        { id: 3, title: t('HomePage.unreplied'), value: reviews.unrepliedReviews, subvalue: null },
    ];

    return (
        <>
            <CardsWrapper>
                {data.map(item => (
                    <MetricItem
                        key={item.id}
                        value={item.value}
                        subvalue={item.subvalue}
                        title={item.title}
                        withSign={item.withSign}
                    />
                ))}
            </CardsWrapper>
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    maxWidth: isMobile ? 'none' : '400px',
                    height: '175px',
                    marginTop: '16px auto 0',
                }}
            >
                <DoughnutChart
                    datasets={datasets}
                    hasData={hasData}
                    optionsProps={{ cutout: '60%', plugins: { legend: { position: 'right' } } }}
                    overall={{ title: '', data: '' }}
                />
            </div>
        </>
    );
};

export default Reviews;
