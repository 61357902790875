import { authHeader, BASE_URL, handleResponse, handleResponseBlob } from '@helpers/api';

export const surveyService = {
    getAll,
    createEmptySurvey,
    deleteSurvey,
    editSurvey,
    getSurveyImage,
    uploadQRLogoImage,
    updateSurveyDelay,
    createAutoSurvey,
    getAutoSurveys,
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/surveys/survey-templates`, requestOptions).then(handleResponse);
}

function createEmptySurvey() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/surveys/survey-templates/empty`, requestOptions).then(handleResponse);
}

function editSurvey(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/surveys/survey-templates`, requestOptions).then(handleResponse);
}

function deleteSurvey(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/surveys/survey-templates/${id}`, requestOptions).then(handleResponse);
}

function getSurveyImage(externalId, data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/assets/generate-qr`, requestOptions).then(handleResponseBlob);
}

function uploadQRLogoImage(data, surveyId) {
    const user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + user.token,
        },
        body: data,
    };
    return fetch(`${BASE_URL}api/surveys/survey-templates/${surveyId}/upload-logo`, requestOptions).then(handleResponse);
}

function updateSurveyDelay(data, surveyId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/surveys/survey-templates/${surveyId}/delay`, requestOptions).then(handleResponse);
}

function createAutoSurvey(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/automated-surveys`, requestOptions).then(handleResponse);
}

function getAutoSurveys() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/automated-surveys`, requestOptions).then(handleResponse);
}
