import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Typography } from '@components/Typography';
import Drawer from '@components/Drawer';
import { RadioButtonsGroup } from '@components/RadioButtonsGroup';
import { billingService } from '@services';
import { accountActions, alertActions, hubspotCalendarUiActions, pricingDrawerUiActions } from '@actions';
import { PRICING_PLANS, BILLING_TYPE_MAP, BILLING_TIME_MAP } from '@constants/billing';
import { PRICING_DRAWER_TYPE } from '@constants/pricing-drawer-ui';
import { useTranslation } from '@hooks/useTranslation';
import { getPricingPlans } from '@helpers/billing';
import BillingCard from './BillingCard';
import UpdateSubscriptionReport from './UpdateSubscriptionReport';

const CompanyCounterWrapper = styled('div')({
    display: 'flex',
    width: 70,
    justifyContent: 'space-between',
});

const StyledRadioButtonsGroup = styled(RadioButtonsGroup)({
    marginBottom: '24px',
});

const IconStyles = { width: '16px', fill: '#8FA6AE' };

const BillingDrawer = () => {
    const account = useSelector(state => state.account?.account);
    const { isOpen, type, step } = useSelector(state => state.pricingDrawerUi);

    const subscription = account?.subscriptions[0];
    const IS_LEGACY = useMemo(() => {
        return !Object.values(BILLING_TYPE_MAP).includes(subscription?.billingPlanName);
    }, [subscription?.billingPlanName]);

    const CURRENT_COMPANIES_NUMBER = account?.companies?.length;
    // if Infinity - set 0, for counter
    const MAX_COMPANIES_NUMBER = account?.companiesMaxCount === Infinity ? 0 : account?.companiesMaxCount;
    const CURRENT_USERS_NUMBER = account?.users?.length;
    const MAX_USERS_NUMBER = account?.usersMaxCount === Infinity ? 0 : account?.usersMaxCount;

    const [subscriptionIsLoading, setSubscriptionIsLoading] = useState(false);
    const [billingTimePlan, setBillingTimePlan] = useState(BILLING_TIME_MAP.MONTHLY);
    const [billingType, setBillingType] = useState(BILLING_TYPE_MAP.PROFESSIONAL);
    const [companiesCounter, setCompaniesCounter] = useState({
        min: CURRENT_COMPANIES_NUMBER,
        current: Math.max(CURRENT_COMPANIES_NUMBER, MAX_COMPANIES_NUMBER),
    });
    const [usersCounter, setUsersCounter] = useState({ min: 0, current: 0 });
    const [initialData, setInitialData] = useState({
        timePlan: BILLING_TIME_MAP.MONTHLY,
        type: BILLING_TYPE_MAP.PROFESSIONAL,
        companies: Math.max(CURRENT_COMPANIES_NUMBER, MAX_COMPANIES_NUMBER),
        users: 0,
    });

    const PRICING_PLAN = getPricingPlans(account?.locale);
    const SELECTED_PLAN = PRICING_PLAN[billingTimePlan];
    const isUpgradeDrawer = type === PRICING_DRAWER_TYPE.UPGRADE;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const firstRender = useRef(true);

    useEffect(() => {
        if (!firstRender.current) {
            if (billingType === BILLING_TYPE_MAP.ENTERPRISE) {
                // for Enterprise plan users count is not important
                setUsersCounter(prev => ({ ...prev, min: CURRENT_USERS_NUMBER, current: CURRENT_USERS_NUMBER }));
                return;
            }

            const planUsersCount = PRICING_PLANS.find(item => item.name === billingType)?.users;
            setUsersCounter(prev => ({
                ...prev,
                min: Math.max(CURRENT_USERS_NUMBER, planUsersCount),
                current: Math.max(CURRENT_USERS_NUMBER, planUsersCount, prev.current),
            }));
        }
    }, [CURRENT_USERS_NUMBER, MAX_USERS_NUMBER, billingType]);

    useEffect(() => {
        if (firstRender.current) {
            const maxAccUsersCount = Math.max(CURRENT_USERS_NUMBER, MAX_USERS_NUMBER);
            if (IS_LEGACY) {
                // preselect Enterprise plan for legacy plan, users count is not important
                setBillingType(BILLING_TYPE_MAP.ENTERPRISE);
                setBillingTimePlan(subscription?.productType);
                setUsersCounter({ min: CURRENT_USERS_NUMBER, current: CURRENT_USERS_NUMBER });
                setInitialData(prev => ({
                    ...prev,
                    timePlan: subscription?.productType,
                    type: BILLING_TYPE_MAP.ENTERPRISE,
                    users: CURRENT_USERS_NUMBER,
                }));
            } else {
                // preselect professional plan for upgrade plan drawer or Free Trial plan
                const planUsersCount = PRICING_PLANS.find(
                    item =>
                        item.name ===
                        (isUpgradeDrawer || subscription?.billingPlanName === BILLING_TYPE_MAP.TRIAL
                            ? BILLING_TYPE_MAP.PROFESSIONAL
                            : subscription?.billingPlanName),
                )?.users;
                if (subscription?.billingPlanName !== BILLING_TYPE_MAP.TRIAL && !isUpgradeDrawer) {
                    // preselect Starter or Professional plan if change plan drawer is opened
                    setBillingType(subscription?.billingPlanName);
                    setInitialData(prev => ({ ...prev, type: subscription?.billingPlanName }));
                }
                if (subscription?.billingPlanName !== BILLING_TYPE_MAP.TRIAL) {
                    setBillingTimePlan(subscription?.productType);
                    setInitialData(prev => ({ ...prev, timePlan: subscription?.productType }));
                }
                const initUsersCount = Math.max(CURRENT_USERS_NUMBER, planUsersCount);
                setUsersCounter({ min: initUsersCount, current: Math.max(maxAccUsersCount, planUsersCount) });
                setInitialData(prev => ({ ...prev, users: Math.max(maxAccUsersCount, planUsersCount) }));
            }
            firstRender.current = false;
        }
    }, [subscription, CURRENT_USERS_NUMBER, MAX_USERS_NUMBER]);

    const onAddCompany = () => setCompaniesCounter(prev => ({ ...prev, current: prev.current + 1 }));

    const onRemoveCompany = () => setCompaniesCounter(prev => ({ ...prev, current: prev.current - 1 }));

    const onAddUser = () => setUsersCounter(prev => ({ ...prev, current: prev.current + 1 }));

    const onRemoveUser = () => setUsersCounter(prev => ({ ...prev, current: prev.current - 1 }));

    const onCreateSubscription = async () => {
        if (billingType === BILLING_TYPE_MAP.ENTERPRISE) {
            dispatch(hubspotCalendarUiActions.setOpen(true));
            dispatch(pricingDrawerUiActions.setOpen({ open: false, type: null }));
            return;
        }
        try {
            if (
                subscription?.billingPlanName === BILLING_TYPE_MAP.STARTER ||
                subscription?.billingPlanName === BILLING_TYPE_MAP.PROFESSIONAL
            ) {
                dispatch(pricingDrawerUiActions.setStep(1));
                dispatch(
                    pricingDrawerUiActions.getUpdateSubscriptionData({
                        productName: billingType,
                        locationsCount: companiesCounter.current,
                        usersCount: usersCounter.current,
                        productType: billingTimePlan,
                    }),
                );
                return;
            } else {
                setSubscriptionIsLoading(true);
                const data = await billingService.createSubscription({
                    productName: billingType,
                    locationsCount: companiesCounter.current,
                    usersCount: usersCounter.current,
                    productType: billingTimePlan,
                    currency: SELECTED_PLAN.currency,
                });
                if (data.paymentUrl) {
                    window.location = data.paymentUrl;
                }
            }
        } catch (_) {
            dispatch(alertActions.error(t('alertMessages.updateFail')));
        }
        setSubscriptionIsLoading(false);
    };

    const onUpdateSubscription = async () => {
        try {
            await billingService.updateSubscription({
                productName: billingType,
                locationsCount: companiesCounter.current,
                usersCount: usersCounter.current,
                productType: billingTimePlan,
            });
            dispatch(accountActions.get());
            dispatch(pricingDrawerUiActions.setOpen(false));
            dispatch(alertActions.success(t('alertMessages.updateSuccess')));
        } catch (_) {
            dispatch(alertActions.error(t('alertMessages.updateFail')));
        }
        setSubscriptionIsLoading(false);
    };

    const onClose = () => dispatch(pricingDrawerUiActions.setOpen({ open: false, type: null }));

    const onClickBack = () => dispatch(pricingDrawerUiActions.setStep(0));

    const dataChanged =
        initialData.companies !== companiesCounter.current ||
        initialData.users !== usersCounter.current ||
        initialData.type !== billingType ||
        initialData.timePlan !== billingTimePlan;

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            title={
                type === PRICING_DRAWER_TYPE.UPGRADE
                    ? t('SettingsGeneral.upgradePlan')
                    : t('SettingsGeneral.changePlan')
            }
            subtitle={
                type === PRICING_DRAWER_TYPE.UPGRADE
                    ? t('SettingsGeneral.upgradeDescription')
                    : t('SettingsGeneral.changeDescription')
            }
            primaryText={
                step === 0
                    ? billingType === BILLING_TYPE_MAP.ENTERPRISE
                        ? t('SettingsGeneral.contactSales')
                        : t('SettingsGeneral.proceedToPayment')
                    : t('buttons.confirm')
            }
            primaryDisabled={
                (billingType !== BILLING_TYPE_MAP.ENTERPRISE &&
                    !isUpgradeDrawer &&
                    subscription?.billingPlanName !== BILLING_TYPE_MAP.TRIAL &&
                    !dataChanged) ||
                subscriptionIsLoading
            }
            primaryAction={step === 0 ? onCreateSubscription : onUpdateSubscription}
            secondaryText={step === 0 ? t('buttons.cancel') : t('buttons.back')}
            secondaryAction={step === 0 ? onClose : onClickBack}
        >
            {step === 0 && (
                <>
                    <StyledRadioButtonsGroup
                        value={billingTimePlan}
                        onChange={newValue => setBillingTimePlan(newValue)}
                        options={[
                            { value: BILLING_TIME_MAP.ANNUAL, label: t('SettingsGeneral.annual') },
                            { value: BILLING_TIME_MAP.MONTHLY, label: t('SettingsGeneral.monthly') },
                        ]}
                    />
                    <div
                        style={{
                            marginBottom: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="body2">{t('SettingsGeneral.numberOfCompanies')}</Typography>
                        <CompanyCounterWrapper>
                            <IconButton
                                disabled={companiesCounter.current <= companiesCounter.min}
                                onClick={onRemoveCompany}
                                style={{ height: 24, padding: 8, width: 24 }}
                            >
                                <RemoveCircleIcon
                                    sx={{
                                        opacity: companiesCounter.current <= companiesCounter.min ? 0.5 : 1,
                                        ...IconStyles,
                                    }}
                                />
                            </IconButton>
                            <Typography variant="subtitle1">{companiesCounter.current}</Typography>
                            <IconButton onClick={onAddCompany} style={{ height: 24, padding: 8, width: 24 }}>
                                <AddCircleIcon sx={IconStyles} />
                            </IconButton>
                        </CompanyCounterWrapper>
                    </div>
                    <div
                        style={{
                            marginBottom: '24px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="body2">{t('SettingsGeneral.numberOfUsers')}</Typography>
                        <CompanyCounterWrapper>
                            <IconButton
                                disabled={usersCounter.current <= usersCounter.min}
                                onClick={onRemoveUser}
                                style={{ height: 24, padding: 8, width: 24 }}
                            >
                                <RemoveCircleIcon
                                    sx={{
                                        opacity: usersCounter.current <= usersCounter.min ? 0.5 : 1,
                                        ...IconStyles,
                                    }}
                                />
                            </IconButton>
                            <Typography variant="subtitle1">{usersCounter.current}</Typography>
                            <IconButton onClick={onAddUser} style={{ height: 24, padding: 8, width: 24 }}>
                                <AddCircleIcon sx={IconStyles} />
                            </IconButton>
                        </CompanyCounterWrapper>
                    </div>
                    <div style={{ display: 'grid', gridGap: '8px' }}>
                        {PRICING_PLANS.map(item => (
                            <BillingCard
                                key={item.id}
                                pricingType={item}
                                billingType={billingType}
                                setBillingType={setBillingType}
                                billingTimePlan={billingTimePlan}
                                companyCounter={companiesCounter.current}
                                usersCounter={usersCounter.current}
                                locale={account?.locale}
                            />
                        ))}
                    </div>
                </>
            )}
            {step === 1 && <UpdateSubscriptionReport />}
        </Drawer>
    );
};

export default BillingDrawer;
