import { memo, useMemo, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { format, parseISO } from 'date-fns';
import Paper from '@mui/material/Paper';
import { Typography } from '@components/Typography';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { checkIntegration } from '@helpers/analytics';
import { handleChartPrint } from '@helpers/analytics';
import TableContainer from '@mui/material/TableContainer';
import { useTranslation } from '@hooks/useTranslation';
import { styled } from '@mui/system';

const PaperStyled = styled(Paper)({
    width: '100%',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px !important',
    marginTop: '16px',
    '@media print': {
        breakInside: 'avoid',
    },
});
const ChartWrapper = styled('div')({
    position: 'relative',
    height: '280px',
    '@media (max-width: 1024px)': {
        height: '400px',
        width: '900px',
    },
    '@media print': {
        height: '280px !important',
    },
});

const createChartData = (labels, data, othersLabel) => {
    return {
        labels,
        datasets: [
            {
                label: othersLabel,
                data: data.map(i => checkIntegration(i).isOthers),
                backgroundColor: ['rgba(188, 201, 206, 1)'],
                categoryPercentage: 1.0,
                barPercentage: 0.7,
                borderWidth: 0,
                borderRadius: 4,
            },
            {
                label: 'iFood',
                data: data.map(i => checkIntegration(i).isIFood),
                backgroundColor: ['#E7EEB3'],
                categoryPercentage: 1.0,
                barPercentage: 0.7,
                borderWidth: 0,
                borderRadius: 4,
            },
            {
                label: 'Trustpilot',
                data: data.map(i => checkIntegration(i).isTrustpilot),
                backgroundColor: ['#BDE0CC'],
                categoryPercentage: 1.0,
                barPercentage: 0.7,
                borderWidth: 0,
                borderRadius: 4,
            },
            {
                label: 'Booking.com',
                data: data.map(i => checkIntegration(i).isBooking),
                backgroundColor: ['#98CBAD'],
                categoryPercentage: 1.0,
                barPercentage: 0.7,
                borderWidth: 0,
                borderRadius: 4,
            },
            {
                label: 'Facebook',
                data: data.map(i => checkIntegration(i).isFacebook),
                backgroundColor: ['rgba(98, 130, 141, 1)'],
                categoryPercentage: 1.0,
                barPercentage: 0.7,
                borderWidth: 0,
                borderRadius: 4,
            },
            {
                label: 'Google',
                data: data.map(i => checkIntegration(i).isGoogle),
                backgroundColor: ['rgba(31, 76, 92, 1)'],
                categoryPercentage: 1.0,
                lol: 'lol',
                barPercentage: 0.7,
                borderWidth: 0,
                borderRadius: 4,
            },
        ],
    };
};

const createChartOptions = (total, labels) => {
    return {
        elements: {
            bar: {
                borderWidth: 0,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            chartAreaBorder: {
                borderColor: 'rgba(0, 0, 0, 0.5)',
                borderWidth: 1,
            },
            legend: {
                position: 'bottom',
                align: 'start',
                reverse: true,
                onClick: () => null,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    font: {
                        family: 'Rubik',
                        size: 10,
                        weight: 400,
                    },
                    filter: function (legendItem, data) {
                        const index = legendItem.datasetIndex;
                        const currentDataValue = data.datasets[index];
                        const isNullable = currentDataValue.data.every(i => i === 0);
                        return !isNullable;
                    },
                },
            },
            tooltip: {
                displayColors: false,
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 1)',
                callbacks: {
                    title: function () {},
                    label: function (context) {
                        return `${context.dataset.label} - ${context.formattedValue}`;
                    },
                },
                filter: tooltipItem => tooltipItem.raw > 0,
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    tickLength: 0,
                    drawTicks: false,
                    drawBorder: true,
                    drawOnChartArea: false,
                    borderColor: 'rgba(0, 0, 0, 0.3)',
                },
                ticks: {
                    autoSkip: false,
                    padding: 8,
                    font: {
                        family: 'Rubik',
                        size: 10,
                        weight: 400,
                    },
                    callback: function (_, index) {
                        const parts = labels[index].toUpperCase().split(' ');
                        return `${parts[0]} ${parts[1].substring(0, 3).toUpperCase()}`;
                    },
                },
            },
            y: {
                grid: {
                    tickLength: 0,
                    drawTicks: false,
                    drawBorder: true,
                    borderDash: [5, 5],
                    borderColor: 'rgba(0, 0, 0, 0.3)',
                },
                ticks: {
                    font: {
                        family: 'Rubik',
                        size: 10,
                    },
                    color: 'rgba(0, 0, 0, 0.6)',
                    padding: 10,
                },
                stacked: true,
                title: {
                    display: true,
                    position: 'left',
                    align: 'center',
                    font: {
                        family: 'Rubik',
                        size: 10,
                        weight: 500,
                    },
                    color: 'rgba(0, 0, 0, 0.6)',
                    text: total.toUpperCase(),
                    padding: {
                        bottom: 14,
                    },
                },
            },
        },
    };
};

const chartAreaBorder = {
    id: 'chartAreaBorder',
    beforeDraw(chart) {
        const { ctx } = chart;
        ctx.save();

        ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.lineWidth = chart.config.options.scales.x.grid.lineWidth || 3;

        ctx.beginPath();
        ctx.moveTo(chart.chartArea.right, chart.chartArea.top);
        ctx.lineTo(chart.chartArea.right, chart.chartArea.bottom);
        ctx.stroke();

        ctx.restore();
    },
};

const ReviewsChannelTrend = memo(({ data, language }) => {
    const { t } = useTranslation();
    const chartRef = useRef(null);

    useEffect(() => {
        return handleChartPrint(chartRef, 'chart-wrapper-reviews');
    }, [() => chartRef.current]);

    const formattedData = useMemo(
        () =>
            data.map(item => {
                return item.breakdown.reduce((acc, curr) => {
                    acc[curr.profileType] = curr.totalReviews;
                    return acc;
                }, {});
            }),
        [data],
    );

    const labels = useMemo(() => {
        return data.map(d => format(parseISO(d.date), 'dd MMM', { locale: I18N_DATE_FORMAT[language] }));
    }, [language, data]);

    return (
        <PaperStyled elevation={1}>
            <Typography variant="title1" color="textPrimary" sx={{ marginBottom: '50px' }}>
                {t('AnalyticsReviews.reviewSitesTrend')}
            </Typography>
            <TableContainer>
                <ChartWrapper id="chart-wrapper-reviews">
                    {labels.length && (
                        <Bar
                            ref={chartRef}
                            id="doughnut-chart"
                            data={createChartData(labels, formattedData, t('AnalyticsReviews.others'))}
                            options={createChartOptions(t('AnalyticsReviews.totalReviews'), labels)}
                            plugins={[chartAreaBorder]}
                        />
                    )}
                </ChartWrapper>
            </TableContainer>
        </PaperStyled>
    );
});

ReviewsChannelTrend.displayName = 'ReviewsChannelTrend';

export default ReviewsChannelTrend;
