export const getI18nForm = (number, key) => {
    switch (number) {
        case 1:
            return `${key}Single`;
        case 2:
        case 3:
        case 4:
            return `${key}Mid`;
        default:
            return `${key}Plural`;
    }
};
