import { pricingDrawerUiConstaints } from '@constants/pricing-drawer-ui';
import { billingService } from '@services';

const setOpen = data => {
    return { type: pricingDrawerUiConstaints.SET_PRICING_DRAWER_OPEN, payload: data };
};

const setOpenCustom = value => {
    return { type: pricingDrawerUiConstaints.SET_CUSTOM_PRICING_DRAWER_OPEN, payload: value };
};

const setStep = data => {
    return { type: pricingDrawerUiConstaints.SET_PRICING_DRAWER_STEP, payload: data };
};

const success = data => {
    return { type: pricingDrawerUiConstaints.PRICING_DRAWER_DATA_SUCCESS, payload: data };
};

function getUpdateSubscriptionData(data) {
    return dispatch => {
        return billingService.getUpdateSubscriptionData(data).then(
            data => dispatch(success(data)),
            () => console.error("Couldn't fetch update subscription data"),
        );
    };
}

export const pricingDrawerUiActions = {
    setOpen,
    setOpenCustom,
    setStep,
    getUpdateSubscriptionData,
};
