export const socialPostsConstants = {
    SOCIAL_POSTS_FETCH: 'SOCIAL_POSTS_FETCH',
    SOCIAL_POSTS_SUCCESS: 'SOCIAL_POSTS_SUCCESS',
    SOCIAL_POSTS_ERROR: 'SOCIAL_POSTS_ERROR',
    SET_SOCIAL_POSTS: 'SET_SOCIAL_POSTS',
};

export const POST_STATUS = {
    SCHEDULED: 0,
    PUBLISHED: 1,
    FAILED: 2,
    DRAFT: 3,
};

export const POST_CHANNEL = {
    GOOGLE: 0,
    FACEBOOK: 1,
    INSTAGRAM: 2,
};

export const POST_CHANNEL_NAME = {
    0: 'Google',
    1: 'Facebook',
    2: 'Instagram',
};
