import { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { labelsActions } from '@actions';
import { LABEL_TYPE } from '@constants/labels';
import KnowledgeBase from './KnowledgeBase';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
    },
});

const ChatbotPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
        dispatch(labelsActions.get(LABEL_TYPE.CONTACT));
    }, []);

    return (
        <Container>
            <Switch>
                <Route path="/messaging/chatbot/knowledge-base" component={KnowledgeBase} />
                <Redirect from="/messaging/chatbot" to="/messaging/chatbot/knowledge-base" />
            </Switch>
        </Container>
    );
};

export default ChatbotPage;
