import { createTheme } from '@mui/material/styles';

const themeConfig = {
    palette: {
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.6)',
            tertiary: 'rgba(0, 0, 0, 0.38)',
        },
        primary: {
            light: '#BCC9CE',
            main: '#1F4C5C',
            dark: '#173C4A',
        },
        secondary: {
            light: '#EBF6F0',
            main: '#C7E5D4',
            dark: '#AED8C0',
        },
        tertiary: {
            light: '#FBFCF1',
            main: '#F3F7D7',
            dark: '#EDF2C4',
        },
        error: {
            main: '#B00020',
        },
        success: {
            main: '#0B8D00',
        },
        action: {
            disabledBackground: 'rgba(0, 0, 0, 0.14)',
            disabled: 'rgba(0, 0, 0, 0.38)',
        },
        light: {
            main: '#fff',
        },
    },
    typography: {
        fontSize: 16,
        fontFamily: 'Lora, Rubik, sans-serif',
        h1: {
            fontFamily: 'Lora, serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '6rem', //96px
            lineHeight: '112px',
            letterSpacing: '-1.5px',
        },
        h2: {
            fontFamily: 'Lora, serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '3.75rem', //60px
            lineHeight: '72px',
            letterSpacing: '-0.5px',
        },
        h3: {
            fontFamily: 'Lora, serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '3rem', //48px
            lineHeight: '56px',
            letterSpacing: '0px',
        },
        h4: {
            fontFamily: '"Lora", "Lora", serif',
            // fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '2.125rem', //34px
            lineHeight: '40px',
            letterSpacing: '-0.25px',
        },
        h5: {
            fontFamily: 'Lora, serif',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '1.5rem', //28px
            lineHeight: '28px',
            letterSpacing: '0px',
        },
        h6: {
            fontFamily: 'Lora, serif',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '1.25rem', //48px
            lineHeight: '24px',
            letterSpacing: '0.15px',
        },
        title1: {
            fontFamily: 'Lora, serif',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '1.5rem', //24px
            lineHeight: '28px',
            letterSpacing: '-0.5px',
        },
        subtitle1: {
            fontFamily: 'Rubik, sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '1rem', //16px
            lineHeight: '24px',
            letterSpacing: '0.15px',
        },
        subtitle2: {
            fontFamily: 'Rubik, sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '0.875rem', //14px
            lineHeight: '16px',
            letterSpacing: '0.1px',
        },
        body1: {
            fontFamily: 'Rubik, sans-serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '1rem', //16px
            lineHeight: '24px',
            letterSpacing: '0.5px',
        },
        body2: {
            fontFamily: 'Rubik, sans-serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '0.875rem', //14px
            lineHeight: '16px',
            letterSpacing: '0.25px',
        },
        caption: {
            fontFamily: 'Rubik, sans-serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '0.75rem', //12px
            lineHeight: '14px',
            letterSpacing: '0.4px',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1680,
        },
    },
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                },
                fontSizeMedium: {
                    fontSize: '1.5rem',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    letterSpacing: '0.15px',
                },
                adornedStart: {
                    fontFamily: 'Rubik, sans-serif',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    lineHeight: '1.5rem',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: 'Rubik, sans-serif',
                },
            },
        },
    },
};

const theme = createTheme(themeConfig);

export default theme;
