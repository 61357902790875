import { INTEGRATIONS_LOCATION_DICTIONARY, ProfileName, LocationIntegrationName } from '@constants/integrations';
import {
    YelpIcon,
    FacebookIcon,
    GoogleIcon,
    TrustpilotIcon,
    TripAdvisorIcon,
    BookingIcon,
    IFoodIcon,
} from 'assets/images/integrations';

export const getIntegrationNames = profiles =>
    INTEGRATIONS_LOCATION_DICTIONARY.reduce((acc, curr) => {
        const isConnected = profiles?.find(p => p.type === curr.type);
        if (isConnected) {
            return [...acc, curr.type];
        }
        return acc;
    }, []);

export const getIntegrationIcon = type => {
    switch (type) {
        case ProfileName.Yelp:
            return <YelpIcon />;
        case ProfileName.Facebook:
            return <FacebookIcon />;
        case ProfileName.Google:
            return <GoogleIcon />;
        case ProfileName.Trustpilot:
            return <TrustpilotIcon />;
        case ProfileName.TripAdvisor:
            return <TripAdvisorIcon />;
        case LocationIntegrationName.Google:
            return <GoogleIcon />;
        case ProfileName['Booking.com']:
            return <BookingIcon />;
        case LocationIntegrationName.iFood:
            return <IFoodIcon />;
        case LocationIntegrationName.Facebook:
            return <FacebookIcon />;
        default:
            return null;
    }
};
