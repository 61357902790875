import { useSelector } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import { useTranslation } from '@hooks/useTranslation';
import { COMPANY_STATUS } from '@constants/companies';

export const Form = styled('div')({
    maxWidth: 698,
    display: 'grid',
    columnGap: 24,
    rowGap: 16,
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
    '@media (max-width: 1024px)': {
        gridTemplateColumns: 'minmax(0, 1fr)',
        maxWidth: 'none',
    },
});

const GeneralInfo = ({ selectedCompany }) => {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();

    const brands = useSelector(state => state.brands.data);

    const statusOptions = [
        { value: COMPANY_STATUS.OPEN, label: t('common.open') },
        { value: COMPANY_STATUS.CLOSED, label: t('common.closed') },
    ];

    const brandsOptions = brands.map(brand => ({ value: brand.id, label: brand.title }));

    const isMainLocation = selectedCompany.brand?.isMainLocation;

    return (
        <div>
            <Form>
                <Controller
                    control={control}
                    name="internalName"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('SettingsCompanies.internalName')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="locationName"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('SettingsCompanies.locationName')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="status"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Select
                            {...field}
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            onChange={({ target: { value } }) => {
                                setValue('status', value, { shouldDirty: true });
                            }}
                            options={statusOptions}
                            label={t('common.status')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="brand"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Select
                            {...field}
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            onChange={({ target: { value } }) => {
                                setValue('brand', value, { shouldDirty: true });
                            }}
                            options={brandsOptions}
                            label={t('common.brand')}
                            fullWidth
                            disabled={isMainLocation}
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            withEndAdornment={!!field.value}
                            onClickEndAdornment={() => setValue('brand', undefined, { shouldDirty: true })}
                        />
                    )}
                />
            </Form>
        </div>
    );
};

export default GeneralInfo;
