import { FiltersIntegrationName, AccIntegrationName, LocationIntegrationName } from '@constants/integrations';
import { compareAsc, parseISO } from 'date-fns';

export const prepareReviewsAndFeedbackOverall = data => {
    let overall = data;
    if (Array.isArray(overall)) {
        overall = data.pop();
    }

    if (overall.totalReviewsDelta) {
        overall.totalReviewsDelta = overall.totalReviewsDelta.toFixed(0);
    }
    if (overall.totalResponsesDelta) {
        overall.totalResponsesDelta = overall.totalResponsesDelta.toFixed(0);
    }
    if (overall.netPromoterScoreDelta) {
        overall.netPromoterScoreDelta = overall.netPromoterScoreDelta.toFixed(0);
    }
    if (overall.negativeResponsesDelta) {
        overall.negativeResponsesDelta = overall.negativeResponsesDelta.toFixed(0);
    }

    return overall;
};

export function checkIntegration(i) {
    const mainIntegrations = [
        FiltersIntegrationName.Facebook,
        AccIntegrationName.Facebook,
        FiltersIntegrationName.Google,
        AccIntegrationName.Google,
        FiltersIntegrationName['Booking.com'],
        FiltersIntegrationName.Trustpilot,
        FiltersIntegrationName.iFood,
    ];

    const isOthers = Object.keys(i).reduce((sum, key) => {
        return mainIntegrations.includes(+key) ? sum : sum + i[key];
    }, 0);

    return {
        isGoogle: (i[FiltersIntegrationName.Google] || 0) + (i[AccIntegrationName.Google] || 0),
        isFacebook: (i[FiltersIntegrationName.Facebook] || 0) + (i[AccIntegrationName.Facebook] || 0),
        isBooking: i[FiltersIntegrationName['Booking.com']] || 0,
        isTrustpilot: i[FiltersIntegrationName.Trustpilot] || 0,
        isIFood: i[FiltersIntegrationName.iFood] || 0,
        isOthers: isOthers,
    };
}

export const prerareSentimentData = data => {
    return data
        .map(item => {
            return {
                averageRating: item.breakdown[0].averageRating.toFixed(2),
                numberOfNegativeReviews: item.breakdown[0].numberOfNegativeReviews,
                numberOfPositiveReviews: item.breakdown[0].numberOfPositiveReviews,
                dateLabel: parseISO(item.date),
            };
        })
        .sort((a, b) => compareAsc(a.dateLabel, b.dateLabel));
};

export const prepareNpsData = data => {
    return data
        .map(item => {
            return {
                promoters: item.breakdown[0].promoters,
                neutrals: item.breakdown[0].neutrals,
                detractors: item.breakdown[0].detractors,
                nps: item.breakdown[0].nps?.toFixed(2),
                dateLabel: parseISO(item.date),
            };
        })
        .sort((a, b) => compareAsc(a.dateLabel, b.dateLabel));
};

// Conversion
const prepareCompanyConversion = company => {
    return {
        id: company.companyId,
        surveysSent: company.surveysSent,
        responsesReceived: company.responsesReceived,
        responsesReceivedPercent: company.responsesReceivedPercent.toFixed(2),
        responsesReceivedChange: company.responsesReceivedChange,
        leaveReviewClicked: company.leaveReviewClicked,
        leaveReviewClickedPercent: company.leaveReviewClickedPercent.toFixed(2),
        leaveReviewClickedChange: company.leaveReviewClickedChange,
        linksClicked: company.linksClicked,
        linksClickedPercent: company.linksClickedPercent.toFixed(2),
        linksClickedChange: company.linksClickedChange,
        reviewsReceived: company.reviewsReceived,
        reviewsReceivedPercent: company.reviewsReceivedPercent.toFixed(2),
        reviewsReceivedChange: company.reviewsReceivedChange,
    };
};

const prepareConversionOverall = company => {
    return {
        surveysSent: company.surveysSent,
        responsesReceived: company.responsesReceived,
        leaveReviewClicked: company.leaveReviewClicked,
        linksClicked: company.linksClicked,
        reviewsReceived: company.reviewsReceived,
        // percents
        responsesReceivedPercent: company.responsesReceivedPercent.toFixed(2),
        surveysSentPercent: company.surveysSentPercent.toFixed(2),
        linksClickedPercent: company.linksClickedPercent.toFixed(2),
        leaveReviewClickedPercent: company.leaveReviewClickedPercent.toFixed(2),
        reviewsReceivedPercent: company.reviewsReceivedPercent.toFixed(2),
        // rates
        responseRate: company.responseRate.toFixed(2),
        responseRateDelta: company.responseRateDelta.toFixed(2),
        reviewRate: company.reviewRate.toFixed(2),
        reviewRateDelta: company.reviewRateDelta.toFixed(2),
    };
};

export const formattedConversionData = (tableData, overallData) => {
    const formattedTableData = tableData.map(data => prepareCompanyConversion(data));
    const formattedOverallData = prepareConversionOverall(overallData);

    return {
        tableData: formattedTableData,
        overall: {
            ...formattedOverallData,
            id: 'Overall',
        },
    };
};

export const handleChartPrint = (ref, parentId, size = [630, 280]) => {
    if (!ref.current) return;

    const parentContainer = document.getElementById(parentId);

    const handleBeforePrint = () => {
        if (ref.current) {
            ref.current.resize(size[0], size[1]);
        }
    };

    const handleAfterPrint = () => {
        if (ref.current) {
            ref.current.resize(parentContainer.clientWidth, 280);
        }
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
        window.removeEventListener('beforeprint', handleBeforePrint);
        window.removeEventListener('afterprint', handleAfterPrint);
    };
};

export function getPresenceOptions(companies, selectedCompanies) {
    const { googleConnected, facebookConnected } = companies
        ?.filter(c => selectedCompanies.includes(c.companyId))
        ?.map(c => c.profiles)
        .flat()
        .reduce(
            (prev, acc) => {
                if (acc.type === LocationIntegrationName.Google) {
                    return { ...prev, googleConnected: true };
                }
                if (acc.type === LocationIntegrationName.Facebook) {
                    return { ...prev, facebookConnected: true };
                }
                return prev;
            },
            { googleConnected: false, facebookConnected: false },
        );

    const platformsConnectedIds = [
        ...(googleConnected ? [LocationIntegrationName.Google] : []),
        ...(facebookConnected ? [LocationIntegrationName.Facebook] : []),
    ];

    const platformsOptions = [
        ...(googleConnected ? [{ value: LocationIntegrationName.Google, label: 'Google' }] : []),
        ...(facebookConnected ? [{ value: LocationIntegrationName.Facebook, label: 'Facebook' }] : []),
    ];

    return { platformsConnectedIds, platformsOptions };
}
