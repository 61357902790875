import { instagramConstants } from '@constants/instagramChannel';
import { channelsService } from '@services';

function request() {
    return { type: instagramConstants.INSTAGRAM_LOCATIONS_REQUEST };
}

function success(data) {
    return { type: instagramConstants.INSTAGRAM_LOCATIONS_SUCCESS, data };
}

function failure(message) {
    return { type: instagramConstants.INSTAGRAM_LOCATIONS_FAILURE, message };
}

const getInstagramLocations = () => {
    return dispatch => {
        dispatch(request());
        channelsService.getConectedInstagramLocations().then(
            data => dispatch(success(data)),
            () => dispatch(failure()),
        );
    };
};

export const instagramChannelActions = {
    getInstagramLocations,
};
