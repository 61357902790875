import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@hooks/useTranslation';
import { Typography } from '@components/Typography';
import SplitButton from '@components/SplitButton';
import Button from '@components/Button';
import {
    SalesforceIcon,
    HubspotIcon,
    PipeDriveIcon,
    XeroIcon,
    QuickBooksIcon,
    GoogleIcon,
    ApiIcon,
    IFoodIcon,
    CoverManagerIcon,
    VTEXIcon,
    FacebookIcon,
} from 'assets/images/integrations';
import zapier from 'assets/images/integrations/zapier.png';
import {
    GOOGLE_OAUTH_URL,
    AccIntegrationName,
    INTEGRATIONS_ACCOUNT_DICTIONARY,
    ApiIntegrationLinks,
} from '@constants/integrations';
import {
    googleIntegrationService,
    ifoodIntegrationService,
    vtexIntegrationService,
    coverManagerIntegrationService,
    facebookIntegrationService,
} from '@services';
import { alertActions, accountActions, googleActions } from '@actions';
import { useHubspotChat } from '@hooks/useHubspotChat';
import { useQueryParams } from '@hooks/useQueryParams';
import ZapierModal from '@features/ZapierModal';
import IFoodModal from './IFoodModal';
import VTEXModal from './VTEXModal';
import CoverManagerModal from './CoverManagerModal';
import { getFacebookLoginStatus, loginFacebook, loginMetaBusiness } from '@helpers/facebook-sdk';
import { createProxyObject } from '@helpers/createProxyObject';
import { Container, Card, Status } from './styles';

const GOOGLE_ERROR = {
    CANCEL: 'access_denied',
};

const Integrations = () => {
    const { t } = useTranslation();

    const [zapierOpen, setZapierOpen] = useState(false);
    const [iFoodModalOpen, setIFoodModalOpen] = useState(false);
    const [vtexModalOpen, setVtexModalOpen] = useState(false);
    const [coverManagerModalOpen, setCoverManagerModalOpen] = useState(false);

    const account = useSelector(state => state.account?.account);
    const language = useSelector(state => state.authentication.user?.language);
    const dispatch = useDispatch();
    const { openHandler } = useHubspotChat();
    const query = useQueryParams();
    const history = useHistory();

    const ICONS = {
        [AccIntegrationName['API integration']]: <ApiIcon style={{ height: 40, width: 40 }} />,
        [AccIntegrationName.Zapier]: <img src={zapier} alt="zapier" style={{ height: 40, marginBottom: 8 }} />,
        [AccIntegrationName.Google]: <GoogleIcon />,
        [AccIntegrationName.Salesforce]: <SalesforceIcon />,
        [AccIntegrationName.Xero]: <XeroIcon />,
        [AccIntegrationName.Pipedrive]: <PipeDriveIcon />,
        [AccIntegrationName.Hubspot]: <HubspotIcon />,
        [AccIntegrationName.QuickBooks]: <QuickBooksIcon />,
        [AccIntegrationName.iFood]: <IFoodIcon />,
        [AccIntegrationName.VTEX]: <VTEXIcon />,
        [AccIntegrationName.CoverManager]: <CoverManagerIcon />,
        [AccIntegrationName.Facebook]: <FacebookIcon />,
    };

    const INTEGRATIONS_MAP = useMemo(() => {
        const CustomTextMap = createProxyObject({
            [AccIntegrationName['API integration']]: t('buttons.documentation'),
            default: t('buttons.contactSupport'),
        });
        const CustomClickMap = createProxyObject({
            [AccIntegrationName['API integration']]: () => window.open(ApiIntegrationLinks[language]),
            default: openHandler,
        });
        return INTEGRATIONS_ACCOUNT_DICTIONARY.map(item => {
            return {
                ...item,
                connected: account?.integrations.find(i => i.type === item.id)?.isActive,
                canConnect:
                    item.id === AccIntegrationName.Google ||
                    item.id === AccIntegrationName.Zapier ||
                    item.id === AccIntegrationName.iFood ||
                    item.id === AccIntegrationName.CoverManager ||
                    item.id === AccIntegrationName.VTEX ||
                    item.id === AccIntegrationName.Facebook,
                customText: CustomTextMap[item.id],
                customClick: CustomClickMap[item.id],
                buttonType: item.id === AccIntegrationName.Facebook ? 'splitButton' : 'button',
            };
        });
    }, [account]);

    useEffect(() => {
        const code = query.get('code');
        const error = query.get('error');
        if (error === GOOGLE_ERROR.CANCEL) {
            history.replace('/account-settings/integrations');
        } else if (code && account) {
            connectGoogleIntegration(code);
        }
    }, [query, history, account]);

    const connectGoogleIntegration = async code => {
        try {
            await googleIntegrationService.authenticateGoogleIntegration({
                accountId: account?.id,
                code,
                redirectUri: `${window.location.origin}/account-settings/integrations`,
            });
            history.replace('/account-settings/integrations');
            const locations = await dispatch(googleActions.get());
            if (locations.length === 0) {
                dispatch(alertActions.error(t('SettingsIntegrations.googleNoLocations')));
                await googleIntegrationService.revokeGoogleIntegration();
            } else {
                dispatch(alertActions.success(t('alertMessages.connectIntegrationSuccess')));
                dispatch(accountActions.get());
            }
        } catch (error) {
            history.replace('/account-settings/integrations');
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.connectIntegrationFail')));
            }
        }
    };

    const connectFacebookIntegration = async (data, splitButtonState) => {
        const { accessToken, expiresIn, userID, code } = data;
        try {
            if (splitButtonState === 0) {
                if (!accessToken) return;
                await facebookIntegrationService.authenticateFacebookIntegration({
                    accessToken,
                    expiresIn,
                    userID,
                });
            } else {
                if (!code) return;
                await facebookIntegrationService.authenticateMetaBusinessIntegration({
                    code,
                });
            }
            dispatch(alertActions.success(t('alertMessages.connectIntegrationSuccess')));
            dispatch(accountActions.get());
        } catch (error) {
            if (error.errorCode) {
                if (error.errorCode === 'FACEBOOK_INVALID_TOKEN') {
                    console.error('FACEBOOK_INVALID_TOKEN');
                }
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.connectIntegrationFail')));
            }
        }
    };

    const onConnectFacebook = async splitButtonState => {
        try {
            const { status } = await getFacebookLoginStatus();
            if (!status) return;

            const response = splitButtonState === 0 ? await loginFacebook() : await loginMetaBusiness();
            if (response.authResponse && response.status === 'connected') {
                connectFacebookIntegration(response.authResponse, splitButtonState);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };

    const IntegrationMapCb = {
        [AccIntegrationName.Google]: {
            apiCall: () => googleIntegrationService.revokeGoogleIntegration(),
            notConnectedCb: () => (window.location = GOOGLE_OAUTH_URL),
        },
        [AccIntegrationName.iFood]: {
            apiCall: () => ifoodIntegrationService.disconnectIntegration(),
            notConnectedCb: () => setIFoodModalOpen(true),
        },
        [AccIntegrationName.VTEX]: {
            apiCall: () => vtexIntegrationService.disconnectIntegration(),
            notConnectedCb: () => setVtexModalOpen(true),
        },
        [AccIntegrationName.CoverManager]: {
            apiCall: () => coverManagerIntegrationService.disconnectIntegration(),
            notConnectedCb: () => setCoverManagerModalOpen(true),
        },
        [AccIntegrationName.Facebook]: {
            apiCall: () => facebookIntegrationService.disconnectIntegration(),
            notConnectedCb: splitButtonState => onConnectFacebook(splitButtonState),
        },
    };

    const onClickIntegration = async (type, splitButtonState) => {
        const isConnected = INTEGRATIONS_MAP.find(i => i.id === type)?.connected;
        switch (type) {
            case AccIntegrationName.Google:
            case AccIntegrationName.iFood:
            case AccIntegrationName.VTEX:
            case AccIntegrationName.CoverManager:
            case AccIntegrationName.Facebook:
                if (isConnected) {
                    try {
                        await IntegrationMapCb[type].apiCall();
                        dispatch(alertActions.success(t('alertMessages.disconnectIntegrationSuccess')));
                        dispatch(accountActions.get());
                    } catch (error) {
                        dispatch(alertActions.error(t('apiErrors.google_access_revoke_error')));
                    }
                } else {
                    IntegrationMapCb[type].notConnectedCb(splitButtonState);
                }
                return;
            case AccIntegrationName['API integration']:
                window.open(ApiIntegrationLinks[language]);
                return;
            default:
                setZapierOpen(true);
                return;
        }
    };

    return (
        <>
            <Container>
                {INTEGRATIONS_MAP.map(item => (
                    <Card key={item.id} elevation={1}>
                        <span style={{ marginBottom: 8 }}>{ICONS[item.id]}</span>
                        <Typography variant="title1" sx={{ marginBottom: '16px', color: 'rgba(0, 0, 0, 0.87)' }}>
                            {item.name}
                        </Typography>
                        {account ? (
                            <Status
                                isConnected={item.connected}
                                isHidden={
                                    item.id !== AccIntegrationName.Google &&
                                    item.id !== AccIntegrationName.iFood &&
                                    item.id !== AccIntegrationName.CoverManager &&
                                    item.id !== AccIntegrationName.VTEX &&
                                    item.id !== AccIntegrationName.Facebook
                                }
                            >
                                {item.connected
                                    ? t('SettingsIntegrations.connected')
                                    : t('SettingsIntegrations.notConnected')}
                            </Status>
                        ) : (
                            <Status>...</Status>
                        )}
                        {item.canConnect ? (
                            item.buttonType === 'button' || item.connected ? (
                                <Button
                                    type="button"
                                    variant={item.connected ? 'danger' : 'outlined'}
                                    sx={{ marginTop: 'auto' }}
                                    onClick={() => onClickIntegration(item.id)}
                                >
                                    {item.connected ? t('buttons.disconnect') : t('buttons.connect')}
                                </Button>
                            ) : (
                                <SplitButton
                                    variant="outlined"
                                    options={[
                                        {
                                            text: t('buttons.connect'),
                                            onClick: () => onClickIntegration(item.id, 0),
                                        },
                                        {
                                            text: t('buttons.connectWithMeta'),
                                            onClick: () => onClickIntegration(item.id, 1),
                                        },
                                    ]}
                                />
                            )
                        ) : (
                            <Button
                                type="button"
                                variant="outlined"
                                sx={{ marginTop: 'auto' }}
                                onClick={item.customClick}
                            >
                                {item.customText}
                            </Button>
                        )}
                    </Card>
                ))}
            </Container>
            <ZapierModal isOpen={zapierOpen} handleClose={() => setZapierOpen(false)} />
            {iFoodModalOpen ? <IFoodModal isOpen={iFoodModalOpen} onClose={() => setIFoodModalOpen(false)} /> : null}
            {vtexModalOpen ? <VTEXModal isOpen={vtexModalOpen} onClose={() => setVtexModalOpen(false)} /> : null}
            {coverManagerModalOpen ? (
                <CoverManagerModal isOpen={coverManagerModalOpen} onClose={() => setCoverManagerModalOpen(false)} />
            ) : null}
        </>
    );
};

export default Integrations;
