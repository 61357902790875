import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@components/Typography';
import { Input } from '@components/Input';
import { Checkbox } from '@components/Checkbox';
import { MultiSelect } from '@components/MultiSelect';
import { MultiSelectLabels } from '@components/MultiSelectLabels';
import MergeContactsModal from '@features/MergeContactsModal';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { alertActions, contactsActions, conversationsActions, feedbacksActions, reviewActions } from '@actions';
import { contactsService } from '@services';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { getFullName } from '@helpers/userName';
import { MODAL_TYPE, CONTACT_MODAL_TYPE } from '@constants/modal';
import { useTranslation } from '@hooks/useTranslation';
import { Modal, Content, ActivityWrapper, CheckboxWrapper, StyledInstagramInput, StyledFacebookInput } from './styles';
import { LABEL_TYPE } from '@constants/labels';

const EditContactModal = ({
    modal,
    onClose,
    setDeleteModal,
    withRefetchContacts,
    withRefetchSurveys,
    withRefetchReviews,
    withDeleteContact,
    withRefetchConversations,
    withUpdateConversations,
    refetchCallBack,
    setSelectedConversation = null,
}) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isMergeModalOpen, setMergeModalOpen] = useState(false);

    const account = useSelector(state => state.account?.account);
    const { data, contact } = useSelector(state => state.contacts);
    const { contact: labels, location: locationLabels } = useSelector(state => state.labels);
    const { data: conversations } = useSelector(state => state.conversations);
    const { feedbacks } = useSelector(state => state.feedbacks);
    const { reviews } = useSelector(state => state.reviews);
    const isMobile = useMediaQuery('@media (max-width: 600px)');
    const dispatch = useDispatch();

    const labelsOptions = useMemo(() => labels.map(item => ({ value: item.id, label: item.name })), [labels]);

    const ContactSchema = yup.object().shape(
        {
            firstName: yup.string().required('validation.required'),
            email: yup.string().when('email', {
                is: val => !val?.length,
                then: yup.string().notRequired(),
                otherwise: yup.string().required('validation.required').email('validation.wrongEmailFormat'),
            }),
            phoneNumber: yup.string().validatePhone('validation.wrongPhoneFormat'),
            companies: yup.array().min(1, 'validation.required'),
        },
        [
            ['email', 'email'],
            ['phoneNumber', 'phoneNumber'],
        ],
    );

    const resolver = useYupValidationResolver(ContactSchema);

    const {
        getValues,
        setValue,
        control,
        watch,
        formState: { isValid, isDirty },
    } = useForm({
        mode: 'onBlur',
        resolver,
        defaultValues: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            facebookProfile: '',
            instagramProfile: '',
            companies: [],
            unsubscribed: false,
            labels: [],
            locationLabels: [],
        },
    });

    const selectedLocationLabels = watch('locationLabels');

    const locationLabelsOptions = useMemo(() => {
        const availableLabels = [...new Set(account?.companies.map(item => item.labels).flat())];
        return locationLabels
            .filter(l => availableLabels.includes(l.id))
            .map(item => ({ value: item.id, label: item.name }));
    }, [account?.companies, locationLabels]);

    const companyOptions = useMemo(() => {
        return selectedLocationLabels.length === locationLabelsOptions.length
            ? account?.companies.map(item => ({ value: item.companyId, label: item.internalName }))
            : account?.companies
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [account?.companies, locationLabelsOptions, selectedLocationLabels]);

    useEffect(() => {
        const availableLabels = [...new Set(account?.companies.map(item => item.labels).flat())];
        const labelsIds = locationLabels.filter(l => availableLabels.includes(l.id)).map(item => item.id);
        setValue('locationLabels', labelsIds, { shouldValidate: true });
    }, [locationLabels]);

    useEffect(() => {
        if (modal.type === MODAL_TYPE.EDIT) {
            setValue('firstName', contact?.firstName || '', { shouldValidate: true });
            setValue('lastName', contact?.lastName || '');
            setValue('phoneNumber', contact?.phoneNumber || '', { shouldValidate: true });
            setValue('email', contact?.email || '', { shouldValidate: true });
            setValue('facebookProfile', contact?.facebookProfile || '', { shouldValidate: true });
            setValue('instagramProfile', contact?.instagramProfile || '', { shouldValidate: true });
            setValue('companies', contact?.companies || [], { shouldValidate: true });
            setValue('labels', contact?.labels?.map(l => l.id) || [], { shouldValidate: true });
            setValue('unsubscribed', !!contact?.unsubscribed, { shouldValidate: true });
        }
    }, [contact, modal.type]);

    const clearForm = () => {
        setValue('firstName', '', { shouldValidate: false, shouldDirty: false });
        setValue('lastName', '', { shouldValidate: false, shouldDirty: false });
        setValue('phoneNumber', '', { shouldValidate: false, shouldDirty: false });
        setValue('email', '', { shouldValidate: false, shouldDirty: false });
        setValue('facebookProfile', '', { shouldValidate: false, shouldDirty: false });
        setValue('instagramProfile', '', { shouldValidate: false, shouldDirty: false });
        setValue('companies', [], { shouldValidate: false, shouldDirty: false });
        setValue('labels', [], { shouldValidate: false, shouldDirty: false });
        setValue('unsubscribed', false, { shouldValidate: false, shouldDirty: false });
    };

    const onCloseModal = () => {
        onClose();
        clearForm();
    };

    const onSaveContact = async () => {
        const values = getValues();
        setIsSubmitting(true);
        try {
            const newContact = await contactsService.createOrUpdateContact({
                ...contact,
                ...values,
                labels: labels.filter(label => values.labels.includes(label.id)),
                ...(modal.type === MODAL_TYPE.EDIT && { id: contact?.id }),
                accountId: account?.id,
            });
            setIsSubmitting(false);
            if (modal.type === MODAL_TYPE.CREATE && withRefetchContacts) {
                refetchCallBack();
            } else {
                const contactsCopy = data.slice();
                const updatedContactIndex = contactsCopy.findIndex(c => c.id === contact?.id);
                if (updatedContactIndex !== -1 && newContact) {
                    contactsCopy[updatedContactIndex] = newContact;
                    dispatch(contactsActions.setContacts(contactsCopy));
                }
                dispatch(contactsActions.setContact(newContact));
                if (withUpdateConversations) {
                    const conversationsCopy = conversations.slice();
                    conversationsCopy.forEach(conversation => {
                        if (conversation.contactId === contact?.id) {
                            conversation.firstName = newContact?.firstName;
                            conversation.lastName = newContact?.lastName;
                            conversation.phoneNumber = newContact?.phoneNumber;
                            conversation.email = newContact?.email;
                        }
                    });
                    dispatch(conversationsActions.update(conversationsCopy));
                    setSelectedConversation(prev => ({
                        ...prev,
                        firstName: newContact?.firstName,
                        lastName: newContact?.lastName,
                        phoneNumber: newContact?.phoneNumber,
                        email: newContact?.email,
                    }));
                }
                if (withRefetchSurveys) {
                    const surveysCopy = feedbacks.slice();
                    surveysCopy.forEach(survey => {
                        if (survey.contactId === contact?.id) {
                            survey.contactName = getFullName(newContact?.firstName, newContact?.lastName);
                        }
                    });
                    dispatch(feedbacksActions.update(surveysCopy));
                }
                if (withRefetchReviews) {
                    const reviewsCopy = reviews.slice();
                    reviewsCopy.forEach(review => {
                        if (review.contactId === contact?.id) {
                            review.contactName = getFullName(newContact?.firstName, newContact?.lastName);
                        }
                    });
                    dispatch(reviewActions.updateReviews(reviewsCopy));
                }
            }
            dispatch(
                alertActions.success(
                    modal.type === MODAL_TYPE.CREATE
                        ? t('alertMessages.createSuccess')
                        : t('alertMessages.editSuccess'),
                ),
            );
            onCloseModal();
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(
                    alertActions.error(
                        modal.type === MODAL_TYPE.CREATE ? t('alertMessages.createFail') : t('alertMessages.editFail'),
                    ),
                );
            }
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Modal
                isOpen={modal.open}
                handleClose={onCloseModal}
                title={modal.type === MODAL_TYPE.CREATE ? t('ContactsPage.createTitle') : t('ContactsPage.editTitle')}
                subtitle={
                    <Typography variant="caption" color="textSecondary">
                        {modal.type === MODAL_TYPE.CREATE
                            ? t('ContactsPage.createSubtitle')
                            : t('ContactsPage.editSubtitle')}
                    </Typography>
                }
                onPrimaryAction={onSaveContact}
                primaryActionText={modal.type === MODAL_TYPE.CREATE ? t('buttons.create') : t('buttons.saveChanges')}
                primaryActionDisabled={isSubmitting || !isValid || !isDirty}
                onSecondaryAction={onCloseModal}
                secondaryActionText={t('buttons.cancel')}
                onAdditionalAction={
                    modal.type === MODAL_TYPE.EDIT && withDeleteContact
                        ? () => setDeleteModal({ open: true, type: CONTACT_MODAL_TYPE.DIRECT })
                        : null
                }
                additionalTextButton={modal.type === MODAL_TYPE.EDIT ? t('buttons.delete') : null}
                onSecondAdditionalAction={modal.type === MODAL_TYPE.EDIT ? () => setMergeModalOpen(true) : null}
                secondAdditionalTextButton={modal.type === MODAL_TYPE.EDIT ? t('buttons.merge') : null}
            >
                <Content>
                    <Controller
                        control={control}
                        name="firstName"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('common.firstName')}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                required
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="lastName"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('common.lastName')}
                                fullWidth
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="phoneNumber"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('common.phoneNumber')}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="email"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('common.email')}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="facebookProfile"
                        render={({ field, fieldState: { error }, ref }) => (
                            <StyledFacebookInput
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('ContactsPage.facebookProfile')}
                                fullWidth
                                startAdornment="facebook.com/"
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="instagramProfile"
                        render={({ field, fieldState: { error }, ref }) => (
                            <StyledInstagramInput
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('ContactsPage.instagramProfile')}
                                fullWidth
                                startAdornment="instagram.com/"
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="locationLabels"
                        render={({ field, ref }) => (
                            <MultiSelect
                                {...field}
                                ref={ref}
                                label={t('common.locationLabels')}
                                onChange={({ target: { value } }) => {
                                    const selectedLabels = getMultiselectSelectedOptions(locationLabelsOptions, value);
                                    setValue('locationLabels', selectedLabels, { shouldDirty: true });
                                    const availableCompanies =
                                        selectedLabels.length === locationLabelsOptions.length
                                            ? account?.companies.map(item => item.companyId)
                                            : account?.companies
                                                  .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                                                  .map(item => item.companyId);
                                    setValue('companies', availableCompanies, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                    });
                                }}
                                options={locationLabelsOptions}
                                countable
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="companies"
                        render={({ field, fieldState: { error }, ref }) => (
                            <MultiSelect
                                {...field}
                                ref={ref}
                                onChange={({ target: { value } }) => {
                                    setValue('companies', getMultiselectSelectedOptions(companyOptions, value), {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                    });
                                }}
                                options={companyOptions}
                                label={t('common.locations')}
                                fullWidth
                                countable
                                required
                                error={error}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="labels"
                        render={({ field, ref }) => (
                            <div style={{ gridColumn: isMobile ? '1 / span 1' : '1 / span 2' }}>
                                <MultiSelectLabels
                                    {...field}
                                    ref={ref}
                                    onChange={({ target: { value } }) => {
                                        setValue('labels', getMultiselectSelectedOptions(labelsOptions, value), {
                                            shouldDirty: true,
                                        });
                                    }}
                                    options={labelsOptions}
                                    label={t('common.labels')}
                                    fullWidth
                                    type={LABEL_TYPE.CONTACT}
                                    onUpdateCallback={() => {
                                        if (withRefetchContacts) {
                                            refetchCallBack();
                                        }
                                    }}
                                    onDeleteCallback={() => {
                                        if (withRefetchContacts) {
                                            refetchCallBack();
                                        }
                                    }}
                                    onCreateCallback={newLabel => {
                                        const values = getValues();
                                        setValue('labels', [...values.labels, newLabel], {
                                            shouldDirty: true,
                                        });
                                    }}
                                />
                            </div>
                        )}
                    />
                    {modal.type === MODAL_TYPE.EDIT ? (
                        <>
                            <CheckboxWrapper>
                                <Controller
                                    control={control}
                                    name="unsubscribed"
                                    render={({ field, ref }) => (
                                        <Checkbox
                                            ref={ref}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={e =>
                                                setValue('unsubscribed', e.target.checked, { shouldDirty: true })
                                            }
                                            checked={field.value}
                                        />
                                    )}
                                />
                                <Typography variant="body2">{t('ContactsPage.unsubscribe')}</Typography>
                            </CheckboxWrapper>
                            <ActivityWrapper>
                                {contact?.createdOn ? (
                                    <div>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            sx={{ marginBottom: '6px' }}
                                        >
                                            {t('ContactsPage.createdOn')}
                                        </Typography>
                                        <Typography variant="body2">
                                            {format(new Date(contact?.createdOn), 'dd MMM yyyy')}
                                        </Typography>
                                    </div>
                                ) : null}
                                {contact?.lastActiveOn ? (
                                    <div>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            sx={{ marginBottom: '6px' }}
                                        >
                                            {t('ContactsPage.lastActiveOn')}
                                        </Typography>
                                        <Typography variant="body2">
                                            {format(new Date(contact?.lastActiveOn), 'dd MMM yyyy, HH:mm')}
                                        </Typography>
                                    </div>
                                ) : null}
                            </ActivityWrapper>
                        </>
                    ) : null}
                </Content>
            </Modal>
            {isMergeModalOpen ? (
                <MergeContactsModal
                    modal={isMergeModalOpen}
                    setModal={setMergeModalOpen}
                    closeEditModal={() => onClose()}
                    refetchCallBack={refetchCallBack}
                    withRefetchContacts={withRefetchContacts}
                    withRefetchConversations={withRefetchConversations}
                    withRefetchSurveys={withRefetchSurveys}
                    withRefetchReviews={withRefetchReviews}
                />
            ) : null}
        </>
    );
};

export default EditContactModal;
