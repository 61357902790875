import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Input } from '@components/Input';
import { Checkbox } from '@components/Checkbox';
import { Select } from '@components/Select';
import { Typography } from '@components/Typography';
import { DeleteIcon } from 'assets/images/icons';
import { useTranslation } from '@hooks/useTranslation';

export const CustomFieldType = {
    TEXT: 0,
    NUMBER: 1,
    DATE: 2,
};

const AdditionalFieldWrapper = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
    gridGap: '0 16px',
    marginTop: '16px',
});

const PlatformWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    transform: 'translateX(-7px)',
    '& .MuiCheckbox-root': {
        padding: 4,
    },
});

const AdditionalField = ({ index, isView, setPreview }) => {
    const { t } = useTranslation();

    const { control, setValue, remove } = useFormContext();
    return (
        <AdditionalFieldWrapper>
            <Controller
                control={control}
                name={`customFields[${index}].name`}
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{
                            ...field,
                            onChange: e => {
                                field.onChange(e.target.value);
                                setPreview(prev => {
                                    prev.customFields[index].name = e.target.value;
                                    return { ...prev, customFields: prev.customFields };
                                });
                            },
                        }}
                        label={t('common.name')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                        disabled={isView}
                    />
                )}
            />
            <Controller
                control={control}
                name={`customFields[${index}].type`}
                render={({ field, ref }) => (
                    <Select
                        {...field}
                        onChange={e => {
                            field.onChange(e.target.value);
                            setPreview(prev => {
                                prev.customFields[index].type = e.target.value;
                                return { ...prev, customFields: prev.customFields };
                            });
                        }}
                        ref={ref}
                        label={t('common.type')}
                        fullWidth
                        options={[
                            { label: 'Text', value: CustomFieldType.TEXT },
                            { label: 'Number', value: CustomFieldType.NUMBER },
                            { label: 'Date', value: CustomFieldType.DATE },
                        ]}
                        disabled={isView}
                    />
                )}
            />
            <PlatformWrapper>
                <Controller
                    control={control}
                    name={`customFields[${index}].required`}
                    render={({ field, ref }) => (
                        <Checkbox
                            ref={ref}
                            disabled={isView}
                            inputProps={{ 'aria-label': 'controlled' }}
                            onChange={e => {
                                setValue(`customFields[${index}].required`, e.target.checked, {
                                    shouldDirty: true,
                                });
                                setPreview(prev => {
                                    prev.customFields[index].required = e.target.checked;
                                    return { ...prev, customFields: prev.customFields };
                                });
                            }}
                            checked={field.value}
                        />
                    )}
                />
                <Typography variant="body2">{t('common.required')}</Typography>
            </PlatformWrapper>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                    sx={{
                        padding: '4px',
                        marginRight: '1px',
                        transform: 'translateX(-7px)',
                        '& svg path': {
                            fill: 'rgba(0, 0, 0, 0.6)',
                        },
                    }}
                    onClick={() => {
                        remove(index);
                        setPreview(prev => {
                            return { ...prev, customFields: prev.customFields.filter((_, i) => i !== index) };
                        });
                    }}
                >
                    <DeleteIcon width={20} height={20} />
                </IconButton>
                <Typography variant="body2">{t('FeedbackSurveys.deleteField')}</Typography>
            </div>
        </AdditionalFieldWrapper>
    );
};

export default AdditionalField;
