import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import MuiAvatar from '@mui/material/Avatar';

export const StyledAvatar = styled(MuiAvatar)(({ size }) => ({
    border: '0.5px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.38)',
    zIndex: 2,
    ...(size === 'xSmall' && {
        width: 20,
        height: 20,
        fontSize: 8,
    }),
    ...(size === 'small' && {
        width: 24,
        height: 24,
    }),
    ...(size === 'medium' && {
        width: 32,
        height: 32,
    }),
    ...(size === 'large' && {
        width: 40,
        height: 40,
        fontSize: 16,
    }),
}));

const StyledBadge = styled(Badge)(({ size }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#fff',
        height: 16,
        minWidth: 16,
        padding: 0,
        zIndex: 3,
        border: '.5px solid rgba(0, 0, 0, 0.12)',
        '& svg': {
            width: 10,
            height: 10,
            display: 'flex',
        },
        ...(size === 'small' && {
            height: 10,
            minWidth: 10,
            '& svg': {
                width: 6,
                height: 6,
            },
        }),
        ...(size === 'medium' && {
            height: 12,
            minWidth: 12,
        }),
    },
}));

function stringToHslColor(str, saturation, lightness) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
}

const Avatar = ({ label, icon, image, fullName, className, size, withBadge, badge, sx }) => {
    return (
        <div>
            <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={withBadge && <span style={{ display: 'flex' }}>{badge}</span>}
            >
                <StyledAvatar
                    size={size}
                    className={className}
                    src={image}
                    sx={{
                        backgroundColor: fullName && stringToHslColor(fullName, 30, 85),
                        color: fullName && stringToHslColor(fullName, 30, 25),
                        ...sx,
                    }}
                >
                    {label ? label : icon}
                </StyledAvatar>
            </StyledBadge>
        </div>
    );
};

export default Avatar;
