import { Fragment, useCallback, useState, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { LabelChip } from '@components/Label';
import { CssNoOptions } from '@components/Select';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import {
    StyledAutocomplete,
    StyledPopper,
    ErrorWrapper,
    AllOption,
    OneGroupOption,
    GroupOption,
    GroupWrapper,
    GroupHeader,
    BaseOptionWrapper,
    CssCheckbox,
    ErrText,
} from './styles';

const BaseOptionComponent = ({ id, label, ...props }) => {
    return (
        <BaseOptionWrapper key={id} component="li" {...props}>
            <Typography variant="body2">{label}</Typography>
        </BaseOptionWrapper>
    );
};

const Autocomplete = ({
    className,
    label,
    options,
    onChangeAutoComplete,
    ValueComponent,
    OptionComponent,
    optionLabelProp = 'label',
    inputProps = {},
    readOnly,
    inputRef,
    error,
    groupMultiProps = null,
    groupSelectProps = null,
    ...props
}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [isFocused, setIsFocused] = useState(false);

    const { t } = useTranslation();

    const extendedOptions = [...(groupMultiProps ? [{ id: 'all', label: t('common.all') }] : []), ...options];

    const checkOption = option => selectedItems.some(c => c.id === option.id);

    const checkAllOption = () => {
        return {
            all: options.length === selectedItems.length,
            some: selectedItems.length > 0 && options.length !== selectedItems.length,
        };
    };

    const selectAll = () => {
        const selectAllItems = options.length === selectedItems.length ? [] : options;
        setSelectedItems(selectAllItems);
        onChangeAutoComplete(selectAllItems);
    };

    const checkGroup = group => {
        const groupLength = options.filter(c => c.group === group).length;
        const selectedGroupLength = selectedItems.filter(c => c.group === group).length;
        return {
            all: groupLength === selectedGroupLength,
            some: selectedGroupLength > 0 && groupLength !== selectedGroupLength,
        };
    };

    const selectGroup = group => {
        const groupedOptions = options.filter(c => c.group === group);
        const selectedGroupedOptions = selectedItems.filter(c => c.group === group);

        if (selectedGroupedOptions.length > 0) {
            setSelectedItems(prevState => [...prevState.filter(c => c.group !== group)]);
        } else {
            setSelectedItems(prevState => [...prevState, ...groupedOptions]);
        }
    };

    const hasOneGroup = useMemo(
        () => (groupMultiProps || groupSelectProps) && new Set(options.map(option => option.group)).size === 1,
        [options],
    );

    const customPopper = useCallback(popperProps => <StyledPopper {...popperProps} />, []);

    return (
        <ErrorWrapper error={!!error} className={className}>
            <StyledAutocomplete
                {...props}
                multiple
                open
                options={extendedOptions}
                readOnly={readOnly}
                value={props?.value || selectedItems}
                getOptionLabel={option => option.id}
                renderInput={params => (
                    <TextField
                        {...params}
                        ref={inputRef}
                        variant="outlined"
                        label={label}
                        required={props.required}
                        inputProps={{
                            ...params.inputProps,
                            ...(groupMultiProps
                                ? {}
                                : {
                                      onFocus: () => setIsFocused(true),
                                      onBlur: () => setIsFocused(false),
                                      ...inputProps,
                                  }),
                        }}
                        error={!!error}
                    />
                )}
                onChange={(_, items, reason) => {
                    setSelectedItems(items);
                    onChangeAutoComplete(items);
                    if (!groupMultiProps) setIsFocused(reason === 'clear');
                }}
                {...(groupMultiProps
                    ? {
                          inputValue: '',
                          disableCloseOnSelect: true,
                          groupBased: !!groupMultiProps,
                          groupBasedSelected: selectedItems.length > 0,
                          groupBy: option => option.group,
                          renderOption: (props, option) => {
                              const { all, some } = checkAllOption();
                              return option.id === 'all' ? (
                                  <AllOption
                                      component="li"
                                      key={option.id}
                                      {...props}
                                      aria-selected={all}
                                      onClick={selectAll}
                                  >
                                      <CssCheckbox checked={all} indeterminate={some} />
                                      <Typography variant="body2" sx={{ lineHeight: '32px !important' }}>
                                          {t('common.all')}
                                      </Typography>
                                  </AllOption>
                              ) : hasOneGroup ? (
                                  <OneGroupOption
                                      key={option.id}
                                      component="li"
                                      {...props}
                                      aria-selected={checkOption(option)}
                                  >
                                      <CssCheckbox checked={checkOption(option)} />
                                      <Typography variant="body2">{option.label}</Typography>
                                  </OneGroupOption>
                              ) : (
                                  <GroupOption key={option.id} component="li" {...props}>
                                      <CssCheckbox checked={checkOption(option)} />
                                      <Typography variant="body2">{option.label}</Typography>
                                  </GroupOption>
                              );
                          },
                          renderGroup: params => {
                              if (!params.group || hasOneGroup)
                                  return <GroupWrapper key={params.key}>{params.children}</GroupWrapper>;
                              const { all, some } = checkGroup(params.group);
                              return (
                                  <Fragment key={params.key}>
                                      <div
                                          style={{ display: 'flex', alignItems: 'center', gap: '3px', margin: '3px 0' }}
                                      >
                                          <i />
                                          <CssCheckbox
                                              checked={all}
                                              indeterminate={some}
                                              onChange={() => selectGroup(params.group)}
                                          />
                                          <GroupHeader variant="caption">{params.group}</GroupHeader>
                                      </div>
                                      <GroupWrapper>{params.children}</GroupWrapper>
                                  </Fragment>
                              );
                          },
                          renderTags: () => {
                              return selectedItems.map((option, index) => (
                                  <LabelChip
                                      key={index}
                                      type={option.label}
                                      label={
                                          <Typography variant="caption" sx={{ display: 'block', height: 14 }}>
                                              {option.label}
                                          </Typography>
                                      }
                                      clickable
                                      deleteIcon={<CloseIcon onMouseDown={e => e.stopPropagation()} />}
                                      onDelete={() => {
                                          const filteredItems = selectedItems.filter(item => item.id !== option.id);
                                          setSelectedItems(filteredItems);
                                          onChangeAutoComplete(filteredItems);
                                      }}
                                      sx={{ margin: '0 3px 3px 0' }}
                                  />
                              ));
                          },
                          ...groupMultiProps,
                      }
                    : {
                          open: isFocused,
                          renderOption: (props, option) =>
                              OptionComponent ? (
                                  <OptionComponent key={option.id} option={option} {...props} />
                              ) : (
                                  <BaseOptionComponent
                                      key={option.id}
                                      id={option.id}
                                      label={option[optionLabelProp]}
                                      {...props}
                                  />
                              ),
                          renderTags: options => {
                              return options.map(option =>
                                  ValueComponent ? (
                                      <ValueComponent key={option.id} option={option} {...props} />
                                  ) : (
                                      <BaseOptionComponent
                                          key={option.id}
                                          id={option.id}
                                          label={option[optionLabelProp]}
                                      />
                                  ),
                              );
                          },
                          ...(groupSelectProps
                              ? {
                                    inputValue: '',
                                    groupBy: option => option.group,
                                    renderGroup: params => {
                                        return hasOneGroup ? (
                                            <GroupWrapper key={params.key}>{params.children}</GroupWrapper>
                                        ) : (
                                            <Fragment key={params.key}>
                                                <GroupHeader variant="caption" sx={{ padding: '0 16px' }}>
                                                    {params.group}
                                                </GroupHeader>
                                                <GroupWrapper>{params.children}</GroupWrapper>
                                            </Fragment>
                                        );
                                    },
                                    ...groupSelectProps,
                                }
                              : {}),
                      })}
                noOptionsText={<CssNoOptions>{t('common.noOptions')}</CssNoOptions>}
                PopperComponent={customPopper}
                ListboxProps={{
                    sx: {
                        overflow: 'auto !important',
                        '& .MuiAutocomplete-option': {
                            minHeight: '32px',
                            flexWrap: 'nowrap',
                            '@media (max-width: 600px)': {
                                width: 'calc(100% - 16px)',
                            },
                        },
                    },
                }}
                popupIcon={null}
                error={!!error}
            />
            {error && error?.message && <ErrText>{t(error.message)}</ErrText>}
        </ErrorWrapper>
    );
};

export default Autocomplete;
