import { surveysConstants } from '@constants/surveys';
import { surveyService } from '@services';

export const surveysActions = {
    getAll,
    getAutoSurveys,
};

function getAll() {
    return dispatch => {
        dispatch(request());
        return surveyService.getAll().then(
            data => dispatch(success(data.items, data.totalCount)),
            error => dispatch(failure(error)),
        );
    };
}

function getAutoSurveys() {
    return dispatch => {
        dispatch(request());
        return surveyService.getAutoSurveys().then(
            data => dispatch(successAutoSurveys(data)),
            error => dispatch(failure(error)),
        );
    };
}

function request() {
    return { type: surveysConstants.SURVEYS_REQUEST };
}

function success(data, totalCount) {
    return { type: surveysConstants.SURVEYS_SUCCESS, data, totalCount };
}

function successAutoSurveys(data) {
    return { type: surveysConstants.AUTO_SURVEYS_SUCCESS, data };
}

function failure(message) {
    return { type: surveysConstants.SURVEYS_ERROR, message };
}
