import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import { MultiSelect } from '@components/MultiSelect';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { useTranslation } from '@hooks/useTranslation';

const Container = styled('div')({
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
    '@media (max-width: 600px)': {
        gridTemplateColumns: '100%',
        gridGap: '16px',
    },
});

const providerOptions = [{ value: 0, label: 'SMTP' }];
const encryptionOptions = [
    { value: true, label: 'SSL' },
    { value: false, label: 'TLS' },
];

const EmailForm = ({ labelsOptions }) => {
    const companies = useSelector(state => state.account?.account?.companies || []);

    const { t } = useTranslation();
    const { controlEmail, watchEmail, setValueEmail } = useFormContext();
    const selectedLocationLabels = watchEmail('locationLabels');

    const companyOptions = useMemo(() => {
        return selectedLocationLabels.length === labelsOptions.length
            ? companies.map(item => ({ value: item.companyId, label: item.internalName }))
            : companies
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [companies, labelsOptions, selectedLocationLabels]);

    return (
        <Container>
            <Controller
                control={controlEmail}
                name="name"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsChannels.channelName')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                    />
                )}
            />
            <Controller
                control={controlEmail}
                name="provider"
                render={({ field, fieldState: { error }, ref }) => (
                    <Select
                        {...field}
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        onChange={({ target: { value } }) => {
                            setValueEmail('provider', value, { shouldDirty: true });
                        }}
                        options={providerOptions}
                        label={t('SettingsChannels.provider')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                        disabled
                    />
                )}
            />
            <Controller
                control={controlEmail}
                name="locationLabels"
                render={({ field, ref }) => (
                    <MultiSelect
                        {...field}
                        ref={ref}
                        label={t('common.locationLabels')}
                        onChange={({ target: { value } }) => {
                            const selectedLabels = getMultiselectSelectedOptions(labelsOptions, value);
                            setValueEmail('locationLabels', selectedLabels, { shouldDirty: true });
                            const availableCompanies =
                                selectedLabels.length === labelsOptions.length
                                    ? companies.map(item => item.companyId)
                                    : companies
                                          .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                                          .map(item => item.companyId);
                            setValueEmail('companies', availableCompanies, {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                        }}
                        options={labelsOptions}
                        countable
                    />
                )}
            />
            <Controller
                control={controlEmail}
                name="companies"
                render={({ field, fieldState: { error }, ref }) => (
                    <MultiSelect
                        {...field}
                        ref={ref}
                        onChange={({ target: { value } }) => {
                            setValueEmail('companies', getMultiselectSelectedOptions(companyOptions, value), {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                        }}
                        options={companyOptions}
                        label={t('common.locations')}
                        fullWidth
                        countable
                        required
                        error={error}
                    />
                )}
            />
            <Controller
                control={controlEmail}
                name="smtpHost"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsChannels.smtpHost')}
                        fullWidth
                        required
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                    />
                )}
            />
            <Controller
                control={controlEmail}
                name="smtpPort"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsChannels.smtpPort')}
                        type="number"
                        fullWidth
                        required
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                    />
                )}
            />
            <Controller
                control={controlEmail}
                name="username"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('SettingsChannels.username')}
                        fullWidth
                        required
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                    />
                )}
            />
            <Controller
                control={controlEmail}
                name="isSSL"
                render={({ field, fieldState: { error }, ref }) => (
                    <Select
                        {...field}
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        onChange={({ target: { value } }) => {
                            setValueEmail('isSSL', value, { shouldDirty: true });
                        }}
                        options={encryptionOptions}
                        label={t('SettingsChannels.encryption')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                    />
                )}
            />
            <Controller
                control={controlEmail}
                name="email"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('common.email')}
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                    />
                )}
            />
            <Controller
                control={controlEmail}
                name="password"
                render={({ field, fieldState: { error }, ref }) => (
                    <Input
                        ref={ref}
                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                        label={t('common.password')}
                        type="password"
                        fullWidth
                        error={!!error}
                        helperText={error ? t(error.message) : null}
                        required
                    />
                )}
            />
        </Container>
    );
};

export default EmailForm;
