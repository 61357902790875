import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { Typography } from '@components/Typography';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { channelsActions, alertActions } from '@actions';
import { channelsService } from '@services';
import { CHANNEL } from '@constants/channels';
import LiveChatCode from '@features/CreateChannel/LiveChatHTMLCode';
import WhatsAppForm from './WhatsAppForm';
import LiveChatForm from './LiveChatForm';
import FacebookForm from '@features/CreateChannel/FacebookForm';
import SmsForm from '@features/CreateChannel/SmsForm';
import EmailForm from '@features/CreateChannel/EmailForm';
import {
    SmsSchema,
    WhatsAppSchema,
    LiveChatSchema,
    FacebookSchema,
    EmailSchema,
} from '@features/CreateChannel/schemas';
import { convertWorkingHours } from '@helpers/dates';
import { useTranslation } from '@hooks/useTranslation';
import { Modal, TabsWrapper, StyledRadioButtons } from './styles';
import { AccIntegrationName } from '@constants/integrations';

const Tab = {
    Settings: 0,
    Code: 1,
};

const EditModal = ({ open, setOpen, selectedChannel, setSelectedChannel, onClickDelete }) => {
    const { t } = useTranslation();

    const [tab, setTab] = useState(Tab.Settings);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { location: labels } = useSelector(state => state.labels);
    const companies = useSelector(state => state.account?.account?.companies || []);
    const dispatch = useDispatch();

    const resolverSms = useYupValidationResolver(SmsSchema);

    const {
        getValues: getValuesSms,
        setValue: setValueSms,
        control: controlSms,
        reset: resetSms,
        watch: watchSms,
        formState: { isValid: isValidSms, isDirty: isDirtySms },
    } = useForm({
        mode: 'onBlur',
        resolver: resolverSms,
        defaultValues: {
            name: '',
            companies: [],
            phoneNumber: '',
            senderId: '',
            provider: 0,
            accountId: '',
            apiKey: '',
            token: '',
            login: '',
            password: '',
            locationLabels: [],
        },
    });

    const resolverWhatsApp = useYupValidationResolver(WhatsAppSchema);

    const {
        getValues: getValuesWhatsApp,
        setValue: setValueWhatsApp,
        control: controlWhatsApp,
        reset: resetWhatsApp,
        watch: watchWhatsApp,
        formState: { isValid: isValidWhatsApp, isDirty: isDirtyWhatsApp },
    } = useForm({
        mode: 'onBlur',
        resolver: resolverWhatsApp,
        defaultValues: { name: '', companies: [], phoneNumber: '', locationLabels: [] },
    });

    const resolverLiveChat = useYupValidationResolver(LiveChatSchema);

    const {
        getValues: getValuesLiveChat,
        setValue: setValueLiveChat,
        control: controlLiveChat,
        reset: resetLiveChat,
        watch: watchLiveChat,
        trigger: triggerLiveChat,
        formState: { isValid: isValidLiveChat, isDirty: isDirtyLiveChat },
    } = useForm({
        mode: 'all',
        resolver: resolverLiveChat,
        defaultValues: {
            name: '',
            companies: [],
            smsChannelId: '',
            emailChannelId: '',
            labels: [],
            notifyAboutMissingConversations: true,
            welcomeHeading: '',
            welcomeTagline: '',
            workingHoursEnabled: false,
            offlineMessage: '',
            workingHours: {},
            // collect information
            contactEmail: false,
            contactEmailRequired: false,
            contactPhone: true,
            contactPhoneRequired: true,
            contactName: true,
            contactNameRequired: true,
            locationLabels: [],
        },
    });

    const resolverFacebook = useYupValidationResolver(FacebookSchema);

    const {
        getValues: getValuesFacebook,
        setValue: setValueFacebook,
        control: controlFacebook,
        reset: resetFacebook,
        watch: watchFacebook,
        formState: { isValid: isValidFacebook, isDirty: isDirtyFacebook },
    } = useForm({
        mode: 'onBlur',
        resolver: resolverFacebook,
        defaultValues: { name: '', companies: [], id: undefined, saveInstagramReplies: undefined, locationLabels: [] },
    });

    const resolverEmail = useYupValidationResolver(EmailSchema);

    const {
        getValues: getValuesEmail,
        setValue: setValueEmail,
        control: controlEmail,
        reset: resetEmail,
        watch: watchEmail,
        formState: { isValid: isValidEmail, isDirty: isDirtyEmail },
    } = useForm({
        mode: 'onBlur',
        resolver: resolverEmail,
        defaultValues: {
            name: '',
            companies: [],
            provider: 0,
            smtpHost: '',
            smtpPort: '',
            username: '',
            isSSL: true,
            email: '',
            password: '',
            locationLabels: [],
        },
    });

    const getAvailableLabels = () => {
        let availableLabels = [];
        if (selectedChannel.type === CHANNEL.FACEBOOK) {
            availableLabels = [
                ...new Set(
                    companies
                        .filter(c => c.profiles.some(i => i.type === AccIntegrationName.Facebook))
                        .map(item => item.labels)
                        .flat(),
                ),
            ];
        } else {
            availableLabels = [...new Set(companies.map(item => item.labels).flat())];
        }
        return availableLabels;
    };

    const setLabelsToForm = () => {
        const availableLabels = getAvailableLabels();
        const labelsIds = labels.filter(l => availableLabels.includes(l.id)).map(item => item.id);
        setValueSms('locationLabels', labelsIds, { shouldDirty: true });
        setValueWhatsApp('locationLabels', labelsIds, { shouldDirty: true });
        setValueLiveChat('locationLabels', labelsIds, { shouldDirty: true });
        setValueFacebook('locationLabels', labelsIds, { shouldDirty: true });
        setValueEmail('locationLabels', labelsIds, { shouldDirty: true });
    };

    useEffect(() => {
        if (selectedChannel?.type === CHANNEL.SMS) {
            setValueSms('name', selectedChannel?.name || '', { shouldValidate: true });
            setValueSms('companies', selectedChannel?.companies || [], { shouldValidate: true });
            setValueSms('phoneNumber', selectedChannel?.smsParameters?.phoneNumber || '', {
                shouldValidate: true,
            });
            setValueSms('senderId', selectedChannel?.smsParameters?.senderId || '', { shouldValidate: true });
            setValueSms('provider', selectedChannel?.smsParameters?.provider || 0, { shouldValidate: true });
            setValueSms('accountId', selectedChannel?.smsParameters?.accountId || '', {
                shouldValidate: true,
            });
            setValueSms('apiKey', selectedChannel?.smsParameters?.apiKey || '', { shouldValidate: true });
            setValueSms('token', selectedChannel?.smsParameters?.token || '', { shouldValidate: true });
            setValueSms('login', selectedChannel?.smsParameters?.login || '', { shouldValidate: true });
            setValueSms('password', selectedChannel?.smsParameters?.password || '', { shouldValidate: true });
            return;
        }
        if (selectedChannel?.type === CHANNEL.WHATSAPP) {
            setValueWhatsApp('name', selectedChannel?.name || '', { shouldValidate: true });
            setValueWhatsApp('phoneNumber', selectedChannel?.phoneNumber || '', { shouldValidate: true });
            setValueWhatsApp('companies', selectedChannel?.companies || [], { shouldValidate: true });
            return;
        }
        if (selectedChannel?.type === CHANNEL.LIVE_CHAT) {
            setValueLiveChat('name', selectedChannel?.name || '', { shouldValidate: true });
            setValueLiveChat('companies', selectedChannel?.companies || [], { shouldValidate: true });

            setValueLiveChat('smsChannelId', selectedChannel?.liveChatParameters?.smsChannelId || '', {
                shouldValidate: true,
            });
            setValueLiveChat('emailChannelId', selectedChannel?.liveChatParameters?.emailChannelId || '', {
                shouldValidate: true,
            });
            setValueLiveChat('labels', selectedChannel?.labels || []);
            setValueLiveChat(
                'notifyAboutMissingConversations',
                selectedChannel?.liveChatParameters?.notifyAboutMissingConversations,
                {
                    shouldValidate: true,
                },
            );
            setValueLiveChat('welcomeHeading', selectedChannel?.liveChatParameters?.welcomeHeading || '', {
                shouldValidate: true,
            });
            setValueLiveChat('welcomeTagline', selectedChannel?.liveChatParameters?.welcomeTagline || '', {
                shouldValidate: true,
            });
            setValueLiveChat('workingHoursEnabled', selectedChannel?.liveChatParameters?.workingHoursEnabled, {
                shouldValidate: true,
            });
            setValueLiveChat('offlineMessage', selectedChannel?.liveChatParameters?.offlineMessage || '', {
                shouldValidate: true,
            });
            setValueLiveChat(
                'workingHours',
                { days: convertWorkingHours(selectedChannel?.liveChatParameters?.workingHours?.days || []) },
                { shouldValidate: true },
            );
            setValueLiveChat('contactEmail', selectedChannel?.liveChatParameters?.fields?.Email.enable);
            setValueLiveChat('contactEmailRequired', selectedChannel?.liveChatParameters?.fields?.Email.required);
            setValueLiveChat('contactPhone', selectedChannel?.liveChatParameters?.fields?.Phone.enable);
            setValueLiveChat('contactPhoneRequired', selectedChannel?.liveChatParameters?.fields?.Phone.required);
            setValueLiveChat('contactName', selectedChannel?.liveChatParameters?.fields?.Name.enable);
            setValueLiveChat('contactNameRequired', selectedChannel?.liveChatParameters?.fields?.Name.required);
            return;
        }
        if (selectedChannel?.type === CHANNEL.FACEBOOK || selectedChannel?.type === CHANNEL.INSTAGRAM) {
            setValueFacebook('name', selectedChannel?.name || '', { shouldValidate: true });
            setValueFacebook('companies', selectedChannel?.companies || [], { shouldValidate: true });
            setValueFacebook('id', selectedChannel?.id || '', { shouldValidate: true });
            selectedChannel?.type === CHANNEL.INSTAGRAM &&
                setValueFacebook('saveInstagramReplies', selectedChannel?.facebookParameters?.saveInstagramReplies, {
                    shouldValidate: true,
                });
            return;
        }
        if (selectedChannel?.type === CHANNEL.EMAIL) {
            setValueEmail('name', selectedChannel?.name || '', { shouldValidate: true });
            setValueEmail('companies', selectedChannel?.companies || [], { shouldValidate: true });
            setValueEmail('provider', selectedChannel?.emailParameters?.provider || 0, {
                shouldValidate: true,
            });
            setValueEmail('smtpHost', selectedChannel?.emailParameters?.smtpHost || '', {
                shouldValidate: true,
            });
            setValueEmail('smtpPort', selectedChannel?.emailParameters?.smtpPort || '', {
                shouldValidate: true,
            });
            setValueEmail('username', selectedChannel?.emailParameters?.username || '', {
                shouldValidate: true,
            });
            setValueEmail('isSSL', selectedChannel?.emailParameters?.isSSL, { shouldValidate: true });
            setValueEmail('email', selectedChannel?.emailParameters?.email || '', { shouldValidate: true });
            setValueEmail('password', selectedChannel?.emailParameters?.password || '', {
                shouldValidate: true,
            });
            return;
        }
    }, [selectedChannel, setValueSms, setValueWhatsApp]);

    useEffect(() => {
        setLabelsToForm();
    }, []);

    const labelsOptions = useMemo(() => {
        const availableLabels = getAvailableLabels();
        return labels.filter(l => availableLabels.includes(l.id)).map(item => ({ value: item.id, label: item.name }));
    }, [companies, labels]);

    const getChannelValuesByType = type => {
        switch (type) {
            case CHANNEL.SMS:
                return getValuesSms();
            case CHANNEL.WHATSAPP:
                return getValuesWhatsApp();
            case CHANNEL.LIVE_CHAT:
                return getValuesLiveChat();
            case CHANNEL.FACEBOOK:
                return getValuesFacebook();
            case CHANNEL.EMAIL:
                return getValuesEmail();
            default:
                return {};
        }
    };

    const onClickSubmit = async () => {
        setIsSubmitting(true);
        const values = getChannelValuesByType(selectedChannel?.type);

        if (
            selectedChannel?.type === CHANNEL.LIVE_CHAT &&
            !values?.contactEmailRequired &&
            !values?.contactPhoneRequired
        ) {
            dispatch(alertActions.error(t('apiErrors.phoneOrEmailRequired')));
            setIsSubmitting(false);
            return;
        }

        try {
            if (selectedChannel?.type === CHANNEL.SMS) {
                await channelsService.createOrUpdateChannelSms({
                    id: selectedChannel?.id,
                    name: values.name,
                    companies: values.companies,
                    smsParameters: {
                        senderId: values?.senderId,
                        provider: values?.provider,
                        accountId: values?.accountId,
                        apiKey: values?.apiKey,
                        token: values?.token,
                        phoneNumber: values.phoneNumber,
                        login: values?.login,
                        password: values?.password,
                    },
                });
            } else if (selectedChannel?.type === CHANNEL.WHATSAPP) {
                await channelsService.createOrUpdateChannelWhatsApp({
                    id: selectedChannel?.id,
                    name: values.name,
                    newWhatsAppAccount: selectedChannel?.newWhatsAppAccount,
                    companies: values.companies,
                });
            } else if (selectedChannel?.type === CHANNEL.LIVE_CHAT) {
                await channelsService.createOrUpdateChannelLiveChat({
                    id: selectedChannel?.id,
                    name: values.name,
                    companies: values.companies,
                    labels: values.labels,
                    liveChatParameters: {
                        smsChannelId: values.smsChannelId,
                        emailChannelId: values.emailChannelId,
                        notifyAboutMissingConversations: values.notifyAboutMissingConversations,
                        welcomeHeading: values.welcomeHeading,
                        welcomeTagline: values.welcomeTagline,
                        workingHoursEnabled: values.workingHoursEnabled,
                        workingHours: { days: convertWorkingHours(values.workingHours.days, 'toUTC') },
                        offlineMessage: values.offlineMessage,
                        fields: {
                            Email: { type: 0, enable: values.contactEmail, required: values.contactEmailRequired },
                            Phone: { type: 1, enable: values.contactPhone, required: values.contactPhoneRequired },
                            Name: { type: 2, enable: values.contactName, required: values.contactNameRequired },
                        },
                    },
                });
            } else if (selectedChannel?.type === CHANNEL.FACEBOOK) {
                await channelsService.createOrUpdateChannelFacebook({
                    id: selectedChannel?.id,
                    ...values,
                });
            } else if (selectedChannel?.type === CHANNEL.INSTAGRAM) {
                const values = getChannelValuesByType(CHANNEL.FACEBOOK);
                await channelsService.createOrUpdateChannelInstagram({
                    id: selectedChannel?.id,
                    ...values,
                });
            } else if (selectedChannel?.type === CHANNEL.EMAIL) {
                await channelsService.createOrUpdateChannelEmail({
                    id: selectedChannel?.id,
                    name: values.name,
                    companies: values.companies,
                    emailParameters: {
                        provider: values.provider,
                        smtpHost: values.smtpHost,
                        smtpPort: values.smtpPort,
                        username: values.username,
                        isSSL: values.isSSL,
                        email: values.email,
                        password: values.password,
                    },
                });
            }
            await dispatch(channelsActions.get());
            dispatch(alertActions.success(t('alertMessages.updateSuccess')));
            onClose();
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.updateFail')));
            }
        }
        setIsSubmitting(false);
    };

    const onClose = () => {
        setOpen(false);
        setSelectedChannel({});
        resetSms();
        resetWhatsApp();
        resetLiveChat();
        resetFacebook();
        resetEmail();
    };

    const getTitleText = () => {
        switch (selectedChannel?.type) {
            case CHANNEL.SMS:
                return t('common.sms');
            case CHANNEL.WHATSAPP:
                return t('common.whatsApp');
            case CHANNEL.LIVE_CHAT:
                return t('common.liveChatLowercase');
            case CHANNEL.FACEBOOK:
                return t('common.facebookMessenger');
            case CHANNEL.INSTAGRAM:
                return t('common.instagram');
            case CHANNEL.EMAIL:
                return t('common.email');
            default:
                return '';
        }
    };

    const getPrimaryActionState = () => {
        switch (true) {
            case selectedChannel?.type === CHANNEL.SMS && (isSubmitting || !isValidSms || !isDirtySms):
            case selectedChannel?.type === CHANNEL.WHATSAPP && (isSubmitting || !isValidWhatsApp || !isDirtyWhatsApp):
            case selectedChannel?.type === CHANNEL.LIVE_CHAT && (isSubmitting || !isValidLiveChat || !isDirtyLiveChat):
            case selectedChannel?.type === CHANNEL.FACEBOOK && (isSubmitting || !isValidFacebook || !isDirtyFacebook):
            case selectedChannel?.type === CHANNEL.INSTAGRAM && (isSubmitting || !isValidFacebook || !isDirtyFacebook):
            case selectedChannel?.type === CHANNEL.EMAIL && (isSubmitting || !isValidEmail || !isDirtyEmail):
                return true;
            default:
                return false;
        }
    };

    const onDelete = () => onClickDelete(selectedChannel);

    return (
        <Modal
            isOpen={open}
            handleClose={onClose}
            title={t('SettingsChannels.editChannelTitle', { channelType: getTitleText() })}
            subtitle={
                <Typography variant="caption" color="textSecondary">
                    {t('SettingsChannels.editChannelSubtitle', { channelType: getTitleText() })}
                </Typography>
            }
            onPrimaryAction={onClickSubmit}
            primaryActionText={t('buttons.saveChanges')}
            primaryActionDisabled={getPrimaryActionState()}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
            onAdditionalAction={onDelete}
            additionalTextButton={t('buttons.delete')}
        >
            {selectedChannel?.type === CHANNEL.LIVE_CHAT ? (
                <TabsWrapper>
                    <StyledRadioButtons
                        value={tab}
                        onChange={setTab}
                        options={[
                            { value: Tab.Settings, label: t('MicrositesPage.settings') },
                            { value: Tab.Code, label: t('ShowcaseWidgets.widgetCode') },
                        ]}
                    />
                </TabsWrapper>
            ) : null}
            {selectedChannel?.type === CHANNEL.SMS ? (
                <FormProvider {...{ controlSms, setValueSms, watchSms, twillioUid: selectedChannel?.id }}>
                    <SmsForm labelsOptions={labelsOptions} />
                </FormProvider>
            ) : null}
            {selectedChannel?.type === CHANNEL.WHATSAPP ? (
                <FormProvider {...{ controlWhatsApp, setValueWhatsApp, watchWhatsApp, getValuesWhatsApp }}>
                    <WhatsAppForm labelsOptions={labelsOptions} />
                </FormProvider>
            ) : null}
            {selectedChannel?.type === CHANNEL.LIVE_CHAT && tab === Tab.Settings ? (
                <FormProvider
                    {...{ controlLiveChat, setValueLiveChat, getValuesLiveChat, watchLiveChat, triggerLiveChat }}
                >
                    <LiveChatForm labelsOptions={labelsOptions} />
                </FormProvider>
            ) : null}
            {selectedChannel?.type === CHANNEL.LIVE_CHAT && tab === Tab.Code ? (
                <LiveChatCode liveChatPublicId={selectedChannel?.publicId} />
            ) : null}
            {selectedChannel?.type === CHANNEL.FACEBOOK || selectedChannel?.type === CHANNEL.INSTAGRAM ? (
                <FormProvider {...{ controlFacebook, setValueFacebook, watchFacebook, getValuesFacebook }}>
                    <FacebookForm labelsOptions={labelsOptions} type={selectedChannel?.type} />
                </FormProvider>
            ) : null}
            {selectedChannel?.type === CHANNEL.EMAIL ? (
                <FormProvider {...{ controlEmail, setValueEmail, watchEmail, getValuesEmail }}>
                    <EmailForm labelsOptions={labelsOptions} />
                </FormProvider>
            ) : null}
        </Modal>
    );
};

export default EditModal;
