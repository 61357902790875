import { keywordsConstants } from '@constants/keywords';

const initialState = {
    data: [],
    dataAll: {},
    loading: true,
    error: '',
    totalCount: 0,
    pages: 1,
};

export function keywords(state = initialState, action) {
    switch (action.type) {
        case keywordsConstants.KEYWORDS_REQUEST:
            return { ...state, loading: true, error: '' };
        case keywordsConstants.KEYWORDS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.items,
                totalCount: action.data.totalCount,
                pages: action.data.pages,
                error: '',
            };
        case keywordsConstants.KEYWORDS_ERROR:
            return { ...state, error: action.message, loading: false };
        case keywordsConstants.KEYWORDS_ALL_SUCCESS:
            return { ...state, dataAll: action.data, loading: false };
        default:
            return state;
    }
}
