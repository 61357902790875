import { useState } from 'react';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Checkbox } from '@components/Checkbox';
import TableCellCheckbox from './TableCellCheckbox';

const TableCellStyled = styled(TableCell)(() => ({
    padding: '4px 16px',
    minHeight: 45,
    height: 45,
    '& .MuiIconButton-root': {
        padding: 8,
        '&:not(:first-of-type)': {
            marginLeft: 16,
        },
        '& svg': {
            color: 'rgba(0, 0, 0, 0.6)',
            width: 20,
            height: 20,
        },
    },
}));

const ActionsWrapper = styled('div')(() => ({
    display: 'flex',
}));

const TableRowStyled = styled(TableRow)(() => ({
    '&.MuiTableRow-hover:hover': {
        background: '#F7FBF9',
    },
}));

const ActionComponent = ({ cellItem, isCustomActionComponent, onPrimaryAction, onSecondaryAction }) => {
    if (isCustomActionComponent) {
        return cellItem?.component || cellItem;
    }
    return (
        <ActionsWrapper>
            {onPrimaryAction && (
                <IconButton onClick={() => onPrimaryAction(cellItem)}>
                    <EditIcon fontSize="inherit" />
                </IconButton>
            )}
            {onSecondaryAction && (
                <IconButton onClick={() => onSecondaryAction(cellItem)}>
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            )}
        </ActionsWrapper>
    );
};

const TableRowWithActions = ({
    rowData,
    onPrimaryAction,
    onSecondaryAction,
    isCustomActionComponent,
    withCheckbox,
    checked,
    onSelect,
}) => {
    const [isActionsVisible, showActions] = useState(false);

    return (
        <TableRowStyled
            hover
            onMouseEnter={() => showActions(true)}
            onMouseLeave={() => showActions(false)}
            sx={{ cursor: 'pointer' }}
        >
            {withCheckbox && (
                <TableCellCheckbox>
                    <Checkbox checked={checked} onChange={onSelect} />
                </TableCellCheckbox>
            )}
            {rowData?.length &&
                rowData.map((cell, position) =>
                    position + 1 < rowData.length ? (
                        <TableCellStyled
                            align={position === 0 ? 'left' : 'center'}
                            component="th"
                            scope="row"
                            key={`row-cell-${cell?.value}-${position}`}
                        >
                            {cell?.component || cell}
                        </TableCellStyled>
                    ) : (
                        <TableCellStyled
                            sx={{ padding: 0, width: 104 }}
                            align="right"
                            key={`row-cell-${cell?.value}-${position}`}
                        >
                            {isActionsVisible && cell.value && (
                                <ActionComponent
                                    cellItem={cell}
                                    onPrimaryAction={onPrimaryAction}
                                    onSecondaryAction={onSecondaryAction}
                                    isCustomActionComponent={isCustomActionComponent}
                                />
                            )}
                        </TableCellStyled>
                    ),
                )}
        </TableRowStyled>
    );
};

export default TableRowWithActions;
