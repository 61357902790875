import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import { Table } from '@components/Table';
import { ModalConfirm } from '@components/Modal';
import Button from '@components/Button';
import LockedButton from '@features/LockedButton';
import { Typography } from '@components/Typography';
import { accountActions, alertActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';
import { userService } from '@services';
import { PRICING_DRAWER_TYPE } from '@constants/pricing-drawer-ui';
import { filterAndFormatCompaniesByRole } from '@helpers/companies';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'calc(var(--100vh) - 221px)',
        marginRight: '-16px',
    },
});

const Footer = styled('div')({
    marginTop: '24px',
    marginLeft: '-24px',
    padding: 24,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
});

const GetUsersColumnNames = () => {
    const { t } = useTranslation();
    return [
        { key: 0, component: <Typography variant="subtitle2">{t('common.email')}</Typography> },
        {
            key: 1,
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('common.fullName')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('SettingsUsers.assignedCompanies')}
                </Typography>
            ),
        },
        {
            key: 3,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('SettingsUsers.addedOn')}
                </Typography>
            ),
        },
        {
            key: 4,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('SettingsUsers.lastLogin')}
                </Typography>
            ),
        },
        { key: 5, component: <Typography align="left" variant="subtitle2"></Typography> },
    ];
};

const getRowsData = (account, currentUser) => {
    const currentUserIsAdmin =
        currentUser.roles.filter(u => u.role === 0).length > 0 &&
        !currentUser.isAccountOwner &&
        !currentUser.isGlobalAdmin;
    return (account?.users || []).map(user => {
        const userIAM = user.id === currentUser.id;
        let hasAccess = false;
        if (currentUser.isAccountOwner && !user.isAccountOwner) {
            hasAccess = true;
        } else if (currentUser.isGlobalAdmin && !user.isAccountOwner && !userIAM) {
            hasAccess = true;
        } else if (currentUserIsAdmin && !user.isGlobalAdmin && !user.isAccountOwner && !userIAM) {
            hasAccess = true;
        }
        return [
            {
                component: (
                    <Typography variant="subtitle2" align="left">
                        {user.email}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">{`${user?.firstName} ${user?.lastName}`}</Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {filterAndFormatCompaniesByRole(user, account?.companies)}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {format(new Date(user.registrationDate), 'dd/MM/yyyy')}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {format(new Date(user.lastVisit), 'dd/MM/yyyy')}
                    </Typography>
                ),
            },
            {
                value: !hasAccess ? null : user,
            },
        ];
    });
};

const UserTable = ({ currentUser, account, selectedUser, setSelectedUser, view, setView }) => {
    const { t } = useTranslation();

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const dispatch = useDispatch();

    const onDeleteUser = async () => {
        setDeleteModalOpen(false);
        try {
            await userService.deleteUser(selectedUser.id);
            await dispatch(accountActions.get());
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.deleteFail')));
            }
        }
        setSelectedUser(null);
        setView(view.TABLE);
    };

    const limitExceeded = account?.users?.length >= account?.usersMaxCount;

    return (
        <Container>
            <TableContainer>
                <Table
                    columnNames={GetUsersColumnNames()}
                    rows={getRowsData(account, currentUser)}
                    onPrimaryAction={({ value }) => {
                        if (value.isAccountOwner && !currentUser.isAccountOwner) {
                            return;
                        }
                        setSelectedUser(value);
                        setView(view.FORM);
                    }}
                    onSecondaryAction={({ value }) => {
                        if (value.isAccountOwner && !currentUser.isAccountOwner) {
                            return;
                        }
                        setSelectedUser(value);
                        setDeleteModalOpen(true);
                    }}
                    isInteractive
                />
            </TableContainer>
            <Footer>
                {limitExceeded || account?.billingDiffInSeconds < 0 ? (
                    <LockedButton tooltipType={1} variant="contained" drawerType={PRICING_DRAWER_TYPE.CHANGE}>
                        {t('buttons.addUser')}
                    </LockedButton>
                ) : (
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => {
                            setSelectedUser(null);
                            setView(view.FORM);
                        }}
                    >
                        {t('buttons.addUser')}
                    </Button>
                )}
            </Footer>
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDeleteUser}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                    onSecondaryAction={() => {
                        setDeleteModalOpen(false);
                        setSelectedUser(null);
                    }}
                />
            )}
        </Container>
    );
};

export default UserTable;
