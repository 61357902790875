import { useDispatch } from 'react-redux';
import { ModalConfirm } from '@components/Modal';
import { userActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';

const LogoutModal = ({ isOpen, setIsOpen, className }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <ModalConfirm
            isOpen={isOpen}
            className={className}
            handleClose={() => setIsOpen(false)}
            title={t('LogoutModal.logOutConfirmation')}
            description={t('LogoutModal.logOutConfirmationDescription')}
            onPrimaryAction={() => {
                setIsOpen(false);
                dispatch(userActions.logout());
            }}
            primaryActionText={t('buttons.logout')}
            primaryActionType="danger"
            isLogout
        />
    );
};

export default LogoutModal;
