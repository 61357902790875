import { useSelector } from 'react-redux';
import TableContainer from '@mui/material/TableContainer';
import { Table } from '@components/Table';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { formatCompanies } from '@helpers/companies';
import { FORM_TYPE } from '../EditModal';

const GetCompaniesColumnNames = () => {
    const { t } = useTranslation();
    return [
        { key: 0, component: <Typography variant="subtitle2">{t('common.title')}</Typography> },
        {
            key: 1,
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('SettingsBrands.mainLocation')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.locations')}
                </Typography>
            ),
        },
        { key: 3, component: <Typography align="left" variant="subtitle2"></Typography> },
    ];
};

const getRowsData = (data, locations) => {
    return data.map(brand => {
        const mainLocationId = brand.locations.find(l => l.isMainLocation)?.id;
        const mainLocation = locations.find(l => l.companyId === mainLocationId)?.internalName;
        const assignedCompanies = brand.locations.map(l => l.id);
        return [
            {
                component: (
                    <Typography variant="subtitle2" align="left">
                        {brand.title}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {mainLocation}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography
                        sx={{
                            maxWidth: '360px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        variant="body2"
                        align="left"
                    >
                        {formatCompanies(locations, assignedCompanies)}
                    </Typography>
                ),
            },
            {
                value: brand,
            },
        ];
    });
};

const BrandsTable = ({
    locations,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    setSelectedBrand,
    setDeleteModalOpen,
    setModal,
}) => {
    const { t } = useTranslation();

    const { data, loading, totalCount, pages } = useSelector(state => state.brands);

    const onPageChange = (_, newPage) => setPage(newPage);

    const onRowsPerPageChange = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer>
            <Table
                columnNames={GetCompaniesColumnNames()}
                rows={getRowsData(data, locations)}
                isDataLoading={loading}
                onPrimaryAction={({ value }) => {
                    setSelectedBrand(value);
                    setModal(prev => ({ ...prev, open: true, type: FORM_TYPE.EDIT }));
                }}
                onSecondaryAction={({ value }) => {
                    setSelectedBrand(value);
                    setDeleteModalOpen(true);
                }}
                isInteractive
                withPagination
                paginationProps={{
                    colSpan: 5,
                    count: totalCount,
                    rowsPerPage: rowsPerPage,
                    page: page,
                    onPageChange,
                    onRowsPerPageChange,
                    labelDisplayedRows: ({ from, to, count }) =>
                        `${from}–${to} ${t('Table.of')} ${count !== -1 ? count : `${t('Table.moreThan')} ${to}`}`,
                    labelRowsPerPage: <span>{t('Table.rowsPerPage')}</span>,
                    pages,
                }}
            />
        </TableContainer>
    );
};

export default BrandsTable;
