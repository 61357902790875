import { coverManagerConstants } from '@constants/coverManager';
import { coverManagerIntegrationService } from '@services';

export const coverManagerActions = {
    get,
};

function get() {
    return dispatch => {
        dispatch(request());
        return coverManagerIntegrationService.getRestaurants().then(
            data => {
                dispatch(success(data));
                return data;
            },
            error => dispatch(failure(error)),
        );
    };
}

function request() {
    return { type: coverManagerConstants.COVER_MANAGER_RESTAURANTS_REQUEST };
}

function success(data) {
    return { type: coverManagerConstants.COVER_MANAGER_RESTAURANTS_SUCCESS, data };
}

function failure(message) {
    return { type: coverManagerConstants.COVER_MANAGER_RESTAURANTS_ERROR, message };
}
