export const LANGUAGE = {
    ENGLISH: 'GB',
    UKRAINIAN: 'UA',
    PORTUGUESE: 'PT',
    SPANISH: 'ES',
    PORTUGUESE_BRAZIL: 'BR',
};

export const SURVEY_LANGUAGE = {
    GB: 'GB',
    ES: 'ES',
    DE: 'DE',
    FR: 'FR',
    AR: 'AR',
    UA: 'UA',
    PT: 'PT',
    BR: 'BR',
    RU: 'RU',
    PL: 'PL',
};
