import { useState } from 'react';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { useTranslation } from '@hooks/useTranslation';
import { LEARN_MORE } from '@constants/home-page';
import Card from './Card';

const Container = styled('div')({
    padding: 16,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#fff',
    boxShadow:
        '0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.20)',
    borderRadius: 4,
    marginBottom: 40,
    position: 'relative',
});

const StepsWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'surveysEnabled',
})(({ surveysEnabled }) => ({
    display: 'grid',
    gridGap: '16px 24px',
    marginBottom: 16,
    gridTemplateAreas: '"area0 area1 area2" "area3 area4 area5"',
    ...(!surveysEnabled && {
        gridTemplateAreas: '"area0 area0 area1 area1 area2 area2" "area3 area3 area3 area5 area5 area5"',
    }),
    '@media (max-width: 1400px)': {
        gridGap: 16,
    },
    '@media (max-width: 1024px)': {
        gridTemplateAreas: '"area0 area3" "area1 area4" "area2 area5"',
        ...(!surveysEnabled && {
            gridTemplateAreas:
                '"area0 area0 area0 area3 area3 area3" "area1 area1 area1 area2 area2 area2" "area4 area4 area5 area5 area6 area6"',
        }),
    },
    '@media (max-width: 600px)': {
        gridTemplateAreas: '"area0" "area3" "area1" "area4" "area2" "area5"',
        ...(!surveysEnabled && {
            gridTemplateAreas: '"area0" "area3" "area1" "area2" "area5"',
        }),
    },
}));

const Onboarding = ({ account, accountOnboarding }) => {
    const isMobile = useMediaQuery('@media (max-width: 1024px)', { noSsr: true });
    const [expanded, setExpanded] = useState(!isMobile);

    const { t, i18n } = useTranslation();

    const onExpandSection = () => setExpanded(prev => !prev);

    const LIST = [
        {
            id: 0,
            title: t('HomePage.googleTitle'),
            description: t('HomePage.googleDescription'),
            isPassed: accountOnboarding.googleConnected,
            link: LEARN_MORE[i18n.language].google,
            style: { gridArea: 'area0' },
        },
        {
            id: 1,
            title: t('HomePage.channelsTitle'),
            description: t('HomePage.channelsDescription'),
            isPassed: accountOnboarding.channelsCreated,
            link: LEARN_MORE[i18n.language].channels,
            style: { gridArea: 'area1' },
        },
        {
            id: 2,
            title: t('HomePage.autoRepliesTitle'),
            description: t('HomePage.autoRepliesDescription'),
            isPassed: accountOnboarding.autoRepliesCreated,
            link: LEARN_MORE[i18n.language].autoReplies,
            style: { gridArea: 'area2' },
        },
        {
            id: 3,
            title: t('HomePage.facebookTitle'),
            description: t('HomePage.facebookDescription'),
            isPassed: accountOnboarding.facebookConnected,
            link: LEARN_MORE[i18n.language].fb,
            style: { gridArea: 'area3' },
        },
        ...(account?.surveysEnabled
            ? [
                  {
                      id: 4,
                      title: t('HomePage.templatesTitle'),
                      description: t('HomePage.templatesDescription'),
                      isPassed: accountOnboarding.surveyTemplatedCreated,
                      link: LEARN_MORE[i18n.language].templates,
                      style: { gridArea: 'area4' },
                  },
              ]
            : []),
        {
            id: 5,
            title: t('HomePage.usersTitle'),
            description: t('HomePage.usersDescription'),
            isPassed: accountOnboarding.usersCreated,
            link: LEARN_MORE[i18n.language].users,
            style: { gridArea: 'area5' },
        },
    ];

    const passedNumber = Object.values(accountOnboarding).filter(Boolean).length;
    const percentage = Math.floor((passedNumber * 100) / (account?.surveysEnabled ? 6 : 5));

    return (
        <Container>
            <Typography variant="subtitle2" align="center" sx={{ color: 'rgba(0, 0, 0, 0.87)', marginBottom: '16px' }}>
                {t('HomePage.getStarted', { steps: account?.surveysEnabled ? 6 : 5 })}
            </Typography>
            <Collapse in={expanded}>
                <StepsWrapper surveysEnabled={account?.surveysEnabled}>
                    {LIST.map(item => (
                        <Card key={item.id} {...item} />
                    ))}
                </StepsWrapper>
            </Collapse>
            <div style={{ display: 'flex', gap: 16, marginBottom: '16px' }}>
                <div
                    style={{
                        backgroundColor: '#E4EAEB',
                        height: '14px',
                        width: 'calc(100% - 36px)',
                        borderRadius: '14px',
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            borderRadius: '14px',
                            backgroundColor: '#1F4C5C',
                            width: `${percentage}%`,
                        }}
                    />
                </div>
                <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                    {passedNumber}/{account?.surveysEnabled ? 6 : 5}
                </Typography>
            </div>
            <div
                style={{
                    position: 'absolute',
                    height: '32px',
                    backgroundColor: '#fff',
                    left: '50%',
                    transform: 'translateX(-50%)',
                }}
            >
                <Button
                    variant="outlined"
                    sx={{
                        color: 'rgba(0, 0, 0, 0.6)',
                        width: 'max-content',
                        boxShadow:
                            '0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.20)',
                    }}
                    onClick={onExpandSection}
                    endIcon={expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                >
                    {t('HomePage.continueOnboarding')}
                </Button>
            </div>
        </Container>
    );
};

export default Onboarding;
