import { googleConstants } from '@constants/google';
import { googleIntegrationService } from '@services';

export const googleActions = {
    get,
    getListing,
    setListing,
    getSearchedCategories,
    resetSearchedCategories,
};

function get() {
    return dispatch => {
        dispatch(request());
        return googleIntegrationService.getLocations().then(
            data => {
                dispatch(success(data));
                return data;
            },
            error => dispatch(failure(error)),
        );
    };
}

function request() {
    return { type: googleConstants.GOOGLE_LOCATIONS_REQUEST };
}

function success(data) {
    return { type: googleConstants.GOOGLE_LOCATIONS_SUCCESS, data };
}

function failure(message) {
    return { type: googleConstants.GOOGLE_LOCATIONS_ERROR, message };
}

function getListing(profileId) {
    return dispatch => {
        dispatch(requestListing());
        return googleIntegrationService.getLocationListing(profileId).then(
            data => {
                dispatch(setListing(data));
            },
            error => dispatch(failureListing(error)),
        );
    };
}

function requestListing() {
    return { type: googleConstants.GOOGLE_LISTING_PROFILE_REQUEST };
}

function setListing(data) {
    return { type: googleConstants.GOOGLE_LISTING_PROFILE_SUCCESS, data };
}

function failureListing(message) {
    return { type: googleConstants.GOOGLE_LISTING_PROFILE_ERROR, message };
}

function setSearchedCategories(data) {
    return { type: googleConstants.GOOGLE_CATEGORIES_SUCCESS, data };
}

function resetSearchedCategories() {
    return { type: googleConstants.GOOGLE_CATEGORIES_RESET };
}

function getSearchedCategories(params) {
    return dispatch => {
        dispatch(request());
        googleIntegrationService.getCategories(params).then(
            data => dispatch(setSearchedCategories(data)),
            error => dispatch(failure(error)),
        );
    };
}
