import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Typography } from '@components/Typography';
import { Input } from '@components/Input';
import { ExternalLink } from '@components/Link';
import { Trans } from 'react-i18next';
import { alertActions, accountActions } from '@actions';
import { vtexIntegrationService } from '@services';
import { useTranslation } from '@hooks/useTranslation';
import { Content, Modal } from './styles';

const VTEXModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const VTEXSchema = yup.object().shape({
        accountName: yup.string().required('validation.required'),
        applicationToken: yup.string().required('validation.required'),
        applicationKey: yup.string().required('validation.required'),
    });

    const resolver = useYupValidationResolver(VTEXSchema);

    const {
        getValues,
        control,
        formState: { isValid, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            accountName: '',
            applicationToken: '',
            applicationKey: '',
        },
    });

    const onConnect = async () => {
        try {
            const values = getValues();
            await vtexIntegrationService.connectIntegration(values);
            dispatch(alertActions.success(t('alertMessages.connectIntegrationSuccess')));
            dispatch(accountActions.get());
            onClose();
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.connectIntegrationFail')));
            }
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            handleClose={onClose}
            title={t('SettingsIntegrations.vtexTitle')}
            subtitle={
                <Typography variant="caption" color="textSecondary">
                    <Trans t={t} i18nKey="SettingsIntegrations.vtexSubtitle">
                        <ExternalLink
                            href="https://help.pluspoint.io/en/articles/7971010-connect-vtex-account"
                            variant="caption"
                            underline="hover"
                        >
                            {{ link: t('SettingsIntegrations.instructionsLink') }}
                        </ExternalLink>
                    </Trans>
                </Typography>
            }
            onPrimaryAction={onConnect}
            primaryActionText={t('buttons.connect')}
            primaryActionDisabled={isSubmitting || !isValid}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content>
                <Controller
                    control={control}
                    name="accountName"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            sx={{ gridArea: 'input1' }}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('SettingsIntegrations.accountName')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="applicationToken"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            sx={{ gridArea: 'input2' }}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('SettingsIntegrations.appToken')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="applicationKey"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            sx={{ gridArea: 'input3' }}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('SettingsIntegrations.appKey')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                        />
                    )}
                />
            </Content>
        </Modal>
    );
};

export default VTEXModal;
