import { useState } from 'react';
import { styled } from '@mui/material/styles';
import LogoutModal from '@features/LogoutModal';
import { ReactComponent as LogoIcon } from 'assets/images/logo.svg';
import { LogoutIcon } from 'assets/images/icons';

const StyledLogoutModal = styled(LogoutModal)({
    left: 0,
    '& .MuiBackdrop-root': {
        left: 0,
    },
});

const StyledHeader = styled('div')({
    width: '100%',
    height: 72,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '22px 19px',
    position: 'fixed',
    '@media (max-width: 1024px)': {
        height: 60,
        padding: 16,
        backgroundColor: '#272727',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    },
});

const Logo = styled('span')({
    fontFamily: 'Rubik, sans-serif',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    marginLeft: 11,
});

const LogoutWrapper = styled('div')({
    cursor: 'pointer',
    padding: '7px 8px',
    border: '1px solid rgba(255, 255, 255, 0.38)',
    borderRadius: 8,
    '&:hover': {
        opacity: 0.8,
    },
    '& svg path': {
        fill: '#fff',
    },
});

const Header = () => {
    const [logoutIsOpen, setLogoutIsOpen] = useState(false);

    const onLogoutClick = () => setLogoutIsOpen(true);

    return (
        <>
            <StyledHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <LogoIcon />
                    <Logo>Pluspoint</Logo>
                </div>
                <LogoutWrapper onClick={onLogoutClick}>
                    <LogoutIcon />
                </LogoutWrapper>
            </StyledHeader>
            <StyledLogoutModal isOpen={logoutIsOpen} setIsOpen={setLogoutIsOpen} />
        </>
    );
};

export default Header;
