export const conversationsConstants = {
    CONVERSATIONS_FETCH: 'CONVERSATIONS_FETCH',
    CONVERSATIONS_SUCCESS: 'CONVERSATIONS_SUCCESS',
    CONVERSATIONS_ERROR: 'CONVERSATIONS_ERROR',
    CONVERSATIONS_LOAD_MORE_SUCCESS: 'CONVERSATIONS_LOAD_MORE_SUCCESS',
    CONVERSATIONS_UPDATE: 'CONVERSATIONS_UPDATE',
    CONVERSATIONS_REQUIRES_REFETCH: 'CONVERSATIONS_REQUIRES_REFETCH',
    CONVERSATIONS_RESET: 'CONVERSATIONS_RESET',
    CONVERSATIONS_COUNTERS_SUCCESS: 'CONVERSATIONS_COUNTERS_SUCCESS',
    CONVERSATIONS_ARCHIVED_SUCCESS: 'CONVERSATIONS_ARCHIVED_SUCCESS',
};

export const CONVERSATION_STATUS = {
    OPEN: 0,
    CLOSED: 1,
};

export const CONVERSATION_MAP = {
    Archived: 5,
    Instagram: 3,
    Messenger: 2,
    WhatsApp: 6,
    SMS: 4,
    'Live-chat': 1,
};

export const ASSIGNED_STATUS = {
    ASSIGNED: 0,
    UNASSIGNED: 1,
    ALL: 2,
    MINE: 3,
};

export const DEFAULT_TAKE = 15;
export const DEFAULT_START = 0;
