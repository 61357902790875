import { useState } from 'react';
import { useDispatch } from 'react-redux';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@components/Button';
import { useTranslation } from '@hooks/useTranslation';
import { alertActions } from '@actions';

const ExportCSV = ({ callback, type }) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch();

    const onExport = async () => {
        setIsSubmitting(true);
        try {
            const file = await callback();
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = file;
            anchor.download = `${type}.csv`;
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(file);
            anchor.remove();
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('common.exportError')));
            }
        }
        setIsSubmitting(false);
    };

    return (
        <Button
            variant="outlined"
            style={{ flexShrink: 0 }}
            startIcon={<FileDownloadIcon />}
            onClick={onExport}
            disabled={isSubmitting}
        >
            {t('buttons.exportAsCSV')}
        </Button>
    );
};

export default ExportCSV;
