import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { Input } from '@components/Input';
import TextEditor from '@components/TextEditor';
import { Typography } from '@components/Typography';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { useTranslation } from '@hooks/useTranslation';
import { replyTemplatesService } from '@services';
import { alertActions, replyTemplatesActions } from '@actions';
import { parseTipTapJSONToPlainText, testEditorLength } from '@helpers/text-editor';
import { Modal, Content } from './styles';

export const FORM_TYPE = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    EDIT_COPY: 'EDIT_COPY',
    VIEW: 'VIEW',
    DELETE: 'DELETE',
};

const EditModal = ({ selectedTemplate, setSelectedTemplate, modal, setModal, user, page, setPage, rowsPerPage }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const ReplyTemplateSchema = yup.object().shape({
        name: yup.string().required('validation.required'),
        author: yup.string().notRequired(),
        text: yup
            .string()
            .required('validation.required')
            .test('text-editor-length', `text-editor-length`, value => testEditorLength(value, 1000)),
    });

    const resolver = useYupValidationResolver(ReplyTemplateSchema);

    const {
        getValues,
        setValue,
        control,
        handleSubmit,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: { name: '', author: `${user.firstName} ${user.lastName}`, text: '' },
    });

    useEffect(() => {
        if (modal.type !== FORM_TYPE.NEW) {
            setValue('name', selectedTemplate.name, { shouldValidate: true, shouldDirty: false });
            setValue('author', selectedTemplate.createdByName, { shouldValidate: true });
            setValue('text', selectedTemplate.text, { shouldValidate: true });
        }
    }, [selectedTemplate, modal.type, setValue]);

    const onClose = () => {
        setSelectedTemplate(null);
        setModal({ open: false, type: FORM_TYPE.NEW });
    };

    const refetchFirstPage = async () => {
        if (page === 0) {
            await dispatch(replyTemplatesActions.get(page, rowsPerPage));
        } else {
            setPage(0); // Triggers refetch of templates
        }
    };

    const REFETCH_MAP = {
        [FORM_TYPE.EDIT]: async () => await dispatch(replyTemplatesActions.get(page, rowsPerPage)),
        [FORM_TYPE.NEW]: () => refetchFirstPage(),
        [FORM_TYPE.EDIT_COPY]: () => refetchFirstPage(),
    };

    const RESPONSE_MSG = {
        [FORM_TYPE.EDIT]: {
            success: t('alertMessages.editSuccess'),
            error: t('alertMessages.editFail'),
        },
        [FORM_TYPE.NEW]: {
            success: t('alertMessages.createSuccess'),
            error: t('alertMessages.createFail'),
        },
        [FORM_TYPE.EDIT_COPY]: {
            success: t('alertMessages.createSuccess'),
            error: t('alertMessages.createFail'),
        },
    };

    const onSubmit = async () => {
        const { name, text } = getValues();
        try {
            await replyTemplatesService.addOrUpdateReplyTemplate({
                name,
                text,
                ...(modal.type === FORM_TYPE.EDIT && { id: selectedTemplate.id }),
            });
            dispatch(alertActions.success(RESPONSE_MSG[modal.type].success));
            REFETCH_MAP[modal.type]();
            setModal({ open: false, type: FORM_TYPE.NEW });
            setSelectedTemplate(null);
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(RESPONSE_MSG[modal.type].error));
            }
        }
    };

    const isView = modal.type === FORM_TYPE.VIEW;

    return (
        <Modal
            isOpen={modal.open}
            handleClose={onClose}
            title={
                modal.type === FORM_TYPE.NEW || modal.type === FORM_TYPE.EDIT_COPY
                    ? t('ReviewsReplyTemplates.addTemplate')
                    : modal.type === FORM_TYPE.VIEW
                    ? t('ReviewsReplyTemplates.viewTemplate')
                    : t('ReviewsReplyTemplates.editTemplate')
            }
            onPrimaryAction={handleSubmit(onSubmit)}
            primaryActionText={t('buttons.save')}
            primaryActionDisabled={isSubmitting || !isValid || !isDirty || isView}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content>
                <Controller
                    control={control}
                    name="name"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('common.templateName')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                            disabled={isView}
                            sx={{ gridArea: 'input1' }}
                        />
                    )}
                />
                <Typography
                    variant="caption"
                    sx={{ color: 'rgba(0, 0, 0, 0.6)', display: 'block', gridArea: 'text', paddingBottom: '16px' }}
                >
                    {t('ReviewsReplyTemplates.messageInputText')}
                </Typography>
                <Controller
                    control={control}
                    name="text"
                    render={({ field, fieldState: { error }, ref }) => (
                        <div style={{ gridArea: 'input3' }}>
                            <TextEditor
                                {...field}
                                ref={ref}
                                label={t('common.message', { count: 1 })}
                                limit={1000}
                                initialValue={selectedTemplate?.text || ''}
                                onChange={text =>
                                    setValue('text', parseTipTapJSONToPlainText(text), {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                    })
                                }
                                editable={!isView}
                                error={error}
                            />
                        </div>
                    )}
                />
            </Content>
        </Modal>
    );
};

export default EditModal;
