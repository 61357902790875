import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import Tooltip from '@components/Tooltip';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Table } from '@components/Table';
import { CopyIcon } from 'assets/images/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from '@hooks/useTranslation';
import { FORM_TYPE } from '../EditModal';

const CroppedText = styled(Typography)({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 400,
    '@media (max-width: 1024px)': {
        maxWidth: 200,
    },
});

const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            width: '20%',
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.templateName')}
                </Typography>
            ),
        },
        {
            key: 1,
            width: '65%',
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.message', { count: 1 })}
                </Typography>
            ),
        },
        {
            key: 2,
            width: '5%',
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('InboxTemplates.sharedTemplate')}
                </Typography>
            ),
        },
        {
            key: 3,
            width: '10%',
            component: <Typography align="left" variant="subtitle2"></Typography>,
        },
    ];
};

const GetTableBody = (data, onClickAction, isAccountOwner, currentUser) => {
    const { t } = useTranslation();
    return data.map(template => {
        const IS_TEMPLATE_OWNER = template.authorId === currentUser.id;
        return [
            {
                component: (
                    <Typography variant="subtitle2" align="left">
                        {template.name}
                    </Typography>
                ),
            },
            {
                component: (
                    <CroppedText variant="body2" align="left">
                        {template.message}
                    </CroppedText>
                ),
            },
            {
                component: (
                    <Typography style={{ width: 220 }} variant="body2" align="left">
                        {template.shared ? t('common.yes') : t('common.no')}
                    </Typography>
                ),
            },
            ...(isAccountOwner || IS_TEMPLATE_OWNER
                ? [
                      {
                          component: (
                              <Typography align="right" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <Tooltip title={t('common.edit')} placement="top">
                                      <IconButton onClick={() => onClickAction(template, FORM_TYPE.EDIT)}>
                                          <EditIcon viewBox="0 0 22 22" />
                                      </IconButton>
                                  </Tooltip>
                                  <Tooltip title={t('common.duplicate')} placement="top">
                                      <IconButton
                                          sx={{ width: '36px' }}
                                          onClick={() => onClickAction(template, FORM_TYPE.EDIT_COPY)}
                                      >
                                          <CopyIcon style={{ fill: '#000', width: 18 }} />
                                      </IconButton>
                                  </Tooltip>
                                  <Tooltip title={t('common.delete')} placement="top">
                                      <IconButton onClick={() => onClickAction(template, FORM_TYPE.DELETE)}>
                                          <DeleteIcon sx={{ path: { fill: 'rgba(0,0,0,.6)' } }} />
                                      </IconButton>
                                  </Tooltip>
                              </Typography>
                          ),
                      },
                  ]
                : [
                      {
                          component: (
                              <Typography align="right">
                                  <Tooltip title={t('common.view')} placement="top">
                                      <IconButton onClick={() => onClickAction(template, FORM_TYPE.VIEW)}>
                                          <VisibilityIcon viewBox="0 0 22 22" />
                                      </IconButton>
                                  </Tooltip>
                              </Typography>
                          ),
                      },
                  ]),
        ];
    });
};

const InboxTemplateTable = ({
    setModal,
    setSelectedTemplate,
    setPage,
    page,
    setRowsPerPage,
    rowsPerPage,
    setDeleteModalOpen,
    isAccountOwner,
    currentUser,
}) => {
    const { t } = useTranslation();
    const { data, loading, totalCount, pages } = useSelector(state => state.inboxTemplates);

    const onClickAction = (template, type) => {
        setSelectedTemplate({
            ...template,
            ...(type === FORM_TYPE.EDIT_COPY && { name: `${template.name} (${t('common.copy')})` }),
        });
        if (type === FORM_TYPE.DELETE) {
            setDeleteModalOpen(true);
            return;
        }
        setModal({ open: true, type });
    };

    const onPageChange = (_, newPage) => setPage(newPage);

    const onRowsPerPageChange = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer>
            <Table
                withHover
                columnNames={GetTableHeader()}
                rows={GetTableBody(data, onClickAction, isAccountOwner, currentUser)}
                isDataLoading={loading}
                withPagination
                paginationProps={{
                    colSpan: 5,
                    count: totalCount,
                    rowsPerPage,
                    page,
                    onPageChange,
                    onRowsPerPageChange,
                    labelDisplayedRows: ({ from, to, count }) =>
                        `${from}–${to} ${t('Table.of')} ${count !== -1 ? count : `${t('Table.moreThan')} ${to}`}`,
                    labelRowsPerPage: <span>{t('Table.rowsPerPage')}</span>,
                    pages,
                }}
            />
        </TableContainer>
    );
};

export default InboxTemplateTable;
