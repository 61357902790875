import { dashboardConstants } from '@constants/dashboard';

const initialState = {
    dashboard: {
        presence: {
            views: 0,
            viewsDelta: 0,
            engagement: 0,
            engagementDelta: 0,
            conversion: 0,
            conversionDelta: 0,
            distribution: [],
        },
        profilesSync: {
            actionRequired: 0,
            inProgress: 0,
            synced: 0,
        },
        inboxes: {
            mine: 0,
            open: 0,
            unassigned: 0,
        },
        reviews: {
            averageRating: 0,
            averageRatingDelta: 0,
            collected: 0,
            collectedDelta: 0,
            unrepliedReviews: 0,
            ratingDistribution: [],
        },
        surveyResponses: {
            averageRating: 0,
            averageRatingDelta: 0,
            nps: 0,
            npsDelta: 0,
            responsesCount: 0,
            responsesCountDelta: 0,
            npsDistribution: [],
        },
        socialPosts: {
            engagement: 0,
            engagementDelta: 0,
            impressions: 0,
            impressionsDelta: 0,
            reach: 0,
            reachDelta: 0,
        },
    },
    leaderboard: [],
    recommendations: [],
};

export function dashboard(state = initialState, action) {
    switch (action.type) {
        case dashboardConstants.DASHBOARD_SUCCESS:
            return { ...state, dashboard: action.data };
        case dashboardConstants.DASHBOARD_ERROR:
            return { ...state, dashboard: initialState.dashboard };
        case dashboardConstants.LEADERBOARD_SUCCESS:
            return { ...state, leaderboard: action.data };
        case dashboardConstants.LEADERBOARD_ERROR:
            return { ...state, leaderboard: [] };
        case dashboardConstants.RECOMMENDATIONS_SUCCESS:
            return { ...state, recommendations: action.data };
        case dashboardConstants.RECOMMENDATIONS_ERROR:
            return { ...state, recommendations: [] };
        case dashboardConstants.SET_RECOMMENDATIONS:
            return { ...state, recommendations: action.data };
        default:
            return state;
    }
}
