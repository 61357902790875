import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';

const Container = styled('div')({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const Progress = styled(CircularProgress)({
    color: 'rgba(0, 0, 0, 0.38)',
    width: '58px !important',
    height: '58px !important',
    display: 'block',
    margin: '0 auto 24px',
});

const ReviewsAreLoading = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <div>
                <Progress />
                <Typography variant="h4" sx={{ color: '#1F4C5C', marginBottom: '8px', textAlign: 'center' }}>
                    {t('ReviewsEmptyFeed.reviewsAreLoadingTitle')}
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'center' }}>
                    {t('ReviewsEmptyFeed.reviewsAreLoadingDescription')}
                </Typography>
            </div>
        </Container>
    );
};

export default ReviewsAreLoading;
