import { StyledPopper, StyledMenuHeader, ScrollableSection } from './styles';
import { DesktopMenuItem } from '.';

const Submenu = ({ anchorEl, menu }) => {
    return (
        anchorEl && (
            <StyledPopper
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                disablePortal={true}
                popperOptions={{ placement: 'right' }}
            >
                <StyledMenuHeader>{menu.header}</StyledMenuHeader>
                <ScrollableSection>
                    {menu?.list?.map(item => (
                        <DesktopMenuItem key={item.text} item={item} anchorEl={anchorEl} />
                    ))}
                </ScrollableSection>
            </StyledPopper>
        )
    );
};

export default Submenu;
