import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { Footer, Header, Progress } from './styles';

const ViewCompleted = () => {
    const { t } = useTranslation();

    return (
        <>
            <Header>
                <Typography variant="h6">{t('OnboardingPage.success')} 🎉 🎉 🎉</Typography>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '4px' }}></Typography>
            </Header>
            <Footer isNotMain>
                <Progress />
            </Footer>
        </>
    );
};

export default ViewCompleted;
