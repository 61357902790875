import { useSelector } from 'react-redux';
import { Typography } from '@components/Typography';
import Tooltip from '@components/Tooltip';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Table } from '@components/Table';
import { useTranslation } from '@hooks/useTranslation';
import { formatCompanies } from '@helpers/companies';
import { REVIEW_TYPE } from '@constants/autoreply-rules';
import { FORM_TYPE } from '../EditModal';

export const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('ReviewsAutoreplies.ruleName')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.locations')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.rating')}
                </Typography>
            ),
        },
        {
            key: 3,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('ReviewsAutoreplies.reviewType')}
                </Typography>
            ),
        },
        { key: 4, component: <Typography align="left" variant="subtitle2"></Typography> },
    ];
};

export const GetTableBody = (data, user, allCompanies, onClickAction) => {
    const { t } = useTranslation();
    return data.map(rule => [
        {
            component: (
                <Typography variant="subtitle2" align="left">
                    {rule.name}
                </Typography>
            ),
        },
        {
            component: (
                <Typography variant="body2" align="left">
                    {formatCompanies(allCompanies, rule.companies, 30)}
                </Typography>
            ),
        },
        {
            component: (
                <Typography variant="body2" align="left">
                    {`(${rule.ratings.length}) ${rule.ratings.join(', ')}`}
                </Typography>
            ),
        },
        {
            component: (
                <Typography
                    variant="body2"
                    align="left"
                    sx={{
                        textTransform: 'lowercase',
                        maxWidth: '180px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {`(${rule.reviewTypes.length}) ${rule.reviewTypes
                        .map(reviewType =>
                            reviewType === REVIEW_TYPE.WITHOUT_COMMENT
                                ? t('ReviewsAutoreplies.withoutComment')
                                : t('ReviewsAutoreplies.withComment'),
                        )
                        .join(', ')}`}
                </Typography>
            ),
        },
        ...(user?.isAccountOwner || user?.isGlobalAdmin
            ? [
                  {
                      component: (
                          <Typography align="right" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Tooltip title={t('common.edit')} placement="top">
                                  <IconButton onClick={() => onClickAction(rule, FORM_TYPE.EDIT)}>
                                      <EditIcon viewBox="0 0 22 22" />
                                  </IconButton>
                              </Tooltip>
                              <Tooltip title={t('common.delete')} placement="top">
                                  <IconButton onClick={() => onClickAction(rule, FORM_TYPE.DELETE)}>
                                      <DeleteIcon viewBox="0 0 22 22" />
                                  </IconButton>
                              </Tooltip>
                          </Typography>
                      ),
                  },
              ]
            : [
                  {
                      component: (
                          <Typography align="right">
                              <Tooltip title={t('common.view')} placement="top">
                                  <IconButton onClick={() => onClickAction(rule, FORM_TYPE.VIEW)}>
                                      <VisibilityIcon viewBox="0 0 22 22" />
                                  </IconButton>
                              </Tooltip>
                          </Typography>
                      ),
                  },
              ]),
    ]);
};

const AutoreplyRulesTable = ({
    setSelectedRule,
    setDeleteModalOpen,
    setModal,
    setPage,
    page,
    setRowsPerPage,
    rowsPerPage,
    user,
    companies,
}) => {
    const { t } = useTranslation();
    const { data, loading, totalCount, pages } = useSelector(state => state.autoreplyRules);

    const onClickAction = (rule, type) => {
        setSelectedRule(rule);
        if (type === FORM_TYPE.DELETE) {
            setDeleteModalOpen(true);
            return;
        }
        setModal({ open: true, type });
    };

    const onPageChange = (_, newPage) => setPage(newPage);

    const onRowsPerPageChange = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer>
            <Table
                withHover
                columnNames={GetTableHeader()}
                rows={GetTableBody(data, user, companies, onClickAction)}
                isDataLoading={loading}
                withPagination
                paginationProps={{
                    colSpan: 5,
                    count: totalCount,
                    rowsPerPage,
                    page,
                    onPageChange,
                    onRowsPerPageChange,
                    labelDisplayedRows: ({ from, to, count }) =>
                        `${from}–${to} ${t('Table.of')} ${count !== -1 ? count : `${t('Table.moreThan')} ${to}`}`,
                    labelRowsPerPage: <span>{t('Table.rowsPerPage')}</span>,
                    pages,
                }}
            />
        </TableContainer>
    );
};

export default AutoreplyRulesTable;
