import { reviewsConstants } from '@constants/reviews';

const initialState = {
    loading: true,
    hasMore: false,
    newReviewsExist: false,
    reviews: [],
};

export function reviews(state = initialState, action) {
    switch (action.type) {
        case reviewsConstants.REVIEWS_REQUEST:
            return { ...state, loading: true };
        case reviewsConstants.REVIEWS_SUCCESS:
            return {
                ...state,
                reviews: action.reviews.items,
                hasMore: action.reviews.hasMore,
                loading: false,
            };
        case reviewsConstants.REVIEWS_LOAD_MORE_SUCCESS:
            return {
                ...state,
                reviews: [...state.reviews, ...action.reviews.items],
                hasMore: action.reviews.hasMore,
            };
        case reviewsConstants.NEW_REVIEW_EXIST_SUCCESS:
            return { ...state, newReviewsExist: action.payload };
        case reviewsConstants.NEW_REVIEW_EXIST_RESET:
            return { ...state, newReviewsExist: false };
        case reviewsConstants.UPDATE_REVIEWS:
            return { ...state, reviews: action.payload };
        case reviewsConstants.RESET_LOADING:
            return { ...state, loading: false };
        default:
            return state;
    }
}
