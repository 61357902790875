import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { accountActions } from '@actions';
import WelcomeForm from './WelcomeForm';
import SecondStep from './SecondStep';
import Header from './Header';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#87AFBD',
});

const AppContent = styled('div')({
    padding: '24px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    minHeight: `calc(var(--100vh) - 72px)`,
    marginTop: 72,
    '@media (max-width: 1024px)': {
        padding: 0,
        height: `calc(var(--100vh) - 60px)`,
        minHeight: `calc(var(--100vh) - 60px)`,
        marginTop: 60,
    },
});

const OnboardingPage = () => {
    const account = useSelector(state => state.account);
    const auth = useSelector(state => state.authentication);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!account.account && !account.loading && auth.user) {
            dispatch(accountActions.get());
        }
    }, [account, auth.user, dispatch]);

    const accountLoaded = account.account && auth.user;

    return (
        <Container>
            <Header />
            <AppContent>
                {accountLoaded && account?.account?.onboardingStatus === 0 ? <WelcomeForm /> : null}
                {accountLoaded && account?.account?.onboardingStatus !== 0 ? (
                    <SecondStep account={account?.account} user={auth.user} />
                ) : null}
            </AppContent>
        </Container>
    );
};

export default OnboardingPage;
