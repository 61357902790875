import { useState, useEffect, useMemo } from 'react';
import { formatDistanceStrict } from 'date-fns';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { LabelPlain } from '@components/Label';
import Avatar from '@components/Avatar';
import { getInitialsParts, getFullName, getInitialsFull, getUserContacts } from '@helpers/userName';
import { getChannelIcon } from '@helpers/channels';
import { MESSAGE_CONTENT_TYPE } from '@constants/inbox';
import { useTranslation } from '@hooks/useTranslation';
import { UserIcon } from 'assets/images/icons';
import { CHANNEL } from '@constants/channels';

const StyledUserIcon = styled(UserIcon)({
    width: '18px !important',
    height: '18px !important',
    '& path': {
        fill: 'rgba(0, 0, 0, 0.38)',
        fillOpacity: 1,
    },
});

const DialogWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'selected' && prop !== 'read',
})(({ selected, read }) => ({
    willChange: 'scroll-position',
    border: selected ? '1px solid #1F4C5C' : '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: selected || !read ? '#EBF6F0' : '#fff',
    padding: 8,
    borderRadius: 4,
    cursor: 'pointer',
    marginBottom: 8,
    '&:hover': {
        borderColor: '#1F4C5C',
    },
}));

const Dialog = ({ data, channelName, selected, language, onClick, conversationRef, users }) => {
    const [, setTime] = useState(new Date());

    const { t } = useTranslation();

    useEffect(() => {
        const interval = setInterval(() => setTime(new Date()), 30000);
        return () => clearInterval(interval);
    }, []);

    const lastMessage = {
        [MESSAGE_CONTENT_TYPE.TEXT]: data.lastMessage,
        [MESSAGE_CONTENT_TYPE.IMAGE]: t('common.image'),
        [MESSAGE_CONTENT_TYPE.FILE]: t('common.file'),
        [MESSAGE_CONTENT_TYPE.VIDEO]: t('common.video'),
        [MESSAGE_CONTENT_TYPE.AUDIO]: t('common.audio'),
        [MESSAGE_CONTENT_TYPE.STORY_IMAGE]: t('common.story'),
        [MESSAGE_CONTENT_TYPE.STORY_VIDEO]: t('common.story'),
    };

    const assignedUser = useMemo(() => {
        return users.find(user => user.id === data.assignedUserId);
    }, [data.assignedUserId, users]);

    return (
        <DialogWrapper selected={selected} onClick={onClick} read={data.read} ref={conversationRef}>
            <div style={{ display: 'grid', gridGap: '0 8px', gridTemplateColumns: '40px calc(100% - 48px)' }}>
                <Avatar
                    size="large"
                    fullName={getFullName(data?.firstName, data?.lastName)}
                    label={getInitialsParts(data?.firstName, data?.lastName)}
                    icon={<StyledUserIcon />}
                    withBadge
                    badge={getChannelIcon(data.channelId === CHANNEL.ARCHIVED ? CHANNEL.ARCHIVED : data.channelType)}
                />
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                        <Typography
                            variant="caption"
                            sx={{
                                color: 'rgba(0, 0, 0, 0.6)',
                                marginBottom: '2px',
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {channelName}
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'end', whiteSpace: 'nowrap' }}
                        >
                            {formatDistanceStrict(new Date(data.lastActivityAt), new Date(), {
                                addSuffix: true,
                                locale: I18N_DATE_FORMAT[language],
                            })}
                        </Typography>
                    </div>
                    <Typography
                        variant="subtitle2"
                        sx={{ marginBottom: '2px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {getUserContacts(
                            {
                                firstName: data.firstName,
                                lastName: data.lastName,
                                phoneNumber: data.phoneNumber,
                                email: data.email,
                            },
                            '-',
                        )}
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{
                            color: 'rgba(0, 0, 0, 0.6)',
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {lastMessage[data.lastMessageContentType]}
                    </Typography>
                </div>
            </div>
            <div
                style={{
                    marginTop: data.labels?.length ? '8px' : 0,
                    display: 'grid',
                    gridTemplateColumns: assignedUser !== undefined ? 'calc(100% - 28px) 20px' : 'calc(100%) 0px',
                    gridGap: '8px',
                    alignItems: 'baseline',
                }}
            >
                <div style={{ overflow: 'hidden', display: 'flex' }}>
                    {data.labels?.length
                        ? data.labels.map(label => (
                              <LabelPlain key={label.id} type={label.name} variant="caption" size="small">
                                  {label.name}
                              </LabelPlain>
                          ))
                        : null}
                </div>
                {assignedUser !== undefined && (
                    <Avatar
                        size="xSmall"
                        fullName={`${assignedUser?.firstName} ${assignedUser?.lastName}`}
                        label={getInitialsFull(`${assignedUser?.firstName} ${assignedUser?.lastName}`)}
                        icon={<StyledUserIcon />}
                    />
                )}
            </div>
        </DialogWrapper>
    );
};

export default Dialog;
