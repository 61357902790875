import i18next from 'i18next';

const format = (data, notation, style) => {
    const language = i18next.language;
    const locale = language === 'UA' ? 'uk-UA' : language;
    const formatter = new Intl.NumberFormat(locale, { notation, style });
    return formatter.format(data);
};

export function formatNumber(data, notation = 'standard') {
    try {
        const number = Number(data);
        return format(number, notation, 'decimal')?.replace(/\.$/, '')?.replace(/\s/g, ' ');
    } catch (e) {
        console.error('formatNumber', data);
    }
}

export function formatPercentNumber(data, notation = 'standard') {
    try {
        const number = Number(data);
        return format(number / 100, notation, 'percent');
    } catch (e) {
        console.error('formatPercentNumber', data);
    }
}
