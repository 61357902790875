import { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useCurrentEditor } from '@tiptap/react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Button from '@components/Button';
import { Typography } from '@components/Typography';
import Avatar from '@components/Avatar';
import { UsersMenu } from '@components/UsersMenu';
import { InternalLink } from '@components/Link';
import TemplateMenu from '@pages/InboxPage/Inbox/TemplateMenu';
import SurveysMenu from '@pages/InboxPage/Inbox//SurveysMenu';
import { getInitialsParts, getFullName, getUserContacts } from '@helpers/userName';
import { validateFileSize } from '@helpers/upload-file';
import { parseTipTapJSONToPlainText } from '@helpers/text-editor';
import { useTranslation } from '@hooks/useTranslation';
import { contactsActions, alertActions, conversationsActions, inboxActions, channelsActions } from '@actions';
import { conversationsService, inboxService, socketService } from '@services';
import { CONVERSATION_STATUS, DEFAULT_TAKE, DEFAULT_START, ASSIGNED_STATUS } from '@constants/conversations';
import { INBOX_HISTORY, MESSAGE_STATUS } from '@constants/inbox';
import { MB } from '@constants/file';
import { AITaskType, AI_TASK_TYPE } from '@constants/ai-assistant';
import { CHANNEL, STATUS } from '@constants/channels';
import { EDITOR_TYPES } from '@components/TextEditor';
import { InboxEmptyIcon, AttachIcon } from 'assets/images/icons';
import { SendIcon } from 'assets/images/icons';
import Message from './Message';
import ConversationsEvent from './ConversationsEvent';
import { Container, Header, Feed, Footer, SendButton, StyledTextEditor, StyledUserIcon } from './styles';

const ButtonComponent = ({ disabled, onClick }) => {
    const { editor } = useCurrentEditor();

    const handleClick = async () => {
        try {
            await onClick();
            editor?.commands.clearContent();
        } catch (error) {
            console.error(error);
        }
    };
    return <SendButton variant="contained" disabled={disabled} onClick={handleClick} endIcon={<SendIcon />} />;
};

const Inbox = ({ isMobile, filter, setFilter, selectedConversation, setSelectedConversation }) => {
    const [message, setMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [assignedUser, setAssignedUser] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { data: messages, loading } = useSelector(state => state.inbox);
    const { data: conversations } = useSelector(state => state.conversations);
    const channels = useSelector(state => state.channels.data);
    const account = useSelector(state => state.account?.account);
    const user = useSelector(state => state.authentication.user);
    const users = useSelector(state => state.account?.account?.users || []);
    const companies = useSelector(state => state.account?.account?.companies || []);
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const messagesRef = useRef(null);
    const channelsRef = useRef([]);
    const conversationsRef = useRef([]);
    const delayedMessagesRef = useRef([]);
    const attachInputRef = useRef(null);

    const conversationCompanies = useMemo(
        () =>
            companies
                .filter(company => company.channels.some(channel => channel === selectedConversation?.channelId))
                .map(company => company.companyId),
        [companies, selectedConversation],
    );

    const usersOptions = useMemo(() => {
        return users
            .filter(user => {
                if (user.isAccountOwner || user.isGlobalAdmin) return true;
                return user.roles?.some(role => conversationCompanies.includes(role.companyId));
            })
            .map(user => ({ label: `${user.firstName} ${user.lastName}`, value: user.id }));
    }, [users, conversationCompanies]);

    useEffect(() => {
        channelsRef.current = channels;
        conversationsRef.current = conversations;
    }, [channels, conversations]);

    useEffect(() => {
        messagesRef.current = messages;
        // when messages change, update messages with delayed statuses
        if (delayedMessagesRef.current.length) {
            delayedMessagesRef.current.forEach(payload => {
                const messagesCopy = messagesRef.current.slice();
                const updatedMessageIndex = messagesCopy.findIndex(c => c.id === payload?.messageId);
                if (updatedMessageIndex !== -1) {
                    messagesCopy[updatedMessageIndex].status = payload?.status;
                    dispatch(inboxActions.update(messagesCopy));
                }
            });
            delayedMessagesRef.current = [];
        }
    }, [messages]);

    useEffect(() => {
        socketService.registerOnServerEvents('inbox-message-status', payload => {
            const messagesCopy = messagesRef.current.slice();
            const updatedMessageIndex = messagesCopy.findIndex(c => c.id === payload?.messageId);
            if (updatedMessageIndex !== -1) {
                messagesCopy[updatedMessageIndex].status = payload?.status;
                if (payload?.messageContent) {
                    messagesCopy[updatedMessageIndex].content = payload?.messageContent;
                }
                dispatch(inboxActions.update(messagesCopy));
            } else {
                // put payload into queue to update later
                delayedMessagesRef.current.push(payload);
            }
            // update conversations if channel removed
            if (payload?.channelId === CHANNEL.ARCHIVED) {
                const conversationsCopy = conversationsRef.current.slice();
                const updatedConversationIndex = conversationsRef.current?.findIndex(
                    c => c.id === payload?.conversationId,
                );
                if (updatedConversationIndex !== -1) {
                    conversationsCopy[updatedConversationIndex].channelId = CHANNEL.ARCHIVED;
                    dispatch(conversationsActions.update(conversationsCopy));
                    selectedConversation(prev => {
                        if (prev?.id === payload?.conversationId) {
                            return { ...prev, channelId: CHANNEL.ARCHIVED, channelStatus: payload?.channelStatus };
                        }
                        return prev;
                    });
                }
            }
            // update channels if channel status changed
            const channelIndex = channelsRef.current?.findIndex(c => c.id === payload?.channelId);
            if (channelIndex !== -1) {
                if (channelsRef.current[channelIndex].status !== payload?.channelStatus) {
                    channelsRef.current[channelIndex].status = payload?.channelStatus;
                    dispatch(channelsActions.update(channelsRef.current));
                    setSelectedConversation(prev => {
                        if (prev?.channelId === payload?.channelId) {
                            return { ...prev, channelStatus: payload?.channelStatus };
                        }
                        return prev;
                    });
                    const conversationsCopy = conversationsRef.current.slice();
                    const updatedConversationIndex = conversationsRef.current?.findIndex(
                        c => c.id === payload?.conversationId,
                    );
                    if (updatedConversationIndex !== -1) {
                        conversationsCopy[updatedConversationIndex].channelStatus = payload?.channelStatus;
                        dispatch(conversationsActions.update(conversationsCopy));
                    }
                }
            }
        });
    }, [dispatch]);

    useEffect(() => {
        return () => socketService.stopConnection('inbox-message-status');
    }, []);

    useEffect(() => {
        if (selectedConversation) {
            setAssignedUser(selectedConversation.assignedUserId);
            setMessage('');
        }
    }, [selectedConversation]);

    const onClickBack = () => {
        history.replace('/messaging/inbox');
        setFilter(prev => ({ ...prev, status: selectedConversation.status }));
        setSelectedConversation(null);
    };

    const onClickContactDetails = () => {
        dispatch(contactsActions.getContact(selectedConversation?.contactId));
        dispatch(contactsActions.setContactOpen(true));
    };

    const onClickSolve = async () => {
        try {
            if (selectedConversation?.status === CONVERSATION_STATUS.OPEN) {
                await conversationsService.close(selectedConversation?.id);
                await dispatch(conversationsActions.get(filter, 0, 15));
                dispatch(conversationsActions.getCounters(filter));
                setSelectedConversation(null);
            } else {
                const newConversationEvent = await conversationsService.open(selectedConversation?.id);
                setFilter(prev => ({ ...prev, status: CONVERSATION_STATUS.OPEN }));
                setSelectedConversation(prev => ({ ...prev, status: CONVERSATION_STATUS.OPEN }));
                await dispatch(conversationsActions.get({ ...filter, status: CONVERSATION_STATUS.OPEN }, 0, 15));
                dispatch(conversationsActions.getCounters({ ...filter, status: CONVERSATION_STATUS.OPEN }));
                dispatch(inboxActions.update([...messages, newConversationEvent]));
            }
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
    };

    const onClickSendMessage = async () => {
        try {
            await inboxService.sendMessage(selectedConversation?.id, parseTipTapJSONToPlainText(message));
            setMessage('');
            if (!selectedConversation?.assignedUserId) {
                setAssignedUser(user?.id);
            }
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
            throw error;
        }
    };

    const onUploadImage = async e => {
        if (!e.target.files[0]) return;

        const { error, metric, size } = validateFileSize(e.target.files[0], 5 * MB);
        if (error) {
            dispatch(alertActions.error(t('apiErrors.file_size_too_large', { size, metric })));
            return;
        }
        try {
            setIsUploading(true);
            const data = new FormData();
            data.append('file', e.target.files[0]);
            data.append('fileName', e.target.files[0].name);
            data.append('conversationId', selectedConversation?.id);
            await inboxService.uploadImage(data);
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
        setIsUploading(false);
        e.target.value = '';
    };

    const onClickResendMessage = async messageId => {
        try {
            await inboxService.resendMessage(messageId);
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
    };

    const onClickAttach = () => attachInputRef.current?.click();

    const onClickUsersMenu = event => setAnchorEl(event.currentTarget);

    const onCloseUsersMenu = () => setAnchorEl(null);

    const onSelectUsersMenu = async userId => {
        if (assignedUser === userId) return;

        setAssignedUser(userId);
        try {
            const updatedConversation = await conversationsService.assign(selectedConversation?.id, { userId });
            setSelectedConversation(updatedConversation);
            if (filter.assignment === ASSIGNED_STATUS.ALL) {
                dispatch(
                    conversationsActions.update([
                        ...conversations.filter(c => c.id !== updatedConversation.id),
                        updatedConversation,
                    ]),
                );
            } else {
                dispatch(conversationsActions.get(filter, DEFAULT_START, DEFAULT_TAKE));
            }
            dispatch(conversationsActions.getCounters(filter));
            dispatch(alertActions.success(t('alertMessages.updateSuccess')));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
    };

    const messagesHistory = useMemo(() => {
        const sortedMessages = messages.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        const groupedMessages = [];
        let currentGroup = null;

        for (let i = 0; i < sortedMessages.length; i++) {
            const message = sortedMessages[i];
            const messageTime = new Date(message.createdAt);

            // Extract date up to minute and type of the current message
            const currentGroupDate = currentGroup ? new Date(currentGroup.createdAt) : null;
            const messageDate = new Date(
                messageTime.getFullYear(),
                messageTime.getMonth(),
                messageTime.getDate(),
                messageTime.getHours(),
                messageTime.getMinutes(),
            );

            if (
                !currentGroup ||
                messageDate.getTime() !== currentGroupDate.getTime() ||
                message.type !== currentGroup.type ||
                message.status === MESSAGE_STATUS.FAILED
            ) {
                // Create a new group if there's no current group or if the message's date and type
                // is different from the current group's date and type
                currentGroup = {
                    ...message,
                    children: [
                        {
                            content: message.content,
                            contentType: message.contentType,
                            createdAt: message.createdAt,
                            replyMessage: message.replyMessage,
                            senderType: message.senderType,
                            status: message.status,
                        },
                    ],
                    createdAt: messageDate,
                };
                groupedMessages.push(currentGroup);
            } else {
                // Concatenate the message content to the current group's content
                currentGroup.children.push({
                    content: message.content,
                    contentType: message.contentType,
                    createdAt: message.createdAt,
                    replyMessage: message.replyMessage,
                    senderType: message.senderType,
                    status: message.status,
                });
            }
        }
        return groupedMessages;
    }, [messages]);

    const inputDisabled = !selectedConversation || selectedConversation?.status !== CONVERSATION_STATUS.OPEN;
    const isArchived = selectedConversation?.channelId === CHANNEL.ARCHIVED;

    return (
        <Container hasConversation={!!selectedConversation}>
            {selectedConversation && (
                <>
                    <Header>
                        {isMobile && (
                            <Button sx={{ minWidth: 'auto' }} variant="outlined" onClick={onClickBack}>
                                <ArrowBackIcon sx={{ fill: 'rgba(0, 0, 0, 0.6)' }} />
                            </Button>
                        )}
                        <Avatar
                            size={isMobile ? 'medium' : 'large'}
                            fullName={getFullName(selectedConversation?.firstName, selectedConversation?.lastName)}
                            label={getInitialsParts(selectedConversation?.firstName, selectedConversation?.lastName)}
                            icon={<StyledUserIcon isLarge={!isMobile} />}
                        />
                        {selectedConversation && (
                            <>
                                <div>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    >
                                        {getUserContacts(
                                            {
                                                firstName: selectedConversation?.firstName,
                                                lastName: selectedConversation?.lastName,
                                                phoneNumber: selectedConversation?.phoneNumber,
                                                email: selectedConversation?.email,
                                            },
                                            '-',
                                        )}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        sx={{ cursor: 'pointer', color: '#1F4C5C' }}
                                        onClick={onClickContactDetails}
                                    >
                                        {t('InboxPage.viewDetails')}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <UsersMenu
                                        usersOptions={usersOptions}
                                        value={assignedUser}
                                        onClick={onClickUsersMenu}
                                        onClose={onCloseUsersMenu}
                                        onSelect={onSelectUsersMenu}
                                        open={open}
                                        anchorEl={anchorEl}
                                    />
                                    <Button
                                        variant="outlined"
                                        disabled={!selectedConversation || isArchived}
                                        onClick={onClickSolve}
                                    >
                                        {t(
                                            selectedConversation?.status === CONVERSATION_STATUS.OPEN
                                                ? 'buttons.resolve'
                                                : 'buttons.reopen',
                                        )}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Header>
                    <Feed>
                        {!loading &&
                            messagesHistory.map((item, index) =>
                                item.modelType === INBOX_HISTORY.MESSAGE ? (
                                    <Message
                                        key={index}
                                        data={item}
                                        users={users}
                                        companies={companies}
                                        assignedLocation={
                                            selectedConversation?.locations?.length === 1
                                                ? selectedConversation?.locations[0]
                                                : null
                                        }
                                        channel={selectedConversation?.channelType}
                                        channelStatus={selectedConversation?.channelStatus}
                                        onResend={() => onClickResendMessage(item.id)}
                                    />
                                ) : (
                                    <ConversationsEvent key={index} data={item} users={users} />
                                ),
                            )}
                    </Feed>
                    <Footer>
                        {selectedConversation?.channelId === CHANNEL.ARCHIVED ? (
                            <Typography
                                variant="caption"
                                sx={{ textAlign: 'center', display: 'block', color: 'rgba(0, 0, 0, 0.6)' }}
                            >
                                {t('InboxPage.archivedConversation')}
                            </Typography>
                        ) : selectedConversation?.channelStatus === STATUS.DISCONNECTED ? (
                            <Typography
                                variant="caption"
                                sx={{ textAlign: 'center', display: 'block', color: 'rgba(0, 0, 0, 0.6)' }}
                            >
                                <Trans t={t} i18nKey="InboxPage.disconnectedChannel">
                                    <InternalLink
                                        to="/account-settings/channels"
                                        variant="caption"
                                        align="center"
                                        underline="hover"
                                    >
                                        {{ link: t('InboxPage.settings') }}
                                    </InternalLink>
                                </Trans>
                            </Typography>
                        ) : selectedConversation?.status === CONVERSATION_STATUS.CLOSED ? (
                            <Typography
                                variant="caption"
                                sx={{ textAlign: 'center', display: 'block', color: 'rgba(0, 0, 0, 0.6)' }}
                            >
                                {t('InboxPage.closedConversation')}
                            </Typography>
                        ) : (
                            <StyledTextEditor
                                editorType={EDITOR_TYPES.INBOX}
                                initialValue={''}
                                onChange={text => setMessage(text)}
                                editable={!inputDisabled}
                                aiAssistant={{
                                    additionalOptions: [
                                        {
                                            text: t('AIAssistant.writeResponse'),
                                            type: AITaskType.GenerateInboxMessage,
                                            taskType: AI_TASK_TYPE.CONTEXTUAL,
                                            conversationId: selectedConversation?.id,
                                        },
                                    ],
                                }}
                                placeholder={t('InboxPage.messageLabel')}
                                toolbar={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <div style={{ display: 'flex', gap: '6px' }}>
                                            <TemplateMenu
                                                disabled={inputDisabled}
                                                message={message}
                                                setMessage={setMessage}
                                                selectedConversation={selectedConversation}
                                            />
                                            {account?.surveysEnabled && (
                                                <SurveysMenu
                                                    disabled={inputDisabled}
                                                    setMessage={setMessage}
                                                    selectedConversation={selectedConversation}
                                                />
                                            )}
                                            <IconButton
                                                size="small"
                                                color="inherit"
                                                onClick={isUploading ? null : onClickAttach}
                                                disabled={inputDisabled}
                                                sx={{ height: 18, width: 18, padding: '2px !important' }}
                                            >
                                                <div style={{ display: 'flex' }}>
                                                    <input
                                                        ref={attachInputRef}
                                                        hidden
                                                        accept=".jpg,.jpeg,.png,.webp"
                                                        type="file"
                                                        onChange={onUploadImage}
                                                    />
                                                    <AttachIcon style={{ width: 13 }} />
                                                </div>
                                            </IconButton>
                                        </div>
                                        <ButtonComponent
                                            disabled={inputDisabled || !message}
                                            onClick={onClickSendMessage}
                                        />
                                    </div>
                                }
                            />
                        )}
                    </Footer>
                </>
            )}
            {!selectedConversation && (
                <div
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                >
                    <InboxEmptyIcon />
                    <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '16px' }}>
                        {t('InboxPage.noConversationsSelected')}
                    </Typography>
                </div>
            )}
        </Container>
    );
};

export default Inbox;
