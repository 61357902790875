import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import uniqBy from 'lodash.uniqby';
import Button from '@components/Button';
import { ModalBase } from '@components/Modal';
import { FiltersFormCreator } from '@components/FiltersFormCreator';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { useTranslation } from '@hooks/useTranslation';
import { Completeness } from '@constants/companies';
import { FiltersIcon } from 'assets/images/icons';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        width: 564,
        '@media (max-width: 1024px)': {
            width: '100%',
            height: '100%',
            borderRadius: 0,
            maxHeight: 'none',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

export const FiltersWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: '8px',
    '@media print': {
        '& button': {
            display: 'none',
        },
    },
});

const FiltersPanel = ({ onApplyFilters, filter, setFilter }) => {
    const [isFilterOpened, setFilterOpened] = useState(false);
    const [refetchFilters, setRefetchFilters] = useState(false);
    const [filterNumber, setFilterNumber] = useState(0);

    const companies = useSelector(state => state.account?.account?.companies || []);

    const { t } = useTranslation();

    useEffect(() => {
        if (refetchFilters) {
            setRefetchFilters(false);
            onApplyFilters();
        }
    }, [refetchFilters]);

    const options = useMemo(() => {
        //completenessOptions
        const completenessLevelsOptions = [
            { value: Completeness.Low, label: t('common.low') },
            { value: Completeness.Medium, label: t('common.medium') },
            { value: Completeness.High, label: t('common.high') },
        ];

        //locationBrandsOptions
        const locationBrandsOptions = uniqBy(companies, 'brand.id')
            .filter(company => company.brand)
            .map(item => ({
                value: item.brand.id,
                label: item.brand.title,
            }));

        return {
            brands: [...locationBrandsOptions, { value: null, label: `(${t('common.none')})` }],
            completenessLevels: completenessLevelsOptions,
        };
    }, [companies]);

    useEffect(() => {
        const brandsNumber = filter.brands.length !== options.brands.length ? 1 : 0;
        const completenessLevelsNumber = filter.completenessLevels.length !== options.completenessLevels.length ? 1 : 0;
        setFilterNumber(brandsNumber + completenessLevelsNumber);
    }, [filter, options]);

    const update = {
        brands: e => {
            const selectedBrands = getMultiselectSelectedOptions(options.brands, e.target.value);
            setFilter(prev => ({ ...prev, brands: selectedBrands }));
        },
        completenessLevels: e => {
            setFilter(prev => ({
                ...prev,
                completenessLevels: getMultiselectSelectedOptions(options.completenessLevels, e.target.value),
            }));
        },
    };

    const formOptions = [
        {
            type: 'multiSelect',
            label: t('filters.brands'),
            value: filter.brands,
            onChange: update.brands,
            options: options.brands,
            countable: true,
            error: !filter.brands.length,
        },
        {
            type: 'multiSelect',
            label: t('SettingsCompanies.profileCompleteness'),
            value: filter.completenessLevels,
            onChange: update.completenessLevels,
            options: options.completenessLevels,
            countable: true,
            error: !filter.completenessLevels.length,
        },
    ];

    const isValid = Object.keys(filter).every(key => {
        if (Array.isArray(filter[key])) {
            return !!filter[key].length;
        } else {
            return true;
        }
    });

    return (
        <>
            {/* <Controller
                    control={control}
                    name="brands"
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                        <Autocomplete
                            {...field}
                            inputRef={ref}
                            label={t('common.brands')}
                            options={[
                                { group: 'Europe', id: 'AT', label: 'Austria' },
                                { group: 'Europe', id: 'BE', label: 'Belgium' },
                                { group: 'Europe', id: 'BG', label: 'Bulgaria' },
                                { group: 'Europe', id: 'HR', label: 'Croatia' },
                                { group: 'Africa', id: 'DZ', label: 'Algeria' },
                                { group: 'Africa', id: 'AO', label: 'Angola' },
                                { group: 'Asia', id: 'CN', label: 'China' },
                                { group: 'Asia', id: 'JP', label: 'Japan' },
                                { group: 'Asia', id: 'IN', label: 'India' },
                            ]}
                            value={field.value}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            readOnly={isSubmitting}
                            onChangeAutoComplete={items => {
                                setValue('brands', items.length ? items : [], {
                                    shouldDirty: true,
                                });
                            }}
                            required
                            error={error}
                            sx={{ marginBottom: '16px' }}
                            groupSelectProps={{}}
                            inputProps={{
                                style: { display: field?.value?.length > 0 ? 'none' : 'block' },
                            }}
                        />
                    )}
                /> */}
            <Button
                sx={{
                    minWidth: '40px',
                    marginBottom: '22px',
                    '& .MuiButton-startIcon svg': {
                        width: 'auto',
                        height: 'auto',
                    },
                }}
                variant="outlined"
                onClick={() => setFilterOpened(!isFilterOpened)}
                startIcon={<FiltersIcon />}
            >
                {t('buttons.filters')}
                {filterNumber > 0 ? ` (${filterNumber})` : ''}
            </Button>
            <Modal
                isOpen={isFilterOpened}
                handleClose={() => setFilterOpened(false)}
                title={t('buttons.filters')}
                onPrimaryAction={() => {
                    setRefetchFilters(true);
                    setFilterOpened(false);
                }}
                primaryActionDisabled={!isValid}
                primaryActionText={t('buttons.applyFilters')}
                onSecondaryAction={() => setFilterOpened(false)}
                secondaryActionText={t('buttons.cancel')}
            >
                <FiltersFormCreator type="locations" options={formOptions} />
            </Modal>
        </>
    );
};

export default FiltersPanel;
