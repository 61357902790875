import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const accountService = {
    getAccount,
    updateAccount,
    checkSubscriptionType,
    updateQuota,
    getDashboardOnboarding,
    getLocations,
};

/*
    subscriptionStatus:
    0 - подписка stripe не создана
    1 - подписка создана (ожидает оплаты)
    2 - подписка активна
    3 - подписка отменена (пользователь отменил свою подписку)
    4 - проблема с оплатой (не актуальная карточка, недостаточно средств и т.д.)
*/
function getAccount() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/Account`, requestOptions).then(handleResponse);
}

function updateAccount(account) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(account),
    };

    return fetch(`${BASE_URL}api/Account/${account.id}`, requestOptions)
        .then(handleResponse)
        .catch(() => {});
}

function checkSubscriptionType(sessionId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/stripe/subscription/${sessionId}`, requestOptions).then(handleResponse);
}

function updateQuota() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/account/quota`, requestOptions).then(handleResponse);
}

function getDashboardOnboarding() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/account/onboarding`, requestOptions).then(handleResponse);
}

function getLocations(query, page, pageSize) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(query),
    };

    return fetch(`${BASE_URL}api/locations?page=${page}&pageSize=${pageSize}`, requestOptions).then(handleResponse);
}
