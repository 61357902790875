import { useEffect, useState } from 'react';
import { useLocation, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { NavTabs } from '@components/NavTabs';
import { ControlledToaster } from '@components/Toaster';
import { ACCOUNT_SETTINGS_TABS } from '@constants/tabs';
import { useTranslation } from '@hooks/useTranslation';
import { GeneralSettings } from './GeneralSettings';
import Companies from './Companies';
import UserManagement from './UserManagement';
import Integrations from './Integrations';
import Profile from './Profile';
import Channels from './Channels';
import Brands from './Brands';

const PageWrapper = styled('div')({
    '@media (max-width: 1024px)': {
        height: `calc(var(--100vh) - 108px)`,
        padding: '16px 16px 0',
        overflowY: 'auto',
    },
});

const AccountSettingsPage = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const [availableTabs, setAvailableTabs] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [currentTab, setCurrentTab] = useState(pathname);

    const user = useSelector(state => state.authentication.user);
    const account = useSelector(state => state.account?.account);
    const isAdmin = useSelector(state => {
        if (!state.authentication?.user || !state.authentication?.user?.id) {
            return false;
        }
        return state.authentication?.user?.roles?.filter(u => u.role === 0)?.length > 0;
    });

    const productsMaxNumberExceeded =
        account?.companiesMaxCount < account?.companies?.length || account?.usersMaxCount < account?.users?.length;
    const billingExpired = account && account?.billingDiffInSeconds < 0;

    useEffect(() => {
        setCurrentTab(pathname);
    }, [pathname]);

    useEffect(() => {
        if (productsMaxNumberExceeded || billingExpired) {
            setOpenAlert(true);
        }
    }, [productsMaxNumberExceeded, billingExpired]);

    useEffect(() => {
        let translatedTabs = ACCOUNT_SETTINGS_TABS.map(tab => {
            const isDisabled =
                (tab.value === '/account-settings/integrations' || tab.value === '/account-settings/channels') &&
                (productsMaxNumberExceeded || billingExpired);
            return {
                ...tab,
                label: t(tab.label),
                disabledTooltip: isDisabled ? t('common.subscriptionExpiredMsg') : null,
                disabled: isDisabled,
            };
        });
        if (!user?.isAccountOwner && !user?.isGlobalAdmin) {
            translatedTabs = translatedTabs.filter(tab => {
                return (
                    tab.value !== '/account-settings/general' &&
                    tab.value !== '/account-settings/integrations' &&
                    tab.value !== '/account-settings/brands'
                );
            });
        }
        if (!user?.isAccountOwner && !user?.isGlobalAdmin && !isAdmin) {
            translatedTabs = translatedTabs.filter(tab => tab.value === '/account-settings/profile');
        }
        setAvailableTabs(translatedTabs);
    }, [user?.isAccountOwner, user?.isGlobalAdmin, isAdmin, account]);

    const getAvailableTabIndex = () => {
        if (user?.isAccountOwner || user?.isGlobalAdmin) {
            return {
                '/account-settings/profile': 0,
                '/account-settings/general': 1,
                '/account-settings/companies': 2,
                '/account-settings/brands': 3,
                '/account-settings/channels': 4,
                '/account-settings/users': 5,
                '/account-settings/integrations': 6,
            };
        } else if (user?.isAccountOwner || user?.isGlobalAdmin || isAdmin) {
            return {
                '/account-settings/profile': 0,
                '/account-settings/companies': 1,
                '/account-settings/channels': 2,
                '/account-settings/users': 3,
            };
        } else {
            return {
                '/account-settings/profile': 0,
            };
        }
    };

    return (
        <PageWrapper>
            <NavTabs
                tabLinks={availableTabs}
                onTabClick={selectedTab => setCurrentTab(selectedTab)}
                currentTabIndex={getAvailableTabIndex()[currentTab]}
            />
            <Switch>
                <Route path="/account-settings/profile" component={Profile} />
                <Route path="/account-settings/general" component={GeneralSettings} />
                <Route path="/account-settings/companies" component={Companies} />
                <Route path="/account-settings/brands" component={Brands} />
                <Route path="/account-settings/channels" component={Channels} />
                <Route path="/account-settings/users" component={UserManagement} />
                <Route path="/account-settings/integrations" component={Integrations} />
            </Switch>
            <ControlledToaster
                autoHideDuration={null}
                text={t('common.subscriptionExpiredMsg')}
                type="error"
                open={openAlert}
                setOpen={setOpenAlert}
            />
        </PageWrapper>
    );
};

export default AccountSettingsPage;
