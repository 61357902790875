import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import { Checkbox } from '@components/Checkbox';
import { generateCompanyCardAddress } from '@helpers/companies';

const CardWrapper = styled('div')(({ isActive }) => ({
    marginBottom: 8,
    padding: '8px 16px 16px 9px',
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    ...(isActive ? { backgroundColor: '#F7FBF9' } : {}),
    '&:hover': {
        backgroundColor: '#F7FBF9',
    },
}));

const StyledCheckbox = styled(Checkbox)({
    height: 30,
    padding: 7,
    marginRight: 10,
    '& svg': {
        width: 18,
    },
});

const GoogleCompanyItem = ({ location = {}, selectedCompanies, setCompany, index }) => {
    const isActive = selectedCompanies.includes(index);

    return (
        <CardWrapper onClick={() => setCompany(index)} isActive={isActive}>
            <StyledCheckbox
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={() => setCompany(index)}
                checked={isActive}
            />
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Typography variant="subtitle1">{location.title}</Typography>
                {location.country && (
                    <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', margin: '2px 0 6px' }}>
                        {generateCompanyCardAddress(location)}
                    </Typography>
                )}
                {location.primaryPhoneNumber && (
                    <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                        {location.primaryPhoneNumber}
                    </Typography>
                )}
            </div>
        </CardWrapper>
    );
};

export default GoogleCompanyItem;
