import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { Input } from '@components/Input';
import { Typography } from '@components/Typography';
import TextEditor from '@components/TextEditor';
import { Switch } from '@components/Switch';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { useTranslation } from '@hooks/useTranslation';
import { inboxTemplatesService } from '@services';
import { alertActions, inboxTemplatesActions } from '@actions';
import { parseTipTapJSONToPlainText } from '@helpers/text-editor';
import { Modal, Content } from './styles';

export const FORM_TYPE = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    EDIT_COPY: 'EDIT_COPY',
    VIEW: 'VIEW',
    DELETE: 'DELETE',
};

const EditModal = ({ selectedTemplate, setSelectedTemplate, modal, setModal, user, page, setPage, rowsPerPage }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const InboxTemplateSchema = yup.object().shape({
        name: yup.string().required('validation.required'),
        authorName: yup.string().notRequired(),
        message: yup.string().required('validation.required'),
    });

    const resolver = useYupValidationResolver(InboxTemplateSchema);

    const {
        getValues,
        setValue,
        control,
        handleSubmit,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: { name: '', authorName: `${user.firstName} ${user.lastName}`, message: '', shared: true },
    });

    useEffect(() => {
        if (modal.type !== FORM_TYPE.NEW) {
            setValue('name', selectedTemplate.name, { shouldValidate: true, shouldDirty: false });
            setValue('authorName', selectedTemplate.authorName, { shouldValidate: true });
            setValue('message', selectedTemplate.message, { shouldValidate: true });
            setValue('shared', selectedTemplate.shared, { shouldValidate: true });
        }
    }, [selectedTemplate, modal.type, setValue]);

    const onClose = () => {
        setSelectedTemplate(null);
        setModal({ open: false, type: FORM_TYPE.NEW });
    };

    const refetchFirstPage = async () => {
        if (page === 0) {
            await dispatch(inboxTemplatesActions.get(page, rowsPerPage));
        } else {
            setPage(0); // Triggers refetch of templates
        }
    };

    const REFETCH_MAP = {
        [FORM_TYPE.EDIT]: async () => await dispatch(inboxTemplatesActions.get(page, rowsPerPage)),
        [FORM_TYPE.NEW]: () => refetchFirstPage(),
        [FORM_TYPE.EDIT_COPY]: () => refetchFirstPage(),
    };

    const RESPONSE_MSG = {
        [FORM_TYPE.EDIT]: {
            success: t('alertMessages.editSuccess'),
            error: t('alertMessages.editFail'),
        },
        [FORM_TYPE.NEW]: {
            success: t('alertMessages.createSuccess'),
            error: t('alertMessages.createFail'),
        },
        [FORM_TYPE.EDIT_COPY]: {
            success: t('alertMessages.createSuccess'),
            error: t('alertMessages.createFail'),
        },
    };

    const onSubmit = async () => {
        const { name, message, shared } = getValues();
        try {
            await inboxTemplatesService.addOrUpdateInboxTemplate({
                name,
                message: parseTipTapJSONToPlainText(message),
                ...(modal.type === FORM_TYPE.EDIT && { id: selectedTemplate.id }),
                shared,
            });
            dispatch(alertActions.success(RESPONSE_MSG[modal.type].success));
            REFETCH_MAP[modal.type]();
            setModal({ open: false, type: FORM_TYPE.NEW });
            setSelectedTemplate(null);
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(RESPONSE_MSG[modal.type].error));
            }
        }
    };

    const isView = modal.type === FORM_TYPE.VIEW;

    return (
        <Modal
            isOpen={modal.open}
            handleClose={onClose}
            title={
                modal.type === FORM_TYPE.NEW || modal.type === FORM_TYPE.EDIT_COPY
                    ? t('InboxTemplates.addNewTemplate')
                    : modal.type === FORM_TYPE.VIEW
                    ? t('InboxTemplates.viewTemplate')
                    : t('InboxTemplates.editTemplate')
            }
            onPrimaryAction={handleSubmit(onSubmit)}
            primaryActionText={t('buttons.save')}
            primaryActionDisabled={isSubmitting || !isValid || !isDirty || isView}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content>
                <Controller
                    control={control}
                    name="name"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('common.templateName')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                            disabled={isView}
                            sx={{ gridArea: 'input1' }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="message"
                    render={({ field, fieldState: { error }, ref }) => (
                        <div style={{ gridArea: 'input3' }}>
                            <Typography
                                variant="caption"
                                sx={{ marginBottom: '16px', color: 'rgba(0, 0, 0, 0.6)', display: 'inline-block' }}
                            >
                                {t('ContactsPage.messageText')}
                            </Typography>
                            <TextEditor
                                {...field}
                                ref={ref}
                                label={t('common.message', { count: 1 })}
                                limit={1000}
                                initialValue={selectedTemplate?.message || ''}
                                onChange={text =>
                                    setValue('message', text, { shouldValidate: true, shouldDirty: true })
                                }
                                editable={!isView}
                                required
                                error={error}
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="shared"
                    render={({ field, ref }) => (
                        <Switch
                            {...field}
                            ref={ref}
                            checked={getValues('shared')}
                            handleChange={event => setValue('shared', event.target.checked, { shouldDirty: true })}
                            label={t('InboxTemplates.sharedTemplate')}
                            disabled={isView}
                            style={{ gridArea: 'switch' }}
                        />
                    )}
                />
            </Content>
        </Modal>
    );
};

export default EditModal;
