import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TableContainer from '@mui/material/TableContainer';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { Table } from '@components/Table';
import { dashboardActions } from '@actions';
import { formatNumber } from '@helpers/formatNumbers';
import { useTranslation } from '@hooks/useTranslation';
import { StyledPopper, ArrowDown } from './styles';

const GetTableHeader = surveysEnabled => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.location')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('Sidebar.reviews')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.rating')}
                </Typography>
            ),
        },
        ...(surveysEnabled
            ? [
                  {
                      key: 3,
                      component: (
                          <Typography align="left" variant="subtitle2">
                              NPS
                          </Typography>
                      ),
                  },
              ]
            : []),
    ];
};

const getTableBody = (data, surveysEnabled) => {
    return data.map(stat => [
        {
            component: (
                <Typography variant="subtitle2" align="left">
                    {stat.name}
                </Typography>
            ),
        },
        {
            component: (
                <Typography variant="body2" align="left">
                    {formatNumber(stat.reviewsCount)}
                </Typography>
            ),
        },
        {
            component: (
                <Typography variant="body2" align="left">
                    {formatNumber(stat.rating)}
                </Typography>
            ),
        },
        ...(surveysEnabled
            ? [
                  {
                      component: (
                          <Typography variant="body2" align="left">
                              {formatNumber(stat.nps)}
                          </Typography>
                      ),
                  },
              ]
            : []),
    ]);
};

export const SORT_TYPE = { REVIEWS: 0, RATING: 1, NPS: 2 };
export const LEADERBOARD_TYPE = { TOP: 0, BOTTOM: 1 };

const Leaderboard = ({ surveysEnabled }) => {
    const { t } = useTranslation();
    const [type, setType] = useState(LEADERBOARD_TYPE.TOP);
    const [sort, setSort] = useState(SORT_TYPE.REVIEWS);
    const [anchorElSort, setAnchorElSort] = useState(null);
    const [anchorElType, setAnchorElType] = useState(null);

    const { leaderboard } = useSelector(state => state.dashboard);

    const dispatch = useDispatch();

    const handleOpenSort = event => setAnchorElSort(event.currentTarget);
    const handleCloseSort = () => setAnchorElSort(null);
    const handleOpenType = event => setAnchorElType(event.currentTarget);
    const handleCloseType = () => setAnchorElType(null);

    const onChangeSorting = sorting => {
        setSort(sorting);
        handleCloseSort();
        dispatch(dashboardActions.getLeaderboardAnalytics(sorting, type));
    };

    const onChangeType = type => {
        setType(type);
        handleCloseType();
        dispatch(dashboardActions.getLeaderboardAnalytics(sort, type));
    };

    const TYPE_MAP = {
        [SORT_TYPE.REVIEWS]: t('common.top'),
        [SORT_TYPE.RATING]: t('common.bottom'),
    };
    const TYPE_LIST = [
        { id: [LEADERBOARD_TYPE.TOP], name: t('common.top') },
        { id: [LEADERBOARD_TYPE.BOTTOM], name: t('common.bottom') },
    ];

    const SORT_MAP = {
        [SORT_TYPE.REVIEWS]: t('HomePage.reviews'),
        [SORT_TYPE.RATING]: t('HomePage.rating'),
        ...(surveysEnabled && { [SORT_TYPE.NPS]: t('HomePage.nps') }),
    };
    const SORT_LIST = [
        { id: [SORT_TYPE.REVIEWS], name: t('HomePage.reviews') },
        { id: [SORT_TYPE.RATING], name: t('HomePage.rating') },
        ...(surveysEnabled ? [{ id: [SORT_TYPE.NPS], name: t('HomePage.nps') }] : []),
    ];

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 16 }}>
                <Button
                    variant="text"
                    sx={{
                        fontSize: '0.875rem',
                        lineHeight: '16px',
                        letterSpacing: '0.1px',
                        height: '24px',
                        padding: '4px',
                        minWidth: 'initial',
                    }}
                    onClick={handleOpenType}
                >
                    {TYPE_MAP[type]}
                    {anchorElType ? <ArrowDown isUp={!!anchorElType} /> : <ArrowDown />}
                </Button>
                <Typography variant="body2">{t('HomePage.5LocationsBy')}</Typography>
                <Button
                    variant="text"
                    sx={{
                        fontSize: '0.875rem',
                        lineHeight: '16px',
                        letterSpacing: '0.1px',
                        height: '24px',
                        padding: '4px',
                        minWidth: 'initial',
                    }}
                    onClick={handleOpenSort}
                >
                    {SORT_MAP[sort]}
                    {anchorElSort ? <ArrowDown isUp={!!anchorElSort} /> : <ArrowDown />}
                </Button>
            </div>
            <TableContainer>
                <Table
                    withHover
                    columnNames={GetTableHeader(surveysEnabled)}
                    rows={getTableBody(leaderboard, surveysEnabled)}
                    isDataLoading={false}
                />
            </TableContainer>
            {anchorElSort && (
                <ClickAwayListener onClickAway={handleCloseSort}>
                    <StyledPopper
                        anchorEl={anchorElSort}
                        open={!!anchorElSort}
                        disablePortal={true}
                        popperOptions={{ placement: 'bottom' }}
                        sx={{ borderRadius: '8px' }}
                    >
                        {SORT_LIST.map(item => (
                            <MenuItem key={item.id} onClick={() => onChangeSorting(item.id)}>
                                <Typography variant="body2">{item.name}</Typography>
                            </MenuItem>
                        ))}
                    </StyledPopper>
                </ClickAwayListener>
            )}
            {anchorElType && (
                <ClickAwayListener onClickAway={handleCloseType}>
                    <StyledPopper
                        anchorEl={anchorElType}
                        open={!!anchorElType}
                        disablePortal={true}
                        popperOptions={{ placement: 'bottom' }}
                        sx={{ borderRadius: '8px' }}
                    >
                        {TYPE_LIST.map(item => (
                            <MenuItem key={item.id} onClick={() => onChangeType(item.id)}>
                                <Typography variant="body2">{item.name}</Typography>
                            </MenuItem>
                        ))}
                    </StyledPopper>
                </ClickAwayListener>
            )}
        </div>
    );
};

export default Leaderboard;
