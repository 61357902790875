export const googleConstants = {
    GOOGLE_LOCATIONS_SUCCESS: 'GOOGLE_LOCATIONS_SUCCESS',
    GOOGLE_LOCATIONS_ERROR: 'GOOGLE_LOCATIONS_ERROR',
    GOOGLE_LOCATIONS_REQUEST: 'GOOGLE_LOCATIONS_REQUEST',

    GOOGLE_LISTING_PROFILE_SUCCESS: 'GOOGLE_LISTING_PROFILE_SUCCESS',
    GOOGLE_LISTING_PROFILE_ERROR: 'GOOGLE_LISTING_PROFILE_ERROR',
    GOOGLE_LISTING_PROFILE_REQUEST: 'GOOGLE_LISTING_PROFILE_REQUEST',

    GOOGLE_CATEGORIES_SUCCESS: 'GOOGLE_CATEGORIES_SUCCESS',
    GOOGLE_CATEGORIES_RESET: 'GOOGLE_CATEGORIES_RESET',
};
