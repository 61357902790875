import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { Switch } from '@components/Switch';

export const KeyboardArrowDown = styled(KeyboardArrowDownIcon, {
    shouldForwardProp: prop => prop !== 'opened',
})(({ opened }) => ({
    transform: opened && 'rotate(180deg)',
}));

export const CssMenu = styled(Menu)({
    '.MuiMenu-paper': {
        minWidth: '120px',
        boxShadow:
            '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 8,
        transform: 'translateY(12px) translatex(-56px) !important',
    },
    '.MuiMenuItem-root': {
        padding: '8px 13px',
        fontSize: '16px',
        lineHeight: '24px',
        width: '120px',
        justifyContent: 'flex-start',
    },
    '.MuiMenu-list': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    '.MuiMenuItem-logout': {
        color: '#B00020',
    },
});

export const StyledSwitch = styled(Switch)({
    paddingTop: 8,
    width: '100%',
});
