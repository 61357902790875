export const LEARN_MORE = {
    GB: {
        google: 'https://pluspoint.notion.site/9e312778e83d477cb1900edd6b50482b#88cbb6d39e66494e989702e6881f2c61',
        fb: 'https://pluspoint.notion.site/9e312778e83d477cb1900edd6b50482b#88cbb6d39e66494e989702e6881f2c61',
        channels: 'https://pluspoint.notion.site/9e312778e83d477cb1900edd6b50482b#18ca4ab3d25d470a9f4b8df040cd1796',
        templates: 'https://pluspoint.notion.site/9e312778e83d477cb1900edd6b50482b#213dcb64e8314adb923e47ea885ab27c',
        autoReplies: 'https://pluspoint.notion.site/db71ad8e93fc4162b55838f8f2d7cf0d#1a2ad0e922804e00a74762011ee991fc',
        users: 'https://pluspoint.notion.site/9e312778e83d477cb1900edd6b50482b#509676b257fc4890aead1321bd14eee3',
    },
    ES: {
        google: 'https://pluspoint.notion.site/6a8b73efdc104c028ec2e5a0b0265474#1b2a270c72454e79ba2af1a02e49e5a0',
        fb: 'https://pluspoint.notion.site/6a8b73efdc104c028ec2e5a0b0265474#1b2a270c72454e79ba2af1a02e49e5a0',
        channels: 'https://pluspoint.notion.site/6a8b73efdc104c028ec2e5a0b0265474#545ff57d4c854ea8b5601a7982c4a458',
        templates: 'https://pluspoint.notion.site/6a8b73efdc104c028ec2e5a0b0265474#de4dc8ddc4dd4deaa32c4a1001e8729b',
        autoReplies: 'https://pluspoint.notion.site/1d19710e9ee846f8a7a50d215c7261f9#0bc83b35263c4fac8e4bf5011c8e9f82',
        users: 'https://pluspoint.notion.site/6a8b73efdc104c028ec2e5a0b0265474#f2091fdeacb647ef9e4dd5e4a898ccb7',
    },
    PT: {
        google: 'https://pluspoint.notion.site/0030b0361cd44f139eec247bdf7857a3#884de7951f9340a0b3e9744ab61d8281',
        fb: 'https://pluspoint.notion.site/0030b0361cd44f139eec247bdf7857a3#884de7951f9340a0b3e9744ab61d8281',
        channels: 'https://pluspoint.notion.site/0030b0361cd44f139eec247bdf7857a3#5547898224cf4054893845a58b49c318',
        templates: 'https://pluspoint.notion.site/0030b0361cd44f139eec247bdf7857a3#9241518021ff4ddb987be737e1d96810',
        autoReplies: 'https://pluspoint.notion.site/22fddd4e1f774e7bb2306bb81d575c85#e24bd010699540319343d42f6660027b',
        users: 'https://pluspoint.notion.site/0030b0361cd44f139eec247bdf7857a3#19ad675c65584d10aeac5c23cda395c7',
    },
    UA: {
        google: 'https://pluspoint.notion.site/57b1016542994ba1924b6d934485f27e#604735b87e9b467093634e8f4c31e27c',
        fb: 'https://pluspoint.notion.site/57b1016542994ba1924b6d934485f27e#604735b87e9b467093634e8f4c31e27c',
        channels: 'https://pluspoint.notion.site/57b1016542994ba1924b6d934485f27e#ba640417a38b4ee7b552869a7779a3c3',
        templates: 'https://pluspoint.notion.site/57b1016542994ba1924b6d934485f27e#94623a11c2c84411b0ce4f202fa11929',
        autoReplies: 'https://pluspoint.notion.site/ed4e191fe10b4dee89bd7df0c242daec#0cca32ec96384b06bc5d19057c43ea7c',
        users: 'https://pluspoint.notion.site/57b1016542994ba1924b6d934485f27e#e0376efe15984dfcbfb249e1d73c368f',
    },
};
