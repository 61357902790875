import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DeleteIcon } from 'assets/images/icons';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';

const Card = styled('div')({
    padding: 16,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    maxWidth: 360,
    '@media (max-width: 700px)': {
        maxWidth: 'none',
    },
});

const Footer = styled('div')({
    paddingTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 700px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& div:nth-child(1)': {
            marginBottom: 16,
        },
    },
});

const CardConversion = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    '& span:nth-child(1)': {
        marginBottom: 4,
    },
});

const CardControls = styled('div')({
    '@media (max-width: 700px)': {
        width: '100%',
    },
});

const StyledIconButton = styled(IconButton)({
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    height: 32,
    width: 32,
    padding: 2,
    '@media (max-width: 700px)': {
        width: 'calc(25% - 6px)',
    },
    '&:not(:last-child)': {
        marginRight: 6,
    },
    '& svg': {
        width: '18px',
        height: '18px',
        '& path': {
            fill: 'rgba(0, 0, 0, 0.6)',
        },
    },
});

const MicrositeCard = ({ microsite, companies, isSubmitting, onClickDelete, onClickEdit, onClickView, isUser }) => {
    const { t } = useTranslation();

    const internalName = companies?.find(company => company.companyId === microsite.companyId)?.internalName;

    return (
        <Card>
            <Typography variant="subtitle2">{microsite.name}</Typography>
            <Typography
                variant="caption"
                sx={{ color: 'rgba(0, 0, 0, 0.6)', margin: '4px 0 16px', display: 'inline-block' }}
            >
                {internalName}
            </Typography>
            <div style={{ width: '100%', height: 1, backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
            <Footer>
                <CardConversion>
                    <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                        {t('MicrositesPage.visits')}{' '}
                        <span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{microsite.visitorsCount || '0'}</span>
                    </Typography>
                </CardConversion>
                <CardControls>
                    {!isUser ? (
                        <>
                            <StyledIconButton disabled={isSubmitting} onClick={onClickEdit}>
                                <EditIcon />
                            </StyledIconButton>
                            <StyledIconButton disabled={isSubmitting} onClick={onClickDelete}>
                                <DeleteIcon />
                            </StyledIconButton>
                        </>
                    ) : (
                        <>
                            <StyledIconButton onClick={onClickView}>
                                <VisibilityIcon />
                            </StyledIconButton>
                        </>
                    )}
                </CardControls>
            </Footer>
        </Card>
    );
};

export default MicrositeCard;
