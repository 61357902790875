import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { CSVToArray } from '@helpers/csv-helper';
import { validateBulkData, removeUnchangedContacts, findDuplicate } from '@helpers/bulk-contacts-upload';
import { alertActions } from '@actions';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Table } from '@components/Table';
import TableContainer from '@mui/material/TableContainer';
import { formatCompanies } from '@helpers/companies';
import { useTranslation } from '@hooks/useTranslation';
import { Modal, FileInputContainer } from './styles';

const baseStyles = { maxWidth: '222px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' };

const ContactsColumnNames = t => {
    return [
        {
            key: 0,
            component: <Typography variant="subtitle2">{t('ContactsPage.id')}</Typography>,
        },
        {
            key: 1,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.firstName')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.lastName')}
                </Typography>
            ),
        },
        {
            key: 3,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.phoneNumber')}
                </Typography>
            ),
        },
        {
            key: 4,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.emailAddress')}
                </Typography>
            ),
        },
        {
            key: 5,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.locations')}
                </Typography>
            ),
        },
        {
            key: 6,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('ContactsPage.facebookProfile')}
                </Typography>
            ),
        },
        {
            key: 7,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('ContactsPage.instagramProfile')}
                </Typography>
            ),
        },
        {
            key: 8,
            component: <Typography align="left" variant="subtitle2"></Typography>,
        },
    ];
};

const cell = (isChanged, oldValue, newValue, resultColor) => {
    return !isChanged ? (
        <Typography color={resultColor} variant="body2" align="left" style={{ ...baseStyles }}>
            {newValue || '--'}
        </Typography>
    ) : (
        <>
            <Typography
                color={resultColor}
                variant="body2"
                align="left"
                style={{
                    ...baseStyles,
                    textDecoration: 'line-through',
                }}
            >
                {oldValue || '--'}
            </Typography>
            <Typography color={'success'} variant="body2" align="left" style={{ ...baseStyles }}>
                {newValue || '--'}
            </Typography>
        </>
    );
};

const contactRows = (model, onDeleteContact, companies) =>
    model.contacts.map((contact, index) => {
        const duplicate = findDuplicate(contact, model.duplicates);
        let phoneColor = contact.phoneValid || !contact.phoneNumber ? 'textPrimary' : 'error';
        let emailColor = contact.emailValid || !contact.email ? 'textPrimary' : 'error';
        let resultColor = 'textPrimary';
        const changed = {
            firstName: false,
            lastName: false,
            phoneNumber: false,
            email: false,
            facebookProfile: false,
            instagramProfile: false,
        };
        if (duplicate && contact.phoneValid && contact.emailValid) {
            Object.entries(changed).forEach(([key]) => {
                if (contact[key] && contact[key] !== duplicate[key]) {
                    changed[key] = true;
                }
                if (!contact[key] && duplicate[key]) {
                    contact[key] = duplicate[key];
                }
            });
        }
        const companiesText = formatCompanies(companies, model.contacts[index].companies || duplicate.companies, 20);
        const components = [];
        components.push({
            component: (
                <Typography variant="body2" align="left" sx={{ maxWidth: '56px' }}>
                    {index + 1}
                </Typography>
            ),
        });
        Object.entries(changed).forEach(([key, value]) => {
            const color = key === 'phoneNumber' ? phoneColor : key === 'email' ? emailColor : resultColor;
            components.push({
                component: cell(value, duplicate?.[key], contact[key], color),
            });
        });
        components.push({
            component: (
                <IconButton
                    color={!contact.phoneValid || !contact.emailValid ? 'error' : 'primary'}
                    onClick={() => onDeleteContact(index)}
                >
                    <DeleteIcon />
                </IconButton>
            ),
        });
        components.splice(5, 0, {
            component: (
                <Typography color={resultColor} variant="body2" align="left" style={{ ...baseStyles }}>
                    {companiesText || '--'}
                </Typography>
            ),
        });
        return components;
    });

const ImportModal = ({ isOpen, setModal, locale, companies, getDuplicates, importContactsBulk }) => {
    const { t } = useTranslation();
    const [modalState, setModalState] = useState({ isExpectingFile: true, showContactList: false });
    const [importModel, setImportModel] = useState({ contacts: [], duplicates: [] });
    const [fileName, setFileName] = useState('');

    const dispatch = useDispatch();

    const isListValid = useMemo(() => {
        return (
            importModel.contacts.length > 0 &&
            importModel.contacts.every(
                person => (person.emailValid && person.phoneValid) || (person.emailValid && !person.phoneNumber),
            )
        );
    }, [importModel.contacts]);

    const onDeleteContact = newValue => {
        const contactsCopy = [...importModel.contacts];
        contactsCopy.splice(newValue, 1);
        setImportModel(prev => ({ ...prev, contacts: contactsCopy }));
    };

    const onCloseModal = () => setModal(false);

    const submitCSV = event => {
        const file = event.target.files[0];
        setFileName(file.name);
        let fileReader = new FileReader();
        const processData = async () => {
            const data = fileReader.result;
            const list = validateBulkData(CSVToArray(data), locale, companies);
            if (!list || list.length === 0) {
                dispatch(alertActions.error(t('validation.wrongFile')));
            } else {
                const savedContacts = await getDuplicates(list);
                const response = removeUnchangedContacts(list, savedContacts);
                setImportModel(response);
                setModalState(prev => ({ ...prev, isExpectingFile: false }));
            }
        };
        fileReader.onloadend = processData;
        fileReader.readAsText(file);

        event.target.value = null;
    };

    const importContacts = () => setModalState(prev => ({ ...prev, showContactList: true }));

    const uploadContacts = async () => await importContactsBulk(importModel.contacts, importModel.duplicates);

    return (
        <>
            <Modal
                isOpen={isOpen}
                handleClose={onCloseModal}
                showContactList={modalState.showContactList}
                title={t('ContactsPage.importTitle')}
                subtitle={
                    <Typography variant="caption" color="textSecondary">
                        {t('ContactsPage.importSubtitle')}{' '}
                        <a href="/import-contact-example.csv" style={{ color: '#1F4C5C' }}>
                            {t('ContactsPage.bulkFileLink')}
                        </a>
                    </Typography>
                }
                onPrimaryAction={!modalState.showContactList ? importContacts : uploadContacts}
                primaryActionText={
                    !modalState.showContactList ? t('ContactsPage.validate') : t('ContactsPage.importFile')
                }
                primaryActionDisabled={
                    modalState.isExpectingFile ? true : modalState.showContactList ? !isListValid : false
                }
                onSecondaryAction={onCloseModal}
                secondaryActionText={t('buttons.cancel')}
            >
                {modalState.showContactList ? (
                    <>
                        <Typography
                            variant="caption"
                            sx={{ marginBottom: '24px', display: 'block', color: 'rgba(0, 0, 0, 0.6)' }}
                        >
                            {t('ContactsPage.dataVerificationDescription')}
                        </Typography>
                        <TableContainer>
                            <Table
                                withHover
                                columnNames={ContactsColumnNames(t)}
                                rows={contactRows(importModel, onDeleteContact, companies)}
                            />
                        </TableContainer>
                    </>
                ) : (
                    <>
                        <Typography variant="caption" color="textSecondary">
                            {t('ContactsPage.csvFile')}
                        </Typography>
                        <FileInputContainer>
                            <input
                                accept=".csv,.xlsx,.xls"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                                onChange={submitCSV}
                                name="contactsList"
                            />
                            <label htmlFor="raised-button-file">
                                <Button type="button" component="span" fullWidth variant="outlined">
                                    {t('buttons.uploadCSVFile')}
                                </Button>
                            </label>
                            <Typography variant="caption" color="textSecondary">
                                {fileName}
                            </Typography>
                        </FileInputContainer>
                        <Typography variant="caption" color="textSecondary">
                            {importModel.contacts?.length > 0 && importModel.duplicates?.length >= 0
                                ? t('ContactsPage.numberToImport', {
                                      diff: importModel.contacts?.length - importModel.duplicates?.length,
                                      duplicates: importModel.duplicates?.length,
                                  })
                                : t('ContactsPage.noContacts')}
                        </Typography>
                    </>
                )}
            </Modal>
        </>
    );
};

export default ImportModal;
