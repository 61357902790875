import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { useTranslation } from '@hooks/useTranslation';
import { dashboardActions, accountActions } from '@actions';
import { LeaderboardIcon } from 'assets/images/icons';
import { ReviewsIcon, SocialIcon, MessagingIcon, SurveysIcon } from 'assets/images/sidebar';
import { RecommendationsIcon, PresenceIcon, SynchronizationIcon } from 'assets/images/icons';
import Leaderboard from './Leaderboard';
import SectionWrapper from './SectionWrapper';
import Reviews from './Reviews';
import Inbox from './Inbox';
import Surveys from './Surveys';
import Social from './Social';
import SurveysDisabled from './SurveysDisabled';
import Onboarding from './Onboarding';
import Synchronization from './Synchronization';
import Presence from './Presence';
import Recommendations from './Recommendations';
import { SORT_TYPE, LEADERBOARD_TYPE } from './Leaderboard';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

const Content = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
    gridTemplateAreas: '"column1 column2" "leaderboard leaderboard"',
    gridGap: 16,
    '@media (max-width: 850px)': {
        gridTemplateColumns: '100%',
        gridTemplateAreas: '"column1" "column2" "leaderboard"',
    },
});

const HomePage = () => {
    const { t } = useTranslation();
    const account = useSelector(state => state.account?.account);
    const accountOnboarding = useSelector(state => state.account.onboarding);
    const { recommendations } = useSelector(state => state.dashboard);
    const user = useSelector(state => state.authentication.user);
    const isAdmin = useSelector(state => {
        if (!state.authentication?.user?.id) return false;
        return state.authentication?.user?.roles?.filter(u => u.role === 0)?.length > 0;
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(accountActions.getDashboardOnboarding());
        dispatch(dashboardActions.getDashboardAnalytics());
        dispatch(dashboardActions.getLeaderboardAnalytics(SORT_TYPE.REVIEWS, LEADERBOARD_TYPE.TOP));
        dispatch(dashboardActions.getRecommendations());
    }, []);

    const isUser = !isAdmin && !user?.isAccountOwner && !user?.isGlobalAdmin;
    const onboardingCompleted = Object.values(accountOnboarding).every(item => !!item);

    return (
        <Container>
            {!isUser && !onboardingCompleted ? (
                <Onboarding account={account} accountOnboarding={accountOnboarding} />
            ) : null}
            <Content>
                <div style={{ gridArea: 'column1' }}>
                    {recommendations.length > 0 ? (
                        <SectionWrapper
                            withLink={false}
                            text={t('HomePage.recommendations')}
                            icon={RecommendationsIcon}
                        >
                            <Recommendations />
                        </SectionWrapper>
                    ) : null}
                    <SectionWrapper url="/analytics/presence" text={t('HomePage.presence')} icon={PresenceIcon}>
                        <Presence />
                    </SectionWrapper>
                    <SectionWrapper
                        url="/presence/profiles"
                        text={t('HomePage.synchronization')}
                        icon={SynchronizationIcon}
                    >
                        <Synchronization />
                    </SectionWrapper>
                    <SectionWrapper withLink={false} text={t('HomePage.leaderboard')} icon={LeaderboardIcon}>
                        <Leaderboard surveysEnabled={account?.surveysEnabled} />
                    </SectionWrapper>
                </div>
                <div style={{ gridArea: 'column2' }}>
                    <SectionWrapper url="/messaging/inbox" text={t('HomePage.inbox')} icon={MessagingIcon}>
                        <Inbox />
                    </SectionWrapper>
                    <SectionWrapper url="/reviews/manage" text={t('Sidebar.reviews')} icon={ReviewsIcon}>
                        <Reviews />
                    </SectionWrapper>
                    {account?.surveysEnabled ? (
                        <SectionWrapper url="/surveys/responses" text={t('HomePage.surveys')} icon={SurveysIcon}>
                            <Surveys />
                        </SectionWrapper>
                    ) : (
                        <SurveysDisabled />
                    )}
                    <SectionWrapper url="/social-posts" text={t('Sidebar.socialPosts')} icon={SocialIcon}>
                        <Social />
                    </SectionWrapper>
                </div>
            </Content>
        </Container>
    );
};

export default HomePage;
