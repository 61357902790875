import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@components/Typography';
import QRCode from 'react-qr-code';
import { Trans } from 'react-i18next';
import { useTranslation } from '@hooks/useTranslation';

const Container = styled('div')({
    height: 215,
    display: 'flex',
    alignItems: 'center',
    gap: 42,
    justifyContent: 'center',
    '@media (max-width: 700px)': {
        height: '100%',
        flexDirection: 'column-reverse',
        gap: 32,
    },
});

const QRWrapper = styled('div')({
    width: 120,
    height: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 700px)': {
        width: 160,
        height: 160,
    },
});

const Progress = styled(CircularProgress)({
    color: 'rgba(0, 0, 0, 0.38)',
    width: '58px !important',
    height: '58px !important',
    display: 'block',
});

const WhatsAppCheck = ({ qrWhatsApp }) => {
    const { t } = useTranslation();

    return (
        <Container>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                <Typography variant="caption">{t('SettingsChannels.whatsAppInstruction1')}</Typography>
                <Typography variant="caption">
                    <Trans t={t} i18nKey="SettingsChannels.whatsAppInstruction2">
                        <strong>{{ settings: t('SettingsChannels.settings') }}</strong>
                        <strong>{{ qrIcon: t('SettingsChannels.qrIcon') }}</strong>
                    </Trans>
                </Typography>
                <Typography variant="caption">
                    <Trans t={t} i18nKey="SettingsChannels.whatsAppInstruction3">
                        <strong>{{ scan: t('SettingsChannels.scan') }}</strong>
                    </Trans>
                </Typography>
            </div>
            <QRWrapper>
                {qrWhatsApp ? (
                    <QRCode
                        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                        value={qrWhatsApp}
                        viewBox={`0 0 256 256`}
                        fgColor="#1F4C5C"
                    />
                ) : (
                    <Progress />
                )}
            </QRWrapper>
        </Container>
    );
};

export default WhatsAppCheck;
