import { useMemo, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import sortBy from 'lodash.sortby';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@components/Button';
import { ModalBase } from '@components/Modal';
import { Chip } from '@components/Chip';
import { FiltersFormCreator } from '@components/FiltersFormCreator';
import { FiltersIcon } from 'assets/images/icons';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { updateState, getChipsLabel, getAvailableCompanies } from '@helpers/filters';
import { AccIntegrationName, LocationIntegrationName } from '@constants/integrations';
import { INCLUDE_COVER_MANAGER } from '@constants/feedbacks';
import { useTranslation } from '@hooks/useTranslation';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        width: 564,
        '@media (max-width: 1024px)': {
            width: '100%',
            height: '100%',
            borderRadius: 0,
            maxHeight: 'none',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

export const FiltersWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: '8px',
    '@media print': {
        '& button': {
            display: 'none',
        },
    },
});

const FiltersPanel = ({
    className,
    setFiltersOpened,
    filtersNumber,
    filterOpened,
    onApplyFilters,
    disabled = false,
    companies = [],
    labels = [],
    filter,
    setFilter,
    withChips = true,
    type,
    setFilterNumber,
}) => {
    const firstRender = useRef(true);
    const { t } = useTranslation();
    const account = useSelector(state => state.account?.account);
    const [filterChips, setFilterChips] = useState({
        labels: [],
        companies: [],
        surveys: [],
        rating: [],
        statuses: [],
        timeframe: {},
    });
    const [refetchFilters, setRefetchFilters] = useState(false);

    const isMobile = useMediaQuery('@media (max-width: 768px)');

    useEffect(() => {
        if (refetchFilters) {
            setRefetchFilters(false);
            onApplyFilters();
        }
    }, [refetchFilters]);

    useEffect(() => {
        if (firstRender.current && filter.companies.length > 0) {
            applyChips();
            firstRender.current = false;
        }
    }, [filter]);

    const isCoverManagerConnected = account?.integrations.find(
        i => i.type === AccIntegrationName.CoverManager,
    )?.isActive;

    const coverManagerConnectedCompanies = useMemo(
        () =>
            companies
                ?.filter(c => c.profiles.some(p => p.type === LocationIntegrationName.CoverManager))
                ?.map(i => i.companyId),
        [companies],
    );

    const options = useMemo(() => {
        //labelsOptions
        const labelsOptions = labels.map(item => ({ value: item.id, label: item.name }));

        //companyOptions
        const companyOptionsWithLabels = companies
            .filter(item => item.labels.some(l => filter.labels.includes(l)))
            .map(item => ({ value: item.companyId, label: item.internalName }));

        const companyOptionsWithoutLabels = companies
            .filter(item => item.labels?.length === 0)
            .map(item => ({ value: item.companyId, label: item.internalName }));

        const companyOptions = filter.labels.includes('none')
            ? [...companyOptionsWithLabels, ...companyOptionsWithoutLabels]
            : companyOptionsWithLabels;

        //surveysOptions
        let surveysOptions = filter.companies
            .map(selectedItem => companies.find(company => company.companyId === selectedItem))
            .filter(Boolean)
            ?.map(company => company.surveys)
            ?.flat()
            ?.map(item => ({ value: item.id, label: item.name }));

        const isCoverManagerInCompanies = filter.companies.some(item => coverManagerConnectedCompanies.includes(item));
        const newSurveysOptions =
            isCoverManagerConnected && isCoverManagerInCompanies
                ? [...surveysOptions, { value: INCLUDE_COVER_MANAGER, label: 'CoverManager' }]
                : surveysOptions;
        surveysOptions = sortBy(newSurveysOptions, ['label']);

        //statusesOptions
        const statusesOptions = [
            { value: 0, label: t('common.none') },
            { value: 1, label: t('common.actionRequired') },
            { value: 2, label: t('common.inProgress') },
            { value: 3, label: t('FeedbackResponses.completed') },
        ];

        //ratingOptions
        const ratingOptions = [
            { value: 1, label: '1' },
            { value: 2, label: '2' },
            { value: 3, label: '3' },
            { value: 4, label: '4' },
            { value: 5, label: '5' },
        ];

        return {
            labels: labelsOptions,
            companies: companyOptions,
            surveys: surveysOptions,
            statuses: statusesOptions,
            rating: ratingOptions,
        };
    }, [companies, labels, filter, coverManagerConnectedCompanies, isCoverManagerConnected]);

    useEffect(() => {
        const allFilters = { ...filter, ...filterChips };
        const filterNumber = Object.keys(allFilters).reduce((total, key) => {
            let increment = 0;

            switch (key) {
                case 'timeframe':
                    increment = filterChips[key]?.start ? 1 : 0;
                    break;
                case 'hideAnonimous':
                    increment = filter[key] === false ? 1 : 0;
                    break;
                default:
                    increment = filterChips[key]?.length > 0 ? 1 : 0;
                    break;
            }

            return total + increment;
        }, 0);

        setFilterNumber(filterNumber);
    }, [filterChips, filter]);

    const updateCompanySensitiveData = newCompaniesIds => {
        const availableSurveys = newCompaniesIds
            .map(selectedItem => companies.find(company => company.companyId === selectedItem))
            .map(company => company.surveys)
            ?.flat()
            ?.map(item => item.id);

        const isCoverManagerInCompanies = newCompaniesIds.some(item => coverManagerConnectedCompanies.includes(item));
        const surveys =
            isCoverManagerConnected && isCoverManagerInCompanies
                ? [...availableSurveys, INCLUDE_COVER_MANAGER]
                : availableSurveys;

        return { surveys };
    };

    const update = {
        labels: (value, isEvent = true) => {
            const selectedValues = isEvent ? value.target.value : value;

            const isAllCompaniesSelected = filter.companies.length === options.companies.length;
            const isAllSurveysSelected = filter.surveys.length === options.surveys.length;

            const selectedLabels = getMultiselectSelectedOptions(options.labels, selectedValues);
            const availableCompanies = getAvailableCompanies(companies, selectedLabels);
            const { surveys } = updateCompanySensitiveData(availableCompanies);

            setFilter(prev => ({
                ...prev,
                labels: selectedLabels,
                companies: updateState(prev?.companies, availableCompanies, isAllCompaniesSelected),
                surveys: updateState(prev?.surveys, surveys, isAllSurveysSelected && isAllCompaniesSelected),
            }));
        },
        companies: (value, isEvent) => {
            const selectedValues = isEvent ? value.target.value : value;

            const isAllSurveysSelected = filter.surveys.length === options.surveys.length;
            const newCompanies = getMultiselectSelectedOptions(options.companies, selectedValues);
            const { surveys } = updateCompanySensitiveData(newCompanies);

            setFilter(prev => ({
                ...prev,
                companies: newCompanies,
                surveys: updateState(prev?.surveys, surveys, isAllSurveysSelected),
            }));
        },
        surveys: e =>
            setFilter(prev => ({
                ...prev,
                surveys: getMultiselectSelectedOptions(options.surveys, e.target.value),
            })),
        rating: e =>
            setFilter(prev => ({ ...prev, rating: getMultiselectSelectedOptions(options.rating, e.target.value) })),
        statuses: e =>
            setFilter(prev => ({ ...prev, statuses: getMultiselectSelectedOptions(options.statuses, e.target.value) })),
        timeframe: data => {
            setFilter(prev => ({ ...prev, timeframe: { start: data[0], end: data[1] } }));
        },
        hideAnonimous: e => setFilter(prev => ({ ...prev, hideAnonimous: e.target.checked })),
    };

    const CHIPS_ACTIONS = {
        labels: {
            onDelete: () => {
                const allLabels = labels?.map(item => item.id);
                update.labels(allLabels, false);
                setFilterChips(prev => ({ ...prev, labels: [] }));
            },
            value: getChipsLabel(filterChips?.labels),
        },
        companies: {
            onDelete: () => {
                const selectedCompanies = getAvailableCompanies(companies, filter?.labels);
                update.companies(selectedCompanies, false);
                setFilterChips(prev => ({ ...prev, companies: [] }));
            },
            value: getChipsLabel(filterChips?.companies),
        },
        surveys: {
            onDelete: () => {
                setFilter(prev => ({
                    ...prev,
                    surveys: updateCompanySensitiveData(filter?.companies).surveys,
                }));
                setFilterChips(prev => ({ ...prev, surveys: [] }));
            },
            value: getChipsLabel(filterChips?.surveys),
        },
        rating: {
            onDelete: () => {
                setFilter(prev => ({ ...prev, rating: [1, 2, 3, 4, 5] }));
                setFilterChips(prev => ({ ...prev, rating: [] }));
            },
            value: getChipsLabel(filterChips?.rating),
        },
        statuses: {
            onDelete: () => {
                setFilter(prev => ({ ...prev, statuses: [0, 1, 2, 3] }));
                setFilterChips(prev => ({ ...prev, statuses: [] }));
            },
            value: getChipsLabel(filterChips?.statuses),
        },
        timeframe: filterChips?.timeframe?.start
            ? {
                  onDelete: () => {
                      setFilter(prev => ({ ...prev, timeframe: { start: null, end: null } }));
                      setFilterChips(prev => ({ ...prev, timeframe: { start: null, end: null } }));
                  },
                  value: `${format(new Date(filterChips.timeframe.start), 'dd.MM.yyyy')} - ${format(
                      new Date(filterChips.timeframe.end),
                      'dd.MM.yyyy',
                  )}`,
              }
            : { onDelete: () => {}, value: '' },
    };

    const applyChips = () => {
        let updatedChips = { ...filterChips };

        Object.keys(updatedChips).forEach(key => {
            if (key !== 'timeframe') {
                updatedChips[key] =
                    filter?.[key]?.length < options?.[key]?.length
                        ? options?.[key]?.filter(i => filter?.[key]?.includes(i.value))
                        : [];
            }
        });

        updatedChips['timeframe'] = filter.timeframe;

        setFilterChips(updatedChips);
    };

    const createFormOptions = (filter, update, options) => {
        const formOptions = [];

        for (let key in filter) {
            if (key === 'reviewId') continue;

            if (Array.isArray(filter[key])) {
                formOptions.push({
                    type: 'multiSelect',
                    label: t(`filters.${key}`),
                    value: filter[key],
                    onChange: update[key],
                    options: options[key],
                    countable: true,
                    error: !filter[key].length && key !== 'surveys', // FIXME: hotfix for surveys
                });
            } else if (key === 'hideAnonimous') {
                formOptions.push({
                    type: 'switch',
                    label: t(`filters.${key}`),
                    value: filter[key],
                    onChange: update[key],
                });
            } else if (key === 'timeframe') {
                formOptions.push({
                    type: 'timeframe',
                    label: t(`filters.${key}`),
                    value: [filter[key].start, filter[key].end],
                    onChange: update[key],
                });
            }
        }

        return formOptions;
    };

    const isValid = Object.keys(filter).every(key => {
        if (Array.isArray(filter[key]) && key !== 'surveys') {
            // FIXME: hotfix for surveys
            return !!filter[key].length;
        } else {
            return true;
        }
    });

    return (
        <FiltersWrapper>
            <Button
                className={className}
                variant="outlined"
                onClick={() => setFiltersOpened(true)}
                startIcon={<FiltersIcon />}
                disabled={disabled}
                sx={{ marginRight: '8px' }}
            >
                {t('buttons.filters')}
                {filtersNumber > 0 ? ` (${filtersNumber})` : ''}
            </Button>
            {withChips &&
                !isMobile &&
                Object.keys(filterChips).map(
                    key =>
                        (!!filterChips[key]?.length || filterChips[key]?.start) && (
                            <Chip
                                key={key}
                                label={CHIPS_ACTIONS[key].value}
                                onDelete={() => {
                                    CHIPS_ACTIONS[key].onDelete();
                                    setRefetchFilters(true);
                                }}
                            />
                        ),
                )}
            <Modal
                isOpen={filterOpened}
                handleClose={() => setFiltersOpened(false)}
                title={t('buttons.filters')}
                onPrimaryAction={() => {
                    applyChips();
                    setRefetchFilters(true);
                    setFiltersOpened(false);
                }}
                primaryActionDisabled={!isValid}
                primaryActionText={t('buttons.applyFilters')}
                onSecondaryAction={() => setFiltersOpened(false)}
                secondaryActionText={t('buttons.cancel')}
            >
                <FiltersFormCreator type={type} options={createFormOptions(filter, update, options)} />
            </Modal>
        </FiltersWrapper>
    );
};

export default FiltersPanel;
