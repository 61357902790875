import { memo, useState } from 'react';
import { styled } from '@mui/system';
import { ReviewsNotSelected, ReviewsNotExist } from '@features/ReviewsEmptyFeed';
import InfiniteScroll from 'react-infinite-scroll-component';
import TicketForm from '../TicketForm';
import FeedbackItem from './FeedbackItem';
import InitLiveChat from './InitLiveChat';
import { FeedbackItemSkeleton, FeedbacksSkeleton } from './FeedbacksSkeleton';

const FeedbacksContainer = styled('div')({
    height: 'calc(var(--100vh) - 168px)',
    backgroundColor: '#F2F2F2',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    overflowY: 'auto',
    borderRadius: 4,
    '@media (max-width: 1024px)': {
        height: 'calc(var(--100vh) - 172px)',
        margin: '0 -16px',
    },
});

const Feedbacks = memo(({ data, language, hasMore, fetchData, loading, feedType, onClickContactDetails }) => {
    const [ticketData, setTicketData] = useState(null);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [modalLiveChatOpen, setModalLiveChatOpen] = useState(false);

    const handleCloseTicket = () => setTicketData(null);

    const handleOpenTicket = ({ id, ticket, type, companyId }) => setTicketData({ id, ticket, type, companyId });

    const handleOpenLiveChat = item => {
        setModalLiveChatOpen(true);
        setSelectedFeedback(item);
    };

    const getEmptyComponent = () => {
        switch (feedType) {
            case 1:
                return <ReviewsNotSelected type="feedback" />;
            case 3:
                return <ReviewsNotExist />;
            default:
                return null;
        }
    };

    if (loading) {
        return (
            <FeedbacksContainer id="pl-feed-feedbacks">
                <FeedbacksSkeleton data={data} />
            </FeedbacksContainer>
        );
    }

    if (feedType) {
        return (
            <FeedbacksContainer style={{ padding: '16px' }} id="pl-feed-feedbacks">
                {getEmptyComponent()}
            </FeedbacksContainer>
        );
    }

    return (
        <FeedbacksContainer id="pl-feed-feedbacks">
            <InfiniteScroll
                dataLength={data.length}
                next={fetchData}
                hasMore={hasMore}
                loader={<FeedbackItemSkeleton data={data} />}
                style={{ padding: '16px', overflow: 'hidden' }}
                scrollableTarget="pl-feed-feedbacks"
            >
                {data.map((item, index) => (
                    <FeedbackItem
                        key={item?.id || `feedback-${index}`}
                        item={item}
                        index={index}
                        language={language}
                        handleOpenTicket={handleOpenTicket}
                        handleOpenLiveChat={handleOpenLiveChat}
                        onClickContactDetails={onClickContactDetails}
                    />
                ))}
            </InfiniteScroll>
            <TicketForm ticketData={ticketData} onClose={handleCloseTicket} />
            {modalLiveChatOpen && (
                <InitLiveChat
                    data={selectedFeedback}
                    open={modalLiveChatOpen}
                    onCloseModal={() => setModalLiveChatOpen(false)}
                />
            )}
        </FeedbacksContainer>
    );
});

Feedbacks.displayName = 'Feedbacks';

export default Feedbacks;
