import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import GoogleMap from '@components/GoogleMap';
import { GoogleIcon } from 'assets/images/integrations';
import { accountActions, alertActions, userActions } from '@actions';
import { companyService } from '@services';
import GTMHelper from '@helpers/gtm';
import LocationAutocomplete from '@features/LocationAutocomplete';
import { useTranslation } from '@hooks/useTranslation';
import { Footer, Header, GoogleContentWrapper } from './styles';

const ViewManual = ({ onImportFromGoogleClick, user }) => {
    const { t } = useTranslation();
    const [placeId, setPlaceId] = useState('');
    const [mapInited, setMapInited] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const mapRef = useRef();

    const dispatch = useDispatch();

    const onClearPlace = () => setPlaceId('');

    const handleSelect = option => {
        const { place_id: placeId } = option;
        if (placeId) {
            setPlaceId(placeId);
        }
    };

    const onComplete = async () => {
        setIsSubmitting(true);
        try {
            await companyService.addCompany(placeId);
            const updatedAcc = await dispatch(accountActions.get());
            await dispatch(
                accountActions.updateAccount({
                    ...updatedAcc,
                    onboardingCompleted: true,
                    onboardingStatus: 5,
                }),
            );
            GTMHelper.push('onboarding-completed');
            await dispatch(userActions.getUser(user?.id));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
        setIsSubmitting(false);
    };

    return (
        <>
            <Header>
                <Typography variant="h6">{t('OnboardingPage.titleManual')}</Typography>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '4px' }}>
                    {t('OnboardingPage.subtitleManual')}
                </Typography>
            </Header>
            <div style={{ gridArea: 'main', overflowY: 'auto' }}>
                <GoogleContentWrapper>
                    {mapInited && (
                        <LocationAutocomplete
                            label={t('OnboardingPage.googleAutocomplete')}
                            onSelectOption={handleSelect}
                            autocompleteRequestOptions={{
                                fields: ['address_components', 'geometry', 'icon', 'name'],
                                types: ['establishment'],
                                strictBounds: true,
                            }}
                            onClearPlace={onClearPlace}
                        />
                    )}
                    <GoogleMap
                        id="locations-map"
                        options={{ center: { lat: -33.8688, lng: 151.2195 }, zoom: 17 }}
                        onMapLoad={map => {
                            mapRef.current = map;
                            setMapInited(true);
                        }}
                        style={{ display: 'none' }}
                    />
                </GoogleContentWrapper>
            </div>
            <Footer isSpaceBetween>
                <Button
                    type="submit"
                    variant="outlined"
                    onClick={onImportFromGoogleClick}
                    disabled={isSubmitting}
                    startIcon={<GoogleIcon />}
                >
                    {t('buttons.importFromGoogle')}
                </Button>
                <Button type="submit" variant="contained" onClick={onComplete} disabled={!placeId || isSubmitting}>
                    {t('buttons.complete')}
                </Button>
            </Footer>
        </>
    );
};

export default ViewManual;
