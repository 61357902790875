import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { Typography } from '@components/Typography';
import ImageUploaderSingle from '@components/ImageUploaderSingle';
import { useTranslation } from '@hooks/useTranslation';
import { companyService } from '@services';
import { KB, MB } from '@constants/file';

const Appearance = ({ companyId }) => {
    const { setValue, watch } = useFormContext();
    const { t } = useTranslation();
    const [uploading, setUploading] = useState({ logoUrl: false, coverUrl: false });

    const logoUrl = watch('logoUrl');
    const coverUrl = watch('coverUrl');

    const uploadLogo = e => async field => {
        try {
            setUploading(prev => ({ ...prev, [field]: true }));
            const data = new FormData();
            data.append('file', e.target.files[0]);
            data.append('fileName', e.target.files[0].name);
            const response = await companyService.uploadCompanyImage(
                data,
                companyId,
                field === 'logoUrl' ? 'logo' : 'cover',
            );
            setValue(field, response.publicUrl, { shouldDirty: true });
        } catch (_) {
            console.error(`Couldn't upload ${field}`);
        }
        setUploading(prev => ({ ...prev, [field]: false }));
        e.target.value = '';
    };

    const onDeleteLogo = field => setValue(field, null, { shouldDirty: true });

    return (
        <>
            <Typography variant="body2">{t('common.logo')}</Typography>
            <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', display: 'block', margin: '4px 0 8px' }}>
                {t('SettingsCompanies.format250x250')}
            </Typography>
            <ImageUploaderSingle
                maxSize={KB * 500}
                extensions=".png, .jpg, .jpeg"
                dimension={[250, 250]}
                logoUrl={logoUrl}
                disabled={uploading.logoUrl}
                onDelete={() => onDeleteLogo('logoUrl')}
                uploadLogo={e => uploadLogo(e)('logoUrl')}
                isUploading={uploading.logoUrl}
            />
            <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.87)', marginTop: '8px' }}>
                {t('SettingsCompanies.coverPhoto')}
            </Typography>
            <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', display: 'block', margin: '4px 0 8px' }}>
                {t('SettingsCompanies.format1100x374')}
            </Typography>
            <ImageUploaderSingle
                maxSize={5 * MB}
                minSize={10 * KB}
                extensions=".png, .jpg, .jpeg"
                dimension={[1110, 374]}
                logoUrl={coverUrl}
                disabled={uploading.coverUrl}
                onDelete={() => onDeleteLogo('coverUrl')}
                uploadLogo={e => uploadLogo(e)('coverUrl')}
                isUploading={uploading.coverUrl}
            />
        </>
    );
};

export default Appearance;
