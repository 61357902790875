import { useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import { useTranslation } from '@hooks/useTranslation';
import DoughnutChart from '@components/Charts/DoughnutChart';
import { formatNumber } from '@helpers/formatNumbers';

const createChartData = (data, hasData, t) => {
    const EMPTY_SPACE = '                                             ';
    const backgroundColors = ['#F2F2F2', '#FF5853', '#FFCE4D', '#A6D364', '#4CC474'];
    const borderColors = ['#F2F2F2', '#FF5853', '#FFCE4D', '#A6D364', '#4CC474'];
    const dataMap = [
        'naCount',
        'actionRequiredCount',
        'inProgressCount',
        'syncedWithConflictsCount',
        'syncedCount',
    ].map(prop => ({
        label: `${t('common.' + prop.slice(0, -5))} - ${formatNumber(data[prop])} ${EMPTY_SPACE}`,
        value: data[prop],
    }));

    return {
        labels: dataMap.map(d => d.label),
        datasets: [
            {
                label: '',
                data: !hasData ? [1] : dataMap.map(d => d.value),
                backgroundColor: !hasData ? ['#F2F2F2', ...backgroundColors] : backgroundColors,
                borderColor: !hasData ? ['#F2F2F2', ...borderColors] : borderColors,
                borderWidth: 1,
            },
        ],
    };
};

const StatusChart = memo(() => {
    const { t } = useTranslation();
    const {
        loading,
        totalCount,
        actionRequiredCount,
        inProgressCount,
        syncedCount,
        naCount,
        syncedWithConflictsCount,
    } = useSelector(state => state.profiles);
    const isMobile = useMediaQuery('@media (max-width: 600px)');

    const data = {
        actionRequiredCount,
        inProgressCount,
        syncedCount,
        naCount,
        syncedWithConflictsCount,
    };

    const hasData = useMemo(
        () =>
            !!data?.actionRequiredCount ||
            !!data?.inProgressCount ||
            !!data?.syncedCount ||
            !!data?.naCount ||
            !!data.syncedWithConflictsCount,
        [data],
    );
    const datasets = useMemo(() => createChartData(data, hasData, t), [data, hasData]);

    return (
        <Paper
            elevation={1}
            sx={{
                padding: '0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '4px',
                breakInside: 'avoid',
                boxShadow: 'none',
            }}
        >
            <div
                style={{
                    position: 'relative',
                    height: '130px',
                    width: '130px',
                    marginBottom: isMobile ? '16px' : 0,
                }}
            >
                {!loading ? (
                    <DoughnutChart
                        datasets={datasets}
                        hasData={hasData}
                        optionsProps={{
                            cutout: '75%',
                            plugins: {
                                legend: {
                                    display: false,
                                },
                                tooltip: {
                                    xAlign: 'center',
                                    bodyFont: {
                                        size: 10,
                                    },
                                },
                            },
                        }}
                        overall={{
                            title: t('ProfilesPage.profiles'),
                            data: totalCount?.toLocaleString('en-US'),
                        }}
                        textCenterProps={{
                            fontTitle: 'bold 14px Lora, serif',
                            fontData: '48px Lora, serif',
                            yTitleOffset: 20,
                            yDataOffset: 15,
                        }}
                    />
                ) : null}
            </div>
        </Paper>
    );
});

StatusChart.displayName = 'StatusChart';

export default StatusChart;
