import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import GTMHelper from '@helpers/gtm';
import { userActions } from '@actions';
import { userService } from '@services';

const VerifyEmail = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();

    const verifyEmail = async () => {
        try {
            const user = await userService.verifyEmail(params.token);
            GTMHelper.update(user);
            dispatch(userActions.setUser(user));
            history.push('/');
        } catch (_) {
            history.push('/login');
        }
    };

    useEffect(() => {
        verifyEmail();
    }, []);

    return <div />;
};

export default VerifyEmail;
