const facebookProfileUrl = 'https://www.facebook.com/profile.php';

export function validateUrl(url, type) {
    const validUrls = {
        2: ['facebook.'],
        1: ['yelp.'],
        8: ['trustpilot'],
        9: ['tripadvisor'],
        12: ['instagram.com/'],
        16: ['booking'],
        24: ['youtube'],
        25: ['x.com', 'twitter'],
        26: ['linkedin'],
        27: ['pinterest'],
        28: ['tiktok'],
    };
    const r = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValidTypeUrl = () => {
        let isValid = false;
        validUrls[type].forEach(subUrl => {
            if (url.includes(subUrl)) {
                isValid = true;
            }
        });

        // Facebook profile url without id is not valid
        if (type === 2 && url.startsWith(facebookProfileUrl) && !url.includes('id=')) {
            isValid = false;
        }
        return isValid;
    };
    return r.test(url) && isValidTypeUrl();
}
