import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { billingService } from '@services/billing';
import BillingDrawerCustom from '@features/BillingDrawerCustom';
import { alertActions, pricingDrawerUiActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';
import { getExpiredAtInfo } from '@helpers/dates';
import { BILLING_TYPE_MAP } from '@constants/billing';
import { PRICING_DRAWER_TYPE } from '@constants/pricing-drawer-ui';
import { getI18nForm } from '@helpers/i18n';

const Container = styled('div')({
    padding: 16,
    borderRadius: 4,
    maxWidth: 698,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
});

const TextDivider = styled('i')({
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    width: 1,
    height: 14,
    margin: '0 8px',
    display: 'inline-block',
});

const Billing = ({ account }) => {
    const { t } = useTranslation();
    const [customerPortalIsLoading, setCustomerPortalIsLoading] = useState(false);

    const { customIsOpen } = useSelector(state => state.pricingDrawerUi);
    const language = useSelector(state => state.authentication.user?.language);
    const dispatch = useDispatch();

    const subscription = account?.subscriptions[0];
    const isMultipleStripeAcc = account?.subscriptions.length > 1;
    const isExpiredDate = subscription?.billingDiffInDays < 0;

    const MAX_COMPANIES_NUMBER = account?.companiesMaxCount;
    const MAX_USERS_NUMBER = account?.usersMaxCount;
    const CURRENT_COMPANIES_NUMBER = account?.companies?.length;
    const CURRENT_USERS_NUMBER = account?.users?.length;
    const BILLING_PLAN = subscription?.billingPlanName;

    const { expiredAt } = getExpiredAtInfo(subscription.expiresAt, language);

    const getButtonHandler = () => {
        if (isMultipleStripeAcc) {
            return {
                i18nButton1: t('buttons.managePlan'),
                i18nButton2: null,
                buttonHandler1: () => dispatch(pricingDrawerUiActions.setOpenCustom(true)),
                buttonHandler2: null,
            };
        } else {
            switch (BILLING_PLAN) {
                case BILLING_TYPE_MAP.TRIAL:
                    return {
                        i18nButton1: t('buttons.upgradePlan'),
                        i18nButton2: null,
                        buttonHandler1: () =>
                            dispatch(
                                pricingDrawerUiActions.setOpen({
                                    open: true,
                                    type: PRICING_DRAWER_TYPE.UPGRADE,
                                }),
                            ),
                        buttonHandler2: null,
                    };
                case BILLING_TYPE_MAP.ENTERPRISE:
                    return {
                        i18nButton1: t('buttons.contactSales'),
                        i18nButton2: t('buttons.changePlan'),
                        buttonHandler1: null,
                        buttonHandler2: () =>
                            dispatch(
                                pricingDrawerUiActions.setOpen({
                                    open: true,
                                    type: PRICING_DRAWER_TYPE.CHANGE,
                                }),
                            ),
                    };
                default:
                    return {
                        i18nButton1: t('buttons.managePlan'),
                        i18nButton2: t('buttons.changePlan'),
                        buttonHandler1: onManagePlan,
                        buttonHandler2: () =>
                            dispatch(
                                pricingDrawerUiActions.setOpen({
                                    open: true,
                                    type: PRICING_DRAWER_TYPE.CHANGE,
                                }),
                            ),
                    };
            }
        }
    };

    const onManagePlan = async () => {
        setCustomerPortalIsLoading(true);
        try {
            const data = await billingService.createCustomerPortal({
                subscriptionId: subscription.id,
            });
            if (data.paymentUrl) {
                window.location = data.paymentUrl;
            }
        } catch (_) {
            dispatch(alertActions.error(t('apiErrors.something_wrong')));
        }
        setCustomerPortalIsLoading(false);
    };

    const { i18nButton1, i18nButton2, buttonHandler1, buttonHandler2 } = getButtonHandler();

    return (
        <>
            <Typography variant="h6" color="textPrimary" sx={{ marginBottom: '16px' }}>
                {t('SettingsGeneral.billing')}
            </Typography>
            <Container>
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                                {t('SettingsGeneral.currentPlan')}
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    color: '#1F4C5C',
                                    marginBottom: '8px',
                                    '@media (max-width: 400px)': {
                                        fontSize: '24px !important',
                                    },
                                }}
                            >
                                {!isMultipleStripeAcc ? subscription.billingPlanName : 'Custom'}
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                gap: 8,
                                flexWrap: 'wrap',
                                justifyContent: 'flex-end',
                                marginBottom: 8,
                                height: 'fit-content',
                            }}
                        >
                            {buttonHandler1 && (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    disabled={customerPortalIsLoading}
                                    sx={{
                                        alignSelf: 'flex-start',
                                        maxWidth: '180px',
                                        height: 'auto',
                                        minHeight: '36px',
                                    }}
                                    onClick={buttonHandler1}
                                >
                                    {i18nButton1}
                                </Button>
                            )}
                            {BILLING_PLAN !== BILLING_TYPE_MAP.TRIAL && !isMultipleStripeAcc && (
                                <Button
                                    type="button"
                                    variant="contained"
                                    disabled={customerPortalIsLoading}
                                    sx={{
                                        alignSelf: 'flex-start',
                                        maxWidth: '180px',
                                        height: 'auto',
                                        minHeight: '36px',
                                    }}
                                    onClick={buttonHandler2}
                                >
                                    {i18nButton2}
                                </Button>
                            )}
                        </div>
                    </div>
                    <Typography
                        variant="caption"
                        sx={{ display: 'flex', flexWrap: 'wrap', color: 'rgba(0, 0, 0, 0.87)' }}
                    >
                        <span style={{ display: 'flex', marginBottom: 4, textTransform: 'lowercase' }}>
                            {`${CURRENT_COMPANIES_NUMBER}${
                                MAX_COMPANIES_NUMBER !== Infinity ? `/${MAX_COMPANIES_NUMBER}` : ''
                            } ${t('common.locations')}`}
                            <TextDivider />
                        </span>
                        <span style={{ display: 'flex', marginBottom: 4, textTransform: 'lowercase' }}>
                            {`${CURRENT_USERS_NUMBER}${
                                MAX_USERS_NUMBER !== Infinity ? `/${MAX_USERS_NUMBER}` : ''
                            } ${t(`common.users`)}`}
                            {!isMultipleStripeAcc && <TextDivider />}
                        </span>
                        {!isMultipleStripeAcc && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: 4,
                                    flexWrap: 'wrap',
                                }}
                            >
                                {BILLING_PLAN === BILLING_TYPE_MAP.TRIAL ? (
                                    <>
                                        <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                                            {`${subscription.billingPlanName} ${
                                                isExpiredDate
                                                    ? t('SettingsGeneral.endedOn')
                                                    : t('SettingsGeneral.endsOn')
                                            }`}
                                            &nbsp;
                                        </span>
                                        <span style={{ color: isExpiredDate && '#B00020' }}>
                                            {expiredAt}&nbsp;
                                        </span>
                                        <span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                                            ({isExpiredDate ? 0 : subscription?.billingDiffInDays}&nbsp;
                                            {t(
                                                `SettingsGeneral.${getI18nForm(
                                                    subscription?.billingDiffInDays,
                                                    'dayLeft',
                                                )}`,
                                            )}
                                            )&nbsp;
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                                            {t('SettingsGeneral.nextPaymentOn')}&nbsp;
                                        </span>
                                        <span style={{ color: isExpiredDate && '#B00020' }}>
                                            {expiredAt} - {subscription?.price} {subscription?.currency}
                                            &nbsp;
                                        </span>
                                    </>
                                )}
                            </div>
                        )}
                    </Typography>
                </div>
            </Container>
            {customIsOpen && <BillingDrawerCustom />}
        </>
    );
};

export default Billing;
