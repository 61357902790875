class GTMHelper {
    update(data) {
        const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));
        localStorage.setItem(
            'user',
            JSON.stringify({
                ...userFromLocalStorage,
                ...data,
                token: data.token || userFromLocalStorage.token,
            }),
        );
        this.push('user-data-updated');
    }

    push(event) {
        window.dataLayer?.push({ event });
    }
}

export default new GTMHelper();
