import { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import format from 'date-fns/format';
import { styled } from '@mui/material/styles';
import { ControlledToaster } from '@components/Toaster';
import { useTranslation } from '@hooks/useTranslation';
import { Trans } from 'react-i18next';
import ContactInfo from '@pages/ContactsPage/ContactInfo';
import ExportCSV from '@features/ExportCSV';
import { PLUSPOINT_INTEGRATION, AccIntegrationName } from '@constants/integrations';
import { MODAL_TYPE, CONTACT_MODAL_TYPE } from '@constants/modal';
import { channelsActions, feedbacksActions, contactsActions, labelsActions } from '@actions';
import { feedbacksService } from '@services';
import { useQueryParams } from '@hooks/useQueryParams';
import FiltersPanel from './FiltersPanel';
import Feedbacks from './Feedbacks';
import { INCLUDE_COVER_MANAGER } from '@constants/feedbacks';
import { LABEL_TYPE } from '@constants/labels';
import { getLocationLabels } from '@helpers/locationLabels';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: '16px 16px 0',
    },
});

const DEFAULT_START = 0;
const DEFAULT_SKIP = 50;
const DEFAULT_LOAD_MORE = 20;
const MINUTE = 1000 * 60;

const FeedbacksPage = () => {
    const { t } = useTranslation();
    const params = useQueryParams();
    const history = useHistory();
    const { companies } = useSelector(state => state.account?.account);
    const account = useSelector(state => state.account?.account);
    const { location: labels, locationLoading: labelsLoading } = useSelector(state => state.labels);
    const { feedbacks, hasMore, loading, newFeedbacksExist } = useSelector(state => state.feedbacks);
    const language = useSelector(state => state.authentication.user?.language);

    const queryParamsFeedbackId = params.get('feedbackId');

    const [filter, setFilter] = useState({
        labels: [],
        companies: [],
        surveys: [],
        timeframe: { start: null, end: null },
        rating: params.get('rating') ? JSON.parse(params.get('rating')) : [1, 2, 3, 4, 5],
        statuses: params.get('statuses') ? JSON.parse(params.get('statuses')) : [0, 1, 2, 3],
        hideAnonimous: params.get('hideAnonimous') ? JSON.parse(params.get('hideAnonimous')) : true,
        feedbackId: queryParamsFeedbackId ? queryParamsFeedbackId : undefined,
    });
    const [openAlert, setOpenAlert] = useState(false);
    const [filterNumber, setFilterNumber] = useState(0);
    const [filterOpened, setFiltersOpened] = useState(false);
    const [contactDrawerOpened, setContactDrawerOpened] = useState(false);
    const [deleteModal, setDeleteModal] = useState({ open: false, type: CONTACT_MODAL_TYPE.DIRECT });
    const [editModal, setEditModal] = useState({ open: false, type: MODAL_TYPE.EDIT });
    const [sendMessageModal, setSendMessageModal] = useState({ open: false, type: CONTACT_MODAL_TYPE.DIRECT });

    const paginationStart = useRef(DEFAULT_START);
    const firstRender = useRef(true);

    const dispatch = useDispatch();

    const isCoverManagerConnected = account?.integrations.find(
        i => i.type === AccIntegrationName.CoverManager,
    )?.isActive;

    useEffect(() => {
        dispatch(labelsActions.get(LABEL_TYPE.CONTACT));
        dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
        dispatch(channelsActions.get());
    }, []);

    const availableLabels = useMemo(() => getLocationLabels(labels, companies, t), [labels, companies]);

    useEffect(() => {
        if (companies.length && !labelsLoading) {
            const availableSurveys = companies
                ?.map(company => company.surveys)
                ?.flat()
                ?.map(item => item.id);
            const surveys = isCoverManagerConnected ? [...availableSurveys, INCLUDE_COVER_MANAGER] : availableSurveys;
            // const availableLabels = [...new Set(companies.map(item => item.labels).flat())];
            const queryParamsFilter = [params.get('rating'), params.get('hideAnonimous'), params.get('statuses')];
            setFilterNumber(queryParamsFilter.filter(Boolean).length);
            setFilter(prev => ({
                ...prev,
                surveys,
                companies: companies.map(item => item.companyId),
                labels: availableLabels.map(item => item.id),
            }));
            if (params.get('feedbackId')) {
                history.replace();
            }
        } else {
            dispatch(feedbacksActions.resetLoading());
        }
    }, [companies, labelsLoading, dispatch]);

    const currentQuery = useMemo(() => {
        return {
            ...filter,
            surveys: isCoverManagerConnected ? filter.surveys.filter(i => i !== INCLUDE_COVER_MANAGER) : filter.surveys,
            start: DEFAULT_START,
            end: DEFAULT_SKIP,
            integrationNames: [PLUSPOINT_INTEGRATION],
            startDate: filter.timeframe.start ? format(new Date(filter.timeframe.start), 'dd/MMM/yyyy') : null,
            endDate: filter.timeframe.end ? format(new Date(filter.timeframe.end), 'dd/MMM/yyyy') : null,
            includeAnonymous: !filter.hideAnonimous,
            includeCoverManager: isCoverManagerConnected ? filter.surveys.includes(INCLUDE_COVER_MANAGER) : false,
        };
    }, [filter, isCoverManagerConnected]);

    useEffect(() => {
        if (queryParamsFeedbackId && !firstRender.current) {
            dispatch(feedbacksActions.getAll({ ...currentQuery, feedbackId: queryParamsFeedbackId }));
            history.replace();
        }
    }, [queryParamsFeedbackId]);

    useEffect(() => {
        if (currentQuery.companies.length && currentQuery.rating.length && currentQuery.statuses.length) {
            paginationStart.current = 0;
            if (firstRender.current) {
                dispatch(feedbacksActions.getAll(currentQuery));
                firstRender.current = false;
            }
        }
    }, [currentQuery, dispatch]);

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const newExists = await feedbacksService.checkNewExist(currentQuery);
                dispatch(feedbacksActions.checkNewExistSuccess(newExists));
            } catch (_) {
                console.error('Failed to check new feedbacks');
            }
        }, MINUTE);
        return () => clearInterval(interval);
    }, [currentQuery, dispatch]);

    useEffect(() => {
        if (newFeedbacksExist) {
            setOpenAlert(true);
        }
    }, [newFeedbacksExist]);

    const getNewReviews = () => {
        setOpenAlert(false);
        dispatch(feedbacksActions.resetNewExist());
        dispatch(feedbacksActions.getAll(currentQuery));
    };

    const onCloseToaster = () => dispatch(feedbacksActions.resetNewExist());

    const loadMore = () => {
        if (!hasMore) return;
        const newPaginationStart =
            paginationStart.current === 0 ? DEFAULT_SKIP : paginationStart.current + DEFAULT_LOAD_MORE;
        paginationStart.current = newPaginationStart;
        dispatch(feedbacksActions.loadMore({ ...currentQuery, start: newPaginationStart, end: DEFAULT_LOAD_MORE }));
    };

    const onApplyFilters = () => {
        paginationStart.current = 0;
        dispatch(feedbacksActions.getAll({ ...currentQuery, feedbackId: undefined }));
    };

    const surveyExists = useMemo(() => {
        return companies?.map(company => company.surveys)?.flat().length > 0;
    }, [companies]);

    const getFeedType = () => {
        if (!surveyExists) {
            return 3;
        }
        if (!filter.companies.length || !filter.rating.length || !filter.statuses.length) {
            return 1;
        }
        if (!feedbacks?.length && !firstRender.current) {
            return 3;
        }
        return 0;
    };

    const onClickContactDetails = contactId => {
        dispatch(contactsActions.getContact(contactId));
        setContactDrawerOpened(true);
    };

    const onCloseContactDrawer = () => setContactDrawerOpened(false);

    const handleContactActions = (_, type) => {
        switch (type) {
            case 'delete':
                setDeleteModal({ open: true, type: CONTACT_MODAL_TYPE.DIRECT });
                break;
            case 'edit':
                setEditModal({ open: true, type: MODAL_TYPE.EDIT });
                break;
            case 'sendMessage':
                setSendMessageModal({ open: true, type: CONTACT_MODAL_TYPE.DIRECT });
                break;
            default:
                break;
        }
    };

    const refetchCallBack = () => {
        dispatch(feedbacksActions.getAll(currentQuery));
    };

    return (
        <Container>
            <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
                <FiltersPanel
                    setFiltersOpened={setFiltersOpened}
                    filtersNumber={filterNumber}
                    filterOpened={filterOpened}
                    companies={companies}
                    labels={availableLabels}
                    filter={filter}
                    setFilter={setFilter}
                    type="feedbacks"
                    setFilterNumber={setFilterNumber}
                    onApplyFilters={onApplyFilters}
                />
                <ExportCSV
                    callback={() => feedbacksService.exportCSV({ ...currentQuery, start: 0, end: 0 })}
                    type="feedbacks"
                />
            </div>
            <Feedbacks
                data={feedbacks}
                companies={companies}
                language={language}
                loading={loading}
                hasMore={hasMore}
                fetchData={loadMore}
                feedType={getFeedType()}
                onClickContactDetails={onClickContactDetails}
            />
            <ControlledToaster
                callback={getNewReviews}
                autoHideDuration={null}
                text={
                    <Trans t={t} i18nKey={'FeedbackResponses.newReviews'}>
                        <b style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={getNewReviews}>
                            {{ update: t('FeedbackResponses.update') }}
                        </b>
                    </Trans>
                }
                type="info"
                open={openAlert}
                setOpen={setOpenAlert}
                clickClose={onCloseToaster}
            />
            <ContactInfo
                onClickContact={handleContactActions}
                editModal={editModal}
                setEditModal={setEditModal}
                sendMessageModal={sendMessageModal}
                setSendMessageModal={setSendMessageModal}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                contactDrawerOpened={contactDrawerOpened}
                onCloseContactDrawer={onCloseContactDrawer}
                withRefetchSurveys
                refetchCallBack={refetchCallBack}
            />
        </Container>
    );
};

export default FeedbacksPage;
