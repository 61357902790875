import { format } from 'date-fns';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { getFullName } from '@helpers/userName';
import { CONVERSATION_STATUS } from '@constants/conversations';

const ConversationsEvent = ({ data, users }) => {
    const { t } = useTranslation();
    const user = users.find(user => user.id === data.userId);
    return (
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 8 }}>
            <Typography
                variant="caption"
                sx={{
                    color: 'rgba(0, 0, 0, 0.38)',
                    padding: '0 8px',
                    zIndex: 2,
                    backgroundColor: '#fff',
                    textAlign: 'center',
                }}
            >
                {t(data?.status === CONVERSATION_STATUS.CLOSED ? 'InboxPage.resolvedBy' : 'InboxPage.reopenedBy', {
                    date: format(new Date(data.createdAt), 'dd/MM • HH:mm'),
                    name: getFullName(user?.firstName, user?.lastName, '-'),
                })}
            </Typography>
            <div
                style={{
                    borderTop: '1px dashed rgba(0, 0, 0, 0.12)',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: '50%',
                }}
            />
        </div>
    );
};

export default ConversationsEvent;
