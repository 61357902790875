import { memo, useMemo, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { ListingsIcon } from 'assets/images/icons';
import { LiveChatIcon } from 'assets/images/channels';
import { useTranslation } from '@hooks/useTranslation';
import Button from '@components/Button';
import { Typography } from '@components/Typography';
import { CHANNEL } from '@constants/channels';

const Container = styled('div', {
    shouldForwardProp: prop => prop !== 'hasResponse',
})(({ hasResponse }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: hasResponse ? 'space-between' : 'flex-end',
    flexWrap: 'wrap',
    '@media (max-width: 600px)': {
        flexDirection: 'column',
    },
}));

const TicketInfoWrapper = styled('div')({
    display: 'flex',
    gap: '16px',
    '@media (max-width: 600px)': {
        gap: '0',
        flexDirection: 'column',
    },
});

const TicketInfo = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1200px)': {
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
});

const TicketLabel = styled('div')({
    marginRight: '16px',
    maxWidth: '172px',
    minWidth: '75px',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1200px)': {
        maxWidth: '150px',
        minWidth: '100px',
        marginBottom: '16px',
        '&:first-of-type': {
            flex: 1,
        },
    },
});

const FeedbackToolbar = memo(({ data, onClickTicket, onClickLiveChat }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const users = useSelector(state => state.account?.account?.users || [], shallowEqual);
    const channels = useSelector(state => state.channels.data);
    const {
        id,
        ticket = null,
        liveChatStatus,
        liveChatAssigneeName,
        contactId,
        companyId,
        liveChatConversationId,
    } = data;

    const hasResponse = Boolean(ticket) || Boolean(liveChatStatus !== null);

    const handleClickTicket = useCallback(
        type => {
            onClickTicket({ id, ticket, type, companyId });
        },
        [id, ticket, companyId],
    );

    const handleClickLiveChat = () =>
        liveChatConversationId
            ? history.push(
                  `/messaging/inbox?conversationId=${liveChatConversationId}&conversationStatus=${liveChatStatus}`,
              )
            : onClickLiveChat(data);

    const assignee = users.find(user => user.id === ticket?.assigneeId) || { firstName: '', lastName: '' };
    const ticketStatusMap = {
        0: t('FeedbackToolbar.empty'),
        1: t('common.actionRequired'),
        2: t('common.inProgress'),
        3: t('FeedbackToolbar.completed'),
    };
    const liveChatStatusMap = {
        0: t('InboxPage.open'),
        1: t('InboxPage.resolved'),
    };

    const availableLiveChatChannels = useMemo(() => {
        return channels.filter(
            channel => channel.type === CHANNEL.LIVE_CHAT && channel.companies.includes(data.companyId),
        );
    }, [channels, data]);

    const isLiveChatButtonAvailable = (availableLiveChatChannels.length > 0 && contactId) || liveChatConversationId;

    return (
        <Container hasResponse={hasResponse}>
            <TicketInfoWrapper>
                {liveChatConversationId && (
                    <TicketInfo>
                        <TicketLabel>
                            <Typography
                                variant="caption"
                                sx={{ display: 'inline-block', marginBottom: '2px', color: 'rgba(0, 0, 0, 0.6)' }}
                            >
                                {t('FeedbackToolbar.liveChatAssignedTo')}
                            </Typography>
                            <Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                                {liveChatAssigneeName}
                            </Typography>
                        </TicketLabel>
                        <TicketLabel>
                            <Typography
                                variant="caption"
                                sx={{ display: 'inline-block', marginBottom: '2px', color: 'rgba(0, 0, 0, 0.6)' }}
                            >
                                {t('FeedbackToolbar.liveChatStatus')}
                            </Typography>
                            <Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                                {liveChatStatusMap[liveChatStatus]}
                            </Typography>
                        </TicketLabel>
                    </TicketInfo>
                )}
                {ticket && (
                    <TicketInfo>
                        <TicketLabel>
                            <Typography
                                variant="caption"
                                sx={{ display: 'inline-block', marginBottom: '2px', color: 'rgba(0, 0, 0, 0.6)' }}
                            >
                                {t('FeedbackToolbar.ticketAssignedTo')}
                            </Typography>
                            <Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                                {`${assignee?.firstName} ${assignee?.lastName}`}
                            </Typography>
                        </TicketLabel>
                        <TicketLabel>
                            <Typography
                                variant="caption"
                                sx={{ display: 'inline-block', marginBottom: '2px', color: 'rgba(0, 0, 0, 0.6)' }}
                            >
                                {t('FeedbackToolbar.ticketStatus')}
                            </Typography>
                            <Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                                {ticketStatusMap[ticket?.ticketStatus]}
                            </Typography>
                        </TicketLabel>
                    </TicketInfo>
                )}
            </TicketInfoWrapper>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                {isLiveChatButtonAvailable && (
                    <Button variant="outlined" onClick={handleClickLiveChat}>
                        <LiveChatIcon style={{ width: '1rem', height: '1rem', marginRight: '8px' }} />
                        {liveChatConversationId
                            ? t('FeedbackResponses.viewLiveChat')
                            : t('FeedbackResponses.newLiveChat')}
                    </Button>
                )}
                <Button
                    variant="outlined"
                    onClick={() => (ticket ? handleClickTicket('view') : handleClickTicket('create'))}
                >
                    <ListingsIcon style={{ width: '1rem', height: '1rem', marginRight: '8px' }} />
                    {ticket ? t('buttons.viewTicket') : t('buttons.newTicket')}
                </Button>
            </div>
        </Container>
    );
});

FeedbackToolbar.displayName = 'FeedbackToolbar';

export default FeedbackToolbar;
