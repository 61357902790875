import { styled } from '@mui/material/styles';
import Card from '@components/Card';
import { generateCompanyCardAddress } from '@helpers/companies';

const CardText = styled('p')({
    fontFamily: 'Rubik, sans-serif',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '12px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: 0,
});

const GoogleLocationCard = ({ location = {}, selectedLocation, setLocation, index }) => (
    <Card
        type="radio"
        selected={index === Number(selectedLocation)}
        title={location.title}
        value={selectedLocation}
        index={index}
        setValue={setLocation}
    >
        {location.country && (
            <CardText style={{ marginBottom: '8px' }}>{generateCompanyCardAddress(location)}</CardText>
        )}
        {location.primaryPhoneNumber && (
            <CardText style={{ marginBottom: '16px' }}>{location.primaryPhoneNumber}</CardText>
        )}
        <CardText style={{ color: 'rgba(0, 0, 0, 0.38)', marginBottom: 0 }}>{location.accountName}</CardText>
    </Card>
);

export default GoogleLocationCard;
