import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import sortBy from 'lodash.sortby';
import Paper from '@mui/material/Paper';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@components/Typography';
import { Table } from '@components/Table';
import { useTranslation } from '@hooks/useTranslation';
import { formatNumber, formatPercentNumber } from '@helpers/formatNumbers';

const Container = styled(Paper)({
    padding: '24px',
    display: 'flex',
    width: '100%',
    borderRadius: 4,
    marginBottom: 16,
});

const StyledTable = styled(Table)({
    '& tbody .MuiTableRow-root:last-child': {
        backgroundColor: '#EBF6F0',
    },
});

const TableCellText = styled(Typography)({
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
});

const Text = styled('span', { shouldForwardProp: prop => prop !== 'withIcon' })(({ withIcon }) => ({
    width: 'auto',
    marginLeft: '4px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginRight: '28px',
    ...(withIcon && {
        marginRight: '8px',
    }),
}));

export const GetTableHeader = isQr => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('common.location')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography variant="subtitle2" align="center">
                    {isQr ? t('AnalyticsConversion.qrScanned') : t('AnalyticsConversion.sentSurveys')}
                </Typography>
            ),
        },
        ...(!isQr
            ? [
                  {
                      key: 2,
                      component: (
                          <Typography align="center" variant="subtitle2">
                              {t('AnalyticsConversion.openedLinks')}
                          </Typography>
                      ),
                  },
              ]
            : []),
        {
            key: 3,
            component: (
                <Typography align="center" variant="subtitle2">
                    {t('AnalyticsConversion.responses')}
                </Typography>
            ),
        },
        {
            key: 4,
            component: (
                <Typography align="center" variant="subtitle2">
                    {t('AnalyticsConversion.proceededToReviewSite')}
                </Typography>
            ),
        },
        {
            key: 5,
            component: (
                <Typography align="center" variant="subtitle2">
                    {t('AnalyticsConversion.collectedReviews')}
                </Typography>
            ),
        },
    ];
};

const getTableArrow = field => {
    switch (field) {
        case 1:
            return <ArrowUpwardIcon sx={{ color: '#0B8D00', fontSize: '20px' }} />;
        case -1:
            return <ArrowDownwardIcon sx={{ color: '#B00020', fontSize: '20px' }} />;
        case 0:
            return null;
        default:
            return null;
    }
};

export const GetTableBody = (data, isQr) => {
    const { t } = useTranslation();
    return data.map(
        (
            {
                internalName,
                surveysSent,
                responsesReceived,
                responsesReceivedPercent,
                responsesReceivedChange,
                leaveReviewClicked,
                leaveReviewClickedPercent,
                leaveReviewClickedChange,
                linksClicked,
                linksClickedPercent,
                linksClickedChange,
                reviewsReceived,
                reviewsReceivedChange,
            },
            index,
        ) => [
            {
                component: (
                    <Typography variant="subtitle2" align="left">
                        {index === data.length - 1 ? t('AnalyticsConversion.overall') : internalName}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="right">
                        {!isQr ? formatNumber(surveysSent) : formatNumber(linksClicked)}
                    </Typography>
                ),
            },
            ...(!isQr
                ? [
                      {
                          component: (
                              <TableCellText variant="body2" align="right">
                                  {formatNumber(linksClicked)}
                                  <Text withIcon={linksClickedChange}>
                                      ({formatPercentNumber(linksClickedPercent)})
                                  </Text>
                                  {getTableArrow(linksClickedChange)}
                              </TableCellText>
                          ),
                      },
                  ]
                : []),
            {
                component: (
                    <TableCellText variant="body2" align="right">
                        {formatNumber(responsesReceived)}
                        <Text withIcon={responsesReceivedChange}>
                            ({formatPercentNumber(responsesReceivedPercent)})
                        </Text>
                        {getTableArrow(responsesReceivedChange)}
                    </TableCellText>
                ),
            },
            {
                component: (
                    <TableCellText variant="body2" align="right">
                        {formatNumber(leaveReviewClicked)}
                        <Text withIcon={leaveReviewClickedChange}>
                            ({formatPercentNumber(leaveReviewClickedPercent)})
                        </Text>
                        {getTableArrow(leaveReviewClickedChange)}
                    </TableCellText>
                ),
            },
            {
                component: (
                    <TableCellText variant="body2" align="right">
                        {formatNumber(reviewsReceived)}
                        <Text withIcon={reviewsReceivedChange} />
                        {getTableArrow(reviewsReceivedChange)}
                    </TableCellText>
                ),
            },
        ],
    );
};

const ConversionTable = memo(({ isLoading, data, type }) => {
    const companies = useSelector(state => state.account?.account?.companies || []);

    const dataSortedByName = useMemo(() => {
        const dataWithLocationNames = data.tableData.map(item => ({
            ...item,
            internalName: companies.find(company => company.companyId === item.id)?.internalName || '',
        }));
        return [...sortBy(dataWithLocationNames, item => item.internalName.toLowerCase()), data.overall];
    }, [data, companies]);

    const isQr = type === 'qr';

    return (
        <Container elevation={1}>
            <TableContainer>
                <StyledTable
                    columnNames={GetTableHeader(isQr)}
                    rows={GetTableBody(dataSortedByName, isQr)}
                    isDataLoading={isLoading}
                />
            </TableContainer>
        </Container>
    );
});

ConversionTable.displayName = 'ConversionTable';

export default ConversionTable;
