import { accountConstants } from '@constants/account';
import { accountService } from '@services';
import GTMHelper from '@helpers/gtm';
import { getDifferenceInDates } from '@helpers/dates';
import { userActions } from './user';
import { BILLING_TYPE_MAP } from '@constants/billing';

export const accountActions = {
    get,
    updateAccount,
    checkSubscriptionType,
    updateQuota,
    getDashboardOnboarding,
    getLocations,
};

function get() {
    return dispatch => {
        dispatch(request());
        return accountService
            .getAccount()
            .then(
                data => {
                    const mainSubscription = data.subscriptions.sort(
                        (a, b) => new Date(a.expiresAt) - new Date(b.expiresAt),
                    )[0];
                    const expiredAtDate = new Date(mainSubscription?.expiresAt);
                    // if the user is NOT on trial, we need to add 30 days to the expiredAtDate
                    if (mainSubscription?.billingPlanName !== BILLING_TYPE_MAP.TRIAL) {
                        expiredAtDate.setDate(expiredAtDate.getDate() + 30);
                    }
                    let { billingDiffInSeconds } = getDifferenceInDates(expiredAtDate);
                    if (!data.onboardingCompleted) {
                        billingDiffInSeconds = 0;
                    }

                    const subscriptions = data.subscriptions.map(subscription => {
                        const expiredAtDate = new Date(subscription.expiresAt);
                        const isTrial = subscription?.billingPlanName === BILLING_TYPE_MAP.TRIAL;
                        if (!isTrial) {
                            expiredAtDate.setDate(expiredAtDate.getDate() + 30);
                        }
                        let { billingDiffInDays } = getDifferenceInDates(expiredAtDate, isTrial ? 0 : 30);
                        if (!data.onboardingCompleted) {
                            billingDiffInDays = 0;
                        }
                        return { ...subscription, billingDiffInDays };
                    });

                    dispatch(
                        success({
                            ...data,
                            subscriptions,
                            billingDiffInSeconds,
                            // because of the way the backend works, we need to set the max count to infinity if it's -1
                            companiesMaxCount: data.companiesMaxCount === -1 ? Infinity : data.companiesMaxCount,
                            usersMaxCount: data.usersMaxCount === -1 ? Infinity : data.usersMaxCount,
                        }),
                    );
                    return data;
                },
                error => {
                    dispatch(userActions.logout());
                    dispatch(failure(error.message ? error.message : error));
                },
            )
            .catch(error => dispatch(failure(error)));
    };
}

function updateAccount(account) {
    return dispatch => {
        return accountService.updateAccount(account).then(
            data => {
                const mainSubscription = data.subscriptions.sort(
                    (a, b) => new Date(a.expiresAt) - new Date(b.expiresAt),
                )[0];
                const expiredAtDate = new Date(mainSubscription?.expiresAt);
                // if the user is NOT on trial, we need to add 30 days to the expiredAtDate
                if (mainSubscription?.billingPlanName !== BILLING_TYPE_MAP.TRIAL) {
                    expiredAtDate.setDate(expiredAtDate.getDate() + 30);
                }
                let { billingDiffInSeconds } = getDifferenceInDates(expiredAtDate);
                if (!data.onboardingCompleted) {
                    billingDiffInSeconds = 0;
                }

                const subscriptions = data.subscriptions.map(subscription => {
                    const expiredAtDate = new Date(subscription.expiresAt);
                    const isTrial = subscription?.billingPlanName === BILLING_TYPE_MAP.TRIAL;
                    if (!isTrial) {
                        expiredAtDate.setDate(expiredAtDate.getDate() + 30);
                    }
                    let { billingDiffInDays } = getDifferenceInDates(expiredAtDate, isTrial ? 0 : 30);
                    if (!data.onboardingCompleted) {
                        billingDiffInDays = 0;
                    }
                    return { ...subscription, billingDiffInDays };
                });
                GTMHelper.update({
                    accountName: data.accountName,
                    onboardingCompleted: data.onboardingCompleted,
                    onboardingStatus: data.onboardingStatus,
                });
                dispatch(
                    success({
                        ...data,
                        subscriptions,
                        billingDiffInSeconds,
                        // because of the way the backend works, we need to set the max count to infinity if it's -1
                        companiesMaxCount: data.companiesMaxCount === -1 ? Infinity : data.companiesMaxCount,
                        usersMaxCount: data.usersMaxCount === -1 ? Infinity : data.usersMaxCount,
                    }),
                );
            },
            error => {
                dispatch(userActions.logout());
                dispatch(failure(error.message ? error.message : error));
            },
        );
    };
}

function request() {
    return { type: accountConstants.ACCOUNT_REQUEST };
}

function success(account) {
    return { type: accountConstants.ACCOUNT_SUCCESS, account };
}

function failure(message) {
    return { type: accountConstants.ACCOUNT_ERROR, message };
}

function checkSubscriptionType(sessionId) {
    return () => {
        return accountService.checkSubscriptionType(sessionId).then(
            data => GTMHelper.push(`${(data.name ?? '').toLowerCase()}-subscription-started`),
            () => console.error("Couldn't check subscription type"),
        );
    };
}

function successQuota(data) {
    return { type: accountConstants.ACCOUNT_UPDATE_QUOTA, data };
}

function updateQuota() {
    return dispatch => {
        return accountService.updateQuota().then(
            data => dispatch(successQuota(data)),
            () => console.error("Couldn't update quota"),
        );
    };
}

function successDashboardOnboarding(data) {
    return { type: accountConstants.DASHBOARD_ONBOARDING_SUCCESS, data };
}

function getDashboardOnboarding() {
    return dispatch => {
        return accountService.getDashboardOnboarding().then(data => dispatch(successDashboardOnboarding(data)));
    };
}

function locationsRequest() {
    return { type: accountConstants.LOCATIONS_FETCH };
}

function locationsSuccess(data) {
    return { type: accountConstants.LOCATIONS_SUCCESS, data };
}

function locationsFailure() {
    return { type: accountConstants.LOCATIONS_ERROR };
}

function getLocations(query, page, pageSize) {
    return dispatch => {
        dispatch(locationsRequest());
        accountService.getLocations(query, page, pageSize).then(
            data => dispatch(locationsSuccess(data)),
            () => dispatch(locationsFailure()),
        );
    };
}
