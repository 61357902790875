import { LOCALE } from './locale';

export const COUNTRY_CODES = {
    [LOCALE.BRAZIL]: '+55',
    [LOCALE.INTERNATIONAL]: '',
    [LOCALE.PORTUGAL]: '+351',
    [LOCALE.SPAIN]: '+34',
    [LOCALE.UKRAINE]: '+380',
    [LOCALE.UNITED_KINGDOM]: '+44',
    [LOCALE.POLAND]: '+48',
    [LOCALE.GERMANY]: '+49',
};

export const PHONE_MASKS = {
    AF: '... ... ... ... ..',
    AL: '... ... ... ... ..',
    DZ: '... ... ... ... ..',
    AD: '... ... ... ... ..',
    AO: '... ... ... ... ..',
    AG: '... ... ... ... ..',
    AR: '(..) ........',
    AM: '.. ......',
    AW: '... ... ... ... ..',
    AU: '(..) .... ....',
    AT: '... ... ... ... ..',
    AZ: '(..) ... .. ..',
    BS: '... ... ... ... ..',
    BH: '... ... ... ... ..',
    BD: '... ... ... ... ..',
    BB: '... ... ... ... ..',
    BY: '(..) ... .. ..',
    BE: '... .. .. ..',
    BZ: '... ... ... ... ..',
    BJ: '... ... ... ... ..',
    BT: '... ... ... ... ..',
    BO: '... ... ... ... ..',
    BA: '... ... ... ... ..',
    BW: '... ... ... ... ..',
    BR: '(..) .........',
    IO: '... ... ... ... ..',
    BN: '... ... ... ... ..',
    BG: '... ... ... ... ..',
    BF: '... ... ... ... ..',
    BI: '... ... ... ... ..',
    KH: '... ... ... ... ..',
    CM: '... ... ... ... ..',
    CA: '(...) ...-....',
    CV: '... ... ... ... ..',
    BQ: '... ... ... ... ..',
    CF: '... ... ... ... ..',
    TD: '... ... ... ... ..',
    CL: '... ... ... ... ..',
    CN: '..-.........',
    CO: '... ... ....',
    KM: '... ... ... ... ..',
    CD: '... ... ... ... ..',
    CG: '... ... ... ... ..',
    CR: '....-....',
    CI: '.. .. .. ..',
    HR: '... ... ... ... ..',
    CU: '... ... ... ... ..',
    CW: '... ... ... ... ..',
    CY: '.. ......',
    CZ: '... ... ...',
    DK: '.. .. .. ..',
    DJ: '... ... ... ... ..',
    DM: '... ... ... ... ..',
    DO: '... ... ... ... ..',
    EC: '... ... ... ... ..',
    EG: '... ... ... ... ..',
    SV: '....-....',
    GQ: '... ... ... ... ..',
    ER: '... ... ... ... ..',
    EE: '.... ......',
    ET: '... ... ... ... ..',
    FJ: '... ... ... ... ..',
    FI: '.. ... .. ..',
    FR: '. .. .. .. ..',
    GF: '... ... ... ... ..',
    PF: '... ... ... ... ..',
    GA: '... ... ... ... ..',
    GM: '... ... ... ... ..',
    GE: '... ... ... ... ..',
    DE: '.... ........',
    GH: '... ... ... ... ..',
    GR: '... ... ... ... ..',
    GD: '... ... ... ... ..',
    GP: '... ... ... ... ..',
    GU: '... ... ... ... ..',
    GT: '....-....',
    GN: '... ... ... ... ..',
    GW: '... ... ... ... ..',
    GY: '... ... ... ... ..',
    HT: '....-....',
    HN: '... ... ... ... ..',
    HK: '.... ....',
    HU: '... ... ... ... ..',
    IS: '... ....',
    IN: '.....-.....',
    ID: '... ... ... ... ..',
    IR: '... ... ....',
    IQ: '... ... ... ... ..',
    IE: '.. .......',
    IL: '... ... ....',
    IT: '... .......',
    JM: '... ... ... ... ..',
    JP: '.. .... ....',
    JO: '... ... ... ... ..',
    KZ: '... ...-..-..',
    KE: '... ... ... ... ..',
    KI: '... ... ... ... ..',
    XK: '... ... ... ... ..',
    KW: '... ... ... ... ..',
    KG: '... ... ...',
    LA: '... ... ... ... ..',
    LV: '.. ... ...',
    LB: '... ... ... ... ..',
    LS: '... ... ... ... ..',
    LR: '... ... ... ... ..',
    LY: '... ... ... ... ..',
    LI: '... ... ... ... ..',
    LT: '... ... ... ... ..',
    LU: '... ... ... ... ..',
    MO: '... ... ... ... ..',
    MK: '... ... ... ... ..',
    MG: '... ... ... ... ..',
    MW: '... ... ... ... ..',
    MY: '..-....-....',
    MV: '... ... ... ... ..',
    ML: '... ... ... ... ..',
    MT: '... ... ... ... ..',
    MH: '... ... ... ... ..',
    MQ: '... ... ... ... ..',
    MR: '... ... ... ... ..',
    MU: '... ... ... ... ..',
    MX: '... ... ....',
    FM: '... ... ... ... ..',
    MD: '(..) ..-..-..',
    MC: '... ... ... ... ..',
    MN: '... ... ... ... ..',
    ME: '... ... ... ... ..',
    MA: '... ... ... ... ..',
    MZ: '... ... ... ... ..',
    MM: '... ... ... ... ..',
    NA: '... ... ... ... ..',
    NR: '... ... ... ... ..',
    NP: '... ... ... ... ..',
    NL: '.. ........',
    NC: '... ... ... ... ..',
    NZ: '...-...-....',
    NI: '... ... ... ... ..',
    NE: '... ... ... ... ..',
    NG: '... ... ... ... ..',
    KP: '... ... ... ... ..',
    NO: '... .. ...',
    OM: '... ... ... ... ..',
    PK: '...-.......',
    PW: '... ... ... ... ..',
    PS: '... ... ... ... ..',
    PA: '... ... ... ... ..',
    PG: '... ... ... ... ..',
    PY: '... ... ... ... ..',
    PE: '... ... ... ... ..',
    PH: '.... .......',
    PL: '...-...-...',
    PT: '... ... ... ... ..',
    PR: '... ... ... ... ..',
    QA: '... ... ... ... ..',
    RE: '... ... ... ... ..',
    RO: '... ... ... ... ..',
    RU: '(...) ...-..-..',
    RW: '... ... ... ... ..',
    KN: '... ... ... ... ..',
    LC: '... ... ... ... ..',
    VC: '... ... ... ... ..',
    WS: '... ... ... ... ..',
    SM: '... ... ... ... ..',
    ST: '... ... ... ... ..',
    SA: '... ... ... ... ..',
    SN: '... ... ... ... ..',
    RS: '... ... ... ... ..',
    SC: '... ... ... ... ..',
    SL: '... ... ... ... ..',
    SG: '....-....',
    SK: '... ... ... ... ..',
    SI: '... ... ... ... ..',
    SB: '... ... ... ... ..',
    SO: '... ... ... ... ..',
    ZA: '... ... ... ... ..',
    KR: '... .... ....',
    SS: '... ... ... ... ..',
    ES: '... ... ...',
    LK: '... ... ... ... ..',
    SD: '... ... ... ... ..',
    SR: '... ... ... ... ..',
    SZ: '... ... ... ... ..',
    SE: '(...) ...-...',
    CH: '.. ... .. ..',
    SY: '... ... ... ... ..',
    TW: '... ... ... ... ..',
    TJ: '... ... ... ... ..',
    TZ: '... ... ... ... ..',
    TH: '... ... ... ... ..',
    TL: '... ... ... ... ..',
    TG: '... ... ... ... ..',
    TO: '... ... ... ... ..',
    TT: '... ... ... ... ..',
    TN: '... ... ... ... ..',
    TR: '... ... .. ..',
    TM: '... ... ... ... ..',
    TV: '... ... ... ... ..',
    UG: '... ... ... ... ..',
    UA: '(..) ... .. ..',
    AE: '... ... ... ... ..',
    GB: '.... ......',
    US: '(...) ...-....',
    UY: '... ... ... ... ..',
    UZ: '.. ... .. ..',
    VU: '... ... ... ... ..',
    VA: '.. .... ....',
    VE: '... ... ... ... ..',
    VN: '... ... ... ... ..',
    YE: '... ... ... ... ..',
    ZM: '... ... ... ... ..',
    ZW: '... ... ... ... ..',
};
