import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import AIAssistant, { AIAssistantLoadingWrapper } from '@features/AIAssistant';
import { ColorPicker } from '@components/ColorPicker';
import { RadioButtonsGroup } from '@components/RadioButtonsGroup';
import ImageUploaderSingle from '@components/ImageUploaderSingle';
import { Switch } from '@components/Switch';
import { Select } from '@components/Select';
import { Input } from '@components/Input';
import { Typography } from '@components/Typography';
import { AITaskType, AI_TASK_TYPE } from '@constants/ai-assistant';
import { CHANNEL } from '@constants/channels';
import { getSurveysOptions } from '@helpers/send-invites';
import { useTranslation } from '@hooks/useTranslation';
import { campaignsService } from '@services';

const StyledRadioButtons = styled(RadioButtonsGroup)({
    marginTop: '8px',
    marginBottom: '8px',
});

export const MESSAGE_TYPE = {
    MESSAGE: 1,
    SURVEY: 2,
};

const ContentSource = ({ channelType, isView, companies }) => {
    const [messageType, setMessageType] = useState(MESSAGE_TYPE.MESSAGE);
    const [aiIsLoading, setAiIsLoading] = useState(false);
    const [uploading, setUploading] = useState(false);

    const { t } = useTranslation();
    const { control, setValue, watch } = useFormContext();
    const coverPhotoEnabled = watch('coverPhotoEnabled');
    const companyIds = watch('locations');
    const coverPhotoUrl = watch('coverPhotoUrl');

    const uploadLogo = async e => {
        try {
            setUploading(true);
            const data = new FormData();
            data.append('file', e.target.files[0]);
            data.append('fileName', e.target.files[0].name);
            const response = await campaignsService.uploadEmailCampaignImage(data);
            setValue('coverPhotoUrl', response.publicUrl, { shouldDirty: true, shouldValidate: true });
        } catch (_) {
            console.error(`Couldn't upload coverPhotoUrl`);
        }
        setUploading(false);
        e.target.value = '';
    };

    const IS_EMAIL = channelType === CHANNEL.EMAIL;

    return (
        <>
            <div style={{ gridArea: 'radio-buttons' }}>
                <Controller
                    control={control}
                    name="messageType"
                    render={({ field }) => (
                        <StyledRadioButtons
                            id="pl-radiobuttons"
                            value={field.value}
                            onChange={value => {
                                setValue('messageType', value, { shouldDirty: true, shouldValidate: true });
                                setMessageType(value);
                            }}
                            options={[
                                { value: MESSAGE_TYPE.MESSAGE, label: t('common.message', { count: 1 }) },
                                { value: MESSAGE_TYPE.SURVEY, label: t('common.survey') },
                            ]}
                            disabled={isView}
                        />
                    )}
                />
            </div>
            {messageType === MESSAGE_TYPE.MESSAGE && (
                <>
                    {IS_EMAIL && (
                        <Controller
                            control={control}
                            name="subject"
                            render={({ field, fieldState: { error }, ref }) => (
                                <Input
                                    ref={ref}
                                    inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                    label={t('common.subject')}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? t(error.message) : null}
                                    required
                                    disabled={isView}
                                    sx={{ gridArea: 'subject' }}
                                />
                            )}
                        />
                    )}
                    <Controller
                        control={control}
                        name="message"
                        render={({ field, fieldState: { error }, ref }) => (
                            <div style={{ gridArea: 'message', position: 'relative' }}>
                                {aiIsLoading && <AIAssistantLoadingWrapper />}
                                <Input
                                    ref={ref}
                                    inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                    label={t('common.message', { count: 1 })}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? t(error.message, { count: 1024 }) : null}
                                    multiline
                                    required
                                    minRows={4}
                                    maxLength={channelType === CHANNEL.WHATSAPP ? 1024 : 0}
                                    disabled={isView || aiIsLoading}
                                    toolbar={
                                        <AIAssistant
                                            btnStyles={{ position: 'absolute', left: 12, bottom: 32.5 }}
                                            value={field.value}
                                            setValue={newValue => field.onChange(newValue)}
                                            disabled={isView || aiIsLoading}
                                            setLoading={setAiIsLoading}
                                            additionalOptions={[
                                                {
                                                    text: t('AIAssistant.writeCampaign'),
                                                    type: AITaskType.GenerateCampaignMessage,
                                                    taskType: AI_TASK_TYPE.CONTEXTUAL,
                                                    companyId: companyIds.length === 1 ? companyIds[0] : null,
                                                },
                                            ]}
                                        />
                                    }
                                />
                            </div>
                        )}
                    />
                    {IS_EMAIL && (
                        <>
                            <Controller
                                control={control}
                                name="coverPhotoEnabled"
                                render={({ field, ref }) => (
                                    <Switch
                                        {...field}
                                        ref={ref}
                                        checked={field.value}
                                        handleChange={event => {
                                            setValue('coverPhotoEnabled', event.target.checked, {
                                                shouldDirty: true,
                                                shouldValidate: true,
                                            });
                                        }}
                                        label={t('CampaignsPage.addCoverPhoto')}
                                        style={{ gridArea: 'coverPhotoSwitch' }}
                                    />
                                )}
                            />
                            {coverPhotoEnabled && (
                                <div style={{ gridArea: 'image' }}>
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: 'rgba(0, 0, 0, 0.6)',
                                            display: ' block',
                                            marginBottom: '8px',
                                        }}
                                    >
                                        {t('CampaignsPage.coverPhotoFormat')}
                                    </Typography>
                                    <ImageUploaderSingle
                                        extensions=".png, .jpg, .jpeg"
                                        dimension={[600]}
                                        logoUrl={coverPhotoUrl}
                                        disabled={uploading}
                                        onDelete={() =>
                                            setValue('coverPhotoUrl', null, {
                                                shouldDirty: true,
                                                shouldValidate: true,
                                            })
                                        }
                                        uploadLogo={uploadLogo}
                                        isUploading={uploading}
                                    />
                                </div>
                            )}
                            <Controller
                                control={control}
                                name="ctaButtonEnabled"
                                render={({ field, ref }) => (
                                    <Switch
                                        {...field}
                                        ref={ref}
                                        checked={field.value}
                                        handleChange={event => {
                                            setValue('ctaButtonEnabled', event.target.checked, {
                                                shouldDirty: true,
                                                shouldValidate: true,
                                            });
                                        }}
                                        label={t('CampaignsPage.addCTAButton')}
                                        style={{ gridArea: 'ctaButtonSwitch' }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="ctaTitle"
                                render={({ field, fieldState: { error }, ref }) => (
                                    <Input
                                        ref={ref}
                                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                        label={t('CampaignsPage.name')}
                                        fullWidth
                                        error={!!error}
                                        helperText={error ? t(error.message) : null}
                                        required
                                        disabled={isView}
                                        sx={{ gridArea: 'ctaTitle' }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="ctaLink"
                                render={({ field, fieldState: { error }, ref }) => (
                                    <Input
                                        ref={ref}
                                        inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                        label={t('common.link')}
                                        fullWidth
                                        error={!!error}
                                        helperText={error ? t(error.message) : null}
                                        required
                                        disabled={isView}
                                        sx={{ gridArea: 'ctaLink' }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="ctaColor"
                                render={({ field, fieldState: { error }, ref }) => (
                                    <ColorPicker
                                        ref={ref}
                                        inputProps={{
                                            ...field,
                                            onChange: e => {
                                                const val = e?.target?.value ? e.target.value : e;
                                                field.onChange(val);
                                            },
                                        }}
                                        label={t('common.color')}
                                        fullWidth
                                        error={!!error}
                                        helperText={error ? t(error.message) : null}
                                        required
                                        disabled={isView}
                                        styles={{ gridArea: 'ctaColor' }}
                                    />
                                )}
                            />
                        </>
                    )}
                </>
            )}
            {messageType === MESSAGE_TYPE.SURVEY && (
                <Controller
                    control={control}
                    name="surveyTemplateId"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Select
                            {...field}
                            ref={ref}
                            onChange={({ target: { value } }) => {
                                setValue('surveyTemplateId', value, {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                });
                            }}
                            options={getSurveysOptions(companyIds, companies)}
                            required
                            disabled={isView}
                            label={t('common.survey')}
                            fullWidth
                            style={{ gridArea: 'message' }}
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                        />
                    )}
                />
            )}
        </>
    );
};

export default ContentSource;
