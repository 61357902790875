export const channelsConstants = {
    CHANNELS_FETCH: 'CHANNELS_FETCH',
    CHANNELS_SUCCESS: 'CHANNELS_SUCCESS',
    CHANNELS_ERROR: 'CHANNELS_ERROR',
    CHANNELS_UPDATE: 'CHANNELS_UPDATE',
};

export const CHANNEL = {
    SMS: 0,
    WHATSAPP: 1,
    EMAIL: 2,
    LIVE_CHAT: 4,
    FACEBOOK: 5,
    INSTAGRAM: 6,
    ARCHIVED: null,
};

export const CHANNEL_NAME = {
    SMS: 'SMS',
    WHATSAPP: 'WhatsApp (Beta)',
    EMAIL: 'Email',
    LIVE_CHAT: 'Live-chat',
    FACEBOOK: 'Facebook Messenger',
    INSTAGRAM: 'Instagram',
};

export const CHANNEL_MAP = {
    0: 'SMS',
    1: 'WhatsApp (Beta)',
    2: 'Email',
    4: 'Live-chat',
    5: 'Facebook Messenger',
    6: 'Instagram',
};

export const STATUS = {
    PENDING: 0,
    SUCCESS: 1,
    DISCONNECTED: 2,
    NUMBER_EXISTS: 3,
};

export const SmsProviderType = {
    Twilio: 0,
    TurboSms: 1,
    Lifecell: 2,
    SmsTo: 3,
};
