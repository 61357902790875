import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Table } from '@components/Table';
import { Typography } from '@components/Typography';
import Tooltip from '@components/Tooltip';
import { getChannelIcon } from '@helpers/channels';
import { LabelPlain } from '@components/Label';
import { useTranslation } from '@hooks/useTranslation';
import { formatCompanies } from '@helpers/companies';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { CAMPAIGN_STATUS } from '@constants/campaigns';
import { CHANNEL } from '@constants/channels';

const StyledTable = styled(Table)({
    '& .MuiTableBody-root .MuiTableCell-body:last-child': {
        padding: 4,
    },
});

const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.title')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.location')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.channel')}
                </Typography>
            ),
        },
        {
            key: 3,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('CampaignsPage.audience')}
                </Typography>
            ),
        },
        {
            key: 4,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.scheduledTime')}
                </Typography>
            ),
        },
        {
            key: 5,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.status')}
                </Typography>
            ),
        },
        { key: 6, component: <Typography align="left" variant="subtitle2" /> },
    ];
};

const GetTableBody = (data, language, companies, channels, onClick) => {
    const { t } = useTranslation();
    return data.map(campaign => {
        const isArchived = campaign.channelId === CHANNEL.ARCHIVED;
        let channel;
        if (isArchived) {
            channel = { type: CHANNEL.ARCHIVED, name: t('common.archived') };
        } else {
            channel = channels.find(c => c.id === campaign.channelId);
        }
        return [
            {
                component: (
                    <Typography
                        variant="subtitle2"
                        align="left"
                        sx={{ width: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {campaign.title}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left" sx={{ width: '120px' }}>
                        {formatCompanies(companies, campaign.locations, 30)}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left" sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <span style={{ width: 16 }}>{getChannelIcon(channel?.type)}</span> {channel?.name}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {campaign.labels?.length ? (
                            <Tooltip placement="top" title={campaign.labels.map(l => l.name).join(', ')}>
                                <span style={{ width: 120, overflow: 'hidden', display: 'flex' }}>
                                    {campaign.labels.map(label => (
                                        <LabelPlain key={label.id} type={label.name} variant="caption">
                                            {label.name}
                                        </LabelPlain>
                                    ))}
                                </span>
                            </Tooltip>
                        ) : (
                            '--'
                        )}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left" sx={{ width: 90 }}>
                        {format(new Date(campaign.scheduledTime), 'dd MMM yyyy, HH:mm', {
                            locale: I18N_DATE_FORMAT[language],
                        })}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="left">
                        {t(`CampaignsPage.status${campaign.status}`)}
                        <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                            {' '}
                            ({campaign.progress}/{campaign.itemsCount})
                        </span>
                    </Typography>
                ),
            },
            {
                component: (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Tooltip title={t('common.view')} placement="top">
                            <IconButton onClick={() => onClick(campaign, 'view')}>
                                <VisibilityIcon viewBox="0 0 22 22" />
                            </IconButton>
                        </Tooltip>
                        {campaign.status === CAMPAIGN_STATUS.SCHEDULED && !isArchived && (
                            <Tooltip title={t('common.edit')} placement="top">
                                <IconButton onClick={() => onClick(campaign, 'edit')}>
                                    <EditIcon viewBox="0 0 22 22" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {(campaign.status === CAMPAIGN_STATUS.SCHEDULED ||
                            campaign.status === CAMPAIGN_STATUS.INPROGRESS) && (
                            <Tooltip title={t('common.delete')} placement="top">
                                <IconButton onClick={() => onClick(campaign, 'delete')}>
                                    <DeleteIcon viewBox="0 0 22 22" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                ),
            },
        ];
    });
};

const OneTimeTable = ({
    companies,
    rowsPerPageRequests,
    pageRequests,
    onPageChange,
    onRowsPerPageChange,
    onClickCampaign,
}) => {
    const { t } = useTranslation();
    const { data, loading, totalCount, pages } = useSelector(state => state.campaigns);
    const { data: channels } = useSelector(state => state.channels);
    const language = useSelector(state => state.authentication.user?.language);

    return (
        <TableContainer>
            <StyledTable
                withHover
                columnNames={GetTableHeader()}
                rows={GetTableBody(data, language, companies, channels, onClickCampaign)}
                isDataLoading={loading}
                withPagination
                paginationProps={{
                    colSpan: 7,
                    count: totalCount,
                    rowsPerPage: rowsPerPageRequests,
                    page: pageRequests,
                    onPageChange,
                    onRowsPerPageChange,
                    labelDisplayedRows: ({ from, to, count }) =>
                        `${from}–${to} ${t('Table.of')} ${count !== -1 ? count : `${t('Table.moreThan')} ${to}`}`,
                    labelRowsPerPage: <span>{t('Table.rowsPerPage')}</span>,
                    pages,
                }}
            />
        </TableContainer>
    );
};

export default OneTimeTable;
