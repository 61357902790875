import { useState, forwardRef, useRef, memo } from 'react';
import { useSelector } from 'react-redux';
import { getYear, getMonth } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { styled } from '@mui/system';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { I18N_DATE_FORMAT, getMonthsList } from '@helpers/dates';
import Button from '@components/Button';
import { Input as BaseInput } from '@components/Input';
import { useTranslation } from '@hooks/useTranslation';
import './styles.css';

const AllTimeContainer = styled('div')({
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '16px',
    float: 'left',
    width: '100%',
});

const MultiselectErrorWrapper = styled('div')(({ error }) => ({
    position: 'relative',
    height: 'fit-content',
    '& .MuiInputLabel-root': {
        color: error && '#B00020',
    },
    '& .MuiOutlinedInput-root fieldset, .MuiOutlinedInput-root:hover fieldset': {
        borderColor: error && '#B00020',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
        borderColor: error && '#B00020',
    },
}));

const ErrText = styled('p')({
    position: 'absolute',
    bottom: '-16px',
    left: 11,
    color: '#B00020',
    fontFamily: 'Rubik,sans-serif',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.4px',
});

const MonthYearSelect = styled('select')({
    outline: 'none',
    border: 'none',
    fontFamily: 'Rubik, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.875rem', //14px
    lineHeight: '16px',
    letterSpacing: '0.1px',
});

const CustomInput = forwardRef(
    (
        { label, showTimeSelect, isRequired, onBlurTimeSelect, value, onClick, isDisabled, isOpen, range, dateRef },
        ref,
    ) => {
        const { t } = useTranslation();
        return (
            <BaseInput
                label={label}
                addonComponent={
                    <CalendarTodayRoundedIcon sx={{ fill: isOpen ? '#1F4C5C' : 'rgba(0, 0, 0, 0.38)', fontSize: 16 }} />
                }
                onAddonClick={() => dateRef.current.setOpen(!isOpen)}
                fullWidth
                inputRef={ref}
                disabled={isDisabled}
                required={isRequired}
                inputProps={{
                    value: value ? value : showTimeSelect || !range ? '' : t('common.allTime'),
                    readOnly: true,
                    onClick,
                    onBlur: onBlurTimeSelect,
                }}
            />
        );
    },
);

CustomInput.displayName = 'CustomInput';

const TimeFramePicker = memo(
    ({
        className,
        label = '',
        onChangeTimeframe,
        disabled,
        required = false,
        range = true,
        value = [new Date(), null],
        showTimeSelect = false,
        onBlurTimeSelect = null,
        dateFormat = 'd/M/yyyy',
        minDate = new Date(),
        maxDate = null,
        error,
    }) => {
        const { t } = useTranslation();
        const [isCalendarOpen, setCalendarOpen] = useState(false);
        const language = useSelector(state => state.authentication.user?.language);
        const dateRef = useRef();

        const onChange = dates => onChangeTimeframe(dates);

        const filterPassedTime = time => {
            if (maxDate && !range) {
                const selectedDate = new Date(time);
                return selectedDate.getTime() < maxDate.getTime();
            }
            const currentDate = new Date();
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        };

        const minMaxProps = range ? { minDate: null, maxDate: new Date() } : { minDate, maxDate };
        const currentYear = getYear(new Date());
        const yearsRange = (start, stop, step) =>
            Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
        const years = yearsRange(currentYear, currentYear - 50, -1);
        const months = getMonthsList(I18N_DATE_FORMAT[language]);

        return (
            <MultiselectErrorWrapper className={className} error={!!error}>
                <DatePicker
                    ref={dateRef}
                    popperModifiers={[
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -24],
                            },
                        },
                    ]}
                    selected={value[0]}
                    onChange={onChange}
                    startDate={value[0]}
                    endDate={value[1]}
                    selectsRange={range}
                    showTimeSelect={showTimeSelect}
                    timeFormat="HH:mm"
                    calendarStartDay={1}
                    locale={I18N_DATE_FORMAT[language]}
                    dateFormat={dateFormat}
                    onCalendarClose={() => setCalendarOpen(false)}
                    onCalendarOpen={() => setCalendarOpen(true)}
                    maxDate={range ? new Date() : null}
                    minDate={range ? null : new Date()}
                    filterTime={range ? null : filterPassedTime}
                    {...minMaxProps}
                    customInput={
                        <CustomInput
                            dateRef={dateRef}
                            label={label}
                            showTimeSelect={showTimeSelect}
                            isOpen={isCalendarOpen}
                            isDisabled={disabled}
                            isRequired={required}
                            onBlurTimeSelect={onBlurTimeSelect}
                            range={range}
                        />
                    }
                    monthsShown={1}
                    renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        changeYear,
                        changeMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div className="navigation-container">
                            <button
                                aria-label="Previous Month"
                                className="react-datepicker__navigation react-datepicker__navigation--previous"
                                style={{ marginLeft: '8px' }}
                                onClick={decreaseMonth}
                                type="button"
                                disabled={prevMonthButtonDisabled}
                            >
                                <ArrowBackRoundedIcon sx={{ fill: '#123341' }} />
                            </button>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <MonthYearSelect
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) => changeYear(value)}
                                >
                                    {years.map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </MonthYearSelect>
                                <MonthYearSelect
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                                >
                                    {months.map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </MonthYearSelect>
                            </div>
                            <button
                                aria-label="Next Month"
                                className={'react-datepicker__navigation react-datepicker__navigation--next'}
                                style={{ marginRight: '8px' }}
                                onClick={increaseMonth}
                                type="button"
                                disabled={nextMonthButtonDisabled}
                            >
                                <ArrowForwardRoundedIcon sx={{ fill: '#123341' }} />
                            </button>
                        </div>
                    )}
                >
                    {range ? (
                        <AllTimeContainer>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => {
                                    if (value[0] || value[1]) onChangeTimeframe([null, null]);
                                    setCalendarOpen(false);
                                    dateRef.current.setOpen(false);
                                }}
                            >
                                {t('common.allTime')}
                            </Button>
                        </AllTimeContainer>
                    ) : null}
                </DatePicker>
                {error ? <ErrText>{error ? t(error.message) : null}</ErrText> : null}
            </MultiselectErrorWrapper>
        );
    },
);

TimeFramePicker.displayName = 'TimeFramePicker';

export default TimeFramePicker;
