import Snackbar from '@mui/material/Snackbar';
import Alert from './Alert';

const ControlledToaster = ({
    callback = null,
    autoHideDuration = 6000,
    text,
    type,
    open,
    setOpen,
    clickClose = null,
}) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        if (callback) {
            callback();
        }
    };

    const handleCloseButton = () => {
        setOpen(false);
        if (clickClose) {
            clickClose();
        }
    };

    return (
        <Snackbar style={{ zIndex: 99999 }} open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
            <Alert severity={type} onClose={handleCloseButton}>
                {text}
            </Alert>
        </Snackbar>
    );
};

export default ControlledToaster;
