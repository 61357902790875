import { profilesConstants } from '@constants/profiles';

const initialState = {
    data: [],
    loading: true,
    error: '',
    totalCount: 0,
    pages: 1,
    actionRequiredCount: 0,
    inProgressCount: 0,
    naCount: 0,
    syncCompleted: true,
    syncedCount: 0,
    syncedWithConflictsCount: 0,
};

export function profiles(state = initialState, action) {
    switch (action.type) {
        case profilesConstants.PROFILES_FETCH:
            return { ...state, error: '' };
        case profilesConstants.PROFILES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data.items,
                totalCount: action.data.data.totalCount,
                pages: action.data.data.pages,
                actionRequiredCount: action.data.actionRequiredCount,
                inProgressCount: action.data.inProgressCount,
                naCount: action.data.naCount,
                syncCompleted: action.data.syncCompleted,
                syncedCount: action.data.syncedCount,
                syncedWithConflictsCount: action.data.syncedWithConflicts,
                error: '',
            };
        case profilesConstants.SET_SYNC_STATUS:
            return { ...state, syncCompleted: action.data.syncCompleted, loading: false, error: '' };
        case profilesConstants.PROFILES_ERROR:
            return { ...state, error: action.message, loading: false };
        case profilesConstants.SET_PROFILES:
            return { ...state, data: action.data };
        default:
            return state;
    }
}
