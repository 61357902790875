import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import { Input } from '@components/Input';
import { CssMenuItem } from '@components/Select';

export const MenuItem = styled(CssMenuItem, {
    shouldForwardProp: prop => prop !== 'isMainLabel',
})(({ isMainLabel }) => ({
    '&.MuiMenuItem-root': {
        padding: isMainLabel ? '8px 12px 8px 35px' : '8px 12px',
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 1024px)': {
            minHeight: '32px',
        },
        '&>*:first-of-type': {
            marginRight: 9,
            color: '#1F4C5C',
        },
    },
}));

export const MenuItemManage = styled('div', {
    shouldForwardProp: prop => prop !== 'isMainLabel',
})(({ isMainLabel }) => ({
    padding: isMainLabel ? '8px 12px 8px 35px' : '8px 12px',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& .MuiFormControl-root': {
        paddingBottom: 0,
    },
    '@media (max-width: 1024px)': {
        minHeight: '32px',
    },
}));

export const SearchBar = styled('div')({
    padding: '4px 12px',
    display: 'flex',
    width: '100%',
    gap: 8,
    '& .MuiFormControl-root': {
        paddingBottom: 0,
    },
});

export const CssInput = styled(Input)({
    input: {
        padding: '8px !important',
        fontSize: 12,
    },
    '.MuiInputLabel-root': {
        transform: 'translate(12px, 8px) scale(1)',
        fontSize: 12,
    },
    '& .MuiInputLabel-root': {
        transform: 'translate(34px, 8px)',
    },
    '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) !important',
        fontSize: 10,
    },
    '.MuiSvgIcon-root': {
        width: 16,
        height: 16,
        marginRight: 4,
    },
});

export const CssSelect = styled(Select)({
    '&.MuiOutlinedInput-root': {
        minHeight: '40px',
    },
    '.MuiSelect-icon': {
        color: 'rgba(0, 0, 0, 0.88)',
    },
    '& .MuiSelect-icon.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    '.MuiSelect-select': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 3,
        paddingTop: '10px',
        paddingBottom: '10px',
    },
});
