import { styled } from '@mui/material/styles';
import {
    ImproveIcon,
    TranslateIcon,
    MakeShorterIcon,
    MakeLongerIcon,
    MicrophoneIcon,
    PanIcon,
} from 'assets/images/icons';

export const InputBlurWrapper = styled('div')(() => ({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100% - 4px)',
    height: 'calc(100% - 20px)',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    pointerEvents: 'none',
    top: 2,
    left: 2,
    '& .MuiCircularProgress-root': {
        color: 'rgba(0, 0, 0, 0.38) !important',
    },
}));

const styledIcon = IconComponent =>
    styled(IconComponent)({
        width: 18,
        height: 18,
        marginRight: 8,
    });

export const StyledImproveIcon = styledIcon(ImproveIcon);
export const StyledTranslateIcon = styledIcon(TranslateIcon);
export const StyledMakeShorterIcon = styledIcon(MakeShorterIcon);
export const StyledMakeLongerIcon = styledIcon(MakeLongerIcon);
export const StyledMicrophoneIcon = styledIcon(MicrophoneIcon);
export const StyledPanIcon = styledIcon(PanIcon);
