export const getKeywordsOptions = (keywords, companies) => {
    const keywordsMap = {};
    companies?.forEach(company => {
        const companyKw = keywords[company] || [];
        for (const kw of companyKw) {
            if (!keywordsMap[kw.keyword]) {
                keywordsMap[kw.keyword] = kw.reviewsCount;
            } else {
                keywordsMap[kw.keyword] = keywordsMap[kw.keyword] + kw.reviewsCount;
            }
        }
    });
    return Object.entries(keywordsMap)
        .map(([key, value]) => ({ label: `${key} (${value})`, value: key, sortableField: value }))
        .sort((a, b) => b.sortableField - a.sortableField)
        .slice(0, 100);
};
