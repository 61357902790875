import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendInvitesUiActions } from '@actions';

export function useClickSendSurveys() {
    const history = useHistory();
    const dispatch = useDispatch();
    const companies = useSelector(state => state.account?.account?.companies);

    const surveyExists = useMemo(() => {
        return companies?.map(company => company.surveys)?.flat().length > 0;
    }, [companies]);

    const onClickSendSurveys = () => {
        if (!surveyExists) {
            history.push('/surveys/templates');
        } else {
            dispatch(sendInvitesUiActions.setOpen(true));
        }
    };

    return { surveyExists, onClickSendSurveys };
}
