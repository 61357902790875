export const init = () => {
    function appHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--100vh', window.innerHeight + 'px');
    }

    window.addEventListener('resize', appHeight);

    appHeight();
};
