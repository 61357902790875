import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@components/Typography';
import { Table } from '@components/Table';
import { useTranslation } from '@hooks/useTranslation';
import { formatNumber } from '@helpers/formatNumbers';

const Container = styled(Paper)({
    padding: '24px',
    width: '100%',
    borderRadius: 4,
    '@media print': {
        breakInside: 'avoid',
    },
});

export const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('AnalyticsKeywords.keyword')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography variant="subtitle2" align="right">
                    {t('AnalyticsPresence.searchAppearances')}
                </Typography>
            ),
        },
    ];
};

export const getTableBody = data => {
    return data.map(({ keyword, views }) => [
        {
            component: (
                <Typography variant="body2" align="left">
                    {keyword}
                </Typography>
            ),
        },
        {
            component: (
                <Typography variant="body2" align="right">
                    {formatNumber(views)}
                </Typography>
            ),
        },
    ]);
};

const SearchKeywordsTable = ({ isLoading, data }) => {
    const { t } = useTranslation();
    return (
        <Container elevation={1}>
            <Typography variant="title1" sx={{ color: 'rgba(0, 0, 0, 0.87)', display: 'block', marginBottom: '16px' }}>
                {t('AnalyticsPresence.searchKeywords')}
            </Typography>
            <TableContainer>
                <Table columnNames={GetTableHeader()} rows={getTableBody(data)} isDataLoading={isLoading} />
            </TableContainer>
        </Container>
    );
};

export default SearchKeywordsTable;
