import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { Typography } from '@components/Typography';
import { MultiSelect } from '@components/MultiSelect';
import MediaUploaderMulti from '@components/MediaUploaderMulti';
import { Select } from '@components/Select';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { useTranslation } from '@hooks/useTranslation';
import { LocationIntegrationName } from '@constants/integrations';
import { alertActions, profilesMediaActions } from '@actions';
import { profilesMediaService } from '@services';
import { KB, MB } from '@constants/file';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { Content, Modal } from './styles';

const UploadModal = ({ isOpen, handleClose }) => {
    const { t } = useTranslation();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const { location: labels } = useSelector(state => state.labels);
    const companies = useSelector(state => state.account?.account?.companies || []);
    const { data } = useSelector(state => state.profilesMedia);

    const dispatch = useDispatch();

    const availableLabels = [
        ...new Set(
            companies
                .filter(c => c.profiles?.some(p => p.type === LocationIntegrationName.Google))
                .map(item => item.labels)
                .flat(),
        ),
    ];

    const labelsOptions = useMemo(() => {
        return labels.filter(l => availableLabels.includes(l.id)).map(item => ({ value: item.id, label: item.name }));
    }, [companies, labels]);

    const categoriesOptions = [
        { value: 1, label: t('ProfilesMedia.exterior') },
        { value: 2, label: t('ProfilesMedia.interior') },
        { value: 3, label: t('ProfilesMedia.product') },
        { value: 4, label: t('ProfilesMedia.atWork') },
        { value: 5, label: t('ProfilesMedia.foodAndDrink') },
        { value: 6, label: t('ProfilesMedia.menu') },
        { value: 7, label: t('ProfilesMedia.commonArea') },
        { value: 8, label: t('ProfilesMedia.rooms') },
        { value: 9, label: t('ProfilesMedia.teams') },
    ];

    const UploadSchema = yup.object().shape({
        locations: yup.array().min(1, 'validation.required'),
        category: yup.number(),
        photoUrls: yup.array().min(1, 'validation.required'),
    });

    const resolver = useYupValidationResolver(UploadSchema);

    const {
        getValues,
        setValue,
        control,
        reset,
        watch,
        formState: { isValid, isDirty, errors },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            labels: labels.filter(l => availableLabels.includes(l.id)).map(item => item.id),
            locations: companies
                .filter(c => c.profiles?.some(p => p.type === LocationIntegrationName.Google))
                .map(c => c.companyId),
            category: '',
            photoUrls: [],
        },
    });

    const selectedLocationLabels = watch('labels');

    const companyOptions = useMemo(() => {
        const filteredCompanies = companies.filter(c =>
            c.profiles?.some(p => p.type === LocationIntegrationName.Google),
        );
        return selectedLocationLabels.length === labelsOptions.length
            ? filteredCompanies.map(item => ({ value: item.companyId, label: item.internalName }))
            : filteredCompanies
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [selectedLocationLabels]);

    const onCloseModal = () => {
        handleClose(false);
        reset();
    };

    const onUpload = async () => {
        const values = getValues();
        setIsSubmitting(true);
        onCloseModal();
        try {
            const newMedia = await profilesMediaService.uploadMedia({ ...values, labels: undefined });
            dispatch(profilesMediaActions.set([...newMedia, ...data]));
            dispatch(alertActions.success(t('alertMessages.uploadSuccess')));
        } catch (error) {
            if (error.errorCode === 'google_validation_failed' && error.reasons?.length > 0) {
                error.reasons.forEach(reason => {
                    dispatch(alertActions.error(reason));
                });
            } else if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.uploadFail')));
            }
            setIsSubmitting(false);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            handleClose={onCloseModal}
            title={t('ProfilesMedia.uploadPhotos')}
            onPrimaryAction={onUpload}
            primaryActionText={t('ProfilesMedia.uploadPhotos')}
            primaryActionDisabled={isSubmitting || !isValid || !isDirty || !!errors?.message}
            onSecondaryAction={onCloseModal}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content>
                <Controller
                    control={control}
                    name="labels"
                    render={({ field, ref }) => (
                        <MultiSelect
                            {...field}
                            ref={ref}
                            label={t('common.locationLabels')}
                            onChange={({ target: { value } }) => {
                                const selectedLabels = getMultiselectSelectedOptions(labelsOptions, value);
                                setValue('labels', selectedLabels, { shouldDirty: true });
                                const filteredCompanies = companies.filter(c =>
                                    c.profiles?.some(p => p.type === LocationIntegrationName.Google),
                                );
                                const availableCompanies =
                                    selectedLabels.length === labelsOptions.length
                                        ? filteredCompanies.map(item => item.companyId)
                                        : filteredCompanies
                                              .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                                              .map(item => item.companyId);
                                setValue('locations', availableCompanies, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                            }}
                            options={labelsOptions}
                            style={{ gridArea: 'select0' }}
                            countable
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="locations"
                    render={({ field, fieldState: { error }, ref }) => (
                        <MultiSelect
                            {...field}
                            ref={ref}
                            onChange={({ target: { value } }) => {
                                setValue('locations', getMultiselectSelectedOptions(companyOptions, value), {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                            }}
                            options={companyOptions}
                            label={t('common.locations')}
                            style={{ gridArea: 'select1' }}
                            fullWidth
                            countable
                            required
                            error={error}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="category"
                    render={({ field, ref }) => (
                        <Select
                            {...field}
                            onChange={({ target: { value } }) => {
                                setValue('category', value, { shouldDirty: true });
                            }}
                            ref={ref}
                            label={t('common.category', { count: 0 })}
                            fullWidth
                            options={categoriesOptions}
                            style={{ gridArea: 'select2' }}
                        />
                    )}
                />
            </Content>
            <Typography
                variant="caption"
                sx={{ color: 'rgba(0, 0, 0, 0.6)', marginBottom: '12px', display: 'inline-block' }}
            >
                {t('ProfilesMedia.photosFormat')}
            </Typography>
            <MediaUploaderMulti
                limit={10}
                imagesValidation={{ maxSize: 5 * MB, minSize: 10 * KB }}
                extensions=".png, .jpg, .jpeg"
                onChange={updatedMedia =>
                    setValue(
                        'photoUrls',
                        updatedMedia.map(m => m.link),
                        { shouldDirty: true, shouldValidate: true },
                    )
                }
                imageServiceCallback={data => profilesMediaService.uploadProfilesImages(data)}
            />
        </Modal>
    );
};

export default UploadModal;
