import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import PageHeader from '@features/PageHeader';
import { ModalConfirm } from '@components/Modal';
import Button from '@components/Button';
import { inboxTemplatesActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';
import { inboxTemplatesService } from '@services';
import { alertActions } from '@actions';
import { ReplyTemplatesIcon } from 'assets/images/icons';
import EditModal, { FORM_TYPE } from './EditModal';
import InboxTemplateTable from './InboxTemplateTable';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

const InboxTemplates = () => {
    const { t } = useTranslation();

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [modal, setModal] = useState({ open: false, type: FORM_TYPE.NEW });
    const [pageTemplates, setPageTemplates] = useState(0);
    const [rowsPerPageTemplates, setRowsPerPageTemplates] = useState(10);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const user = useSelector(state => state.authentication.user);
    const IS_ACC_OWNER = user?.isAccountOwner || user?.isGlobalAdmin;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(inboxTemplatesActions.get(pageTemplates, rowsPerPageTemplates));
    }, [pageTemplates, rowsPerPageTemplates, dispatch]);

    const onDelete = async () => {
        try {
            await inboxTemplatesService.deleteInboxTemplate(selectedTemplate.id);
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
            if (pageTemplates === 0) {
                await dispatch(inboxTemplatesActions.get(pageTemplates, rowsPerPageTemplates));
            } else {
                setPageTemplates(0); // Triggers refetch of templates
            }
            setDeleteModalOpen(false);
            setSelectedTemplate(null);
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.deleteFail')));
        }
    };

    const onAddTemplateClick = () => setModal({ open: true, type: FORM_TYPE.NEW });

    return (
        <Container>
            <PageHeader
                styles={{ marginBottom: '16px' }}
                icon={<ReplyTemplatesIcon />}
                title={t('InboxTemplates.title')}
                subtitle={t('InboxTemplates.subtitle')}
            >
                <Button variant="contained" onClick={onAddTemplateClick}>
                    {t('buttons.addNewTemplate')}
                </Button>
            </PageHeader>
            <InboxTemplateTable
                setModal={setModal}
                setSelectedTemplate={setSelectedTemplate}
                setPage={setPageTemplates}
                page={pageTemplates}
                setRowsPerPage={setRowsPerPageTemplates}
                rowsPerPage={rowsPerPageTemplates}
                setDeleteModalOpen={setDeleteModalOpen}
                isAccountOwner={IS_ACC_OWNER}
                currentUser={user}
            />
            {modal.open ? (
                <EditModal
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    modal={modal}
                    setModal={setModal}
                    user={user}
                    setPage={setPageTemplates}
                    page={pageTemplates}
                    rowsPerPage={rowsPerPageTemplates}
                />
            ) : null}
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDelete}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                />
            )}
        </Container>
    );
};

export default InboxTemplates;
