import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import { MESSAGE_TYPE, MESSAGE_STATUS, MESSAGE_CONTENT_TYPE, SENDER_TYPE } from '@constants/inbox';
import { getFullName } from '@helpers/userName';
import MessageContent from './MessageContent';

const MessageWrapper = styled('div', {
    shouldForwardProp: prop =>
        prop !== 'type' &&
        prop !== 'multiple' &&
        prop !== 'isLast' &&
        prop !== 'contentType' &&
        prop !== 'withReply' &&
        prop !== 'senderType' &&
        prop !== 'messageStatus',
})(
    ({ type, multiple, isLast, contentType, withReply, senderType, messageStatus }) => `
    padding: 12px;
    width: fit-content;
    background-color: ${
        type === MESSAGE_TYPE.OUTGOING
            ? messageStatus === MESSAGE_STATUS.FAILED
                ? 'rgba(176, 0, 32, 0.25)'
                : '#EBF6F0'
            : '#F2F2F2'
    };
    border-radius: ${type === MESSAGE_TYPE.OUTGOING ? '8px 8px 0px 8px' : '8px 8px 8px 0px'};
    background-color: ${type === MESSAGE_TYPE.OUTGOING && senderType === SENDER_TYPE.BOT && '#E4EAEB'};
    & svg > path {
        fill: rgba(0, 0, 0, 0.87);
    }
    ${
        multiple &&
        `
        border-radius: 8px;
        &:not(:last-child) {
            margin-bottom: 4px;
        }
    `
    }
    ${
        multiple &&
        isLast &&
        `
        border-radius: ${type === MESSAGE_TYPE.OUTGOING ? '8px 8px 0 8px' : '8px 8px 8px 0'};
    `
    }
    ${
        contentType === MESSAGE_CONTENT_TYPE.AUDIO &&
        `
        padding: ${withReply ? '12px 12px 0 12px' : '0'};
    `
    }
`,
);

const ReplyWrapper = styled('div')`
    padding-left: 5px;
    border-left: 2px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 6px;
`;

const MessageContentWrapper = ({
    data,
    type,
    isLast,
    multiple,
    users,
    companies,
    assignedLocation,
    isOutgoing,
    messageStatus,
}) => {
    const senderName = useMemo(() => {
        if (!data.replyMessage) return '';
        const user = users.find(user => user.id === data.replyMessage.senderId);
        return getFullName(user?.firstName, user?.lastName);
    }, [data.replyMessage?.senderId, users]);

    const internalName = useMemo(() => {
        if (!data.replyMessage) return '';
        return companies.find(c => c.companyId === assignedLocation)?.internalName;
    }, [data.replyMessage?.companyId, companies]);

    const withReply = !!data.replyMessage;

    return (
        <MessageWrapper
            type={type}
            multiple={multiple}
            isLast={isLast}
            contentType={data.contentType}
            withReply={withReply}
            senderType={data.senderType}
            messageStatus={messageStatus}
        >
            {withReply ? (
                <ReplyWrapper>
                    <Typography
                        variant="caption"
                        sx={{ color: 'rgba(0, 0, 0, 0.60)', marginBottom: '5px', display: 'block' }}
                    >
                        {isOutgoing ? senderName : internalName}
                    </Typography>
                    <MessageContent data={data.replyMessage} isOutgoing={isOutgoing} />
                </ReplyWrapper>
            ) : null}
            <MessageContent data={data} withReply={withReply} isOutgoing={isOutgoing} />
        </MessageWrapper>
    );
};

export default MessageContentWrapper;
