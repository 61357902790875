import { inboxTemplatesConstants } from '@constants/inbox-templates';
import { inboxTemplatesService } from '@services';

export const inboxTemplatesActions = {
    get,
    getAll,
};

function get(page = 0, pageSize = 10) {
    return dispatch => {
        dispatch(request());
        return inboxTemplatesService.getInboxTemplates(page, pageSize).then(data => dispatch(success(data)));
    };
}

function getAll() {
    return dispatch => {
        dispatch(request());
        return inboxTemplatesService.getAllInboxTemplates().then(data => dispatch(successAllActive(data)));
    };
}

function request() {
    return { type: inboxTemplatesConstants.INBOX_TEMPLATES_REQUEST };
}

function success(data) {
    return { type: inboxTemplatesConstants.INBOX_TEMPLATES_SUCCESS, data };
}

function successAllActive(data) {
    return { type: inboxTemplatesConstants.INBOX_ALL_ACTIVE_TEMPLATES_SUCCESS, data };
}
