import { styled } from '@mui/system';
import { format } from 'date-fns';
import { Typography } from '@components/Typography';
import ResponseItem from '@components/ResponseItem';
import { CustomFieldType } from '@constants/feedbacks';
import { LocationIntegrationName } from '@constants/integrations';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { useTranslation } from '@hooks/useTranslation';
import FeedbackToolbar from './FeedbackToolbar';
import { CoverManagerIcon } from 'assets/images/integrations';

const BodyTextTypography = styled(Typography)({
    whiteSpace: 'pre-line',
    width: '60%',
    '@media (max-width: 1024px)': {
        width: '100%',
    },
});

const FeedbackBody = ({ item, language }) => {
    const { t } = useTranslation();
    return (
        <div style={{ marginTop: '16px' }}>
            {item?.customFields?.length > 0 && (
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                    {item?.customFields.map(
                        field =>
                            field?.value && (
                                <div key={field?.name} style={{ display: 'flex', marginBottom: '5px' }}>
                                    <Typography variant="subtitle2" color="textPrimary">
                                        {field?.name}:
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ marginLeft: '5px' }}>
                                        {field?.type === CustomFieldType.Date && field?.value
                                            ? format(new Date(field?.value), 'dd MMM yyyy', {
                                                  locale: I18N_DATE_FORMAT[language],
                                              })
                                            : field?.value}
                                    </Typography>
                                </div>
                            ),
                    )}
                </div>
            )}
            <BodyTextTypography
                variant="body2"
                color="textSecondary"
                style={{
                    color: item?.text ? 'inherit' : 'rgba(0, 0, 0, 0.38)',
                }}
            >
                {item?.text || t('FeedbackResponses.onlyRating')}
            </BodyTextTypography>
        </div>
    );
};

const InfoCaption = ({ date, internalName, surveyName, isCoverManager }) => {
    const { t } = useTranslation();
    return `${date} - ${internalName || t('FeedbackResponses.noDataMessage')}
            / 
            ${isCoverManager ? 'CoverManager' : surveyName || t('FeedbackResponses.noDataMessage')}`;
};

const IntegrationLabel = ({ type }) => {
    const { t } = useTranslation();

    const getIntegrationIcon = integrationType => {
        switch (integrationType) {
            case 19:
                return <CoverManagerIcon height={20} width={18} />;
            default:
                return null;
        }
    };

    return (
        <>
            <Typography variant="caption" sx={{ marginRight: '18px', color: '#1F4C5C' }}>
                {t('FeedbackResponses.viaCoverManager')}
            </Typography>
            {getIntegrationIcon(type)}
        </>
    );
};

const FeedbackItem = ({ item, index, language, handleOpenTicket, handleOpenLiveChat, onClickContactDetails }) => {
    const { t } = useTranslation();

    const isCoverManager = item?.type === LocationIntegrationName.CoverManager;
    const date = item?.createdAt
        ? format(new Date(item?.createdAt), 'dd MMM yyyy', { locale: I18N_DATE_FORMAT[language] })
        : t('FeedbackResponses.noDataMessage');

    return (
        <ResponseItem
            key={item?.id || `feedback-${index}`}
            index={index}
            contactName={item?.contactName}
            phoneNumber={item?.phoneNumber}
            email={item?.email}
            viewed={item?.viewed}
            rating={item?.rating}
            infoCaption={
                <InfoCaption
                    date={date}
                    internalName={item?.internalName}
                    surveyName={item?.surveyName}
                    isCoverManager={isCoverManager}
                />
            }
            body={<FeedbackBody item={item} language={language} />}
            footer={
                <FeedbackToolbar data={item} onClickTicket={handleOpenTicket} onClickLiveChat={handleOpenLiveChat} />
            }
            integrationLabel={isCoverManager ? <IntegrationLabel type={19} /> : null}
            contactDetailsLink={
                item?.contactId ? (
                    <Typography
                        variant="caption"
                        sx={{ cursor: 'pointer', color: '#1F4C5C' }}
                        onClick={() => onClickContactDetails(item?.contactId, 'view')}
                    >
                        {t('InboxPage.viewDetails')}
                    </Typography>
                ) : null
            }
        />
    );
};

FeedbackItem.displayName = 'FeedbackItem';

export default FeedbackItem;
