import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

function get(page, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/social-posting?page=${page}&pageSize=${pageSize}`, requestOptions).then(
        handleResponse,
    );
}

function createOrUpdateSocialPost(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/social-posting`, requestOptions).then(handleResponse);
}

function deleteSocialPost(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/social-posting/${id}`, requestOptions).then(handleResponse);
}

function uploadSocialPostImages(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + user.token,
        },
        body: data,
    };
    return fetch(`${BASE_URL}api/social-posting/upload-image`, requestOptions).then(handleResponse);
}

function uploadSocialPostVideos(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + user.token,
        },
        body: data,
    };
    return fetch(`${BASE_URL}api/social-posting/upload-video`, requestOptions).then(handleResponse);
}

export const socialPostsService = {
    get,
    createOrUpdateSocialPost,
    deleteSocialPost,
    uploadSocialPostImages,
    uploadSocialPostVideos,
};
