import { styled } from '@mui/material/styles';

export const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

export const FiltersWrapper = styled('div')({
    marginBottom: 16,
    display: 'grid',
    gridGap: 8,
    gridTemplateAreas: '"search filters toolbar controls"',
    gridTemplateColumns: '320px max-content 1fr',
    '@media (max-width: 1400px)': {
        gridTemplateAreas: '"search filters controls" "toolbar toolbar toolbar"',
        gridTemplateColumns: '320px max-content 1fr',
    },
    '@media (max-width: 650px)': {
        gridTemplateAreas: '"search search" "filters controls" "toolbar toolbar"',
        gridTemplateColumns: '1fr',
    },
});

export const ToolbarWrapper = styled('div')({
    gridArea: 'toolbar',
    display: 'flex',
    width: 'fit-content',
    '& svg': {
        '& path': {
            fill: 'rgba(31, 76, 92, 1)',
            fillOpacity: 1,
        },
    },
});

export const SearchWrapper = styled('div')({
    minWidth: 150,
    gridArea: 'search',
    '& .MuiFormControl-root': {
        paddingBottom: 0,
    },
    '& input': {
        padding: '8px !important',
    },
    '& .MuiInputLabel-root': {
        transform: 'translate(34px, 8px)',
    },
    '.MuiSvgIcon-root': {
        width: 16,
        height: 16,
        marginRight: 4,
    },
});
