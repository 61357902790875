import { styled } from '@mui/material/styles';
import Card from '@components/Card';
import { LocationIntegrationName } from '@constants/integrations';

const CardAddress = styled('p')({
    fontFamily: 'Rubik, sans-serif',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '12px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: 0,
    marginBottom: 8,
});

const generateAddress = addr => {
    return `${addr.address || ''}, ${addr.city || ''}, ${addr.postalCode || ''}, ${addr.country || ''}`;
};

const LocationCard = ({ location = {}, selectedLocation, setLocation, index, integrationType }) => (
    <Card
        type="radio"
        selected={index === Number(selectedLocation)}
        title={location.name}
        value={selectedLocation}
        index={index}
        setValue={setLocation}
    >
        {integrationType === LocationIntegrationName.CoverManager && (
            <CardAddress>{generateAddress(location)}</CardAddress>
        )}
    </Card>
);

export default LocationCard;
