import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const TablePaginationActions = ({ page, onPageChange, pages }) => {
    const handleFirstPageButtonClick = event => onPageChange(event, 0);

    const handleBackButtonClick = event => onPageChange(event, page - 1);

    const handleNextButtonClick = event => onPageChange(event, page + 1);

    const handleLastPageButtonClick = event => onPageChange(event, pages - 1);

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                <FirstPageIcon sx={{ fontSize: 20 }} />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                <KeyboardArrowLeft sx={{ fontSize: 20 }} />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page === pages - 1 || pages === 1 || pages === 0}
                aria-label="next page"
            >
                <KeyboardArrowRight sx={{ fontSize: 20 }} />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page === pages - 1 || pages === 1 || pages === 0}
                aria-label="last page"
            >
                <LastPageIcon sx={{ fontSize: 20 }} />
            </IconButton>
        </Box>
    );
};

export default TablePaginationActions;
