import { useState, useEffect, useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { FormControlLabel, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Switch } from '@components/Switch';
import { Select } from '@components/Select';
import { Typography } from '@components/Typography';
import { AddIcon, DeleteIcon } from 'assets/images/icons';
import cloneDeep from 'lodash.clonedeep';
import { LocationIntegrationName } from '@constants/integrations';
import { useTranslation } from '@hooks/useTranslation';
import { generateTimeOptions } from '@helpers/dates';

const RowWrapper = styled(Grid)({
    alignItems: 'flex-start',
    minHeight: '40px',
    padding: '4px',
    '& .MuiFormControl-root': {
        paddingBottom: 0,
    },
});

const TimeSelectWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
        paddingTop: '6px',
    },
});

const ALL_DAY = '24:00';

const SELECT_TYPE = {
    FROM: 'from',
    TO: 'to',
};

const initialDaysState = [
    { day: 1, enabled: false, hours: [{ from: '09:00', to: '18:00' }] },
    { day: 2, enabled: false, hours: [{ from: '09:00', to: '18:00' }] },
    { day: 3, enabled: false, hours: [{ from: '09:00', to: '18:00' }] },
    { day: 4, enabled: false, hours: [{ from: '09:00', to: '18:00' }] },
    { day: 5, enabled: false, hours: [{ from: '09:00', to: '18:00' }] },
    { day: 6, enabled: false, hours: [{ from: '09:00', to: '18:00' }] },
    { day: 0, enabled: false, hours: [{ from: '09:00', to: '18:00' }] },
];

const BusinessHoursSelection = ({ data, onChangeHours, withAdditionalTime = false, type = null }) => {
    const { t } = useTranslation();

    const [workingDays, setWorkingDays] = useState(initialDaysState);

    const timeOptions = useMemo(() => [{ value: ALL_DAY, label: t('common.24hours') }, ...generateTimeOptions()], []);

    const DAY_NAMES = {
        0: t('common.sunday'),
        1: t('common.monday'),
        2: t('common.tuesday'),
        3: t('common.wednesday'),
        4: t('common.thursday'),
        5: t('common.friday'),
        6: t('common.saturday'),
    };

    useEffect(() => {
        if (data) {
            const updatedDays = initialDaysState.map(day => {
                const matchingDay = data.find(item => item.day === day.day);
                return matchingDay ? { ...day, ...matchingDay, enabled: true } : { ...day, enabled: false };
            });
            setWorkingDays(updatedDays);
        }
    }, [data]);

    const handleChange = (e, dayIndex, hoursIndex, type) => {
        const newWorkingDays = cloneDeep(workingDays);

        if (type) {
            const timeItem = newWorkingDays[dayIndex].hours[hoursIndex];
            if (timeItem.from === '00:00' && timeItem.to === ALL_DAY) {
                const nextTimeIndex = timeOptions.findIndex(i => i.value === e.target.value) + 1;
                timeItem.from = e.target.value;
                timeItem.to = timeOptions[nextTimeIndex].value;
            } else {
                timeItem[type] = e.target.value;
            }
        } else {
            newWorkingDays[dayIndex].enabled = e.target.checked;
        }

        setWorkingDays(newWorkingDays);
        onChangeHours(
            newWorkingDays
                .filter(day => day.enabled)
                .map(day => {
                    const updatedHours = day.hours.map(i => {
                        if (i.from === ALL_DAY || i.to === ALL_DAY) {
                            return { from: '00:00', to: '24:00' };
                        } else {
                            return i;
                        }
                    });
                    return { ...day, hours: updatedHours };
                }),
        );
    };

    const onAddNewHours = index => {
        const newWorkingDays = cloneDeep(workingDays);
        newWorkingDays[index].hours.push({ from: '09:00', to: '18:00' });
        setWorkingDays(newWorkingDays);
        onChangeHours(newWorkingDays.filter(day => day.enabled));
    };

    const onDeleteNewHours = (dayIndex, hoursIndex) => {
        const newWorkingDays = cloneDeep(workingDays);
        newWorkingDays[dayIndex].hours.splice(hoursIndex, 1);
        setWorkingDays(newWorkingDays);
        onChangeHours(newWorkingDays.filter(day => day.enabled));
    };

    const renderAddButton = useCallback(
        dayIndex => {
            const additionalHoursLimit = workingDays[dayIndex].hours.length > 2;
            if (!withAdditionalTime) return null;
            if (type === LocationIntegrationName.Facebook && additionalHoursLimit) return null;
            return (
                <IconButton sx={{ marginLeft: '10px', padding: '4px' }} onClick={() => onAddNewHours(dayIndex)}>
                    <AddIcon />
                </IconButton>
            );
        },
        [workingDays, withAdditionalTime, type],
    );

    return (
        <Grid container rowSpacing={2}>
            {workingDays.map((currentDay, dayIndex) => (
                <RowWrapper key={currentDay.day} container item spacing={{ xs: 1, md: 1 }} columns={{ xs: 9, md: 16 }}>
                    <Grid
                        item
                        md={3}
                        xs={3}
                        pt="0 !important"
                        sx={{ display: 'flex', alignItems: 'center', height: '40px' }}
                    >
                        <div>
                            <Typography variant="body2" color="textPrimary">
                                {DAY_NAMES[currentDay.day]}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md={4} pt="0 !important">
                        <FormControlLabel
                            sx={{ height: '40px' }}
                            control={
                                <Switch checked={currentDay.enabled} handleChange={e => handleChange(e, dayIndex)} />
                            }
                            label={
                                <Typography variant="body2" color="textPrimary">
                                    {currentDay.enabled ? t('common.open') : t('common.closed')}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid
                        sx={{ width: 'auto !important', flexDirection: 'column' }}
                        container
                        item
                        spacing={{ xs: 1, md: 1 }}
                        columns={{ xs: 8, md: 8 }}
                    >
                        {currentDay.enabled &&
                            currentDay.hours.map((currentHours, hoursIndex) => (
                                <Grid
                                    key={hoursIndex}
                                    item
                                    md={9}
                                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                    pt={hoursIndex === 0 && '0 !important'}
                                >
                                    {currentHours.from === ALL_DAY ? (
                                        <TimeSelectWrapper>
                                            <Select
                                                value={currentHours.from}
                                                onChange={e => handleChange(e, dayIndex, hoursIndex, SELECT_TYPE.FROM)}
                                                options={timeOptions}
                                                style={{ width: '100%' }}
                                                dropdownStyles={{ maxHeight: '300px' }}
                                            />
                                        </TimeSelectWrapper>
                                    ) : currentHours.to === ALL_DAY ? (
                                        <TimeSelectWrapper>
                                            <Select
                                                value={currentHours.to}
                                                onChange={e => handleChange(e, dayIndex, hoursIndex, SELECT_TYPE.TO)}
                                                options={timeOptions}
                                                style={{ width: '100%' }}
                                                dropdownStyles={{ maxHeight: '300px' }}
                                            />
                                        </TimeSelectWrapper>
                                    ) : (
                                        <TimeSelectWrapper>
                                            <Select
                                                value={currentHours.from}
                                                onChange={e => handleChange(e, dayIndex, hoursIndex, SELECT_TYPE.FROM)}
                                                options={timeOptions}
                                                dropdownStyles={{ maxHeight: '300px' }}
                                                style={{ width: '84px' }}
                                            />
                                            <span> — </span>
                                            <Select
                                                value={currentHours.to}
                                                onChange={e => handleChange(e, dayIndex, hoursIndex, SELECT_TYPE.TO)}
                                                options={timeOptions}
                                                dropdownStyles={{ maxHeight: '300px' }}
                                                style={{ width: '84px' }}
                                            />
                                        </TimeSelectWrapper>
                                    )}
                                    {hoursIndex > 0 ? (
                                        <IconButton
                                            sx={{
                                                marginLeft: '10px',
                                                padding: '4px',
                                                '& path': {
                                                    fill: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}
                                            onClick={() => onDeleteNewHours(dayIndex, hoursIndex)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    ) : (
                                        renderAddButton(dayIndex)
                                    )}
                                </Grid>
                            ))}
                    </Grid>
                </RowWrapper>
            ))}
        </Grid>
    );
};

export default BusinessHoursSelection;
