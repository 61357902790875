import { memo, useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from '@hooks/useTranslation';
import useMediaQuery from '@mui/material/useMediaQuery';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Typography } from '@components/Typography';
import BarChart from '@components/Charts/BarChart';
import { POST_CHANNEL } from '@constants/social-posts';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { styled } from '@mui/system';

const PaperCss = styled(Paper)({
    width: '100%',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    marginBottom: '16px',
    '@media print': {
        breakInside: 'avoid',
    },
});

const ChartWrapper = styled('div')({
    '@media print': {
        height: '280px !important',
    },
});

const chartItemOptions = {
    categoryPercentage: 1.0,
    barPercentage: 0.7,
    borderWidth: 0,
    borderRadius: 4,
};

const createChartData = (labels, data) => {
    return {
        labels,
        datasets: [
            {
                label: 'Instagram',
                data: data.map(d => d[POST_CHANNEL.INSTAGRAM]),
                backgroundColor: ['#98CBAD'],
                ...chartItemOptions,
            },
            {
                label: 'Facebook',
                data: data.map(d => d[POST_CHANNEL.FACEBOOK]),
                backgroundColor: ['#416774'],
                ...chartItemOptions,
            },
            {
                label: 'Google',
                data: data.map(d => d[POST_CHANNEL.GOOGLE]),
                backgroundColor: ['#1F4C5C'],
                ...chartItemOptions,
            },
        ],
    };
};

const SocialReachTrend = memo(({ data, language }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('@media (max-width: 1024px)');

    const formattedData = useMemo(
        () =>
            data.map(item => {
                return item.breakdown.reduce((acc, curr) => {
                    acc[curr.platform] = curr.count;
                    return acc;
                }, {});
            }),
        [data],
    );

    const labels = useMemo(() => {
        return data.map(d => format(parseISO(d.date), 'dd MMM', { locale: I18N_DATE_FORMAT[language] }));
    }, [language, data]);

    return (
        <PaperCss elevation={1}>
            <Typography variant="title1" sx={{ marginBottom: '50px' }}>
                {t('AnalyticsSocialPosts.reach')}
            </Typography>
            <TableContainer>
                <ChartWrapper
                    id="chart-wrapper"
                    style={{
                        position: 'relative',
                        height: isMobile ? '400px' : '280px',
                        ...(isMobile && { width: '900px' }),
                    }}
                >
                    {labels.length ? (
                        <BarChart
                            data={createChartData(labels, formattedData)}
                            labels={labels}
                            legends={t('AnalyticsSocialPosts.reach')}
                        />
                    ) : null}
                </ChartWrapper>
            </TableContainer>
        </PaperCss>
    );
});

SocialReachTrend.displayName = 'SocialReachTrend';

export default SocialReachTrend;
