import { styled } from '@mui/material/styles';
import { ArrowDownIcon } from 'assets/images/icons';
import SaveIcon from '@mui/icons-material/Save';
import { DeleteIcon } from 'assets/images/icons';

export const ArrowDown = styled(ArrowDownIcon)({
    width: 4,
    marginLeft: 6,
    '& path': {
        fill: '#416774',
        fillOpacity: 1,
    },
});

export const ArrowUp = styled(ArrowDown)({
    transform: 'rotate(180deg)',
});

const styledIcon = IconComponent =>
    styled(IconComponent)({
        width: 18,
        height: 18,
        marginRight: 8,
        '& path': {
            fill: 'rgba(0, 0, 0, 0.6)',
        },
    });

export const StyledSaveIcon = styledIcon(SaveIcon);
export const StyledDeleteIcon = styledIcon(DeleteIcon);
