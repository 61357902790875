import { styled } from '@mui/material/styles';
import RadioGroup from '@mui/material/RadioGroup';

export const Container = styled('div')({
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: '1fr 1fr',
    '@media (max-width: 600px)': {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'max-content',
        gridGap: '16px',
    },
});

export const StyledRadioGroup = styled(RadioGroup)({
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiFormControlLabel-root': {
        marginRight: '8px',
    },
    '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
    },
});

export const ChannelRadioWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'isActive' && prop !== 'isDisabled',
})(({ isActive, isDisabled }) => ({
    height: 40,
    display: 'flex',
    paddingLeft: 18,
    borderRadius: 4,
    border: `1px solid ${isActive ? '#8FA6AE' : 'rgba(0, 0, 0, 0.12)'}`,
    background: isActive ? '#F7FBF9' : '#fff',
    cursor: isDisabled ? 'default' : 'pointer',
    opacity: isDisabled ? 0.6 : 1,
}));
