import { sentRequestsConstants } from '@constants/sent-requests';
import { sentRequestsService } from '@services';

function request() {
    return { type: sentRequestsConstants.SENT_REQUESTS_FETCH };
}

function success(data) {
    return { type: sentRequestsConstants.SENT_REQUESTS_SUCCESS, data };
}

function failure(message) {
    return { type: sentRequestsConstants.SENT_REQUESTS_ERROR, message };
}

const get = (page = 0, pageSize = 10) => {
    return dispatch => {
        dispatch(request());
        sentRequestsService.get(page, pageSize).then(
            data => dispatch(success(data)),
            () => dispatch(failure()),
        );
    };
};

export const sentRequestsActions = {
    get,
};
