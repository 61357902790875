import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';

export const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 564,
        height: 350,
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
});

export const Content = styled('div')({
    padding: '24px 16px',
    display: 'flex',
    flexDirection: 'column',
});
