import { memo, useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from '@hooks/useTranslation';
import useMediaQuery from '@mui/material/useMediaQuery';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Typography } from '@components/Typography';
import BarChart from '@components/Charts/BarChart';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { styled } from '@mui/system';

const PaperCss = styled(Paper)({
    width: '100%',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    marginBottom: '16px',
    '@media print': {
        breakInside: 'avoid',
    },
});

const ChartWrapper = styled('div')({
    '@media print': {
        height: '280px !important',
    },
});

const chartItemOptions = {
    categoryPercentage: 1.0,
    barPercentage: 0.7,
    borderWidth: 0,
    borderRadius: 4,
};

const createChartData = (labels, data, t) => {
    return {
        labels,
        datasets: [
            {
                label: t('AnalyticsPresence.views'),
                data: data.map(item => item),
                backgroundColor: ['#1F4C5C'],
                ...chartItemOptions,
            },
        ],
    };
};

const PresenceViewsTrend = memo(({ data, language }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('@media (max-width: 1024px)');

    const formattedData = useMemo(() => {
        return data.map(item => item.breakdown[0].views);
    }, [data]);

    const labels = useMemo(() => {
        return data.map(d => format(parseISO(d.date), 'dd MMM', { locale: I18N_DATE_FORMAT[language] }));
    }, [language, data]);

    return (
        <PaperCss elevation={1}>
            <Typography variant="title1" sx={{ marginBottom: '50px' }}>
                {t('AnalyticsPresence.views')}
            </Typography>
            <TableContainer>
                <ChartWrapper
                    id="chart-wrapper"
                    style={{
                        position: 'relative',
                        height: isMobile ? '400px' : '280px',
                        ...(isMobile && { width: '900px' }),
                    }}
                >
                    {labels.length ? (
                        <BarChart
                            data={createChartData(labels, formattedData, t)}
                            labels={labels}
                            legends={t('AnalyticsPresence.views')}
                            optionsProps={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                            }}
                        />
                    ) : null}
                </ChartWrapper>
            </TableContainer>
        </PaperCss>
    );
});

PresenceViewsTrend.displayName = 'PresenceViewsTrend';

export default PresenceViewsTrend;
