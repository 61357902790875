import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import widgetSingle from 'assets/images/widget-single.svg';
import gridWidget from 'assets/images/widget-grid.svg';
import { useTranslation } from '@hooks/useTranslation';

const CardsWrapper = styled('div')({
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(auto-fit, 360px)',
    gridAutoRows: 240,
    '@media (max-width: 768px)': {
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    },
});

const Card = styled('div')({
    background: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
});

const CardContent = styled('div')({
    backgroundColor: '#D9D9D9',
    padding: 24,
    display: 'flex',
    justifyContent: 'center',
    '& svg, img': {
        height: 128,
    },
});

const CardFooter = styled('div')({
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const Listing = ({ onClickCard }) => {
    const { t } = useTranslation();
    return (
        <CardsWrapper>
            <Card>
                <CardContent>
                    <img src={widgetSingle} alt="popup-widfget" />
                </CardContent>
                <CardFooter>
                    <Typography variant="subtitle2" color="textPrimary">
                        {t('ShowcaseWidgets.popup')}
                    </Typography>
                    <Button variant="outlined" onClick={() => onClickCard('popup')}>
                        {t('buttons.preview')}
                    </Button>
                </CardFooter>
            </Card>
            <Card>
                <CardContent>
                    <img src={gridWidget} alt="grid-widfget" />
                </CardContent>
                <CardFooter>
                    <Typography variant="subtitle2" color="textPrimary">
                        {t('ShowcaseWidgets.grid')}
                    </Typography>
                    <Button variant="outlined" onClick={() => onClickCard('grid')}>
                        {t('buttons.preview')}
                    </Button>
                </CardFooter>
            </Card>
        </CardsWrapper>
    );
};

export default Listing;
