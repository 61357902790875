import { authHeader, BASE_URL, handleResponse, handleResponseBlob } from '@helpers/api';

export const reviewsService = {
    getAll,
    markAsViewed,
    checkNewExist,
    exportCSV,
    assignContact,
    unassignContact,
    replyReview,
    deleteReply,
};

function getAll(query) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(query),
    };
    return fetch(`${BASE_URL}api/Reviews/query`, requestOptions).then(handleResponse);
}

function checkNewExist(query) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(query),
    };
    return fetch(`${BASE_URL}api/Reviews/new`, requestOptions).then(handleResponse);
}

function markAsViewed(reviewId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(reviewId),
    };

    return fetch(`${BASE_URL}api/Reviews/viewed`, requestOptions).then(handleResponse);
}

function exportCSV(query) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(query),
    };
    return fetch(`${BASE_URL}api/export/reviews`, requestOptions).then(handleResponseBlob);
}

function assignContact(data, reviewId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/reviews/${reviewId}:assign-contact`, requestOptions).then(handleResponse);
}

function unassignContact(reviewId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/reviews/${reviewId}:unassign-contact`, requestOptions).then(handleResponse);
}

function replyReview(data, reviewId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/reviews/${reviewId}/replies`, requestOptions).then(handleResponse);
}

function deleteReply(reviewId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/reviews/${reviewId}/replies`, requestOptions).then(handleResponse);
}
