import { styled } from '@mui/system';
import Popper from '@mui/material/Popper';
import { Typography } from '@components/Typography';
import { MEDIA_STATUS } from '@constants/profiles-media';

export const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
    },
});

export const Content = styled('div')({
    height: 'calc(var(--100vh) - 168px)',
    overflowY: 'auto',
    '@media (max-width: 1024px)': {
        height: 'calc(var(--100vh) - 188px)',
    },
});

export const FiltersWrapper = styled('div')({
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    '@media print': {
        display: 'none',
    },
});

export const Badge = styled(Typography, {
    shouldForwardProp: prop => prop !== 'type',
})(({ type }) => ({
    padding: '2px 6px',
    backgroundColor: type === MEDIA_STATUS.NEW ? 'rgba(11, 141, 0, 0.1)' : 'rgba(176, 0, 32, 0.1)',
    color: type === MEDIA_STATUS.NEW ? '#0B8D00' : '#B00020',
}));

export const StyledPopper = styled(Popper)({
    boxShadow:
        'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
    zIndex: 3,
    background: '#fff',
    padding: '8px 0',
    borderRadius: 8,
    '.MuiMenuItem-root': {
        padding: '8px 16px',
        fontSize: '14px',
        lineHeight: '16px',
        width: '100%',
        justifyContent: 'flex-start',
    },
});
