import { useState, useEffect, memo } from 'react';
import InfoSnackbar from '@components/InfoSnackbar';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { Trans } from 'react-i18next';
import { useWebPushNotifications } from '@hooks/useWebPushNotifications';
import { PERMISSION } from '@constants/browser';

const SubscribeToMessagesSnackbar = memo(() => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const { isSupported, permission, isSubscribed, requestPermissions } = useWebPushNotifications();

    useEffect(() => {
        const hideInboxSubscribe = JSON.parse(localStorage.getItem('hideInboxSubscribe'));
        if (isSupported && permission === PERMISSION.DEFAULT && !isSubscribed && !hideInboxSubscribe) {
            setIsOpen(true);
        }
    }, [isSupported, permission, isSubscribed]);

    const handleClose = (_, reason) => {
        if (reason === 'clickaway') return;
        setIsOpen(false);
        // if true - don't show snackbar again
        localStorage.setItem('hideInboxSubscribe', true);
    };

    const onSubscribe = async () => {
        requestPermissions();
        setIsOpen(false);
    };

    return (
        <InfoSnackbar
            isOpen={isOpen}
            handleClose={handleClose}
            message={
                <Typography variant="body2" sx={{ color: '#416774', textAlign: 'center' }}>
                    <Trans t={t} i18nKey="SettingsProfile.pushMessagesSnackbar">
                        <b
                            style={{ textDecoration: 'underline', cursor: 'pointer', textTransform: 'uppercase' }}
                            onClick={onSubscribe}
                        >
                            {{ enable: t('buttons.enable') }}
                        </b>
                    </Trans>
                </Typography>
            }
        />
    );
});

SubscribeToMessagesSnackbar.displayName = 'SubscribeToMessagesSnackbar';

export default SubscribeToMessagesSnackbar;
