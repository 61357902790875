import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { GoogleIcon } from 'assets/images/integrations';
import { useTranslation } from '@hooks/useTranslation';
import { Footer, Header } from './styles';

const ViewInit = ({ onImportFromGoogleClick, onAddManuallyClick }) => {
    const { t } = useTranslation();

    return (
        <>
            <Header>
                <Typography variant="h6">{t('OnboardingPage.titleSecond')}</Typography>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '4px' }}>
                    {t('OnboardingPage.subtitleSecond')}
                </Typography>
            </Header>
            <Footer isNotMain>
                <Button type="submit" variant="contained" onClick={onImportFromGoogleClick} startIcon={<GoogleIcon />}>
                    {t('buttons.importFromGoogle')}
                </Button>
                <Typography
                    variant="caption"
                    sx={{
                        margin: '0 12px',
                        '@media (max-width: 600px)': {
                            margin: '8px 0',
                        },
                    }}
                >
                    {t('common.or')}
                </Typography>
                <Button type="submit" variant="outlined" onClick={onAddManuallyClick}>
                    {t('buttons.addManually')}
                </Button>
            </Footer>
        </>
    );
};

export default ViewInit;
