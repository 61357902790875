import { useMemo, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';

const StyledVisibility = styled(Visibility)({
    fontSize: '16px',
});

const StyledVisibilityOff = styled(VisibilityOff)({
    fontSize: '16px',
});

export const CssFormControl = styled(FormControl)({
    paddingBottom: 16,
    width: '100%',
    '.MuiFormLabel-asterisk': {
        color: '#B00020',
    },
});

export const CssInputLabel = styled(InputLabel, { shouldForwardProp: prop => prop !== 'isEmpty' })(({ isEmpty }) => ({
    fontSize: '0.875rem',
    lineHeight: '1rem',
    letterSpacing: '0.5px',
    transform: 'translate(12px, 12px) scale(1)',
    fontFamily: 'Rubik, sans-serif',
    '&.Mui-focused': {
        color: '#1F4C5C',
    },
    '&.Mui-error': {
        color: '#B00020',
    },
    '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) !important',
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '0.38px',
    },
    '&.MuiInputLabel-root:not(.Mui-focused) ~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend': {
        maxWidth: isEmpty && 0,
        padding: isEmpty && 0,
    },
}));

export const CssOutlinedInput = styled(OutlinedInput, {
    shouldForwardProp: prop => prop !== 'withExtension',
})(({ withExtension }) => ({
    '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#1F4C5C',
        },
    },
    '&.Mui-error': {
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#B00020',
        },
    },
    '&.MuiInputBase-adornedStart': {
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.6)',
        '& input': {
            paddingLeft: 0,
            lineHeight: '15px',
        },
        '& fieldset legend': {
            padding: '0 10px',
        },
        '@media (max-width: 1024px)': {
            '& fieldset legend': {
                padding: '0 5px',
            },
        },
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline ': {
        borderColor: 'rgba(0, 0, 0, 0.12)',
    },
    input: {
        padding: '12px',
        height: '1rem',
        lineHeight: '16px',
        fontSize: '14px',
        fontFamily: 'Rubik, sans-serif',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    fieldset: {
        borderRadius: 4,
        borderColor: 'rgba(0, 0, 0, 0.12)',
        legend: {
            letterSpacing: '0.6px',
        },
    },
    ...(withExtension
        ? {
              '&.MuiInputBase-multiline': {
                  paddingBottom: '38px !important',
              },
          }
        : {}),
    '& .MuiSelect-icon': {
        color: 'rgba(0, 0, 0, 0.88)',
    },
    '& .MuiSelect-select': {
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 12,
        boxSizing: 'border-box',
        fontSize: '0.875rem',
        fontFamily: 'Rubik, sans-serif',
        lineHeight: '16px',
    },
    '& textarea': {
        lineHeight: '16px',
        fontSize: '14px',
        fontFamily: 'Rubik,sans-serif',
    },
}));

export const CssFormHelperText = styled(FormHelperText)(({ error, maxLength }) => ({
    position: 'absolute',
    bottom: -3,
    left: 0,
    margin: '0 12px',
    fontFamily: 'Rubik, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.4px',
    '&.Mui-error': {
        color: '#B00020',
    },
    '@media (max-width: 380px)': {
        bottom: error && maxLength && -12,
    },
}));

const CssCharCounter = styled(Typography)({
    position: 'absolute',
    bottom: 33.5,
    right: 14,
});

export const CssFooter = styled('div')(({ error, maxLength }) => ({
    '@media (max-width: 380px)': {
        height: error && maxLength && '20px',
        position: error && maxLength && 'relative',
    },
}));

export const CssInputAdornment = styled(InputAdornment)({
    marginLeft: 0,
    marginRight: 2,
});

const separateTopLevelProperties = obj => {
    const topLevelProps = {};
    const nestedProps = {};

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                nestedProps[key] = obj[key];
            } else {
                topLevelProps[key] = obj[key];
            }
        }
    }
    return { topLevelProps, nestedProps };
};

export const Input = ({
    inputRef,
    type = 'text',
    id,
    sx,
    label,
    className,
    fullWidth,
    multiline,
    minRows,
    maxRows,
    autoFocus,
    inputProps,
    required,
    disabled,
    error,
    helperText,
    onMouseLeave,
    onMouseEnter,
    onAddonClick,
    addonWithoutWrapper = false,
    addonComponent,
    startAdornment,
    placeholder = null,
    autoComplete = 'new-password',
    maxLength = null,
    toolbar = null,
}) => {
    const [isPasswordVisible, setPasswordVisibility] = useState(false);
    const [isShrink, setIsShrink] = useState(false);
    const text = inputProps?.value || '';

    const getInputType = () => {
        if (type === 'password') {
            return isPasswordVisible ? 'text' : 'password';
        }
        return type;
    };

    const sxProps = useMemo(() => separateTopLevelProperties(sx), [sx]);

    return (
        <div style={{ position: 'relative', width: '100%', ...sxProps.topLevelProps }}>
            <CssFormControl
                sx={sxProps.nestedProps}
                required={required}
                variant="outlined"
                className={className}
                fullWidth={fullWidth}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <CssInputLabel error={error} shrink={isShrink || !!text} isEmpty={!text}>
                    {label}
                </CssInputLabel>
                <CssOutlinedInput
                    inputRef={inputRef}
                    id={id}
                    autoComplete={autoComplete}
                    autoFocus={autoFocus}
                    error={error}
                    label={label}
                    placeholder={placeholder}
                    disabled={disabled}
                    multiline={multiline}
                    minRows={minRows}
                    maxRows={maxRows}
                    inputProps={{
                        ...inputProps,
                        type: getInputType(),
                        onFocus: () => setIsShrink(true),
                        onBlur: () => {
                            setIsShrink(false);
                            if (inputProps.onBlur) {
                                inputProps?.onBlur();
                            }
                        },
                    }}
                    endAdornment={
                        type === 'password' ? (
                            <CssInputAdornment position="end">
                                <IconButton onClick={() => setPasswordVisibility(!isPasswordVisible)} edge="end">
                                    {isPasswordVisible ? <StyledVisibility /> : <StyledVisibilityOff />}
                                </IconButton>
                            </CssInputAdornment>
                        ) : addonComponent ? (
                            !addonWithoutWrapper ? (
                                <CssInputAdornment position="end">
                                    <IconButton onClick={() => onAddonClick?.()} edge="end">
                                        {addonComponent}
                                    </IconButton>
                                </CssInputAdornment>
                            ) : (
                                addonComponent
                            )
                        ) : null
                    }
                    startAdornment={startAdornment}
                    withExtension={!!maxLength || toolbar}
                />

                {maxLength ? (
                    <CssCharCounter
                        variant="caption"
                        sx={{
                            color:
                                text.length > maxLength
                                    ? '#B00020'
                                    : text.length > maxLength - 20
                                    ? '#ff9900'
                                    : 'rgba(0, 0, 0, 0.6)',
                        }}
                    >
                        {text.length}
                        <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>/{maxLength}</span>
                    </CssCharCounter>
                ) : null}
                <CssFooter error={error} maxLength={maxLength}>
                    <CssFormHelperText error={error} maxLength={maxLength}>
                        {helperText}
                    </CssFormHelperText>
                </CssFooter>
            </CssFormControl>
            {toolbar ? toolbar : null}
        </div>
    );
};
