import SingleLevelItem from './SingleLevelItem';
import MultiLevelItem from './MultiLevelItem';

const hasChildren = item => {
    const { children } = item;
    if (children === undefined) {
        return false;
    }
    if (children.constructor !== Array) {
        return false;
    }
    if (children.length === 0) {
        return false;
    }
    return true;
};

const MenuItem = ({ exact, item, callback, disabled }) => {
    const Component = hasChildren(item) && !disabled ? MultiLevelItem : SingleLevelItem;
    return <Component exact={exact} item={item} callback={callback} disabled={disabled} />;
};

export default MenuItem;
