import { googleConstants } from '@constants/google';

const initialState = {
    data: [],
    listing: {},
    searchedCategories: [],
    loading: true,
    loadingListing: true,
    error: '',
};

export function google(state = initialState, action) {
    switch (action.type) {
        case googleConstants.GOOGLE_LOCATIONS_REQUEST:
            return { ...state, loading: true, error: '' };
        case googleConstants.GOOGLE_LOCATIONS_SUCCESS:
            return { ...state, loading: false, data: action.data, error: '' };
        case googleConstants.GOOGLE_LISTING_PROFILE_REQUEST:
            return { ...state, loadingListing: true, error: '' };
        case googleConstants.GOOGLE_LISTING_PROFILE_SUCCESS:
            return { ...state, loadingListing: false, listing: action.data, error: '' };
        case googleConstants.GOOGLE_CATEGORIES_SUCCESS:
            return { ...state, loading: false, searchedCategories: action.data, error: '' };
        case googleConstants.GOOGLE_CATEGORIES_RESET:
            return { ...state, searchedCategories: [] };
        case googleConstants.GOOGLE_LOCATIONS_ERROR:
        case googleConstants.GOOGLE_LISTING_PROFILE_ERROR:
            return { ...state, error: action.message };
        default:
            return state;
    }
}
