import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { ModalBase } from '@components/Modal';
import { Typography } from '@components/Typography';
import { Table } from '@components/Table';
import { useTranslation } from '@hooks/useTranslation';
import { CHANNEL } from '@constants/channels';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 'calc(100% - 32px)',
        height: 'calc(var(--100vh) - 32px)',
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

const GetContactsColumnNames = type => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: <Typography variant="subtitle2">{t('SendInvites.id')}</Typography>,
        },
        {
            key: 1,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.firstName')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.lastName')}
                </Typography>
            ),
        },
        type === CHANNEL.EMAIL
            ? {
                  key: 3,
                  component: (
                      <Typography align="right" variant="subtitle2">
                          {t('common.email')}
                      </Typography>
                  ),
              }
            : {
                  key: 3,
                  component: (
                      <Typography align="right" variant="subtitle2">
                          {t('common.phoneNumber')}
                      </Typography>
                  ),
              },
        {
            key: 4,
            component: <Typography align="left" variant="subtitle2"></Typography>,
        },
    ];
};

const getContactsRows = (type, contacts, onDeleteContact) => {
    const isEmail = type === CHANNEL.EMAIL;
    return contacts.map(({ First_Name, Last_Name, Mobile_Number, Email, phoneValid, emailValid }, index) => {
        const phoneColor = phoneValid || !Mobile_Number ? 'textPrimary' : 'error';
        const emailColor = emailValid || !Email ? 'textPrimary' : 'error';
        const resultColor = !phoneValid && !emailValid ? 'error' : 'textPrimary';
        return [
            {
                component: (
                    <Typography variant="subtitle2" align="left" sx={{ maxWidth: '56px' }}>
                        {index + 1}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography
                        color={resultColor}
                        variant="body2"
                        align="left"
                        style={{ maxWidth: '222px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {First_Name || '--'}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography
                        color={resultColor}
                        variant="body2"
                        align="left"
                        style={{ maxWidth: '222px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {Last_Name || '--'}
                    </Typography>
                ),
            },
            ...(!isEmail
                ? [
                      {
                          component: (
                              <Typography color={phoneColor} variant="body2" align="right">
                                  {Mobile_Number || '--'}
                              </Typography>
                          ),
                      },
                  ]
                : [
                      {
                          component: (
                              <Typography color={emailColor} variant="body2" align="right">
                                  {Email || '--'}
                              </Typography>
                          ),
                      },
                  ]),
            {
                component: (
                    <IconButton
                        sx={{ color: !phoneValid && !emailValid ? '#B00020' : 'rgba(0, 0, 0, 0.6)' }}
                        onClick={() => onDeleteContact(index)}
                    >
                        <DeleteIcon />
                    </IconButton>
                ),
            },
        ];
    });
};

const DataVerificationModal = ({ data, setData, channelType, isOpen, onClose }) => {
    const [isValid, setIsValid] = useState(false);
    const { t } = useTranslation();
    const firstRender = useRef(true);

    useEffect(() => {
        if (data.list.length > 0 && firstRender.current) {
            setIsValid(validateList(data.list));
            firstRender.current = false;
        }
    }, [data]);

    const validateList = people => {
        return channelType === CHANNEL.EMAIL
            ? people.every(person => person.emailValid)
            : people.every(person => person.phoneValid);
    };

    const onDeleteContact = newValue => {
        const contactsCopy = [...data.list];
        contactsCopy.splice(newValue, 1);
        setData(prev => ({ ...prev, list: contactsCopy }));
        setIsValid(validateList(contactsCopy));
    };

    const handleClose = () => {
        setData(prev => ({ ...prev, list: [] }));
        onClose();
    };

    const handleSubmit = () => {
        setData(prev => ({ ...prev, list: [], selected: prev.list }));
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            handleClose={handleClose}
            title={t('SendInvites.dataVerification')}
            onPrimaryAction={handleSubmit}
            primaryActionText={t('buttons.import', { amount: data.length })}
            primaryActionDisabled={!isValid}
            onSecondaryAction={handleClose}
            secondaryActionText={t('buttons.cancel')}
        >
            <Typography
                variant="caption"
                color="textSecondary"
                sx={{ marginBottom: '24px', display: 'block', color: 'rgba(0, 0, 0, 0.6)' }}
            >
                {t('SendInvites.dataVerificationDescription')}
            </Typography>
            <TableContainer>
                <Table
                    columnNames={GetContactsColumnNames(channelType)}
                    rows={getContactsRows(channelType, data.list, onDeleteContact)}
                />
            </TableContainer>
        </Modal>
    );
};

export default DataVerificationModal;
