import DoneIcon from '@mui/icons-material/Done';
import { Typography } from '@components/Typography';
import { ExternalLink } from '@components/Link';
import { useTranslation } from '@hooks/useTranslation';
import { HelpCenterIcon } from 'assets/images/sidebar';

const Card = ({ isPassed, title, description, link, style }) => {
    const { t } = useTranslation();

    return (
        <div
            style={{ display: 'grid', gridGap: '18px', gridTemplateColumns: '20px 1fr', ...style }}
            className="home-onboarding-item"
        >
            <div
                style={{
                    height: 20,
                    width: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: isPassed ? '#1F4C5C' : '#fff',
                    borderRadius: '50%',
                    border: isPassed ? '1px solid #1F4C5C' : '1px solid rgba(0, 0, 0, 0.12)',
                    marginTop: '2px',
                }}
            >
                {isPassed ? <DoneIcon sx={{ width: 14, height: 14, color: '#fff' }} /> : null}
            </div>
            <div>
                <Typography
                    variant="subtitle2"
                    sx={{ color: isPassed ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.87)', marginBottom: '8px' }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="caption"
                    sx={{
                        color: isPassed ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.60)',
                        display: 'block',
                        marginBottom: '8px',
                    }}
                >
                    {description}
                </Typography>
                {!isPassed ? (
                    <Typography variant="caption">
                        <ExternalLink
                            href={link}
                            underline="none"
                            sx={{ display: 'flex', '& svg path': { fill: '#1F4C5C', fillOpacity: '1' } }}
                        >
                            <HelpCenterIcon height={14} width={14} style={{ marginRight: '8px' }} />
                            {t('common.learnMore')}
                        </ExternalLink>
                    </Typography>
                ) : null}
            </div>
        </div>
    );
};

export default Card;
