import IconButton from '@mui/material/IconButton';
import { CloseMenuIcon, BurgerMenuIcon } from 'assets/images/sidebar';
import { IconWrapper } from './styles';

const BurgerButton = ({ isExpanded, setExpanded }) => {
    return isExpanded ? (
        <IconButton onClick={() => setExpanded(false)}>
            <IconWrapper>
                <CloseMenuIcon height={14} />
            </IconWrapper>
        </IconButton>
    ) : (
        <IconButton onClick={() => setExpanded(true)}>
            <IconWrapper>
                <BurgerMenuIcon height={14} />
            </IconWrapper>
        </IconButton>
    );
};

export default BurgerButton;
