import { conversationsConstants } from '@constants/conversations';

const initialState = {
    data: [],
    loading: true,
    hasMore: false,
    requiresRefetch: false,
    counters: {},
    archived: 0,
    archivedLoading: true,
};

export function conversations(state = initialState, action) {
    switch (action.type) {
        case conversationsConstants.CONVERSATIONS_FETCH:
            return { ...state, loading: true };
        case conversationsConstants.CONVERSATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                hasMore: action.payload.hasMore,
                data: action.payload.items,
                requiresRefetch: false,
            };
        case conversationsConstants.CONVERSATIONS_LOAD_MORE_SUCCESS:
            return {
                ...state,
                data: [...state.data, ...action.payload.items],
                hasMore: action.payload.hasMore,
                requiresRefetch: false,
            };
        case conversationsConstants.CONVERSATIONS_COUNTERS_SUCCESS:
            return { ...state, counters: action.payload };
        case conversationsConstants.CONVERSATIONS_ERROR:
            return { ...state, loading: false };
        case conversationsConstants.CONVERSATIONS_UPDATE:
            return { ...state, data: action.data };
        case conversationsConstants.CONVERSATIONS_REQUIRES_REFETCH:
            return { ...state, requiresRefetch: action.data };
        case conversationsConstants.CONVERSATIONS_ARCHIVED_SUCCESS:
            return { ...state, archived: action.payload.count, archivedLoading: false };
        case conversationsConstants.CONVERSATIONS_RESET:
            return initialState;
        default:
            return state;
    }
}
