import { channelsConstants } from '@constants/channels';
import { channelsService } from '@services';

function request() {
    return { type: channelsConstants.CHANNELS_FETCH };
}

function success(data) {
    return { type: channelsConstants.CHANNELS_SUCCESS, data };
}

function failure(message) {
    return { type: channelsConstants.CHANNELS_ERROR, message };
}

const get = () => {
    return dispatch => {
        dispatch(request());
        channelsService.get().then(
            data => dispatch(success(data)),
            () => dispatch(failure()),
        );
    };
};

function update(data) {
    return { type: channelsConstants.CHANNELS_UPDATE, data };
}

export const channelsActions = {
    get,
    update,
};
