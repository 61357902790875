import { useMemo, memo } from 'react';
import { Bar } from 'react-chartjs-2';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@components/Typography';
import { LANGUAGE } from '@constants/language';
import { formatPercentNumber } from '@helpers/formatNumbers';
import { useTranslation } from '@hooks/useTranslation';

const Container = styled('div')({
    display: 'flex',
    '@media (max-width: 600px)': {
        display: 'block',
        width: '100%',
        position: 'relative',
        marginTop: '16px',
    },
});

const TextWrapper = styled('div')({
    paddingTop: '14px',
    '@media (max-width: 600px)': {
        width: '100%',
        position: 'absolute',
        paddingTop: 0,
        left: '6px',
        top: '5px',
    },
});

const Label = styled(Typography)({
    display: 'block',
    marginBottom: '6px',
    lineHeight: '16px !important',
    color: 'rgba(0, 0, 0, 0.87)',
    '&:not(:first-child)': {
        marginBottom: '5px',
    },
    '@media (max-width: 600px)': {
        marginBottom: '20px',
        '&:not(:first-child)': {
            marginBottom: '20px',
        },
    },
    '@media (max-width: 345px)': {
        fontSize: '0.65rem !important',
    },
});

const createChartData = (data, maxValue, isLoading) => {
    return {
        labels: ['', '', '', '', ''],
        datasets: [
            {
                label: '',
                data:
                    data.surveysSent && !isLoading
                        ? [
                              data.surveysSent,
                              data.linksClicked,
                              data.responsesReceived,
                              data.leaveReviewClicked,
                              data.reviewsReceived,
                          ]
                        : [],
                backgroundColor: ['#1F4C5C'],
                maxBarThickness: 16,
                categoryPercentage: 1.0,
                barPercentage: 1.0,
                borderWidth: 0,
            },
            {
                label: 'qwe',
                data: data.surveysSent || isLoading ? Array(5).fill(maxValue) : Array(5).fill(10),
                backgroundColor: ['#E4EAEB'],
                hoverBackgroundColor: ['#E4EAEB'],
                maxBarThickness: 16,
                categoryPercentage: 1.0,
                barPercentage: 1.0,
                borderWidth: 0,
            },
        ],
    };
};

const createChartOptions = (labelsMap, maxValue) => {
    return {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 0,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },

            tooltip: {
                displayColors: false,
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 1)',
                filter: function (tooltipItem) {
                    return tooltipItem.datasetIndex === 0;
                },
                callbacks: {
                    label: function (context) {
                        return `${labelsMap[context.dataIndex].label} - ${
                            context.formattedValue
                        } (${formatPercentNumber(labelsMap[context.dataIndex].percent)})`;
                    },
                },
            },
        },
        scales: {
            x: {
                display: false,
                stacked: true,
                max: maxValue || 10,
                grid: {
                    tickLength: 0,
                },
            },
            y: {
                grid: {
                    drawBorder: false,
                    display: false,
                    tickLength: 0,
                },
                stacked: true,
                max: maxValue > 10 ? maxValue : 10,
            },
        },
    };
};

const ConversionBarChart = memo(({ data, isLoading, language }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('@media (max-width: 600px)');

    const labelsMap = useMemo(
        () => [
            {
                label: t('AnalyticsConversion.surveysSent'),
                value: data.surveysSent,
                percent: data.surveysSentPercent,
            },
            {
                label: t('AnalyticsConversion.linksClicked'),
                value: data.linksClicked,
                percent: data.linksClickedPercent,
            },
            {
                label: t('AnalyticsConversion.responsesReceived'),
                value: data.responsesReceived,
                percent: data.responsesReceivedPercent,
            },
            {
                label: t('AnalyticsConversion.leaveReviewClicked'),
                value: data.leaveReviewClicked,
                percent: data.leaveReviewClickedPercent,
            },
            {
                label: t('AnalyticsConversion.reviewsReceived'),
                value: data.reviewsReceived,
                percent: data.reviewsReceivedPercent,
            },
        ],
        [t, data],
    );

    const maxValue = useMemo(() => {
        return Math.max(
            ...[
                data.surveysSent,
                data.linksClicked,
                data.responsesReceived,
                data.leaveReviewClicked,
                data.reviewsReceived,
            ],
        );
    }, [data]);

    const datasets = useMemo(() => createChartData(data, maxValue, isLoading), [data, maxValue, isLoading]);
    const options = useMemo(() => createChartOptions(labelsMap, maxValue), [maxValue, labelsMap]);

    const languageStyle = {
        [LANGUAGE.ENGLISH]: { barWrapper: 200, textWrapper: 190 },
        [LANGUAGE.UKRAINIAN]: { barWrapper: 260, textWrapper: 260 },
        [LANGUAGE.PORTUGUESE]: { barWrapper: 260, textWrapper: 260 },
        [LANGUAGE.SPANISH]: { barWrapper: 260, textWrapper: 260 },
    };

    return (
        <Container>
            <TextWrapper style={{ width: `${languageStyle[language].textWrapper}px` }}>
                {labelsMap.map(({ label, value, percent }) => (
                    <Label key={label} variant="caption">
                        {label} - {value} ({formatPercentNumber(percent)})
                    </Label>
                ))}
            </TextWrapper>

            <div
                style={{
                    position: 'relative',
                    width: isMobile ? '100%' : `calc(100% - ${languageStyle[language].barWrapper}px)`,
                    height: isMobile ? '200px' : '130px',
                }}
            >
                <Bar data={datasets} options={options} />
            </div>
        </Container>
    );
});

ConversionBarChart.displayName = 'ConversionBarChart';

export default ConversionBarChart;
