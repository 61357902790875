import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const micrositesService = {
    getAll,
    getMicrosite,
    createEmptyMicrosite,
    deleteMicrosite,
    updateMicrosite,
    uploadMicrositeImage,
    getRestrictedNames,
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/microsites`, requestOptions).then(handleResponse);
}

function getMicrosite(externalId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/microsites/${externalId}`, requestOptions).then(handleResponse);
}

function createEmptyMicrosite() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/microsites/empty`, requestOptions).then(handleResponse);
}

function updateMicrosite(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${BASE_URL}api/microsites`, requestOptions).then(handleResponse);
}

function deleteMicrosite(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/microsites/${id}`, requestOptions).then(handleResponse);
}

function uploadMicrositeImage(data, micrositeId) {
    const user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + user.token,
        },
        body: data,
    };
    return fetch(`${BASE_URL}api/microsites/${micrositeId}/upload-logo`, requestOptions).then(handleResponse);
}

function getRestrictedNames() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${BASE_URL}api/microsites/restricted-names`, requestOptions).then(handleResponse);
}
