import contactsHelper from './contacts';

export const validateBulkData = (data, locale, companies) => {
    if (!data || data.length === 0) return null;
    const list = [];

    data.forEach(element => {
        element = convertToPluspointModel(element);
        contactsHelper.checkPhoneNumbers(element, locale, 'phoneNumber');
        contactsHelper.checkEmails(element, 'email');
        list.push(element);
    });
    const filteredEmptyValues = list.filter(item => item.phoneNumber || item.email);
    return assigneCompanies(contactsHelper.checkUniqueContacts(filteredEmptyValues, 'email', 'phoneNumber'), companies);
};

const convertToPluspointModel = contact => {
    const { phoneValid, emailValid, Mobile_Number, Email, First_Name, Last_Name, companies, Instagram, Facebook } =
        contact;
    return {
        phoneValid,
        emailValid,
        phoneNumber: Mobile_Number,
        email: Email,
        firstName: First_Name,
        lastName: Last_Name,
        companies: companies,
        instagramProfile: Instagram,
        facebookProfile: Facebook,
    };
};

export const isDuplicate = (contact, duplicate) => {
    return (
        (contact.phoneNumber && duplicate.phoneNumber === contact.phoneNumber) ||
        (!contact.phoneNumber && !duplicate.phoneNumber && contact.email && duplicate.email === contact.email)
    );
};

export const findDuplicate = (contact, duplicates) => {
    const duplicatedContacts = duplicates?.filter(duplicate => isDuplicate(contact, duplicate));
    let duplicate = duplicatedContacts[0];
    if (duplicatedContacts.length > 1) {
        duplicate = duplicatedContacts
            .filter(duplicate => contact.email && duplicate.email === contact.email)
            .sort((a, b) => (a.createdDate > b.createdDate ? 0 : 1))[0];
    }
    return duplicate;
};

export const removeUnchangedContacts = (contacts, duplicates) => {
    const contactsForUpdate = [];
    contacts = contacts.filter(contact => {
        const duplicate = findDuplicate(contact, duplicates);
        let isChanged = false;
        if (duplicate) {
            isChanged =
                (contact.firstName && contact.firstName !== '-' && duplicate.firstName !== contact.firstName) ||
                (contact.lastName && contact.lastName !== '-' && duplicate.lastName !== contact.lastName) ||
                (contact.instagramProfile && duplicate.instagramProfile !== contact.instagramProfile) ||
                (contact.facebookProfile && duplicate.facebookProfile !== contact.facebookProfile) ||
                (contact.email && duplicate.email !== contact.email);
        } else {
            isChanged = true;
        }
        if (isChanged && duplicate) {
            contactsForUpdate.push(duplicate);
        }
        return isChanged;
    });
    return { contacts, duplicates: contactsForUpdate };
};

const assigneCompanies = (contacts, companies) => {
    return contacts.map(contact => {
        return {
            ...contact,
            companies: companies.map(company => company.companyId),
        };
    });
};
