import LanguageSelector from '@components/PreviewLanguageSelector';
import { BUTTONS_MAP } from '@constants/surveys';
import { Container, Header, Footer, SubmitButton, HeaderTitle, FooterTitle } from './styles';

const Positive = ({ t, satisfactionSurveyEnabled, company, reviewPlatforms, language }) => {
    return (
        <Container>
            {satisfactionSurveyEnabled ? (
                <Header>
                    <LanguageSelector selectedLanguage={language} />
                    <div>
                        <HeaderTitle>{t.positiveTitle}</HeaderTitle>
                        <p style={{ color: '#1f4c5c' }}>{t.positiveMessage}</p>
                    </div>
                </Header>
            ) : (
                <Header>
                    <LanguageSelector selectedLanguage={language} />
                    <div>
                        <HeaderTitle>{t.headerTitle}</HeaderTitle>
                        <p style={{ color: '#1f4c5c' }}>
                            <span style={{ display: 'block' }}>
                                <b>{company.locationName}</b>
                            </span>
                            {company.hasBusinessAddress ? `${company.addressLine1}, ${company.city}` : ''}
                        </p>
                    </div>
                </Header>
            )}
            <Footer>
                <div style={{ maxWidth: 305, width: '100%', alignSelf: 'center', margin: '0 auto' }}>
                    <FooterTitle>{t.leaveReview}</FooterTitle>
                    {reviewPlatforms.map(platform => (
                        <SubmitButton variant="contained" key={platform}>
                            <img
                                style={{ height: '20px', width: '20px', marginRight: 16 }}
                                src={BUTTONS_MAP[platform].src}
                                alt="logo"
                            />
                            <span>{BUTTONS_MAP[platform].name}</span>
                        </SubmitButton>
                    ))}
                </div>
            </Footer>
        </Container>
    );
};

export default Positive;
