import { store } from 'store';
import { history } from '@helpers/history';
import { userActions, accountActions } from '@actions';
import { accountConstants } from '@constants/account';

export const BASE_URL =
    process.env.NODE_ENV !== 'development' ? `${window.location.origin}/` : process.env.REACT_APP_API_URL;

export const authHeader = () => {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token };
    } else {
        return {};
    }
};

const authRoutes = ['/login', '/signup', '/reset-password/', '/forgot-password', '/verify-email/', '/error'];

const handleErrors = response => {
    if (response.status === 401) {
        const isAuthUrl = authRoutes.some(url => window.location.pathname.startsWith(url));
        if (!isAuthUrl) {
            store.dispatch(userActions.logout());
        }
    }
    const isAuthenticated = store.getState().authentication.user;
    if (response.status === 402 && accountConstants.BILLING_EXPIRED === 0 && isAuthenticated) {
        accountConstants.BILLING_EXPIRED = 1;
        store.dispatch(accountActions.get());
    }

    if (response.status === 500) {
        history.push('/error');
    }
};

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            handleErrors(response);
            let error;
            if (data && (data.errorCode || data.ErrorCode)) {
                error = data;
                return Promise.reject(error);
            }
            error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

export function handleResponseBlob(response) {
    return response.blob().then(blobObject => {
        const blob = window.URL.createObjectURL(blobObject);
        if (!response.ok) {
            handleErrors(response);
            return Promise.reject("Couldn't download image!");
        }
        return blob;
    });
}
