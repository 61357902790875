import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import MuiButton from '@mui/material/Button';

const StyledButton = styled(MuiButton)(({ variant, disabled }) => {
    return {
        fontFamily: 'Rubik, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '0.875rem', //14px
        lineHeight: '16px',
        letterSpacing: '0.1px',
        borderRadius: '4px',
        height: 32,
        textTransform: 'none',
        padding: '8px 12px',
        ...(variant === 'contained' && {
            backgroundColor: '#1F4C5C',
            color: '#fff',
            '&:hover': {
                backgroundColor: '#173C4A',
            },
        }),
        ...(variant === 'outlined' && {
            border: '1px solid rgba(0, 0, 0, 0.12)',
            color: '#1F4C5C',
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                border: '1px solid rgba(0, 0, 0, 0.12)',
            },
        }),
        ...(variant === 'text' && {
            color: '#1F4C5C',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
        }),
        ...(variant === 'danger' && {
            border: '1px solid rgba(0, 0, 0, 0.12)',
            color: '#B00020',
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                border: '1px solid rgba(0, 0, 0, 0.12)',
            },
        }),
        '& .MuiButton-startIcon > *:nth-of-type(1)': {
            fontSize: '18px',
        },
        '& .MuiButton-endIcon > *:nth-of-type(1)': {
            fontSize: '18px',
        },
        ...(disabled && {
            backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
            border: 'none !important',
        }),
    };
});

const Button = ({ className, children, variant, startIcon = null, endIcon = null, ...rest }) => {
    return (
        <StyledButton
            {...rest}
            className={className}
            disableElevation
            variant={variant}
            startIcon={startIcon}
            endIcon={endIcon}
        >
            {children}
        </StyledButton>
    );
};

Button.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['contained', 'outlined', 'text', 'danger']),
};

export default Button;
