import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const StyledRadioGroup = styled(RadioGroup)({
    '& .MuiFormControlLabel-root': {
        marginRight: '8px !important',
        transform: 'translateY(-3px)',
    },
    '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
    },
});

const CardWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'isActive',
})(({ isActive }) => ({
    marginBottom: 8,
    padding: '8px 16px 16px 18px',
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    backgroundColor: isActive ? '#F7FBF9' : 'transparent',
    '&:hover': {
        backgroundColor: '#F7FBF9',
    },
}));

const AutocompleteCompanyItem = ({ location = {}, selectedLocation, setLocation, index }) => {
    const isActive = selectedLocation === index;

    return (
        <CardWrapper isActive={isActive} onClick={() => setLocation(index)}>
            <StyledRadioGroup value={`${selectedLocation}`} onChange={e => setLocation(e.target.value)}>
                <FormControlLabel
                    value={`${index}`}
                    control={
                        <Radio
                            sx={{ color: 'rgba(0, 0, 0, 0.6)', padding: '6px', '&.Mui-checked': { color: '#1F4C5C' } }}
                        />
                    }
                    label=""
                />
            </StyledRadioGroup>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Typography variant="subtitle1">{location.name}</Typography>
                <Typography variant="body2" className={{ color: 'rgba(0, 0, 0, 0.6)', margin: '2px 0 0' }}>
                    {location.address}
                </Typography>
            </div>
        </CardWrapper>
    );
};

export default AutocompleteCompanyItem;
