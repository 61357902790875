import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';
import { RadioButtonsGroup } from '@components/RadioButtonsGroup';

export const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: '564px !important',
        '@media (max-width: 600px)': {
            height: 'var(--100vh) !important',
            width: '100% !important',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

export const TabsWrapper = styled('div')({
    overflowX: 'auto',
    height: 32,
    marginBottom: 24,
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
});

export const StyledRadioButtons = styled(RadioButtonsGroup)({
    width: '100%',
    display: 'block',
    marginBottom: '24px',
    '& .MuiFormControlLabel-root': {
        height: '32px',
        padding: '0 4px',
    },
});
