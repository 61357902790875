import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import { ArrowDownIcon } from 'assets/images/icons';

export const StyledArrowRight = styled(ArrowDownIcon)({
    transform: 'rotate(270deg)',
    width: 6,
    height: 4,
    marginLeft: 'auto',
});

export const StyledMenuHeader = styled('div')({
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '1.5px',
    color: 'rgba(0, 0, 0, 0.38)',
    textTransform: 'uppercase',
    padding: '0 16px',
    marginBottom: 8,
    marginTop: 6,
});

export const ScrollableSection = styled('div')({
    width: '100%',
    overflowY: 'auto',
    maxHeight: 320,
    '@media (max-width: 600px)': {
        maxHeight: '160px',
        overflowY: 'auto',
    },
});

export const StyledPopper = styled(Popper)({
    boxShadow:
        'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
    zIndex: 4,
    background: '#fff',
    padding: '8px 0',
    borderRadius: 4,
    '.MuiMenuItem-root': {
        padding: '8px 16px',
        fontSize: '14px',
        lineHeight: '16px',
        width: '100%',
        justifyContent: 'flex-start',
    },
});
