import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';

export const StyledContainer = styled('div')({
    display: 'grid',
    height: 'calc(var(--100vh))',
    gridTemplateColumns: '280px calc(100% - 280px)',
    gridTemplateAreas: `"sidebar main" "sidebar main"`,
    '@media (max-width: 1024px)': {
        gridTemplateColumns: '100%',
        gridTemplateAreas: `"sidebar" "main"`,
    },
});

export const StyledSidebar = styled('div')({
    gridArea: 'sidebar',
    background: 'rgb(39, 39, 39)',
    height: '100%',
    '@media (max-width: 1024px)': {
        height: 'fit-content',
    },
});

export const StyledSidebarHeader = styled('div')({
    width: '100%',
    height: 72,
    display: 'flex',
    padding: '22px 19px',
    alignItems: 'center',
    borderBottom: '1px solid #373737',
    '@media (max-width: 1024px)': {
        height: 60,
    },
});

export const StyledPluspoint = styled('div')({
    color: '#FFFFFF',
    fontSize: 10,
    fontFamily: 'Rubik, sans-serif',
    fontWeight: 500,
    lineHeight: 12,
    marginLeft: 11,
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
});

export const StyledSidebarLinks = styled('div')({
    '@media (max-width: 1024px)': {
        display: 'none',
    },
});

export const StyledSidebarLink = styled('div')({
    padding: '8px 18px 8px 25px',
    display: 'flex',
    alignItems: 'center',
    gap: 18,
});

export const StyledMain = styled('div')({
    gridArea: 'main',
    '@media (max-width: 1024px)': {
        height: 'calc(var(--100vh) - 60px)',
    },
});

export const StyledHeader = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '22px 24px',
    height: 72,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '@media (max-width: 1024px)': {
        height: 48,
    },
});

export const StyledHeaderAvatar = styled(Skeleton)({
    '@media (max-width: 1024px)': {
        display: 'none !important',
    },
});

export const StyledContent = styled('div')({
    height: 'calc(var(--100vh) - 72px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 1024px)': {
        height: 'calc(var(--100vh) - 108px)',
    },
});

export const StyledProgress = styled(CircularProgress)({
    color: 'rgba(0, 0, 0, 0.38)',
    width: '58px !important',
    height: '58px !important',
    display: 'block !important',
    margin: '0 auto 24px',
});
