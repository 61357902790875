import EditIcon from '@mui/icons-material/Edit';
import { getChannelIcon } from '@helpers/channels';
import { DeleteIcon } from 'assets/images/icons';
import { Typography } from '@components/Typography';
import { CHANNEL, CHANNEL_MAP, STATUS } from '@constants/channels';
import { formatCompanies } from '@helpers/companies';
import { useTranslation } from '@hooks/useTranslation';
import { StyledIconButton, Container, Footer, Controls } from './styles';

const ChannelItem = ({ channel, companies, isSubmitting, onClickDelete, onClickEdit }) => {
    const { t } = useTranslation();

    const assignedCompanies = formatCompanies(companies, channel.companies, 42, false);

    return (
        <Container>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="subtitle2">{channel.name}</Typography>
                {channel?.type === CHANNEL.WHATSAPP && channel?.status === STATUS.PENDING ? (
                    <Typography variant="caption" sx={{ color: '#FF8700' }}>
                        {t('SettingsChannels.pending')}
                    </Typography>
                ) : null}
                {channel?.status === STATUS.DISCONNECTED ? (
                    <Typography variant="caption" sx={{ color: '#B00020' }}>
                        {t('SettingsChannels.disconnected')}
                    </Typography>
                ) : null}
            </div>
            <Typography
                variant="caption"
                sx={{
                    color: assignedCompanies ? 'rgba(0, 0, 0, 0.6)' : '#fff',
                    margin: '4px 0 16px',
                    display: 'inline-block',
                }}
            >
                {assignedCompanies}
            </Typography>
            <div style={{ width: '100%', height: 1, backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
            <Footer>
                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <Typography variant="caption" color="textSecondary">
                        {t('common.type')}:
                    </Typography>
                    {getChannelIcon(channel.type)}
                    <Typography variant="caption" color="textSecondary">
                        {CHANNEL_MAP[channel?.type]}
                    </Typography>
                </div>
                <Controls>
                    <StyledIconButton
                        disabled={
                            isSubmitting ||
                            (channel.type === CHANNEL.EMAIL && channel.isDefault) ||
                            (channel.type === CHANNEL.SMS && channel.isDefault)
                        }
                        onClick={() => onClickEdit(channel)}
                    >
                        <EditIcon />
                    </StyledIconButton>
                    <StyledIconButton
                        disabled={
                            isSubmitting ||
                            (channel.type === CHANNEL.EMAIL && channel.isDefault) ||
                            (channel.type === CHANNEL.SMS && channel.isDefault)
                        }
                        onClick={() => onClickDelete(channel)}
                    >
                        <DeleteIcon />
                    </StyledIconButton>
                </Controls>
            </Footer>
        </Container>
    );
};

export default ChannelItem;
