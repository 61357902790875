import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageHeader from '@features/PageHeader';
import Button from '@components/Button';
import { IntegrationRequiredIcon } from 'assets/images/icons';
import { useTranslation } from '@hooks/useTranslation';

const IntegrationLearnMoreLinks = {
    GB: 'https://pluspoint.notion.site/9e312778e83d477cb1900edd6b50482b#88cbb6d39e66494e989702e6881f2c61',
    ES: 'https://pluspoint.notion.site/6a8b73efdc104c028ec2e5a0b0265474#1b2a270c72454e79ba2af1a02e49e5a0',
    PT: 'https://pluspoint.notion.site/0030b0361cd44f139eec247bdf7857a3#884de7951f9340a0b3e9744ab61d8281',
    UA: 'https://pluspoint.notion.site/57b1016542994ba1924b6d934485f27e#604735b87e9b467093634e8f4c31e27c',
};

const IntegrationRequired = ({ googleOnly = false }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const user = useSelector(state => state.authentication.user);
    const USER_HAS_RIGHTS = user?.isAccountOwner || user?.isGlobalAdmin;

    const onClickLearnMore = () => {
        window.open(IntegrationLearnMoreLinks[user?.language], '_blank');
    };

    return (
        <PageHeader
            icon={<IntegrationRequiredIcon />}
            title={t('IntegrationRequired.title')}
            subtitle={t(`IntegrationRequired.${googleOnly ? 'subtitleGoogle' : 'subtitleCommon'}`)}
            styles={{ marginBottom: '16px' }}
        >
            {USER_HAS_RIGHTS && (
                <Button variant="contained" onClick={() => history.push('/account-settings/integrations')}>
                    {t('buttons.connect')}
                </Button>
            )}
            <Button variant="outlined" onClick={onClickLearnMore}>
                {t('common.learnMore')}
            </Button>
        </PageHeader>
    );
};

export default IntegrationRequired;
