import { useMemo, useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@components/Button';
import { ModalBase } from '@components/Modal';
import { Chip } from '@components/Chip';
import { FiltersFormCreator } from '@components/FiltersFormCreator';
import { FiltersIcon } from 'assets/images/icons';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { updateState, getChipsLabel, getAvailableCompanies } from '@helpers/filters';
import { AUTHOR, FORMAT } from '@constants/profiles-media';
import { useTranslation } from '@hooks/useTranslation';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        width: 564,
        '@media (max-width: 1024px)': {
            width: '100%',
            height: '100%',
            borderRadius: 0,
            maxHeight: 'none',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

export const FiltersWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: '8px',
    '@media print': {
        '& button': {
            display: 'none',
        },
    },
});

const FiltersPanel = ({
    className,
    setFiltersOpened,
    filtersNumber,
    filterOpened,
    onApplyFilters,
    disabled = false,
    companies = [],
    labels = [],
    filter,
    setFilter,
    withChips = true,
    type,
    setFilterNumber,
}) => {
    const firstRender = useRef(true);
    const { t } = useTranslation();
    const [filterChips, setFilterChips] = useState({
        labels: [],
        companies: [],
        formats: [],
        categories: [],
        authors: [],
        timeframe: {},
    });
    const [refetchFilters, setRefetchFilters] = useState(false);

    const isMobile = useMediaQuery('@media (max-width: 768px)');

    useEffect(() => {
        if (refetchFilters) {
            setRefetchFilters(false);
            onApplyFilters();
        }
    }, [refetchFilters]);

    useEffect(() => {
        if (firstRender.current && filter.companies.length > 0) {
            applyChips();
            firstRender.current = false;
        }
    }, [filter]);

    const options = useMemo(() => {
        //labelsOptions
        const labelsOptions = labels.map(item => ({ value: item.id, label: item.name }));

        //companyOptions
        const companyOptionsWithLabels = companies
            .filter(item => item.labels.some(l => filter.labels.includes(l)))
            .map(item => ({ value: item.companyId, label: item.internalName }));

        const companyOptionsWithoutLabels = companies
            .filter(item => item.labels?.length === 0)
            .map(item => ({ value: item.companyId, label: item.internalName }));

        const companyOptions = filter.labels.includes('none')
            ? [...companyOptionsWithLabels, ...companyOptionsWithoutLabels]
            : companyOptionsWithLabels;

        //formatsOptions
        const formatsOptions = [
            { value: FORMAT.PHOTO, label: t('common.photo') },
            { value: FORMAT.VIDEO, label: t('common.video') },
        ];

        //categoriesOptions
        const categoriesOptions = [
            { value: 0, label: t('common.none') },
            { value: 1, label: t('ProfilesMedia.exterior') },
            { value: 2, label: t('ProfilesMedia.interior') },
            { value: 3, label: t('ProfilesMedia.product') },
            { value: 4, label: t('ProfilesMedia.atWork') },
            { value: 5, label: t('ProfilesMedia.foodAndDrink') },
            { value: 6, label: t('ProfilesMedia.menu') },
            { value: 7, label: t('ProfilesMedia.commonArea') },
            { value: 8, label: t('ProfilesMedia.rooms') },
            { value: 9, label: t('ProfilesMedia.teams') },
        ];

        //authorsOptions
        const authorsOptions = [
            { value: AUTHOR.BUSINESS, label: t('common.business') },
            { value: AUTHOR.CUSTOMER, label: t('common.customer') },
        ];

        return {
            labels: labelsOptions,
            companies: companyOptions,
            formats: formatsOptions,
            categories: categoriesOptions,
            authors: authorsOptions,
        };
    }, [companies, labels, filter]);

    useEffect(() => {
        const allFilters = { ...filter, ...filterChips };
        const filterNumber = Object.keys(allFilters).reduce((total, key) => {
            let increment = 0;

            switch (key) {
                case 'timeframe':
                    increment = filterChips[key]?.start ? 1 : 0;
                    break;
                default:
                    increment = filterChips[key]?.length > 0 ? 1 : 0;
                    break;
            }

            return total + increment;
        }, 0);

        setFilterNumber(filterNumber);
    }, [filterChips, filter]);

    const update = {
        labels: (value, isEvent = true) => {
            const selectedValues = isEvent ? value.target.value : value;

            const isAllCompaniesSelected = filter.companies.length === options.companies.length;
            const selectedLabels = getMultiselectSelectedOptions(options.labels, selectedValues);
            const availableCompanies = getAvailableCompanies(companies, selectedLabels);

            setFilter(prev => ({
                ...prev,
                labels: selectedLabels,
                companies: updateState(prev?.companies, availableCompanies, isAllCompaniesSelected),
            }));
        },
        companies: e => {
            const newCompanies = getMultiselectSelectedOptions(options.companies, e.target.value);

            setFilter(prev => ({
                ...prev,
                companies: newCompanies,
            }));
        },
        formats: e => {
            const newFormat = getMultiselectSelectedOptions(options.formats, e.target.value);
            setFilter(prev => ({ ...prev, formats: newFormat }));
        },
        categories: e => {
            const newCategories = getMultiselectSelectedOptions(options.categories, e.target.value);
            setFilter(prev => ({ ...prev, categories: newCategories }));
        },
        authors: e => {
            const newAuthor = getMultiselectSelectedOptions(options.authors, e.target.value);
            setFilter(prev => ({ ...prev, authors: newAuthor }));
        },

        timeframe: data => {
            setFilter(prev => ({ ...prev, timeframe: { start: data[0], end: data[1] } }));
        },
    };

    const CHIPS_ACTIONS = {
        labels: {
            onDelete: () => {
                const allLabels = labels?.map(item => item.id);
                update.labels(allLabels, false);
                setFilterChips(prev => ({ ...prev, labels: [] }));
            },
            value: getChipsLabel(filterChips?.labels),
        },
        companies: {
            onDelete: () => {
                const selectedCompanies = getAvailableCompanies(companies, filter?.labels);
                setFilter(prev => ({
                    ...prev,
                    companies: selectedCompanies,
                }));
                setFilterChips(prev => ({ ...prev, companies: [] }));
            },
            value: getChipsLabel(filterChips?.companies),
        },
        formats: {
            onDelete: () => {
                setFilter(prev => ({ ...prev, formats: [FORMAT.PHOTO, FORMAT.VIDEO] }));
                setFilterChips(prev => ({ ...prev, formats: [] }));
            },
            value: getChipsLabel(filterChips?.formats),
        },
        categories: {
            onDelete: () => {
                setFilter(prev => ({ ...prev, categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] }));
                setFilterChips(prev => ({ ...prev, categories: [] }));
            },
            value: getChipsLabel(filterChips?.categories),
        },
        authors: {
            onDelete: () => {
                setFilter(prev => ({ ...prev, authors: [AUTHOR.BUSINESS, AUTHOR.CUSTOMER] }));
                setFilterChips(prev => ({ ...prev, authors: [] }));
            },
            value: getChipsLabel(filterChips?.authors),
        },
        timeframe: filterChips?.timeframe?.start
            ? {
                  onDelete: () => {
                      setFilter(prev => ({ ...prev, timeframe: { start: null, end: null } }));
                      setFilterChips(prev => ({ ...prev, timeframe: { start: null, end: null } }));
                  },
                  value: `${format(new Date(filterChips.timeframe.start), 'dd.MM.yyyy')} - ${format(
                      new Date(filterChips.timeframe.end),
                      'dd.MM.yyyy',
                  )}`,
              }
            : { onDelete: () => {}, value: '' },
    };

    const applyChips = () => {
        let updatedChips = { ...filterChips };

        Object.keys(updatedChips).forEach(key => {
            if (key !== 'timeframe') {
                updatedChips[key] =
                    filter?.[key]?.length < options?.[key]?.length
                        ? options?.[key]?.filter(i => filter?.[key]?.includes(i.value))
                        : [];
            }
        });

        updatedChips['timeframe'] = filter.timeframe;

        setFilterChips(updatedChips);
    };

    const createFormOptions = (filter, update, options) => {
        const formOptions = [];

        for (let key in filter) {
            if (Array.isArray(filter[key])) {
                formOptions.push({
                    type: 'multiSelect',
                    label: t(`filters.${key}`),
                    value: filter[key],
                    onChange: update[key],
                    options: options[key],
                    countable: true,
                    error: !filter[key].length,
                });
            } else if (key === 'timeframe') {
                formOptions.push({
                    type: 'timeframe',
                    label: t(`filters.${key}`),
                    value: [filter[key].start, filter[key].end],
                    onChange: update[key],
                });
            }
        }

        return formOptions;
    };

    const isValid = Object.keys(filter).every(key => {
        if (Array.isArray(filter[key])) {
            return !!filter[key].length;
        } else {
            return true;
        }
    });

    return (
        <FiltersWrapper>
            <Button
                className={className}
                variant="outlined"
                onClick={() => setFiltersOpened(true)}
                startIcon={<FiltersIcon />}
                disabled={disabled}
                sx={{ marginRight: '8px' }}
            >
                {t('buttons.filters')}
                {filtersNumber > 0 ? ` (${filtersNumber})` : ''}
            </Button>
            {withChips &&
                !isMobile &&
                Object.keys(filterChips).map(
                    key =>
                        (!!filterChips[key]?.length || filterChips[key]?.start) && (
                            <Chip
                                key={key}
                                label={CHIPS_ACTIONS[key].value}
                                onDelete={() => {
                                    CHIPS_ACTIONS[key].onDelete();
                                    setRefetchFilters(true);
                                }}
                            />
                        ),
                )}
            <Modal
                isOpen={filterOpened}
                handleClose={() => setFiltersOpened(false)}
                title={t('buttons.filters')}
                onPrimaryAction={() => {
                    applyChips();
                    setRefetchFilters(true);
                    setFiltersOpened(false);
                }}
                primaryActionDisabled={!isValid}
                primaryActionText={t('buttons.applyFilters')}
                onSecondaryAction={() => setFiltersOpened(false)}
                secondaryActionText={t('buttons.cancel')}
            >
                <FiltersFormCreator type={type} options={createFormOptions(filter, update, options)} />
            </Modal>
        </FiltersWrapper>
    );
};

export default FiltersPanel;
