import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const chatbotContentService = {
    getChatbotContent,
    addOrUpdateChatbotContent,
    deleteChatbotContent,
};

function getChatbotContent(params, page, pageSize) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params),
    };

    return fetch(`${BASE_URL}api/chat-bot/knowledge-base/list?page=${page}&pageSize=${pageSize}`, requestOptions).then(
        handleResponse,
    );
}

function addOrUpdateChatbotContent(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/chat-bot/knowledge-base`, requestOptions).then(handleResponse);
}

function deleteChatbotContent(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/chat-bot/knowledge-base/${id}`, requestOptions).then(handleResponse);
}
