import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';

export const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 564,
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
    '& .modal-base-content': {
        padding: 16,
    },
});

export const Content = styled('div')({
    display: 'grid',
    gridGap: '16px 24px',
    gridTemplateAreas: '"input1"',
    gridTemplateColumns: '100%',
    '@media (max-width: 600px)': {
        gridTemplateColumns: '100%',
        gridGap: '16px',
    },
});
