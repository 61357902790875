import { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import merge from 'lodash.merge';

const createTextCenterPlugin = (title, data, styles) => ({
    id: 'textCenter',
    beforeDatasetsDraw(chart) {
        const { ctx } = chart;
        const xCoor = chart.getDatasetMeta(0).data[0].x;
        const yCoor = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.font = styles.fontTitle;
        ctx.fillStyle = styles.colorTitle;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(title, xCoor, yCoor - styles.yTitleOffset);

        ctx.font = styles.fontData;
        ctx.fillStyle = styles.colorData;
        ctx.fillText(data, xCoor, yCoor + styles.yDataOffset);
    },
});

const createChartOptions = (hasData = false, optionsOverride) => {
    const defaultOptions = {
        responsive: true,
        cutout: '78%',
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    font: {
                        family: 'Rubik, sans-serif',
                        size: 10,
                        color: 'rgba(0, 0, 0, 0.60)',
                    },
                },
                onClick: () => null,
                reverse: true,
            },
            title: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 1)',
                xAlign: 'left',
                yAlign: 'bottom',
                filter: function () {
                    return hasData;
                },
                callbacks: {
                    label: function (context) {
                        return context.label.trim();
                    },
                },
            },
        },
    };

    return merge({}, defaultOptions, optionsOverride);
};

const DoughnutChart = ({ overall, datasets, optionsProps, hasData, textCenterProps }) => {
    const textCenterStyles = {
        fontTitle: 'bold 18px Lora, serif',
        colorTitle: 'rgba(0, 0, 0, 0.87)',
        fontData: '60px Lora, serif',
        colorData: '#1F4C5C',
        yTitleOffset: 16,
        yDataOffset: 32,
        ...textCenterProps,
    };

    const options = useMemo(() => createChartOptions(hasData, optionsProps), [hasData, optionsProps]);

    return (
        <Doughnut
            key={`${overall.title}-${overall.data}`}
            data={datasets}
            options={options}
            plugins={[createTextCenterPlugin(overall.title, overall.data, textCenterStyles)]}
        />
    );
};

export default DoughnutChart;
