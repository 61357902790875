import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import sortBy from 'lodash.sortby';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@components/Typography';
import { Table } from '@components/Table';
import { getTableBody } from './FeedbackTableBody';
import { getCollapsibleTableBody } from './CollapsibleRowBody';
import { useTranslation } from '@hooks/useTranslation';

export const PaperStyled = styled(Paper)({
    padding: '24px',
    display: 'flex',
    width: '100%',
    borderRadius: '4px',
});

export const TableStyled = styled(Table)({
    '& tbody .MuiTableRow-root[data-row=true]': {
        backgroundColor: 'rgba(247, 251, 249)',
    },
});

export const TableCellText = styled(Typography)({
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
});

export const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            width: '5%',
            component: <></>,
        },
        {
            key: 1,
            width: '23%',
            component: (
                <Typography variant="subtitle2" align="left">
                    {t('common.location')}
                </Typography>
            ),
        },
        {
            key: 2,
            width: '18%',
            component: (
                <Typography variant="subtitle2" align="right">
                    {t('AnalyticsReviews.totalResponses')}
                </Typography>
            ),
        },
        {
            key: 3,
            width: '18%',
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsReviews.averageRating')}
                </Typography>
            ),
        },
        {
            key: 4,
            width: '18%',
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsReviews.negativeResponses')}
                </Typography>
            ),
        },
        {
            key: 5,
            width: '18%',
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsReviews.nps')}
                </Typography>
            ),
        },
    ];
};

export const getTableArrow = field => {
    if (field > 0) {
        return <ArrowUpwardIcon sx={{ color: '#0B8D00', fontSize: '20px !important' }} />;
    } else if (field < 0) {
        return <ArrowDownwardIcon sx={{ color: '#B00020', fontSize: '20px !important' }} />;
    }
    return null;
};

const CollapsibleRow = ({ data, index }) => {
    return <Table rows={getCollapsibleTableBody(data, index)} />;
};

const ReviewsTable = memo(({ isLoading, data }) => {
    const companies = useSelector(state => state.account?.account?.companies || []);

    const dataSortedByName = useMemo(() => {
        const dataWithLocationNames = data.map(item => ({
            ...item,
            internalName: companies.find(company => company.companyId === item.companyId)?.internalName || '',
        }));
        return sortBy(dataWithLocationNames, item => item.internalName.toLowerCase());
    }, [data, companies]);

    return (
        <PaperStyled elevation={1}>
            <TableContainer>
                <TableStyled
                    data={dataSortedByName}
                    columnNames={GetTableHeader()}
                    rows={getTableBody(dataSortedByName)}
                    isDataLoading={isLoading}
                    isCollapsible={true}
                    CollapsibleRow={CollapsibleRow}
                />
            </TableContainer>
        </PaperStyled>
    );
});

ReviewsTable.displayName = 'ReviewsTable';

export default ReviewsTable;
