import { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { ModalBase } from '@components/Modal';
import { FiltersIntegrationType } from '@constants/integrations';
import { labelsActions } from '@actions';
import { LABEL_TYPE } from '@constants/labels';
import { ReactComponent as WidgetSingle } from 'assets/images/widget-single.svg';
import gridWidget from 'assets/images/widget-grid.svg';
import Listing from './Listing';
import ConfigForm from './ConfigForm';
import Code from './Code';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1024px)': {
        padding: '24px 16px',
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
});

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        width: 'calc(100% - 32px)',
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100% !important',
        },
    },
    '& .modal-base-content': {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        gridTemplateAreas: `"main sub"`,
        '@media (max-width: 600px)': {
            gridTemplateColumns: '100%',
            gridTemplateRows: '176px',
            gridTemplateAreas: `"sub" "main"`,
        },
    },
});

const MainContent = styled('div')({
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gridArea: 'main',
});

const SubContent = styled('div')({
    display: 'flex',
    alignItems: 'center',
    minHeight: 300,
    justifyContent: 'center',
    backgroundColor: '#F2F2F2',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    gridArea: 'sub',
    '@media (max-width: 600px)': {
        minHeight: 'initial',
        height: 176,
    },
    '& svg, img': {
        width: 384,
    },
});

const VIEW = {
    FORM: 0,
    CODE: 1,
};

const WidgetsPage = memo(() => {
    const { t } = useTranslation();
    const [modal, setModal] = useState({ open: false, type: null, view: VIEW.FORM });
    const [widgetData, setWidgetData] = useState({
        sorting: '0',
        count: '24',
        hideEmptyReviews: true,
        labels: [],
        company: [],
        integrations: [],
        backgroundColor: '#F1F1F1',
    });

    const account = useSelector(state => state.account?.account);
    const companies = useSelector(state => state.account?.account?.companies || []);
    const { location: labels, locationLoading: labelsLoading } = useSelector(state => state.labels);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
    }, []);

    useEffect(() => {
        if (companies.length && !labelsLoading) {
            const integrations = [
                ...new Set(
                    companies
                        .map(company => company.profiles)
                        .flat()
                        .filter(p => p.type in FiltersIntegrationType)
                        .map(p => p.type),
                ),
            ];
            const availableLabels = [...new Set(companies.map(item => item.labels).flat())];
            setWidgetData(prev => ({
                ...prev,
                integrations,
                company: companies.map(item => item.companyId),
                labels: labels.filter(l => availableLabels.includes(l.id)).map(item => item.id),
            }));
        }
    }, [companies, labelsLoading]);

    const onClickCard = type => setModal({ open: true, type, view: VIEW.FORM });

    const onCloseModal = () => {
        setModal({ open: false, type: null, view: VIEW.FORM });
        if (companies.length) {
            const integrations = [
                ...new Set(
                    companies
                        .map(company => company.profiles)
                        .flat()
                        .filter(p => p.type in FiltersIntegrationType)
                        .map(p => p.type),
                ),
            ];
            const availableLabels = [...new Set(companies.map(item => item.labels).flat())];
            setWidgetData(prev => ({
                ...prev,
                sorting: '0',
                count: '24',
                hideEmptyReviews: true,
                integrations,
                company: companies.map(item => item.companyId),
                labels: labels.filter(l => availableLabels.includes(l.id)).map(item => item.id),
            }));
        }
    };

    const handleChangeView = () => setModal({ ...modal, view: modal.view === VIEW.CODE ? VIEW.FORM : VIEW.CODE });

    return (
        <Container>
            <Typography variant="h6" color="textPrimary">
                {t('ShowcaseWidgets.title')}
            </Typography>
            <Typography variant="body2" sx={{ margin: '4px 0 16px', color: 'rgba(0, 0, 0, 0.6)' }}>
                {t('ShowcaseWidgets.subtitle')}
            </Typography>
            <Listing onClickCard={onClickCard} />
            <Modal
                isOpen={modal.open}
                handleClose={onCloseModal}
                title={
                    modal.view === VIEW.CODE
                        ? t('ShowcaseWidgets.widgetCode')
                        : modal.type === 'popup'
                        ? t('ShowcaseWidgets.popupWidget')
                        : t('ShowcaseWidgets.gridWidget')
                }
                onPrimaryAction={modal.view === VIEW.FORM ? handleChangeView : onCloseModal}
                primaryActionText={modal.view === VIEW.FORM ? t('buttons.getCode') : t('buttons.done')}
                primaryActionDisabled={!widgetData.company.length || !widgetData.integrations.length}
                onSecondaryAction={modal.view === VIEW.FORM ? onCloseModal : handleChangeView}
                secondaryActionText={modal.view === VIEW.FORM ? t('buttons.cancel') : t('buttons.back')}
            >
                <MainContent>
                    {modal.view === VIEW.FORM ? (
                        <ConfigForm
                            widgetData={widgetData}
                            setWidgetData={setWidgetData}
                            companies={companies}
                            labels={labels}
                            type={modal.type}
                        />
                    ) : (
                        <Code widgetKey={account?.widgetKey} type={modal.type} data={widgetData} />
                    )}
                </MainContent>
                <SubContent>
                    {modal.type === 'popup' ? <WidgetSingle /> : <img src={gridWidget} alt="grid-widfget" />}
                </SubContent>
            </Modal>
        </Container>
    );
});

WidgetsPage.displayName = 'WidgetsPage';

export default WidgetsPage;
