import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import printJS from 'print-js';
import { styled } from '@mui/material/styles';
import PageHeader from '@features/PageHeader';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { ModalConfirm } from '@components/Modal';
import { useTranslation } from '@hooks/useTranslation';
import { accountActions, alertActions, labelsActions, surveysActions } from '@actions';
import { surveyService } from '@services';
import { VIEW_TYPE } from '@constants/surveys';
import { LABEL_TYPE } from '@constants/labels';
import { SurveysIcon } from 'assets/images/icons';
import TemplateItem from './TemplateItem';
import EditModal from './EditModal';

const SystemFieldType = {
    EMAIL: 0,
    PHONE: 1,
    NAME: 2,
};

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

const CardsGrid = styled('div')({
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
});

const SurveysPage = () => {
    const { t } = useTranslation();

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modal, setModal] = useState({ open: false, type: VIEW_TYPE.EDIT });

    const user = useSelector(state => state.authentication.user);
    const account = useSelector(state => state.account?.account);
    const data = useSelector(state => state.surveys.data);
    const surveysTotalCount = useSelector(state => state.surveys.totalCount);
    const isAdmin = useSelector(state => {
        if (!state.authentication?.user?.id) return false;
        return state.authentication?.user?.roles?.filter(u => u.role === 0)?.length > 0;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(surveysActions.getAll());
        dispatch(labelsActions.get(LABEL_TYPE.CONTACT));
    }, []);

    const onCreateEmptySurvey = async () => {
        setIsSubmitting(true);
        try {
            const newSurvey = await surveyService.createEmptySurvey();
            await dispatch(surveysActions.getAll());
            await dispatch(accountActions.get());
            setSelectedSurvey(newSurvey);
            setModal({ open: true, type: VIEW_TYPE.EDIT });
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.createFail')));
        }
        setIsSubmitting(false);
    };

    const onDelete = async () => {
        setIsSubmitting(true);
        try {
            await surveyService.deleteSurvey(selectedSurvey.id);
            await dispatch(surveysActions.getAll());
            await dispatch(accountActions.get());
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.deleteFail')));
        }
        setSelectedSurvey(null);
        if (modal.open) {
            setModal({ open: false, type: VIEW_TYPE.EDIT });
        }
        setDeleteModalOpen(false);
        setIsSubmitting(false);
    };

    const onGetSurvey = async (survey, type) => {
        setIsSubmitting(true);
        try {
            const surveyImage = await surveyService.getSurveyImage(survey.externalId, {
                topText: survey.topText,
                bottomText: survey.bottomText,
                serviceId: survey.externalId,
                backgroundColor: survey.backgroundColor,
                fillColor: survey.fillColor,
                logoUrl: survey.qrLogoUrl,
                customizeQrCodeDesign: survey.customizeQrCodeDesign,
            });
            if (type === 'print') {
                printJS({ printable: surveyImage, type: 'image' });
            } else {
                const anchor = document.createElement('a');
                anchor.style.display = 'none';
                anchor.href = surveyImage;
                anchor.download = `${survey.name}.png`;
                document.body.appendChild(anchor);
                anchor.click();
                window.URL.revokeObjectURL(surveyImage);
                anchor.remove();
            }
        } catch (_) {
            if (type === 'print') {
                dispatch(alertActions.error(t('alertMessages.printFail')));
            } else {
                dispatch(alertActions.error(t('alertMessages.downloadFail')));
            }
        }
        setIsSubmitting(false);
    };

    const onCloseModal = () => {
        setModal({ open: false, type: VIEW_TYPE.EDIT });
        setSelectedSurvey(null);
    };

    const onSave = async values => {
        setIsSubmitting(true);
        try {
            await surveyService.editSurvey({
                ...selectedSurvey,
                ...values,
                systemFields: {
                    Email: {
                        type: SystemFieldType.EMAIL,
                        required: values.contactEmailRequired,
                        enable: values.contactEmail,
                    },
                    Phone: {
                        type: SystemFieldType.PHONE,
                        required: values.contactPhoneRequired,
                        enable: values.contactPhone,
                    },
                    Name: {
                        type: SystemFieldType.NAME,
                        required: values.contactNameRequired,
                        enable: values.contactName,
                    },
                },
                message: values.message,
                followUpText: values.followUpText,
            });
            await dispatch(surveysActions.getAll());
            await dispatch(accountActions.get());
            dispatch(alertActions.success(t('alertMessages.editSuccess')));
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.editFail')));
        }
        setSelectedSurvey(null);
        if (modal.open) {
            setModal({ open: false, type: VIEW_TYPE.EDIT });
        }
        setIsSubmitting(false);
    };

    const isUser = !isAdmin && !user?.isAccountOwner && !user?.isGlobalAdmin;

    return (
        <Container>
            <PageHeader
                styles={{ marginBottom: '16px' }}
                icon={<SurveysIcon />}
                title={t('FeedbackSurveys.title')}
                subtitle={t('FeedbackSurveys.subtitle')}
                metrics={
                    <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                        {t('FeedbackSurveys.created')} {surveysTotalCount}
                    </Typography>
                }
            >
                {!isUser && (
                    <Button variant="contained" onClick={onCreateEmptySurvey} disabled={isSubmitting}>
                        {t('buttons.addSurvey')}
                    </Button>
                )}
            </PageHeader>
            <CardsGrid>
                {data.map(item => (
                    <TemplateItem
                        key={item.survey.id}
                        data={item}
                        isUser={isUser}
                        companies={account?.companies}
                        isSubmitting={isSubmitting}
                        onClickEdit={() => {
                            setSelectedSurvey(item.survey);
                            setModal({ open: true, type: VIEW_TYPE.EDIT });
                        }}
                        onClickView={() => {
                            setSelectedSurvey(item.survey);
                            setModal({ open: true, type: VIEW_TYPE.VIEW });
                        }}
                        onClickDelete={() => {
                            setSelectedSurvey(item.survey);
                            setDeleteModalOpen(true);
                        }}
                        onClickDownload={() => onGetSurvey(item.survey, 'download')}
                        onClickPrint={() => onGetSurvey(item.survey, 'print')}
                    />
                ))}
            </CardsGrid>
            <EditModal
                modal={modal}
                account={account}
                onCloseModal={onCloseModal}
                selectedSurvey={selectedSurvey}
                onDelete={() => setDeleteModalOpen(true)}
                onSave={onSave}
                isSubmitting={isSubmitting}
                isUser={isUser}
            />
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDelete}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                />
            )}
        </Container>
    );
};

export default SurveysPage;
