import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { ModalBase } from '@components/Modal';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { AUTHOR, FORMAT, MEDIA_STATUS } from '@constants/profiles-media';
import { I18N_DATE_FORMAT } from '@helpers/dates';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: '95%',
        height: '90%',
        '@media (max-width: 600px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
});

const Container = styled('div')({
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 2fr) 360px',
    '@media (max-width: 600px)': {
        gridTemplateColumns: '1fr',
    },
});

const ImageWrapper = styled('div')({
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#272727',
    '@media (max-width: 600px)': {
        overflow: 'unset',
    },
});

const FIELDS = ['authorName', 'profileName', 'locationsNames', 'categoryName', 'createdAt'];

const ViewModal = ({ isOpen, handleClose, selectedImage, setDeleteModalOpen, setReportModalOpen }) => {
    const { t } = useTranslation();
    const locations = useSelector(state => state.account?.account?.companies || []);
    const language = useSelector(state => state.authentication.user?.language);

    const FIELDS_MAP = selectedImage?.status === MEDIA_STATUS.REPORTED ? FIELDS.concat('reportedAt') : FIELDS;

    const categoriesOptions = {
        0: t('common.none'),
        1: t('ProfilesMedia.exterior'),
        2: t('ProfilesMedia.interior'),
        3: t('ProfilesMedia.product'),
        4: t('ProfilesMedia.atWork'),
        5: t('ProfilesMedia.foodAndDrink'),
        6: t('ProfilesMedia.menu'),
        7: t('ProfilesMedia.commonArea'),
        8: t('ProfilesMedia.rooms'),
        9: t('ProfilesMedia.teams'),
    };

    const isBusiness = selectedImage?.authorType === AUTHOR.BUSINESS;

    const getFieldValue = field => {
        switch (field) {
            case 'authorName':
                return isBusiness ? t('common.business') : selectedImage?.authorName;
            case 'locationsNames':
                return locations
                    .filter(location =>
                        location.profiles.some(profile => profile.profileId === selectedImage?.profileId),
                    )
                    .map(location => location.locationName)
                    .join(', ');
            case 'categoryName':
                return categoriesOptions[selectedImage?.category] || '';
            case 'createdAt':
            case 'reportedAt':
                return selectedImage?.[field]
                    ? format(new Date(selectedImage?.[field]), 'dd MMM yyyy', {
                          locale: I18N_DATE_FORMAT[language],
                      })
                    : '';
            default:
                return selectedImage?.[field] || '';
        }
    };

    const onSubmit = () => {
        window.open(selectedImage?.viewOnGoogleUrl, '_blank');
    };

    return (
        <Modal
            isOpen={isOpen}
            handleClose={handleClose}
            title={t('ProfilesMedia.viewContent')}
            onPrimaryAction={onSubmit}
            primaryActionText={t('buttons.viewOnGoogle')}
            onSecondaryAction={handleClose}
            secondaryActionText={t('buttons.cancel')}
            onAdditionalAction={() => (isBusiness ? setDeleteModalOpen(true) : setReportModalOpen(true))}
            additionalTextButton={t(isBusiness ? 'buttons.delete' : 'buttons.report')}
        >
            <Container>
                <ImageWrapper>
                    {selectedImage.format === FORMAT.VIDEO ? (
                        <video
                            src={selectedImage.originalUrl}
                            controls
                            style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }}
                        />
                    ) : (
                        <img
                            src={selectedImage.originalUrl}
                            alt={selectedImage.title}
                            loading="lazy"
                            style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }}
                        />
                    )}
                </ImageWrapper>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '24px' }}>
                    {FIELDS_MAP.map(field => (
                        <div key={field}>
                            <Typography variant="caption" color="textSecondary">
                                {t(`ProfilesMedia.${field}`)}
                            </Typography>
                            <Typography variant="body2" style={{ marginTop: '5px' }}>
                                {getFieldValue(field)}
                            </Typography>
                        </div>
                    ))}
                </div>
            </Container>
        </Modal>
    );
};

export default ViewModal;
