import { styled } from '@mui/material/styles';
import { Input } from '@components/Input';
import Button from '@components/Button';
import TextEditor from '@components/TextEditor';
import { UserIcon } from 'assets/images/icons';

export const StyledUserIcon = styled(UserIcon, {
    shouldForwardProp: prop => prop !== 'isLarge',
})(({ isLarge }) => ({
    width: `${isLarge ? 18 : 16}px !important`,
    height: `${isLarge ? 18 : 16}px !important`,
    '& path': {
        fill: 'rgba(0, 0, 0, 0.38)',
        fillOpacity: 1,
    },
}));

export const Container = styled('div', {
    shouldForwardProp: prop => prop !== 'hasConversation',
})(({ hasConversation }) => ({
    display: 'grid',
    height: 'calc(var(--100vh) - 85px)',
    gridTemplateRows: hasConversation ? '57px auto max-content' : '1fr',
    '@media (max-width: 1024px)': {
        height: 'calc(var(--100vh) - 124px)',
    },
}));

export const Header = styled('div')({
    display: 'grid',
    padding: '0 16px 13px 16px',
    gridGap: '0 16px',
    gridTemplateColumns: '40px 1fr max-content',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '@media (max-width: 1280px)': {
        gridGap: '0 8px',
    },
    '@media (max-width: 768px)': {
        gridTemplateColumns: '32px 32px minmax(0, 100%) max-content',
        padding: '0 8px 13px 8px',
        gridGap: '0 8px',
    },
});

export const Feed = styled('div')({
    overflowY: 'auto',
    padding: 16,
    display: 'flex',
    flexFlow: 'column-reverse',
    gap: 8,
    '@media (max-width: 768px)': {
        padding: 8,
    },
});

export const Footer = styled('div')({
    padding: 16,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    position: 'relative',
    '@media (max-width: 768px)': {
        padding: 8,
    },
});

export const SendButton = styled(Button)({
    height: 24,
    minWidth: 'auto',
    '& .MuiButton-endIcon': {
        transform: 'rotate(90deg)',
        margin: 0,
        '& svg path': {
            fillOpacity: 1,
        },
    },
});

export const StyledInput = styled(Input, {
    shouldForwardProp: prop => prop !== 'hasValue',
})(({ hasValue }) => ({
    paddingBottom: 0,
    width: '100%',
    '& label': {
        transform: 'translate(14px, 16px) scale(1)',
    },
    '& .MuiInputBase-root.MuiInputBase-multiline': {
        paddingBottom: '46px !important',
    },
    '& .Mui-focused.MuiInputLabel-root': {
        display: 'none',
    },
    '& .Mui-focused fieldset': {
        top: 0,
    },
    '& .Mui-focused legend': {
        display: 'none',
    },
    ...(hasValue && {
        '& .MuiInputLabel-shrink': {
            display: 'none',
        },
        '& legend': {
            display: 'none',
        },
        '& fieldset': {
            top: 0,
        },
    }),
}));

export const StyledTextEditor = styled(TextEditor)({
    paddingBottom: ' 0 !important',
    '& .ProseMirror': {
        maxHeight: '160px !important',
    },
    '& .editor-container': {
        minHeight: '78.5px !important',
        paddingBottom: '46px !important',
        '&:focus-within': {
            padding: '15.5px 13px 45px 13px !important',
        },
    },
    '& .editor-toolbar': {
        bottom: '16.5px !important',
    },
});
