import { styled } from '@mui/material/styles';
import logo from 'assets/images/pluspoint.svg';

export const Container = styled('div')({
    width: '100%',
    height: 'var(--100vh)',
    minWidth: '320px',
    fontFamily: 'Rubik',
    overflow: 'scroll',
    backgroundColor: '#ededed',
    '@media only screen and (max-width: 600px)': {
        padding: '25px',
    },
});

export const Header = styled('div')({
    padding: '60px 60px 0',
    '@media only screen and (max-width: 600px)': {
        padding: '0',
    },
});

export const Logo = styled('div')({
    margin: '0 auto',
    width: '122px',
    height: '14px',
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

export const Title = styled('h3')({
    fontFamily: 'Lora',
    fontSize: '50px',
    fontWeight: 'normal',
    color: '#000000',
    padding: 0,
    margin: '0 0 30px',
    textAlign: 'center',
    '@media only screen and (max-width: 330px)': {
        marginTop: '70px',
    },
    '@media only screen and (max-width: 600px)': {
        fontSize: '30px',
    },
});

export const Text = styled('p')({
    width: '578px',
    fontSize: '14px',
    fontWeight: 'normal',
    textAlign: 'center',
    color: '#707070',
    margin: '0 auto',
    marginBottom: '50px',
    '@media only screen and (max-width: 600px)': {
        width: 'auto',
    },
});
