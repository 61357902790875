import { memo } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@components/Typography';
import Avatar from '@components/Avatar';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { getInitialsFull, getUserContacts } from '@helpers/userName';
import {
    MainSection,
    Footer,
    ItemContainer,
    WrapperContact,
    WrapperContactName,
    WrapperRating,
    IntegrationContainer,
    CssRating,
    StyledUserIcon,
} from './styles';

const ResponseItem = memo(
    ({
        index,
        body,
        footer,
        footerStyles,
        integrationLabel,
        contactDetailsLink,
        contactName,
        phoneNumber,
        email,
        viewed,
        rating,
        infoCaption,
        userProfileImg,
    }) => {
        const { t } = useTranslation();
        const isMobile = useMediaQuery('@media (max-width: 1024px)');
        const [firstName, lastName] = contactName.split(' ');
        return (
            <ItemContainer elevation={4} key={`feedback-${index}`} style={{ background: viewed ? '#FFF' : '#F7FBF9' }}>
                <MainSection>
                    <WrapperRating style={{ marginBottom: isMobile ? '16px' : '8px' }}>
                        <CssRating
                            size="small"
                            name="read-only"
                            value={rating || 0}
                            readOnly
                            icon={<StarRoundedIcon fontSize="inherit" />}
                            emptyIcon={<StarRoundedIcon fontSize="inherit" sx={{ color: '#E4EAEB' }} />}
                        />
                        {infoCaption && (
                            <Typography variant="caption" color="textSecondary">
                                {infoCaption}
                            </Typography>
                        )}
                    </WrapperRating>
                    <WrapperContact>
                        <Avatar
                            size={isMobile ? 'medium' : 'large'}
                            fullName={contactName}
                            image={userProfileImg}
                            label={getInitialsFull(contactName)}
                            icon={<StyledUserIcon />}
                        />
                        <WrapperContactName>
                            <Typography
                                variant="subtitle1"
                                color="textPrimary"
                                style={{ marginRight: '16px', marginBottom: 0 }}
                            >
                                {getUserContacts(
                                    {
                                        firstName,
                                        lastName,
                                        phoneNumber: phoneNumber?.length > 4 ? phoneNumber : undefined,
                                        email,
                                    },
                                    '-',
                                ) || t('FeedbackResponses.anonimous')}
                            </Typography>
                            {contactDetailsLink && <>{contactDetailsLink}</>}
                        </WrapperContactName>
                    </WrapperContact>
                    {body}
                    {integrationLabel && <IntegrationContainer>{integrationLabel}</IntegrationContainer>}
                </MainSection>
                <Footer style={{ ...footerStyles }}>{footer}</Footer>
            </ItemContainer>
        );
    },
);

ResponseItem.displayName = 'ResponseItem';

export default ResponseItem;
