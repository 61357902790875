import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

const SkeletonWrapper = styled('div')({
    padding: '8px 16px',
    '&:first-of-type': {
        padding: '16px 16px 8px',
    },
});

const FeedbackContainer = styled('div')({
    maxWidth: '600px',
    width: '50%',
    '& .MuiSkeleton-root': {
        height: '15px !important',
    },
    '@media (max-width: 1024px)': {
        width: '100%',
    },
});

const FeedbackRow = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    '@media (max-width: 1024px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
});

const FeedbackItem = styled(Paper)({
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: '4px',
});

const FeedbackItemSkeleton = data => (
    <FeedbackItem elevation={4} sx={{ marginTop: data?.length && '16px' }}>
        <FeedbackContainer>
            <FeedbackRow>
                <Skeleton sx={{ height: '12px', width: '100%', transform: 'none' }} />
            </FeedbackRow>
            <FeedbackRow>
                <Skeleton sx={{ height: '16px', width: '100%', transform: 'none' }} />
            </FeedbackRow>
            <Skeleton sx={{ height: '40px', transform: 'none' }} />
        </FeedbackContainer>
    </FeedbackItem>
);

const FeedbacksSkeleton = () => {
    return [...Array(5).keys()].map(item => (
        <SkeletonWrapper key={item}>
            <FeedbackItemSkeleton data={null} />
        </SkeletonWrapper>
    ));
};

export { FeedbackItemSkeleton, FeedbacksSkeleton };
