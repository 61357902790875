import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import PageHeader from '@features/PageHeader';
import { ModalConfirm } from '@components/Modal';
import Button from '@components/Button';
import { useTranslation } from '@hooks/useTranslation';
import { alertActions, chatbotContentActions } from '@actions';
import { chatbotContentService } from '@services';
import { ChatbotHeaderIcon } from 'assets/images/icons';
import EditModal, { FORM_TYPE } from './EditModal';
import KnowledgeBaseTable from './KnowledgeBaseTable';
import FiltersPanel from './FiltersPanel';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

const StyledFiltersPanel = styled(FiltersPanel)({
    '& svg': {
        height: 'auto',
        width: 'auto',
    },
});

const KnowledgeBase = () => {
    const { t } = useTranslation();

    const [selectedContent, setSelectedContent] = useState(null);
    const [modal, setModal] = useState({ open: false, type: FORM_TYPE.NEW });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filter, setFilter] = useState({ labels: [], locations: [] });
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const locations = useSelector(state => state.account?.account?.companies || []);
    const dispatch = useDispatch();
    const firstRender = useRef(true);

    useEffect(() => {
        if (filter.locations.length) {
            if (firstRender.current) {
                dispatch(chatbotContentActions.get({ locations: filter.locations }, page, rowsPerPage));
                firstRender.current = false;
            }
        }
    }, [page, filter, rowsPerPage, dispatch]);

    const onDelete = async () => {
        try {
            await chatbotContentService.deleteChatbotContent(selectedContent.id);
            dispatch(alertActions.success(t('alertMessages.deleteSuccess')));
            if (page === 0) {
                await dispatch(chatbotContentActions.get({ locations: filter.locations }, page, rowsPerPage));
            } else {
                setPage(0); // Triggers refetch of templates
            }
            setDeleteModalOpen(false);
            setSelectedContent(null);
        } catch (error) {
            dispatch(alertActions.error(t('alertMessages.deleteFail')));
        }
    };

    const onAddContentClick = () => setModal({ open: true, type: FORM_TYPE.NEW });

    const onApplyFilters = () => {
        dispatch(chatbotContentActions.get({ locations: filter.locations }, 0, rowsPerPage));
        setPage(0);
    };

    return (
        <Container>
            <PageHeader
                styles={{ marginBottom: '16px' }}
                icon={<ChatbotHeaderIcon />}
                title={t('KnowledgeBase.title')}
                subtitle={t('KnowledgeBase.subtitle')}
            >
                <Button variant="contained" onClick={onAddContentClick}>
                    {t('buttons.addContent')}
                </Button>
                <StyledFiltersPanel
                    onApplyFilters={onApplyFilters}
                    filter={filter}
                    setFilter={setFilter}
                    locations={locations}
                    withChips={false}
                    type="knowledge-base"
                />
            </PageHeader>
            <KnowledgeBaseTable
                setModal={setModal}
                setSelectedContent={setSelectedContent}
                setPage={setPage}
                page={page}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                setDeleteModalOpen={setDeleteModalOpen}
                locations={locations}
            />
            {modal.open ? (
                <EditModal
                    selectedContent={selectedContent}
                    setSelectedContent={setSelectedContent}
                    filter={filter}
                    modal={modal}
                    setModal={setModal}
                    setPage={setPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setDeleteModalOpen={setDeleteModalOpen}
                    locations={locations}
                />
            ) : null}
            {isDeleteModalOpen && (
                <ModalConfirm
                    isOpen={isDeleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    title={t('common.deleteTitle')}
                    description={t('common.deleteDescription')}
                    onPrimaryAction={onDelete}
                    primaryActionText={t('buttons.delete')}
                    primaryActionType="danger"
                />
            )}
        </Container>
    );
};

export default KnowledgeBase;
