export const pricingDrawerUiConstaints = {
    SET_PRICING_DRAWER_OPEN: 'SET_PRICING_DRAWER_OPEN',
    SET_CUSTOM_PRICING_DRAWER_OPEN: 'SET_CUSTOM_PRICING_DRAWER_OPEN',
    SET_PRICING_DRAWER_STEP: 'SET_PRICING_DRAWER_STEP',
    PRICING_DRAWER_DATA_SUCCESS: 'PRICING_DRAWER_DATA_SUCCESS',
};

export const PRICING_DRAWER_TYPE = {
    UPGRADE: 'UPGRADE',
    CHANGE: 'CHANGE',
};
