import { conversationsService } from '@services';
import { conversationsConstants } from '@constants/conversations';

export const conversationsActions = {
    get,
    loadMore,
    update,
    getCounters,
    setRequiresRefetch,
    success,
    reset,
    getArchivedCounters,
};

function get(params, skip, take) {
    return dispatch => {
        dispatch(request());
        return conversationsService
            .get(params, skip, take)
            .then(data => dispatch(success(data)))
            .catch(() => dispatch(error()));
    };
}

function loadMore(params, skip, take) {
    return dispatch => {
        return conversationsService
            .get(params, skip, take)
            .then(data => dispatch(successLoadMore(data)))
            .catch(() => dispatch(error()));
    };
}

function getCounters(params) {
    return dispatch => {
        return conversationsService
            .getCounters(params)
            .then(data => dispatch(successCounters(data)))
            .catch(() => dispatch(error()));
    };
}

function getArchivedCounters() {
    return dispatch => {
        return conversationsService
            .getArchivedCount()
            .then(data => dispatch(successArchived(data)))
            .catch(() => dispatch(error()));
    };
}

function request() {
    return { type: conversationsConstants.CONVERSATIONS_FETCH };
}

function success(payload) {
    return { type: conversationsConstants.CONVERSATIONS_SUCCESS, payload };
}

function successLoadMore(payload) {
    return { type: conversationsConstants.CONVERSATIONS_LOAD_MORE_SUCCESS, payload };
}

function successCounters(payload) {
    return { type: conversationsConstants.CONVERSATIONS_COUNTERS_SUCCESS, payload };
}

function successArchived(payload) {
    return { type: conversationsConstants.CONVERSATIONS_ARCHIVED_SUCCESS, payload };
}

function error() {
    return { type: conversationsConstants.CONVERSATIONS_ERROR };
}

function update(data) {
    return { type: conversationsConstants.CONVERSATIONS_UPDATE, data };
}

function setRequiresRefetch(data) {
    return { type: conversationsConstants.CONVERSATIONS_REQUIRES_REFETCH, data };
}

function reset() {
    return { type: conversationsConstants.CONVERSATIONS_RESET };
}
