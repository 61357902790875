import { styled } from '@mui/system';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { Typography } from '@components/Typography';
import { Checkbox } from '@components/Checkbox';
import { ExternalLink } from '@components/Link';
import LanguageSelector from '@components/PreviewLanguageSelector';
import {
    StyledInput,
    Container,
    Header,
    Footer,
    SubmitButton,
    HeaderTitle,
    AgreementWrapper,
    FooterTitle,
    FooterStars,
} from './styles';
import { CustomFieldType } from '../EditModal/AdditionalField';

export const StyledTextArea = styled(StyledInput)({
    height: '102px',
    '& fieldset': {
        height: '102px',
    },
    '& textarea': {
        height: '102px',
    },
});

const Negative = ({ t, language, systemFields, customFields }) => {
    const emailField = systemFields.Email;
    const phoneField = systemFields.Phone;
    const nameField = systemFields.Name;
    const hasRequiredField =
        emailField?.required ||
        phoneField?.required ||
        nameField?.required ||
        (customFields || []).some(f => f?.required);
    return (
        <Container>
            <Header>
                <LanguageSelector selectedLanguage={language} />
                <div>
                    <HeaderTitle>{t.negativeTitle}</HeaderTitle>
                    <p style={{ color: '#1f4c5c' }}>{t.negativeMessage}</p>
                </div>
            </Header>
            <Footer>
                {hasRequiredField ? (
                    <div style={{ marginBottom: 8 }}>
                        <FooterTitle>{t.negativeFooterTitle}</FooterTitle>
                        <StyledTextArea label={t.negativeFooterPlaceholder} fullWidth multiline disabled minRows={3} />
                    </div>
                ) : (
                    <FooterStars style={{ margin: '0 auto' }}>
                        {emailField.enable && (
                            <StyledInput
                                inputProps={{ value: '' }}
                                label={t.email}
                                fullWidth
                                required={emailField.required}
                                disabled
                            />
                        )}
                        {phoneField.enable && (
                            <StyledInput
                                inputProps={{ value: '' }}
                                label={t.phone}
                                fullWidth
                                required={phoneField.required}
                                disabled
                            />
                        )}
                        {nameField.enable && (
                            <StyledInput
                                inputProps={{ value: '' }}
                                label={t.name}
                                fullWidth
                                required={nameField.required}
                                disabled
                            />
                        )}
                        {customFields.map((field, index) => (
                            <StyledInput
                                key={index}
                                inputProps={{ value: '' }}
                                label={field.name}
                                fullWidth
                                required={field.required}
                                disabled
                                addonWithoutWrapper
                                addonComponent={
                                    field.type === CustomFieldType.DATE ? (
                                        <CalendarTodayRoundedIcon
                                            sx={{
                                                fill: 'rgba(0, 0, 0, 0.38)',
                                                fontSize: 12,
                                            }}
                                        />
                                    ) : null
                                }
                            />
                        ))}
                        <StyledTextArea label={t.negativeFooterPlaceholder} fullWidth multiline disabled minRows={2} />
                        <AgreementWrapper>
                            <Checkbox
                                checked
                                disabled
                                style={{
                                    marginRight: '4px',
                                    marginLeft: '-5px',
                                    height: '36px',
                                    padding: '4px',
                                }}
                            />
                            <Typography
                                variant="caption"
                                sx={{
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    textAlign: 'start',
                                    fontSize: '10px !important',
                                    '& a': {
                                        fontSize: '10px',
                                    },
                                }}
                            >
                                {t.iAgreeTo}
                                <ExternalLink variant="caption" underline="always">
                                    {' '}
                                    {t.pp}
                                </ExternalLink>
                            </Typography>
                        </AgreementWrapper>
                    </FooterStars>
                )}
                <SubmitButton variant="contained">{t.negativeFooterButton}</SubmitButton>
            </Footer>
        </Container>
    );
};

export default Negative;
