import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import TableContainer from '@mui/material/TableContainer';
import FiltersPanel from '@pages/AnalyticsPage/Conversion/FiltersPanel';
import Button from '@components/Button';
import { Typography } from '@components/Typography';
import Tooltip from '@components/Tooltip';
import { keywordsActions, labelsActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';
import { subtractYears } from '@helpers/dates';
import { LABEL_TYPE } from '@constants/labels';
import { getLocationLabels } from '@helpers/locationLabels';
import { StyledTable, Container, SentimentWrapper, SentimentPiece } from './styles';

const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('AnalyticsKeywords.keyword')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsKeywords.avgRating')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="right" variant="subtitle2">
                    {t('AnalyticsKeywords.numberOfReviews')}
                </Typography>
            ),
        },
        {
            key: 3,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('AnalyticsKeywords.sentiment')}
                </Typography>
            ),
        },
        {
            key: 4,
            component: <Typography align="left" variant="subtitle2"></Typography>,
        },
    ];
};

const GetTableBody = (data, onClickKeyword) => {
    const { t } = useTranslation();
    return data.map(keyword => {
        const positive = keyword.sentiments.Positive || 0;
        const neutral = keyword.sentiments.Neutral || 0;
        const negative = keyword.sentiments.Negative || 0;
        const total = positive + neutral + negative;
        const positivePercent = (positive * 100) / total;
        const neutralPercent = (neutral * 100) / total;
        const negativePercent = (negative * 100) / total;

        const positiveTooltip = positive > 0 ? `${t('FeedbackSurveys.positive')}: ${positive}` : '';
        const neutralTooltip = neutral > 0 ? `${t('FeedbackSurveys.neutral')}: ${neutral}` : '';
        const negativeTooltip = negative > 0 ? `${t('FeedbackSurveys.negative')}: ${negative}` : '';

        const tooltipText = [positiveTooltip, neutralTooltip, negativeTooltip].filter(Boolean).join(', ');

        return [
            {
                component: (
                    <Typography variant="subtitle2" align="left">
                        {keyword.keyword}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="right">
                        {keyword.averageRating.toFixed(2)}
                    </Typography>
                ),
            },
            {
                component: (
                    <Typography variant="body2" align="right">
                        {keyword.numberOfReviews}
                    </Typography>
                ),
            },
            {
                component: (
                    <Tooltip title={tooltipText} placement="top">
                        <SentimentWrapper>
                            <SentimentPiece type={1} percentage={positivePercent} />
                            <SentimentPiece type={0} percentage={neutralPercent} />
                            <SentimentPiece type={2} percentage={negativePercent} />
                        </SentimentWrapper>
                    </Tooltip>
                ),
            },
            {
                component: (
                    <Button variant="outlined" onClick={() => onClickKeyword(keyword.keyword)}>
                        {t('buttons.viewReviews')}
                    </Button>
                ),
                value: true,
            },
        ];
    });
};

const Keywords = () => {
    const { t } = useTranslation();
    const [filter, setFilter] = useState({
        labels: [],
        companies: [],
        timeframe: {
            start: subtractYears(new Date(), 1),
            end: new Date(),
        },
    });
    const [filterOpened, setFiltersOpened] = useState(false);
    const [filterNumber, setFilterNumber] = useState(0);
    const [pageRequests, setPageRequests] = useState(0);
    const [rowsPerPageRequests, setRowsPerPageRequests] = useState(20);

    const firstRender = useRef(true);
    const isMobile = useMediaQuery('@media (max-width: 1024px)');

    const companies = useSelector(state => state.account?.account?.companies || []);
    const { location: labels, locationLoading: labelsLoading } = useSelector(state => state.labels);
    const { data, loading, totalCount, pages } = useSelector(state => state.keywords);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
    }, [dispatch]);

    const availableLabels = useMemo(() => getLocationLabels(labels, companies, t), [labels, companies]);

    useEffect(() => {
        if (companies.length && !labelsLoading) {
            setFilter(prev => ({
                ...prev,
                companies: companies.map(item => item.companyId),
                labels: availableLabels.map(item => item.id),
            }));
        }
    }, [companies, labelsLoading, dispatch]);

    const onApplyFilters = () => {
        dispatch(
            keywordsActions.get(
                {
                    companies: filter.companies,
                    from: filter.timeframe.start ? format(new Date(filter.timeframe.start), 'yyyy-MM-dd') : null,
                    to: filter.timeframe.end ? format(new Date(filter.timeframe.end), 'yyyy-MM-dd') : null,
                },
                0,
                rowsPerPageRequests,
            ),
        );
        setPageRequests(0);
    };

    useEffect(() => {
        if (filter.companies.length) {
            if (firstRender.current) {
                onApplyFilters();
                firstRender.current = false;
            }
        }
    }, [filter, rowsPerPageRequests, pageRequests, dispatch]);

    useEffect(() => {
        if (filter.companies.length) {
            if (!firstRender.current) {
                onApplyFilters();
            }
        }
    }, [rowsPerPageRequests, pageRequests, dispatch]);

    const onPageChange = (_, newPage) => setPageRequests(newPage);

    const onRowsPerPageChange = event => {
        setRowsPerPageRequests(parseInt(event.target.value, 10));
        setPageRequests(0);
    };

    const onClickKeyword = keyword => history.push(`/reviews/manage?keyword=${keyword}`);

    return (
        <Container>
            <div style={{ marginBottom: 16 }}>
                <FiltersPanel
                    setFiltersOpened={setFiltersOpened}
                    filtersNumber={filterNumber}
                    filterOpened={filterOpened}
                    companies={companies}
                    labels={availableLabels}
                    filter={filter}
                    setFilter={setFilter}
                    setFilterNumber={setFilterNumber}
                    onApplyFilters={onApplyFilters}
                />
            </div>
            <section style={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: 4, padding: 16 }}>
                <Typography variant="subtitle2" sx={{ marginBottom: '16px', color: 'rgba(0, 0, 0, 0.87)' }}>
                    {t('AnalyticsKeywords.tableTitle')}
                </Typography>
                <TableContainer>
                    <StyledTable
                        withHover
                        columnNames={GetTableHeader()}
                        rows={GetTableBody(data, onClickKeyword)}
                        isDataLoading={loading}
                        withPagination
                        isInteractive={!isMobile}
                        isCustomActionComponent
                        paginationProps={{
                            colSpan: 5,
                            count: totalCount,
                            rowsPerPage: rowsPerPageRequests,
                            page: pageRequests,
                            onPageChange,
                            onRowsPerPageChange,
                            labelDisplayedRows: ({ from, to, count }) =>
                                `${from}–${to} ${t('Table.of')} ${
                                    count !== -1 ? count : `${t('Table.moreThan')} ${to}`
                                }`,
                            labelRowsPerPage: <span>{t('Table.rowsPerPage')}</span>,
                            pages,
                        }}
                    />
                </TableContainer>
            </section>
        </Container>
    );
};

export default Keywords;
