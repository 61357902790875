import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@components/Button';
import Drawer from '@components/Drawer';
import { AccIntegrationName } from '@constants/integrations';
import { accountActions, alertActions, googleActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';
import { companyService } from '@services';
import GoogleCompanyCard from './GoogleCompanyCard';

export const Progress = styled(CircularProgress)({
    color: 'rgba(0, 0, 0, 0.38)',
    width: '58px !important',
    height: '58px !important',
    display: 'block',
    margin: '0 auto 24px',
});

const ImportGoogleCompanies = ({ disabled }) => {
    const { t } = useTranslation();

    const [drawerOpened, setDrawerOpened] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const account = useSelector(state => state.account?.account);
    const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();

    const GOOGLE_CONNECTED = account?.integrations.find(i => i.type === AccIntegrationName.Google)?.isActive;
    const IS_ACCOUNT_OWNER = user?.isAccountOwner || user?.isGlobalAdmin;
    const LOCATIONS = useSelector(state => state.google.data);
    const locationsLoading = useSelector(state => state.google.loading);
    const MAX_AVAILABLE_COMPANIES_COUNT = account?.companiesMaxCount - account?.companies?.length;

    useEffect(() => {
        if (GOOGLE_CONNECTED && !LOCATIONS.length) {
            dispatch(googleActions.get());
        }
    }, [dispatch, GOOGLE_CONNECTED, LOCATIONS.length]);

    const handleOpen = () => setDrawerOpened(true);
    const handleClose = () => setDrawerOpened(false);

    const onCheckCompany = index => {
        if (companies.includes(index)) {
            setCompanies(companies.filter(c => c !== index));
            return;
        }
        if (companies.length < MAX_AVAILABLE_COMPANIES_COUNT) {
            setCompanies([...new Set([...companies, index])]);
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            const data = companies.map(index => LOCATIONS[index]);
            await companyService.importGoogleCompanies(data);
            await dispatch(accountActions.get());
            dispatch(alertActions.success(t('alertMessages.importSuccess')));
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.importFail')));
            }
        }
        handleClose();
        setIsSubmitting(false);
    };

    return IS_ACCOUNT_OWNER && GOOGLE_CONNECTED ? (
        <>
            <Button type="button" variant="outlined" onClick={handleOpen} disabled={disabled}>
                {t('buttons.importFromGoogle')}
            </Button>
            <Drawer
                anchor="right"
                open={drawerOpened}
                onClose={handleClose}
                title={t('SettingsCompanies.importCompanies')}
                subtitle={t('SettingsCompanies.selectCompanies')}
                primaryText={t('SettingsCompanies.importCompanies')}
                primaryDisabled={!companies.length || isSubmitting}
                primaryAction={handleSubmit}
                secondaryText={t('buttons.cancel')}
            >
                {!locationsLoading ? (
                    <div style={{ display: 'grid', gridGap: '8px' }}>
                        {LOCATIONS.map((l, index) => (
                            <GoogleCompanyCard
                                key={l.name}
                                location={l}
                                selectedCompanies={companies}
                                setCompany={onCheckCompany}
                                index={index}
                            />
                        ))}
                    </div>
                ) : (
                    <Progress />
                )}
            </Drawer>
        </>
    ) : null;
};

export default ImportGoogleCompanies;
