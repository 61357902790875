import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CssTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} PopperProps={{ style: { zIndex: 9999 } }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(0, 0, 0, 0.87)',
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '0.4px',
        fontFamily: 'Rubik',
        padding: '5px 8px',
    },
    [`& .${tooltipClasses.popper}`]: {
        zIndex: 9999,
    },
}));

export default CssTooltip;
