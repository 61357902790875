import { init as initGTM } from './gtm';
import { init as initAppHeight } from './app-height';
import { init as initSentry } from './sentry';
import { initFacebookSdk } from './init-facebook';

const initPlugins = () => {
    initSentry();
    initAppHeight();
    initGTM();
    initFacebookSdk();
};

export default initPlugins;
