import Rating from '@mui/material/Rating';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { Typography } from '@components/Typography';
import { Checkbox } from '@components/Checkbox';
import { ExternalLink } from '@components/Link';
import LanguageSelector from '@components/PreviewLanguageSelector';
import {
    Container,
    Header,
    Footer,
    SubmitButton,
    HeaderTitle,
    AgreementWrapper,
    FooterTitle,
    FooterStars,
    StyledInput,
} from './styles';
import { CustomFieldType } from '../EditModal/AdditionalField';

const Main = ({ company, t, language, systemFields, customFields }) => {
    const emailField = systemFields.Email;
    const phoneField = systemFields.Phone;
    const nameField = systemFields.Name;
    const hasRequiredField =
        emailField?.required ||
        phoneField?.required ||
        nameField?.required ||
        (customFields || []).some(f => f?.required);
    return (
        <Container>
            <Header>
                <LanguageSelector selectedLanguage={language} />
                <div>
                    <HeaderTitle>{t.headerTitle}</HeaderTitle>
                    <p style={{ color: '#1f4c5c' }}>
                        <span style={{ display: 'block' }}>
                            <b>{company.locationName}</b>
                        </span>
                        {company.hasBusinessAddress ? `${company.addressLine1}, ${company.city}` : ''}
                    </p>
                </div>
            </Header>
            <Footer>
                <div>
                    <FooterTitle>{t.footerTitle}</FooterTitle>
                    <FooterStars style={{ marginBottom: 8 }}>
                        {hasRequiredField && (
                            <>
                                {emailField.enable && (
                                    <StyledInput
                                        inputProps={{ value: '' }}
                                        label={t.email}
                                        fullWidth
                                        required={emailField.required}
                                        disabled
                                    />
                                )}
                                {phoneField.enable && (
                                    <StyledInput
                                        inputProps={{ value: '' }}
                                        label={t.phone}
                                        fullWidth
                                        required={phoneField.required}
                                        disabled
                                    />
                                )}
                                {nameField.enable && (
                                    <StyledInput
                                        inputProps={{ value: '' }}
                                        label={t.name}
                                        fullWidth
                                        required={nameField.required}
                                        disabled
                                    />
                                )}
                                {customFields.map((field, index) => (
                                    <StyledInput
                                        key={index}
                                        inputProps={{ value: '' }}
                                        label={field.name}
                                        fullWidth
                                        required={field.required}
                                        disabled
                                        addonWithoutWrapper
                                        addonComponent={
                                            field.type === CustomFieldType.DATE ? (
                                                <CalendarTodayRoundedIcon
                                                    sx={{
                                                        fill: 'rgba(0, 0, 0, 0.38)',
                                                        fontSize: 12,
                                                    }}
                                                />
                                            ) : null
                                        }
                                    />
                                ))}
                            </>
                        )}
                        <Rating
                            value={5}
                            emptyIcon={<StarBorderIcon fontSize="inherit" />}
                            disabled
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: '28px',
                                },
                            }}
                        />
                    </FooterStars>
                    {hasRequiredField && (
                        <AgreementWrapper>
                            <Checkbox
                                style={{
                                    marginRight: '4px',
                                    marginLeft: '-5px',
                                    height: '36px',
                                    padding: '4px',
                                }}
                                checked
                                disabled
                            />
                            <Typography
                                variant="caption"
                                sx={{
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    textAlign: 'start',
                                    fontSize: '10px !important',
                                    '& a': {
                                        fontSize: '10px',
                                    },
                                }}
                            >
                                {t.iAgreeTo}
                                <ExternalLink variant="caption" underline="always">
                                    {' '}
                                    {t.pp}
                                </ExternalLink>
                            </Typography>
                        </AgreementWrapper>
                    )}
                </div>
                <SubmitButton variant="contained">{t.footerButton}</SubmitButton>
            </Footer>
        </Container>
    );
};

export default Main;
