import { useFormContext } from 'react-hook-form';
import { useTranslation } from '@hooks/useTranslation';
import LocationAutocomplete from '@features/LocationAutocomplete';

const GoogleProfile = () => {
    const { t } = useTranslation();
    const { setValue } = useFormContext();

    const onClearPlace = () => setValue('profileUrl', '', { shouldValidate: true, shouldDirty: true });

    const handleSelect = option => {
        const { place_id: placeId } = option;
        if (placeId) {
            setValue('profileUrl', `https://www.google.com/maps/place/?q=place_id:${placeId}`, {
                shouldValidate: true,
                shouldDirty: true,
            });
        }
    };

    return (
        <LocationAutocomplete
            label={t('SettingsCompanies.googleAutocomplete')}
            onSelectOption={handleSelect}
            autocompleteRequestOptions={{
                fields: ['address_components', 'geometry', 'icon', 'name'],
                types: ['establishment'],
                strictBounds: true,
            }}
            onClearPlace={onClearPlace}
        />
    );
};

export default GoogleProfile;
