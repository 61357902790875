import { styled, keyframes } from '@mui/system';

const Slider = styled('div')({
    position: 'absolute',
    width: '100%',
    height: '4px',
    overflow: 'hidden',
    zIndex: 1,
    top: '-4px',
});

const Subline = styled('div')({
    position: 'absolute',
    background: '#1F4C5C',
    height: '5px',
});

const increase = keyframes`
    from {
        left: -5%;
        width: 5%;
    }
    to {
        left: 130%;
        width: 100%;
    }
`;

const decrease = keyframes`
    from {
        left: -80%;
        width: 80%;
    }
    to {
        left: 110%;
        width: 10%;
    }
`;

const Increase = styled(Subline)({
    animation: `${increase} 2s infinite`,
});

const Decrease = styled(Subline)({
    animation: `${decrease} 2s 0.5s infinite`,
});

export const AuthProgress = () => (
    <Slider>
        <Increase />
        <Decrease />
    </Slider>
);
