import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@components/Typography';
import Tooltip from '@components/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Table } from '@components/Table';
import { LabelPlain } from '@components/Label';
import Avatar from '@components/Avatar';
import { useTranslation } from '@hooks/useTranslation';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { getInitialsParts } from '@helpers/userName';
import { formatCompanies } from '@helpers/companies';
import { getFullName } from '@helpers/userName';
import { UserIcon } from 'assets/images/icons';

const StyledUserIcon = styled(UserIcon)({
    width: '12px !important',
    height: '12px !important',
    '& path': {
        fill: 'rgba(0, 0, 0, 0.38)',
        fillOpacity: 1,
    },
});

const StyledTable = styled(Table)({
    '& .MuiTableBody-root .MuiTableCell-root .MuiIconButton-root:not(:first-of-type)': {
        marginLeft: 8,
    },
    '& .MuiTableBody-root .MuiTableCell-body:last-child': {
        padding: 4,
    },
});

const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.name')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.phoneNumber')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.email')}
                </Typography>
            ),
        },
        {
            key: 3,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.locations')}
                </Typography>
            ),
        },
        {
            key: 4,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.labels')}
                </Typography>
            ),
        },
        {
            key: 5,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('ContactsPage.createdOn')}
                </Typography>
            ),
        },
        {
            key: 6,
            component: <Typography align="left" variant="subtitle2" />,
        },
    ];
};

const GetTableBody = (data, language, companies, onClickContact) => {
    const { t } = useTranslation();
    return data.map(contact => [
        {
            component: (
                <Typography
                    variant="subtitle2"
                    align="left"
                    sx={{
                        width: 180,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        size="small"
                        fullName={getFullName(contact?.firstName, contact?.lastName)}
                        label={getInitialsParts(contact?.firstName, contact?.lastName)}
                        icon={<StyledUserIcon />}
                    />
                    <span style={{ marginLeft: 8 }}>{getFullName(contact.firstName, contact.lastName, '-')}</span>
                </Typography>
            ),
        },
        {
            component: (
                <Typography variant="body2" align="left">
                    {contact.phoneNumber || '--'}
                </Typography>
            ),
        },
        {
            component: (
                <Typography
                    variant="body2"
                    align="left"
                    sx={{
                        color: !contact.email && 'rgba(0, 0, 0, 0.6)',
                        width: 145,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {contact.email || '--'}
                </Typography>
            ),
        },
        {
            component: (
                <Typography variant="body2" align="left">
                    {formatCompanies(companies, contact.companies, 20)}
                </Typography>
            ),
        },
        {
            component: (
                <Typography variant="body2" align="left">
                    {contact.labels?.length ? (
                        <Tooltip placement="top" title={contact.labels.map(l => l.name).join(', ')}>
                            <span style={{ width: 120, overflow: 'hidden', display: 'flex' }}>
                                {contact.labels.map(label => (
                                    <LabelPlain key={label.id} type={label.name} variant="caption">
                                        {label.name}
                                    </LabelPlain>
                                ))}
                            </span>
                        </Tooltip>
                    ) : (
                        '--'
                    )}
                </Typography>
            ),
        },
        {
            component: (
                <Typography variant="body2" align="left" sx={{ width: 90 }}>
                    {format(new Date(contact.createdOn), 'dd MMM yyyy', { locale: I18N_DATE_FORMAT[language] })}
                </Typography>
            ),
        },
        {
            component: (
                <Typography align="right" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip title={t('common.view')} placement="top">
                        <IconButton onClick={() => onClickContact(contact, 'view')}>
                            <VisibilityIcon viewBox="0 0 22 22" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('common.edit')} placement="top">
                        <IconButton onClick={() => onClickContact(contact, 'edit')}>
                            <EditIcon viewBox="0 0 22 22" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('common.delete')} placement="top">
                        <IconButton onClick={() => onClickContact(contact, 'delete')}>
                            <DeleteIcon viewBox="0 0 22 22" />
                        </IconButton>
                    </Tooltip>
                </Typography>
            ),
        },
    ]);
};

const ContactsTable = ({
    companies,
    onClickContact,
    selectedContacts,
    setSelectedContacts,
    rowsPerPageRequests,
    pageRequests,
    onPageChange,
    onRowsPerPageChange,
}) => {
    const { t } = useTranslation();
    const { data, loading, totalCount, pages } = useSelector(state => state.contacts);
    const language = useSelector(state => state.authentication.user?.language);

    return (
        <TableContainer>
            <StyledTable
                withHover
                data={data}
                columnNames={GetTableHeader()}
                rows={GetTableBody(data, language, companies, onClickContact)}
                isDataLoading={loading}
                withCheckbox
                entity={t('ContactsPage.contacts')}
                selectedItems={selectedContacts}
                setSelectedItems={setSelectedContacts}
                withPagination
                paginationProps={{
                    colSpan: 8,
                    count: totalCount,
                    rowsPerPage: rowsPerPageRequests,
                    rowsPerPageOptions: [20, 50, 100],
                    page: pageRequests,
                    onPageChange,
                    onRowsPerPageChange,
                    labelDisplayedRows: ({ from, to, count }) =>
                        `${from}–${to} ${t('Table.of')} ${count !== -1 ? count : `${t('Table.moreThan')} ${to}`}`,
                    labelRowsPerPage: <span>{t('Table.rowsPerPage')}</span>,
                    pages,
                }}
            />
        </TableContainer>
    );
};

export default ContactsTable;
