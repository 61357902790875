export const MODAL_TYPE = {
    CREATE: 0,
    EDIT: 1,
    VIEW: 2,
};

export const CONTACT_MODAL_TYPE = {
    DIRECT: 0,
    BULK: 1,
};
