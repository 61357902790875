import { sendInvitesUiConstaints } from '@constants/send-invites-ui';

const initialState = {
    isOpen: false,
};

export function sendInvitesUi(state = initialState, action) {
    switch (action.type) {
        case sendInvitesUiConstaints.SET_SEND_INVITES_OPEN:
            return { ...state, isOpen: action.payload };
        default:
            return state;
    }
}
