import { replyTemplatesConstants } from '@constants/reply-templates';
import { replyTemplatesService } from '@services';

export const replyTemplatesActions = {
    get,
    getAll,
};

function get(page = 0, pageSize = 10) {
    return dispatch => {
        dispatch(request());
        return replyTemplatesService.getReplyTemplates(page, pageSize).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error)),
        );
    };
}

function getAll() {
    return dispatch => {
        dispatch(request());
        return replyTemplatesService.getAllReplyTemplates().then(
            data => dispatch(successAllActive(data.items)),
            error => dispatch(failure(error)),
        );
    };
}

function request() {
    return { type: replyTemplatesConstants.REPLY_TEMPLATES_REQUEST };
}

function success(data) {
    return { type: replyTemplatesConstants.REPLY_TEMPLATES_SUCCESS, data };
}

function successAllActive(data) {
    return { type: replyTemplatesConstants.REPLY_ALL_ACTIVE_TEMPLATES_SUCCESS, data };
}

function failure(message) {
    return { type: replyTemplatesConstants.REPLY_TEMPLATES_ERROR, message };
}
