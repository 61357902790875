import { analyticsConstants } from '@constants/analytics';
import { analyticsService } from '@services';
import {
    prepareReviewsAndFeedbackOverall,
    formattedConversionData,
    prerareSentimentData,
    prepareNpsData,
} from '@helpers/analytics';

export const analyticsActions = {
    getAnalyticsConversion,
    getAnalyticsReviews,
    getAnalyticsFeedback,
    getAnalyticsConversations,
    getAnalyticsSocialPosts,
    getAnalyticsPresence,
};

function getAnalyticsConversion(query) {
    return dispatch => {
        dispatch(analyticsConversionRequest());

        analyticsService.getAnalyticsConversion(query).then(
            data => {
                const { qrConversionByLocation, surveyConversionByLocation, totalQrConversion, totalSurveyConversion } =
                    data;
                const qr = formattedConversionData(qrConversionByLocation, totalQrConversion);
                const surveys = formattedConversionData(surveyConversionByLocation, totalSurveyConversion);
                dispatch(analyticsConversionSuccess({ surveys, qr }));
            },
            error => dispatch(analyticsConversionFailure(error)),
        );
    };
}

const analyticsConversionRequest = () => ({
    type: analyticsConstants.ANALYTICS_CONVERSION_REQUEST,
});

const analyticsConversionSuccess = data => ({
    type: analyticsConstants.ANALYTICS_CONVERSION_SUCCESS,
    payload: data,
});

const analyticsConversionFailure = error => ({
    type: analyticsConstants.ANALYTICS_CONVERSION_FAILURE,
    payload: error,
});

function getAnalyticsReviews(query) {
    return dispatch => {
        dispatch(analyticsReviewsRequest());

        analyticsService.getAnalyticsReviews(query).then(
            data => {
                const { locationsDistribution, sentimentDistribution, profilesDistribution } = data;

                const overall = prepareReviewsAndFeedbackOverall(data);
                const tableData = locationsDistribution;
                const channelData = profilesDistribution;
                const sentimentData = prerareSentimentData(sentimentDistribution);

                dispatch(
                    analyticsReviewsSuccess({
                        tableData,
                        overall,
                        channelData,
                        sentimentData,
                    }),
                );
            },
            error => dispatch(analyticsReviewsFailure(error)),
        );
    };
}

const analyticsReviewsRequest = () => ({
    type: analyticsConstants.ANALYTICS_REVIEWS_REQUEST,
});

const analyticsReviewsSuccess = data => ({
    type: analyticsConstants.ANALYTICS_REVIEWS_SUCCESS,
    payload: data,
});

const analyticsReviewsFailure = error => ({
    type: analyticsConstants.ANALYTICS_REVIEWS_FAILURE,
    payload: error,
});

function getAnalyticsFeedback(query) {
    return dispatch => {
        dispatch(analyticsFeedbackRequest());

        analyticsService.getAnalyticsFeedback(query).then(
            data => {
                const { templatesDistribution, sentimentDistribution, npsDistribution } = data;

                const overall = prepareReviewsAndFeedbackOverall(data);
                const tableData = templatesDistribution;
                const sentimentData = prerareSentimentData(sentimentDistribution);
                const npsData = prepareNpsData(npsDistribution);

                dispatch(
                    analyticsFeedbackSuccess({
                        tableData,
                        overall,
                        sentimentData,
                        npsData,
                    }),
                );
            },
            error => dispatch(analyticsFeedbackFailure(error)),
        );
    };
}

const analyticsFeedbackRequest = () => ({
    type: analyticsConstants.ANALYTICS_FEEDBACK_REQUEST,
});

const analyticsFeedbackSuccess = data => ({
    type: analyticsConstants.ANALYTICS_FEEDBACK_SUCCESS,
    payload: data,
});

const analyticsFeedbackFailure = error => ({
    type: analyticsConstants.ANALYTICS_FEEDBACK_FAILURE,
    payload: error,
});

function getAnalyticsConversations(data) {
    return dispatch => {
        analyticsService.getAnalyticsConversations(data).then(
            data =>
                dispatch(
                    analyticsConversationsSuccess({
                        tableData: data.users || [],
                        trendData: data.trends || [],
                        rates: {
                            closedConversationsCount: data.closedConversationsCount,
                            closedConversationsCountDelta: data.closedConversationsCountDelta,
                            conversationsCount: data.conversationsCount,
                            conversationsCountDelta: data.conversationsCountDelta,
                            openConversationsCount: data.openConversationsCount,
                            resolutionRate: data.resolutionRate,
                            resolutionRateDelta: data.resolutionRateDelta,
                            resolutionTime: data.resolutionTime,
                            resolutionTimeDelta: data.resolutionTimeDelta,
                            responseRate: data.responseRate,
                            responseRateDelta: data.responseRateDelta,
                            responseTime: data.responseTime,
                            responseTimeDelta: data.responseTimeDelta,
                            unassignedConversationsCount: data.unassignedConversationsCount,
                            channelsBreakdown: data.channelsBreakdown || [],
                        },
                    }),
                ),
            error => dispatch(analyticsConversationsFailure(error)),
        );
    };
}

const analyticsConversationsSuccess = data => ({
    type: analyticsConstants.ANALYTICS_CONVERSATIONS_SUCCESS,
    payload: data,
});

const analyticsConversationsFailure = error => ({
    type: analyticsConstants.ANALYTICS_CONVERSATIONS_FAILURE,
    payload: error,
});

function getAnalyticsSocialPosts(data) {
    return dispatch => {
        analyticsService.getAnalyticsSocialPosts(data).then(
            data =>
                dispatch(
                    analyticsSocialPostsSuccess({
                        impressionsTrendData: data.impressionsDistributions || [],
                        reachTrendData: data.reachDistributions || [],
                        engagementsTrendData: data.engagementsDistributions || [],
                        tableData: data.postsDistributions || [],
                        rates: {
                            impressions: data.impressions,
                            impressionsDelta: data.impressionsDelta,
                            reach: data.reach,
                            reachDelta: data.reachDelta,
                            likes: data.likes,
                            likesDelta: data.likesDelta,
                            comments: data.comments,
                            commentsDelta: data.commentsDelta,
                            shares: data.shares,
                            sharesDelta: data.sharesDelta,
                        },
                    }),
                ),
            error => dispatch(analyticsSocialPostsFailure(error)),
        );
    };
}

const analyticsSocialPostsSuccess = data => ({
    type: analyticsConstants.ANALYTICS_SOCIAL_POSTS_SUCCESS,
    payload: data,
});

const analyticsSocialPostsFailure = error => ({
    type: analyticsConstants.ANALYTICS_SOCIAL_POSTS_FAILURE,
    payload: error,
});

function getAnalyticsPresence(data) {
    return dispatch => {
        dispatch(analyticsPresenceRequest());
        analyticsService.getAnalyticsPresence(data).then(
            data =>
                dispatch(
                    analyticsPresenceSuccess({
                        keywords: data.keywords,
                        devicesDistribution: data.devicesDistribution,
                        locationsDistribution: data.locationsDistribution,
                        engagementsDistribution: data.engagementsDistribution,
                        viewsDistribution: data.viewsDistribution,
                        rates: {
                            searchAppearance: data.searchAppearance,
                            searchAppearanceChange: data.searchAppearanceChange,
                            views: data.views,
                            viewsChange: data.viewsChange,
                            engagements: data.engagements,
                            engagementsChange: data.engagementsChange,
                            conversion: data.conversion,
                            conversionChange: data.conversionChange,
                        },
                    }),
                ),
            error => dispatch(analyticsPresenceFailure(error)),
        );
    };
}

const analyticsPresenceRequest = () => ({
    type: analyticsConstants.ANALYTICS_PRESENCE_REQUEST,
});

const analyticsPresenceSuccess = data => ({
    type: analyticsConstants.ANALYTICS_PRESENCE_SUCCESS,
    payload: data,
});

const analyticsPresenceFailure = error => ({
    type: analyticsConstants.ANALYTICS_PRESENCE_FAILURE,
    payload: error,
});
