import { LOCALE } from '@constants/locale';
import { BILLING_TIME_MAP, BILLING_TYPE_MAP } from '@constants/billing';

export const getPricingPlans = locale => {
    switch (locale) {
        case LOCALE.BRAZIL:
            return {
                [BILLING_TIME_MAP.MONTHLY]: {
                    users: 99,
                    price: {
                        [BILLING_TYPE_MAP.STARTER]: 199,
                        [BILLING_TYPE_MAP.PROFESSIONAL]: 349,
                        [BILLING_TYPE_MAP.ENTERPRISE]: 0,
                    },
                    symbol: 'R$',
                    currency: 'BRL',
                },
                [BILLING_TIME_MAP.ANNUAL]: {
                    users: 990,
                    price: {
                        [BILLING_TYPE_MAP.STARTER]: 1990,
                        [BILLING_TYPE_MAP.PROFESSIONAL]: 3490,
                        [BILLING_TYPE_MAP.ENTERPRISE]: 0,
                    },
                    symbol: 'R$',
                    currency: 'BRL',
                },
            };
        case LOCALE.PORTUGAL:
        case LOCALE.SPAIN:
        case LOCALE.POLAND:
        case LOCALE.GERMANY:
            return {
                [BILLING_TIME_MAP.MONTHLY]: {
                    users: 18,
                    price: {
                        [BILLING_TYPE_MAP.STARTER]: 36,
                        [BILLING_TYPE_MAP.PROFESSIONAL]: 65,
                        [BILLING_TYPE_MAP.ENTERPRISE]: 0,
                    },
                    symbol: '€',
                    currency: 'EUR',
                },
                [BILLING_TIME_MAP.ANNUAL]: {
                    users: 180,
                    price: {
                        [BILLING_TYPE_MAP.STARTER]: 360,
                        [BILLING_TYPE_MAP.PROFESSIONAL]: 650,
                        [BILLING_TYPE_MAP.ENTERPRISE]: 0,
                    },
                    symbol: '€',
                    currency: 'EUR',
                },
            };
        case LOCALE.UNITED_KINGDOM:
            return {
                [BILLING_TIME_MAP.MONTHLY]: {
                    users: 15,
                    price: {
                        [BILLING_TYPE_MAP.STARTER]: 32,
                        [BILLING_TYPE_MAP.PROFESSIONAL]: 55,
                        [BILLING_TYPE_MAP.ENTERPRISE]: 0,
                    },
                    symbol: '£',
                    currency: 'GBP',
                },
                [BILLING_TIME_MAP.ANNUAL]: {
                    users: 150,
                    price: {
                        [BILLING_TYPE_MAP.STARTER]: 320,
                        [BILLING_TYPE_MAP.PROFESSIONAL]: 550,
                        [BILLING_TYPE_MAP.ENTERPRISE]: 0,
                    },
                    symbol: '£',
                    currency: 'GBP',
                },
            };
        default:
            return {
                [BILLING_TIME_MAP.MONTHLY]: {
                    users: 19,
                    price: {
                        [BILLING_TYPE_MAP.STARTER]: 39,
                        [BILLING_TYPE_MAP.PROFESSIONAL]: 69,
                        [BILLING_TYPE_MAP.ENTERPRISE]: 0,
                    },
                    symbol: '$',
                    currency: 'USD',
                },
                [BILLING_TIME_MAP.ANNUAL]: {
                    users: 190,
                    price: {
                        [BILLING_TYPE_MAP.STARTER]: 390,
                        [BILLING_TYPE_MAP.PROFESSIONAL]: 690,
                        [BILLING_TYPE_MAP.ENTERPRISE]: 0,
                    },
                    symbol: '$',
                    currency: 'USD',
                },
            };
    }
};
