import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import { Typography } from '@components/Typography';
import { MESSAGE_STATUS, MESSAGE_TYPE, SENDER_TYPE } from '@constants/inbox';
import { useTranslation } from '@hooks/useTranslation';
import { getFullName } from '@helpers/userName';
import MessageContentWrapper from './MessageContentWrapper';
import { CHANNEL, STATUS } from '@constants/channels';

const Container = styled('div', {
    shouldForwardProp: prop => prop !== 'type',
})(({ type }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 4,
    maxWidth: 300,
    alignItems: type === MESSAGE_TYPE.OUTGOING ? 'flex-end' : 'flex-start',
    alignSelf: type === MESSAGE_TYPE.OUTGOING ? 'flex-end' : 'flex-start',
    '@media (max-width: 768px)': {},
}));

const TextLink = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.6)',
    marginLeft: '4px',
    cursor: 'pointer',
    '&:hover': {
        color: 'rgba(0, 0, 0, 0.8)',
    },
});

const messageIconStyles = {
    marginLeft: '4px',
    maxWidth: '12px',
    maxHeight: '14px',
};
const SentIcon = styled(DoneIcon)(() => ({ ...messageIconStyles, color: 'rgba(0, 0, 0, 0.38)' }));
const DeliveredIcon = styled(DoneAllIcon)(() => ({ ...messageIconStyles, color: 'rgba(0, 0, 0, 0.38)' }));
const FailedIcon = styled(ErrorIcon)(() => ({ ...messageIconStyles, color: 'rgba(0, 0, 0, 0.6)' }));
const ReadIcon = styled(DoneAllIcon)(() => ({ ...messageIconStyles, color: '#1F4C5C' }));

const Message = ({ data, users, companies, assignedLocation, onResend, channel, channelStatus }) => {
    const [resendDisabled, setResendDisabled] = useState(false);
    const { t } = useTranslation();

    const isOutgoing = data.type === MESSAGE_TYPE.OUTGOING;

    const handleResend = () => {
        setResendDisabled(true);
        onResend();
    };

    const senderName = useMemo(() => {
        const user = users.find(user => user.id === data.senderId);
        return getFullName(user?.firstName, user?.lastName);
    }, [data.senderId, users]);

    const internalName = useMemo(() => {
        return companies.find(c => c.companyId === assignedLocation)?.internalName;
    }, [data.companyId, companies]);

    const messagesContent = useMemo(() => {
        // sort for clusterization
        return data.children.length === 1
            ? data.children
            : data.children.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    }, [data.children]);

    return (
        <Container type={data.type}>
            {isOutgoing ? (
                <Typography
                    variant="caption"
                    sx={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'right', marginBottom: '4px' }}
                >
                    {data.senderType === SENDER_TYPE.USER ? senderName : 'Pluspoint (Bot)'}
                </Typography>
            ) : null}
            {!isOutgoing && channel !== CHANNEL.FACEBOOK && channel !== CHANNEL.INSTAGRAM && assignedLocation ? (
                <Typography
                    variant="caption"
                    sx={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'left', marginBottom: '4px' }}
                >
                    {internalName}
                </Typography>
            ) : null}
            {data.children.length === 1 ? (
                <MessageContentWrapper
                    data={data.children[0]}
                    type={data.type}
                    isLast
                    users={users}
                    companies={companies}
                    assignedLocation={assignedLocation}
                    isOutgoing={isOutgoing}
                    messageStatus={data.status}
                />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: isOutgoing ? 'flex-end' : 'flex-start',
                    }}
                >
                    {messagesContent.map((item, index) => (
                        <MessageContentWrapper
                            key={index}
                            data={item}
                            multiple
                            isLast={index === messagesContent.length - 1}
                            type={data.type}
                            users={users}
                            companies={companies}
                            assignedLocation={assignedLocation}
                            isOutgoing={isOutgoing}
                            messageStatus={data.status}
                        />
                    ))}
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    justifyContent: isOutgoing ? 'flex-end' : 'flex-start',
                    alignItems: 'center',
                    marginTop: 4,
                }}
            >
                <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                    {format(new Date(data.createdAt), 'dd/MM • HH:mm')}
                </Typography>
                {isOutgoing && data.status === MESSAGE_STATUS.FAILED ? (
                    <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: '4px' }}>
                        {t('InboxPage.failed')}
                    </Typography>
                ) : null}
                {isOutgoing && data.status === MESSAGE_STATUS.SENT ? <SentIcon /> : null}
                {isOutgoing && data.status === MESSAGE_STATUS.DELIVERED ? <DeliveredIcon /> : null}
                {isOutgoing && data.status === MESSAGE_STATUS.FAILED ? <FailedIcon /> : null}
                {isOutgoing && data.status === MESSAGE_STATUS.READ ? <ReadIcon /> : null}
                {!resendDisabled &&
                isOutgoing &&
                data.status === MESSAGE_STATUS.FAILED &&
                channelStatus !== STATUS.DISCONNECTED ? (
                    <TextLink variant="caption" onClick={handleResend}>
                        {t('InboxPage.resend')}
                    </TextLink>
                ) : null}
            </div>
        </Container>
    );
};

export default Message;
