/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { PERMISSION } from '@constants/browser';
import { browserService } from '@services';

export const useWebPushNotifications = () => {
    const [permission, setPermission] = useState();
    const [isSupported, setIsSupported] = useState(false);
    const [isSubscribed, setIsSubscibed] = useState(false);

    useEffect(() => {
        // check browser support for web push notifications
        const supported = 'serviceWorker' in navigator && 'PushManager' in window && 'Notification' in window;
        setIsSupported(supported);
        if (supported) {
            setPermission(Notification?.permission);

            const setSubscriptionStatus = async () => {
                const registration = await navigator.serviceWorker.ready;
                const subscription = await registration.pushManager.getSubscription();
                setIsSubscibed(subscription !== null);
            };
            setSubscriptionStatus();
        }
    }, []);

    const togglePermissions = () => {
        if (Notification?.permission === PERMISSION.DENIED) {
            alert(
                'Web push notifications are blocked. Please go to your browser settings and unblock notifications for this website.',
            );
            return;
        }
        if (isSubscribed) {
            unsubscribeUserFromPush();
            return;
        }
        Notification?.requestPermission(result => {
            if (result !== PERMISSION.GRANTED) {
                console.log("Web Push Service: Permission isn't granted");
            } else {
                setPermission(true);
                subscribeUserToPush();
            }
        });
    };

    const requestPermissions = () => {
        Notification.requestPermission(result => {
            if (result !== PERMISSION.GRANTED) {
                console.log("Web Push Service: Permission isn't granted");
            } else {
                setPermission(true);
                subscribeUserToPush();
            }
        });
    };

    const subscribeUserToPush = async () => {
        try {
            const registration = await navigator.serviceWorker.register('/webpush-sw.js');
            const subscription = await registration.pushManager.getSubscription();
            if (subscription !== null) {
                return;
            } else {
                const convertedVapidPublicKey = urlBase64ToUint8Array(process.env.REACT_APP_SERVICE_WORKER_PUBLIC_KEY);
                const subscribeOptions = {
                    userVisibleOnly: true,
                    applicationServerKey: convertedVapidPublicKey,
                };
                const newSubscription = await registration.pushManager.subscribe(subscribeOptions);
                sendSubscriptionToServer(newSubscription);
                // if true - don't show snackbar again (need for InboxPage)
                localStorage.setItem('hideInboxSubscribe', true);
            }
        } catch (error) {
            console.log('Web Push Service: Failed to create subscription: ' + error);
        }
    };

    const sendSubscriptionToServer = async subscription => {
        try {
            const response = await browserService.createWebPushSubscription(subscription);
            if (response.ok) {
                setIsSubscibed(true);
                console.log('Web Push Service: Successfully subscribed for Push Notifications');
            } else {
                console.log('Web Push Service: Failed to store the Push Notifications subscription on server');
            }
        } catch (error) {
            console.log('Web Push Service: Failed to store the Push Notifications subscription on server: ' + error);
        }
    };

    const unsubscribeUserFromPush = async () => {
        if (!isSupported) {
            return;
        }
        try {
            const registration = await navigator.serviceWorker.ready;
            const subscription = await registration.pushManager.getSubscription();
            await browserService.deleteWebPushSubscription(subscription.endpoint);
            await subscription.unsubscribe();
            console.log('Web Push Service: Successfully unsubscribed for Push Notifications');
            setIsSubscibed(false);
        } catch (error) {
            console.log('Web Push Service: Failed to unsubscribe from server', error);
        }
    };

    const urlBase64ToUint8Array = base64String => {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        // eslint-disable-next-line no-useless-escape
        const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };

    return {
        permission,
        isSupported,
        isSubscribed,
        togglePermissions,
        requestPermissions,
    };
};
