import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import Percentage from './Percentage';
import { Trans } from 'react-i18next';
import { formatNumber } from '@helpers/formatNumbers';

const CardWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'withBorder',
})(({ withBorder }) => ({
    border: withBorder ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
    cursor: withBorder ? 'pointer' : 'default',
    borderRadius: 4,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    '&:hover': {
        backgroundColor: withBorder ? 'rgba(0, 0, 0, 0.02)' : 'transparent',
    },
    '@media (max-width: 1200px)': {
        gap: 8,
    },
    '@media (max-width: 820px)': {
        maxWidth: 'none',
    },
}));

const ValueWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'hasIcon',
})(({ hasIcon }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    ...(hasIcon && { display: 'grid', gridTemplateColumns: '28px auto', gridGap: 16 }),
    '@media (max-width: 1200px)': {
        gap: 8,
        ...(hasIcon && { gridGap: 8 }),
    },
    '@media (max-width: 820px)': {
        '& svg': {
            width: 28,
            height: 28,
        },
    },
}));

const MetricItem = ({ title, value, subvalue, withPercent, withSign, withBorder, Icon = null, onClick = null }) => {
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery('@media (max-width: 1200px)');
    const isSubvalueExists = subvalue ?? false;
    return (
        <CardWrapper withBorder={withBorder} onClick={onClick}>
            <Typography
                variant="caption"
                sx={{ display: 'inline-block', color: 'rgba(0, 0, 0, 0.6)', textAlign: 'center' }}
            >
                {title}
            </Typography>
            <ValueWrapper hasIcon={!!Icon}>
                {Icon}
                <Typography variant={isSmallScreen ? 'h4' : 'h3'} sx={{ wordBreak: 'break-word', textAlign: 'center' }}>
                    {formatNumber(value, 'compact')}
                    {withPercent && '%'}
                </Typography>
            </ValueWrapper>
            {isSubvalueExists !== false ? (
                <Typography
                    variant="caption"
                    sx={{ display: 'block', textAlign: 'center', gap: '4px', color: 'rgba(0, 0, 0, 0.6)' }}
                >
                    <Trans t={t} i18nKey="HomePage.vsPrevMonth">
                        <Percentage value={subvalue} withSign={withSign} />
                    </Trans>
                </Typography>
            ) : null}
        </CardWrapper>
    );
};

export default MetricItem;
