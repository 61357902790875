import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { format } from 'date-fns';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { useTranslation } from '@hooks/useTranslation';
import { prepareNpsData } from '@helpers/analytics';

const createChartData = (labels, data, legends) => {
    return {
        labels,
        datasets: [
            {
                type: 'line',
                yAxisID: 'line',
                label: legends[3],
                data: data.map(i => i?.nps),
                backgroundColor: ['#1F4C5C'],
                borderColor: ['#1F4C5C'],
                fill: false,
                borderWidth: 2,
                pointHoverRadius: 0,
            },
            {
                type: 'bar',
                yAxisID: 'bar',
                stack: 'stack1',
                label: legends[2],
                data: data.map(i => i?.detractors),
                backgroundColor: ['#FF5853'],
                categoryPercentage: 1.0,
                barPercentage: 0.7,
                borderWidth: 0,
                borderRadius: 4,
            },
            {
                type: 'bar',
                yAxisID: 'bar',
                stack: 'stack1',
                label: legends[1],
                data: data.map(i => i?.neutrals),
                backgroundColor: ['#FFCE4D'],
                categoryPercentage: 1.0,
                barPercentage: 0.7,
                borderWidth: 0,
                borderRadius: 4,
            },
            {
                type: 'bar',
                yAxisID: 'bar',
                stack: 'stack1',
                label: legends[0],
                data: data.map(i => i?.promoters),
                backgroundColor: ['#4CC474'],
                categoryPercentage: 1.0,
                barPercentage: 0.7,
                borderWidth: 0,
                borderRadius: 4,
            },
        ],
    };
};

const createChartOptions = labels => {
    return {
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
        },
        elements: {
            bar: {
                borderWidth: 0,
            },
            point: {
                radius: 0,
            },
            line: {
                tension: 0.4,
                pointStyle: 'circle',
            },
        },
        clip: false,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 1)',
                callbacks: {
                    title: function () {},
                    label: function (context) {
                        return `${context.dataset.label} - ${context.formattedValue}`;
                    },
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    drawOnChartArea: false,
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    autoSkip: false,
                    padding: 0,
                    font: {
                        family: 'Rubik',
                        size: 12,
                        weight: 400,
                    },
                    callback: (_, index) => labels[index],
                },
                border: {
                    display: false,
                },
            },
            bar: {
                type: 'linear',
                position: 'left',
                grid: {
                    drawOnChartArea: false,
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: false,
                },
                stacked: true,
                title: {
                    display: false,
                },
            },
            line: {
                type: 'linear',
                position: 'right',
                max: 100,
                min: -100,
                ticks: {
                    display: false,
                },
                grid: {
                    drawOnChartArea: false,
                    display: false,
                    drawBorder: false,
                },
                title: {
                    display: false,
                },
            },
        },
    };
};

const NpsChart = ({ data, language }) => {
    const { t } = useTranslation();

    const formattedData = useMemo(() => prepareNpsData(data), [data]);

    const labels = useMemo(
        () => formattedData.map(({ dateLabel }) => format(dateLabel, 'MMM', { locale: I18N_DATE_FORMAT[language] })),
        [formattedData],
    );

    const legends = ['Promoters', 'Neutrals', 'Detractors', t('AnalyticsReviews.nps')];

    return (
        <div style={{ position: 'relative', height: '170px', padding: '0 16px' }}>
            {formattedData.length && (
                <Bar data={createChartData(labels, formattedData, legends)} options={createChartOptions(labels)} />
            )}
        </div>
    );
};

export default NpsChart;
