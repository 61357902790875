import Skeleton from '@mui/material/Skeleton';
import { Typography } from '@components/Typography';
import { ReactComponent as LogoIcon } from 'assets/images/logo.svg';
import {
    StyledContainer,
    StyledSidebar,
    StyledSidebarHeader,
    StyledPluspoint,
    StyledSidebarLinks,
    StyledSidebarLink,
    StyledMain,
    StyledHeader,
    StyledHeaderAvatar,
    StyledContent,
    StyledProgress,
} from './styles';

const AppSkeleton = () => {
    return (
        <StyledContainer>
            <StyledSidebar>
                <StyledSidebarHeader>
                    <LogoIcon />
                    <StyledPluspoint>Pluspoint</StyledPluspoint>
                </StyledSidebarHeader>
                <StyledSidebarLinks>
                    {[1, 2, 3, 4, 5, 6].map(item => (
                        <StyledSidebarLink key={item}>
                            <Skeleton
                                variant="rectangular"
                                width={15}
                                height={15}
                                sx={{ bgcolor: 'rgba(255, 255, 255, 0.34)', borderRadius: '2px' }}
                            />
                            <Typography variant="body1">
                                <Skeleton
                                    variant="rectangular"
                                    width={180}
                                    height={24}
                                    sx={{ bgcolor: 'rgba(255, 255, 255, 0.34)', borderRadius: '4px' }}
                                />
                            </Typography>
                        </StyledSidebarLink>
                    ))}
                </StyledSidebarLinks>
            </StyledSidebar>
            <StyledMain>
                <StyledHeader>
                    <Skeleton variant="rectangular" width={180} height={28} sx={{ borderRadius: '4px' }} />
                    <StyledHeaderAvatar variant="rectangular" width={28} height={28} sx={{ borderRadius: '4px' }} />
                </StyledHeader>
                <StyledContent>
                    <StyledProgress />
                </StyledContent>
            </StyledMain>
        </StyledContainer>
    );
};

export default AppSkeleton;
