import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import { Checkbox } from '@components/Checkbox';
import AIAssistant, { AIAssistantLoadingWrapper } from '@features/AIAssistant';
import { LocationIntegrationName } from '@constants/integrations';
import { AITaskType, AI_TASK_TYPE } from '@constants/ai-assistant';
import { reviewsService } from '@services';
import { alertActions, reviewActions } from '@actions';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { useTranslation } from '@hooks/useTranslation';
import { useThrottle } from '@hooks/useThrottle';
import { useCopyToClipboard } from '@hooks/useCopyToClipboard';
import { ReplyIcon, ReplyArrowIcon } from 'assets/images/icons';
import RespondTemplate from '../RespondTemplate';
import {
    ReplyIconWrapper,
    AIWrapper,
    ReplyContainer,
    ReplyBtnWrapper,
    FormWrapper,
    StyledInput,
    CheckboxWrapper,
} from './styles';

const REPLY_MODE = {
    EMPTY: 'empty',
    FORM: 'form',
};

const ACTION = {
    REPLY: 0,
    MARK_AS_REPLIED: 1,
    COPY_AND_OPEN: 2,
};

const Reply = ({ language, review, isMobile, selectedCompanies }) => {
    const { t } = useTranslation();

    const [mode, setMode] = useState(REPLY_MODE.EMPTY);
    const [comment, setComment] = useState('');
    const [markAsReplied, setMarkAsReplied] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [aiIsLoading, setAiIsLoading] = useState(false);

    const reviews = useSelector(state => state.reviews.reviews || []);
    const dispatch = useDispatch();
    const { onCopy } = useCopyToClipboard();
    const [onCopyClick] = useThrottle(value => onCopy(value), 1000);

    const onClickReply = () => setMode(REPLY_MODE.FORM);

    const onEditReply = () => {
        setComment(review.ownerAnswer);
        setMode(REPLY_MODE.FORM);
    };

    const onDeleteReply = async () => {
        setIsSubmitting(true);
        try {
            const updatedReview = await reviewsService.deleteReply(review.reviewId);
            const reviewsCopy = reviews.slice();
            const reviewIndex = reviewsCopy.findIndex(r => r.reviewId === review.reviewId);
            setMode(REPLY_MODE.EMPTY);
            setComment('');
            if (reviewIndex !== -1 && updatedReview) {
                reviewsCopy[reviewIndex] = updatedReview?.webReview;
                dispatch(reviewActions.updateReviews(reviewsCopy));
            }
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
        setIsSubmitting(false);
    };

    const onCancelReply = () => {
        setComment('');
        setMode(REPLY_MODE.EMPTY);
    };

    const onClickPublish = async type => {
        try {
            if (type === ACTION.COPY_AND_OPEN) {
                onCopyClick(comment);
                window.open(review.link, '_blank');
                if (!markAsReplied) return;
            }
            if (review.integrationName === LocationIntegrationName.iFood) {
                if (comment.length < 10 || comment.length > 300) {
                    dispatch(alertActions.error(t('apiErrors.IFOOD_INVALID_REVIEW_REPLY_LENGTH')));
                    return;
                }
            }
            setIsSubmitting(true);
            let updatedReview = null;
            updatedReview = await reviewsService.replyReview(
                { comment: type === ACTION.MARK_AS_REPLIED ? '' : comment },
                review.reviewId,
            );
            const reviewsCopy = reviews.slice();
            const reviewIndex = reviewsCopy.findIndex(r => r.reviewId === review.reviewId);
            setMode(REPLY_MODE.EMPTY);
            setComment('');
            if (reviewIndex !== -1 && updatedReview) {
                reviewsCopy[reviewIndex] = updatedReview.webReview;
                dispatch(reviewActions.updateReviews(reviewsCopy));
            }
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
        setIsSubmitting(false);
    };

    const answerDate = review.ownerAnswerDate
        ? format(new Date(review.ownerAnswerDate), 'dd MMM yyyy', { locale: I18N_DATE_FORMAT[language] })
        : t('common.noData');

    const IS_NOT_EXTERNAL = [LocationIntegrationName.Google, LocationIntegrationName.iFood].includes(
        review.integrationName,
    );
    const IS_GOOGLE = review.integrationName === LocationIntegrationName.Google;

    return (
        <>
            {mode === REPLY_MODE.FORM && (
                <>
                    <ReplyContainer isPublish>
                        <ReplyIconWrapper isForm>
                            <ReplyIcon />
                        </ReplyIconWrapper>
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'rgba(0, 0, 0, 0.6)',
                                '@media (max-width: 1024px)': {
                                    display: 'none !important',
                                },
                            }}
                        >
                            {t(`ReviewsManage.${IS_NOT_EXTERNAL ? 'replyingAsOwner' : 'replyingOnExternal'}`)}
                        </Typography>
                        {!isMobile && (
                            <RespondTemplate
                                selectedCompanies={selectedCompanies}
                                setComment={setComment}
                                comment={comment}
                                review={review}
                                isMobile={isMobile}
                            />
                        )}
                    </ReplyContainer>
                    <FormWrapper>
                        {isMobile && (
                            <RespondTemplate
                                selectedCompanies={selectedCompanies}
                                setComment={setComment}
                                comment={comment}
                                review={review}
                            />
                        )}
                        <AIWrapper>
                            {aiIsLoading && <AIAssistantLoadingWrapper />}
                            <StyledInput
                                inputProps={{
                                    name: 'comment',
                                    value: comment,
                                    onChange: e => setComment(e.target.value),
                                }}
                                fullWidth
                                multiline
                                minRows={3}
                                label={t('ReviewsManage.yourReply')}
                                disabled={aiIsLoading}
                                toolbar={
                                    <AIAssistant
                                        btnStyles={{ position: 'absolute', left: 12, bottom: 16.5 }}
                                        value={comment}
                                        setValue={newValue => setComment(newValue)}
                                        disabled={aiIsLoading}
                                        setLoading={setAiIsLoading}
                                        additionalOptions={[
                                            {
                                                text: t('AIAssistant.writeReply'),
                                                type: AITaskType.GenerateReplyToReview,
                                                taskType: AI_TASK_TYPE.CONTEXTUAL,
                                                reviewId: review.reviewId,
                                            },
                                        ]}
                                    />
                                }
                            />
                        </AIWrapper>
                        <ReplyBtnWrapper isExternal={!IS_NOT_EXTERNAL}>
                            {!IS_NOT_EXTERNAL ? (
                                <CheckboxWrapper>
                                    <Checkbox
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        onChange={() => setMarkAsReplied(!markAsReplied)}
                                        checked={markAsReplied}
                                    />
                                    <Typography variant="body2">{t('ReviewsManage.markAsReplied')}</Typography>
                                </CheckboxWrapper>
                            ) : null}
                            {IS_NOT_EXTERNAL ? (
                                <Button
                                    type="button"
                                    variant="contained"
                                    disabled={!comment || isSubmitting}
                                    onClick={() => onClickPublish(ACTION.REPLY)}
                                >
                                    {t('buttons.publish')}
                                </Button>
                            ) : (
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => onClickPublish(ACTION.COPY_AND_OPEN)}
                                >
                                    {t('buttons.copyAndOpen')}
                                </Button>
                            )}
                            <Button type="button" variant="outlined" sx={{ marginLeft: '4px' }} onClick={onCancelReply}>
                                {t('buttons.cancel')}
                            </Button>
                        </ReplyBtnWrapper>
                    </FormWrapper>
                </>
            )}
            {mode === REPLY_MODE.EMPTY && review.ownerAnswer === null && (
                <ReplyContainer>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button
                            type="button"
                            variant="contained"
                            sx={{
                                marginLeft: '48px',
                                width: 'fit-content',
                                '& svg path': {
                                    fill: '#fff',
                                },
                                '@media (max-width: 1024px)': {
                                    marginLeft: 0,
                                },
                            }}
                            onClick={onClickReply}
                            startIcon={<ReplyArrowIcon />}
                        >
                            {t('buttons.reply')}
                        </Button>
                        {!IS_NOT_EXTERNAL && (
                            <Button
                                type="button"
                                variant="outlined"
                                sx={{ width: 'fit-content' }}
                                onClick={() => onClickPublish(ACTION.MARK_AS_REPLIED)}
                                disabled={isSubmitting}
                            >
                                {t('buttons.markAsReplied')}
                            </Button>
                        )}
                    </div>
                </ReplyContainer>
            )}
            {mode === REPLY_MODE.EMPTY && review.ownerAnswer !== null && (
                <>
                    <ReplyContainer>
                        <ReplyIconWrapper>
                            <ReplyIcon />
                        </ReplyIconWrapper>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: !IS_NOT_EXTERNAL && review.ownerAnswer === '' ? 'center' : 'flex-start',
                            }}
                        >
                            <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.38)', marginBottom: '4px' }}>
                                {t(`ReviewsManage.${!review.manuallyReplied ? 'repliedOn' : 'manuallyRepliedOn'}`, {
                                    date: answerDate,
                                })}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {review.ownerAnswer || (IS_NOT_EXTERNAL ? t('ReviewsManage.noTextMsg') : '')}
                            </Typography>
                        </div>
                    </ReplyContainer>
                    {(IS_GOOGLE || review.manuallyReplied) && (
                        <ReplyBtnWrapper hasEmptyReply={!review.ownerAnswer}>
                            <Button type="button" variant="outlined" onClick={onEditReply} disabled={isSubmitting}>
                                {t('buttons.edit')}
                            </Button>
                            <Button
                                type="button"
                                variant="danger"
                                sx={{ marginLeft: '10px' }}
                                onClick={onDeleteReply}
                                disabled={isSubmitting}
                            >
                                {t('buttons.delete')}
                            </Button>
                        </ReplyBtnWrapper>
                    )}
                </>
            )}
        </>
    );
};

export default Reply;
