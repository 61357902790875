import { styled } from '@mui/material/styles';
import { Input } from '@components/Input';

export const StyledInput = styled(Input)({
    paddingBottom: 0,
});

export const CheckboxWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    '& .MuiCheckbox-root': {
        padding: 4,
    },
});

export const AIWrapper = styled('div')({
    paddingBottom: 0,
    position: 'relative',
    marginLeft: '48px',
    maxWidth: 'calc(100% - 20px)',
    marginTop: '8px',
    '@media (max-width: 1024px)': {
        marginLeft: 0,
        maxWidth: 'none',
    },
});

export const ReplyContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'isPublish',
})(({ isPublish }) => ({
    display: 'flex',
    marginTop: 16,
    maxWidth: '600px',
    ...(isPublish && { alignItems: 'center', flexWrap: 'no-wrap' }),
    '@media (max-width: 1024px)': {
        flexDirection: 'column',
        marginTop: 8,
    },
}));

export const ReplyBtnWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'hasEmptyReply' && prop !== 'isExternal',
})(({ hasEmptyReply, isExternal }) => ({
    marginLeft: 48,
    marginTop: hasEmptyReply ? 0 : isExternal ? 8 : 16,
    width: 'calc(100% - 48px)',
    '@media (max-width: 1024px)': {
        marginLeft: 0,
        marginTop: isExternal ? 8 : 16,
        '& button': {
            padding: '0 8px',
        },
    },
}));

export const FormWrapper = styled('div')({
    width: '100%',
    '@media(max-width: 665px)': {
        width: 'auto',
        margin: ' 0 -16px',
        padding: '8px 16px 0',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
});

export const ReplyIconWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'isForm',
})(({ isForm }) => ({
    marginRight: '8px',
    width: '40px',
    minWidth: '40px',
    maxWidth: '40px',
    height: '40px',
    borderRadius: '100%',
    border: '0.5px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#1F4C5C',
    zIndex: 3,
    '& svg path': {
        fill: 'rgba(0, 0, 0, 0.38)',
        fillOpacity: 1,
    },
    '@media (max-width: 1024px)': {
        width: '32px',
        minWidth: '32px',
        maxWidth: '32px',
        height: '32px',
        marginBottom: '8px',
        ...(isForm && { display: 'none' }),
    },
}));
