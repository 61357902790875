export const getSingleWidgetScript = (key, data) => `<!-- Pluspoint script -->
    <script>"loading"!==document.readyState?init():document.addEventListener("DOMContentLoaded",init);function init(){var t=document.createElement("script");t.id="pl-single-widget",t.dataset.key="${key}",t.dataset.companies="[${data.company}]",t.dataset.channels="[${data.integrations}]",t.dataset.hideemptyreviews="${data.hideEmptyReviews}",t.dataset.count="${data.count}",t.dataset.sorting="${data.sorting}",t.src="${window.location.origin}/widget/pl.widget.single.min.js",document.getElementsByTagName("body")[0].appendChild(t)}</script>
<!-- End of Pluspoint script -->`;

export const getGridWidgetScript = (key, data) => `<!-- Pluspoint tag -->
    <div id="pl-grid-widget-container"></div>
<!-- End of Pluspoint tag -->
<!-- Pluspoint script -->
    <script>"loading"!==document.readyState?init():document.addEventListener("DOMContentLoaded",init);function init(){var t=document.createElement("script");t.id="pl-grid-widget",t.dataset.key="${key}",t.dataset.companies="[${data.company}]",t.dataset.channels="[${data.integrations}]",t.dataset.hideemptyreviews="${data.hideEmptyReviews}",t.dataset.count="${data.count}",t.dataset.sorting="${data.sorting}",t.dataset.background="${data.backgroundColor}",t.src="${window.location.origin}/widget/pl.widget.grid.min.js",document.getElementsByTagName("body")[0].appendChild(t)}</script>
<!-- End of Pluspoint script -->
`;

export const getChatWidgetScript = (channelId, alignment) => `<!-- Pluspoint script -->
    <script>"loading"!==document.readyState?init():document.addEventListener("DOMContentLoaded",init);function init(){var t=document.createElement("script");t.id="pl-chat-widget",t.dataset.channelid="${channelId}",t.dataset.align="${alignment}",t.src="${window.location.origin}/widget/pl.widget.chat.min.js",document.getElementsByTagName("body")[0].appendChild(t)}</script>
<!-- End of Pluspoint script -->
`;
