import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';
import Select from '@mui/material/Select';
import { Typography } from '@components/Typography';
import { useTranslation } from '@hooks/useTranslation';
import { CssMenuItem, CssNoOptions } from '../Select';
import { Checkbox } from '../Checkbox';
import { CssInputLabel, CssFormControl, CssOutlinedInput } from '../Input';

const CssMenuItemCheckbox = styled(CssMenuItem)(({ disabled }) => {
    return {
        '&.MuiMenuItem-root': {
            width: '100%',
            padding: '0 18px 0 9px',
            display: 'flex',
            alignItems: 'center',
            ...(disabled && {
                backgroundColor: '#E4EAEB',
            }),
            '@media (max-width: 1024px)': {
                minHeight: '32px',
            },
            '&>*:first-of-type': {
                marginRight: 9,
                ...(disabled && {
                    color: 'rgba(0, 0, 0, 0.87)',
                }),
            },
        },
    };
});

const CssMenuItemCreatable = styled(CssMenuItem)(() => {
    return {
        '&.MuiMenuItem-root': {
            padding: '12px 18px 12px 9px',
            display: 'flex',
            alignItems: 'center',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            marginTop: 8,
            '& svg': {
                height: 16,
                '& path': {
                    fill: '#1F4C5C',
                },
            },
            '@media (max-width: 1024px)': {
                minHeight: '32px',
            },
        },
    };
});

const CssCheckbox = styled(Checkbox)({
    padding: '7px 9px 7px 9px',
    '& .MuiSvgIcon-root': {
        fontSize: '1.125rem',
    },
});

const CssSelect = styled(Select)({
    '.MuiSelect-icon': {
        color: 'rgba(0, 0, 0, 0.88)',
    },
    '& .MuiSelect-icon.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    '.MuiCheckbox-root': {
        '&.Mui-checked': {
            color: '#1F4C5C',
        },
    },
});

const OptionAll = styled('div')({
    position: 'absolute',
    content: '""',
    height: '1px',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.12)',
    left: 0,
    bottom: '-8px',
});

const MultiselectErrorWrapper = styled('div')(({ error }) => {
    return {
        position: 'relative',
        height: 'fit-content',
        '& .MuiInputLabel-root': {
            color: error && '#B00020 !important',
        },
    };
});

const ErrText = styled('p')({
    position: 'absolute',
    bottom: '-16px',
    left: 11,
    color: '#B00020',
    fontFamily: 'Rubik,sans-serif',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.4px',
});

const renderOptionsWithAll = (options, value) => {
    return options.map(option =>
        option.value === 'all' ? (
            <CssMenuItemCheckbox
                key={option.value}
                value={option.value}
                sx={{ marginBottom: '16px', overflow: 'visible !important' }}
            >
                <CssCheckbox
                    indeterminate={!!value.length && options.length - 1 !== value.length}
                    checked={options.length - 1 === value.length}
                />
                <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body2">
                    {option.label}
                </Typography>
                <OptionAll />
            </CssMenuItemCheckbox>
        ) : (
            <CssMenuItemCheckbox key={option.value} value={option.value} disabled={option?.disabled}>
                <CssCheckbox checked={value?.some(selected => selected === option?.value)} />
                <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body2">
                    {option.label}
                </Typography>
            </CssMenuItemCheckbox>
        ),
    );
};

export const MultiSelect = ({
    className,
    options: propsOptions = [],
    label,
    value = [],
    id,
    required,
    countable,
    nameBased = false, // show selected option.name in input instead of label
    creatable = false,
    creatableText = '',
    creatableClick = () => {},
    InputProps = {},
    style,
    error,
    withAllOption = true,
    ...rest
}) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('@media (max-width: 1024px)');
    const optionsWithAll = propsOptions.length ? [{ value: 'all', label: t('common.all') }, ...propsOptions] : [];
    const options = withAllOption ? optionsWithAll : propsOptions;

    return (
        <MultiselectErrorWrapper style={style} error={!!error}>
            <CssFormControl fullWidth required={required} className={className}>
                <CssInputLabel>{label}</CssInputLabel>
                <CssSelect
                    id={id}
                    value={value}
                    multiple
                    input={<CssOutlinedInput label={label} {...InputProps} />}
                    renderValue={selectedValues => {
                        const selectedOptions = selectedValues.map(selectedValue =>
                            options.find(({ value }) => selectedValue === value),
                        );
                        if (
                            options.some(option => option?.value === 'all') &&
                            selectedValues.length === options.length - 1
                        ) {
                            return options[0].label;
                        }
                        const countPrefix = `${countable ? `(${selectedOptions.length}) ` : ''}`;
                        return (
                            countPrefix +
                            selectedOptions.map(option => (nameBased ? option?.name : option?.label)).join(', ')
                        );
                    }}
                    MenuProps={{
                        autoFocus: false,
                        style: { zIndex: 9999 },
                        ...(isMobile
                            ? {
                                  MenuListProps: {
                                      style: {
                                          maxWidth: 'initial',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                      },
                                  },
                              }
                            : {}),
                        PaperProps: {
                            sx: {
                                '& .MuiList-root': {
                                    paddingBottom: creatable ? '0px' : '8px',
                                },
                            },
                            style: {
                                maxHeight: isMobile ? '35vh' : '55vh',
                                maxWidth: isMobile ? '210px' : 'none',
                            },
                        },
                    }}
                    error={!!error}
                    {...rest}
                >
                    {options?.some(option => option.value === 'all') ? (
                        renderOptionsWithAll(options, value)
                    ) : options.length ? (
                        options.map(option => (
                            <CssMenuItemCheckbox key={option.value} value={option.value} disabled={option?.disabled}>
                                <CssCheckbox
                                    checked={value?.some(selected => selected === option?.value) || !!option?.disabled}
                                />
                                <Typography
                                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}
                                    variant="body2"
                                >
                                    {option.label}
                                </Typography>
                            </CssMenuItemCheckbox>
                        ))
                    ) : (
                        <CssNoOptions>{t('common.noOptions')}</CssNoOptions>
                    )}
                    {creatable ? (
                        <CssMenuItemCreatable onClick={creatableClick}>
                            <AddIcon />
                            <Typography sx={{ color: '#1F4C5C' }} variant="body2">
                                {creatableText}
                            </Typography>
                        </CssMenuItemCreatable>
                    ) : null}
                </CssSelect>
            </CssFormControl>
            {error ? <ErrText>{error ? t(error.message) : null}</ErrText> : null}
        </MultiselectErrorWrapper>
    );
};
