import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { useTranslation } from '@hooks/useTranslation';
import { ErrorIcon, InProgressIcon, SuccessIcon } from 'assets/images/icons';
import { SYNC_TYPE } from '@constants/profiles';
import MetricItem from './MetricItem';

const CardsWrapper = styled('div')({
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    height: 'calc(100% - 57px)',
    '@media (max-width: 375px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
    },
});

const Synchronization = () => {
    const { t } = useTranslation();
    const { profilesSync } = useSelector(state => state.dashboard.dashboard);
    const history = useHistory();

    const data = [
        {
            id: 0,
            title: t('ProfilesPage.synced'),
            value: profilesSync.synced,
            subvalue: null,
            icon: <SuccessIcon />,
            onClick: () => history.push(`/presence/profiles?status=${SYNC_TYPE.SYNCED}`),
        },
        {
            id: 2,
            title: t('common.inProgress'),
            value: profilesSync.inProgress,
            subvalue: null,
            icon: <InProgressIcon />,
            onClick: () => history.push(`/presence/profiles?status=${SYNC_TYPE.IN_PROGRESS}`),
        },
        {
            id: 3,
            title: t('common.actionRequired'),
            value: profilesSync.actionRequired,
            subvalue: null,
            icon: <ErrorIcon />,
            onClick: () => history.push(`/presence/profiles?status=${SYNC_TYPE.ACTION_REQUIRED}`),
        },
    ];

    return (
        <CardsWrapper>
            {data.map(item => (
                <MetricItem
                    key={item.id}
                    value={item.value}
                    subvalue={item.subvalue}
                    title={item.title}
                    withSign={item.withSign}
                    withBorder
                    Icon={item.icon}
                    onClick={item.onClick}
                />
            ))}
        </CardsWrapper>
    );
};

export default Synchronization;
