import { reviewsService } from '@services';
import { reviewsConstants } from '@constants/reviews';

export const reviewActions = {
    getAll,
    loadMore,
    resetLoading,
    resetNewExist,
    updateReviews,
    checkNewExistSuccess,
};

function getAll(query) {
    return dispatch => {
        dispatch(request());
        reviewsService.getAll(query).then(
            reviews => {
                dispatch(successAll(reviews));
                reviewsService.markAsViewed(reviews.items.map(r => r.reviewId));
            },
            () => {},
        );
    };
}

function loadMore(query) {
    return dispatch => {
        reviewsService.getAll(query).then(
            reviews => {
                dispatch(successLoadMore(reviews));
                reviewsService.markAsViewed(reviews.items.map(r => r.reviewId));
            },
            () => {},
        );
    };
}

function request() {
    return { type: reviewsConstants.REVIEWS_REQUEST };
}

function successAll(reviews) {
    return { type: reviewsConstants.REVIEWS_SUCCESS, reviews };
}

function successLoadMore(reviews) {
    return { type: reviewsConstants.REVIEWS_LOAD_MORE_SUCCESS, reviews };
}

function resetLoading() {
    return { type: reviewsConstants.RESET_LOADING };
}

function checkNewExistSuccess(data) {
    return { type: reviewsConstants.NEW_REVIEW_EXIST_SUCCESS, payload: data };
}

function resetNewExist() {
    return { type: reviewsConstants.NEW_REVIEW_EXIST_RESET };
}

function updateReviews(reviews) {
    return { type: reviewsConstants.UPDATE_REVIEWS, payload: reviews };
}
