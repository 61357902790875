import { labelsConstants } from '@constants/labels';

const initialState = {
    contact: [],
    location: [],
    contactLoading: true,
    locationLoading: true,
};

export function labels(state = initialState, action) {
    switch (action.type) {
        case labelsConstants.LABELS_SUCCESS:
            return {
                ...state,
                [action.payload.labelType]: action.payload.data,
                [`${action.payload.labelType}Loading`]: false,
            };
        default:
            return state;
    }
}
