import { dashboardConstants } from '@constants/dashboard';
import { dashboardService } from '@services';

export const dashboardActions = {
    getDashboardAnalytics,
    getLeaderboardAnalytics,
    getRecommendations,
    setRecommendations,
};

function getDashboardAnalytics() {
    return dispatch => {
        return dashboardService.getDashboardAnalytics().then(
            data => dispatch(successDashboard(data)),
            () => dispatch(failureDashboard()),
        );
    };
}

function successDashboard(data) {
    return { type: dashboardConstants.DASHBOARD_SUCCESS, data };
}

function failureDashboard() {
    return { type: dashboardConstants.DASHBOARD_ERROR };
}

function getLeaderboardAnalytics(sorting, type) {
    return dispatch => {
        return dashboardService.getLeaderboardAnalytics(sorting, type).then(
            data => dispatch(successLeaderboard(data)),
            () => dispatch(failureLeaderboard()),
        );
    };
}

function successLeaderboard(data) {
    return { type: dashboardConstants.LEADERBOARD_SUCCESS, data };
}

function failureLeaderboard() {
    return { type: dashboardConstants.LEADERBOARD_ERROR };
}

function getRecommendations() {
    return dispatch => {
        return dashboardService.getRecommendations().then(
            data => dispatch(successRecommendations(data)),
            () => dispatch(failureRecommendations()),
        );
    };
}

function successRecommendations(data) {
    return { type: dashboardConstants.RECOMMENDATIONS_SUCCESS, data };
}

function failureRecommendations() {
    return { type: dashboardConstants.RECOMMENDATIONS_ERROR };
}

function setRecommendations(data) {
    return { type: dashboardConstants.SET_RECOMMENDATIONS, data };
}
