import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from '@hooks/useTranslation';
import IntegrationRequired from '@features/IntegrationRequired';
import PageHeader from '@features/PageHeader';
import Button from '@components/Button';
import { profilesService } from '@services';
import { profilesActions, labelsActions, alertActions } from '@actions';
import { AccIntegrationName, LocationIntegrationName } from '@constants/integrations';
import { LABEL_TYPE } from '@constants/labels';
import { SYNC_TYPE } from '@constants/profiles';
import { useQueryParams } from '@hooks/useQueryParams';
import { getLocationLabels } from '@helpers/locationLabels';
import FiltersPanel from './FiltersPanel';
import ProfilesTable from './ProfilesTable';
import StatusChart from './StatusChart';
import ResolveModal from './ResolveModal';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

export const StyledFiltersPanel = styled(FiltersPanel)({
    '& svg': {
        height: 'auto',
        width: 'auto',
    },
});

const StyledSyncButton = styled(Button)({
    '& svg': {
        height: 'auto',
        width: 'auto',
    },
});

const ProfilesPage = () => {
    const { t } = useTranslation();
    const params = useQueryParams();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filter, setFilter] = useState({
        labels: [],
        companies: [],
        platforms: [],
        statuses: params.get('status')
            ? [JSON.parse(params.get('status'))]
            : [
                  SYNC_TYPE['N/A'],
                  SYNC_TYPE.IN_PROGRESS,
                  SYNC_TYPE.ACTION_REQUIRED,
                  SYNC_TYPE.SYNCED,
                  SYNC_TYPE.SYNCED_WITH_CONFLICTS,
              ],
    });
    const [filterNumber, setFilterNumber] = useState(0);
    const [filterOpened, setFiltersOpened] = useState(false);
    const [resolveModalOpened, setResolveModalOpened] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState({ index: null, platform: null });

    const user = useSelector(state => state.authentication.user);
    const isAdmin = useSelector(state => {
        if (!state.authentication?.user?.id) return false;
        return state.authentication?.user?.roles?.filter(u => u.role === 0)?.length > 0;
    });
    const account = useSelector(state => state.account?.account);
    const companies = useSelector(state => state.account?.account?.companies || []);
    const { location: labels, locationLoading: labelsLoading } = useSelector(state => state.labels);
    const { syncCompleted } = useSelector(state => state.profiles);
    const firstRender = useRef(true);
    const dispatch = useDispatch();

    const INTEGRATION_CONNECTED = account?.integrations.find(
        i => i.type === AccIntegrationName.Google || i.type === AccIntegrationName.Facebook,
    )?.isActive;
    const isUser = !isAdmin && !user?.isAccountOwner && !user?.isGlobalAdmin;

    useEffect(() => {
        dispatch(labelsActions.get(LABEL_TYPE.LOCATION));
    }, []);

    const availableCompanies = useMemo(
        () =>
            companies.filter(item =>
                item.profiles.some(i => i.type === AccIntegrationName.Google || i.type === AccIntegrationName.Facebook),
            ),
        [companies],
    );

    const availableLabels = useMemo(
        () => getLocationLabels(labels, availableCompanies, t),
        [labels, availableCompanies],
    );

    useEffect(() => {
        if (companies.length && !labelsLoading) {
            const platforms = [
                ...new Set(
                    availableCompanies
                        .map(location => location.profiles)
                        .flat()
                        .filter(
                            p =>
                                p.type === LocationIntegrationName.Google ||
                                p.type === LocationIntegrationName.Facebook,
                        )
                        .map(p => p.type),
                ),
            ];
            setFilter(prev => ({
                ...prev,
                companies: availableCompanies.map(item => item.companyId),
                platforms,
                labels: availableLabels.map(item => item.id),
            }));
        }
    }, [companies, labelsLoading, dispatch]);

    const currentQuery = useMemo(() => {
        return {
            locations: filter.companies,
            platforms: filter.platforms,
            statuses: filter.statuses,
        };
    }, [filter]);

    useEffect(() => {
        if (filter.companies.length && INTEGRATION_CONNECTED) {
            if (firstRender.current) {
                dispatch(profilesActions.get(currentQuery, page, rowsPerPage));
                firstRender.current = false;
            }
        }
    }, [page, filter, currentQuery, INTEGRATION_CONNECTED, rowsPerPage, dispatch]);

    useEffect(() => {
        let interval;

        if (!syncCompleted) {
            interval = setInterval(() => {
                dispatch(profilesActions.get(currentQuery, page, rowsPerPage));
            }, 10000);
        }

        return () => clearInterval(interval);
    }, [syncCompleted, currentQuery, page, rowsPerPage, dispatch]);

    const onStartSync = async () => {
        try {
            const response = await profilesService.startSync();
            if (response) {
                dispatch(profilesActions.setSyncStatus(response));
                dispatch(alertActions.success(t('ProfilesPage.syncSuccessMessage')));
            }
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('apiErrors.something_wrong')));
            }
        }
    };

    const onPageChange = (_, newPage) => {
        setPage(newPage);
        dispatch(profilesActions.get(currentQuery, newPage, rowsPerPage));
    };

    const onRowsPerPageChange = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        dispatch(profilesActions.get(currentQuery, 0, parseInt(event.target.value, 10)));
    };

    const onApplyFilters = () => {
        dispatch(profilesActions.get(currentQuery, 0, rowsPerPage));
        setPage(0);
    };

    const onClickResolve = (index, platform) => {
        setSelectedProfile({ index, platform });
        setResolveModalOpened(true);
    };

    return (
        <Container>
            {!INTEGRATION_CONNECTED ? (
                <IntegrationRequired />
            ) : (
                <>
                    <PageHeader
                        icon={<StatusChart />}
                        title={t('ProfilesPage.title')}
                        subtitle={t('ProfilesPage.subtitle')}
                        styles={{ marginBottom: '16px' }}
                    >
                        {syncCompleted ? (
                            <StyledSyncButton variant="contained" onClick={onStartSync}>
                                <RefreshIcon
                                    sx={{
                                        color: '#fff',
                                        width: '1em !important',
                                        height: '1em !important',
                                        fontSize: '22px',
                                    }}
                                />
                                <span style={{ marginLeft: '6px' }}>{t('buttons.startSync')}</span>
                            </StyledSyncButton>
                        ) : (
                            <StyledSyncButton variant="contained" disabled>
                                <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.38)' }} size={16} />
                                <span style={{ marginLeft: '8px' }}>{t('buttons.syncing')}</span>
                            </StyledSyncButton>
                        )}
                        <StyledFiltersPanel
                            setFiltersOpened={setFiltersOpened}
                            filtersNumber={filterNumber}
                            filterOpened={filterOpened}
                            companies={availableCompanies}
                            labels={availableLabels}
                            filter={filter}
                            setFilter={setFilter}
                            type="profiles"
                            setFilterNumber={setFilterNumber}
                            onApplyFilters={onApplyFilters}
                            withChips={false}
                        />
                    </PageHeader>
                    <ProfilesTable
                        isUser={isUser}
                        locations={companies}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                        onClickResolve={onClickResolve}
                    />
                </>
            )}
            {resolveModalOpened && (
                <ResolveModal
                    isOpen={resolveModalOpened}
                    handleClose={() => setResolveModalOpened(false)}
                    selectedProfile={selectedProfile}
                    onRefetchData={onApplyFilters}
                />
            )}
        </Container>
    );
};

export default ProfilesPage;
