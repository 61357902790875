import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { isFuture } from 'date-fns';
import { Input } from '@components/Input';
import { Typography } from '@components/Typography';
import { MultiSelect } from '@components/MultiSelect';
import SplitButton from '@components/SplitButton';
import MediaUploaderMulti from '@components/MediaUploaderMulti';
import { Select } from '@components/Select';
import AIAssistant, { AIAssistantLoadingWrapper } from '@features/AIAssistant';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { useTranslation } from '@hooks/useTranslation';
import { LocationIntegrationName } from '@constants/integrations';
import { POST_STATUS, POST_CHANNEL } from '@constants/social-posts';
import { alertActions, socialPostsActions } from '@actions';
import { socialPostsService } from '@services';
import { MODAL_TYPE } from '@constants/modal';
import { AITaskType, AI_TASK_TYPE } from '@constants/ai-assistant';
import { KB, MB, MEDIA_TYPE } from '@constants/file';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { getPlatformsOptions } from '@helpers/social-posts';
import { StyledTimeframePicker, Content, Modal } from './styles';

const EditModal = ({ modal, setModal, selectedSocialPost, setSelectedSocialPost, rowsPerPageRequests }) => {
    const { t } = useTranslation();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [aiIsLoading, setAiIsLoading] = useState(false);
    const [isMediaUploading, setIsMediaUploading] = useState(false);
    const { data } = useSelector(state => state.socialPosts);
    const { location: labels } = useSelector(state => state.labels);
    const companies = useSelector(state => state.account?.account?.companies || []);

    const dispatch = useDispatch();

    const isView = modal.type === MODAL_TYPE.VIEW;
    const isPublished = selectedSocialPost?.status === POST_STATUS.PUBLISHED;

    const availableLabels = [
        ...new Set(
            companies
                .filter(c =>
                    c.profiles?.some(
                        p => p.type === LocationIntegrationName.Google || p.type === LocationIntegrationName.Facebook,
                    ),
                )
                .map(item => item.labels)
                .flat(),
        ),
    ];

    const labelsOptions = useMemo(() => {
        return labels.filter(l => availableLabels.includes(l.id)).map(item => ({ value: item.id, label: item.name }));
    }, [companies, labels]);

    const buttonOptions = [
        { value: 0, label: t('common.none') },
        { value: 1, label: t('SocialPosts.book') },
        { value: 2, label: t('SocialPosts.orderOnline') },
        { value: 3, label: t('SocialPosts.buy') },
        { value: 4, label: t('common.learnMore') },
        { value: 5, label: t('SocialPosts.signUp') },
        { value: 6, label: t('SocialPosts.callNow') },
    ];

    const SocialPostSchema = yup.object().shape({
        title: yup.string().required('validation.required'),
        message: yup.string().required('validation.required').max(1500, 'validation.maxLength'),
        platforms: yup.array().min(1, 'validation.required'),
        companies: yup.array().min(1, 'validation.required'),
        scheduledTime:
            isView || isPublished
                ? yup.string()
                : yup
                      .string()
                      .nullable()
                      .required('validation.required')
                      .test('test-future-date', '', value => isFuture(new Date(value))),
        button: yup.number(),
        buttonContent: yup.string().when('button', {
            is: buttonValue => buttonValue !== 0 && buttonValue !== 6,
            then: yup.string().url('validation.invalidURL').required('validation.required'),
            otherwise: yup.string().nullable(),
        }),
        media: yup.array().when('platforms', {
            is: platformsValue => platformsValue?.includes(POST_CHANNEL.INSTAGRAM),
            then: yup.array().min(1, 'validation.required'),
            otherwise: yup.array(),
        }),
    });

    const resolver = useYupValidationResolver(SocialPostSchema);

    const {
        getValues,
        setValue,
        control,
        reset,
        watch,
        formState: { isValid },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            title: '',
            message: '',
            platforms: [],
            companies: companies
                .filter(c =>
                    c.profiles?.some(
                        p => p.type === LocationIntegrationName.Google || p.type === LocationIntegrationName.Facebook,
                    ),
                )
                .map(c => c.companyId),
            scheduledTime: '',
            button: 0,
            buttonContent: '',
            media: [],
            locationLabels: labels.filter(l => availableLabels.includes(l.id)).map(item => item.id),
        },
    });

    const selectedLocationLabels = watch('locationLabels');
    const selectedCompanies = watch('companies');
    const button = watch('button');
    const instagramIsSelected = watch('platforms')?.includes(POST_CHANNEL.INSTAGRAM);
    const googleIsSelected = watch('platforms')?.includes(POST_CHANNEL.GOOGLE);
    const facebookIsSelected = watch('platforms')?.includes(POST_CHANNEL.FACEBOOK);
    const media = watch('media');

    const companyOptions = useMemo(() => {
        const filteredCompanies = companies.filter(c =>
            c.profiles?.some(
                p => p.type === LocationIntegrationName.Google || p.type === LocationIntegrationName.Facebook,
            ),
        );
        return selectedLocationLabels.length === labelsOptions.length
            ? filteredCompanies.map(item => ({ value: item.companyId, label: item.internalName }))
            : filteredCompanies
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [selectedLocationLabels]);

    const { platformsOptions } = useMemo(
        () => getPlatformsOptions(companies, selectedCompanies),
        [selectedCompanies, companies],
    );

    useEffect(() => {
        if (platformsOptions.length === 0) {
            setValue('platforms', [], { shouldValidate: true });
        }
    }, [platformsOptions]);

    useEffect(() => {
        const isNewSocialPost = modal.type === MODAL_TYPE.CREATE;
        if (!isNewSocialPost) {
            setValue('title', selectedSocialPost?.title || '', { shouldValidate: true });
            setValue('message', selectedSocialPost?.message, { shouldValidate: true });
            setValue('platforms', selectedSocialPost?.platforms || [], {
                shouldValidate: true,
            });
            setValue('companies', selectedSocialPost?.companies || [], {
                shouldValidate: true,
            });
            setValue('scheduledTime', new Date(selectedSocialPost?.scheduledTime), {
                shouldValidate: true,
            });
            setValue('button', selectedSocialPost?.button);
            setValue('buttonContent', selectedSocialPost?.buttonContent || '');
            setValue('media', selectedSocialPost?.media || [], { shouldValidate: true });
        }
    }, [selectedSocialPost, companies, setValue, modal.type]);

    const onCloseModal = () => {
        setModal({ open: false, type: MODAL_TYPE.CREATE });
        setSelectedSocialPost({});
        reset();
    };

    const checkMediaValidity = () => {
        const videosCount = media.filter(m => m.type === MEDIA_TYPE.VIDEO).length;
        const imagesCount = media.filter(m => m.type === MEDIA_TYPE.IMAGE).length;
        // Only 1 image is allowed for Google
        // Only one video or <=10 images are allowed for Facebook
        // <=10 images or videos required for Instagram
        if (googleIsSelected && videosCount > 0) {
            return { isValid: false, text: 'videos_not_supported_by_google' };
        } else if (facebookIsSelected && videosCount > 1) {
            return { isValid: false, text: 'facebook_allow_one_video' };
        } else if (facebookIsSelected && imagesCount > 0 && videosCount > 0) {
            return { isValid: false, text: 'facebook_allow_one_type_of_media' };
        }
        return { isValid: true, text: '' };
    };

    const onSaveCampaign = async status => {
        const values = getValues();
        setIsSubmitting(true);
        try {
            const newSocialPost = await socialPostsService.createOrUpdateSocialPost({
                ...selectedSocialPost,
                ...values,
                scheduledTime: new Date(values.scheduledTime).toISOString(),
                button: googleIsSelected ? values.button : 0,
                buttonContent: googleIsSelected ? values.buttonContent : '',
                status,
                ...(modal.type === MODAL_TYPE.EDIT && { id: selectedSocialPost?.id }),
            });
            setIsSubmitting(false);
            if (modal.type === MODAL_TYPE.CREATE) {
                dispatch(socialPostsActions.get(0, rowsPerPageRequests));
            } else {
                const socialPosts = data.slice();
                const updatedSocialPostIndex = socialPosts.findIndex(c => c.id === selectedSocialPost?.id);
                if (updatedSocialPostIndex !== -1 && newSocialPost) {
                    socialPosts[updatedSocialPostIndex] = newSocialPost;
                    dispatch(socialPostsActions.setSocialPosts(socialPosts));
                }
                setSelectedSocialPost(newSocialPost);
            }
            dispatch(
                alertActions.success(
                    modal.type === MODAL_TYPE.CREATE
                        ? t('alertMessages.createSuccess')
                        : t('alertMessages.editSuccess'),
                ),
            );
            onCloseModal();
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(
                    alertActions.error(
                        modal.type === MODAL_TYPE.CREATE ? t('alertMessages.createFail') : t('alertMessages.editFail'),
                    ),
                );
            }
            setIsSubmitting(false);
        }
    };

    const { isValid: isMediaValid, text: mediaErrorText } = checkMediaValidity();

    return (
        <Modal
            isOpen={modal.open}
            handleClose={onCloseModal}
            title={
                modal.type === MODAL_TYPE.CREATE
                    ? t('buttons.createNewPost')
                    : isView
                    ? t('SocialPosts.viewPost')
                    : t('SocialPosts.editPost')
            }
            primaryActionText={isPublished ? t('buttons.saveChanges') : ''}
            onPrimaryAction={isPublished ? () => onSaveCampaign(selectedSocialPost?.status) : null}
            primaryActionDisabled={isMediaUploading || isSubmitting || !isValid || isView || !isMediaValid}
            primaryButton={
                <SplitButton
                    variant="contained"
                    disabled={isSubmitting || !isValid || isView || !isMediaValid}
                    options={[
                        {
                            text:
                                modal.type === MODAL_TYPE.CREATE ? t('buttons.schedulePost') : t('buttons.saveChanges'),
                            onClick: () =>
                                onSaveCampaign(
                                    modal.type === MODAL_TYPE.CREATE
                                        ? POST_STATUS.SCHEDULED
                                        : selectedSocialPost?.status,
                                ),
                        },
                        {
                            text:
                                selectedSocialPost?.status === POST_STATUS.DRAFT
                                    ? t('buttons.schedulePost')
                                    : t('buttons.saveDraft'),
                            onClick: () =>
                                onSaveCampaign(
                                    selectedSocialPost?.status === POST_STATUS.DRAFT
                                        ? POST_STATUS.SCHEDULED
                                        : POST_STATUS.DRAFT,
                                ),
                        },
                    ]}
                />
            }
            onSecondaryAction={onCloseModal}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content>
                <Controller
                    control={control}
                    name="title"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('CampaignsPage.name')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                            sx={{ gridArea: 'input1' }}
                            disabled={isView}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="locationLabels"
                    render={({ field, ref }) => (
                        <MultiSelect
                            {...field}
                            ref={ref}
                            label={t('common.locationLabels')}
                            onChange={({ target: { value } }) => {
                                const selectedLabels = getMultiselectSelectedOptions(labelsOptions, value);
                                setValue('locationLabels', selectedLabels, { shouldDirty: true });
                                const filteredCompanies = companies.filter(c =>
                                    c.profiles?.some(
                                        p =>
                                            p.type === LocationIntegrationName.Google ||
                                            p.type === LocationIntegrationName.Facebook,
                                    ),
                                );
                                const availableCompanies =
                                    selectedLabels.length === labelsOptions.length
                                        ? filteredCompanies.map(item => item.companyId)
                                        : filteredCompanies
                                              .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                                              .map(item => item.companyId);
                                setValue('companies', availableCompanies, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                            }}
                            options={labelsOptions}
                            style={{ gridArea: 'select0' }}
                            disabled={isView || isPublished}
                            countable
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="companies"
                    render={({ field, fieldState: { error }, ref }) => (
                        <MultiSelect
                            {...field}
                            ref={ref}
                            onChange={({ target: { value } }) => {
                                setValue('companies', getMultiselectSelectedOptions(companyOptions, value), {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                            }}
                            options={companyOptions}
                            label={t('common.locations')}
                            style={{ gridArea: 'select1' }}
                            fullWidth
                            countable
                            required
                            disabled={isView || isPublished}
                            error={error}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="platforms"
                    render={({ field, fieldState: { error }, ref }) => (
                        <MultiSelect
                            {...field}
                            ref={ref}
                            onChange={({ target: { value } }) => {
                                setValue('platforms', getMultiselectSelectedOptions(platformsOptions, value), {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                            }}
                            options={platformsOptions}
                            label={t('SocialPosts.socialChannels')}
                            style={{ gridArea: 'select2' }}
                            fullWidth
                            countable
                            required
                            disabled={isView || isPublished}
                            error={error}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="scheduledTime"
                    render={({ field, fieldState: { error } }) => (
                        <StyledTimeframePicker
                            value={[field.value]}
                            range={false}
                            label={t('common.scheduledTime')}
                            showTimeSelect
                            required
                            disabled={isView || isPublished}
                            dateFormat="d MMM yyyy, HH:mm"
                            onBlurTimeSelect={field.onBlur}
                            onChangeTimeframe={value => {
                                setValue('scheduledTime', value, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                            }}
                            minDate={new Date()}
                            maxDate={null}
                            error={error}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="message"
                    render={({ field, fieldState: { error }, ref }) => (
                        <div style={{ gridArea: 'input2', position: 'relative' }}>
                            {aiIsLoading && <AIAssistantLoadingWrapper />}
                            <Input
                                ref={ref}
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('common.message', { count: 1 })}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message, { count: 1500 }) : null}
                                multiline
                                minRows={4}
                                maxLength={1500}
                                disabled={isView || aiIsLoading}
                                toolbar={
                                    <AIAssistant
                                        btnStyles={{ position: 'absolute', left: 12, bottom: 32.5 }}
                                        value={field.value}
                                        setValue={newValue => field.onChange(newValue)}
                                        disabled={isView || aiIsLoading}
                                        setLoading={setAiIsLoading}
                                        additionalOptions={[
                                            {
                                                text: t('AIAssistant.writePost'),
                                                type: AITaskType.GenerateSocialPost,
                                                taskType: AI_TASK_TYPE.CONTEXTUAL,
                                                companyId: selectedCompanies[0],
                                            },
                                        ]}
                                    />
                                }
                            />
                        </div>
                    )}
                />
                {googleIsSelected ? (
                    <>
                        <Controller
                            control={control}
                            name="button"
                            render={({ field, ref }) => (
                                <Select
                                    {...field}
                                    onChange={({ target: { value } }) => {
                                        setValue('button', value, { shouldDirty: true });
                                        if (value === 0 || value === 6) {
                                            setValue('buttonContent', '', { shouldDirty: true });
                                        }
                                    }}
                                    ref={ref}
                                    label={t('common.button')}
                                    fullWidth
                                    options={buttonOptions}
                                    style={{ gridArea: 'select3' }}
                                    disabled={isView}
                                />
                            )}
                        />
                        {button !== 0 && button !== 6 ? (
                            <Controller
                                control={control}
                                name="buttonContent"
                                render={({ field, fieldState: { error }, ref }) => (
                                    <Input
                                        ref={ref}
                                        inputProps={{
                                            ...field,
                                            onChange: e => field.onChange(e.target.value),
                                        }}
                                        label={t('MicrositesPage.link')}
                                        fullWidth
                                        error={!!error}
                                        helperText={error ? t(error.message) : null}
                                        sx={{ gridArea: 'input4' }}
                                        disabled={isView}
                                    />
                                )}
                            />
                        ) : (
                            <div style={{ gridArea: 'input4' }} />
                        )}
                    </>
                ) : (
                    <>
                        <div style={{ gridArea: 'select3' }} />
                        <div style={{ gridArea: 'input4' }} />
                    </>
                )}
            </Content>
            <Typography variant="body2" color="textPrimary">
                {t('common.uploadMedia')} {instagramIsSelected ? <span style={{ color: '#B00020' }}>*</span> : null}
            </Typography>
            <Typography
                variant="caption"
                sx={{ color: 'rgba(0, 0, 0, 0.6)', marginBottom: '12px', display: 'inline-block' }}
            >
                {t('SocialPosts.imageFormat')}
                <br />
                {t('SocialPosts.videoFormat')}
            </Typography>
            <MediaUploaderMulti
                limit={10}
                imagesValidation={{ maxSize: 4 * MB, minSize: 10 * KB }}
                videoValidation={{
                    maxSize: 100 * MB,
                    minSize: 1 * KB,
                    dimension: [undefined, undefined, 1920, 1080],
                    minDuration: 3,
                    maxDuration: 60,
                }}
                extensions={googleIsSelected ? '.png, .jpg, .jpeg' : '.png, .jpg, .jpeg, .mp4'}
                disabled={isView}
                data={selectedSocialPost?.media || []}
                onChange={updatedMedia => setValue('media', updatedMedia, { shouldDirty: true, shouldValidate: true })}
                imageServiceCallback={data => socialPostsService.uploadSocialPostImages(data)}
                videoServiceCallback={data => socialPostsService.uploadSocialPostVideos(data)}
                setIsUploading={setIsMediaUploading}
            />
            {!isMediaValid && (
                <Typography variant="caption" sx={{ color: '#B00020', display: 'block', marginTop: '8px' }}>
                    {t(`apiErrors.${mediaErrorText}`)}
                </Typography>
            )}
        </Modal>
    );
};

export default EditModal;
