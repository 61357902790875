import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const facebookIntegrationService = {
    disconnectIntegration,
    authenticateFacebookIntegration,
    getPages,
    editProfile,
    getLocationListing,
    updateLocationListing,
    authenticateMetaBusinessIntegration,
};

function getPages() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/facebook/pages`, requestOptions).then(handleResponse);
}

function disconnectIntegration() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/facebook/revoke-access`, requestOptions).then(handleResponse);
}

function authenticateFacebookIntegration(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/facebook/authenticate`, requestOptions).then(handleResponse);
}

function authenticateMetaBusinessIntegration(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/facebook/authenticate-system-user`, requestOptions).then(handleResponse);
}

function editProfile(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/profiles/facebook`, requestOptions).then(handleResponse);
}

function getLocationListing(profileId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/profiles/facebook/${profileId}/location`, requestOptions).then(handleResponse);
}

function updateLocationListing(profileId, data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/profiles/facebook/${profileId}/location`, requestOptions).then(handleResponse);
}
