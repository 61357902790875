import { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@components/Typography';
import DoughnutChart from '@components/Charts/DoughnutChart';
import { CONVERSATION_MAP } from '@constants/conversations';
import { useTranslation } from '@hooks/useTranslation';
import { formatNumber, formatPercentNumber } from '@helpers/formatNumbers';

const createChartData = (data, hasData, isMobile) => {
    const EMPTY_SPACE = '                                   ';
    const backgroundColors = ['#D0D0D0', '#F3F7D7', '#E7EEB3', '#BDE0CC', '#98CBAD', '#1F4C5C'];
    const borderColors = ['#D0D0D0', '#F3F7D7', '#E7EEB3', '#BDE0CC', '#98CBAD', '#1F4C5C'];
    return {
        labels: Object.entries(CONVERSATION_MAP).map(([key, value]) => {
            let set;
            if (value === CONVERSATION_MAP.Archived) {
                set = data.find(d => d.channelId === null);
            } else {
                set = data.find(d => d.channelType === +value);
            }
            return `${key} - ${formatNumber(set?.count || 0)} (${formatPercentNumber(set?.percentage || 0)})${
                isMobile ? EMPTY_SPACE : ''
            }`;
        }),
        datasets: [
            {
                label: '',
                data: !hasData
                    ? [1]
                    : Object.entries(CONVERSATION_MAP).map(([, value]) => {
                          if (value === CONVERSATION_MAP.Archived) {
                              return data.find(d => d.channelId === null)?.count || 0;
                          } else {
                              return data.find(d => d.channelType === +value)?.count || 0;
                          }
                      }),
                backgroundColor: !hasData ? ['#F2F2F2', ...backgroundColors] : backgroundColors,
                borderColor: !hasData ? ['#F2F2F2', ...borderColors] : borderColors,
                borderWidth: 1,
            },
        ],
    };
};

const ChannelsBreakdown = ({ data, isLoading }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('@media (max-width: 600px)');

    const hasData = useMemo(() => data.channelsBreakdown.some(item => !!item.count), [data]);

    const datasets = useMemo(
        () => createChartData(data.channelsBreakdown, hasData, isMobile),
        [data.channelsBreakdown, hasData, isMobile],
    );

    return (
        <Paper
            elevation={1}
            sx={{
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '4px',
                breakInside: 'avoid',
                gridArea: 'doughnut',
            }}
        >
            <Typography variant="title1" style={{ paddingBottom: '10px' }}>
                {t('AnalyticsConversations.channelsBreakdown')}
            </Typography>
            <div
                style={{
                    position: 'relative',
                    width: isMobile ? '100%' : '450px',
                    height: isMobile ? '460px' : '300px',
                    marginTop: isMobile ? '32px' : 0,
                }}
            >
                {!isLoading ? (
                    <DoughnutChart
                        datasets={datasets}
                        hasData={hasData}
                        optionsProps={{
                            plugins: {
                                legend: {
                                    position: isMobile ? 'bottom' : 'right',
                                    ...(isMobile && { align: 'start' }),
                                },
                            },
                        }}
                        overall={{
                            title: t('AnalyticsConversations.totalConversations'),
                            data: data.conversationsCount?.toLocaleString('en-US'),
                        }}
                    />
                ) : null}
            </div>
        </Paper>
    );
};

export default ChannelsBreakdown;
