import { sentRequestsConstants } from '@constants/sent-requests';

const initialState = {
    data: [],
    loading: true,
    error: '',
    totalCount: 0,
    pages: 1,
};

export function sentRequests(state = initialState, action) {
    switch (action.type) {
        case sentRequestsConstants.SENT_REQUESTS_FETCH:
            return { ...state, loading: true, error: '' };
        case sentRequestsConstants.SENT_REQUESTS_SUCCESS:
            return {
                loading: false,
                data: action.data.items,
                totalCount: action.data.totalCount,
                pages: action.data.pages,
                error: '',
            };
        case sentRequestsConstants.SENT_REQUESTS_ERROR:
            return { ...state, error: action.message, loading: false };
        default:
            return state;
    }
}
