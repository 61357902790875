import { useEffect, useState, useRef } from 'react';

export const useHubspotChat = () => {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [activeConversation, setActiveConversation] = useState(false);
    const eventRef = useRef(null);

    useEffect(() => {
        window.hsConversationsOnReady = [() => setHasLoaded(true)];
        return () => {
            window.hsConversationsOnReady = [];
        };
    }, []);

    // Subscripe to conversation events.
    useEffect(() => {
        eventRef.current = payload => {
            setActiveConversation(payload.conversation.conversationId);
        };

        if (hasLoaded) {
            window.HubSpotConversations?.on('conversationStarted', eventRef.current);
        }

        return () => {
            window.HubSpotConversations?.off('conversationStarted', eventRef.current);
        };
    }, [hasLoaded]);

    const openHandler = () => window.HubSpotConversations?.widget?.open();

    const closeHandler = () => window.HubSpotConversations?.widget?.close();

    return {
        hasLoaded,
        activeConversation,
        openHandler,
        closeHandler,
    };
};
