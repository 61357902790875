import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { styled } from '@mui/material/styles';
import { Typography } from '@components/Typography';
import { Input } from '@components/Input';
import Button from '@components/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Switch } from '@components/Switch';
import { userActions, accountActions, alertActions } from '@actions';
import { userService } from '@services';
import { useTranslation } from '@hooks/useTranslation';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import UserAccessTable from './UserAccessTable';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'calc(var(--100vh) - 221px)',
    },
});

const Content = styled('div')({
    maxWidth: '698px',
    display: 'grid',
    columnGap: '24px',
    rowGap: '16px',
    gridTemplateColumns: '1fr 1fr',
    '@media (max-width: 1024px)': {
        gridTemplateColumns: '1fr',
        maxWidth: 'none',
    },
});

const Footer = styled('div')({
    position: 'fixed',
    padding: 24,
    bottom: 0,
    background: 'white',
    left: 280,
    right: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    '@media (max-width: 1024px)': {
        left: 0,
    },
});

const UserForm = ({ selectedUser, setView, view, account, currentUser }) => {
    const { t } = useTranslation();

    const [isSubmitting, setSubmitting] = useState(false);
    const [availableCompanies, setAvailableCompanies] = useState([]);
    const [toggleGlobalAdmin, setToggleGlobalAdmin] = useState(false);
    const [isDirtyTable, setDirtyTable] = useState(false);

    const dispatch = useDispatch();

    const AccountUserSettingsFormSchema = yup.object().shape(
        {
            firstName: yup.string().required('validation.required'),
            email: yup.string().required('validation.required').email('validation.wrongEmailFormat'),
            phoneNumber: yup.string().validatePhone('validation.wrongPhoneFormat'),
        },
        [['phoneNumber', 'phoneNumber']],
    );

    const resolver = useYupValidationResolver(AccountUserSettingsFormSchema);

    const {
        getValues,
        control,
        formState: { isValid, isDirty },
    } = useForm({
        mode: 'all',
        resolver,
        defaultValues: {
            id: selectedUser?.id || 0,
            firstName: selectedUser?.firstName || '',
            lastName: selectedUser?.lastName || '',
            email: selectedUser?.email || '',
            phoneNumber: selectedUser?.phoneNumber || '',
            companies: [],
            isGlobalAdmin: selectedUser?.isGlobalAdmin || false,
        },
    });

    useEffect(() => {
        const companies = account?.companies
            .filter(c => {
                const isAdmin = !!currentUser.roles?.find(r => r.companyId === c.companyId && r.role === 0);
                return currentUser.isAccountOwner || currentUser.isGlobalAdmin || toggleGlobalAdmin || isAdmin;
            })
            .map(company => {
                const role = selectedUser?.roles?.find(r => r.companyId === company.companyId)?.role;
                return {
                    name: company.internalName,
                    id: company.companyId,
                    enabled: role !== undefined || selectedUser?.isAccountOwner || selectedUser?.isGlobalAdmin,
                    role: role !== undefined ? role : 1,
                };
            });
        setAvailableCompanies(companies);
    }, [account, currentUser, selectedUser, toggleGlobalAdmin]);

    useEffect(() => {
        if (selectedUser?.isAccountOwner || selectedUser?.isGlobalAdmin) {
            setToggleGlobalAdmin(true);
        }
    }, [selectedUser]);

    const handleSubmit = async e => {
        e.preventDefault();
        const values = getValues();
        setSubmitting(true);
        try {
            await userService.updateUser({ ...values, locationsPermissions: availableCompanies });
            await dispatch(accountActions.get());
            if (values.id === currentUser.id) {
                await dispatch(userActions.getUser(currentUser.id));
            }
            dispatch(
                alertActions.success(selectedUser ? t('alertMessages.editSuccess') : t('alertMessages.createSuccess')),
            );
            setView(view.TABLE);
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`, { email: values.email })));
            } else {
                dispatch(
                    alertActions.error(selectedUser ? t('alertMessages.editFail') : t('alertMessages.createFail')),
                );
            }
        }
        setSubmitting(false);
    };

    return (
        <Container>
            <div style={{ marginBottom: 104, overflow: 'hidden' }}>
                <Typography
                    variant="h5"
                    sx={{
                        marginBottom: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#1F4C5C',
                    }}
                >
                    <ArrowBackIcon
                        onClick={() => setView(view.TABLE)}
                        sx={{ marginRight: '12px', cursor: 'pointer' }}
                    />
                    {selectedUser
                        ? `${selectedUser.firstName} ${selectedUser.lastName}`
                        : t('SettingsUsers.addNewUser')}
                </Typography>
                <Typography variant="h6" sx={{ color: 'rgba(0, 0, 0, 0.87)', marginBottom: '24px' }}>
                    {t('SettingsUsers.generalInfo')}
                </Typography>
                <Content>
                    <Controller
                        control={control}
                        name="firstName"
                        render={({ field, fieldState: { error } }) => (
                            <Input
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('common.firstName')}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                required
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="lastName"
                        render={({ field, fieldState: { error } }) => (
                            <Input
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('common.lastNameOpt')}
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="email"
                        render={({ field, fieldState: { error } }) => (
                            <Input
                                inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                                label={t('common.email')}
                                fullWidth
                                disabled={!!selectedUser}
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                                required
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="phoneNumber"
                        render={({ field, fieldState: { error }, ref }) => (
                            <Input
                                ref={ref}
                                inputProps={{ ...field }}
                                label={t('common.phoneNumberOpt')}
                                autoComplete="new-password"
                                fullWidth
                                error={!!error}
                                helperText={error ? t(error.message) : null}
                            />
                        )}
                    />
                </Content>
                <Controller
                    control={control}
                    name="isGlobalAdmin"
                    render={({ field, ref }) => (
                        <Switch
                            {...field}
                            ref={ref}
                            sx={{ paddingLeft: '10px' }}
                            checked={getValues('isGlobalAdmin')}
                            handleChange={event => {
                                field.onChange(event.target.checked);
                                setToggleGlobalAdmin(event.target.checked);
                            }}
                            label="Global Admin"
                            disabled={
                                selectedUser?.isAccountOwner ||
                                (!currentUser?.isAccountOwner && !currentUser.isGlobalAdmin)
                            }
                        />
                    )}
                />
                <Typography
                    variant="caption"
                    sx={{ display: 'block', margin: '4px 0 24px', color: 'rgba(0, 0, 0, 0.6)' }}
                >
                    {t('SettingsUsers.isGlobalAdmin')}
                </Typography>
                <UserAccessTable
                    companies={availableCompanies}
                    setCompanies={setAvailableCompanies}
                    toggleGlobalAdmin={toggleGlobalAdmin}
                    setDirtyTable={setDirtyTable}
                />
                <Footer>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting || !isValid || (!isDirty && !isDirtyTable)}
                        onClick={handleSubmit}
                    >
                        {t('buttons.saveChanges')}
                    </Button>
                </Footer>
            </div>
        </Container>
    );
};

export default UserForm;
