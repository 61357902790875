import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Switch } from '@components/Switch';
import BusinessHoursSelection from '@features/BusinessHoursSelection';
import { useTranslation } from '@hooks/useTranslation';
import { LocationIntegrationName } from '@constants/integrations';

const Form = styled('div')({
    maxWidth: 698,
    display: 'grid',
    rowGap: 16,
    gridTemplateColumns: 'minmax(0, 1fr)',
    '@media (max-width: 1024px)': {
        gridTemplateColumns: 'minmax(0, 1fr)',
        maxWidth: 'none',
    },
});

const BusinessHours = () => {
    const { t } = useTranslation();
    const { control, setValue, watch } = useFormContext();
    const isWorkingHoursEnabled = watch('workingHoursEnabled');
    return (
        <Form>
            <Controller
                control={control}
                name="workingHoursEnabled"
                render={({ field, ref }) => (
                    <Switch
                        {...field}
                        ref={ref}
                        checked={field.value}
                        handleChange={event => {
                            setValue('workingHoursEnabled', event.target.checked, {
                                shouldDirty: true,
                            });
                        }}
                        label={t('SettingsChannels.businessHoursSwitch')}
                    />
                )}
            />
            {isWorkingHoursEnabled && (
                <Controller
                    control={control}
                    name="workingHours"
                    render={({ field, ref }) => (
                        <BusinessHoursSelection
                            {...field}
                            data={field?.value}
                            ref={ref}
                            withAdditionalTime
                            type={LocationIntegrationName.Google}
                            onChangeHours={value => {
                                setValue('workingHours', value, { shouldDirty: true });
                                if (!value.length) {
                                    setValue('specialHours', []);
                                }
                            }}
                        />
                    )}
                />
            )}
        </Form>
    );
};

export default BusinessHours;
