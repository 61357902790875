import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Typography } from '@components/Typography';
import { InternalLink } from '@components/Link';

const Container = styled('div')({
    padding: 16,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#fff',
    borderRadius: 4,
    boxShadow:
        '0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.20)',
    '&:not(:last-child)': {
        marginBottom: 16,
    },
});

const Header = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '& svg path': {
        fill: '#1F4C5C',
        fillOpacity: 1,
    },
});

const SectionWrapper = ({ children, withLink = true, icon: Icon, text, url, ...rest }) => {
    return (
        <Container {...rest}>
            <div>
                <Header>
                    <Icon width={24} height={24} style={{ marginRight: '8px' }} />
                    <Typography variant="subtitle2">{text}</Typography>
                    {withLink && (
                        <InternalLink to={url} underline="none" sx={{ marginLeft: 'auto', height: '18px' }}>
                            <IconButton sx={{ height: 24, width: 24 }} color="inherit">
                                <ArrowForwardIcon sx={{ color: '#1F4C5C', height: 18, width: 18 }} />
                            </IconButton>
                        </InternalLink>
                    )}
                </Header>
                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.12)', margin: '16px 0', height: '1px' }} />
            </div>
            {children}
        </Container>
    );
};

export default SectionWrapper;
