import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const init = () => {
    if (process.env.REACT_APP_STAGE === 'prod' || process.env.REACT_APP_STAGE === 'stage') {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [new BrowserTracing()],
            release: process.env.REACT_APP_SENTRY_RELEASE,
            ignoreErrors: ['Non-Error promise rejection captured'],
            environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
            tracesSampleRate: process.env.REACT_APP_STAGE === 'stage' ? 0 : 0.1,
        });
    }
};
