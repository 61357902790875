import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '@hooks/useTranslation';
import Drawer from '@components/Drawer';
import { LocationIntegrationName } from '@constants/integrations';
import { alertActions, coverManagerActions, accountActions, facebookActions, googleActions } from '@actions';
import { coverManagerIntegrationService, facebookIntegrationService, googleIntegrationService } from '@services';
import LocationCard from './LocationCard';
import GoogleLocationCard from './GoogleLocationCard';

const GenericIntegration = ({ onClose, onConnect, isOpen, selectedCompany, setSelectedCompany, integrationType }) => {
    const { t } = useTranslation();
    const [location, setLocation] = useState();

    const account = useSelector(state => state.account.account);
    const auth = useSelector(state => state.authentication);
    const IS_ADMIN = useSelector(state => {
        if (!state.authentication?.user || !state.authentication?.user?.id) {
            return false;
        }
        return (
            state.authentication?.user?.roles?.filter(u => u.companyId === selectedCompany?.companyId && u.role === 0)
                ?.length > 0
        );
    });
    const getData = type => {
        switch (type) {
            case LocationIntegrationName.CoverManager:
                return useSelector(state => state.coverManager.data);
            case LocationIntegrationName.Facebook:
                return useSelector(state => state.facebook.data);
            case LocationIntegrationName.Google:
                return useSelector(state => state.google.data);
            default:
                break;
        }
    };
    const LOCATIONS = getData(integrationType);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        integrationType === LocationIntegrationName.CoverManager && dispatch(coverManagerActions.get());
        integrationType === LocationIntegrationName.Facebook && dispatch(facebookActions.getPages());
        integrationType === LocationIntegrationName.Google && dispatch(googleActions.get());
    }, [dispatch]);

    useEffect(() => {
        if (LOCATIONS?.length) {
            setLocation('0');
        }
    }, [LOCATIONS]);

    const IS_ACCOUNT_OWNER = auth.user?.isAccountOwner || auth.user?.isGlobalAdmin;
    const INTEGRATION_CONNECTED = account?.integrations.find(i => i.type === integrationType)?.isActive;

    const callback = {
        [LocationIntegrationName.CoverManager]: data => coverManagerIntegrationService.editProfile(data),
        [LocationIntegrationName.Facebook]: data => facebookIntegrationService.editProfile(data),
        [LocationIntegrationName.Google]: data => googleIntegrationService.editProfile(data),
    };

    const goToIntegrations = () => history.push('/account-settings/integrations');

    const onConnectIntegration = async () => {
        const data = {
            companyId: selectedCompany.companyId,
            ...(integrationType === LocationIntegrationName.CoverManager
                ? {
                      restaurantSlug: LOCATIONS[location].slug,
                      name: LOCATIONS[location].name,
                  }
                : {}),
            ...(integrationType === LocationIntegrationName.Facebook
                ? {
                      pageId: LOCATIONS[location].id,
                      name: LOCATIONS[location].name,
                      username: LOCATIONS[location].username,
                  }
                : {}),
            ...(integrationType === LocationIntegrationName.Google
                ? {
                      googlePlaceId: LOCATIONS[location].placeId || null,
                      googleAccountId: LOCATIONS[location].accountId || null,
                      googleLocationId: LOCATIONS[location].locationId || null,
                      name: LOCATIONS[location].title,
                  }
                : {}),
        };
        try {
            onClose();
            const updatedCompany = await callback[integrationType](data);
            await dispatch(accountActions.get());
            setSelectedCompany(updatedCompany);
            dispatch(alertActions.success(t('SettingsCompanies.connectIntegrationMsg')));
        } catch (error) {
            dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`) || t('apiErrors.something_wrong')));
        }
        onConnect();
    };

    const getPrimaryActionProps = () => {
        if (!INTEGRATION_CONNECTED && IS_ACCOUNT_OWNER) {
            return {
                primaryAction: goToIntegrations,
                primaryText: t('SettingsCompanies.goToIntegrations'),
                primaryDisabled: false,
            };
        } else if (INTEGRATION_CONNECTED && (IS_ACCOUNT_OWNER || IS_ADMIN)) {
            return {
                primaryAction: onConnectIntegration,
                primaryText: t('buttons.connect'),
                primaryDisabled: !location,
            };
        } else if (!INTEGRATION_CONNECTED && !IS_ACCOUNT_OWNER) {
            return {
                primaryAction: goToIntegrations,
                primaryText: t('SettingsCompanies.goToIntegrations'),
                primaryDisabled: true,
            };
        }
    };

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            title={
                INTEGRATION_CONNECTED
                    ? t('SettingsCompanies.connectLocation')
                    : t('SettingsCompanies.connectDrawerNotConnected')
            }
            subtitle={
                INTEGRATION_CONNECTED
                    ? t('SettingsCompanies.connectDrawerDescription')
                    : t('SettingsCompanies.connectDrawerNotConnectedDescription')
            }
            {...getPrimaryActionProps()}
            secondaryText={INTEGRATION_CONNECTED && (IS_ACCOUNT_OWNER || IS_ADMIN) ? t('buttons.cancel') : null}
        >
            <div style={{ display: 'grid', gridGap: '8px' }}>
                {INTEGRATION_CONNECTED &&
                    location &&
                    LOCATIONS.map((item, index) => {
                        return (
                            <>
                                {integrationType !== LocationIntegrationName.Google && (
                                    <LocationCard
                                        key={item.id}
                                        location={item}
                                        selectedLocation={location}
                                        setLocation={setLocation}
                                        index={index}
                                        integrationType={integrationType}
                                    />
                                )}
                                {integrationType === LocationIntegrationName.Google && (
                                    <GoogleLocationCard
                                        key={item.name}
                                        location={item}
                                        selectedLocation={location}
                                        setLocation={setLocation}
                                        index={index}
                                    />
                                )}
                            </>
                        );
                    })}
            </div>
        </Drawer>
    );
};

export default GenericIntegration;
