import { useState, useEffect } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { formatISO, parseISO } from 'date-fns';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as yup from 'yup';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Typography } from '@components/Typography';
import Button from '@components/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { accountActions, alertActions } from '@actions';
import { companyService } from '@services';
import { useTranslation } from '@hooks/useTranslation';
import { AdditionalContactType } from '@constants/companies';
import IntegrationsTable from '../IntegrationsTable';
import Profiles from '../Profiles';
import GeneralInfo from './GeneralInfo';
import Contacts from './Contacts';
import Address from './Address';
import AdditionalInfo from './AdditionalInfo';
import BusinessHours from './BusinessHours';
import Appearance from './Appearance';
import { Container, Content, Footer } from './styles';

const ExpandedSection = ({ isOpen, onClick, text, children }) => {
    const iconStyles = { width: '17px', marginLeft: '6px', color: 'rgba(0, 0, 0, 0.6)' };
    return (
        <div>
            <div
                onClick={onClick}
                style={{
                    display: 'flex',
                    width: 'max-content',
                    alignItems: 'center',
                    marginBottom: isOpen ? '16px' : 0,
                    cursor: 'pointer',
                }}
            >
                <Typography variant="subtitle1" color="textPrimary">
                    {text}
                </Typography>
                {isOpen ? <ExpandLessIcon sx={iconStyles} /> : <ExpandMoreIcon sx={iconStyles} />}
            </div>
            {isOpen && children}
        </div>
    );
};

const EditForm = ({ filter, page, rowsPerPage, view, setView, selectedCompany, setSelectedCompany, account }) => {
    const [section, setSection] = useState({
        general: true,
        contact: false,
        address: false,
        additionalInfo: false,
        businessHours: false,
        appearance: false,
    });
    const { t } = useTranslation();
    const language = useSelector(state => state.authentication.user?.language);
    const { data: countries, loading: countriesLoading } = useSelector(state => state.countries);

    const dispatch = useDispatch();

    const CompanyFormSchema = yup.object().shape(
        {
            locationName: yup.string().required('validation.required'),
            internalName: yup.string().required('validation.required'),
            status: yup.number().required('validation.required'),
            phoneNumber: yup
                .string()
                .validatePhone('validation.wrongPhoneFormat', true)
                .required('validation.required'),
            phoneExtension: yup.string().when('phoneExtension', {
                is: val => !val?.length,
                then: yup.string().notRequired(),
                otherwise: yup.string().matches(/^\d+$/),
            }),
            email: yup.string().nullable().email('validation.wrongEmailFormat'),
            website: yup.string().url('validation.invalidURL'),
            serviceContacts: yup.array().of(
                yup.object().shape(
                    {
                        type: yup.number(),
                        phoneNumber: yup.string().when('type', {
                            is: val => val === AdditionalContactType.Phone,
                            then: yup
                                .string()
                                .validatePhone('validation.wrongPhoneFormat', true)
                                .required('validation.required'),
                            otherwise: yup.string().notRequired(),
                        }),
                        phoneExtension: yup.string().when('phoneExtension', {
                            is: val => !val?.length,
                            then: yup.string().notRequired(),
                            otherwise: yup.string().matches(/^\d+$/),
                        }),
                        email: yup.string().when('type', {
                            is: val => val === AdditionalContactType.Email,
                            then: yup.string().required('validation.required').email('validation.wrongEmailFormat'),
                            otherwise: yup.string().notRequired(),
                        }),
                    },
                    ['phoneExtension', 'phoneExtension'],
                ),
            ),
            hasBusinessAddress: yup.boolean(),
            addressLine1: yup.string().when('hasBusinessAddress', {
                is: true,
                then: yup.string().required('validation.required'),
                otherwise: yup.string().nullable(),
            }),
            city: yup.string().when('hasBusinessAddress', {
                is: true,
                then: yup.string().required('validation.required'),
                otherwise: yup.string().nullable(),
            }),
            country: yup.string().when('hasBusinessAddress', {
                is: true,
                then: yup.string().required('validation.required'),
                otherwise: yup.string().nullable(),
            }),
            postalCode: yup.string().when('hasBusinessAddress', {
                is: true,
                then: yup.string().required('validation.required'),
                otherwise: yup.string().nullable(),
            }),
            shortDescription: yup.string().max(75, 'validation.maxLength'),
            longDescription: yup.string().max(750, 'validation.maxLength'),
        },
        [['phoneExtension', 'phoneExtension']],
    );

    const resolver = useYupValidationResolver(CompanyFormSchema);

    const {
        getValues,
        setValue,
        control,
        handleSubmit,
        watch,
        trigger,
        reset,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'onBlur',
        resolver: resolver,
        defaultValues: {
            locationName: '',
            internalName: '',
            status: '',
            brand: '',
            phoneNumber: '',
            phoneExtension: '',
            email: '',
            website: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            region: '',
            country: '',
            postalCode: '',
            hasBusinessAddress: false,
            latitude: '',
            longitude: '',
            shortDescription: '',
            longDescription: '',
            openingDate: null,
            workingHoursEnabled: false,
            workingHours: [],
            logoUrl: null,
            coverUrl: null,
            serviceContacts: [],
        },
    });

    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'serviceContacts',
    });
    const watchFieldArray = watch('serviceContacts');
    const serviceContactsFields = fields.map((field, index) => ({ ...field, ...watchFieldArray[index] }));

    useEffect(() => {
        if (selectedCompany?.companyId && !countriesLoading) {
            setValue('locationName', selectedCompany?.locationName || '', { shouldValidate: true });
            setValue('internalName', selectedCompany?.internalName || '', { shouldValidate: true });
            setValue('status', selectedCompany?.status || undefined, { shouldValidate: true });
            setValue('brand', selectedCompany?.brand?.id || undefined);
            const formattedNumber = selectedCompany?.phoneNumber?.startsWith('+')
                ? selectedCompany?.phoneNumber?.slice(1)
                : selectedCompany?.phoneNumber;
            setValue('phoneNumber', formattedNumber || '', { shouldValidate: true });
            setValue('phoneExtension', selectedCompany?.phoneExtension || '', { shouldValidate: true });
            setValue('email', selectedCompany?.email || '', { shouldValidate: true });
            setValue('website', selectedCompany?.website || '', { shouldValidate: true });
            setValue('addressLine1', selectedCompany?.addressLine1 || '', { shouldValidate: true });
            setValue('addressLine2', selectedCompany?.addressLine2 || '');
            setValue('city', selectedCompany?.city || '', { shouldValidate: true });
            const country = countries.find(item => item.iso2 === selectedCompany?.country);
            const isRegionReal = country?.states?.find(item => item.name === selectedCompany?.region);
            setValue('region', isRegionReal ? selectedCompany?.region : '');
            setValue('country', selectedCompany?.country || '', { shouldValidate: true });
            setValue('postalCode', selectedCompany?.postalCode || '', { shouldValidate: true });
            setValue('hasBusinessAddress', selectedCompany?.hasBusinessAddress || false);
            setValue('latitude', selectedCompany?.latitude || '');
            setValue('longitude', selectedCompany?.longitude || '');
            setValue('shortDescription', selectedCompany?.shortDescription || '', { shouldValidate: true });
            setValue('longDescription', selectedCompany?.longDescription || '', { shouldValidate: true });
            setValue('workingHours', selectedCompany?.workingHours || []);
            setValue('workingHoursEnabled', selectedCompany?.workingHoursEnabled);
            setValue('logoUrl', selectedCompany?.logoUrl);
            setValue('coverUrl', selectedCompany?.coverUrl);
            if (selectedCompany?.openingDate) {
                setValue('openingDate', new Date(parseISO(selectedCompany?.openingDate)));
            }
            setValue(
                'serviceContacts',
                selectedCompany.serviceContacts?.map(field => {
                    const formattedServiceNumber = field?.phoneNumber?.startsWith('+')
                        ? field?.phoneNumber?.slice(1)
                        : field?.phoneNumber;
                    return {
                        type: field.type,
                        phoneNumber: formattedServiceNumber || '',
                        phoneExtension: field.phoneExtension,
                        email: field.email,
                    };
                }) || [],
                { shouldValidate: true },
            );
        }
    }, [selectedCompany, countriesLoading]);

    const onSubmit = async () => {
        const values = getValues();
        try {
            await companyService.updateCompany({
                ...selectedCompany,
                ...values,
                brand: values.brand ? { id: values.brand } : null,
                phoneNumber: values.phoneNumber?.startsWith('+') ? values.phoneNumber : `+${values.phoneNumber}`,
                openingDate: values.openingDate ? formatISO(values.openingDate, { representation: 'date' }) : null,
                serviceContacts: values.serviceContacts.map(contact => {
                    if (contact.type === AdditionalContactType.Phone) {
                        return {
                            ...contact,
                            phoneNumber: contact.phoneNumber?.startsWith('+')
                                ? contact.phoneNumber
                                : `+${contact.phoneNumber}`,
                        };
                    }
                    return contact;
                }),
            });
            dispatch(alertActions.success(t('alertMessages.updateSuccess')));
            dispatch(accountActions.get());
            reset(values);
        } catch (error) {
            if (error.errorCode) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(t('alertMessages.updateFail')));
            }
        }
    };

    const onClickCancel = () => {
        dispatch(accountActions.getLocations(filter, page, rowsPerPage));
        setSelectedCompany({});
        setView(view.LIST);
    };

    return (
        <>
            <Container>
                <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                    <ArrowBackIcon
                        sx={{ cursor: 'pointer', fill: '#1F4C5C', marginRight: '12px' }}
                        onClick={onClickCancel}
                    />
                    <Typography
                        variant="h5"
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipses',
                            whitespace: 'nowrap',
                            display: 'block',
                            color: '#1F4C5C',
                        }}
                        noWrap
                    >
                        {selectedCompany?.internalName}
                    </Typography>
                </div>
                <Content>
                    <FormProvider
                        {...{
                            control,
                            setValue,
                            getValues,
                            watch,
                            trigger,
                            fields: serviceContactsFields,
                            update,
                            append,
                            remove,
                        }}
                    >
                        <ExpandedSection
                            isOpen={section.general}
                            onClick={() => setSection(prev => ({ ...prev, general: !prev.general }))}
                            text={t('SettingsCompanies.generalInfo')}
                        >
                            <GeneralInfo selectedCompany={selectedCompany} />
                        </ExpandedSection>
                        <ExpandedSection
                            isOpen={section.contact}
                            onClick={() => setSection(prev => ({ ...prev, contact: !prev.contact }))}
                            text={t('SettingsCompanies.contact')}
                        >
                            <Contacts />
                        </ExpandedSection>
                        <ExpandedSection
                            isOpen={section.address}
                            onClick={() => setSection(prev => ({ ...prev, address: !prev.address }))}
                            text={t('SettingsCompanies.address')}
                        >
                            <Address language={language} countries={countries} />
                        </ExpandedSection>
                        <ExpandedSection
                            isOpen={section.businessHours}
                            onClick={() => setSection(prev => ({ ...prev, businessHours: !prev.businessHours }))}
                            text={t('SettingsCompanies.businessHours')}
                        >
                            <BusinessHours />
                        </ExpandedSection>
                        <ExpandedSection
                            isOpen={section.additionalInfo}
                            onClick={() => setSection(prev => ({ ...prev, additionalInfo: !prev.additionalInfo }))}
                            text={t('SettingsCompanies.additionalInformation')}
                        >
                            <AdditionalInfo />
                        </ExpandedSection>
                        <ExpandedSection
                            isOpen={section.appearance}
                            onClick={() => setSection(prev => ({ ...prev, appearance: !prev.appearance }))}
                            text={t('SettingsCompanies.appearance')}
                        >
                            <Appearance companyId={selectedCompany?.companyId} />
                        </ExpandedSection>
                    </FormProvider>
                    <IntegrationsTable
                        account={account}
                        selectedCompany={selectedCompany}
                        setSelectedCompany={setSelectedCompany}
                    />
                    <Profiles selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany} />
                </Content>
            </Container>
            <Footer>
                <Button
                    onClick={onClickCancel}
                    variant="outlined"
                    sx={{ '@media (max-width: 1024px)': { width: 'calc(50% - 4px)' } }}
                >
                    {t('buttons.cancel')}
                </Button>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    disabled={isSubmitting || !isValid || !isDirty}
                    sx={{ '@media (max-width: 1024px)': { width: 'calc(50% - 4px)' } }}
                >
                    {t('buttons.saveChanges')}
                </Button>
            </Footer>
        </>
    );
};

export default EditForm;
