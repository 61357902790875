import { Typography } from '@components/Typography';
import { formatNumber } from '@helpers/formatNumbers';
import { getTableArrow, TableCellText } from '../../Feedback/FeedbackTable';

export const getTableBody = data => {
    return data.map(
        ({
            socialPostTitle,
            socialPostTotal = false,
            impressions,
            impressionsDelta,
            reach,
            reachDelta,
            likes,
            likesDelta,
            comments,
            commentsDelta,
            shares,
            sharesDelta,
        }) => {
            return socialPostTotal
                ? [
                      {
                          component: (
                              <Typography variant="subtitle2" align="left">
                                  {socialPostTitle || 'NA'}
                              </Typography>
                          ),
                      },
                      {
                          component: (
                              <TableCellText variant="body2" align="right">
                                  <span style={{ marginRight: impressionsDelta === 0 ? '28px' : '8px' }}>
                                      {formatNumber(impressions)}
                                  </span>
                                  {getTableArrow(impressionsDelta)}
                              </TableCellText>
                          ),
                      },
                      {
                          component: (
                              <TableCellText variant="body2" align="right">
                                  <span style={{ marginRight: reachDelta === 0 ? '28px' : '8px' }}>
                                      {formatNumber(reach)}
                                  </span>
                                  {getTableArrow(reachDelta)}
                              </TableCellText>
                          ),
                      },
                      {
                          component: (
                              <TableCellText variant="body2" align="right">
                                  <span style={{ marginRight: likesDelta === 0 ? '28px' : '8px' }}>
                                      {formatNumber(likes)}
                                  </span>
                                  {getTableArrow(likesDelta)}
                              </TableCellText>
                          ),
                      },
                      {
                          component: (
                              <TableCellText variant="body2" align="right">
                                  <span style={{ marginRight: commentsDelta === 0 ? '28px' : '8px' }}>
                                      {formatNumber(comments)}
                                  </span>
                                  {getTableArrow(commentsDelta)}
                              </TableCellText>
                          ),
                      },
                      {
                          component: (
                              <TableCellText variant="body2" align="right">
                                  <span style={{ marginRight: sharesDelta === 0 ? '28px' : '8px' }}>
                                      {formatNumber(shares)}
                                  </span>
                                  {getTableArrow(sharesDelta)}
                              </TableCellText>
                          ),
                      },
                  ]
                : null;
        },
    );
};
