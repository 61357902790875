import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import MessageIcon from '@mui/icons-material/Message';
import Tooltip from '@components/Tooltip';
import IconButton from '@mui/material/IconButton';
import PageHeader from '@features/PageHeader';
import { Typography } from '@components/Typography';
import { InternalLink } from '@components/Link';
import Button from '@components/Button';
import { Table } from '@components/Table';
import { I18N_DATE_FORMAT } from '@helpers/dates';
import { ReviewsOverviewIcon } from 'assets/images/icons';
import { useTranslation } from '@hooks/useTranslation';
import { useClickSendSurveys } from '@hooks/useClickSendSurveys';
import { sentRequestsActions } from '@actions';

const Container = styled('div')({
    '@media (max-width: 1024px)': {
        padding: 16,
        height: 'calc(var(--100vh) - 108px)',
        overflowY: 'auto',
    },
});

const GetTableHeader = () => {
    const { t } = useTranslation();
    return [
        {
            key: 0,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.name')}
                </Typography>
            ),
        },
        {
            key: 1,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('FeedbackOverview.phoneNumberEmail')}
                </Typography>
            ),
        },
        {
            key: 2,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.location')}
                </Typography>
            ),
        },
        {
            key: 3,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('FeedbackOverview.template')}
                </Typography>
            ),
        },
        {
            key: 4,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('common.status')}
                </Typography>
            ),
        },
        {
            key: 5,
            component: (
                <Typography align="left" variant="subtitle2">
                    {t('FeedbackOverview.sentOn')}
                </Typography>
            ),
        },
        {
            key: 6,
            component: <Typography align="left" variant="subtitle2"></Typography>,
        },
    ];
};

const GetTableBody = (data, language) => {
    const { t } = useTranslation();

    const statuses = {
        0: t('FeedbackOverview.undelivered'),
        1: t('FeedbackOverview.delivered'),
        2: t('FeedbackOverview.sending'),
    };

    return data.map(request => [
        {
            component: (
                <Typography variant="subtitle2" align="left">
                    {request.name || '--'}
                </Typography>
            ),
        },
        {
            component: (
                <Typography variant="body2" align="left">
                    {request.phoneNumber || request.email || '--'}
                </Typography>
            ),
        },
        {
            component: (
                <Typography variant="body2" align="left">
                    {request.internalName || '--'}
                </Typography>
            ),
        },
        {
            component: (
                <Typography variant="body2" align="left">
                    {request.surveyName || '--'}
                </Typography>
            ),
        },
        {
            component: (
                <Typography
                    variant="body2"
                    align="left"
                    sx={{ color: request?.status ? 'rgba(0, 0, 0, 0.87)' : '#B00020' }}
                >
                    {statuses[request?.status ?? 0]}
                </Typography>
            ),
        },
        {
            component: (
                <Typography variant="body2" align="left">
                    {format(new Date(request.sendTime), 'dd MMM yyyy, HH:mm', { locale: I18N_DATE_FORMAT[language] })}
                </Typography>
            ),
        },
        {
            component: (
                <IconButton>
                    <Tooltip title={request.message} placement="left" enterTouchDelay={0}>
                        <MessageIcon />
                    </Tooltip>
                </IconButton>
            ),
        },
    ]);
};

const FeedbackOverview = () => {
    const { t } = useTranslation();
    const { onClickSendSurveys } = useClickSendSurveys();

    const [pageRequests, setPageRequests] = useState(0);
    const [rowsPerPageRequests, setRowsPerPageRequests] = useState(10);

    const { data, loading, totalCount, pages } = useSelector(state => state.sentRequests);
    const language = useSelector(state => state.authentication.user?.language);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sentRequestsActions.get(pageRequests, rowsPerPageRequests));
    }, [pageRequests, rowsPerPageRequests, dispatch]);

    const onPageChange = (_, newPage) => setPageRequests(newPage);

    const onRowsPerPageChange = event => {
        setRowsPerPageRequests(parseInt(event.target.value, 10));
        setPageRequests(0);
    };

    return (
        <Container>
            <PageHeader
                styles={{ marginBottom: '16px' }}
                icon={<ReviewsOverviewIcon />}
                title={t('FeedbackOverview.title')}
                subtitle={t('FeedbackOverview.subtitle')}
            >
                <Button variant="outlined" onClick={onClickSendSurveys}>
                    {t('buttons.sendSurveys')}
                </Button>
                <Button variant="outlined">
                    <InternalLink to="/surveys/templates">{t('buttons.setUpSurveys')}</InternalLink>
                </Button>
                <Button variant="outlined">
                    <InternalLink to="/surveys/responses">{t('buttons.viewResponses')}</InternalLink>
                </Button>
            </PageHeader>
            <div style={{ padding: 16, border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: 4 }}>
                <Typography variant="subtitle2" sx={{ marginBottom: '16px' }}>
                    {t('FeedbackOverview.sentInvitations')}
                </Typography>
                <TableContainer>
                    <Table
                        withHover
                        columnNames={GetTableHeader()}
                        rows={GetTableBody(data, language)}
                        isDataLoading={loading}
                        withPagination
                        paginationProps={{
                            colSpan: 7,
                            count: totalCount,
                            rowsPerPage: rowsPerPageRequests,
                            page: pageRequests,
                            onPageChange,
                            onRowsPerPageChange,
                            labelDisplayedRows: ({ from, to, count }) =>
                                `${from}–${to} ${t('Table.of')} ${
                                    count !== -1 ? count : `${t('common.moreThan')} ${to}`
                                }`,
                            labelRowsPerPage: <span>{t('Table.rowsPerPage')}</span>,
                            pages,
                        }}
                    />
                </TableContainer>
            </div>
        </Container>
    );
};

export default FeedbackOverview;
