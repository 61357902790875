import { COUNTRY_NAMES } from '@constants/countries';

export const formatCompanies = (allCompanies, assignedCompanies = [], limit = null, withNumber = true) => {
    if (assignedCompanies.length) {
        let plainNames = '';
        let companiesCount = 0;
        allCompanies.forEach(company => {
            if (assignedCompanies.includes(company.companyId)) {
                if (plainNames.length > 0) {
                    plainNames += ', ' + company.internalName;
                    companiesCount += 1;
                } else {
                    plainNames += company.internalName;
                    companiesCount += 1;
                }
            }
        });
        if (!limit) {
            return `${withNumber ? `(${companiesCount}) ` : ''}${plainNames}`;
        }
        return `${withNumber ? `(${companiesCount}) ` : ''}${
            plainNames.length > limit ? plainNames.substring(0, limit) + '...' : plainNames
        }`;
    }
    return '--';
};

export const filterAndFormatCompaniesByRole = (user, companies) => {
    if (user?.roles?.length) {
        let availableCompanies;
        let companiesCount = 0;
        if (user.isAccountOwner || user.isGlobalAdmin) {
            availableCompanies = companies
                .map(company => {
                    companiesCount += 1;
                    return company.internalName;
                })
                .join(', ');
        } else {
            availableCompanies = user.roles
                .map(role => companies.find(company => company.companyId === role.companyId)?.internalName)
                .filter(Boolean)
                .map(internalName => {
                    companiesCount += 1;
                    return internalName;
                })
                .join(', ');
        }
        return `(${companiesCount}) ${
            availableCompanies.length > 40 ? availableCompanies.substring(0, 40) + '...' : availableCompanies
        }`;
    }
    return '--';
};

export const getCompanyAddress = (address, city) => {
    if (!address && !city) {
        return '--';
    }
    return `${address}${city ? `, ${city}` : ''}`;
};

export const getCompanyFullAddress = company => {
    const addr1 = company.addressLine1 || '';
    const addr2 = company.addressLine2 || '';
    const city = company.city || '';
    const postalCode = company.postalCode || '';
    return `${addr1}${addr2 ? `, ${addr2}` : ''}${city ? `, ${city}` : ''}${postalCode ? `, ${postalCode}` : ''}`;
};

export const generateCompanyCardAddress = addr => {
    return `${addr.addressLines[0] || ''}, ${addr.city || ''}, ${addr.postalCode || ''}, ${
        COUNTRY_NAMES[addr.country] || ''
    }`;
};
