import { useSelector } from 'react-redux';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@components/Typography';
import { getChannelIcon } from '@helpers/channels';
import { CHANNEL_NAME, CHANNEL } from '@constants/channels';
import { AccIntegrationName } from '@constants/integrations';
import { StyledRadioGroup, Container, ChannelRadioWrapper } from './styles';

const SelectChannel = ({ modal, setModal }) => {
    const account = useSelector(state => state.account?.account);

    const isConnectedFacebook = account?.integrations.find(i => i.type === AccIntegrationName.Facebook)?.isActive;

    return (
        <Container>
            {[
                { name: CHANNEL_NAME.SMS, id: CHANNEL.SMS, isConnected: true },
                { name: CHANNEL_NAME.EMAIL, id: CHANNEL.EMAIL, isConnected: true },
                { name: CHANNEL_NAME.WHATSAPP, id: CHANNEL.WHATSAPP, isConnected: true },
                { name: CHANNEL_NAME.LIVE_CHAT, id: CHANNEL.LIVE_CHAT, isConnected: true },
                { name: CHANNEL_NAME.FACEBOOK, id: CHANNEL.FACEBOOK, isConnected: isConnectedFacebook },
                { name: CHANNEL_NAME.INSTAGRAM, id: CHANNEL.INSTAGRAM, isConnected: isConnectedFacebook },
            ].map(channel => (
                <ChannelRadioWrapper
                    key={channel.id}
                    isActive={Number(modal.channel) === channel.id}
                    isDisabled={!channel.isConnected}
                    onClick={() => channel.isConnected && setModal(prev => ({ ...prev, channel: channel.id }))}
                >
                    <StyledRadioGroup value={`${modal.channel}`}>
                        <FormControlLabel
                            value={channel.isConnected ? `${channel.id}` : 'disabled'}
                            control={
                                <Radio
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        padding: '6px',
                                        '&.Mui-checked': { color: '#1F4C5C' },
                                    }}
                                    disabled={!channel.isConnected}
                                />
                            }
                            label=""
                            disabled={!channel.isConnected}
                        />
                        {getChannelIcon(channel.id)}
                        <Typography variant="body2" sx={{ marginLeft: '10px' }}>
                            {channel.name}
                        </Typography>
                    </StyledRadioGroup>
                </ChannelRadioWrapper>
            ))}
        </Container>
    );
};

export default SelectChannel;
