import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';

const AuthRoute = ({ component: Component, ...rest }) => {
    const { state } = useLocation();
    const auth = useSelector(state => state.authentication);

    if (auth.user) {
        if (auth.user?.onboardingCompleted) {
            return <Redirect to="/" />;
        }
        const onboardingAfterGoogle =
            state?.from?.pathname === '/onboarding' && !!state?.from?.search ? state?.from?.search : '';
        if (!auth.user?.onboardingCompleted) {
            return <Redirect to={`/onboarding${onboardingAfterGoogle}`} />;
        }
    }

    return <Route {...rest} render={props => <Component {...props} />} />;
};

export default AuthRoute;
