import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCurrentEditor } from '@tiptap/react';
import sortBy from 'lodash.sortby';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import MenuList from '@components/MenuList';
import { useTranslation } from '@hooks/useTranslation';
import { addTipTapMarkTag } from '@helpers/text-editor';
import { InboxSurveysIcon } from 'assets/images/icons';

const SurveysMenu = ({ disabled, setMessage, selectedConversation }) => {
    const { t } = useTranslation();
    const { editor } = useCurrentEditor();
    const isMobile = useMediaQuery('@media (max-width: 600px)');

    const [anchorElMain, setAnchorElMain] = useState(null);
    const [anchorElCompanies, setAnchorElCompanies] = useState({});
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const companies = useSelector(state => state.account?.account?.companies || []);

    useEffect(() => {
        editor?.commands.clearContent();
    }, [selectedConversation]);

    const handleOpenMain = event => {
        if (isMobile) {
            setMobileMenuOpen(true);
        } else {
            setAnchorElMain(event.currentTarget);
            setAnchorElCompanies({});
        }
    };

    const handleOpenCompany = (event, company) => {
        if (anchorElCompanies[company.companyId] === event.currentTarget) {
            return;
        }
        setAnchorElCompanies({ [company.companyId]: event.currentTarget });
    };

    const handleCloseMain = () => setAnchorElMain(null);

    const onClickTemplate = survey => {
        const newMessage = survey.message
            ?.replace(/\[name\]/g, selectedConversation?.firstName || '')
            ?.replace(/\[last_name\]/g, selectedConversation?.lastName || '')
            ?.replace(
                /\[location\]/g,
                companies.find(c => c.companyId === selectedConversation?.companyId)?.locationName || '',
            );
        const messageWithLink = `${newMessage} [survey] `;
        const messageWithId = survey.id ? `${newMessage} [survey:${survey.id}]` : newMessage;
        setMessage(messageWithId);
        editor?.commands.setContent(addTipTapMarkTag(messageWithLink));
        setMobileMenuOpen(false);
        setAnchorElMain(null);
    };

    const mapMenu = useMemo(() => {
        return companies
            .filter(company => company.surveys?.length > 0)
            .map(company => ({
                icon: null,
                text: company.internalName,
                sub: {
                    header: t('common.surveys'),
                    list: sortBy(
                        company.surveys.map(survey => ({
                            icon: null,
                            text: survey.name,
                            onClick: () => onClickTemplate(survey),
                        })),
                        ['text'],
                    ),
                },
                onClick: e => handleOpenCompany(e, company),
                anchorEl: anchorElCompanies[company.companyId] || null,
            }));
    }, [companies, anchorElCompanies]);

    return (
        <>
            <MenuList
                options={mapMenu}
                anchorEl={anchorElMain}
                onCloseMenu={handleCloseMain}
                openModal={isMobileMenuOpen}
                handleCloseModal={() => setMobileMenuOpen(false)}
                mainTitle={t('InboxTemplates.selectLocation')}
                menuButton={
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={handleOpenMain}
                        disabled={disabled}
                        sx={{ height: 18, width: 18, padding: '2px !important' }}
                    >
                        <InboxSurveysIcon />
                    </IconButton>
                }
            />
        </>
    );
};

export default SurveysMenu;
