import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const keywordsService = {
    get,
    getAll,
};

function get(data, page, pageSize) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/analytics/keywords?page=${page}&pageSize=${pageSize}`, requestOptions).then(
        handleResponse,
    );
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${BASE_URL}api/reviews/keywords`, requestOptions).then(handleResponse);
}
