export const formatTriggers = (allTriggers, assignedTriggers = []) => {
    if (assignedTriggers.length) {
        let plainNames = '';
        let triggersCount = 0;
        Object.entries(allTriggers).forEach(([trigger, label]) => {
            if (assignedTriggers.includes(+trigger)) {
                if (plainNames.length > 0) {
                    plainNames += ', ' + label;
                    triggersCount += 1;
                } else {
                    plainNames += label;
                    triggersCount += 1;
                }
            }
        });
        return `${`(${triggersCount}) `}${plainNames.length > 20 ? plainNames.substring(0, 20) + '...' : plainNames}`;
    }
    return '--';
};
