import { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';
import { HexColorPicker } from 'react-colorful';

export const CssFormControl = styled(FormControl)({
    paddingBottom: 16,
    position: 'relative',
    '.MuiFormLabel-asterisk': {
        color: '#B00020',
    },
    '.react-colorful': {
        width: 180,
        height: 180,
    },
    '.react-colorful__saturation': {
        borderRadius: '4px 4px 0 0',
    },
    '.react-colorful__last-control': {
        borderRadius: '0 0 4px 4px',
    },
    '.react-colorful__wrapper': {
        position: 'absolute',
        top: -190,
        zIndex: 2,
    },
});

export const CssInputLabel = styled(InputLabel)({
    fontSize: '0.875rem',
    lineHeight: '1rem',
    letterSpacing: '0.5px',
    transform: 'translate(12px, 12px) scale(1)',
    fontFamily: 'Rubik, sans-serif',
    '&.Mui-focused': {
        color: '#1F4C5C',
    },
    '&.Mui-error': {
        color: '#B00020',
    },
    '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px)',
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '0.38px',
        fontFamily: 'Rubik, sans-serif',
    },
});

export const CssOutlinedInput = styled(OutlinedInput)({
    '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#1F4C5C',
        },
    },
    '&.Mui-error': {
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#B00020',
        },
    },
    '&.MuiInputBase-adornedStart': {
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.6)',
        '& input': {
            paddingLeft: 0,
            lineHeight: '15px',
        },
        '& fieldset legend': {
            padding: '0 5px',
        },
    },
    input: {
        padding: '12px',
        height: '1rem',
        lineHeight: '16px',
        fontSize: '14px',
        fontFamily: 'Rubik, sans-serif',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    fieldset: {
        borderRadius: 4,
        borderColor: 'rgba(0, 0, 0, 0.12)',
        legend: {
            letterSpacing: '0.6px',
        },
    },
    '& .MuiSelect-icon': {
        color: 'rgba(0, 0, 0, 0.88)',
    },
    '& .MuiSelect-icon.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline ': {
        borderColor: 'rgba(0, 0, 0, 0.12)',
    },
    '& .MuiSelect-select': {
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 12,
        boxSizing: 'border-box',
        fontSize: '0.875rem',
        fontFamily: 'Rubik, sans-serif',
        lineHeight: '16px',
    },
});

export const CssFormHelperText = styled(FormHelperText)({
    position: 'absolute',
    top: 43,
    left: 0,
    margin: '0 12px',
    fontFamily: 'Rubik, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.4px',
    '&.Mui-error': {
        color: '#B00020',
    },
});

export const CssInputAdornment = styled(InputAdornment)({
    marginLeft: 0,
});

export const ColorPicker = ({
    inputRef,
    id,
    label,
    className,
    fullWidth,
    inputProps,
    required,
    disabled,
    error,
    helperText,
    placeholder = null,
    autoComplete = 'new-password',
    styles,
}) => {
    const [isOpen, setIsOpen] = useState(null);

    const handleChange = color => inputProps.onChange(color);

    return (
        <CssFormControl
            required={required}
            variant="outlined"
            className={className}
            fullWidth={fullWidth}
            onFocus={() => setIsOpen(true)}
            onBlur={() => setIsOpen(false)}
            style={styles}
        >
            <CssInputLabel htmlFor={id} error={error}>
                {label}
            </CssInputLabel>
            <CssOutlinedInput
                inputRef={inputRef}
                id={id}
                autoComplete={autoComplete}
                error={error}
                label={label}
                placeholder={placeholder}
                disabled={disabled}
                inputProps={{ ...inputProps, type: 'text' }}
            />
            <CssFormHelperText error={error}>{helperText}</CssFormHelperText>
            {isOpen ? (
                <div className="react-colorful__wrapper">
                    <HexColorPicker color={inputProps.value} onChange={handleChange} />
                </div>
            ) : null}
        </CssFormControl>
    );
};
