import { authHeader, BASE_URL, handleResponse } from '@helpers/api';

export const analyticsService = {
    getAnalyticsConversion,
    getAnalyticsReviews,
    getAnalyticsFeedback,
    getAnalyticsConversations,
    getAnalyticsSocialPosts,
    getAnalyticsPresence,
};

function getAnalyticsConversion(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/Analytics/conversion`, requestOptions).then(handleResponse);
}

function getAnalyticsReviews(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/Analytics/reviews`, requestOptions).then(handleResponse);
}

function getAnalyticsFeedback(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/Analytics/feedbacks`, requestOptions).then(handleResponse);
}

function getAnalyticsConversations(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/Analytics/conversations`, requestOptions).then(handleResponse);
}

function getAnalyticsSocialPosts(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/analytics/social-posts`, requestOptions).then(handleResponse);
}

function getAnalyticsPresence(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL}api/analytics/presence`, requestOptions).then(handleResponse);
}
