import { styled } from '@mui/material/styles';
import { useTranslation } from '@hooks/useTranslation';
import { generateCompanyCardAddress } from '@helpers/companies';
import Card from '@components/Card';

const CardText = styled('p', {
    shouldForwardProp: prop => prop !== 'isDisabled' && prop !== 'isAccName',
})(({ isDisabled, isAccName }) => ({
    fontFamily: 'Rubik, sans-serif',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '12px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: 0,
    ...(isDisabled && {
        color: 'rgba(0, 0, 0, 0.38)',
    }),
    ...(isAccName && {
        color: '#0B8D00',
        marginBottom: 0,
    }),
}));

const GoogleCompanyCard = ({ location = {}, selectedCompanies, setCompany, index }) => {
    const { t } = useTranslation();
    const isActive = selectedCompanies.includes(index);

    return (
        <Card
            type="checkbox"
            selected={isActive}
            disabled={location.company}
            title={location.title}
            index={index}
            setValue={setCompany}
        >
            {location.accountName && (
                <CardText isDisabled={location.company} style={{ marginBottom: '8px' }}>
                    {location.accountName}
                </CardText>
            )}
            {location.country && (
                <CardText isDisabled={location.company} style={{ marginBottom: '8px' }}>
                    {generateCompanyCardAddress(location)}
                </CardText>
            )}
            {location.primaryPhoneNumber && (
                <CardText isDisabled={location.company} style={{ marginBottom: location.company ? '16px' : 0 }}>
                    {location.primaryPhoneNumber}
                </CardText>
            )}
            {location.company && (
                <CardText isAccName>
                    {t('SettingsCompanies.linkedTo')}&nbsp;
                    {location.company?.name}
                </CardText>
            )}
        </Card>
    );
};

export default GoogleCompanyCard;
