import { Typography } from '@components/Typography';
import { formatNumber } from '@helpers/formatNumbers';
import { getTableArrow, TableCellText } from '.';

export const getTableBody = data => {
    return data.map(
        ({
            internalName,
            companyTotal = false,
            totalResponses,
            totalResponsesChange,
            averageRatingChange,
            negativeResponsesChange,
            averageRating,
            negativeResponses,
            netPromoterScoreChange,
            netPromoterScore,
        }) => {
            return companyTotal
                ? [
                      {
                          component: (
                              <Typography variant="subtitle2" align="left">
                                  {internalName?.toUpperCase()}
                              </Typography>
                          ),
                      },
                      {
                          component: (
                              <TableCellText variant="body2" align="right">
                                  <span style={{ marginRight: totalResponsesChange === 0 ? '28px' : '8px' }}>
                                      {formatNumber(totalResponses)}
                                  </span>
                                  {getTableArrow(totalResponsesChange)}
                              </TableCellText>
                          ),
                      },
                      {
                          component: (
                              <TableCellText variant="body2" align="right">
                                  <span style={{ marginRight: averageRatingChange === 0 ? '28px' : '8px' }}>
                                      {formatNumber(averageRating)}
                                  </span>
                                  {getTableArrow(averageRatingChange)}
                              </TableCellText>
                          ),
                      },
                      {
                          component: (
                              <TableCellText variant="body2" align="right">
                                  <span style={{ marginRight: negativeResponsesChange === 0 ? '28px' : '8px' }}>
                                      {formatNumber(negativeResponses)}
                                  </span>
                                  {getTableArrow(negativeResponsesChange)}
                              </TableCellText>
                          ),
                      },
                      {
                          component: (
                              <TableCellText variant="body2" align="right">
                                  <span style={{ marginRight: netPromoterScoreChange === 0 ? '28px' : '8px' }}>
                                      {netPromoterScore}
                                  </span>
                                  {getTableArrow(netPromoterScoreChange)}
                              </TableCellText>
                          ),
                      },
                  ]
                : null;
        },
    );
};
