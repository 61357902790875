import MuiAutocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Checkbox } from '@components/Checkbox';
import { Typography } from '@components/Typography';

export const StyledAutocomplete = styled(MuiAutocomplete, {
    shouldForwardProp: prop =>
        prop !== 'readOnly' && prop !== 'error' && prop !== 'groupBased' && prop !== 'groupBasedSelected',
})(({ readOnly, error, groupBased, groupBasedSelected }) => ({
    '& .MuiAutocomplete-clearIndicator': {
        visibility: `${readOnly ? 'hidden' : 'visible'} !important`,
    },
    '& .Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: error ? '#B00020 !important' : '#1F4C5C !important',
        },
    },
    '& .MuiOutlinedInput-root': {
        padding: !groupBased
            ? `0 ${readOnly ? '16px' : '38px'} 0 0 !important`
            : `10px ${readOnly ? '12px' : '38px'} ${groupBasedSelected ? '7px' : '10px'} 12px !important`,
        ...(!groupBased ? { height: '40px' } : {}),
        fontSize: '14px',
        '& .MuiAutocomplete-input': {
            paddingLeft: '16px',
            ...(groupBased && { padding: 0 }),
        },
        '& fieldset legend': {
            fontSize: 12,
        },
    },
    '& .MuiInputLabel-root': {
        textTransform: 'none !important',
        fontSize: '0.875rem',
        lineHeight: '1rem',
        letterSpacing: '0.5px',
        transform: 'translate(12px, 12px) scale(1)',
        fontFamily: 'Rubik, sans-serif',
        '&.Mui-focused': {
            color: '#1F4C5C',
        },
        '&.Mui-error': {
            color: '#B00020',
        },
        '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) !important',
            fontSize: '12px',
            lineHeight: '14px',
            letterSpacing: '0.38px',
        },
        '& .MuiFormLabel-asterisk': {
            color: '#B00020',
        },
    },
}));

export const StyledPopper = styled(Popper)({
    zIndex: '9999 !important',
    '& .MuiAutocomplete-noOptions': {
        padding: '8px 0',
    },
});

export const ErrorWrapper = styled('div')(({ error }) => ({
    position: 'relative',
    height: 'fit-content',
    '& .MuiInputLabel-root': {
        color: error && '#B00020 !important',
    },
}));

export const ErrText = styled('p')({
    position: 'absolute',
    bottom: '-28px',
    left: '11px',
    color: '#B00020',
    fontFamily: 'Rubik,sans-serif',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.4px',
});

export const GroupWrapper = styled(Paper)({
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    gap: 3,
    boxShadow: 'none',
    width: '100%',
    borderRadius: '0 !important',
});

export const AllOption = styled(GroupWrapper)({
    padding: '0px 3px !important',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
});

export const GroupOption = styled(GroupWrapper)({
    padding: '0px 32px !important',
});

export const OneGroupOption = styled(GroupWrapper)({
    padding: '0px 4px !important',
});

export const BaseOptionWrapper = styled(GroupWrapper)({
    gap: 6,
    padding: '0px 16px !important',
});

export const CssCheckbox = styled(Checkbox)({
    padding: '7px 9px 7px 9px',
    '& .MuiSvgIcon-root': {
        fontSize: '1.125rem',
    },
});

export const GroupHeader = styled(Typography)({
    lineHeight: '32px !important',
    fontFamily: 'Rubik',
    fontWeight: '500 !important',
    fontSize: '10px !important',
    letterSpacing: '1.5px !important',
    color: 'rgba(0, 0, 0, 0.38)',
    textTransform: 'uppercase',
});
