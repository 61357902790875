import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { Input } from '@components/Input';
import { Switch } from '@components/Switch';
import { MultiSelect } from '@components/MultiSelect';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { autoreplyRulesService } from '@services';
import { AccIntegrationName } from '@constants/integrations';
import { REVIEW_TYPE, AUTOREPLY_TYPE } from '@constants/autoreply-rules';
import { alertActions, autoreplyRulesActions } from '@actions';
import { useTranslation } from '@hooks/useTranslation';
import { getMultiselectSelectedOptions } from '@helpers/multiselect';
import { Modal, Content, RadioButtons } from './styles';

export const FORM_TYPE = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
    VIEW: 'VIEW',
};

const ratingOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
];

const EditModal = ({ selectedRule, setSelectedRule, modal, setModal, companies, page, setPage, rowsPerPage }) => {
    const [templatesOptions, setTemplatesOptions] = useState([]);

    const { data } = useSelector(state => state.replyTemplates);
    const { location: labels } = useSelector(state => state.labels);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const reviewTypesOptions = [
        { value: REVIEW_TYPE.WITHOUT_COMMENT, label: t('ReviewsAutoreplies.withoutComment') },
        { value: REVIEW_TYPE.WITH_COMMENT, label: t('ReviewsAutoreplies.withComment') },
    ];

    const availableLabels = [
        ...new Set(
            companies
                .filter(item => item.profiles.some(p => p.type === AccIntegrationName.Google))
                .map(item => item.labels)
                .flat(),
        ),
    ];

    const AutoreplyRuleSchema = yup.object().shape({
        name: yup.string().required('validation.required'),
        companies: yup.array().min(1, 'validation.required'),
        ratings: yup.array().min(1, 'validation.required'),
        reviewTypes: yup.array().min(1, 'validation.required'),
        templates: yup.array().when('type', {
            is: value => value === AUTOREPLY_TYPE.TEMPLATES,
            then: yup.array().min(1, 'validation.required'),
            otherwise: yup.array().notRequired(),
        }),
        enableAI: yup.boolean().when('type', {
            is: value => value === AUTOREPLY_TYPE.AI,
            then: yup.boolean().oneOf([true], 'validation.required'),
            otherwise: yup.boolean().notRequired(),
        }),
    });

    const resolver = useYupValidationResolver(AutoreplyRuleSchema);

    const {
        getValues,
        setValue,
        control,
        handleSubmit,
        watch,
        formState: { isValid, isDirty, isSubmitting },
    } = useForm({
        mode: 'onBlur',
        resolver,
        defaultValues: {
            name: selectedRule?.name || '',
            companies: selectedRule?.companies || [],
            ratings: selectedRule?.ratings || [1, 2, 3, 4, 5],
            reviewTypes: selectedRule?.reviewTypes || [REVIEW_TYPE.WITHOUT_COMMENT, REVIEW_TYPE.WITH_COMMENT],
            type: selectedRule?.type || AUTOREPLY_TYPE.TEMPLATES,
            templates: selectedRule?.templates || [],
            applyToPastReviews: !!selectedRule?.applyToPastReviews,
            enableAI: true,
            locationLabels: labels.filter(l => availableLabels.includes(l.id)).map(item => item.id),
        },
    });

    const autoReplyType = watch('type');
    const enableAI = watch('enableAI');
    const selectedLocationLabels = watch('locationLabels');

    const labelsOptions = useMemo(() => {
        return labels.filter(l => availableLabels.includes(l.id)).map(item => ({ value: item.id, label: item.name }));
    }, [companies, labels]);

    const companyOptions = useMemo(() => {
        const filteredCompanies = companies.filter(item =>
            item.profiles.some(p => p.type === AccIntegrationName.Google),
        );
        return selectedLocationLabels.length === labelsOptions.length
            ? filteredCompanies.map(item => ({ value: item.companyId, label: item.internalName }))
            : filteredCompanies
                  .filter(item => item.labels.some(l => selectedLocationLabels.includes(l)))
                  .map(item => ({ value: item.companyId, label: item.internalName }));
    }, [companies]);

    useEffect(() => {
        if (data.length) {
            const options = data.map(item => ({ value: item.id, label: item.name }));
            setTemplatesOptions(options);
        }
    }, [data, setValue]);

    const onClose = () => {
        setSelectedRule(null);
        setModal({ open: false, type: FORM_TYPE.NEW });
    };

    const refetchFirstPage = async () => {
        if (page === 0) {
            await dispatch(autoreplyRulesActions.get(page, rowsPerPage));
        } else {
            setPage(0); // Triggers refetch of templates
        }
    };

    const REFETCH_MAP = {
        [FORM_TYPE.EDIT]: async () => {
            await dispatch(autoreplyRulesActions.get(page, rowsPerPage));
        },
        [FORM_TYPE.NEW]: () => refetchFirstPage(),
    };

    const RESPONSE_MSG = {
        [FORM_TYPE.EDIT]: {
            success: t('alertMessages.editSuccess'),
            error: t('alertMessages.editFail'),
        },
        [FORM_TYPE.NEW]: {
            success: t('alertMessages.createSuccess'),
            error: t('alertMessages.createFail'),
        },
    };

    const onSubmit = async () => {
        const {
            name,
            companies: assignedCompanies,
            ratings,
            reviewTypes,
            templates,
            applyToPastReviews,
            enableAI,
            type,
        } = getValues();
        try {
            await autoreplyRulesService.addOrUpdateAutoreplyRules({
                name,
                companies: assignedCompanies,
                ratings,
                reviewTypes,
                type,
                ...(type === AUTOREPLY_TYPE.TEMPLATES && { templates, applyToPastReviews }),
                ...(type === AUTOREPLY_TYPE.AI && { enableAI }),
                ...(modal.type === FORM_TYPE.EDIT && { id: selectedRule.id }),
            });
            dispatch(alertActions.success(RESPONSE_MSG[modal.type].success));
            REFETCH_MAP[modal.type]();
            setModal({ open: false, type: FORM_TYPE.NEW });
            setSelectedRule(null);
        } catch (error) {
            if (t(`apiErrors.${error.errorCode}`)) {
                dispatch(alertActions.error(t(`apiErrors.${error.errorCode}`)));
            } else {
                dispatch(alertActions.error(RESPONSE_MSG[modal.type].error));
            }
        }
    };

    const isView = modal.type === FORM_TYPE.VIEW;

    return (
        <Modal
            isOpen={modal.open}
            handleClose={onClose}
            title={
                modal.type === FORM_TYPE.NEW
                    ? t('ReviewsAutoreplies.addRule')
                    : modal.type === FORM_TYPE.VIEW
                    ? t('ReviewsAutoreplies.viewRule')
                    : t('ReviewsAutoreplies.editRule')
            }
            onPrimaryAction={handleSubmit(onSubmit)}
            primaryActionText={t('buttons.save')}
            primaryActionDisabled={isSubmitting || !isValid || !isDirty || isView}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.cancel')}
        >
            <Content>
                <Controller
                    control={control}
                    name="name"
                    render={({ field, fieldState: { error }, ref }) => (
                        <Input
                            ref={ref}
                            inputProps={{ ...field, onChange: e => field.onChange(e.target.value) }}
                            label={t('ReviewsAutoreplies.ruleName')}
                            fullWidth
                            error={!!error}
                            helperText={error ? t(error.message) : null}
                            required
                            disabled={isView}
                            sx={{ gridArea: 'area1' }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="locationLabels"
                    render={({ field, ref }) => (
                        <MultiSelect
                            {...field}
                            ref={ref}
                            label={t('common.locationLabels')}
                            onChange={({ target: { value } }) => {
                                const selectedLabels = getMultiselectSelectedOptions(labelsOptions, value);
                                setValue('locationLabels', selectedLabels, { shouldDirty: true });
                                const filteredCompanies = companies.filter(item =>
                                    item.profiles.some(p => p.type === AccIntegrationName.Google),
                                );
                                const availableCompanies =
                                    selectedLabels.length === labelsOptions.length
                                        ? filteredCompanies.map(item => item.companyId)
                                        : filteredCompanies
                                              .filter(item => item.labels.some(l => selectedLabels.includes(l)))
                                              .map(item => item.companyId);
                                setValue('companies', availableCompanies, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                            }}
                            options={labelsOptions}
                            style={{ gridArea: 'area0' }}
                            countable
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="companies"
                    render={({ field, fieldState: { error }, ref }) => (
                        <MultiSelect
                            {...field}
                            ref={ref}
                            onChange={({ target: { value } }) =>
                                setValue('companies', getMultiselectSelectedOptions(companyOptions, value), {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                })
                            }
                            options={companyOptions}
                            label={t('common.locations')}
                            style={{ gridArea: 'area2' }}
                            fullWidth
                            countable
                            required
                            error={error}
                            disabled={isView}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="ratings"
                    render={({ field, fieldState: { error }, ref }) => (
                        <MultiSelect
                            {...field}
                            ref={ref}
                            onChange={({ target: { value } }) =>
                                setValue('ratings', getMultiselectSelectedOptions(ratingOptions, value), {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                })
                            }
                            options={ratingOptions}
                            label={t('common.rating')}
                            style={{ gridArea: 'area3' }}
                            fullWidth
                            countable
                            required
                            error={error}
                            disabled={isView}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="reviewTypes"
                    render={({ field, fieldState: { error }, ref }) => (
                        <MultiSelect
                            {...field}
                            ref={ref}
                            onChange={({ target: { value } }) =>
                                setValue('reviewTypes', getMultiselectSelectedOptions(reviewTypesOptions, value), {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                })
                            }
                            options={reviewTypesOptions}
                            label={t('ReviewsAutoreplies.reviewType')}
                            style={{ gridArea: 'area4' }}
                            fullWidth
                            required
                            countable
                            error={error}
                            disabled={isView}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="type"
                    render={({ field }) => (
                        <RadioButtons
                            value={field.value}
                            onChange={value => setValue('type', value, { shouldDirty: true, shouldValidate: true })}
                            options={[
                                { value: AUTOREPLY_TYPE.TEMPLATES, label: t('common.templates') },
                                { value: AUTOREPLY_TYPE.AI, label: 'Pluspoint AI' },
                            ]}
                        />
                    )}
                />
                {autoReplyType === AUTOREPLY_TYPE.TEMPLATES ? (
                    <>
                        <Controller
                            control={control}
                            name="templates"
                            render={({ field, fieldState: { error }, ref }) => {
                                return (
                                    <MultiSelect
                                        {...field}
                                        ref={ref}
                                        onChange={({ target: { value } }) => {
                                            setValue(
                                                'templates',
                                                getMultiselectSelectedOptions(templatesOptions, value),
                                                {
                                                    shouldValidate: true,
                                                    shouldDirty: true,
                                                },
                                            );
                                        }}
                                        options={templatesOptions}
                                        label={t('common.replyTemplates')}
                                        style={{ gridArea: 'area6' }}
                                        fullWidth
                                        countable
                                        required
                                        error={error}
                                        disabled={isView}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="applyToPastReviews"
                            render={({ field, ref }) => (
                                <Switch
                                    {...field}
                                    ref={ref}
                                    checked={getValues('applyToPastReviews')}
                                    handleChange={event =>
                                        setValue('applyToPastReviews', event.target.checked, { shouldDirty: true })
                                    }
                                    label={t('ReviewsAutoreplies.applyToPastReviews')}
                                    disabled={isView}
                                    style={{ gridArea: 'area7' }}
                                />
                            )}
                        />
                    </>
                ) : (
                    <>
                        <div style={{ gridArea: 'area7' }} />
                        <Controller
                            control={control}
                            name="enableAI"
                            render={({ field, ref }) => (
                                <Switch
                                    {...field}
                                    ref={ref}
                                    checked={enableAI}
                                    handleChange={event =>
                                        setValue('enableAI', event.target.checked, {
                                            shouldDirty: true,
                                            shouldValidate: true,
                                        })
                                    }
                                    label={t('ReviewsAutoreplies.enableAI')}
                                    disabled={isView}
                                    style={{ gridArea: 'area6' }}
                                />
                            )}
                        />
                    </>
                )}
            </Content>
        </Modal>
    );
};

export default EditModal;
