import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@components/Typography';
import { Checkbox } from '@components/Checkbox';
import { LabelPlain } from '@components/Label';
import { InternalLink } from '@components/Link';
import { FiltersIntegrationType, FiltersIntegrationName, AccIntegrationName } from '@constants/integrations';
import { SurveysIcon, ReviewsIcon } from 'assets/images/sidebar';
import { UserIcon } from 'assets/images/icons';
import { useTranslation } from '@hooks/useTranslation';
import { getChannelIcon } from '@helpers/channels';
import { formatCompanies } from '@helpers/companies';
import { contactsActions } from '@actions';
import { ContactInfoWrapper, UnsubscribeWrapper, ActivityTitle, ActivityWrapper, IconWrapper } from './styles';

const ACTIVITY = {
    MessageSent: 0,
    SurveyResponded: 1,
    SurveySent: 2,
    SurveyReminderSent: 3,
    ConversationStarted: 4,
    ConversationClosed: 5,
    ConversationReopened: 6,
    ContactCreated: 7,
    ReviewLeft: 8,
};

const overflowStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
};

const ContactInfo = ({ contact, resizable, setSelectedConversation = null }) => {
    const { t } = useTranslation();
    const companies = useSelector(state => state.account?.account?.companies || []);
    const isSmallScreen = useMediaQuery('@media (min-width: 1025px) and (max-width: 1280px)');
    const isMobile = useMediaQuery('@media (max-width: 768px)');
    const dispatch = useDispatch();

    const onClickInboxActivity = () => {
        if (isMobile) {
            dispatch(contactsActions.setContactOpen(false));
            if (setSelectedConversation) {
                setSelectedConversation(null);
            }
        }
    };

    const getIntegrationName = integrationType => {
        switch (integrationType) {
            case AccIntegrationName.Facebook:
                return FiltersIntegrationType[FiltersIntegrationName.Facebook];
            case AccIntegrationName.Google:
                return FiltersIntegrationType[FiltersIntegrationName.Google];
            default:
                return FiltersIntegrationType[integrationType];
        }
    };

    const getActivityIcon = item => {
        switch (item?.type) {
            case ACTIVITY.SurveyResponded:
                return (
                    <IconWrapper>
                        <SurveysIcon />
                    </IconWrapper>
                );
            case ACTIVITY.ReviewLeft:
                return (
                    <IconWrapper>
                        <ReviewsIcon />
                    </IconWrapper>
                );
            case ACTIVITY.ContactCreated:
                return (
                    <IconWrapper>
                        <UserIcon />
                    </IconWrapper>
                );
            default:
                return getChannelIcon(item?.channelType);
        }
    };

    return (
        <>
            <ContactInfoWrapper>
                <Typography variant="caption" color="textSecondary" sx={{ marginBottom: '6px' }}>
                    {t('common.firstName')}
                </Typography>
                <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                    {contact.firstName || '--'}
                </Typography>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <Typography variant="caption" color="textSecondary" sx={{ marginBottom: '6px' }}>
                    {t('common.lastName')}
                </Typography>
                <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                    {contact.lastName || '--'}
                </Typography>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <Typography variant="caption" color="textSecondary" sx={{ marginBottom: '6px' }}>
                    {t('common.phoneNumber')}
                </Typography>
                <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                    {contact.phoneNumber || '--'}
                </Typography>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <Typography variant="caption" color="textSecondary" sx={{ marginBottom: '6px' }}>
                    {t('common.emailAddress')}
                </Typography>
                <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'} sx={overflowStyle}>
                    {contact.email || '--'}
                </Typography>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <Typography variant="caption" color="textSecondary" sx={{ marginBottom: '6px', ...overflowStyle }}>
                    {t('ContactsPage.facebookProfile')}
                </Typography>
                <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'} sx={overflowStyle}>
                    {contact.facebookProfile ? (
                        <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>facebook.com/</span>
                    ) : (
                        '--'
                    )}
                    {contact.facebookProfile}
                </Typography>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <Typography variant="caption" color="textSecondary" sx={{ marginBottom: '6px' }}>
                    {t('ContactsPage.instagramProfile')}
                </Typography>
                <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'} sx={overflowStyle}>
                    {contact.instagramProfile ? (
                        <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>instagram.com/</span>
                    ) : (
                        '--'
                    )}
                    {contact.instagramProfile}
                </Typography>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
                <Typography variant="caption" color="textSecondary" sx={{ marginBottom: '6px' }}>
                    {t('common.locations')}
                </Typography>
                <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                    {formatCompanies(companies, contact.companies)}
                </Typography>
            </ContactInfoWrapper>
            {contact.createdOn ? (
                <ContactInfoWrapper>
                    <Typography variant="caption" color="textSecondary" sx={{ marginBottom: '6px' }}>
                        {t('ContactsPage.createdOn')}
                    </Typography>
                    <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                        {format(new Date(contact?.createdOn), 'dd MMM yyyy')}
                    </Typography>
                </ContactInfoWrapper>
            ) : null}
            <ContactInfoWrapper>
                <Typography variant="caption" color="textSecondary" sx={{ marginBottom: '6px' }}>
                    {t('common.labels')}
                </Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                    {contact.labels?.length
                        ? contact.labels?.map(label => (
                              <LabelPlain key={label.id} type={label.name} variant="caption">
                                  {label.name}
                              </LabelPlain>
                          ))
                        : '--'}
                </div>
            </ContactInfoWrapper>
            <UnsubscribeWrapper>
                <Checkbox checked={!!contact?.unsubscribed} disabled />
                <Typography variant="caption">{t('ContactsPage.unsubscribed')}</Typography>
            </UnsubscribeWrapper>
            {!!contact?.activities?.length && (
                <>
                    <div
                        style={{
                            width: '100%',
                            height: '1px',
                            backgroundColor: 'rgba(0, 0, 0, 0.12)',
                            margin: '16px 0',
                        }}
                    />
                    <ActivityTitle variant="caption">{t('ContactsPage.activityTitle')}</ActivityTitle>
                    {contact?.activities?.map(item => (
                        <ActivityWrapper key={item.id}>
                            {getActivityIcon(item)}
                            {item?.type === ACTIVITY.MessageSent && (
                                <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                                    {t('ContactsPage.activityMessageSent', {
                                        channel: item?.channelName,
                                        message: item?.message,
                                    })}
                                </Typography>
                            )}
                            {item?.type === ACTIVITY.SurveyResponded && (
                                <InternalLink to={`/surveys/responses?feedbackId=${item.feedbackId}`} underline="hover">
                                    <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                                        {t('ContactsPage.activitySurveyResponded', {
                                            template: item?.surveyName,
                                            rating: item?.rating,
                                        })}
                                    </Typography>
                                </InternalLink>
                            )}
                            {item?.type === ACTIVITY.SurveySent && (
                                <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                                    {t('ContactsPage.activitySurveySent', { template: item?.surveyName })}
                                </Typography>
                            )}
                            {item?.type === ACTIVITY.SurveyReminderSent && (
                                <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                                    {t('ContactsPage.activitySurveyReminder', { template: item?.surveyName })}
                                </Typography>
                            )}
                            {item?.type === ACTIVITY.ConversationStarted &&
                                (item?.conversationId && item?.conversationStatus !== null ? (
                                    <InternalLink
                                        to={`/messaging/inbox?conversationId=${item?.conversationId}&conversationStatus=${item?.conversationStatus}`}
                                        onClick={onClickInboxActivity}
                                        underline="hover"
                                    >
                                        <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                                            {t('ContactsPage.activityConversationStarted')}
                                        </Typography>
                                    </InternalLink>
                                ) : (
                                    <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                                        {t('ContactsPage.activityConversationStarted')}
                                    </Typography>
                                ))}
                            {item?.type === ACTIVITY.ConversationClosed &&
                                (item?.conversationId && item?.conversationStatus !== null ? (
                                    <InternalLink
                                        to={`/messaging/inbox?conversationId=${item?.conversationId}&conversationStatus=${item?.conversationStatus}`}
                                        onClick={onClickInboxActivity}
                                        underline="hover"
                                    >
                                        <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                                            {t('ContactsPage.activityConversationClosed', { name: item?.userName })}
                                        </Typography>
                                    </InternalLink>
                                ) : (
                                    <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                                        {t('ContactsPage.activityConversationClosed', { name: item?.userName })}
                                    </Typography>
                                ))}
                            {item?.type === ACTIVITY.ConversationReopened &&
                                (item?.conversationId && item?.conversationStatus !== null ? (
                                    <InternalLink
                                        to={`/messaging/inbox?conversationId=${item?.conversationId}&conversationStatus=${item?.conversationStatus}`}
                                        onClick={onClickInboxActivity}
                                        underline="hover"
                                    >
                                        <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                                            {t('ContactsPage.activityConversationReopened', { name: item?.userName })}
                                        </Typography>
                                    </InternalLink>
                                ) : (
                                    <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                                        {t('ContactsPage.activityConversationReopened', { name: item?.userName })}
                                    </Typography>
                                ))}
                            {item?.type === ACTIVITY.ContactCreated && (
                                <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                                    {t('common.contactCreated')}
                                </Typography>
                            )}
                            {item?.type === ACTIVITY.ReviewLeft && (
                                <InternalLink to={`/reviews/manage?reviewId=${item.reviewId}`} underline="hover">
                                    <Typography variant={isSmallScreen && resizable ? 'caption' : 'body2'}>
                                        {t('ContactsPage.activityReviewLeft', {
                                            reviewType: getIntegrationName(item?.reviewType),
                                        })}
                                    </Typography>
                                </InternalLink>
                            )}
                            <Typography
                                variant="caption"
                                sx={{ color: 'rgba(0, 0, 0, 0.38)', display: 'block', textAlign: 'end' }}
                            >
                                {format(new Date(item?.createdAt), 'dd/MM/yy HH:mm')}
                            </Typography>
                        </ActivityWrapper>
                    ))}
                </>
            )}
        </>
    );
};

export default ContactInfo;
