import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { ModalBase } from '@components/Modal';
import { useTranslation } from '@hooks/useTranslation';
import { hubspotCalendarUiActions } from '@actions';

const Modal = styled(ModalBase)({
    '& .modal-base-paper': {
        outline: 'none',
        width: 830,
        maxWidth: 'calc(100% - 32px)',
        height: 680,
        '@media (max-width: 900px)': {
            height: 'var(--100vh)',
            width: '100%',
        },
    },
});

const HubspotCalendar = () => {
    const { isOpen } = useSelector(state => state.hubspotCalendarUi);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onClose = () => dispatch(hubspotCalendarUiActions.setOpen(false));

    return (
        <Modal
            isOpen={isOpen}
            handleClose={onClose}
            title={t('SettingsGeneral.hubspotCalendar')}
            onSecondaryAction={onClose}
            secondaryActionText={t('buttons.close')}
        >
            <div style={{ height: '100%', width: '100%', position: 'relative' }}>
                <iframe
                    title="hubspot"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: '100%',
                        height: '100%',
                        border: 'none',
                    }}
                    src="https://meetings.hubspot.com/sergii1?embed=true"
                />
            </div>
        </Modal>
    );
};

export default HubspotCalendar;
