import { styled } from '@mui/system';
import Rating from '@mui/material/Rating';
import Paper from '@mui/material/Paper';
import { UserIcon } from 'assets/images/icons';

export const ItemContainer = styled(Paper)({
    borderRadius: '4px !important',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '&:not(:last-child)': {
        marginBottom: '16px',
    },
});

export const MainSection = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
});

export const Footer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderTop: '1px solid #E5E5E5',
    padding: '16px',
});

export const WrapperContact = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

export const WrapperRating = styled(WrapperContact)({
    '@media (max-width: 600px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
});

export const IntegrationContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '18px',
    top: '14px',
});

export const CssRating = styled(Rating)({
    marginRight: '9px',
    '@media (max-width: 1024px)': {
        marginBottom: '8px',
    },
    '& .MuiRating-iconFilled': {
        color: '#1F4C5C',
    },
});

export const StyledUserIcon = styled(UserIcon)({
    width: '16px !important',
    height: '16px !important',
    '& path': {
        fill: 'rgba(0, 0, 0, 0.38)',
        fillOpacity: 1,
    },
});

export const WrapperContactName = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    marginLeft: '8px',
});
